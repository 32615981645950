/* eslint-disable import/no-cycle */

import React from 'react';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Content } from '../analysisview/content';
import IconedButton from '../input/iconedButton';

const useStyles = makeStyles((theme) => ({
  cardStyle: {
    margin: '0.25rem',
    width: '18rem',
    height: 'auto',
    padding: '0.25rem',
    paddingTop: '0.3rem',
    paddingBottom: '0.3rem',
    textAlign: 'left',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  button: {
    margin: theme.spacing(1),
  },
  primaryText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
    maxWidth: '8rem',
  },
  secondaryText: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    maxWidth: '8rem',
  },
}));

export default function CollapsibleCard({
  avatar,
  icon,
  primaryText,
  secondaryText,
  content,
  buttons,
  match,
}) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.cardStyle}>
      <CardActionArea onClick={handleExpandClick}>
        <CardHeader
          avatar={
            <Avatar aria-label="client-profile" src={avatar || null}>
              {icon || 'P'}
            </Avatar>
          }
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              size="large"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
          title={
            <Typography
              variant="h5"
              className={classes.primaryText}
              align="left"
              noWrap
            >
              {primaryText}
            </Typography>
          }
          subheader={
            <Typography
              variant="subtitle1"
              className={classes.secondaryText}
              align="left"
              noWrap
            >
              {secondaryText}
            </Typography>
          }
        />
      </CardActionArea>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ padding: '1.5rem', paddingBottom: 0 }}>
          {content && Object.keys(content).length > 0 ? (
            <Content content={content} match={match} />
          ) : null}
        </CardContent>

        {Array.isArray(buttons) ? (
          <CardActions>
            {buttons.map((item, i) => (
              <IconedButton {...item} match={match} key={i} />
            ))}
          </CardActions>
        ) : null}
      </Collapse>
    </Card>
  );
}
