import React from 'react';
import { makeStyles } from '@mui/styles';
import { Tab, Tabs, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  tabGroup: {
    display: 'flex',
    height: '100%',
    width: '100%',

    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .Mui-selected': {
      color: '#336fcc',
      borderColor: '#336fcc',
      backgroundColor: '#212733',
    },
    '& .Mui-flexContainer': {
      height: '100%',
    },
  },
  tab: {
    minWidth: '27px',
    minHeight: '100%',
    padding: 8,
    border: '1px solid #505154',
    backgroundColor: '#1B1C21',

    '&:first-of-type': {
      borderRadius: '4px 0px 0px 4px',
    },
    '&:last-of-type': {
      borderRadius: '0px 4px 4px 0px',
    },
  },
  label: {
    padding: 0,
    fontSize: '16px',
    fontWeight: '500',
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
  },
}));

function GraphTabs({ tabs, selectedTab, handleTabChange = () => {} }) {
  const classes = useStyles();
  return (
    <Tabs className={classes.tabGroup} value={selectedTab}>
      {Object.entries(tabs).map(([key, tabName]) => (
        <Tab
          className={classes.tab}
          key={key}
          value={tabName}
          label={<Typography className={classes.label}>{tabName}</Typography>}
          onClick={() => {
            handleTabChange(tabName);
          }}
        />
      ))}
    </Tabs>
  );
}

export default GraphTabs;
