import { DemoObjectives, DemoTasks } from '../models/demomodels/tasksDemo';

const initState = {
  objectives: DemoObjectives, // [],
  objectiveSelection: 0,
  tasks: DemoTasks,
  tasksOpen: {},
  selectedTask: null,
  recommendations: [],
  clients: [], // DemoDoctors,
  performanceObjectives: [], // DemoObjectives,
  selectedClient: null,
  selectedClientDetailed: null, // {... DemoFullClientData},
  selectedPerformance: null, // {... performanceItem},
  selectedPerformanceGroup: 0,
  selectedPerformanceObjective: 0,
  selectedPerformanceInterval: 0,
  clientObjectiveSelection: 0,
  recommendationSelection: 0,
  isBusy: false,
  errorMessage: '',
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case 'TASKS_GET_OBJECTIVES_PENDING':
    case 'TASKS_GET_PERFORMANCE_OBJECTIVES_PENDING':
    case 'TASKS_GET_PERFORMANCE_REPORT_PENDING':
    case 'TASKS_GET_CLIENTS_PENDING':
    case 'TASKS_GET_FULL_CLIENT_DATA_PENDING':
      return { ...state, isBusy: true, errorMessage: '' };

    case 'TASKS_GET_OBJECTIVES_REJECTED':
    case 'TASKS_GET_PERFORMANCE_REJECTED':
    case 'TASKS_GET_PERFORMANCE_REPORT_REJECTED':
    case 'TASKS_GET_CLIENTS_REJECTED':
    case 'TASKS_GET_FULL_CLIENT_DATA_REJECTED':
      return { ...state, isBusy: false, errorMessage: action.payload.Error };

    case 'TASKS_GET_OBJECTIVES_FULFILLED':
      return {
        ...state,
        isBusy: false,
        objectives: action.payload,
        objectiveSelection: 0,
      };
    case 'TASKS_GET_PERFORMANCE_OBJECTIVES_FULFILLED':
      return {
        ...state,
        isBusy: false,
        performanceObjectives: action.payload,
        selectedPerformanceObjective: 0,
      };
    case 'TASKS_GET_PERFORMANCE_REPORT_FULFILLED':
    case 'TASKS_RESET_PERFORMANCE_REPORT':
      return {
        ...state,
        isBusy: false,
        selectedPerformance: action.payload,
        selectedPerformanceInterval: 0,
        selectedPerformanceGroup: 0,
      };
    case 'TASKS_GET_CLIENTS_FULFILLED':
      return {
        ...state,
        isBusy: false,
        clients: action.payload,
        selectedClient: null,
        selectedClientDetailed: null,
      };
    case 'TASKS_GET_FULL_CLIENT_DATA_FULFILLED': {
      return {
        ...state,
        isBusy: false,
        selectedClientDetailed: action.payload,
      };
    }
    case 'TASKS_SELECT_OBJECTIVE': {
      if (
        Array.isArray(state.objectives) &&
        state.objectives.length > action.payload
      ) {
        return {
          ...state,
          objectiveSelection: action.payload,
          selectedTask: 0,
        };
      }
      return { ...state, objectiveSelection: 0, selectedTask: 0 };
    }
    case 'TASKS_SELECT_PERFORMANCE_OBJECTIVE': {
      if (action.payload === state.selectedPerformanceObjective) {
        return { ...state };
      }

      if (
        Array.isArray(state.objectives) &&
        state.objectives.length > action.payload
      ) {
        return {
          ...state,
          selectedPerformanceObjective: action.payload,
          selectedPerformance: null,
          selectedPerformanceInterval: 0,
          selectedPerformanceGroup: 0,
        };
      }
      return {
        ...state,
        selectedPerformanceObjective: 0,
        selectedPerformance: null,
        selectedPerformanceInterval: 0,
        selectedPerformanceGroup: 0,
      };
    }
    case 'TASKS_SELECT_CLIENT_OBJECTIVE': {
      if (
        state.selectedClientDetailed &&
        Array.isArray(state.selectedClientDetailed.clientGoals) &&
        state.selectedClientDetailed.clientGoals.length > action.payload
      ) {
        return { ...state, clientObjectiveSelection: action.payload };
      }
      return { ...state, clientObjectiveSelection: 0 };
    }
    case 'TASKS_TOGGLE_OPEN': {
      if (state.tasksOpen[action.payload]) {
        const newState = { ...state.tasksOpen };
        delete newState[action.payload];
        return { ...state, tasksOpen: { ...newState } };
      }
      return {
        ...state,
        tasksOpen: { ...state.tasksOpen, [action.payload]: true },
      };
    }
    case 'TASKS_SELECT_RECOMMENDATION':
      return { ...state, recommendationSelection: action.payload };
    case 'TASKS_SELECT_TASK':
      return {
        ...state,
        selectedTask: action.payload,
        recommendationSelection: 0,
      };
    case 'TASKS_SELECT_CLIENT':
      return {
        ...state,
        selectedClient: action.payload,
        clientObjectiveSelection: 0,
        selectedClientDetailed: null,
        recommendationSelection: 0,
      };
    case 'TASKS_SELECT_PERFORMANCE_INTERVAL':
      return {
        ...state,
        selectedPerformanceInterval: action.payload[0],
        selectedPerformanceGroup: action.payload[1],
      };
    default:
      return state;
  }
}
