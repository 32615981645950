import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { selectProfileSpecialties } from '../selectors/app';
import { AppContext } from '../containers/application/appContext';
import { getInternalAccountStatus } from '../request/config';

export const useRelevantUserSpecialties = () => {
  const { projectId } = useParams();
  const { projectList } = useContext(AppContext);

  const profileSpecialties = useSelector(selectProfileSpecialties);
  const project = projectList?.find((proj) => proj.projectId === projectId);
  const projectSpecialties = project ? project.specialties : [];

  const isInternalAccount = getInternalAccountStatus();
  if (isInternalAccount) {
    return projectSpecialties;
  }
  const res = profileSpecialties?.filter((s) =>
    projectSpecialties?.includes(s)
  );

  return res || [];
};

export default useRelevantUserSpecialties;
