import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import styled from '@emotion/styled';
import { RefreshConfirmationModal } from './refreshConfirmationModal';
import { PROJECT_REFRESH_MODAL_STATES } from './projectRefreshModalStates';
import {
  acceptProjectRefresh,
  initiateProjectRefresh,
  rejectProjectRefresh,
} from '../../../request/projectRefreshRequests';
import { themeColors } from '../../../styles/adminStyles';

const StyledLinearProgress = styled(LinearProgress)({
  margin: '36px 0px',
});

const EmphasisText = styled('span')({
  color: themeColors.adminCardHeader,
});

export const RefreshModalHandler = ({
  projectName,
  projectId,
  confirmationModalStatus,
  setConfirmationModalStatus,
}) => (
  <>
    <RefreshConfirmationModal
      isOpen={confirmationModalStatus === PROJECT_REFRESH_MODAL_STATES.REFRESH}
      onClose={() => {
        setConfirmationModalStatus('');
      }}
      body={
        <>
          <span>Are you sure you want to run a project refresh for </span>
          <EmphasisText>{projectName}</EmphasisText>
          <span>?</span>
        </>
      }
      titleText="Project Refresh Confirmation"
      onAccept={async () => {
        try {
          setConfirmationModalStatus(PROJECT_REFRESH_MODAL_STATES.LOADING);
          const res = await initiateProjectRefresh({
            projectId,
          });
          res.status = 200;
          if (res.status === 200) {
            setConfirmationModalStatus(
              PROJECT_REFRESH_MODAL_STATES.IN_PROGRESS
            );
          }
        } catch (error) {
          setConfirmationModalStatus(PROJECT_REFRESH_MODAL_STATES.ERROR);
        }
      }}
      acceptButtonText="Confirm and run refresh"
    />
    <RefreshConfirmationModal
      isOpen={
        confirmationModalStatus === PROJECT_REFRESH_MODAL_STATES.IN_PROGRESS
      }
      confirmationModalStatus={confirmationModalStatus}
      onClose={() => {
        setConfirmationModalStatus('');
      }}
      body={
        <>
          <span>Refresh of </span>
          <EmphasisText>{projectName}</EmphasisText>
          <span>
            is now in progress. You can accept the project refresh when it is
            available.
          </span>
        </>
      }
      showAcceptButton={false}
      titleText="Project Refresh In Progress"
    />
    <RefreshConfirmationModal
      isOpen={confirmationModalStatus === PROJECT_REFRESH_MODAL_STATES.SUCCESS}
      confirmationModalStatus={confirmationModalStatus}
      onClose={() => {
        setConfirmationModalStatus('');
      }}
      body={
        <>
          <EmphasisText>{projectName}</EmphasisText>
          <span>
            {' '}
            is refreshed with new data. You can now close this modal.
          </span>
        </>
      }
      showAcceptButton={false}
      titleText="Project Refreshed Successfully"
    />
    <RefreshConfirmationModal
      isOpen={confirmationModalStatus === PROJECT_REFRESH_MODAL_STATES.REJECTED}
      confirmationModalStatus={confirmationModalStatus}
      onClose={() => {
        setConfirmationModalStatus('');
      }}
      body={
        <>
          <span>Project Refresh of </span>
          <EmphasisText>{projectName}</EmphasisText>
          <span>
            {' '}
            was rejected. You can run another refresh from the project menu.
          </span>
        </>
      }
      showAcceptButton={false}
      titleText="Project Refresh Rejected"
    />
    <RefreshConfirmationModal
      isOpen={confirmationModalStatus === PROJECT_REFRESH_MODAL_STATES.ACCEPT}
      onClose={() => {
        setConfirmationModalStatus('');
      }}
      body={
        <>
          <span>Are you sure you want to accept the project refresh for </span>
          <EmphasisText>{projectName}</EmphasisText>
          <span>? You will not be able to undo this.</span>
        </>
      }
      titleText="Confirmation"
      onAccept={async () => {
        try {
          setConfirmationModalStatus(PROJECT_REFRESH_MODAL_STATES.LOADING);
          const res = await acceptProjectRefresh({
            projectId,
          });
          if (res.status === 200) {
            setConfirmationModalStatus(PROJECT_REFRESH_MODAL_STATES.SUCCESS);
          }
        } catch (error) {
          setConfirmationModalStatus(PROJECT_REFRESH_MODAL_STATES.ERROR);
        }
      }}
      acceptButtonText="Confirm and accept"
    />
    <RefreshConfirmationModal
      isOpen={confirmationModalStatus === PROJECT_REFRESH_MODAL_STATES.REJECT}
      onClose={() => {
        setConfirmationModalStatus('');
      }}
      body={
        <>
          <span>Are you sure you want to reject the project refresh for </span>
          <EmphasisText>{projectName}</EmphasisText>
          <span>? You will not be able to undo this.</span>
        </>
      }
      titleText="Confirmation"
      onAccept={async () => {
        try {
          setConfirmationModalStatus(PROJECT_REFRESH_MODAL_STATES.LOADING);
          const res = await rejectProjectRefresh({
            projectId,
          });
          if (res.status === 200) {
            setConfirmationModalStatus(PROJECT_REFRESH_MODAL_STATES.REJECTED);
          }
        } catch (error) {
          setConfirmationModalStatus(PROJECT_REFRESH_MODAL_STATES.ERROR);
        }
      }}
      acceptButtonText="Confirm and reject"
    />
    <RefreshConfirmationModal
      isOpen={confirmationModalStatus === PROJECT_REFRESH_MODAL_STATES.LOADING}
      confirmationModalStatus={confirmationModalStatus}
      onClose={() => {
        setConfirmationModalStatus('');
      }}
      body={
        <>
          <div>
            <span>Your request is in progress for </span>
            <EmphasisText>{projectName}.</EmphasisText>
            <span>This may take a few seconds.</span>
          </div>
          <StyledLinearProgress />
        </>
      }
      showAcceptButton={false}
      disableCloseButton
      titleText="Request in Progress"
    />
    <RefreshConfirmationModal
      isOpen={confirmationModalStatus === PROJECT_REFRESH_MODAL_STATES.ERROR}
      onClose={() => {
        setConfirmationModalStatus('');
      }}
      onClick={() => {
        setConfirmationModalStatus('');
      }}
      body={
        <>
          <span>There was an issue with your request for </span>
          <EmphasisText>{projectName}. </EmphasisText>
          <span>Please try again later.</span>
        </>
      }
      titleText="Request Error"
      showAcceptButton={false}
    />
  </>
);
