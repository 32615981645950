/* eslint-disable no-nested-ternary */
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/styles';
import { trackVolumeGraphSelection } from '../../../trackers/appEventTracker';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    margin: '5px 0px',
    rowGap: 10,
  },
  chip: {
    marginRight: 10,
    border: 'none',
  },
  chipText: {
    fontSize: 14,
    fontWeight: 400,
  },
}));

export default function ChipSelection({
  options,
  selected,
  onSelect,
  entityName = '',
}) {
  const classes = useStyles();
  const { themeColors } = useTheme();
  return (
    <div className={classes.root}>
      {Array.isArray(options)
        ? options.map((item, i) => (
            <div key={`${item.title}-${i}`}>
              <Chip
                className={`test-chip-selection ${classes.chip} intercom-scripts-volume-chip-${i}`}
                label={
                  <Typography
                    noWrap
                    className={classes.chipText}
                    style={{
                      color:
                        selected === i
                          ? themeColors.badgeActiveContentColor
                          : themeColors.badgePrimaryContentColor,
                      fontWeight: selected === i ? 600 : 400,
                    }}
                  >
                    {item.title || ''}
                  </Typography>
                }
                variant={selected === i ? 'default' : 'outlined'}
                onClick={(event) => {
                  onSelect(event, i);
                  trackVolumeGraphSelection(entityName, options[i].title);
                }}
                style={{
                  backgroundColor:
                    selected === i
                      ? item.productCategory === 'A'
                        ? themeColors.secondaryColor
                        : themeColors.badgeActiveColor
                      : themeColors.badgePrimaryColor,
                }}
                disabled={item.disabled}
              />
              <Typography
                style={{
                  fontSize: '12px',
                  color: themeColors.tertiaryColor,
                }}
              >
                {item.disabled ? 'Not Available*' : ''}
              </Typography>
            </div>
          ))
        : null}
    </div>
  );
}
