import { useMutation, useQueryClient } from 'react-query';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import { useConfigViewContext } from '../configViewContext';
import { updateProductTree } from '../../../request/configViewRequests';
import { ProductTreeView } from './productTreeView';
import { ConfigSectionTitle } from '../configViewTitle';
import { DEFAULT_TABS } from '../configTabs';
import { ProductTreeTitle } from './productTreeTitle';
import { DETAIL_DISPLAY } from '../detailDisplay';
import { CONFIG_ALERT_STATUSES } from '../configAlertStatuses';
import {
  useProductsQuery,
  useProductTreesQuery,
} from '../../../request/queries';
import { CenteredContentContainer } from '../styledComponents';

const MARKET_DETAILS_HEIGHT = 86;

const ProductTreeSectionBody = styled('div')(({ displayTreeDetails }) => ({
  height: `calc(100% - 70px ${
    displayTreeDetails === DETAIL_DISPLAY.SHOW
      ? `- ${MARKET_DETAILS_HEIGHT}px`
      : ''
  })`,
}));

export function ProductTreeProvider() {
  const queryClient = useQueryClient();
  const { showConfigAlert, productLineId } = useConfigViewContext();
  const [displayTreeDetails, setDisplayTreeDetails] = useState(
    DETAIL_DISPLAY.HIDE
  );
  const { data: productTrees = [], isLoading } = useProductTreesQuery();
  const { data: totalProductsList, isLoading: isLoadingProducts } =
    useProductsQuery();
  const { mutate: editExistingTree, isLoading: isTreeUpdating } = useMutation({
    mutationFn: ({ requestBody }) => updateProductTree(requestBody),
    onSuccess: async (data, variables) => {
      const { cleanupFunction, message } = variables;
      showConfigAlert(CONFIG_ALERT_STATUSES.SUCCESS, message);
      cleanupFunction();
      await queryClient.invalidateQueries({ queryKey: ['productTrees'] });
    },
    onError: () => {
      showConfigAlert(
        CONFIG_ALERT_STATUSES.ERROR,
        `Cannot create or save changes. Please try again`
      );
    },
  });
  const productLine = productTrees.find(
    (tree) => tree.productLineId === productLineId
  );

  if (isLoading || isLoadingProducts) {
    return (
      <CenteredContentContainer>
        <CircularProgress />
      </CenteredContentContainer>
    );
  }

  if (!totalProductsList) {
    return null;
  }
  return (
    <>
      {productLine ? (
        <ProductTreeTitle
          productLine={productLine}
          displayTreeDetails={displayTreeDetails}
          setDisplayTreeDetails={setDisplayTreeDetails}
        />
      ) : (
        <ConfigSectionTitle currentTabTitle={DEFAULT_TABS.PRODUCT_TREE} />
      )}
      <ProductTreeSectionBody displayTreeDetails={displayTreeDetails}>
        <ProductTreeView
          productLine={productLine}
          productTrees={productTrees}
          setDisplayTreeDetails={setDisplayTreeDetails}
          editExistingTree={editExistingTree}
          isTreeUpdating={isTreeUpdating}
          totalProductsList={totalProductsList}
        />
      </ProductTreeSectionBody>
    </>
  );
}
