import mixpanel from 'mixpanel-browser';
import { internalMixpanelToken, mixpanelToken } from '../request/config';
import { DEFAULT_CONTEXT } from './context';

let isMixpanelInitialized = false;
const eventQueue = [];

const sendEvent = (eventName, eventPayload) => {
  if (isMixpanelInitialized) {
    mixpanel.track(eventName, eventPayload);
  } else {
    eventQueue.push({ eventName, eventPayload });
    // eslint-disable-next-line no-console
    console.warn(
      `Attempted to send event ${eventName} but mixpanel is not initialized`,
      eventPayload,
      { eventQueue }
    );
  }
};

export function initializeMixpanel(options = {}, specialType = null) {
  if (isMixpanelInitialized) return;

  if (!specialType) {
    mixpanel.init(mixpanelToken);
  } else {
    mixpanel.init(internalMixpanelToken);
  }
  isMixpanelInitialized = true;
  const mixpanelProps = {
    ...options,
    $email: options.Email,
    $name: options.Name,
  };

  mixpanel.identify(mixpanelProps.userId);
  mixpanel.people?.set({ ...mixpanelProps });
  mixpanel.register({ ...mixpanelProps });

  eventQueue.forEach(({ eventName, eventPayload }) => {
    // eslint-disable-next-line no-console
    console.log('flushing event queue:', eventName);
    sendEvent(eventName, eventPayload);
  });
}

export function trackSignIn() {
  sendEvent('Sign In', { ...DEFAULT_CONTEXT });
}

export function trackAppSelection(app) {
  DEFAULT_CONTEXT['App Accessed'] = app;
  sendEvent('App Selected', {
    ...DEFAULT_CONTEXT,
    'App Selected Name': app,
  });
}

export function trackAppSelectionButton() {
  sendEvent('App Selection Button');
}

export function trackTooltipSelection(maptualList, location, context, kind) {
  sendEvent('Tooltip Selection', {
    ...DEFAULT_CONTEXT,
    'Maptual List Selection': maptualList,
    'Trigger Location': location,
    'Trigger Context': context,
    'Trigger Kind': kind,
  });
}

export function trackLearnMoreSelection(maptualList, location, context) {
  sendEvent('Intercom Learn More', {
    ...DEFAULT_CONTEXT,
    'Maptual List Selection': maptualList,
    'Trigger Location': location,
    'Trigger Context': context,
  });
}

export function trackSegmentFilterSelection(
  maptualList,
  selection,
  action,
  selectedFilters
) {
  sendEvent('Filter Selection', {
    ...DEFAULT_CONTEXT,
    'Maptual List Selection': maptualList,
    'Segment/List': selection,
    Action: action,
    'Recent/Predicted Filter':
      selectedFilters?.segment?.length === 1 ? selectedFilters.segment : 'All',
    'Not Contacted Filter': selectedFilters.lastContacted?.label || 'Off',
    'Starred Filter': selectedFilters.starred ? 'On' : 'Off',
    'No-See Filter': selectedFilters.noSee ? 'On' : 'Off',
    'Long-Term Leave Filter': selectedFilters.longTermLeave ? 'On' : 'Off',
    'Specialty Filter': selectedFilters.specialties || 'None',
  });
}

export function trackObjectiveSelected(
  objectiveName,
  defaultLoad,
  actionSource
) {
  sendEvent('Objective Selected', {
    ...DEFAULT_CONTEXT,
    'Objective Name': objectiveName,
    'Default Load': defaultLoad,
    'Action Source': actionSource,
  });
}

export function trackAddToCustomListSelection(
  maptualList,
  entityName,
  listSelection,
  option,
  actionType,
  actionSource
) {
  sendEvent('Added to Custom List', {
    ...DEFAULT_CONTEXT,
    'Maptual List Selection': maptualList,
    'Entity Name': entityName,
    'Segment/List': listSelection,
    Option: option,
    'Action Type': actionType,
    'Action Source': actionSource,
  });
}

export function trackCustomListSelection(customList, maptualList) {
  sendEvent('Custom List Selection', {
    ...DEFAULT_CONTEXT,
    'Maptual List Selection': maptualList,
    'List Selected': customList,
  });
}

export function trackSphereSegmentSelection(segment = {}) {
  sendEvent('Segment Selection', {
    ...DEFAULT_CONTEXT,
    'Segment Name': segment?.segmentName,
    'Segment Friendly Name': segment?.primaryText,
    'Parent Segment Name':
      segment?.parentSegmentName === 'None'
        ? 'NULL'
        : segment?.parentSegmentName,
  });
}

export function trackSphereVolumeGraphSelection({
  graphType,
  graphSelection,
  segmentName,
  maptualListSelectionLabel,
}) {
  sendEvent('Volume Graph Selection', {
    ...DEFAULT_CONTEXT,
    'Volume Graph Selection': graphSelection,
    'Volume Graph Type': graphType,
    'Segment Name': segmentName,
    'Maptual List Selection': maptualListSelectionLabel,
  });
}

export function trackSphereShareGraphDropDownSelection({
  dropdownSelection,
  segmentName,
  maptualListSelectionLabel,
}) {
  sendEvent('Share Graph DropDown Selection', {
    ...DEFAULT_CONTEXT,
    'Share Graph DropDown Selection': dropdownSelection,
    'Segment Name': segmentName,
    'Maptual List Selection': maptualListSelectionLabel,
  });
}

export function mixpanelSphereSegmentCategory(segment = {}) {
  sendEvent('Segment Category Selection', {
    ...DEFAULT_CONTEXT,
    'Segment Name': segment?.segmentName,
    'Segment Friendly Name': segment?.primaryText,
    'Segment Expansion State': segment?.isExpanded ? 'Expanded' : 'Collapsed',
    'Parent Segment Name':
      segment?.parentSegmentName === 'None'
        ? 'NULL'
        : segment?.parentSegmentName,
  });
}

export function trackSphereSearch(searchTerm) {
  sendEvent('Search', { ...DEFAULT_CONTEXT, 'Search Term': searchTerm });
}

export function trackSphereTableViewExport(exportConfig = {}) {
  sendEvent('Table View Data Export', {
    ...DEFAULT_CONTEXT,
    'Export Type': exportConfig.exportType,
    'Segment Name': exportConfig.segmentName,
    'Maptual List Selection': exportConfig.maptualListSelectionLabel,
  });
}

export function trackSphereAttributionChannelSelection(attributionConfig = {}) {
  sendEvent('Attribution Channel Selection', {
    ...DEFAULT_CONTEXT,
    'Segment Name': attributionConfig.segmentName,
    'Maptual List Selection': attributionConfig.maptualListSelectionLabel,
    'Attribution Graph DropDown Selection': attributionConfig.dropdownSelection,
    'Channels Engaged': attributionConfig.engagedChannels,
    'Channels Disengaged': attributionConfig.disengagedChannels,
  });
}

export function trackSphereColumnVisibilityToggle(columnVisibility = {}) {
  sendEvent('Table View Column Selection', {
    ...DEFAULT_CONTEXT,
    'Column Name': columnVisibility.columnName,
    'Column Selection State': columnVisibility.isVisible
      ? 'Selected'
      : 'Deselected',
    'Segment Name': columnVisibility.segmentName,
    'Maptual List Selection': columnVisibility.maptualListSelectionLabel,
  });
}

export function trackTimeframeSelection(timeframeOptions = {}) {
  sendEvent('Metric Component Timeframe Selection', {
    ...DEFAULT_CONTEXT,
    'Metric Component Type': timeframeOptions.metricComponentType, // 'scriptsData' | 'shareData' | 'attributionData'
    'Timeframe Selection': timeframeOptions.timeframeSelectionName,
    'Entity Name': timeframeOptions.entityName, // FIELD only
    'Segment Name': timeframeOptions.segmentName,
    'Maptual List Selection': timeframeOptions.maptualListSelectionLabel,
  });
}

export function trackTimeframeTableRowSelection(timeframeOptions = {}) {
  sendEvent('Metric Component Timeframe Table Row Selection', {
    ...DEFAULT_CONTEXT,
    'Metric Component Type': timeframeOptions.metricComponentType, // 'scriptsData' | 'shareData' | 'attributionData'
    'Table Row Selection': timeframeOptions.primaryLegendTitle,
    'Timeframe Selection': timeframeOptions.timeframeSelectionName,
    'Entity Name': timeframeOptions.entityName, // FIELD only
    'Segment Name': timeframeOptions.segmentName,
    'Maptual List Selection': timeframeOptions.maptualListSelectionLabel,
  });
}

export function trackTerritoryOverviewOpened(source) {
  sendEvent('Territory Overview Opened', {
    ...DEFAULT_CONTEXT,
    'Action Source': source,
  });
}

export function trackTerritoryOverviewTooltip(tooltipName, optionContext) {
  sendEvent('Territory Overview View Tooltip', {
    ...DEFAULT_CONTEXT,
    'Tooltip Name': tooltipName,
    'Option Context': optionContext,
  });
}

export function trackTerritoryOverviewOptionSet(
  optionName,
  optionSetTo,
  optionSetFrom,
  optionContext,
  defaultLoad
) {
  sendEvent('Territory Overview Option Set', {
    ...DEFAULT_CONTEXT,
    'Option Name': optionName,
    'Option Value Set To': optionSetTo,
    'Option Value Set From': optionSetFrom,
    'Option Context': optionContext,
    'Default Load': defaultLoad,
  });
}

export function trackTerritoryOverviewEntityDataVisualized(
  optionContext,
  action,
  entityName,
  dataType,
  defaultLoad
) {
  sendEvent('Territory Overview Entity Data Visualized', {
    ...DEFAULT_CONTEXT,
    'Option Context': optionContext,
    Action: action,
    'Entity Name': entityName,
    'Data Type': dataType,
    'Default Load': defaultLoad,
  });
}

export function trackPreCallInsightFeedback({
  userInput,
  entityId,
  project,
  objective,
  insight,
}) {
  sendEvent('Pre-Call Insights User Feedback', {
    ...DEFAULT_CONTEXT,
    distinct_id: entityId,
    'Project Name': project?.metadata?.projectName,
    'Project ID': project?.metadata?.projectId,
    'Objective Name': objective.objectiveDescription,
    'Objective ID': objective.objectiveId,
    'Insight ID': insight?.id,
    'Insight Type': insight.type,
    'User Input': userInput,
  });
}

export function trackPulseTeamView({
  userGroup,
  marketName,
  objectiveName,
  districtName,
}) {
  sendEvent('Pulse Team Page View', {
    ...DEFAULT_CONTEXT,
    Company: userGroup,
    Business: marketName,
    Objective: objectiveName,
    District: districtName,
  });
}

export function trackPulseRegionView({
  userGroup,
  marketName,
  objectiveName,
  districtName,
  regionName,
}) {
  sendEvent('Pulse Region Page View', {
    ...DEFAULT_CONTEXT,
    Company: userGroup,
    Business: marketName,
    Objective: objectiveName,
    District: districtName,
    Region: regionName,
  });
}
