/* eslint-disable no-empty */
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';
import {
  initializeReactGA,
  trackProjectSelection as trackProjectSelectionGA,
  trackTabNavigation as trackTabNavigationGA,
  trackSecondaryTabNavigation as trackSecondaryTabNavigationGA,
  trackUser as trackUserGA,
} from './googleAnalytics';
import {
  initializeMixpanel,
  mixpanelSphereSegmentCategory,
  trackSphereSegmentSelection as mixpanelSphereSegment,
  trackSphereSearch as mixpanelTrackSphereSearch,
  trackSphereShareGraphDropDownSelection as mixpanelTrackSphereShareGraphDropDownSelection,
  trackSphereVolumeGraphSelection as mixpanelTrackSphereVolumeGraphSelection,
  trackAppSelection as trackAppSelectionMixpanel,
  trackTimeframeSelection as mixpanelTrackTimeframeSelection,
  trackTimeframeTableRowSelection as mixpanelTrackTimeframeTableRowSelection,
} from './mixpanel';
import { DEFAULT_CONTEXT, isSphereView } from './context';
import { baseURL, intercomAppId } from '../request/config';

export const initializeAppTracking = ({ profile, maptualAccess }) => {
  window.Intercom('boot', {
    app_id: intercomAppId,
    email: profile.email ?? '',
    user_hash: profile.intercomHMAC,
  });

  const { userId, name, fullName, email, role, organization } = profile;

  initializeTrackers(
    {
      userId,
      Name: name,
      'Full Name': fullName,
      Email: email,
      Group: role ? role.group : 'UNKNOWN',
      Department: role ? role.department : 'UNKNOWN',
      Role: role ? role.role : 'UNKNOWN',
      Organization: organization ? organization.organizationName : 'UNKNOWN',
      Environment: baseURL,
      'App Permissions': maptualAccess,
    },
    profile.role?.specialType
  );
};

export function initializeTrackers(options = {}, specialType = null) {
  initializeReactGA(options);
  initializeMixpanel(options, specialType);
}

export function trackProjectSelection(projectId) {
  trackProjectSelectionGA(projectId);
}

// track clicks on feature tabs on the left
export function trackTabNavigation(tabName) {
  trackTabNavigationGA(tabName);
}

// track clicks on tabs within a feature (trends & segments vs attribution & audience)
export function trackSecondaryTabNavigation(tabName) {
  trackSecondaryTabNavigationGA(tabName);
}

export function trackSignInOrOut(value) {
  ReactGA.event({
    category: 'sign_in_or_out',
    action: value,
  });
}

export function trackUser(userName) {
  trackUserGA(userName);
}

// ============== AFTER LOGIN TRACKING ====================
export function trackAppSelection(app) {
  trackAppSelectionMixpanel(app);
}

export function trackObjectiveSelection(objectiveName) {
  try {
    ReactGA.event({
      category: 'fieldview_objective_selection',
      action: objectiveName,
    });
  } catch (error) {}

  try {
    mixpanel.track('Objective Selection', {
      ...DEFAULT_CONTEXT,
      'Objective Name': objectiveName,
    });
  } catch (error) {}
}

export function trackMaptualListSelection(listName) {
  try {
    ReactGA.event({
      category: 'fieldview_maptual_list_selection',
      action: listName,
    });
  } catch (error) {}

  try {
    mixpanel.track('Maptual List Selection', {
      ...DEFAULT_CONTEXT,
      'Objective Name': listName,
    });
  } catch (error) {}
}

export function trackEntitySelection(entityName, score = '', scoreChange = '') {
  try {
    ReactGA.event({
      category: 'fieldview_entity_selection',
      action: `score ${score}`,
      label: entityName,
    });
    ReactGA.event({
      category: 'fieldview_entity_selection',
      action: `score_change ${scoreChange}`,
      label: entityName,
    });
  } catch (error) {}

  try {
    mixpanel.track('Entity Selection', {
      ...DEFAULT_CONTEXT,
      'Entity Name': entityName,
      'Entity Score': score,
      'Entity Score Changed': scoreChange,
    });
  } catch (error) {}
}

export function trackSearch(searchTerm) {
  try {
    ReactGA.event({
      category: 'fieldview_search',
      action: searchTerm,
    });
  } catch (error) {}

  try {
    mixpanel.track('Search', { ...DEFAULT_CONTEXT, 'Search Term': searchTerm });
  } catch (error) {}
}

export function trackSegmentSelection(segment, maptualList) {
  try {
    ReactGA.event({
      category: 'fieldview_segment_selection',
      action: segment,
    });
  } catch (error) {}

  try {
    mixpanel.track('Segment Selection', {
      ...DEFAULT_CONTEXT,
      Segment: segment,
      'Maptual List Selection': maptualList,
    });
  } catch (error) {}
}

export function trackSphereSegmentSelection(segment) {
  mixpanelSphereSegment(segment);
}

export function trackSphereSegmentCategory(segment) {
  mixpanelSphereSegmentCategory(segment);
}

export function trackSphereSearch(searchTerm) {
  mixpanelTrackSphereSearch(searchTerm);
}

export function trackJourneySelection(entityName) {
  try {
    ReactGA.event({
      category: 'fieldview_journey_selection',
      label: entityName,
    });
  } catch (error) {}
  try {
    mixpanel.track('Journey Selection', {
      ...DEFAULT_CONTEXT,
      'Entity Name': entityName,
    });
  } catch (error) {}
}

export function trackGoogleMapSelection(entityName, address = '') {
  try {
    ReactGA.event({
      category: 'fieldview_googlemap_selection',
      label: entityName,
      action: `address: ${address}`,
    });
  } catch (error) {}

  try {
    mixpanel.track('Google Map Selection', {
      ...DEFAULT_CONTEXT,
      'Entity Name': entityName,
      'Entity Address': address,
    });
  } catch (error) {}
}

export function trackEntityProfileDetailSelection(entityName) {
  try {
    ReactGA.event({
      category: 'fieldview_profiledetail_selection',
      label: entityName,
    });
  } catch (error) {}

  try {
    mixpanel.track('Profile Detail Selection', {
      ...DEFAULT_CONTEXT,
      'Entity Name': entityName,
    });
  } catch (error) {}
}

const humanizedGraphTypeMap = {
  SCRIPTS_DATA: 'Total Scripts',
  TOTAL_SCRIPTS_DATA: 'Total Scripts',
  AVERAGE_SCRIPTS_DATA: 'Average Scripts',
};
export function trackSphereVolumeGraphSelection({
  graphType,
  graphSelection,
  segmentName,
  maptualListSelectionLabel,
}) {
  mixpanelTrackSphereVolumeGraphSelection({
    graphType: humanizedGraphTypeMap[graphType] || 'Other',
    graphSelection,
    segmentName,
    maptualListSelectionLabel,
  });
}

function trackSphereShareGraphDropDownSelection({
  dropdownSelection,
  segmentName,
  maptualListSelectionLabel,
}) {
  mixpanelTrackSphereShareGraphDropDownSelection({
    dropdownSelection,
    segmentName,
    maptualListSelectionLabel,
  });
}

export function trackShareGraphDropDownSelection({
  entityName,
  selection = '',
  sphereOptions = {},
}) {
  try {
    ReactGA.event({
      category: 'fieldview_sharegraph_dropdown_selection',
      label: entityName,
      action: `selection: ${selection}`,
    });
  } catch (error) {}

  try {
    if (isSphereView()) {
      trackSphereShareGraphDropDownSelection(sphereOptions);
    } else {
      mixpanel.track('Share Graph DropDown Selection', {
        ...DEFAULT_CONTEXT,
        'Entity Name': entityName,
        'Share Graph DropDown Selection': selection,
      });
    }
  } catch (error) {}
}

export function trackVolumeGraphSelection(entityName, selection = '') {
  try {
    ReactGA.event({
      category: 'fieldview_volumegraph_selection',
      label: entityName,
      action: `selection: ${selection}`,
    });
  } catch (error) {}

  try {
    mixpanel.track('Volume Graph Selection', {
      ...DEFAULT_CONTEXT,
      'Entity Name': entityName,
      'Volume Graph Selection': selection,
    });
  } catch (error) {}
}

export function trackCSVDownloadButtonClick() {
  try {
    ReactGA.event({
      category: 'fieldview_csv_download_button_click',
      action: 'click',
    });
  } catch (error) {}

  try {
    mixpanel.track('CSV Download Button Click', {
      ...DEFAULT_CONTEXT,
    });
  } catch (error) {}
}

export function trackTimeframeSelection(timeframeOptions) {
  mixpanelTrackTimeframeSelection(timeframeOptions);
}

export function trackTimeframeTableRowSelection(timeframeOptions) {
  mixpanelTrackTimeframeTableRowSelection(timeframeOptions);
}
