import { useQuery } from 'react-query';
import { useContext } from 'react';
import * as requests from '../../../request/projectRequests';
import useMaptualContext from '../../../hooks/useMaptualContext';
import { getDemoAccountStatus } from '../../../request/config';
import { AppContext } from '../../../containers/application/appContext';

const getCacheKey = (object) => Object.values(object);

export const censorConnectionsResponse = (connections) => {
  if (!getDemoAccountStatus()) return connections;

  return connections.map((connectionEntity) => ({
    ...connectionEntity,
    primaryText: `Entity Name ${connectionEntity.entityId}`,
  }));
};

export default function useConnectionsData(extraParams = {}) {
  const { entityId, maptualListId, projectId } = useMaptualContext();
  const { projectList } = useContext(AppContext);

  const productLineId = projectList.find(
    (proj) => proj.projectId === projectId
  )?.productLineId;

  const params = {
    key: 'connections',
    entityId,
    maptualListId,
    projectId,
    productLineId,
  };

  return useQuery(
    getCacheKey(params),
    async () => {
      const response = await requests.getConnections(params);

      return censorConnectionsResponse(response.data?.connections);
    },
    { ...extraParams }
  );
}
