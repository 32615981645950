/* eslint-disable no-restricted-syntax */
import React from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { CustomTooltip } from '../../application/appViews/pulse/shared/Tooltip';

export const Column = styled(Box)(
  ({
    width,
    marginBottom,
    theme: { themeColors },
    flexDirection = 'column',
  }) => ({
    display: 'flex',
    flexDirection,
    width,
    marginBottom: marginBottom ? 16 : 0,
    textAlign: 'left',
    color: themeColors.tableHeaderContentColor,
    fontSize: '14px',
    alignItems: 'start',
    gap: '4px',
  })
);

export const ColumnTitle = styled('div')((props) => ({
  color: props.theme.themeColors.tableHeaderContentColorHighlight,
  textTransform: 'capitalize',
}));

export const ColumnSubTitle = styled('div')((props) => ({
  color: props.theme.themeColors.tableHeaderContentColor,
}));

export const ColumnContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  gap: 48,
}));

export const StyledMessage = styled(Typography)(() => ({
  marginTop: '8px',
  ':first-child': {
    marginTop: 0,
  },
}));

const StickyColumnContainer = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  gap: 48,
  position: 'sticky',
  zIndex: 1,
  backgroundColor: themeColors.cardBackgroundColor,
  height: 164,
  paddingLeft: 16,
  left: 0,
  paddingBottom: 16,
}));

export const StyledIcon = styled(ErrorOutlineOutlinedIcon)(() => ({
  width: '14px',
  height: '14px',
}));

export const MetricColumns = ({ metricColumns, objectiveBasketName }) => {
  const res = [];
  for (const column of metricColumns) {
    // eslint-disable-next-line no-continue
    if (!column.visible) continue;
    res.push(
      <Column width={column.width} key={`${column.id}-${objectiveBasketName}`}>
        <ColumnTitle>{objectiveBasketName}</ColumnTitle>
        <ColumnSubTitle>{column.sublabel}</ColumnSubTitle>
      </Column>
    );
  }
  return <ColumnContainer>{res}</ColumnContainer>;
};

export const InitialColumns = ({ cols }) => {
  const res = [];
  for (const column of cols) {
    // eslint-disable-next-line no-continue
    if (!column.visible) continue;
    res.push(
      <Column width={column.width} key={`${column.id}`} flexDirection="row">
        <ColumnSubTitle>{column.label}</ColumnSubTitle>
        {column.message && (
          <CustomTooltip
            title={column.label}
            message={column.message}
            placement="bottom-start"
          >
            <ColumnSubTitle>
              {column.subLabel} <StyledIcon />
            </ColumnSubTitle>
          </CustomTooltip>
        )}
      </Column>
    );
  }
  return <StickyColumnContainer>{res}</StickyColumnContainer>;
};

export default function ActivityColumns({ activityColumns }) {
  return (
    <ColumnContainer>
      {activityColumns.map((column) => (
        <Column width={column.width} key={column.id}>
          <ColumnTitle>{column.label}</ColumnTitle>
          {column.message && (
            <CustomTooltip
              title={column.label}
              message={column.message}
              placement="bottom-start"
            >
              <ColumnSubTitle>
                {column.subLabel} <StyledIcon />
              </ColumnSubTitle>
            </CustomTooltip>
          )}
          {!column.message && (
            <ColumnSubTitle>{column.subLabel}</ColumnSubTitle>
          )}
        </Column>
      ))}
    </ColumnContainer>
  );
}
