import { useEffect, useState, useContext } from 'react';

import { getDemoAccountStatus } from '../../request/config';
import { getTimeframeName } from './utils';
import { Distribution, DISTRIBUTION_CONFIG } from './constants';
import { useTerritoryOverviewData } from './useTerritoryOverviewData';
import { trackTerritoryOverviewOptionSet } from '../../trackers/mixpanel';
import { FieldContext } from '../application/appViews/field/fieldContext';

export const useFieldTerritoryOverviewContext = () => {
  const {
    project: projectItem,
    maptualListMetadata,
    isMaptualListsLoading,
  } = useContext(FieldContext);
  const { projectId } = projectItem.metadata || {};

  const projectName = projectItem?.metadata?.projectName || '';
  const maptualListId = maptualListMetadata?.maptualListId;
  const territoryName = maptualListMetadata?.listName;

  const isDemoAccount = getDemoAccountStatus();

  const [metricOptions, setMetricOptions] = useState();
  const [selectedMetric, setSelectedMetric] = useState();

  const [categoryOptions, setCategoryOptions] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const updateSelectedCategory = (newCategory) => {
    trackTerritoryOverviewOptionSet(
      DISTRIBUTION_CONFIG[distributionType]?.dropdownLabel,
      categoryOptions[newCategory].label,
      categoryOptions[selectedCategory]?.label,
      [
        getTimeframeName(dataResponse, selectedTimeframeID),
        distributionType,
        categoryOptions[newCategory].label,
        metricOptions[selectedMetric]?.label,
      ],
      false
    );

    setSelectedCategory(newCategory);
  };

  const [distributionType, setDistributionType] = useState('');

  const updateDistributionType = (newDistributionType) => {
    trackTerritoryOverviewOptionSet(
      'View',
      newDistributionType,
      distributionType,
      [
        getTimeframeName(dataResponse, selectedTimeframeID),
        newDistributionType,
        categoryOptions[selectedCategory]?.label,
        metricOptions[selectedMetric]?.label,
      ],
      false
    );
    setDistributionType(newDistributionType);
  };

  const [selectedTimeframeID, setSelectedTimeframeID] = useState('month');

  const {
    dataResponse,
    setDataResponse,
    status,
    metadataResponse,
    metadataStatus,
    isDataLoading,
    isMetadataLoading,
  } = useTerritoryOverviewData({
    distributionType,
    setDistributionType,
    categoryOptions,
    setCategoryOptions,
    projectId,
    maptualListId,
    metricOptions,
    setMetricOptions,
    setSelectedCategory,
    setSelectedMetric,
    setSelectedTimeframeID,
    selectedMetric,
    selectedCategory,
  });

  useEffect(() => {
    if (categoryOptions?.length > 0) {
      setSelectedCategory(0);
    }
  }, [categoryOptions]);

  useEffect(() => {
    if (categoryOptions?.length > 0) {
      const options = categoryOptions[selectedCategory]?.metrics;
      const cadences = options[0]?.cadences;

      setMetricOptions(options);
      setSelectedMetric(0);

      if (cadences && cadences.length > 0) {
        setTimeframeOptionsAvailable(cadences);
      }
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (dataResponse && distributionType && categoryOptions && metricOptions) {
      trackTerritoryOverviewOptionSet(
        '',
        '',
        '',
        [
          getTimeframeName(dataResponse, selectedTimeframeID),
          distributionType,
          categoryOptions[selectedCategory]?.label,
          metricOptions[selectedMetric]?.label,
        ],
        true
      );
    }
  }, [dataResponse]);

  const [selectedSupplyRows, setSelectedSupplyRows] = useState([]);
  const updateSelectedSupplyRows = (newSelection) =>
    setSelectedSupplyRows(newSelection);

  const [distributionTypesAvailable, setDistributionTypesAvailable] = useState({
    supply: false,
    demand: false,
  });

  useEffect(() => {
    setDistributionTypesAvailable({
      supply: metadataResponse?.data?.availableDistributions?.includes(
        Distribution.SUPPLY
      ),
      demand: metadataResponse?.data?.availableDistributions?.includes(
        Distribution.DEMAND
      ),
    });
  }, [metadataResponse?.data]);

  const [timeframeOptionsAvailable, setTimeframeOptionsAvailable] = useState([
    'month',
    'quarter',
  ]);

  const updateAvailableTimeframes = (newMetric) => {
    const newAvailableTimeframes = metricOptions[newMetric]?.cadences;

    if (newAvailableTimeframes && newAvailableTimeframes.length > 0) {
      setTimeframeOptionsAvailable(newAvailableTimeframes);
    }
  };

  const updateSelectedMetric = (newMetric) => {
    trackTerritoryOverviewOptionSet(
      'Metric',
      metricOptions[newMetric].label,
      metricOptions[selectedMetric]?.label,
      [
        getTimeframeName(dataResponse, selectedTimeframeID),
        distributionType,
        categoryOptions[selectedCategory]?.label,
        metricOptions[newMetric].label,
      ],
      false
    );

    setSelectedMetric(newMetric);
    updateAvailableTimeframes(newMetric);
  };

  return {
    projectId,
    regionId: maptualListId,
    projectName: isDemoAccount ? 'Target Project Name' : projectName,
    territoryName: isDemoAccount ? 'Territory Name' : territoryName,
    data: dataResponse,
    setDataResponse,
    metadata: metadataResponse,
    metadataStatus,
    metricOptions,
    categoryOptions,
    status,
    isDataLoading,
    isMetadataLoading: isMetadataLoading || isMaptualListsLoading,
    selectedCategory,
    updateSelectedCategory,
    selectedMetric,
    updateSelectedMetric,
    selectedTimeframeID,
    setSelectedTimeframeID,
    selectedSupplyRows,
    updateSelectedSupplyRows,
    distributionType,
    updateDistributionType,
    distributionTypesAvailable,
    timeframeOptionsAvailable,
  };
};
