import React, { useMemo, useContext } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import ModeIcon from '@mui/icons-material/Mode';
import ObjectiveTypeEdit from './objectiveTypeEdit';
import { CONFIG_VIEW_MODE } from './userGroupConfigConstants';
import { ConfigViewContext } from './configViewContext';
import { useIsAuthorized } from '../../hooks/useIsAuthorized';
import { RBAC_PERMISSIONS } from '../../constants';

const TableContainer = styled('div')({
  height: '100%',
});

const ObjectiveTypeContainer = styled('div')(
  ({
    removeDividerLine,
    isBeingEdited,
    setLowOpacity,
    theme: { themeColors },
  }) => {
    const borderBottomStyle = removeDividerLine
      ? 'none'
      : `1px solid ${themeColors.borderLowContrast}`;
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '24px 24px 36px',
      border: isBeingEdited
        ? `2px solid ${themeColors.userConfigEditHighlight}`
        : 'none',
      borderBottom: isBeingEdited
        ? `2px solid ${themeColors.userConfigEditHighlight}`
        : borderBottomStyle,
      opacity: setLowOpacity ? 0.5 : 1,
    };
  }
);

const ObjectiveTypeHeader = styled('div')(({ theme: { themeColors } }) => ({
  marginTop: '2px',
  marginBottom: '8px',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '135%',
  color: themeColors.primary99,
}));

const ObjectiveTypeDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  alignItems: 'flex-start',
});

const Label = styled('div')(({ theme: { themeColors } }) => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '120%',
  color: themeColors.neutral60,
}));

const ObjectiveTypeViewWrapper = styled(Box)({
  width: '100%',
  textAlign: 'left',
  overflowY: 'scroll',
  height: '100%',
});

const TitleWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 16,
}));

const SecondaryText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
}));

const StyledEditButton = styled(Button)(({ theme: { themeColors } }) => ({
  color: themeColors.objectiveTypeTextButtonColor,
  '&:hover': {
    color: themeColors.objectiveTypeEditButtonColor,
    cursor: 'pointer',
  },
}));

const StyledEditIcon = styled(ModeIcon)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
  fontSize: '16px',
  marginLeft: '4px',
}));

const StyledDataGrid = styled(DataGridPremium)(
  ({ theme: { themeColors } }) => ({
    border: `1px solid ${themeColors.borderLowContrast}`,
    borderRadius: 4,
    '.MuiDataGrid-columnHeaderTitleContainer': {
      paddingLeft: '6px',
    },
    '.MuiDataGrid-cell': {
      paddingLeft: '16px',
    },
  })
);

function serializeObjectiveTypeDataGridRows(objectiveTypes) {
  return objectiveTypes.map((objectiveType) =>
    objectiveType.params.products.map((product, index) => ({
      productFriendlyName: product.productFriendlyName,
      paramText: product.paramText,
      paramType: product.paramType,
      id: index,
    }))
  );
}

const ObjectiveTypeViewEntry = ({
  objectiveType,
  setObjectiveTypeToBeingEdited,
  basketInformationGridRows,
}) => {
  const { configViewMode, setConfigViewMode } = useContext(ConfigViewContext);
  const hasConfigAdminPermissions = useIsAuthorized([
    RBAC_PERMISSIONS.USER_GROUP_CONFIG_ADMIN,
  ]);
  const basketInformationViewColumnConfig = () => {
    const columnCommonProperties = {
      flex: 1,
      editable: false,
    };
    const columnConfig = [
      {
        field: 'productFriendlyName',
        headerName: 'Product Friendly Name',
        ...columnCommonProperties,
      },
      {
        field: 'paramText',
        headerName: 'ParamText',
        ...columnCommonProperties,
      },
      {
        field: 'paramType',
        headerName: 'ParamType',
        ...columnCommonProperties,
      },
    ];
    return columnConfig;
  };

  return (
    <>
      <TitleWrapper>
        <ObjectiveTypeHeader>{objectiveType.typeDisplay}</ObjectiveTypeHeader>
        <Box>
          {hasConfigAdminPermissions && (
            <StyledEditButton
              onClick={() => {
                setObjectiveTypeToBeingEdited();
                setConfigViewMode(CONFIG_VIEW_MODE.EDIT);
              }}
              disabled={configViewMode !== CONFIG_VIEW_MODE.VIEW}
            >
              <SecondaryText variant="body2">Edit</SecondaryText>
              <StyledEditIcon />
            </StyledEditButton>
          )}
        </Box>
      </TitleWrapper>
      <ContentWrapper>
        <ObjectiveTypeDetails>
          <Label>Type: {objectiveType.type}</Label>
          <Label>Type Display: {objectiveType.typeDisplay}</Label>
          <Label>Type Description: {objectiveType.typeDescription}</Label>
        </ObjectiveTypeDetails>
        <TableContainer>
          <StyledDataGrid
            rows={basketInformationGridRows}
            columns={basketInformationViewColumnConfig()}
            hideFooter
            hideFooterRowCount
          />
        </TableContainer>
      </ContentWrapper>
    </>
  );
};

export const ObjectiveTypesView = ({
  objectiveTypes,
  objectiveTypeBeingEdited,
  setObjectiveTypeBeingEdited,
  removeAddObjectiveType,
  onSaveEditObjectiveType,
  onSaveAddObjectiveType,
  isEditObjectiveTypeSaving,
  isAddObjectiveTypeSaving,
}) => {
  const lastObjectiveTypeIndex = useMemo(
    () => Array.from(objectiveTypes).length - 1,
    [objectiveTypes]
  );
  const objectiveTypesBasketInformationRows = useMemo(
    () => serializeObjectiveTypeDataGridRows(objectiveTypes),
    [objectiveTypes]
  );
  const { configViewMode } = useContext(ConfigViewContext);

  return (
    <ObjectiveTypeViewWrapper>
      {objectiveTypes.map((objectiveType, i) => (
        <ObjectiveTypeContainer
          key={i}
          removeDividerLine={
            i === lastObjectiveTypeIndex &&
            objectiveType.objectiveTypeId !== objectiveTypeBeingEdited
          }
          isBeingEdited={
            objectiveType.objectiveTypeId === objectiveTypeBeingEdited
          }
          setLowOpacity={
            configViewMode !== CONFIG_VIEW_MODE.VIEW &&
            objectiveType.objectiveTypeId !== objectiveTypeBeingEdited
          }
        >
          {objectiveType.objectiveTypeId === objectiveTypeBeingEdited ? (
            <ObjectiveTypeEdit
              objectiveType={objectiveType}
              basketInformationGridRows={objectiveTypesBasketInformationRows[i]}
              removeAddObjectiveType={removeAddObjectiveType}
              onSaveEditObjectiveType={onSaveEditObjectiveType}
              onSaveAddObjectiveType={onSaveAddObjectiveType}
              isEditObjectiveTypeSaving={isEditObjectiveTypeSaving}
              isAddObjectiveTypeSaving={isAddObjectiveTypeSaving}
            />
          ) : (
            <ObjectiveTypeViewEntry
              objectiveType={objectiveType}
              setObjectiveTypeToBeingEdited={() => {
                setObjectiveTypeBeingEdited(objectiveType.objectiveTypeId);
              }}
              basketInformationGridRows={objectiveTypesBasketInformationRows[i]}
            />
          )}
        </ObjectiveTypeContainer>
      ))}
    </ObjectiveTypeViewWrapper>
  );
};
