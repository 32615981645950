import {
  red,
  pink,
  purple,
  deepPurple,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  amber,
  orange,
  deepOrange,
} from '@mui/material/colors';

const GraphConfig = Object.freeze({
  graphHeight: 300,
  graphColors: [
    lightBlue[400],
    green.A400,
    orange[400],
    pink.A400,
    deepPurple.A400,
    deepOrange.A400,
    cyan[600],
    red.A700,
    blue.A400,
    amber.A400,
    teal[600],
    purple.A400,
  ],
});

export default GraphConfig;
