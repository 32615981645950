/* eslint-disable no-restricted-syntax */
import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useParams, useHistory } from 'react-router-dom';
import { GraphCellContainer } from '../../../../../../../../shared/graphs/graphCellContainer';
import { useRegionCrmData } from './hooks/useRegionCrmData';
import { useRegionMetricData } from './hooks/useRegionMetricData';
import { PulseContext } from '../../../../../context/PulseContext';
import ProgressBar from './progressBar/ProgressBar';
import {
  getTrendValue,
  formatChartLabels,
  getObjectiveProducts,
  getObjectiveBasketName,
} from '../../../../../shared/utils';
import { Row } from '../../../../../../../../shared/entitiesTable/Row';

const StyledCardColumnSection = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  gap: 48,
  alignItems: 'center',
  padding: 16,
  borderLeft: `1px solid ${themeColors.buttonBorderColor}`,
  borderRight: `1px solid ${themeColors.buttonBorderColor}`,
  minWidth: '175px',
}));

const EmptyGraph = () => (
  <GraphCellContainer
    key="empty"
    isLoading={false}
    row={{ data: [0], trend: { status: 1, trend: null } }}
    valueType="number"
    graphType="line"
  />
);

export const RepRowProvider = ({
  objectiveId,
  cadence,
  metric = {},
  metricType,
  row,
  isDistrict = false,
  isLoading,
}) => {
  const { projectId } = useParams();
  const {
    productLineId,
    latestCurationTimestamp,
    isTimestampLoading,
    projectTargetProducts,
    isProductsLoading,
  } = useContext(PulseContext);
  const [volumeData, setVolumeData] = useState(null);
  const [shareData, setShareData] = useState(null);
  const [curatedCrmCalls, setCuratedCrmCalls] = useState([]);
  const [nonCuratedCrmCalls, setNonCuratedCrmCalls] = useState([]);
  const [crmXLabels, setCrmXLabels] = useState();
  const [curatedEntityCount, setCuratedEntityCount] = useState();
  const [curatedCallCount, setCuratedCallCount] = useState();
  const history = useHistory();

  const objectiveProducts = getObjectiveProducts(
    projectTargetProducts,
    objectiveId
  );
  const isMetadataLoading = isProductsLoading || isTimestampLoading;

  const { data: crmData, isLoading: isCrmDataLoading } = useRegionCrmData({
    productLineId,
    projectId,
    objectiveId,
    regionId: row.entity.id,
    cadence,
    timestamp: latestCurationTimestamp,
    products: objectiveProducts,
    isTerritory: isDistrict,
  });

  const selectedMetric = metric?.cadence?.find(
    (metricCadence) => metricCadence.value === cadence
  );

  const { data: metricData, isLoading: isMetricDataLoading } =
    useRegionMetricData({
      productLineId,
      projectId,
      objectiveId,
      regionId: row.entity.id,
      metricType: metric?.type,
      cadence,
      timestamp: selectedMetric?.lastTimestamp,
      products: objectiveProducts,
      tableName: selectedMetric?.tableName,
      isDistrict,
    });

  useEffect(() => {
    if (metricData?.volume && metricData?.share) {
      setVolumeData(metricData.volume);
      setShareData(metricData.share);
    }
  }, [metricData]);

  useEffect(() => {
    if (crmData?.calls?.curated && crmData?.calls?.nonCurated) {
      setCuratedCrmCalls(
        Object.entries(crmData.calls.curated).map((i) => Math.round(i[1].total))
      );
      setNonCuratedCrmCalls(
        Object.entries(crmData.calls.nonCurated).map((i) =>
          Math.round(i[1].total)
        )
      );
      setCrmXLabels(Object.entries(crmData.calls.curated).map((i) => i[0]));

      const timestamps = Object.keys(crmData.calls.curated);
      const lastTimestamp = timestamps[timestamps.length - 1];
      if (
        !crmData.metadata.lists?.[lastTimestamp] ||
        !crmData.calls.curated?.[lastTimestamp]
      ) {
        return;
      }
      setCuratedEntityCount(
        Math.round(crmData.metadata.lists[lastTimestamp].total)
      );
      setCuratedCallCount(
        Math.round(crmData.calls.curated[lastTimestamp].total)
      );
    }
  }, [crmData]);

  const totalCallsData = () => {
    const totalCallData = [];
    for (let i = 0; i < curatedCrmCalls.length; i++) {
      totalCallData.push(curatedCrmCalls[i] + nonCuratedCrmCalls[i]);
    }
    return {
      data: totalCallData,
      trend: getTrendValue(
        totalCallData[totalCallData.length - 2],
        totalCallData[totalCallData.length - 1]
      ),
    };
  };

  const totalCallCount = () => {
    if (!curatedCrmCalls.length) return 0;

    const cc = curatedCrmCalls[curatedCrmCalls.length - 1];
    const ncc = nonCuratedCrmCalls[curatedCrmCalls.length - 1];
    return cc + ncc;
  };

  const rxGraphs = (isRxLoading, type) => {
    const data = type === 'volume' ? volumeData : shareData;
    const graphMetricType = type === 'volume' ? 'volume' : 'share';
    const res = [];
    const objectiveBasketName = getObjectiveBasketName(
      projectTargetProducts,
      objectiveId
    );
    if ((!selectedMetric || !data) && !isRxLoading) {
      res.push(
        <EmptyGraph key={`${graphMetricType}-${objectiveBasketName}`} />
      );
    } else {
      const metricTypeTitle =
        graphMetricType.charAt(0).toUpperCase() + graphMetricType.slice(1);
      res.push(
        <GraphCellContainer
          basicColor
          key={`${graphMetricType}-${objectiveBasketName}`}
          isLoading={isRxLoading}
          row={
            data?.length > 0
              ? {
                  data: data.map((v) => v.value),
                  trend: getTrendValue(
                    data[data.length - 2].value,
                    data[data.length - 1].value
                  ),
                }
              : {}
          }
          valueType={type === 'volume' ? 'number' : 'percentNotMultiplied'}
          graphType="line"
          chartLabels={formatChartLabels(
            cadence,
            data?.map((v) => v.timestamp)
          )}
          graphTitle={`${row.entity.name} ${objectiveBasketName} ${metricTypeTitle} ${metric?.name}`}
          yTitle={`${objectiveBasketName} ${metricTypeTitle} ${metric?.name}`}
          tooltipDataPointTitle={`${metricTypeTitle} ${metric?.name}`}
        />
      );
    }
    return res;
  };

  const handleRowClick = () => {
    if (isDistrict) return;
    history.push({
      pathname: `${window.location.pathname}/${row.entity.id}`,
      repName: row.rep?.name,
      objectiveId,
      cadence,
    });
  };

  return (
    <Row
      row={row}
      objectiveId={objectiveId}
      cadence={cadence}
      key={row.id}
      isShimmer={isLoading}
      onRowClick={handleRowClick}
      showPowerscore={false}
      rowTitle={row.rep?.name}
      rowSubtitle={row.entity?.name}
      productLineId={productLineId}
    >
      <StyledCardColumnSection>
        <ProgressBar
          numerator={curatedCallCount}
          denominator={curatedEntityCount}
          isLoading={isCrmDataLoading || isMetadataLoading}
        />
        <ProgressBar
          numerator={curatedCallCount}
          denominator={totalCallCount()}
          isLoading={isCrmDataLoading || isMetadataLoading}
        />
        <GraphCellContainer
          row={totalCallsData()}
          valueType="volume"
          graphType="bar"
          chartLabels={formatChartLabels(cadence, crmXLabels)}
          isLoading={isCrmDataLoading || isMetadataLoading}
          graphTitle={`${row.entity?.name} Total Calls`}
          yTitle="Total Calls"
          tooltipDataPointTitle="Calls"
        />
        <GraphCellContainer
          row={{
            data: nonCuratedCrmCalls,
            trend: getTrendValue(
              nonCuratedCrmCalls[nonCuratedCrmCalls.length - 2],
              nonCuratedCrmCalls[nonCuratedCrmCalls.length - 1]
            ),
          }}
          valueType="volume"
          graphType="bar"
          chartLabels={formatChartLabels(cadence, crmXLabels)}
          isLoading={isCrmDataLoading || isMetadataLoading}
          graphTitle={`${row.entity?.name} Total Calls`}
          yTitle="Total Calls"
          tooltipDataPointTitle="Calls"
        />
      </StyledCardColumnSection>

      <StyledCardColumnSection>
        {metricType?.includes('volume') &&
          rxGraphs(isMetricDataLoading || isMetadataLoading, 'volume')}
        {metricType?.includes('share') &&
          rxGraphs(isMetricDataLoading || isMetadataLoading, 'share')}
      </StyledCardColumnSection>
    </Row>
  );
};
