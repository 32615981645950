import React, { useContext, useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useHistory } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';
import makeStyles from '@mui/styles/makeStyles';
import styled from '@emotion/styled';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  FactCheck,
  People,
  Person,
  Star,
  SwitchCamera,
  TrendingDown,
  TrendingUp,
} from '@mui/icons-material';
import { Fade, Typography } from '@mui/material';
import { inlineContainer } from '../../styles/layoutStyles';
import Dropdown from '../dropdown/dropdown';
import {
  nodePrefix,
  leafPrefix,
  getLeafSegments,
  isLeaf,
  overwriteURLQueryParam,
  getSelectedIdNodes,
} from './helpers';
import {
  trackSphereSegmentCategory,
  trackSphereSegmentSelection,
} from '../../trackers/appEventTracker';
import { ObjectiveContext } from './contexts/objectiveContext';
import { useObjectives } from '../../hooks/useObjectives';
import { TAB_PATHS } from './constants';

const iconMap = {
  trending_up: <TrendingUp />,
  trending_down: <TrendingDown />,
  star: <Star />,
  fact_check: <FactCheck />,
  switch_camera: <SwitchCamera />,
  person: <Person />,
  people: <People />,
};

const useStyles = makeStyles(({ themeColors }) => ({
  inlineContainer: {
    ...inlineContainer,
    width: '20rem',
    minWidth: '15rem',
    maxWidth: '30rem',
    paddingLeft: 0,
    paddingRight: 30,
    paddingBottom: 10,
    borderRightStyle: 'solid',
    borderWidth: 1,
    borderRightColor: themeColors.mainSurfaceBackground,
  },
}));

const ObjectiveFormControl = styled(FormControl)`
  display: block;
  margin-bottom: 16px;
`;

const CustomFormLabel = styled(FormLabel)`
  display: block;
  text-align: left;
  color: #8595ad;
  font-size: 12px;
  font-weight: 400;
  margin: 12px 0;
`;

const ObjectiveRadioLabel = styled(FormControlLabel)`
  color: #e0e4eb;
  margin-bottom: 12px;
  margin-bottom: 20px;
  margin-left: 0;
  .MuiFormControlLabel-label {
    margin-left: 6px;
    text-align: left;
  }
`;

const NodeLabelWrapper = styled.div`
  padding: 1rem 0.5rem;
`;

const LeafLabelWrapper = styled.div`
  padding: 0.75rem 0.5rem;
`;

const NavHeaderTypography = styled(Typography)`
  word-break: break-word;
`;

const StyledTreeItemNode = styled(TreeItem)(({ theme: { themeColors } }) => ({
  borderRadius: 3,
  color: themeColors.secondaryTextColorHighContrast,
  [`& .${treeItemClasses.content}`]: {
    '&.Mui-expanded': {
      fontWeight: 600,
    },
    '&:hover': {
      borderRadius: 3,
      backgroundColor: themeColors.inputBackground,
    },
  },
}));

const StyledTreeItemLeaf = styled(TreeItem)(({ theme: { themeColors } }) => ({
  borderRadius: 3,
  color: themeColors.secondaryTextColorHighContrast,
  [`& .${treeItemClasses.content}`]: {
    '&:hover': {
      borderRadius: 3,
      backgroundColor: themeColors.inputBackground,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      borderRadius: 3,
      backgroundColor: themeColors.primaryColor,
      color: themeColors.primaryTextColor,
    },
  },
}));

const trackSegmentSelection = ({ projectSegments, rawSegmentId }) => {
  const leafSegments = getLeafSegments(projectSegments);
  const matchingSegment = leafSegments.find(
    (segment) => segment.segmentId === rawSegmentId
  );
  if (matchingSegment) {
    trackSphereSegmentSelection(matchingSegment);
  }
};

const trackCategoryToggle = ({ flatSegments, primaryText, isExpanded }) => {
  const segment = flatSegments.find((seg) => seg.primaryText === primaryText);
  trackSphereSegmentCategory({ ...segment, isExpanded });
};

export default function SegmentNav({
  setRegionSelection,
  setSegmentSelection,
  viewer: {
    selectedSegmentId,
    projectSegments,
    flatSegments,
    selectedRegion,
    projectRegions,
  },
  showObjectivesMenu,
}) {
  const classes = useStyles();
  const routeHistory = useHistory();
  const [expanded, setExpanded] = useState(
    getSelectedIdNodes(projectSegments, selectedSegmentId)
  );
  const { data: objectives, isLoading: isObjectivesLoading } = useObjectives();
  const { setSelectedObjective, selectedObjective, isInTab } =
    useContext(ObjectiveContext);

  function mapSegment(segments) {
    return segments.map((segment) => {
      if (isLeaf(segment)) {
        return (
          <StyledTreeItemLeaf
            data-testid="test-sub-segment"
            nodeId={`${leafPrefix}${segment.segmentId}`}
            label={
              <LeafLabelWrapper>
                <NavHeaderTypography variant="h6">
                  {segment.primaryText}
                </NavHeaderTypography>
              </LeafLabelWrapper>
            }
            icon={iconMap[segment.icon]}
            key={segment.primaryText}
          />
        );
      }

      return (
        <StyledTreeItemNode
          data-testid="test-sphere-segment"
          nodeId={`${nodePrefix}${segment.parentSegmentName}${segment.primaryText}`}
          label={
            <NodeLabelWrapper>
              <NavHeaderTypography variant="h6">
                {segment.primaryText}
              </NavHeaderTypography>
            </NodeLabelWrapper>
          }
          key={segment.primaryText}
        >
          {mapSegment(segment.nodes)}
        </StyledTreeItemNode>
      );
    });
  }

  useEffect(() => {
    if (selectedRegion?.maptualListId) {
      const queryParam = overwriteURLQueryParam({
        queryParams: routeHistory.location.search,
        paramKey: 'maptualListId',
        paramValue: selectedRegion?.maptualListId,
      });
      routeHistory.push(`${routeHistory.location.pathname}?${queryParam}`);
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (
      objectives?.length &&
      (!selectedObjective || !objectives.includes(selectedObjective))
    ) {
      setSelectedObjective(objectives[0]);
    }
  }, [isObjectivesLoading]);

  useEffect(() => {
    if (selectedSegmentId) {
      const queryParam = overwriteURLQueryParam({
        queryParams: routeHistory.location.search,
        paramKey: 'segmentId',
        paramValue: selectedSegmentId,
      });
      routeHistory.push(`${routeHistory.location.pathname}?${queryParam}`);
    }
  }, [selectedSegmentId]);

  const objectiveButtonHandler = (event) => {
    setSelectedObjective({
      id: event.target.value,
      title: event.target.label,
    });
  };
  return (
    <Fade in timeout={600} key="segmentNav">
      <div className={classes.inlineContainer}>
        <Scrollbar disableTracksWidthCompensation>
          <div
            style={{
              paddingBottom: 20,
            }}
          >
            {showObjectivesMenu &&
              !!objectives?.length &&
              !isObjectivesLoading && (
                <ObjectiveFormControl className="intercom-sphere-objective-selector">
                  <CustomFormLabel id="radio-buttons-objectives">
                    Objectives
                  </CustomFormLabel>
                  <RadioGroup
                    aria-labelledby="radio-buttons-objectives-label"
                    defaultValue="female"
                    name="radio-buttons-objectives-group"
                    onChange={objectiveButtonHandler}
                  >
                    {objectives.map(({ id, title }) => (
                      <ObjectiveRadioLabel
                        key={id}
                        value={id}
                        control={
                          <Radio checked={selectedObjective?.id === id} />
                        }
                        label={title}
                      />
                    ))}
                  </RadioGroup>
                </ObjectiveFormControl>
              )}
            {isInTab !== TAB_PATHS.TERRITORY_TABLE && (
              <>
                <CustomFormLabel>Region</CustomFormLabel>
                <Dropdown
                  options={projectRegions}
                  selectedItem={selectedRegion}
                  onSelectionChange={setRegionSelection}
                />
              </>
            )}
          </div>

          {Array.isArray(projectSegments) &&
          [TAB_PATHS.TABLE, TAB_PATHS.DASHBOARD].includes(isInTab) ? (
            <>
              <CustomFormLabel>Segments</CustomFormLabel>
              <Fade
                in
                timeout={1000}
                key={`segmentTree-${selectedRegion?.maptualListId}`}
              >
                <TreeView
                  style={{ textAlign: 'start' }}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  multiSelect={false}
                  expanded={expanded}
                  selected={`${leafPrefix}${selectedSegmentId}`}
                  onNodeSelect={(event, segmentId) => {
                    if (segmentId.startsWith(leafPrefix)) {
                      const rawSegmentId = segmentId.slice(leafPrefix.length);
                      setSegmentSelection(rawSegmentId);
                      trackSegmentSelection({ projectSegments, rawSegmentId });
                    }
                  }}
                  onNodeToggle={(event, expandedNodeIds) => {
                    const hasCollapsed =
                      expanded.length > expandedNodeIds.length;

                    if (hasCollapsed) {
                      expanded.some(
                        (id) =>
                          !expandedNodeIds.includes(id) &&
                          trackCategoryToggle({
                            flatSegments,
                            primaryText: id.slice(nodePrefix.length),
                            isExpanded: false,
                          })
                      );
                    } else {
                      expandedNodeIds.every(
                        (id) =>
                          !expanded.includes(id) &&
                          trackCategoryToggle({
                            flatSegments,
                            primaryText: id.slice(nodePrefix.length),
                            isExpanded: true,
                          })
                      );
                    }

                    setExpanded([...expandedNodeIds]);
                  }}
                >
                  {mapSegment(projectSegments)}
                </TreeView>
              </Fade>
            </>
          ) : null}
        </Scrollbar>
      </div>
    </Fade>
  );
}
