import { useDispatch } from 'react-redux';
import { APPVIEWS, ROUTES } from '../../constants';
import { selectAppThunk } from '../../slices/app';

const getAppViewFromUrl = () => {
  const pathnameList = window.location.pathname.split('/');
  const routePrefix =
    pathnameList.length > 1 ? `/${pathnameList?.[1]}` : undefined;

  switch (routePrefix) {
    case ROUTES.SPHERE:
      return APPVIEWS.SPHERE;
    case ROUTES.ADMIN:
      return APPVIEWS.ADMIN;
    case ROUTES.FIELD:
      return APPVIEWS.FIELD;
    case ROUTES.PULSE:
      return APPVIEWS.PULSE;
    default:
      return null;
  }
};

export const getCurrentAppView = ({ maptualAccessList, appViewState }) => {
  const dispatch = useDispatch();
  if (appViewState) return appViewState;

  const urlAppView = getAppViewFromUrl();
  if (maptualAccessList?.includes(urlAppView)) {
    dispatch(selectAppThunk(urlAppView));
    return urlAppView;
  }
  return undefined;
};
