import { selectAppThunk } from '../slices/app';
import { APPVIEWS } from '../constants';
import { getCurrentUser } from '../slices/profile';

const singlePermissionAppSelectionMiddleware =
  (storeAPI) => (next) => (action) => {
    if (action.type === getCurrentUser.fulfilled.type) {
      const { maptualAccess: maptualAccessList, type: roleType } =
        action.payload.role;

      if (Array.isArray(maptualAccessList) && maptualAccessList.length > 0) {
        if (maptualAccessList.length === 1) {
          const appSelected = maptualAccessList[0];
          storeAPI.dispatch(selectAppThunk(appSelected));
        }
      } else {
        const newAppView = APPVIEWS[roleType] || APPVIEWS.FIELD;
        storeAPI.dispatch(selectAppThunk(newAppView));
      }
    }

    return next(action);
  };

export default singlePermissionAppSelectionMiddleware;
