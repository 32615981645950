/* eslint-disable no-console */
/* eslint-disable no-throw-literal */
import memoize from 'lodash/memoize';
import * as requests from '../request/projectRequests';
import censorResponse, {
  censorShareData,
  censorScriptsSphereData,
  censorInsights,
} from '../modules/demoCensor';
import { debouncedRequest } from '../modules/requestHelpers';
import {
  getProjectItem,
  getProjectList,
  loadSavedProjects,
} from '../slices/project';

export function setMaptualListFilterPreferences(item = null) {
  return {
    type: 'PROJECT_SET_MAPTUAL_LIST_FILTER_PREF',
    payload: item,
  };
}

export function setAnalysisIdMapping(item = null) {
  return {
    type: 'PROJECT_SET_ANALYSIS_ID_MAPPING',
    payload: item,
  };
}

export function setFeatureIdMapping(item = null) {
  return {
    type: 'PROJECT_SET_FEATURE_ID_MAPPING',
    payload: item,
  };
}

export function setFullAnalysisItem(item = null) {
  return {
    type: 'PROJECT_SET_FULL_ANALYSIS_ITEM',
    payload: item,
  };
}

export function setFeatureSelection(item = null) {
  return {
    type: 'PROJECT_SET_FEATURE_SELECTION',
    payload: item,
  };
}

export function setRegionSelection(region = null) {
  return {
    type: 'PROJECT_SET_REGION_SELECTION',
    payload: region,
  };
}

export function setProjectItem(item = null) {
  return {
    type: 'PROJECT_SET_PROJECT_ITEM',
    payload: item,
  };
}

export function setAnalysisExpanded(item = null) {
  return {
    type: 'PROJECT_SET_ANALYSIS_EXPANDED',
    payload: item,
  };
}

export function getProjectFromAPI(projectId) {
  return projectId ? getProjectItem({ projectId }) : getProjectList();
}

export function getProject(projectId) {
  return function getProjectThunk(dispatch, getState) {
    const state = getState();
    const userSavedProjects = state.app.profile.projects;

    if (Array.isArray(userSavedProjects) && userSavedProjects.length > 0) {
      return dispatch(loadSavedProjects(censorResponse(userSavedProjects)));
    }

    return dispatch(getProjectFromAPI(projectId));
  };
}

export function getSegments(projectId) {
  return {
    type: 'PROJECT_GET_PROJECT_SEGMENTS',
    payload: requests.getSegments(projectId).then((response) => {
      try {
        return censorResponse(response.data);
      } catch (e) {
        throw { Error: e.message };
      }
    }),
  };
}

export function getDashboardStructure({ projectId, segmentId, maptualListId }) {
  return {
    type: 'PROJECT_GET_DASHBOARD_STRUCTURE',
    payload: requests
      .getDashboardStructure({ projectId, segmentId, maptualListId })
      .then((response) => {
        try {
          console.log('censor get dashboard substructure');
          return censorResponse(JSON.parse(response.data));
        } catch (e) {
          throw { Error: e.message };
        }
      }),
  };
}

function getDashboardSubUrlInternal({
  subUrl,
  projectId,
  segmentId,
  maptualListId,
  signal = null,
}) {
  return {
    type: 'PROJECT_GET_DASHBOARD_SUB_URL',
    payload: requests
      .getDashboardSubUrl({
        subUrl,
        projectId,
        segmentId,
        maptualListId,
        signal,
      })
      .then((response) => {
        try {
          console.log('censor gashboard sub url');
          if (response.data?.shareData) {
            console.log('censor share sphere data');
            const resp = {
              complete: response.data.shareData,
            };
            const censored = censorShareData(resp);
            return {
              shareData: censored.complete,
            };
          }
          if (response.data?.scriptsData) {
            console.log('censor scripts sphere data');
            const censored = censorScriptsSphereData(
              response.data?.scriptsData
            );
            return {
              scriptsData: censored,
            };
          }
          return censorResponse(response.data);
        } catch (e) {
          throw { Error: e.message };
        }
      })
      .catch(() => ({
        isRejected: true,
      })),
  };
}

const getCachedDashboardRequest = memoize(() =>
  debouncedRequest(getDashboardSubUrlInternal)
);
export function getDashboardSubUrl({
  subUrl,
  projectId,
  segmentId,
  maptualListId,
}) {
  return getCachedDashboardRequest(subUrl)({
    subUrl,
    projectId,
    segmentId,
    maptualListId,
  });
}

export function getAnalysis(analysisId, userId) {
  if (analysisId) {
    return {
      type: 'PROJECT_GET_ANALYSIS_ITEM',
      payload: requests.getAnalysis(analysisId, userId).then((response) => {
        let responseJson = {};
        try {
          responseJson = JSON.parse(response.data);
          return responseJson;
        } catch (e) {
          throw { Error: e.message };
        }
      }),
    };
  }
  return {
    type: 'PROJECT_GET_ANALYSIS_LIST',
    payload: requests.getAnalysis().then((response) => {
      let responseJson = {};
      try {
        responseJson = JSON.parse(response.data);
        return responseJson;
      } catch (e) {
        throw { Error: e.message };
      }
    }),
  };
}

export function selectMaptualList(maptualListMetadata) {
  return {
    type: 'PROJECT_SELECT_MAPTUAL_LIST_METADATA',
    payload: maptualListMetadata,
  };
}

export function selectMaptualListSegment(segmentSelection) {
  return {
    type: 'PROJECT_SELECT_MAPTUAL_LIST_SEGMENT',
    payload: segmentSelection,
  };
}

export function selectCustomList(customListSelection) {
  return {
    type: 'PROJECT_SELECT_CUSTOM_LIST',
    payload: customListSelection,
  };
}

export function selectSortingOption(option) {
  return {
    type: 'PROJECT_SELECT_SORTING_OPTION',
    payload: option,
  };
}

export function selectEntity(entityMetadata) {
  return {
    type: 'PROJECT_SELECT_ENTITY_METADATA',
    payload: entityMetadata,
  };
}

export function initializeStandaloneEntityPage({
  entityId,
  maptualListId,
  projectId,
  isStandalonePage,
}) {
  return {
    type: 'PROJECT_INITIALIZE_STANDALONE_ENTITY_PAGE',
    payload: { entityId, maptualListId, projectId, isStandalonePage },
  };
}

export function getMaptualList(projectId, maptualListId) {
  if (projectId) {
    if (maptualListId) {
      return {
        type: 'PROJECT_GET_MAPTUAL_LIST',
        payload: requests
          .getMaptualList(projectId, maptualListId)
          .then((response) => {
            try {
              if (response && response.data) {
                return response.data;
              }
              throw { Error: response };
            } catch (e) {
              throw { Error: e.message };
            }
          }),
      };
    }
    return {
      type: 'PROJECT_GET_MAPTUAL_LISTS',
      payload: requests.getMaptualList(projectId).then((response) => {
        try {
          if (response && response.data) {
            return censorResponse(response.data);
          }
          throw { Error: response };
        } catch (e) {
          throw { Error: e.message };
        }
      }),
    };
  }
  return {
    type: 'PROJECT_GET_MAPTUAL_LISTS_NOT_COMPLETE',
    payload: {},
  };
}

export const getEntity = debouncedRequest(
  ({
    entityType,
    entityId,
    maptualListId,
    projectId,
    signal = null,
    appType = 'sphere',
  }) => {
    if (entityType && entityId && maptualListId && projectId) {
      return {
        type: 'PROJECT_GET_ENTITY_BY_MAPTUALLIST_ID',
        payload: requests
          .getEntity({
            entityType,
            entityId,
            maptualListId,
            projectId,
            signal,
            appType,
          })
          .then((response) => {
            try {
              if (response && response.data) {
                return censorResponse(response.data);
              }
              throw { Error: response };
            } catch (e) {
              throw { Error: e.message };
            }
          })
          .catch((error) => {
            throw { Error: error };
          }),
      };
    }

    return {
      type: 'PROJECT_GET_ENTITY_BY_MAPTUALLIST_NOT_COMPLETE',
      payload: {},
    };
  }
);

export const getShare = debouncedRequest(
  ({ entityType, entityId, maptualListId, projectId, signal = null }) => {
    if (entityType && entityId && maptualListId && projectId) {
      return {
        type: 'PROJECT_GET_SHARE_BY_MAPTUALLIST_ID',
        payload: requests
          .getShare({
            entityType,
            entityId,
            maptualListId,
            projectId,
            signal,
          })
          .then((response) => {
            try {
              if (response && response.data) {
                return censorShareData(response.data);
              }
              throw { Error: response };
            } catch (e) {
              throw { Error: e.message };
            }
          })
          .catch((error) => {
            throw { Error: error };
          }),
      };
    }

    return {
      type: 'PROJECT_GET_SHARE_BY_MAPTUALLIST_NOT_COMPLETE',
      payload: {},
    };
  }
);

export function deleteReduxProject(projectId) {
  return {
    type: 'PROJECT_DELETE_BY_ID',
    payload: { projectId },
  };
}

export function deleteProjectPending(projectId) {
  return {
    type: 'PROJECT_DELETE_PROJECT_ITEM_PENDING',
    payload: { projectId },
  };
}

export function deleteProjectFulfilled(projectId) {
  return {
    type: 'PROJECT_DELETE_PROJECT_ITEM_FULFILLED',
    payload: { projectId },
  };
}

export function deleteProjectRejected(projectId) {
  return {
    type: 'PROJECT_DELETE_PROJECT_ITEM_REJECTED',
    payload: { projectId },
  };
}

export function deleteProject(projectId) {
  return function deleteProjectThunk(dispatch) {
    dispatch(deleteProjectPending(projectId));

    return requests.deleteProject(projectId).then(
      () => {
        dispatch(deleteProjectFulfilled(projectId));
        dispatch(deleteReduxProject(projectId));
      },
      (err) => {
        console.error(
          'There was a problem while deleting the project',
          projectId,
          err.message
        );
        dispatch(deleteProjectRejected(projectId));
      }
    );
  };
}

export const updateProjectMetadata = debouncedRequest((project) => ({
  type: 'PROJECT_UPDATE_METADATA',
  payload: requests
    .patchProjectMetadata(project)
    .then((response) => response.data)
    .catch((error) => {
      throw { Error: error };
    }),
}));

export const getInsights = debouncedRequest(
  ({ entityId, projectId, maptualListId }) => {
    if (entityId && maptualListId && projectId) {
      return {
        type: 'PROJECT_GET_INSIGHTS',
        payload: requests
          .getInsights(entityId, projectId, maptualListId)
          .then((response) => {
            try {
              if (response && response.data) {
                return censorInsights(response.data);
              }
              throw { Error: response };
            } catch (e) {
              throw { Error: e.message };
            }
          })
          .catch((error) => {
            throw { Error: error };
          }),
      };
    }
    return {
      type: 'PROJECT_GET_INSIGHTS_NOT_COMPLETE',
      payload: {},
    };
  }
);
