import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Scrollbar from 'react-scrollbars-custom';
import isEmpty from 'lodash/isEmpty';
import RawDataDisplay from './entitycontents/rawDataDisplay';
import SkeletonChart from '../generic/SkeletonChart';
import { useMetricScriptsMetadata } from '../../hooks/useMetricScriptsMetadata';
import MetricScriptsChart from './entitycontents/metricScriptsChart';
import { MultiObjectivesRow } from '../../containers/shared/MultiObjectivesRow';
import InsightsCardRow from './entityInsights/insightsCardRow';
import { EntityTitleBarContainer } from './entityTitleBar';
import { trackObjectiveSelected } from '../../trackers/mixpanel';
import InsightsSegments from './entityInsights/insightsSegments';
import { InsightsActivity } from './entityInsights/insightsActivity';
import { InsightsChannels } from './entityInsights/insightsChannels';
import { InsightsPrecall } from './entityInsights/insightsPrecall';
import { InsightsTopics } from './entityInsights/insightsTopics';
import { InsightsWarningCard } from './entityInsights/insightsWarningCard';
import { InsightsLeaveBehinds } from './entityInsights/insightsLeaveBehinds';
import useFeatureToggles from '../../hooks/useFeatureToggles';
import { AppContext } from '../../containers/application/appContext';
import { CategoricalDataWrapper } from './entityCategoricalData/categoricalDataWrapper';
import { EntityMessages } from './entityMessages/entityMessages';

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    flexGrow: 2,
    width: '100%',
    height: '100%',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    minWidth: 640,
  },
  content: {
    width: '100%',
    maxWidth: 810,
    minWidth: 560,
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  briefContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  sectionText: {
    fontSize: 22,
    fontWeight: 800,
    color: themeColors.primaryTextColor,
    width: 'auto',
  },
}));

const isAdminUser = (profile) => profile?.role?.type === 'ADMIN';

const EntityMaptualInsightsView = ({
  insights,
  trackObjectiveSelection,
  entityUData,
  maptualInsights,
  shareDataComplete,
  metadata,
  entity,
  displayUserCreatedListsButton,
  maptualTopChannels,
  isNoSee,
  isLongTermLeave,
  categoricalData,
}) => {
  // Visual component elements
  const classes = useStyles();
  const { profile } = useContext(AppContext);
  const featureToggles = useFeatureToggles();
  const {
    isSuccess: isMetricScriptsMetadataLoaded,
    error: metricScriptsMetadataError,
  } = useMetricScriptsMetadata();

  const getScriptsErrorMessage = (message) => {
    let messageUpdated = message;
    if (message === 'Network Error') {
      messageUpdated =
        'We currently do not have enough data to generate a graph and table for this HCP';
    }
    return messageUpdated;
  };

  const [activeObjective, setActiveObjective] = useState({});
  const [objectivesRowActiveObjective, setObjectivesRowActiveObjective] =
    useState({});
  const [viewMultiObjectives, setViewMultiObjectives] = useState(false);

  useEffect(() => {
    if (insights?.objectives && insights.objectives.length > 0) {
      const primaryObjective = insights.objectives[0];

      setActiveObjective(primaryObjective);
      setObjectivesRowActiveObjective({
        ...primaryObjective,
        id: primaryObjective.objectiveId,
        label: primaryObjective.objectiveDescription,
        maptualDisplayScore: primaryObjective.objectiveScore,
      });

      const hasMoreThanOneObjective = insights.objectives.length > 1;
      setViewMultiObjectives(hasMoreThanOneObjective);
      if (hasMoreThanOneObjective) {
        trackObjectiveSelection(primaryObjective, true);
      }
    } else {
      setViewMultiObjectives(false);
    }
  }, [insights]);

  const [objectiveScores, setObjectiveScores] = useState([]);

  useEffect(() => {
    if (insights?.powerscore !== undefined) {
      setObjectiveScores(
        insights?.objectives?.map((obj) => Math.round(obj.objectiveScore))
      );
    } else {
      setObjectiveScores([]);
    }
  }, [insights]);

  const [insightsObjectives, setInsightsObjectives] = useState([]);
  useEffect(() => {
    if (insights?.objectives?.length > 0) {
      const objectives = [];

      insights.objectives.forEach((objective) => {
        objectives.push({
          ...objective,
          id: objective.objectiveId,
          label: objective.objectiveDescription,
          maptualDisplayScore: objective.objectiveScore,
        });
      });

      setInsightsObjectives(objectives);
    }
  }, [insights?.objectives]);

  const handleObjectiveChange = (obj) => {
    trackObjectiveSelection(obj, false);
    setActiveObjective(obj);
    setObjectivesRowActiveObjective(obj);
  };

  return (
    <Scrollbar
      disableTracksWidthCompensation
      className={classes.root}
      data-testid="insightsView"
    >
      <div className={classes.container}>
        <div className={classes.content}>
          <EntityTitleBarContainer
            metadata={metadata}
            entityUData={entityUData || null}
            entity={entity}
            maptualScoreNormalized={
              insights?.powerscore !== undefined
                ? Math.round(insights.powerscore)
                : undefined
            }
            displayUserCreatedListsButton={displayUserCreatedListsButton}
            maptualTopChannels={maptualTopChannels}
            objectiveScores={objectiveScores}
          />

          {viewMultiObjectives ? (
            <MultiObjectivesRow
              objectives={insightsObjectives}
              handleClick={handleObjectiveChange}
              selectedObjective={objectivesRowActiveObjective}
            />
          ) : null}

          {isNoSee && (
            <InsightsWarningCard
              name={metadata.entityName}
              userCreatedListType="No-See"
            />
          )}
          {isLongTermLeave && (
            <InsightsWarningCard
              name={metadata.entityName}
              userCreatedListType="Long-term Leave"
            />
          )}

          <InsightsChannels
            maptualTopChannels={maptualInsights?.maptualTopChannels || []}
          />

          {isAdminUser(profile) &&
            featureToggles(profile.userGroup, 'showPrecallInsights') && (
              <InsightsPrecall
                entityId={entity?.metadata?.entityId}
                objective={activeObjective}
              />
            )}
          {featureToggles(profile.userGroup, 'showTopicsInsights') && (
            <InsightsTopics
              maptualTopTopics={maptualInsights?.maptualTopMessages || []}
            />
          )}
          {featureToggles(profile.userGroup, 'showDisbursementsInsights') && (
            <InsightsLeaveBehinds
              maptualTopLeaveBehinds={
                maptualInsights?.maptualTopDisbursements || []
              }
            />
          )}
          {featureToggles(profile.userGroup, 'showEntityActivityInsights') && (
            <InsightsActivity entityId={entity?.metadata?.entityId} />
          )}
          <EntityMessages selectedObjective={activeObjective} />
          <CategoricalDataWrapper
            data={categoricalData}
            objectiveId={activeObjective?.objectiveId}
          />

          {insights?.objectives ? (
            <InsightsCardRow objectiveCards={activeObjective?.cards || []} />
          ) : null}

          <InsightsSegments objectiveCards={activeObjective?.cards || []} />

          {/* SCRIPTS Complete */}
          {!isMetricScriptsMetadataLoaded || metricScriptsMetadataError ? (
            <SkeletonChart
              title="Scripts Volume"
              error={getScriptsErrorMessage(
                metricScriptsMetadataError?.message
              )}
            />
          ) : (
            <MetricScriptsChart
              key="scripts-data"
              metricComponentType="scriptsData"
              activeObjective={activeObjective}
            />
          )}
          {/* SHARE Complete */}
          {!shareDataComplete && <SkeletonChart title="Market Share" />}
          {!isEmpty(shareDataComplete) && !shareDataComplete.error && (
            <RawDataDisplay
              key="share-data"
              dataKey="shareData"
              data={shareDataComplete}
              activeObjective={activeObjective}
            />
          )}
          {!isEmpty(shareDataComplete) && shareDataComplete.error && (
            <SkeletonChart
              title="Market Share"
              error={shareDataComplete.message}
            />
          )}
        </div>
      </div>
    </Scrollbar>
  );
};

export const EntityMaptualInsights = ({
  entity,
  displayUserCreatedListsButton,
  maptualTopChannels,
  categoricalData,
}) => {
  // Data layer & State
  const { metadata, maptualInsights, shareDataComplete, entityUData } =
    entity || {};

  const insights = useSelector((state) => state?.projectViewer?.insights);

  const trackObjectiveSelection = (objective, defaultLoad) => {
    trackObjectiveSelected(
      `Objective ${objective.objectiveNumber}`,
      defaultLoad,
      'HCP Page Objective Selector Bar'
    );
  };

  return (
    <EntityMaptualInsightsView
      insights={insights}
      trackObjectiveSelection={trackObjectiveSelection}
      entityUData={entityUData}
      maptualInsights={maptualInsights}
      shareDataComplete={shareDataComplete}
      metadata={metadata}
      entity={entity}
      categoricalData={categoricalData}
      displayUserCreatedListsButton={displayUserCreatedListsButton}
      maptualTopChannels={maptualTopChannels}
      isNoSee={metadata?.listMemberships?.noSee || false}
      isLongTermLeave={metadata?.listMemberships?.longTermLeave || false}
    />
  );
};

EntityMaptualInsights.propTypes = {
  entity: PropTypes.shape({
    metadata: PropTypes.shape({
      entityId: PropTypes.string,
      entityName: PropTypes.string,
    }),
    maptualInsights: PropTypes.shape({
      maptualScoreNormalized: PropTypes.shape({
        adjective: PropTypes.shape({
          word: PropTypes.string,
        }),
        lastUpdated: PropTypes.number, // date
      }),
      maptualFactors: PropTypes.arrayOf(
        PropTypes.shape({
          dataList: PropTypes.any,
          primaryText: PropTypes.string,
        })
      ),
    }),
    entityRawData: PropTypes.object,
    scriptsDataBrief: PropTypes.object,
    scriptsDataComplete: PropTypes.array,
    shareDataBrief: PropTypes.object,
    shareDataComplete: PropTypes.array,
  }),
};
