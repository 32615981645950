import { useQuery } from 'react-query';
import { maptualApiInstance } from '../../../../../../../../../../request/config';

export const useRegionCrmData = ({
  productLineId,
  projectId,
  objectiveId,
  regionId,
  cadence,
  timestamp,
  products,
  isTerritory,
}) => {
  const queryCacheKey = Object.values({
    key: 'crm-data',
    productLineId,
    projectId,
    objectiveId,
    regionId,
    cadence,
    timestamp,
    products,
  });
  return useQuery(queryCacheKey, async () => {
    try {
      const response = await getRegionCrmData({
        productLineId,
        projectId,
        objectiveId,
        regionId,
        cadence,
        timestamp,
        products,
        isTerritory,
      });
      return response.data;
    } catch {
      return {};
    }
  });
};

export function getRegionCrmData({
  productLineId,
  projectId,
  objectiveId,
  regionId,
  cadence,
  timestamp,
  products,
  isTerritory,
}) {
  if (
    !productLineId ||
    !objectiveId ||
    !regionId ||
    !cadence ||
    !timestamp ||
    !products ||
    !products.length
  ) {
    return Promise.reject();
  }
  const entityType = !isTerritory ? 'region' : 'territory';
  const productsString = products.map((i) => `products=${i}`).join('&');
  return maptualApiInstance.get(
    `pulse/product-lines/${productLineId}/projects/${projectId}/objectives/${objectiveId}/regions/${regionId}?cadence=${cadence}&${productsString}&timestamp=${timestamp}&entity_type=${entityType}`,
    {
      timeout: 600000,
    }
  );
}
