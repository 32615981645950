import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import { Timeline as AnnualIcon } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTheme } from '@mui/styles';
import { trackTimeframeSelection } from '../../../../trackers/appEventTracker';
import { selectMetricComponentData } from '../../../../selectors/project';
import { ReactComponent as WeeklyIcon } from '../../../../assets/tab_weekly_icon.svg';
import { ReactComponent as MonthlyIcon } from '../../../../assets/tab_monthly_icon.svg';
import { ReactComponent as QuarterlyIcon } from '../../../../assets/tab_quarterly_icon.svg';
import { METRIC_CHART_TAB_CADENCES } from '../tabbedChart/constants';
import { getColorFromTrend } from '../../../../utils/getColorFromTrend';
import toKebabCase from '../../../../utils/toKebabCase';

const CHART_CADENCE_ICON_MAP = {
  wk: WeeklyIcon,
  mth: MonthlyIcon,
  r3m: QuarterlyIcon,
  qtr: QuarterlyIcon,
  yr: AnnualIcon,
};

const CustomTabs = styled(Tabs)`
  align-items: center;
  overflow: initial;
  display: flex;
  gap: 15px;

  .MuiTab-root.Mui-selected {
    background-color: ${(props) =>
      props.theme.themeColors.buttonActiveBackgroundColor};
    color: ${(props) => props.theme.themeColors.buttonActiveContentColor};
    border-color: ${(props) => props.theme.themeColors.buttonActiveBorderColor};
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabs-flexContainer {
    height: 40px;
  }
`;

const CustomTab = styled(Tab)`
  min-height: auto;
  line-height: 1.3;
  padding: 8px;
  background-color: ${(props) => props.theme.themeColors.buttonBackgroundColor};
  color: ${(props) => props.theme.themeColors.buttonContentColor};
  border: 1px solid ${(props) => props.theme.themeColors.buttonBorderColor};
  box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.12);
  text-transform: none;

  .MuiTab-iconWrapper {
    margin: 0 6px 0 0;
  }

  &:hover {
    background-color: ${(props) =>
      props.theme.themeColors.buttonHoverBackgroundColor};
    color: ${(props) => props.theme.themeColors.buttonHoverContentColor};
    border-color: ${(props) => props.theme.themeColors.buttonHoverBorderColor};
  }

  &:first-of-type {
    border-radius: 4px 0px 0px 4px;
  }

  &:last-of-type {
    border-radius: 0px 4px 4px 0px;
  }
`;

const TabsSection = styled('div')(() => ({
  display: 'flex',
  gap: '15px',
}));

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '16px',
}));

const TabInfoContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 33,
  marginTop: 16,
}));

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.csvDownloadColor,
  })
);

const StyledChartTabInfo = styled(Typography)(({ theme: { themeColors } }) => ({
  fontFamily: 'Roboto',
  fontSize: 14,
  fontWeight: 400,
  color: themeColors.neutral60,
  marginLeft: 5,
  lineHeight: '120%',
  letterSpacing: 0,
  textAlign: 'left',
}));

export default function ChartTabs({
  tabs,
  selectedTab,
  setCurrentTab,
  annualTargetRow,
  metricComponentType,
}) {
  const { entityName, maptualListSegmentName, maptualListLabel } = useSelector(
    selectMetricComponentData
  );
  const { themeColors } = useTheme();

  const intercomClass = (tab) =>
    `intercom-field-${toKebabCase(
      metricComponentType
    )}-${tab.name.toLowerCase()}-tab`;

  return (
    <Container>
      <TabsSection>
        <CustomTabs value={selectedTab.id}>
          {tabs?.map((tab) => {
            const Icon = CHART_CADENCE_ICON_MAP[tab.value];
            return (
              <CustomTab
                className={intercomClass(tab)}
                key={tab.id}
                label={tab.name}
                value={tab.id}
                iconPosition="start"
                icon={
                  <Icon
                    style={{
                      color:
                        tab.value === METRIC_CHART_TAB_CADENCES.ANNUAL &&
                        selectedTab.value !== METRIC_CHART_TAB_CADENCES.ANNUAL
                          ? getColorFromTrend(
                              annualTargetRow?.primaryData.prediction
                                .secondaryValue,
                              themeColors
                            )
                          : 'unset',
                    }}
                  />
                }
                onClick={() => {
                  setCurrentTab(tab);
                  trackTimeframeSelection({
                    metricComponentType,
                    timeframeSelectionName: tab.name,
                    entityName,
                    segmentName: maptualListSegmentName,
                    maptualListSelectionLabel: maptualListLabel,
                  });
                }}
              />
            );
          })}
        </CustomTabs>
        {metricComponentType === 'scriptsData' &&
        selectedTab.value === METRIC_CHART_TAB_CADENCES.MAT ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <HelpOutlineIcon style={{ color: themeColors.tertiaryColor }} />
            <Typography
              variant="caption"
              style={{ color: themeColors.tertiaryColor }}
              align="left"
            >
              Rolling annual sum. It changes as the most recent month is added
              and the oldest month is removed.
            </Typography>
          </div>
        ) : null}
      </TabsSection>
      {selectedTab.value === METRIC_CHART_TAB_CADENCES.R3M && (
        <TabInfoContainer>
          <StyledInfoOutlinedIcon />
          <StyledChartTabInfo>Rolling 3 month total</StyledChartTabInfo>
        </TabInfoContainer>
      )}
    </Container>
  );
}
