import { ColTagTypeModel, DatasetModel } from '../models/ingestDataModel';

const initState = {
  columnMeta: null,
  columnDisplay: null,
  columnSamples: null,
  dataset: { ...DatasetModel },
  dataFile: null,
  dataFileName: '',
  dataFileSample: {},
  dataUploadPercentage: 0,
  existingColumns: null,
  existingColumnsMap: null,
  errorMessage: '',
  txtInputErrMsg: null,
  isBusy: false,
  isNewDataset: true,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case 'NEW_DATASET':
      return { ...state, isNewDataset: true };
    case 'ADD_TO_DATASET':
      return { ...state, isNewDataset: false };
    case 'CLEAR_ERROR_MSG':
      return { ...state, errorMessage: '' };
    case 'ON_DATASET_NAME_CHANGE': {
      const newDatasetModel = { ...state.dataset, datasetName: action.payload };
      return {
        ...state,
        dataset: newDatasetModel,
        errorMessage: '',
        isBusy: false,
      };
    }
    case 'VALIDATE_DATASET_NAME_REJECTED':
    case 'SAMPLE_DATAFILE_REJECTED':
    case 'DATA_INGEST_CONFIRM_UPLOAD_REJECTED':
    case 'VALIDATE_DATAFILE_REJECTED':
    case 'VALIDATE_KEY_COLUMNS_REJECTED':
      return { ...state, errorMessage: action.payload.Error, isBusy: false };

    case 'VALIDATE_COLUMN_REVIEW_REJECTED':
    case 'DATA_INGEST_GET_EXISTING_COLUMNS_REJECTED':
    case 'DATA_INGEST_GET_EXISTING_DATASETS_REJECTED':
      return { ...state, errorMessage: action.payload, isBusy: false };
    case 'VALIDATE_DATASET_NAME_PENDING':
    case 'SAMPLE_DATAFILE_PENDING':
    case 'DATA_INGEST_CONFIRM_UPLOAD_PENDING':
    case 'DATA_INGEST_GET_EXISTING_COLUMNS_PENDING':
    case 'DATA_INGEST_GET_EXISTING_DATASETS_PENDING':
    case 'VALIDATE_DATAFILE_PENDING':
      return { ...state, errorMessage: '', isBusy: true };
    case 'DATA_INGEST_GET_EXISTING_DATASETS_FULFILLED':
      return {
        ...state,
        errorMessage: '',
        customerDatasets: action.payload,
        isBusy: false,
      };

    case 'DATA_INGEST_CONFIRM_PROGRESS':
      return {
        ...state,
        errorMessage: '',
        dataUploadPercentage: action.payload,
        isBusy: true,
      };
    case 'VALIDATE_DATASET_NAME_FULFILLED':
    case 'VALIDATE_COLUMN_REVIEW_FULFILLED':
    case 'DATA_INGEST_CONFIRM_UPLOAD_FULFILLED':
    case 'VALIDATE_DATAFILE_FULFILLED':
      return { ...state, errorMessage: '', isBusy: false };

    case 'DATA_INGEST_GET_EXISTING_COLUMNS_FULFILLED': {
      const colMap = {};
      const cols = [...action.payload];
      cols.forEach((item) => {
        colMap[item.friendlyName] = item;
      });
      return {
        ...state,
        existingColumns: cols,
        existingColumnsMap: colMap,
        isBusy: false,
      };
    }

    case 'VALIDATE_KEY_COLUMNS_FULFILLED': {
      const newDataset = { ...state.dataset };
      newDataset.dataColumns = [...action.payload];
      newDataset.dataColumns.forEach((item) => {
        if (item.colTag === '' || item.colTag === null) {
          // eslint-disable-next-line no-param-reassign
          item.colTag = ColTagTypeModel.CONTEXTUAL_DATA;
        }
      });
      return { ...state, dataset: newDataset, errorMessage: '', isBusy: false };
    }

    case 'SAMPLE_DATAFILE_FULFILLED':
      return {
        ...state,
        columnMeta: action.payload.columnMeta,
        columnDisplay: action.payload.columnDisplay,
        columnSamples: action.payload.columnSamples,
        errorMessage: '',
        isBusy: false,
      };

    case 'DATA_INGEST_UPDATE_COLUMN_METADATA': {
      const newColMeta = [...state.columnMeta];
      const index = newColMeta.findIndex(
        (item) => item.nameInOriginalSource === action.payload.columnName
      );
      if (index >= 0) {
        newColMeta[index] = action.payload.data;
      }
      return { ...state, columnMeta: newColMeta, errorMessage: '' };
    }

    case 'ON_SELECT_DATAFILE': {
      const newDatasetModel = { ...state.dataset };
      newDatasetModel.fileName = action.payload.dataFileName;
      return {
        ...state,
        dataFile: action.payload.dataFile,
        dataFileName: action.payload.dataFileName,
        dataset: newDatasetModel,
      };
    }
    case 'DATA_INGEST_INCLUDE_COL': {
      const newColMeta = [...state.columnMeta];
      newColMeta[action.payload.index].isExcluded = action.payload.val;
      return { ...state, columnMeta: newColMeta, errorMessage: '' };
    }
    case 'DATA_INGEST_SET_COL_FRIENDLYNAME': {
      const newColMeta = [...state.columnMeta];

      if (
        state.columnMeta.some((col, i) =>
          i === action.payload.index
            ? false
            : col.friendlyName === action.payload.val
        )
      ) {
        return {
          ...state,
          txtInputErrMsg: {
            target: action.payload.index,
            message: 'Duplicated name',
          },
          errorMessage: '',
        };
      }
      if (action.payload.val === '')
        newColMeta[action.payload.index].friendlyName =
          newColMeta[action.payload.index].nameInOriginalSource;
      else newColMeta[action.payload.index].friendlyName = action.payload.val;
      return {
        ...state,
        columnMeta: newColMeta,
        txtInputErrMsg: {},
        errorMessage: '',
      };
    }
    case 'DATA_INGEST_SET_COL_ENCRYPTION': {
      const newColMeta = [...state.columnMeta];
      newColMeta[action.payload.index].isEncrypted = action.payload.val;
      return {
        ...state,
        columnMeta: newColMeta,
        txtInputErrMsg: {},
        errorMessage: '',
      };
    }
    case 'DATA_INGEST_FINALIZE_COLUMN_META': {
      const newDatasetModel = { ...state.dataset };
      newDatasetModel.customJourneyDuration =
        action.payload.durationLabel === 'Auto Only'
          ? ''
          : action.payload.durationLabel;
      newDatasetModel.dataColumns = [...state.columnMeta];
      newDatasetModel.dataColumns.forEach((item, i) => {
        if (item.colTag === '' || item.colTag === null) {
          // eslint-disable-next-line no-param-reassign
          item.colTag = ColTagTypeModel.CONTEXTUAL_DATA;
        }
        if (
          item.associatedColumn &&
          state.existingColumnsMap &&
          state.existingColumnsMap[item.associatedColumn]
        ) {
          const existingItem = state.existingColumnsMap[item.associatedColumn];
          newDatasetModel.dataColumns[i] = { ...existingItem };
          newDatasetModel.dataColumns[i].columnIndexOriginalSource =
            item.columnIndexOriginalSource;
        }

        // eslint-disable-next-line no-param-reassign
        delete item.associatedColumn;
      });
      return {
        ...state,
        dataset: newDatasetModel,
        errorMessage: '',
        isBusy: false,
      };
    }
    case 'RESET_STEPS':
      return { ...initState };
    default:
      return state;
  }
}
