import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import {
  DataGridPremium,
  GridActionsCellItem,
  GridRowModes,
} from '@mui/x-data-grid-premium';
import { Delete } from '@mui/icons-material';
import { StyledTextField } from './styledComponents';
import {
  formatObjectiveTypeUpdateRequest,
  formatObjectiveTypeAddRequest,
} from './formatUserGroupConfigUpdateRequest';
import { CONFIG_VIEW_MODE } from './userGroupConfigConstants';
import { ConfigViewContext } from './configViewContext';

const ObjectiveTypeEditWrapper = styled(Box)({
  width: '100%',
});

const TitleWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: 24,
  marginTop: -36,
});

const TextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 4,
});

const PrimaryText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.offWhiteFontColor,
}));

const SecondaryText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
}));

const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 16,
});

const RowWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const FlexRowWrapper = styled('div')({
  display: 'flex',
  gap: 16,
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  gap: 8,
  position: 'sticky',
  top: 24,
  height: 36,
  zIndex: 5,
});

const StyledSaveButton = styled(Button)(({ theme: { themeColors } }) => ({
  '&.Mui-disabled': {
    backgroundColor: themeColors.objectiveTypeSaveButtonDisabledColor,
  },
}));

const LoadingIcon = styled(CircularProgress)(({ theme: { themeColors } }) => ({
  display: 'block',
  marginLeft: 7,
  color: themeColors.primaryTextColor,
}));

const TableContainer = styled('div')({
  height: '100%',
});

const StyledDataGrid = styled(DataGridPremium)(
  ({ theme: { themeColors } }) => ({
    border: `1px solid ${themeColors.borderLowContrast}`,
    borderRadius: 4,
    '.MuiDataGrid-cell.MuiDataGrid-cell--editing, .MuiDataGrid-cell.MuiDataGrid-cell--withRenderer':
      {
        boxShadow: 'none',
        backgroundColor: 'inherit',
      },
    '.MuiDataGrid-row.MuiDataGrid-row--lastVisible': {
      borderBottom: `1px solid ${themeColors.borderLowContrast}`,
    },
    '.MuiDataGrid-columnHeaderTitleContainer': {
      paddingLeft: '8px',
    },
  })
);

const DataGridCell = styled('span')(({ theme: { themeColors } }) => ({
  paddingLeft: '8px',
  color: themeColors.offWhiteFontColor,
}));

const EmptyDataGridCell = styled(DataGridCell)(
  ({ theme: { themeColors } }) => ({
    border: `2px solid ${themeColors.danger60}`,
    width: 'calc(100% + 20px)',
    height: '100%',
    marginLeft: '-10px',
    marginRight: '-10px',
  })
);

const BasketInformationCellRenderer = ({ value }) => {
  const isEmpty = !value.length;
  if (isEmpty) {
    return <EmptyDataGridCell />;
  }
  return <DataGridCell>{value}</DataGridCell>;
};

const StyledDeleteIcon = styled(Delete)(({ theme: { themeColors } }) => ({
  color: themeColors.objectiveTypeDeleteIconColor,
}));

const FlexFooterWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  paddingLeft: '4px',
  height: '52px',
});

const StyledFooterButton = styled(Button)(({ theme: { themeColors } }) => ({
  color: themeColors.buttonSecondaryBackgroundColour,
  fontWeight: 400,
}));

const AddBasketGridFooter = ({ handleAddBasket }) => (
  <FlexFooterWrapper>
    <StyledFooterButton variant="text" onClick={() => handleAddBasket()}>
      Add Basket +
    </StyledFooterButton>
  </FlexFooterWrapper>
);

const EmptyNoRowsOverlay = () => <Box />;

const DataGridHelperMessage = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.neutral60,
    marginTop: '8px',
    padding: '0 8px',
    fontWeight: 400,
  })
);

export default function ObjectiveTypeEdit({
  objectiveType,
  basketInformationGridRows,
  removeAddObjectiveType,
  onSaveEditObjectiveType,
  onSaveAddObjectiveType,
  isEditObjectiveTypeSaving,
  isAddObjectiveTypeSaving,
}) {
  const {
    objectiveTypeId,
    type: objectiveTypeType,
    typeDisplay: objectiveTypeDisplay,
    typeDescription: objectiveTypeDescription,
  } = objectiveType;
  const { configViewMode, setConfigViewMode } = useContext(ConfigViewContext);
  const [editObjectiveTypeType, setEditObjectiveTypeType] =
    useState(objectiveTypeType);
  const [editObjectiveTypeDisplay, setEditObjectiveTypeDisplay] =
    useState(objectiveTypeDisplay);
  const [editObjectiveTypeDescription, setEditObjectiveTypeDescription] =
    useState(objectiveTypeDescription);
  const [editBasketInformation, setEditBasketInformation] = useState(
    basketInformationGridRows
  );
  const [basketInformationEdited, setBasketInformationEdited] = useState(false);
  const [hasEmptyCells, setHasEmptyCells] = useState(false);
  const [hasNoBaskets, setHasNoBaskets] = useState(
    basketInformationGridRows.length === 0
  );
  const objectiveTypeEditMade =
    objectiveTypeType !== editObjectiveTypeType ||
    objectiveTypeDisplay !== editObjectiveTypeDisplay ||
    objectiveTypeDescription !== editObjectiveTypeDescription ||
    basketInformationEdited;
  const canSaveObjectiveType =
    objectiveTypeEditMade &&
    !hasEmptyCells &&
    editObjectiveTypeType !== '' &&
    editObjectiveTypeDisplay !== '' &&
    editObjectiveTypeDescription !== '' &&
    !hasNoBaskets;
  const saveInProgressText =
    configViewMode === CONFIG_VIEW_MODE.CREATE ? 'Creating' : 'Saving';
  const saveButtonText =
    configViewMode === CONFIG_VIEW_MODE.CREATE ? 'Create and Save' : 'Save';

  const [rowModesModel, setRowModesModel] = useState({});

  const handleDeleteRowClick = ({ id }) => {
    const newBasketInformation = editBasketInformation.filter(
      (row) => row.id !== id
    );
    setEditBasketInformation(newBasketInformation);
    setBasketInformationEdited(
      validateBasketInformationChanged(newBasketInformation)
    );
    setHasEmptyCells(!validateNoEmptyCells(newBasketInformation));
    setHasNoBaskets(validateNoBaskets(newBasketInformation));
  };

  const basketInformationEditColumnConfig = () => {
    const columnCommonProperties = {
      flex: 1,
      editable: true,
      renderCell: (params) => <BasketInformationCellRenderer {...params} />,
    };
    const columnConfig = [
      {
        field: 'productFriendlyName',
        headerName: 'Product Friendly Name',
        ...columnCommonProperties,
      },
      {
        field: 'paramText',
        headerName: 'ParamText',
        ...columnCommonProperties,
      },
      {
        field: 'paramType',
        headerName: 'ParamType',
        ...columnCommonProperties,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            icon={<StyledDeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteRowClick({ id })}
          />,
        ],
      },
    ];
    return columnConfig;
  };

  const validateTextFieldContainsLetter = (textFieldInput) => {
    const letterRegex = /[A-Za-z]/;
    return textFieldInput.length === 0 || textFieldInput.match(letterRegex);
  };

  const validateNoDuplicateBasketTypes = (updatedRow, basketInformation) =>
    basketInformation.filter(
      (basket) =>
        basket.id !== updatedRow.id && basket.paramType === updatedRow.paramType
    ).length === 0;

  const validateNoEmptyCells = (basketInformation) =>
    basketInformation.every((basket) => {
      const { id, ...basketWithoutId } = basket;
      return Object.values(basketWithoutId).every((value) => value.length > 0);
    });

  const validateBasketInformationChanged = (newBasketInformation) => {
    if (basketInformationGridRows.length === newBasketInformation.length) {
      for (let i = 0; i < basketInformationGridRows.length; i++) {
        const row1 = basketInformationGridRows[i];
        const row2 = newBasketInformation[i];
        if (
          row1.productFriendlyName !== row2.productFriendlyName ||
          row1.paramType !== row2.paramType ||
          row1.paramText !== row2.paramText
        ) {
          return true;
        }
      }
      return false;
    }
    return true;
  };

  const validateNoBaskets = (basketInformation) =>
    basketInformation.length === 0;

  const handleBasketInformationGridEdit = (updatedRow) => {
    const originalRow = editBasketInformation[updatedRow.id];
    if (validateNoDuplicateBasketTypes(updatedRow, editBasketInformation)) {
      const newBasketInformation = [...editBasketInformation];
      newBasketInformation[updatedRow.id] = updatedRow;
      setEditBasketInformation(newBasketInformation);
      setBasketInformationEdited(
        validateBasketInformationChanged(newBasketInformation)
      );
      setHasEmptyCells(!validateNoEmptyCells(newBasketInformation));
      return updatedRow;
    }
    return originalRow;
  };

  const handleAddBasket = () => {
    const newRowId = editBasketInformation.length;
    setEditBasketInformation((oldBasketInformation) => [
      ...oldBasketInformation,
      {
        id: newRowId,
        productFriendlyName: '',
        paramType: '',
        paramText: '',
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newRowId]: {
        mode: GridRowModes.Edit,
        fieldToFocus: 'productFriendlyName',
      },
    }));
    setHasEmptyCells(true);
    setHasNoBaskets(false);
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleCancelClick = () => {
    removeAddObjectiveType();
    setConfigViewMode(CONFIG_VIEW_MODE.VIEW);
  };

  return (
    <ObjectiveTypeEditWrapper>
      <ButtonWrapper>
        <Button onClick={() => handleCancelClick()} variant="outlined">
          Cancel
        </Button>
        <StyledSaveButton
          variant="contained"
          disabled={!canSaveObjectiveType}
          onClick={() => {
            const nonBasketInformation = {
              type: editObjectiveTypeType,
              typeDisplay: editObjectiveTypeDisplay,
              typeDescription: editObjectiveTypeDescription,
            };
            if (configViewMode === CONFIG_VIEW_MODE.CREATE) {
              onSaveAddObjectiveType({
                requestBody: formatObjectiveTypeAddRequest(
                  editBasketInformation,
                  nonBasketInformation
                ),
              });
            } else {
              onSaveEditObjectiveType({
                objectiveTypeIdToUpdate: objectiveTypeId,
                requestBody: formatObjectiveTypeUpdateRequest(
                  objectiveType,
                  editBasketInformation,
                  nonBasketInformation
                ),
              });
            }
          }}
        >
          {isEditObjectiveTypeSaving || isAddObjectiveTypeSaving ? (
            <>
              {saveInProgressText}
              <LoadingIcon size="11px" />
            </>
          ) : (
            saveButtonText
          )}
        </StyledSaveButton>
      </ButtonWrapper>
      <TitleWrapper>
        <TextWrapper>
          <PrimaryText variant="h5">Objective Type Information</PrimaryText>
          <SecondaryText variant="body2">
            Assign identifying information for this objective type
          </SecondaryText>
        </TextWrapper>
      </TitleWrapper>
      <ContentWrapper>
        <RowWrapper>
          <FlexRowWrapper>
            <StyledTextField
              label="Type*"
              value={editObjectiveTypeType}
              onChange={(event) => setEditObjectiveTypeType(event.target.value)}
              isError={!validateTextFieldContainsLetter(editObjectiveTypeType)}
              placeholder="Add Type"
              focused
            />
            <StyledTextField
              label="Type Display*"
              value={editObjectiveTypeDisplay}
              onChange={(event) =>
                setEditObjectiveTypeDisplay(event.target.value)
              }
              isError={
                !validateTextFieldContainsLetter(editObjectiveTypeDisplay)
              }
              placeholder="Add Type Display"
              focused
            />
          </FlexRowWrapper>
        </RowWrapper>
        <RowWrapper>
          <StyledTextField
            label="Type Description*"
            value={editObjectiveTypeDescription}
            onChange={(event) =>
              setEditObjectiveTypeDescription(event.target.value)
            }
            isError={
              !validateTextFieldContainsLetter(editObjectiveTypeDescription)
            }
            multiline
            placeholder="Add Description"
            focused
          />
        </RowWrapper>
        <RowWrapper>
          <TextWrapper>
            <PrimaryText variant="h5">Basket Information*</PrimaryText>
            <SecondaryText variant="body2">
              Add, delete, or click into a cell to edit information regarding
              baskets for this objective type
            </SecondaryText>
          </TextWrapper>
        </RowWrapper>
        <RowWrapper>
          <TableContainer>
            <StyledDataGrid
              rows={editBasketInformation}
              columns={basketInformationEditColumnConfig()}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              processRowUpdate={(updatedRow) =>
                handleBasketInformationGridEdit(updatedRow)
              }
              slots={{
                footer: AddBasketGridFooter,
                noRowsOverlay: EmptyNoRowsOverlay,
              }}
              slotProps={{
                footer: { handleAddBasket },
              }}
              hideFooterSelectedRowCount
              hideFooterRowCount
            />
            {hasNoBaskets && (
              <DataGridHelperMessage variant="body2">
                Must add a basket to continue
              </DataGridHelperMessage>
            )}
          </TableContainer>
        </RowWrapper>
      </ContentWrapper>
    </ObjectiveTypeEditWrapper>
  );
}
