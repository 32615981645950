/* eslint-disable no-loss-of-precision */

export const territoryOverviewMockMetadata = {
  objectives: [
    {
      id: 'e2a5a9e366fd4295a66d575a7f07470f',
      label: 'TRELEGY 200 vs ICS/LABA + SITT',
      metrics: [
        {
          rxType: 'zone_demand',
          label: 'TRx',
        },
      ],
    },
  ],
  products: [
    {
      id: 'trelegy-200',
      label: 'Trelegy 200',
      metrics: [
        {
          rxType: 'zone_demand',
          label: 'TRx',
        },
      ],
    },
  ],
  availableDistributions: ['demand', 'supply'],
  activityChannels: [
    'email',
    'f2f accompanied',
    'f2f short call',
    'f2f',
    'virtual',
    'virtual accompanied',
    'item drop only',
    'sample drop only',
    'phone call',
    'web call',
  ],
};

export const territoryOverviewMockDemandData = {
  timescaleKeys: [
    {
      id: 'month',
      name: 'Month',
    },
    {
      id: 'quarter',
      name: 'Quarter',
    },
  ],
  timescaleData: {
    month: {
      description: 'April 2023 - Compared to last month',
      headers: [
        {
          label: 'Region',
          subLabel: '',
          type: 'label',
        },
        {
          label: 'Activity',
          subLabel: '',
          type: 'number',
          graph: 'bar',
          chartLabels: [
            'November',
            'December',
            'January',
            'February',
            'March',
            'April',
          ],
        },
        {
          label: 'CONTRAVE',
          subLabel: 'Volume TRx',
          type: 'number',
          graph: 'line',
          chartLabels: [
            'November',
            'December',
            'January',
            'February',
            'March',
            'April',
          ],
        },
        {
          label: 'CONTRAVE',
          subLabel: 'Share TRx',
          type: 'percent',
          graph: 'line',
          chartLabels: [
            'November',
            'December',
            'January',
            'February',
            'March',
            'April',
          ],
        },
        {
          label: 'SAXENDA',
          subLabel: 'Share TRx',
          type: 'percent',
          graph: 'line',
          chartLabels: [
            'November',
            'December',
            'January',
            'February',
            'March',
            'April',
          ],
        },
        {
          label: 'XENICAL',
          subLabel: 'Share TRx',
          type: 'percent',
          graph: 'line',
          chartLabels: [
            'November',
            'December',
            'January',
            'February',
            'March',
            'April',
          ],
        },
      ],
      items: [
        [
          {
            data: 'H7A',
          },
          {
            data: [3.0, 8.0, null, 2.0, 2.0, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [33.0, 19.0, 28.0, 26.0, 36.0, 45000.0],
            trend: {
              value: 1,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.3173076808452606, 0.1899999976158142, 0.2857142984867096,
              0.33766233921051025, 0.378947377204895, 0.4326923191547394,
            ],
            trend: {
              value: 0.132,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5961538553237915, 0.6899999976158142, 0.581632673740387,
              0.5714285969734192, 0.621052622795105, 0.5288461446762085,
            ],
            trend: {
              value: -0.145,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.08653846383094788,
              0.11999999731779099,
              0.13265305757522583,
              0.09090909361839294,
              null,
              0.03846153989434242,
            ],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'H7B',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'H7C',
          },
          {
            data: [null, 10.0, 2.0, 3.0, null, 9000.0],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'H7E',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [13.0, 9.0, 11.0, 11.0, 14.0, 10.0],
            trend: {
              value: -0.286,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.38235294818878174, 0.2368421107530594, 0.36666667461395264,
              0.4399999976158142, 0.3499999940395355, 0.2631579041481018,
            ],
            trend: {
              value: -0.257,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.5588235259056091, 0.6842105388641357, 0.5666666626930237,
              0.47999998927116394, 0.625, 0.6052631735801697,
            ],
            trend: {
              value: -0.016,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.05882352963089943, 0.07894736528396606, 0.06666667014360428,
              0.07999999821186066, 0.02500000037252903, 0.1315789520740509,
            ],
            trend: {
              value: 3.333,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'H7G',
          },
          {
            data: [6.0, 3.0, 3.0, 4.0, 9.0, 2.0],
            trend: {
              value: -0.778,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [9.0, 10.0, 14.0, 14.0, 14.0, 15.0],
            trend: {
              value: 0.071,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.36000001430511475, 0.4000000059604645, 0.5185185074806213,
              0.5384615659713745, 0.46666666865348816, 0.5769230723381042,
            ],
            trend: {
              value: 0.234,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.6000000238418579, 0.6000000238418579, 0.40740740299224854,
              0.42307692766189575, 0.5, 0.38461539149284363,
            ],
            trend: {
              value: -0.24,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.03999999910593033,
              null,
              0.07407407462596893,
              0.03846153989434242,
              0.03333333507180214,
              0.03846153989434242,
            ],
            trend: {
              value: 0.333,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'H7H',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [8.0, 9.0, 4.0, 9.0, 8.0, 14.0],
            trend: {
              value: 0.75,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.3076923191547394, 0.29032257199287415, 0.1428571492433548,
              0.3214285671710968, 0.2666666805744171, 0.4516128897666931,
            ],
            trend: {
              value: 0.667,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.6153846383094788, 0.6451612710952759, 0.75, 0.6071428656578064,
              0.6333333253860474, 0.4838709533214569,
            ],
            trend: {
              value: -0.238,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.07692307978868484, 0.06451612710952759, 0.1071428582072258,
              0.0714285746216774, 0.10000000149011612, 0.06451612710952759,
            ],
            trend: {
              value: -0.4,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'H7J',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'H7K',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'H7L',
          },
          {
            data: [1.0, 1.0, null, null, null, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [18.0, 17.0, 10.0, 17.0, 19.0, 16.0],
            trend: {
              value: -0.158,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.44999998807907104, 0.4722222089767456, 0.2631579041481018,
              0.4146341383457184, 0.41304346919059753, 0.42105263471603394,
            ],
            trend: {
              value: 0.024,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.42500001192092896, 0.4722222089767456, 0.5789473652839661,
              0.4390243887901306, 0.43478259444236755, 0.5263158082962036,
            ],
            trend: {
              value: 0.233,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.125, 0.0555555559694767, 0.15789473056793213,
              0.1463414579629898, 0.15217390656471252, 0.05263157933950424,
            ],
            trend: {
              value: -0.667,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'H7M',
          },
          {
            data: [35.0, 16.0, 9.0, 10.0, 1.0, 3.0],
            trend: {
              value: 2.0,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [8.0, 7.0, 7.0, 3.0, 11.0, 5.0],
            trend: {
              value: -0.545,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.3076923191547394, 0.2916666567325592, 0.3888888955116272,
              0.1875, 0.4399999976158142, 0.2380952388048172,
            ],
            trend: {
              value: -0.455,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.5384615659713745, 0.5416666865348816, 0.5555555820465088,
              0.5625, 0.4000000059604645, 0.5714285969734192,
            ],
            trend: {
              value: 0.425,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.1538461595773697, 0.1666666716337204, 0.0555555559694767, 0.25,
              0.1599999964237213, 0.190476194024086,
            ],
            trend: {
              value: 0.188,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'H7N',
          },
          {
            data: [3.0, null, null, null, null, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [9.0, 10.0, 6.0, 10.0, 13.0, 12.0],
            trend: {
              value: -0.077,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.3103448152542114, 0.37037035822868347, 0.260869562625885,
              0.32258063554763794, 0.40625, 0.31578946113586426,
            ],
            trend: {
              value: -0.22,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.6551724076271057, 0.5925925970077515, 0.739130437374115,
              0.6451612710952759, 0.59375, 0.6315789222717285,
            ],
            trend: {
              value: 0.068,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.03448275849223137,
              0.03703703731298447,
              null,
              0.032258063554763794,
              null,
              0.05263157933950424,
            ],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'H7P',
          },
          {
            data: [5.0, 3.0, 7.0, 3.0, 5.0, 5.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
          {
            data: [27.0, 19.0, 31.0, 30.0, 32.0, 32.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
          {
            data: [
              0.44262295961380005, 0.27941176295280457, 0.45588234066963196,
              0.5454545617103577, 0.5333333611488342, 0.4324324429035187,
            ],
            trend: {
              value: -0.189,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.49180328845977783, 0.5147058963775635, 0.44117647409439087,
              0.34545454382896423, 0.3499999940395355, 0.37837839126586914,
            ],
            trend: {
              value: 0.086,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.06557376682758331, 0.20588235557079315, 0.10294117778539658,
              0.1090909093618393, 0.11666666716337204, 0.18918919563293457,
            ],
            trend: {
              value: 0.583,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'H7R',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [12.0, 8.0, 9.0, 11.0, 12.0, 14.0],
            trend: {
              value: 0.167,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.4137931168079376, 0.27586206793785095, 0.375,
              0.42307692766189575, 0.3529411852359772, 0.5,
            ],
            trend: {
              value: 0.429,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.48275861144065857, 0.5862069129943848, 0.625,
              0.4615384638309479, 0.5588235259056091, 0.4285714328289032,
            ],
            trend: {
              value: -0.232,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.1034482792019844,
              0.13793103396892548,
              null,
              0.11538461595773697,
              0.0882352963089943,
              0.0714285746216774,
            ],
            trend: {
              value: -0.222,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'H7S',
          },
          {
            data: [7.0, 1.0, null, 2.0, 3.0, 1.0],
            trend: {
              value: -0.667,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'H7T',
          },
          {
            data: [25.0, 12.0, 3.0, 1.0, 5.0, 6.0],
            trend: {
              value: 0.2,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [7.0, 8.0, 4.0, 7.0, 12.0, 8.0],
            trend: {
              value: -0.333,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.26923078298568726, 0.3076923191547394, 0.17391304671764374,
              0.30434781312942505, 0.3870967626571655, 0.2857142984867096,
            ],
            trend: {
              value: -0.256,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.6153846383094788, 0.5769230723381042, 0.695652186870575,
              0.5652173757553101, 0.5161290168762207, 0.6785714030265808,
            ],
            trend: {
              value: 0.308,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.11538461595773697, 0.11538461595773697, 0.1304347813129425,
              0.1304347813129425, 0.09677419066429138, 0.0357142873108387,
            ],
            trend: {
              value: -0.6,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'H7V',
          },
          {
            data: [5.0, 3.0, 1.0, 3.0, 2.0, 2.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
          {
            data: [5.0, 3.0, 7.0, 6.0, 9.0, 11.0],
            trend: {
              value: 0.222,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.2631579041481018, 0.1764705926179886, 0.3499999940395355,
              0.31578946113586426, 0.375, 0.4399999976158142,
            ],
            trend: {
              value: 0.158,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5789473652839661, 0.5882353186607361, 0.550000011920929,
              0.4736842215061188, 0.5416666865348816, 0.4000000059604645,
            ],
            trend: {
              value: -0.259,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.15789473056793213, 0.23529411852359772, 0.10000000149011612,
              0.21052631735801697, 0.0833333358168602, 0.1599999964237213,
            ],
            trend: {
              value: 1.0,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'H7W',
          },
          {
            data: [7.0, 3.0, 2.0, 3.0, null, 2.0],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [1.0, null, 1.0, 2.0, 2.0, 3.0],
            trend: {
              value: 0.5,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.07692307978868484,
              null,
              0.09090909361839294,
              0.1428571492433548,
              0.20000000298023224,
              0.3333333432674408,
            ],
            trend: {
              value: 0.65,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.9230769276618958, 0.8571428656578064, 0.7272727489471436,
              0.7857142686843872, 0.800000011920929, 0.5555555820465088,
            ],
            trend: {
              value: -0.3,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              null,
              0.1428571492433548,
              0.1818181872367859,
              0.0714285746216774,
              null,
              0.1111111119389534,
            ],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'H7X',
          },
          {
            data: [null, 5.0, 8.0, 10.0, 1.0, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [21.0, 16.0, 16.0, 10.0, 9.0, 10.0],
            trend: {
              value: 0.111,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.2957746386528015, 0.47058823704719543, 0.30188679695129395,
              0.3030303120613098, 0.15789473056793213, 0.17543859779834747,
            ],
            trend: {
              value: 0.125,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5492957830429077, 0.4117647111415863, 0.5660377144813538,
              0.5151515007019043, 0.7543859481811523, 0.719298243522644,
            ],
            trend: {
              value: -0.04,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.15492957830429077, 0.11764705926179886, 0.1320754736661911,
              0.1818181872367859, 0.08771929889917374, 0.10526315867900848,
            ],
            trend: {
              value: 0.222,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'H7Y',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [25.0, 22.0, 24.0, 24.0, 28.0, 25.0],
            trend: {
              value: -0.107,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.29411765933036804, 0.2650602459907532, 0.3287671208381653,
              0.3037974536418915, 0.30434781312942505, 0.2976190447807312,
            ],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
          {
            data: [
              0.4941176474094391, 0.6144578456878662, 0.534246563911438,
              0.5696202516555786, 0.52173912525177, 0.5952380895614624,
            ],
            trend: {
              value: 0.154,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.21176470816135406, 0.1204819306731224, 0.13698630034923553,
              0.1265822798013687, 0.17391304671764374, 0.1071428582072258,
            ],
            trend: {
              value: -0.353,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'J0N',
          },
          {
            data: [7.0, null, null, null, null, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [17.0, 13.0, 18.0, 18.0, 21.0, 18.0],
            trend: {
              value: -0.143,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.2931034564971924, 0.2063492089509964, 0.3461538553237915,
              0.2950819730758667, 0.31343284249305725, 0.2857142984867096,
            ],
            trend: {
              value: -0.065,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.48275861144065857, 0.6349206566810608, 0.48076921701431274,
              0.5409836173057556, 0.4776119291782379, 0.5714285969734192,
            ],
            trend: {
              value: 0.187,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.22413793206214905, 0.1587301641702652, 0.17307692766189575,
              0.16393442451953888, 0.20895522832870483, 0.1428571492433548,
            ],
            trend: {
              value: -0.333,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'J0R',
          },
          {
            data: [11.0, 2.0, null, null, null, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [17.0, 12.0, 11.0, 12.0, 21.0, 16.0],
            trend: {
              value: -0.238,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.4146341383457184, 0.34285715222358704, 0.29729729890823364,
              0.34285715222358704, 0.5, 0.5,
            ],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
          {
            data: [
              0.4878048896789551, 0.5142857432365417, 0.5945945978164673,
              0.5714285969734192, 0.4047619104385376, 0.4375,
            ],
            trend: {
              value: 0.1,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.09756097197532654, 0.1428571492433548, 0.10810811072587967,
              0.08571428805589676, 0.095238097012043, 0.0625,
            ],
            trend: {
              value: -0.4,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'J5J',
          },
          {
            data: [null, null, 3.0, null, null, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [20.0, 20.0, 13.0, 12.0, 20.0, 14.0],
            trend: {
              value: -0.3,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.2531645596027374, 0.2666666805744171, 0.21311475336551666,
              0.27272728085517883, 0.25974026322364807, 0.2545454502105713,
            ],
            trend: {
              value: -0.038,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.6202531456947327, 0.6266666650772095, 0.6065573692321777,
              0.6363636255264282, 0.649350643157959, 0.5636363625526428,
            ],
            trend: {
              value: -0.138,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.1265822798013687, 0.1066666692495346, 0.1803278625011444,
              0.09090909361839294, 0.09090909361839294, 0.1818181872367859,
            ],
            trend: {
              value: 1.0,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'J5K',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'J5L',
          },
          {
            data: [1.0, null, 1.0, null, null, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'J6W',
          },
          {
            data: [2.0, 1.0, 1.0, null, 7.0, 4.0],
            trend: {
              value: -0.429,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [23.0, 15.0, 22.0, 19.0, 17.0, 15.0],
            trend: {
              value: -0.118,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.3484848439693451, 0.24193547666072845, 0.37931033968925476,
              0.34545454382896423, 0.265625, 0.2884615361690521,
            ],
            trend: {
              value: 0.074,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5454545617103577, 0.6612903475761414, 0.517241358757019,
              0.5636363625526428, 0.625, 0.5961538553237915,
            ],
            trend: {
              value: -0.032,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.10606060922145844, 0.09677419066429138, 0.1034482792019844,
              0.09090909361839294, 0.109375, 0.11538461595773697,
            ],
            trend: {
              value: 0.091,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'J6X',
          },
          {
            data: [2.0, 5.0, 4.0, null, 13.0, 1.0],
            trend: {
              value: -0.923,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [47.0, 48.0, 60.0, 47.0, 55.0, 64.0],
            trend: {
              value: 0.164,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.39830508828163147, 0.39024388790130615, 0.4958677589893341,
              0.4653465449810028, 0.4166666567325592, 0.4885496199131012,
            ],
            trend: {
              value: 0.167,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5677965879440308, 0.5691056847572327, 0.4545454680919647,
              0.49504950642585754, 0.5378788113594055, 0.4885496199131012,
            ],
            trend: {
              value: -0.093,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.033898305147886276, 0.04065040498971939, 0.04958677664399147,
              0.039603959769010544, 0.04545454680919647, 0.022900763899087906,
            ],
            trend: {
              value: -0.6,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'J6Y',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [19.0, 12.0, 11.0, 16.0, 16.0, 11.0],
            trend: {
              value: -0.312,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.3799999952316284, 0.2666666805744171, 0.24444444477558136,
              0.3137255012989044, 0.35555556416511536, 0.25581395626068115,
            ],
            trend: {
              value: -0.278,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.5400000214576721, 0.6000000238418579, 0.6666666865348816,
              0.5882353186607361, 0.6000000238418579, 0.6511628031730652,
            ],
            trend: {
              value: 0.083,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.07999999821186066, 0.13333334028720856, 0.08888889104127884,
              0.09803921729326248, 0.04444444552063942, 0.09302325546741486,
            ],
            trend: {
              value: 1.25,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'J6Z',
          },
          {
            data: [4.0, 5.0, 2.0, 3.0, 5.0, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [20.0, 19.0, 17.0, 13.0, 19.0, 12.0],
            trend: {
              value: -0.368,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.40816327929496765, 0.42222222685813904, 0.3695652186870575,
              0.31707316637039185, 0.4523809552192688, 0.3529411852359772,
            ],
            trend: {
              value: -0.222,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.5510203838348389, 0.5555555820465088, 0.5869565010070801,
              0.6585366129875183, 0.5476190447807312, 0.6176470518112183,
            ],
            trend: {
              value: 0.127,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.040816325694322586,
              0.02222222276031971,
              0.043478261679410934,
              0.024390242993831635,
              null,
              0.029411764815449715,
            ],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'J7A',
          },
          {
            data: [4.0, null, 3.0, 3.0, null, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [8.0, 9.0, 8.0, 2.0, 6.0, 5.0],
            trend: {
              value: -0.167,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.380952388048172, 0.4285714328289032, 0.4000000059604645,
              0.1818181872367859, 0.4000000059604645, 0.3125,
            ],
            trend: {
              value: -0.225,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.6190476417541504, 0.5714285969734192, 0.6000000238418579,
              0.8181818127632141, 0.6000000238418579, 0.6875,
            ],
            trend: {
              value: 0.15,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'J7B',
          },
          {
            data: [11.0, null, 6.0, 4.0, null, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [53.0, 51.0, 51.0, 23.0, 21.0, 46.0],
            trend: {
              value: 1.19,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.4173228442668915, 0.3493150770664215, 0.3893129825592041,
              0.2839506268501282, 0.18103447556495667, 0.38333332538604736,
            ],
            trend: {
              value: 1.111,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5433070659637451, 0.5958904027938843, 0.5572519302368164,
              0.7037037014961243, 0.7413793206214905, 0.5666666626930237,
            ],
            trend: {
              value: -0.23,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.03937007859349251, 0.054794520139694214, 0.05343511328101158,
              0.012345679104328156, 0.07758620381355286, 0.05000000074505806,
            ],
            trend: {
              value: -0.375,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'J7C',
          },
          {
            data: [1.0, null, null, null, 1.0, 4.0],
            trend: {
              value: 3.0,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [15.0, 12.0, 15.0, 18.0, 16.0, 21.0],
            trend: {
              value: 0.312,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.3125, 0.2142857164144516, 0.2678571343421936,
              0.4285714328289032, 0.26229506731033325, 0.3684210479259491,
            ],
            trend: {
              value: 0.423,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.6458333134651184, 0.6964285969734192, 0.6785714030265808,
              0.5476190447807312, 0.688524603843689, 0.5964912176132202,
            ],
            trend: {
              value: -0.13,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.0416666679084301, 0.0892857164144516, 0.0535714291036129,
              0.02380952425301075, 0.04918032884597778, 0.035087719559669495,
            ],
            trend: {
              value: -0.2,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'J7E',
          },
          {
            data: [2.0, null, null, 2.0, 2.0, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [27.0, 33.0, 20.0, 19.0, 37.0, 34.0],
            trend: {
              value: -0.081,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.3375000059604645, 0.3510638177394867, 0.2777777910232544,
              0.2467532455921173, 0.3057851195335388, 0.34343433380126953,
            ],
            trend: {
              value: 0.097,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.625, 0.6276595592498779, 0.6944444179534912, 0.7272727489471436,
              0.6528925895690918, 0.6363636255264282,
            ],
            trend: {
              value: -0.015,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.03750000149011612, 0.021276595070958138, 0.02777777798473835,
              0.025974025949835777, 0.041322313249111176, 0.020202020183205605,
            ],
            trend: {
              value: -0.5,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'J7G',
          },
          {
            data: [null, 3.0, 1.0, null, 2.0, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [29.0, 30.0, 32.0, 25.0, 27.0, 23.0],
            trend: {
              value: -0.148,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.37662336230278015, 0.3488371968269348, 0.3855421543121338,
              0.36764705181121826, 0.28421053290367126, 0.29487180709838867,
            ],
            trend: {
              value: 0.036,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5454545617103577, 0.5930232405662537, 0.5421686768531799,
              0.5882353186607361, 0.6421052813529968, 0.6282051205635071,
            ],
            trend: {
              value: -0.016,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.07792207598686218, 0.058139536529779434, 0.07228915393352509,
              0.04411764815449715, 0.0736842080950737, 0.07692307978868484,
            ],
            trend: {
              value: 0.143,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'J7H',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'J7J',
          },
          {
            data: [6.0, 5.0, null, 8.0, 5.0, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'J7L',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'J7N',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [23.0, 19.0, 18.0, 17.0, 20.0, 21.0],
            trend: {
              value: 0.05,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.3194444477558136, 0.30645161867141724, 0.3396226465702057,
              0.26153847575187683, 0.38461539149284363, 0.30434781312942505,
            ],
            trend: {
              value: -0.211,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.625, 0.5967742204666138, 0.5849056839942932, 0.6153846383094788,
              0.48076921701431274, 0.5652173757553101,
            ],
            trend: {
              value: 0.187,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.0555555559694767, 0.09677419066429138, 0.07547169923782349,
              0.1230769231915474, 0.13461539149284363, 0.1304347813129425,
            ],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
        ],
        [
          {
            data: 'J7P',
          },
          {
            data: [5.0, 4.0, 5.0, 3.0, null, 2.0],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'J7R',
          },
          {
            data: [28.0, 24.0, 24.0, 12.0, 6.0, 12.0],
            trend: {
              value: 1.0,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [24.0, 21.0, 26.0, 23.0, 27.0, 28.0],
            trend: {
              value: 0.037,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.3333333432674408, 0.3181818127632141, 0.40625,
              0.3965517282485962, 0.36986300349235535, 0.3733333349227905,
            ],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
          {
            data: [
              0.5972222089767456, 0.6363636255264282, 0.5625, 0.568965494632721,
              0.5616438388824463, 0.5600000023841858,
            ],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
          {
            data: [
              0.0694444477558136, 0.04545454680919647, 0.03125,
              0.03448275849223137, 0.06849315017461777, 0.06666667014360428,
            ],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
        ],
        [
          {
            data: 'J7Y',
          },
          {
            data: [1.0, null, null, null, null, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [8.0, 11.0, 10.0, 11.0, 11.0, 13.0],
            trend: {
              value: 0.182,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.25, 0.3235294222831726, 0.25641027092933655, 0.3142857253551483,
              0.29729729890823364, 0.40625,
            ],
            trend: {
              value: 0.367,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.71875, 0.6470588445663452, 0.692307710647583,
              0.6285714507102966, 0.6216216087341309, 0.59375,
            ],
            trend: {
              value: -0.048,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.03125,
              0.029411764815449715,
              0.05128205195069313,
              0.05714285746216774,
              0.0810810774564743,
              null,
            ],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'J7Z',
          },
          {
            data: [19.0, 7.0, 9.0, null, null, 2.0],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [16.0, 17.0, 18.0, 18.0, 22.0, 18.0],
            trend: {
              value: -0.182,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.47058823704719543, 0.3863636255264282, 0.4736842215061188,
              0.5454545617103577, 0.4399999976158142, 0.4736842215061188,
            ],
            trend: {
              value: 0.068,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5, 0.4318181872367859, 0.44736841320991516, 0.3333333432674408,
              0.47999998927116394, 0.3684210479259491,
            ],
            trend: {
              value: -0.229,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.029411764815449715, 0.1818181872367859, 0.07894736528396606,
              0.12121212482452393, 0.07999999821186066, 0.15789473056793213,
            ],
            trend: {
              value: 1.0,
              type: 'TREND',
              status: 2,
            },
          },
        ],
      ],
    },
    quarter: {
      description: 'Q2 2023 - Compared to last quarter',
      headers: [
        {
          label: 'Region',
          subLabel: '',
          type: 'label',
        },
        {
          label: 'Activity',
          subLabel: '',
          type: 'number',
          graph: 'bar',
          chartLabels: [
            'Q4-2021',
            'Q1-2022',
            'Q2-2022',
            'Q3-2022',
            'Q4-2022',
            'Q1-2023',
          ],
        },
        {
          label: 'CONTRAVE',
          subLabel: 'Volume TRx',
          type: 'number',
          graph: 'line',
          chartLabels: [
            'Q4-2021',
            'Q1-2022',
            'Q2-2022',
            'Q3-2022',
            'Q4-2022',
            'Q1-2023',
          ],
        },
        {
          label: 'CONTRAVE',
          subLabel: 'Share TRx',
          type: 'percent',
          graph: 'line',
          chartLabels: [
            'Q4-2021',
            'Q1-2022',
            'Q2-2022',
            'Q3-2022',
            'Q4-2022',
            'Q1-2023',
          ],
        },
        {
          label: 'SAXENDA',
          subLabel: 'Share TRx',
          type: 'percent',
          graph: 'line',
          chartLabels: [
            'Q4-2021',
            'Q1-2022',
            'Q2-2022',
            'Q3-2022',
            'Q4-2022',
            'Q1-2023',
          ],
        },
        {
          label: 'XENICAL',
          subLabel: 'Share TRx',
          type: 'percent',
          graph: 'line',
          chartLabels: [
            'Q4-2021',
            'Q1-2022',
            'Q2-2022',
            'Q3-2022',
            'Q4-2022',
            'Q1-2023',
          ],
        },
      ],
      items: [
        [
          {
            data: 'H7A',
          },
          {
            data: [null, null, 9.0, 4.0, 14.0, 4.0],
            trend: {
              value: -0.714,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [108.0, 95.0, 100.0, 94.0, 86.0, 90.0],
            trend: {
              value: 0.047,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.3167155385017395, 0.3345070481300354, 0.3236245810985565,
              0.3112582862377167, 0.28289473056793213, 0.3333333432674408,
            ],
            trend: {
              value: 0.179,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5190615653991699, 0.5, 0.5210356116294861, 0.5728476643562317,
              0.6052631735801697, 0.5925925970077515,
            ],
            trend: {
              value: -0.033,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.16422288119792938, 0.1654929518699646, 0.1553398072719574,
              0.11589404195547104, 0.11184210330247879, 0.07407407462596893,
            ],
            trend: {
              value: -0.364,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'H7B',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'H7C',
          },
          {
            data: [5.0, 14.0, 12.0, 27.0, 20.0, 5.0],
            trend: {
              value: -0.75,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'H7E',
          },
          {
            data: [null, null, null, 1.0, null, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [40.0, 33.0, 42.0, 29.0, 34.0, 36.0],
            trend: {
              value: 0.059,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.39603960514068604, 0.38823530077934265, 0.42424243688583374,
              0.3052631616592407, 0.3366336524486542, 0.378947377204895,
            ],
            trend: {
              value: 0.118,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.4653465449810028, 0.5176470875740051, 0.4747474789619446,
              0.6000000238418579, 0.594059407711029, 0.5684210658073425,
            ],
            trend: {
              value: -0.034,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.13861386477947235, 0.0941176488995552, 0.10101009905338287,
              0.09473684430122375, 0.06930693238973618, 0.05263157933950424,
            ],
            trend: {
              value: -0.286,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'H7G',
          },
          {
            data: [14.0, 11.0, 21.0, 18.0, 20.0, 16.0],
            trend: {
              value: -0.2,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [28.0, 21.0, 31.0, 24.0, 28.0, 42.0],
            trend: {
              value: 0.5,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.3888888955116272, 0.2957746386528015, 0.3195876181125641,
              0.31578946113586426, 0.3589743673801422, 0.5060241222381592,
            ],
            trend: {
              value: 0.417,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5138888955116272, 0.5633803009986877, 0.5463917255401611,
              0.6052631735801697, 0.6153846383094788, 0.4457831382751465,
            ],
            trend: {
              value: -0.274,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.0972222238779068, 0.14084507524967194, 0.1340206116437912,
              0.07894736528396606, 0.025641025975346565, 0.048192769289016724,
            ],
            trend: {
              value: 0.667,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'H7H',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [22.0, 25.0, 22.0, 13.0, 26.0, 21.0],
            trend: {
              value: -0.192,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.30985915660858154, 0.4032258093357086, 0.2933333218097687,
              0.17105263471603394, 0.2857142984867096, 0.24418604373931885,
            ],
            trend: {
              value: -0.172,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.5211267471313477, 0.4677419364452362, 0.5733333230018616,
              0.6710526347160339, 0.6483516693115234, 0.6627907156944275,
            ],
            trend: {
              value: 0.015,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.1690140813589096, 0.12903225421905518, 0.13333334028720856,
              0.15789473056793213, 0.06593406945466995, 0.09302325546741486,
            ],
            trend: {
              value: 0.286,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'H7J',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'H7K',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'H7L',
          },
          {
            data: [1.0, 1.0, null, 5.0, 3.0, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [38.0, 39.0, 37.0, 47.0, 57.0, 46.0],
            trend: {
              value: -0.193,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.30894309282302856, 0.31707316637039185, 0.321739137172699,
              0.3700787425041199, 0.4560000002384186, 0.36800000071525574,
            ],
            trend: {
              value: -0.196,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.5447154641151428, 0.5528455376625061, 0.5652173757553101,
              0.4960629940032959, 0.4320000112056732, 0.47999998927116394,
            ],
            trend: {
              value: 0.116,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.1463414579629898, 0.13008129596710205, 0.11304347962141037,
              0.13385826349258423, 0.1120000034570694, 0.15199999511241913,
            ],
            trend: {
              value: 0.364,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'H7M',
          },
          {
            data: [23.0, 21.0, 46.0, 52.0, 57.0, 20.0],
            trend: {
              value: -0.649,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [26.0, 24.0, 32.0, 22.0, 30.0, 21.0],
            trend: {
              value: -0.3,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.37142857909202576, 0.35820895433425903, 0.39024388790130615,
              0.3142857253551483, 0.38461539149284363, 0.35593220591545105,
            ],
            trend: {
              value: -0.053,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.4714285731315613, 0.5373134613037109, 0.4390243887901306, 0.5,
              0.4743589758872986, 0.49152541160583496,
            ],
            trend: {
              value: 0.043,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.15714286267757416, 0.10447761416435242, 0.17073170840740204,
              0.18571428954601288, 0.14102564752101898, 0.1525423675775528,
            ],
            trend: {
              value: 0.071,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'H7N',
          },
          {
            data: [null, null, null, 1.0, 3.0, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [23.0, 26.0, 28.0, 21.0, 30.0, 29.0],
            trend: {
              value: -0.033,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.24210526049137115, 0.2921348214149475, 0.3544303774833679,
              0.2763157784938812, 0.3529411852359772, 0.3372093141078949,
            ],
            trend: {
              value: -0.029,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.5473684072494507, 0.584269642829895, 0.5316455960273743,
              0.6447368264198303, 0.6000000238418579, 0.6511628031730652,
            ],
            trend: {
              value: 0.083,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.21052631735801697, 0.12359550595283508, 0.1139240488409996,
              0.07894736528396606, 0.0470588244497776, 0.011627906933426857,
            ],
            trend: {
              value: -0.8,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'H7P',
          },
          {
            data: [2.0, 10.0, 11.0, 39.0, 9.0, 15.0],
            trend: {
              value: 0.667,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [75.0, 65.0, 90.0, 85.0, 74.0, 93.0],
            trend: {
              value: 0.257,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.3968254029750824, 0.31553396582603455, 0.3813559412956238,
              0.35564854741096497, 0.3737373650074005, 0.5081967115402222,
            ],
            trend: {
              value: 0.378,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.42328041791915894, 0.5097087621688843, 0.47033897042274475,
              0.49790793657302856, 0.4848484992980957, 0.3825136721134186,
            ],
            trend: {
              value: -0.208,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.17989417910575867, 0.17475728690624237, 0.14830508828163147,
              0.14644351601600647, 0.14141413569450378, 0.10928961634635925,
            ],
            trend: {
              value: -0.214,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'H7R',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [22.0, 31.0, 48.0, 37.0, 32.0, 32.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
          {
            data: [
              0.25, 0.3563218414783478, 0.4285714328289032, 0.4157303273677826,
              0.36781609058380127, 0.380952388048172,
            ],
            trend: {
              value: 0.027,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.6136363744735718, 0.5057471394538879, 0.4553571343421936,
              0.516853928565979, 0.540229856967926, 0.5476190447807312,
            ],
            trend: {
              value: 0.019,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.13636364042758942, 0.13793103396892548, 0.1160714253783226,
              0.06741572916507721, 0.09195402264595032, 0.0714285746216774,
            ],
            trend: {
              value: -0.222,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'H7S',
          },
          {
            data: [7.0, 8.0, 5.0, 14.0, 11.0, 5.0],
            trend: {
              value: -0.545,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'H7T',
          },
          {
            data: [19.0, 28.0, 26.0, 30.0, 38.0, 9.0],
            trend: {
              value: -0.763,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [18.0, 14.0, 19.0, 16.0, 16.0, 23.0],
            trend: {
              value: 0.438,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.20930232107639313, 0.1818181872367859, 0.19191919267177582,
              0.2133333384990692, 0.21917808055877686, 0.29870128631591797,
            ],
            trend: {
              value: 0.364,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.7093023061752319, 0.701298713684082, 0.6666666865348816,
              0.6800000071525574, 0.6301369667053223, 0.5844155550003052,
            ],
            trend: {
              value: -0.079,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.08139535039663315, 0.11688311398029327, 0.14141413569450378,
              0.1066666692495346, 0.15068493783473969, 0.11688311398029327,
            ],
            trend: {
              value: -0.2,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'H7V',
          },
          {
            data: [6.0, 21.0, 13.0, 19.0, 10.0, 6.0],
            trend: {
              value: -0.4,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [21.0, 21.0, 22.0, 13.0, 13.0, 22.0],
            trend: {
              value: 0.692,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.3442623019218445, 0.3888888955116272, 0.2933333218097687,
              0.19402985274791718, 0.21666666865348816, 0.3492063581943512,
            ],
            trend: {
              value: 0.591,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5409836173057556, 0.5370370149612427, 0.6266666650772095,
              0.6716417670249939, 0.5833333134651184, 0.523809552192688,
            ],
            trend: {
              value: -0.103,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.1147540956735611, 0.07407407462596893, 0.07999999821186066,
              0.13432836532592773, 0.20000000298023224, 0.1269841343164444,
            ],
            trend: {
              value: -0.35,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'H7W',
          },
          {
            data: [10.0, 13.0, 17.0, 10.0, 17.0, 5.0],
            trend: {
              value: -0.706,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [11.0, 9.0, 1.0, null, 2.0, 5.0],
            trend: {
              value: 1.5,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.42307692766189575,
              0.29032257199287415,
              0.029411764815449715,
              null,
              0.05128205195069313,
              0.1428571492433548,
            ],
            trend: {
              value: 1.8,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.3461538553237915, 0.6129032373428345, 0.8235294222831726,
              0.8709677457809448, 0.8205128312110901, 0.7714285850524902,
            ],
            trend: {
              value: -0.061,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.23076923191547394, 0.09677419066429138, 0.14705882966518402,
              0.12903225421905518, 0.12820513546466827, 0.08571428805589676,
            ],
            trend: {
              value: -0.308,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'H7X',
          },
          {
            data: [1.0, 8.0, 22.0, 17.0, 7.0, 19.0],
            trend: {
              value: 1.714,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [89.0, 76.0, 100.0, 58.0, 60.0, 35.0],
            trend: {
              value: -0.417,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.5235294103622437, 0.5588235259056091, 0.44843047857284546,
              0.4000000059604645, 0.37974682450294495, 0.24475523829460144,
            ],
            trend: {
              value: -0.368,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.364705890417099, 0.33088234066963196, 0.4304932653903961,
              0.4965517222881317, 0.49367088079452515, 0.6293706297874451,
            ],
            trend: {
              value: 0.286,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.11176470667123795, 0.11029411852359772, 0.12107623368501663,
              0.1034482792019844, 0.1265822798013687, 0.1258741319179535,
            ],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
        ],
        [
          {
            data: 'H7Y',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [109.0, 93.0, 93.0, 107.0, 70.0, 76.0],
            trend: {
              value: 0.086,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.372013658285141, 0.34191176295280457, 0.32517483830451965,
              0.34967321157455444, 0.2766798436641693, 0.31147539615631104,
            ],
            trend: {
              value: 0.107,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5392491221427917, 0.5808823704719543, 0.5314685106277466,
              0.5065359473228455, 0.5652173757553101, 0.5409836173057556,
            ],
            trend: {
              value: -0.053,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.08873720467090607, 0.07720588147640228, 0.14335663616657257,
              0.1437908560037613, 0.15810276567935944, 0.14754098653793335,
            ],
            trend: {
              value: -0.063,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'J0N',
          },
          {
            data: [8.0, 1.0, 4.0, 7.0, 7.0, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [65.0, 68.0, 68.0, 68.0, 46.0, 57.0],
            trend: {
              value: 0.239,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.31100478768348694, 0.32380953431129456, 0.31192660331726074,
              0.310502290725708, 0.24731183052062988, 0.3166666626930237,
            ],
            trend: {
              value: 0.28,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5933014154434204, 0.5904762148857117, 0.5275229215621948,
              0.5114155411720276, 0.5752688050270081, 0.5,
            ],
            trend: {
              value: -0.138,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.09569378197193146, 0.08571428805589676, 0.16055046021938324,
              0.1780821979045868, 0.17741934955120087, 0.18333333730697632,
            ],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
        ],
        [
          {
            data: 'J0R',
          },
          {
            data: [4.0, 6.0, 9.0, 10.0, 14.0, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [38.0, 26.0, 19.0, 35.0, 43.0, 44.0],
            trend: {
              value: 0.023,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.3619047701358795, 0.2626262605190277, 0.18627451360225677,
              0.28925618529319763, 0.37719297409057617, 0.38596490025520325,
            ],
            trend: {
              value: 0.026,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5333333611488342, 0.6060606241226196, 0.6666666865348816,
              0.5785123705863953, 0.5175438523292542, 0.5175438523292542,
            ],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
          {
            data: [
              0.10476190596818924, 0.13131313025951385, 0.14705882966518402,
              0.13223139941692352, 0.10526315867900848, 0.09649122506380081,
            ],
            trend: {
              value: -0.091,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'J5J',
          },
          {
            data: [null, 1.0, null, 1.0, null, 3.0],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [63.0, 62.0, 69.0, 70.0, 59.0, 45.0],
            trend: {
              value: -0.237,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.31658291816711426, 0.3483146131038666, 0.3239436745643616,
              0.29288703203201294, 0.2633928656578064, 0.24725274741649628,
            ],
            trend: {
              value: -0.038,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.5628140568733215, 0.5617977380752563, 0.5821596384048462,
              0.589958131313324, 0.6071428656578064, 0.6318681240081787,
            ],
            trend: {
              value: 0.033,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.12060301750898361, 0.08988764137029648, 0.09389671683311462,
              0.1171548143029213, 0.1294642835855484, 0.12087912112474442,
            ],
            trend: {
              value: -0.077,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'J5K',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'J5L',
          },
          {
            data: [null, null, 2.0, 1.0, 1.0, 1.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'J6W',
          },
          {
            data: [9.0, 9.0, 12.0, 15.0, 3.0, 8.0],
            trend: {
              value: 1.667,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [44.0, 59.0, 67.0, 69.0, 56.0, 58.0],
            trend: {
              value: 0.036,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.276729553937912, 0.3314606845378876, 0.31904762983322144,
              0.3349514603614807, 0.2916666567325592, 0.32768362760543823,
            ],
            trend: {
              value: 0.138,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.6037735939025879, 0.584269642829895, 0.5857142806053162,
              0.5922330021858215, 0.609375, 0.5706214904785156,
            ],
            trend: {
              value: -0.066,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.11949685215950012, 0.08426966518163681, 0.095238097012043,
              0.07281553745269775, 0.0989583358168602, 0.10169491171836853,
            ],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
        ],
        [
          {
            data: 'J6X',
          },
          {
            data: [15.0, 7.0, 7.0, 23.0, 8.0, 17.0],
            trend: {
              value: 1.125,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [149.0, 164.0, 166.0, 159.0, 138.0, 162.0],
            trend: {
              value: 0.174,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.379134863615036, 0.4542936384677887, 0.43799471855163574,
              0.4066496193408966, 0.3920454680919647, 0.4576271176338196,
            ],
            trend: {
              value: 0.179,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5216284990310669, 0.47922438383102417, 0.5013192892074585,
              0.5575447678565979, 0.5681818127632141, 0.4971751272678375,
            ],
            trend: {
              value: -0.123,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.09923664480447769, 0.06648199260234833, 0.06068601459264755,
              0.03580562770366669, 0.039772726595401764, 0.0451977401971817,
            ],
            trend: {
              value: 0.25,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'J6Y',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [45.0, 41.0, 42.0, 38.0, 46.0, 43.0],
            trend: {
              value: -0.065,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.3103448152542114, 0.28671327233314514, 0.26923078298568726,
              0.25503355264663696, 0.31292515993118286, 0.304964542388916,
            ],
            trend: {
              value: -0.032,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.5793103575706482, 0.5804196000099182, 0.6089743375778198,
              0.6510066986083984, 0.5782312750816345, 0.6170212626457214,
            ],
            trend: {
              value: 0.069,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.11034482717514038, 0.13286712765693665, 0.12179487198591232,
              0.0939597338438034, 0.10884353518486023, 0.07801418751478195,
            ],
            trend: {
              value: -0.273,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'J6Z',
          },
          {
            data: [8.0, 12.0, 18.0, 22.0, 10.0, 10.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
          {
            data: [59.0, 47.0, 50.0, 49.0, 54.0, 49.0],
            trend: {
              value: -0.093,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.4097222089767456, 0.35606059432029724, 0.3401360511779785,
              0.37404578924179077, 0.3884892165660858, 0.3798449635505676,
            ],
            trend: {
              value: -0.026,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.5416666865348816, 0.560606062412262, 0.5782312750816345,
              0.5648854970932007, 0.5755395889282227, 0.5968992114067078,
            ],
            trend: {
              value: 0.034,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.0486111119389534, 0.0833333358168602, 0.08163265138864517,
              0.06106870248913765, 0.035971224308013916, 0.023255813866853714,
            ],
            trend: {
              value: -0.5,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'J7A',
          },
          {
            data: [1.0, 1.0, 3.0, 1.0, 4.0, 6.0],
            trend: {
              value: 0.5,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [31.0, 22.0, 22.0, 20.0, 20.0, 16.0],
            trend: {
              value: -0.2,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.5535714030265808, 0.5, 0.42307692766189575, 0.4651162922382355,
              0.3571428656578064, 0.3478260934352875,
            ],
            trend: {
              value: -0.028,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.4464285671710968, 0.4545454680919647, 0.5769230723381042,
              0.4883720874786377, 0.625, 0.6521739363670349,
            ],
            trend: {
              value: 0.048,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              null,
              0.04545454680919647,
              null,
              0.04651162773370743,
              0.01785714365541935,
              null,
            ],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'J7B',
          },
          {
            data: [11.0, 14.0, 6.0, 8.0, 11.0, 10.0],
            trend: {
              value: -0.091,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [145.0, 128.0, 174.0, 150.0, 153.0, 95.0],
            trend: {
              value: -0.379,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.361596018075943, 0.34972676634788513, 0.4000000059604645,
              0.35971224308013916, 0.375, 0.2896341383457184,
            ],
            trend: {
              value: -0.237,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.5511221885681152, 0.5710382461547852, 0.5333333611488342,
              0.5827338099479675, 0.5735294222831726, 0.6585366129875183,
            ],
            trend: {
              value: 0.158,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.08728179335594177, 0.07923497259616852, 0.06666667014360428,
              0.057553958147764206, 0.05147058889269829, 0.0518292672932148,
            ],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
        ],
        [
          {
            data: 'J7C',
          },
          {
            data: [2.0, 4.0, 3.0, 2.0, 1.0, 1.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
          {
            data: [71.0, 47.0, 63.0, 55.0, 45.0, 49.0],
            trend: {
              value: 0.089,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.356783926486969, 0.29936304688453674, 0.3333333432674408,
              0.32163742184638977, 0.27272728085517883, 0.30817610025405884,
            ],
            trend: {
              value: 0.148,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5376884341239929, 0.5732483863830566, 0.5608465671539307,
              0.5964912176132202, 0.6666666865348816, 0.6477987170219421,
            ],
            trend: {
              value: -0.03,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.10552763938903809, 0.12738853693008423, 0.10582010447978973,
              0.08187134563922882, 0.06060606241226196, 0.044025156646966934,
            ],
            trend: {
              value: -0.333,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'J7E',
          },
          {
            data: [null, 2.0, 3.0, 2.0, 2.0, 4.0],
            trend: {
              value: 1.0,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [99.0, 103.0, 111.0, 110.0, 100.0, 76.0],
            trend: {
              value: -0.24,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.3235294222831726, 0.33770492672920227, 0.3479623794555664,
              0.38596490025520325, 0.36764705181121826, 0.28148147463798523,
            ],
            trend: {
              value: -0.243,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.6143791079521179, 0.6000000238418579, 0.6206896305084229,
              0.5964912176132202, 0.6102941036224365, 0.6851851940155029,
            ],
            trend: {
              value: 0.131,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.06209150329232216, 0.062295082956552505, 0.03134796395897865,
              0.017543859779834747, 0.022058824077248573, 0.03333333507180214,
            ],
            trend: {
              value: 0.5,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'J7G',
          },
          {
            data: [null, 2.0, 4.0, null, 7.0, 3.0],
            trend: {
              value: -0.571,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [97.0, 112.0, 130.0, 105.0, 92.0, 84.0],
            trend: {
              value: -0.087,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.40082645416259766, 0.42105263471603394, 0.4467353820800781,
              0.3903345763683319, 0.3786008358001709, 0.3414634168148041,
            ],
            trend: {
              value: -0.105,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.5371900796890259, 0.5338345766067505, 0.5154638886451721,
              0.5687732100486755, 0.5514403581619263, 0.5934959053993225,
            ],
            trend: {
              value: 0.073,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.06198346987366676, 0.04511278122663498, 0.03780068829655647,
              0.04089219495654106, 0.06995885074138641, 0.06504064798355103,
            ],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
          },
        ],
        [
          {
            data: 'J7H',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'J7J',
          },
          {
            data: [1.0, 7.0, 9.0, 7.0, 11.0, 13.0],
            trend: {
              value: 0.182,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'J7L',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'J7N',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [84.0, 76.0, 75.0, 83.0, 65.0, 55.0],
            trend: {
              value: -0.154,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.3529411852359772, 0.3333333432674408, 0.34403669834136963,
              0.40096619725227356, 0.32828283309936523, 0.3235294222831726,
            ],
            trend: {
              value: -0.03,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.5378151535987854, 0.5877193212509155, 0.5733944773674011,
              0.5458937287330627, 0.5909090638160706, 0.5647059082984924,
            ],
            trend: {
              value: -0.051,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.10924369841814041, 0.07894736528396606, 0.08256880939006805,
              0.05314009636640549, 0.08080808073282242, 0.11176470667123795,
            ],
            trend: {
              value: 0.375,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'J7P',
          },
          {
            data: [5.0, 6.0, 11.0, 1.0, 9.0, 8.0],
            trend: {
              value: -0.111,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
        ],
        [
          {
            data: 'J7R',
          },
          {
            data: [31.0, 23.0, 39.0, 25.0, 59.0, 42.0],
            trend: {
              value: -0.288,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [91.0, 92.0, 77.0, 66.0, 68.0, 76.0],
            trend: {
              value: 0.118,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.46666666865348816, 0.46000000834465027, 0.37745097279548645,
              0.3251231610774994, 0.3222748935222626, 0.3897435963153839,
            ],
            trend: {
              value: 0.219,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5128205418586731, 0.48500001430511475, 0.5441176295280457,
              0.6059113144874573, 0.620853066444397, 0.5641025900840759,
            ],
            trend: {
              value: -0.097,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.020512821152806282, 0.054999999701976776, 0.0784313753247261,
              0.06896551698446274, 0.056872036308050156, 0.04615384712815285,
            ],
            trend: {
              value: -0.167,
              type: 'TREND',
              status: 0,
            },
          },
        ],
        [
          {
            data: 'J7Y',
          },
          {
            data: [null, 2.0, 6.0, 2.0, 1.0, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
          },
          {
            data: [42.0, 44.0, 47.0, 40.0, 28.0, 32.0],
            trend: {
              value: 0.143,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.30000001192092896, 0.316546767950058, 0.31333333253860474,
              0.3076923191547394, 0.25925925374031067, 0.28828829526901245,
            ],
            trend: {
              value: 0.115,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.5642856955528259, 0.5467625856399536, 0.5799999833106995,
              0.5846154093742371, 0.7037037014961243, 0.6486486196517944,
            ],
            trend: {
              value: -0.071,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.13571429252624512, 0.1366906464099884, 0.1066666692495346,
              0.10769230872392654, 0.03703703731298447, 0.06306306272745132,
            ],
            trend: {
              value: 0.5,
              type: 'TREND',
              status: 2,
            },
          },
        ],
        [
          {
            data: 'J7Z',
          },
          {
            data: [7.0, 7.0, 19.0, 10.0, 26.0, 9.0],
            trend: {
              value: -0.654,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [32.0, 36.0, 38.0, 50.0, 49.0, 58.0],
            trend: {
              value: 0.184,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.26229506731033325, 0.3302752375602722, 0.35849055647850037,
              0.4587155878543854, 0.40495866537094116, 0.4793388545513153,
            ],
            trend: {
              value: 0.2,
              type: 'TREND',
              status: 2,
            },
          },
          {
            data: [
              0.6147540807723999, 0.5412843823432922, 0.5283018946647644,
              0.4678899049758911, 0.4710743725299835, 0.42975205183029175,
            ],
            trend: {
              value: -0.085,
              type: 'TREND',
              status: 0,
            },
          },
          {
            data: [
              0.12295082211494446, 0.12844036519527435, 0.11320754885673523,
              0.07339449226856232, 0.12396693974733353, 0.09090909361839294,
            ],
            trend: {
              value: -0.25,
              type: 'TREND',
              status: 0,
            },
          },
        ],
      ],
    },
  },
};

export const territoryOverviewMockSupplyData = {
  timescaleKeys: [
    {
      id: 'month',
      name: 'Month',
    },
    {
      id: 'quarter',
      name: 'Quarter',
    },
  ],
  timescaleData: {
    month: {
      description: 'March 2023 - Compared to last 12 months',
      headers: [
        {
          label: 'Region',
          subLabel: '',
          type: 'label',
        },
        {
          label: 'APR',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'MAY',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'JUN',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'JUL',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'AUG',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'SEP',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'OCT',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'NOV',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'DEC',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'JAN',
          subLabel: '2023',
          type: 'dollars',
        },
        {
          label: 'FEB',
          subLabel: '2023',
          type: 'dollars',
        },
        {
          label: 'MAR',
          subLabel: '2023',
          type: 'dollars',
        },
      ],
      items: [
        [
          {
            data: 'Region Avg',
          },
          {
            data: [5788.894736842105, 5293.5526315789475],
            trend: {
              value: -0.086,
              type: 'TREND',
              status: 0,
            },
            activity: [5.1],
          },
          {
            data: [5293.5526315789475, 6168.9473684210525],
            trend: {
              value: 0.165,
              type: 'TREND',
              status: 2,
            },
            activity: [5.2631578947368425],
          },
          {
            data: [6168.9473684210525, 6276.815789473684],
            trend: {
              value: 0.017,
              type: 'TREND',
              status: 2,
            },
            activity: [5.791666666666667],
          },
          {
            data: [6276.815789473684, 6183.6578947368425],
            trend: {
              value: -0.015,
              type: 'TREND',
              status: 0,
            },
            activity: [5.222222222222222],
          },
          {
            data: [6183.6578947368425, 5776.605263157895],
            trend: {
              value: -0.066,
              type: 'TREND',
              status: 0,
            },
            activity: [5.647058823529412],
          },
          {
            data: [5776.605263157895, 5416.263157894737],
            trend: {
              value: -0.062,
              type: 'TREND',
              status: 0,
            },
            activity: [7.269230769230769],
          },
          {
            data: [5416.263157894737, 6014.4473684210525],
            trend: {
              value: 0.11,
              type: 'TREND',
              status: 2,
            },
            activity: [3.8125],
          },
          {
            data: [6014.4473684210525, 5950.789473684211],
            trend: {
              value: -0.011,
              type: 'TREND',
              status: 0,
            },
            activity: [7.923076923076923],
          },
          {
            data: [5950.789473684211, 6393.5675675675675],
            trend: {
              value: 0.074,
              type: 'TREND',
              status: 2,
            },
            activity: [6.05],
          },
          {
            data: [6393.5675675675675, 5071.710526315789],
            trend: {
              value: -0.207,
              type: 'TREND',
              status: 0,
            },
            activity: [4.947368421052632],
          },
          {
            data: [5071.710526315789, 5200.945945945946],
            trend: {
              value: 0.025,
              type: 'TREND',
              status: 2,
            },
            activity: [4.470588235294118],
          },
          {
            data: [5200.945945945946, 6051.815789473684],
            trend: {
              value: 0.164,
              type: 'TREND',
              status: 2,
            },
            activity: [4.235294117647059],
          },
        ],
        [
          {
            data: 'H7A',
          },
          {
            data: [2888.0, 3447.0],
            trend: {
              value: 0.194,
              type: 'TREND',
              status: 2,
            },
            activity: [5.0],
          },
          {
            data: [3447.0, 2422.0],
            trend: {
              value: -0.297,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [2422.0, 4099.0],
            trend: {
              value: 0.692,
              type: 'TREND',
              status: 2,
            },
            activity: [4.0],
          },
          {
            data: [4099.0, 2702.0],
            trend: {
              value: -0.341,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [2702.0, 2329.0],
            trend: {
              value: -0.138,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [2329.0, 1770.0],
            trend: {
              value: -0.24,
              type: 'TREND',
              status: 0,
            },
            activity: [4.0],
          },
          {
            data: [1770.0, 3913.0],
            trend: {
              value: 1.211,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [3913.0, 2050.0],
            trend: {
              value: -0.476,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [2050.0, 3261.0],
            trend: {
              value: 0.591,
              type: 'TREND',
              status: 2,
            },
            activity: [8.0],
          },
          {
            data: [3261.0, 3298.0],
            trend: {
              value: 0.011,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [3298.0, 3298.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
            activity: [2.0],
          },
          {
            data: [3298.0, 3104.0],
            trend: {
              value: -0.059,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
        ],
        [
          {
            data: 'H7B',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'H7C',
          },
          {
            data: [2050.0, 2329.0],
            trend: {
              value: 0.136,
              type: 'TREND',
              status: 2,
            },
            activity: [11.0],
          },
          {
            data: [2329.0, 4193.0],
            trend: {
              value: 0.8,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [4193.0, 3075.0],
            trend: {
              value: -0.267,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [3075.0, 3075.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
            activity: [17.0],
          },
          {
            data: [3075.0, 2516.0],
            trend: {
              value: -0.182,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
          {
            data: [2516.0, 4006.0],
            trend: {
              value: 0.592,
              type: 'TREND',
              status: 2,
            },
            activity: [8.0],
          },
          {
            data: [4006.0, 4286.0],
            trend: {
              value: 0.07,
              type: 'TREND',
              status: 2,
            },
            activity: [10.0],
          },
          {
            data: [4286.0, 2982.0],
            trend: {
              value: -0.304,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [2982.0, 3727.0],
            trend: {
              value: 0.25,
              type: 'TREND',
              status: 2,
            },
            activity: [10.0],
          },
          {
            data: [3727.0, 1552.0],
            trend: {
              value: -0.584,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
          {
            data: [1552.0, 3104.0],
            trend: {
              value: 1.0,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [3104.0, 4462.0],
            trend: {
              value: 0.438,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'H7E',
          },
          {
            data: [5590.0, 5031.0],
            trend: {
              value: -0.1,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [5031.0, 6708.0],
            trend: {
              value: 0.333,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [6708.0, 5963.0],
            trend: {
              value: -0.111,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [5963.0, 4659.0],
            trend: {
              value: -0.219,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [4659.0, 5311.0],
            trend: {
              value: 0.14,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [5311.0, 4472.0],
            trend: {
              value: -0.158,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [4472.0, 6895.0],
            trend: {
              value: 0.542,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [6895.0, 5497.0],
            trend: {
              value: -0.203,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [5497.0, 5404.0],
            trend: {
              value: -0.017,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [5404.0, 6595.0],
            trend: {
              value: 0.22,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [6595.0, 6110.0],
            trend: {
              value: -0.074,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [6110.0, 5819.0],
            trend: {
              value: -0.048,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'H7G',
          },
          {
            data: [5031.0, 5218.0],
            trend: {
              value: 0.037,
              type: 'TREND',
              status: 2,
            },
            activity: [14.0],
          },
          {
            data: [5218.0, 6336.0],
            trend: {
              value: 0.214,
              type: 'TREND',
              status: 2,
            },
            activity: [6.0],
          },
          {
            data: [6336.0, 4472.0],
            trend: {
              value: -0.294,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [4472.0, 6615.0],
            trend: {
              value: 0.479,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [6615.0, 5404.0],
            trend: {
              value: -0.183,
              type: 'TREND',
              status: 0,
            },
            activity: [7.0],
          },
          {
            data: [5404.0, 4379.0],
            trend: {
              value: -0.19,
              type: 'TREND',
              status: 0,
            },
            activity: [10.0],
          },
          {
            data: [4379.0, 6615.0],
            trend: {
              value: 0.511,
              type: 'TREND',
              status: 2,
            },
            activity: [11.0],
          },
          {
            data: [6615.0, 5311.0],
            trend: {
              value: -0.197,
              type: 'TREND',
              status: 0,
            },
            activity: [6.0],
          },
          {
            data: [5311.0, 5963.0],
            trend: {
              value: 0.123,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [5963.0, 5819.0],
            trend: {
              value: -0.024,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [5819.0, 4656.0],
            trend: {
              value: -0.2,
              type: 'TREND',
              status: 0,
            },
            activity: [4.0],
          },
          {
            data: [4656.0, 4752.0],
            trend: {
              value: 0.021,
              type: 'TREND',
              status: 2,
            },
            activity: [9.0],
          },
        ],
        [
          {
            data: 'H7H',
          },
          {
            data: [1398.0, 1584.0],
            trend: {
              value: 0.133,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [1584.0, 2236.0],
            trend: {
              value: 0.412,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [2236.0, 2702.0],
            trend: {
              value: 0.208,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [2702.0, 1398.0],
            trend: {
              value: -0.483,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [1398.0, 2702.0],
            trend: {
              value: 0.933,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [2702.0, 1491.0],
            trend: {
              value: -0.448,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [1491.0, 1584.0],
            trend: {
              value: 0.062,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [1584.0, 2516.0],
            trend: {
              value: 0.588,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [2516.0, 2702.0],
            trend: {
              value: 0.074,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [2702.0, 2037.0],
            trend: {
              value: -0.246,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [2037.0, 1940.0],
            trend: {
              value: -0.048,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [1940.0, 2231.0],
            trend: {
              value: 0.15,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'H7J',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'H7K',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'H7L',
          },
          {
            data: [8572.0, 7826.0],
            trend: {
              value: -0.087,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [7826.0, 9783.0],
            trend: {
              value: 0.25,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [9783.0, 11553.0],
            trend: {
              value: 0.181,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [11553.0, 8665.0],
            trend: {
              value: -0.25,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [8665.0, 10342.0],
            trend: {
              value: 0.194,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [10342.0, 6429.0],
            trend: {
              value: -0.378,
              type: 'TREND',
              status: 0,
            },
            activity: [5.0],
          },
          {
            data: [6429.0, 10063.0],
            trend: {
              value: 0.565,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [10063.0, 10156.0],
            trend: {
              value: 0.009,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [10156.0, 9038.0],
            trend: {
              value: -0.11,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [9038.0, 8923.0],
            trend: {
              value: -0.013,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [8923.0, 8341.0],
            trend: {
              value: -0.065,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [8341.0, 8438.0],
            trend: {
              value: 0.012,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'H7M',
          },
          {
            data: [8665.0, 7454.0],
            trend: {
              value: -0.14,
              type: 'TREND',
              status: 0,
            },
            activity: [19.0],
          },
          {
            data: [7454.0, 8665.0],
            trend: {
              value: 0.162,
              type: 'TREND',
              status: 2,
            },
            activity: [10.0],
          },
          {
            data: [8665.0, 7081.0],
            trend: {
              value: -0.183,
              type: 'TREND',
              status: 0,
            },
            activity: [17.0],
          },
          {
            data: [7081.0, 7267.0],
            trend: {
              value: 0.026,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [7267.0, 7826.0],
            trend: {
              value: 0.077,
              type: 'TREND',
              status: 2,
            },
            activity: [20.0],
          },
          {
            data: [7826.0, 5031.0],
            trend: {
              value: -0.357,
              type: 'TREND',
              status: 0,
            },
            activity: [29.0],
          },
          {
            data: [5031.0, 7640.0],
            trend: {
              value: 0.519,
              type: 'TREND',
              status: 2,
            },
            activity: [6.0],
          },
          {
            data: [7640.0, 8665.0],
            trend: {
              value: 0.134,
              type: 'TREND',
              status: 2,
            },
            activity: [35.0],
          },
          {
            data: [8665.0, 4565.0],
            trend: {
              value: -0.473,
              type: 'TREND',
              status: 0,
            },
            activity: [16.0],
          },
          {
            data: [4565.0, 4850.0],
            trend: {
              value: 0.062,
              type: 'TREND',
              status: 2,
            },
            activity: [9.0],
          },
          {
            data: [4850.0, 4850.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
            activity: [10.0],
          },
          {
            data: [4850.0, 8341.0],
            trend: {
              value: 0.72,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
        ],
        [
          {
            data: 'H7N',
          },
          {
            data: [7361.0, 6149.0],
            trend: {
              value: -0.165,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [6149.0, 7081.0],
            trend: {
              value: 0.152,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [7081.0, 9783.0],
            trend: {
              value: 0.382,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [9783.0, 8944.0],
            trend: {
              value: -0.086,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [8944.0, 8572.0],
            trend: {
              value: -0.042,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [8572.0, 6615.0],
            trend: {
              value: -0.228,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [6615.0, 7547.0],
            trend: {
              value: 0.141,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [7547.0, 9410.0],
            trend: {
              value: 0.247,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [9410.0, 9690.0],
            trend: {
              value: 0.03,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [9690.0, 6304.0],
            trend: {
              value: -0.349,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [6304.0, 7759.0],
            trend: {
              value: 0.231,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [7759.0, 8632.0],
            trend: {
              value: 0.113,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'H7P',
          },
          {
            data: [13789.0, 12578.0],
            trend: {
              value: -0.088,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
          {
            data: [12578.0, 15653.0],
            trend: {
              value: 0.244,
              type: 'TREND',
              status: 2,
            },
            activity: [5.0],
          },
          {
            data: [15653.0, 13976.0],
            trend: {
              value: -0.107,
              type: 'TREND',
              status: 0,
            },
            activity: [4.0],
          },
          {
            data: [13976.0, 17516.0],
            trend: {
              value: 0.253,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [17516.0, 14814.0],
            trend: {
              value: -0.154,
              type: 'TREND',
              status: 0,
            },
            activity: [6.0],
          },
          {
            data: [14814.0, 12485.0],
            trend: {
              value: -0.157,
              type: 'TREND',
              status: 0,
            },
            activity: [32.0],
          },
          {
            data: [12485.0, 14441.0],
            trend: {
              value: 0.157,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [14441.0, 14628.0],
            trend: {
              value: 0.013,
              type: 'TREND',
              status: 2,
            },
            activity: [5.0],
          },
          {
            data: [14628.0, 14162.0],
            trend: {
              value: -0.032,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [14162.0, 15034.0],
            trend: {
              value: 0.062,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [15034.0, 14743.0],
            trend: {
              value: -0.019,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [14743.0, 15809.0],
            trend: {
              value: 0.072,
              type: 'TREND',
              status: 2,
            },
            activity: [5.0],
          },
        ],
        [
          {
            data: 'H7R',
          },
          {
            data: [5683.0, 5777.0],
            trend: {
              value: 0.017,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [5777.0, 4286.0],
            trend: {
              value: -0.258,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [4286.0, 6336.0],
            trend: {
              value: 0.478,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [6336.0, 5218.0],
            trend: {
              value: -0.176,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [5218.0, 5590.0],
            trend: {
              value: 0.071,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [5590.0, 4752.0],
            trend: {
              value: -0.15,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [4752.0, 4659.0],
            trend: {
              value: -0.02,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [4659.0, 7454.0],
            trend: {
              value: 0.6,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [7454.0, 6522.0],
            trend: {
              value: -0.125,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [6522.0, 3977.0],
            trend: {
              value: -0.39,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [3977.0, 5529.0],
            trend: {
              value: 0.39,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [5529.0, 5335.0],
            trend: {
              value: -0.035,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'H7S',
          },
          {
            data: [280.0, 186.0],
            trend: {
              value: -0.336,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [186.0, 280.0],
            trend: {
              value: 0.505,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
          {
            data: [280.0, 373.0],
            trend: {
              value: 0.332,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [373.0, 559.0],
            trend: {
              value: 0.499,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [559.0, 93.0],
            trend: {
              value: -0.834,
              type: 'TREND',
              status: 0,
            },
            activity: [6.0],
          },
          {
            data: [93.0, 652.0],
            trend: {
              value: 6.011,
              type: 'TREND',
              status: 2,
            },
            activity: [8.0],
          },
          {
            data: [652.0, 559.0],
            trend: {
              value: -0.143,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [559.0, 652.0],
            trend: {
              value: 0.166,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [652.0, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [1.0],
          },
          {
            data: [null, 485.0],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [null],
          },
          {
            data: [485.0, 582.0],
            trend: {
              value: 0.2,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
          {
            data: [582.0, 582.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
            activity: [3.0],
          },
        ],
        [
          {
            data: 'H7T',
          },
          {
            data: [7267.0, 7640.0],
            trend: {
              value: 0.051,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [7640.0, 7733.0],
            trend: {
              value: 0.012,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [7733.0, 8944.0],
            trend: {
              value: 0.157,
              type: 'TREND',
              status: 2,
            },
            activity: [16.0],
          },
          {
            data: [8944.0, 6801.0],
            trend: {
              value: -0.24,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [6801.0, 9690.0],
            trend: {
              value: 0.425,
              type: 'TREND',
              status: 2,
            },
            activity: [17.0],
          },
          {
            data: [9690.0, 7360.0],
            trend: {
              value: -0.24,
              type: 'TREND',
              status: 0,
            },
            activity: [13.0],
          },
          {
            data: [7360.0, 6615.0],
            trend: {
              value: -0.101,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [6615.0, 6149.0],
            trend: {
              value: -0.07,
              type: 'TREND',
              status: 0,
            },
            activity: [25.0],
          },
          {
            data: [6149.0, 8572.0],
            trend: {
              value: 0.394,
              type: 'TREND',
              status: 2,
            },
            activity: [12.0],
          },
          {
            data: [8572.0, 5723.0],
            trend: {
              value: -0.332,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [5723.0, 7080.0],
            trend: {
              value: 0.237,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [7080.0, 8050.0],
            trend: {
              value: 0.137,
              type: 'TREND',
              status: 2,
            },
            activity: [5.0],
          },
        ],
        [
          {
            data: 'H7V',
          },
          {
            data: [6615.0, 6522.0],
            trend: {
              value: -0.014,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [6522.0, 5497.0],
            trend: {
              value: -0.157,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [5497.0, 7174.0],
            trend: {
              value: 0.305,
              type: 'TREND',
              status: 2,
            },
            activity: [12.0],
          },
          {
            data: [7174.0, 6522.0],
            trend: {
              value: -0.091,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [6522.0, 4565.0],
            trend: {
              value: -0.3,
              type: 'TREND',
              status: 0,
            },
            activity: [8.0],
          },
          {
            data: [4565.0, 5311.0],
            trend: {
              value: 0.163,
              type: 'TREND',
              status: 2,
            },
            activity: [10.0],
          },
          {
            data: [5311.0, 6429.0],
            trend: {
              value: 0.211,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
          {
            data: [6429.0, 5404.0],
            trend: {
              value: -0.159,
              type: 'TREND',
              status: 0,
            },
            activity: [5.0],
          },
          {
            data: [5404.0, 6988.0],
            trend: {
              value: 0.293,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [6988.0, 3395.0],
            trend: {
              value: -0.514,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [3395.0, 5529.0],
            trend: {
              value: 0.629,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [5529.0, 4365.0],
            trend: {
              value: -0.211,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
        ],
        [
          {
            data: 'H7W',
          },
          {
            data: [6429.0, 4938.0],
            trend: {
              value: -0.232,
              type: 'TREND',
              status: 0,
            },
            activity: [7.0],
          },
          {
            data: [4938.0, 5684.0],
            trend: {
              value: 0.151,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [5684.0, 4100.0],
            trend: {
              value: -0.279,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [4100.0, 5777.0],
            trend: {
              value: 0.409,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [5777.0, 4752.0],
            trend: {
              value: -0.177,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [4752.0, 4100.0],
            trend: {
              value: -0.137,
              type: 'TREND',
              status: 0,
            },
            activity: [9.0],
          },
          {
            data: [4100.0, 4845.0],
            trend: {
              value: 0.182,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [4845.0, 5311.0],
            trend: {
              value: 0.096,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [5311.0, 5404.0],
            trend: {
              value: 0.018,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [5404.0, 4365.0],
            trend: {
              value: -0.192,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
          {
            data: [4365.0, 3686.0],
            trend: {
              value: -0.156,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [3686.0, 5723.0],
            trend: {
              value: 0.553,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'H7X',
          },
          {
            data: [9224.0, 9690.0],
            trend: {
              value: 0.051,
              type: 'TREND',
              status: 2,
            },
            activity: [5.0],
          },
          {
            data: [9690.0, 10901.0],
            trend: {
              value: 0.125,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [10901.0, 8013.0],
            trend: {
              value: -0.265,
              type: 'TREND',
              status: 0,
            },
            activity: [16.0],
          },
          {
            data: [8013.0, 10063.0],
            trend: {
              value: 0.256,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [10063.0, 7454.0],
            trend: {
              value: -0.259,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [7454.0, 7826.0],
            trend: {
              value: 0.05,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [7826.0, 10994.0],
            trend: {
              value: 0.405,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
          {
            data: [10994.0, 6988.0],
            trend: {
              value: -0.364,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [6988.0, 8851.0],
            trend: {
              value: 0.267,
              type: 'TREND',
              status: 2,
            },
            activity: [5.0],
          },
          {
            data: [8851.0, 5431.0],
            trend: {
              value: -0.386,
              type: 'TREND',
              status: 0,
            },
            activity: [8.0],
          },
          {
            data: [5431.0, 7371.0],
            trend: {
              value: 0.357,
              type: 'TREND',
              status: 2,
            },
            activity: [10.0],
          },
          {
            data: [7371.0, 10863.0],
            trend: {
              value: 0.474,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
        ],
        [
          {
            data: 'H7Y',
          },
          {
            data: [1677.0, 1584.0],
            trend: {
              value: -0.055,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [1584.0, 2422.0],
            trend: {
              value: 0.529,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [2422.0, 1677.0],
            trend: {
              value: -0.308,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [1677.0, 932.0],
            trend: {
              value: -0.444,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [932.0, 1584.0],
            trend: {
              value: 0.7,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [1584.0, 1398.0],
            trend: {
              value: -0.117,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [1398.0, 1398.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
            activity: [],
          },
          {
            data: [1398.0, 1957.0],
            trend: {
              value: 0.4,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [1957.0, 2516.0],
            trend: {
              value: 0.286,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [2516.0, 1843.0],
            trend: {
              value: -0.267,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [1843.0, 582.0],
            trend: {
              value: -0.684,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [582.0, 1649.0],
            trend: {
              value: 1.833,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'J0N',
          },
          {
            data: [6056.0, 8106.0],
            trend: {
              value: 0.339,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [8106.0, 7920.0],
            trend: {
              value: -0.023,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [7920.0, 8758.0],
            trend: {
              value: 0.106,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [8758.0, 6988.0],
            trend: {
              value: -0.202,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
          {
            data: [6988.0, 7454.0],
            trend: {
              value: 0.067,
              type: 'TREND',
              status: 2,
            },
            activity: [4.0],
          },
          {
            data: [7454.0, 9690.0],
            trend: {
              value: 0.3,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [9690.0, 8013.0],
            trend: {
              value: -0.173,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [8013.0, 9038.0],
            trend: {
              value: 0.128,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [9038.0, 8292.0],
            trend: {
              value: -0.083,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [8292.0, 7662.0],
            trend: {
              value: -0.076,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [7662.0, 5917.0],
            trend: {
              value: -0.228,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [5917.0, 5335.0],
            trend: {
              value: -0.098,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'J0R',
          },
          {
            data: [9410.0, 10528.0],
            trend: {
              value: 0.119,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
          {
            data: [10528.0, 10715.0],
            trend: {
              value: 0.018,
              type: 'TREND',
              status: 2,
            },
            activity: [6.0],
          },
          {
            data: [10715.0, 10715.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
            activity: [1.0],
          },
          {
            data: [10715.0, 11740.0],
            trend: {
              value: 0.096,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [11740.0, 11646.0],
            trend: {
              value: -0.008,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [11646.0, 7920.0],
            trend: {
              value: -0.32,
              type: 'TREND',
              status: 0,
            },
            activity: [4.0],
          },
          {
            data: [7920.0, 11553.0],
            trend: {
              value: 0.459,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [11553.0, 12392.0],
            trend: {
              value: 0.073,
              type: 'TREND',
              status: 2,
            },
            activity: [11.0],
          },
          {
            data: [12392.0, 12951.0],
            trend: {
              value: 0.045,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
          {
            data: [12951.0, 7177.0],
            trend: {
              value: -0.446,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [7177.0, 7177.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
            activity: [null],
          },
          {
            data: [7177.0, 8535.0],
            trend: {
              value: 0.189,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'J5J',
          },
          {
            data: [2329.0, 1491.0],
            trend: {
              value: -0.36,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [1491.0, 1118.0],
            trend: {
              value: -0.25,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [1118.0, 2422.0],
            trend: {
              value: 1.166,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [2422.0, 2236.0],
            trend: {
              value: -0.077,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [2236.0, 1863.0],
            trend: {
              value: -0.167,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [1863.0, 2236.0],
            trend: {
              value: 0.2,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [2236.0, 1398.0],
            trend: {
              value: -0.375,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [1398.0, 2516.0],
            trend: {
              value: 0.8,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [2516.0, 2236.0],
            trend: {
              value: -0.111,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [2236.0, 3007.0],
            trend: {
              value: 0.345,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [3007.0, null],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [null],
          },
          {
            data: [null, 1940.0],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'J5K',
          },
          {
            data: [932.0, 745.0],
            trend: {
              value: -0.201,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [745.0, 745.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
            activity: [],
          },
          {
            data: [745.0, 559.0],
            trend: {
              value: -0.25,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [559.0, 1398.0],
            trend: {
              value: 1.501,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [1398.0, 559.0],
            trend: {
              value: -0.6,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [559.0, 839.0],
            trend: {
              value: 0.501,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [839.0, 559.0],
            trend: {
              value: -0.334,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [559.0, 1304.0],
            trend: {
              value: 1.333,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [1304.0, 466.0],
            trend: {
              value: -0.643,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [466.0, 485.0],
            trend: {
              value: 0.041,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [485.0, 873.0],
            trend: {
              value: 0.8,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [873.0, 776.0],
            trend: {
              value: -0.111,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'J5L',
          },
          {
            data: [7547.0, 3540.0],
            trend: {
              value: -0.531,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [3540.0, 4752.0],
            trend: {
              value: 0.342,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [4752.0, 5497.0],
            trend: {
              value: 0.157,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [5497.0, 3820.0],
            trend: {
              value: -0.305,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [3820.0, 5963.0],
            trend: {
              value: 0.561,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [5963.0, 5311.0],
            trend: {
              value: -0.109,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [5311.0, 4472.0],
            trend: {
              value: -0.158,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [4472.0, 3261.0],
            trend: {
              value: -0.271,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [3261.0, 3634.0],
            trend: {
              value: 0.114,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [3634.0, 4849.0],
            trend: {
              value: 0.334,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [4849.0, 3783.0],
            trend: {
              value: -0.22,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [3783.0, 5723.0],
            trend: {
              value: 0.513,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'J6W',
          },
          {
            data: [8013.0, 8106.0],
            trend: {
              value: 0.012,
              type: 'TREND',
              status: 2,
            },
            activity: [5.0],
          },
          {
            data: [8106.0, 9876.0],
            trend: {
              value: 0.218,
              type: 'TREND',
              status: 2,
            },
            activity: [5.0],
          },
          {
            data: [9876.0, 8013.0],
            trend: {
              value: -0.189,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
          {
            data: [8013.0, 10994.0],
            trend: {
              value: 0.372,
              type: 'TREND',
              status: 2,
            },
            activity: [10.0],
          },
          {
            data: [10994.0, 6801.0],
            trend: {
              value: -0.381,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [6801.0, 9131.0],
            trend: {
              value: 0.343,
              type: 'TREND',
              status: 2,
            },
            activity: [5.0],
          },
          {
            data: [9131.0, 10621.0],
            trend: {
              value: 0.163,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [10621.0, 7920.0],
            trend: {
              value: -0.254,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
          {
            data: [7920.0, 9503.0],
            trend: {
              value: 0.2,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [9503.0, 9214.0],
            trend: {
              value: -0.03,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [9214.0, 7565.0],
            trend: {
              value: -0.179,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [7565.0, 8826.0],
            trend: {
              value: 0.167,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
        ],
        [
          {
            data: 'J6X',
          },
          {
            data: [6895.0, 4845.0],
            trend: {
              value: -0.297,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [4845.0, 8758.0],
            trend: {
              value: 0.808,
              type: 'TREND',
              status: 2,
            },
            activity: [6.0],
          },
          {
            data: [8758.0, 6429.0],
            trend: {
              value: -0.266,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [6429.0, 7081.0],
            trend: {
              value: 0.101,
              type: 'TREND',
              status: 2,
            },
            activity: [19.0],
          },
          {
            data: [7081.0, 5777.0],
            trend: {
              value: -0.184,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [5777.0, 8479.0],
            trend: {
              value: 0.468,
              type: 'TREND',
              status: 2,
            },
            activity: [4.0],
          },
          {
            data: [8479.0, 6615.0],
            trend: {
              value: -0.22,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [6615.0, 7454.0],
            trend: {
              value: 0.127,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
          {
            data: [7454.0, 5777.0],
            trend: {
              value: -0.225,
              type: 'TREND',
              status: 0,
            },
            activity: [5.0],
          },
          {
            data: [5777.0, 4559.0],
            trend: {
              value: -0.211,
              type: 'TREND',
              status: 0,
            },
            activity: [4.0],
          },
          {
            data: [4559.0, 5334.0],
            trend: {
              value: 0.17,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [5334.0, 6208.0],
            trend: {
              value: 0.164,
              type: 'TREND',
              status: 2,
            },
            activity: [13.0],
          },
        ],
        [
          {
            data: 'J6Y',
          },
          {
            data: [2236.0, 2050.0],
            trend: {
              value: -0.083,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [2050.0, 1677.0],
            trend: {
              value: -0.182,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [1677.0, 3075.0],
            trend: {
              value: 0.834,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [3075.0, 2888.0],
            trend: {
              value: -0.061,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [2888.0, 1491.0],
            trend: {
              value: -0.484,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [1491.0, 2609.0],
            trend: {
              value: 0.75,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [2609.0, 2795.0],
            trend: {
              value: 0.071,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [2795.0, 1304.0],
            trend: {
              value: -0.533,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [1304.0, 2422.0],
            trend: {
              value: 0.857,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [2422.0, 2328.0],
            trend: {
              value: -0.039,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [2328.0, 1649.0],
            trend: {
              value: -0.292,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [1649.0, 1649.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'J6Z',
          },
          {
            data: [7081.0, 4379.0],
            trend: {
              value: -0.382,
              type: 'TREND',
              status: 0,
            },
            activity: [6.0],
          },
          {
            data: [4379.0, 7547.0],
            trend: {
              value: 0.723,
              type: 'TREND',
              status: 2,
            },
            activity: [4.0],
          },
          {
            data: [7547.0, 7547.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
            activity: [8.0],
          },
          {
            data: [7547.0, 5404.0],
            trend: {
              value: -0.284,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [5404.0, 6056.0],
            trend: {
              value: 0.121,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [6056.0, 6336.0],
            trend: {
              value: 0.046,
              type: 'TREND',
              status: 2,
            },
            activity: [19.0],
          },
          {
            data: [6336.0, 5963.0],
            trend: {
              value: -0.059,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [5963.0, 7920.0],
            trend: {
              value: 0.328,
              type: 'TREND',
              status: 2,
            },
            activity: [4.0],
          },
          {
            data: [7920.0, 7081.0],
            trend: {
              value: -0.106,
              type: 'TREND',
              status: 0,
            },
            activity: [5.0],
          },
          {
            data: [7081.0, 4753.0],
            trend: {
              value: -0.329,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
          {
            data: [4753.0, 6983.0],
            trend: {
              value: 0.469,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [6983.0, 6013.0],
            trend: {
              value: -0.139,
              type: 'TREND',
              status: 0,
            },
            activity: [5.0],
          },
        ],
        [
          {
            data: 'J7A',
          },
          {
            data: [4472.0, 3913.0],
            trend: {
              value: -0.125,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [3913.0, 6615.0],
            trend: {
              value: 0.691,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [6615.0, 4752.0],
            trend: {
              value: -0.282,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
          {
            data: [4752.0, 5031.0],
            trend: {
              value: 0.059,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [5031.0, 4565.0],
            trend: {
              value: -0.093,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [4565.0, 4938.0],
            trend: {
              value: 0.082,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [4938.0, 3727.0],
            trend: {
              value: -0.245,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [3727.0, 5124.0],
            trend: {
              value: 0.375,
              type: 'TREND',
              status: 2,
            },
            activity: [4.0],
          },
          {
            data: [5124.0, 4845.0],
            trend: {
              value: -0.054,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [4845.0, 4171.0],
            trend: {
              value: -0.139,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [4171.0, 4753.0],
            trend: {
              value: 0.14,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [4753.0, 3686.0],
            trend: {
              value: -0.224,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'J7B',
          },
          {
            data: [4938.0, 4006.0],
            trend: {
              value: -0.189,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [4006.0, 5311.0],
            trend: {
              value: 0.326,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [5311.0, 4565.0],
            trend: {
              value: -0.14,
              type: 'TREND',
              status: 0,
            },
            activity: [5.0],
          },
          {
            data: [4565.0, 4565.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
            activity: [7.0],
          },
          {
            data: [4565.0, 6056.0],
            trend: {
              value: 0.327,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [6056.0, 3634.0],
            trend: {
              value: -0.4,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [3634.0, 3913.0],
            trend: {
              value: 0.077,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [3913.0, 5031.0],
            trend: {
              value: 0.286,
              type: 'TREND',
              status: 2,
            },
            activity: [11.0],
          },
          {
            data: [5031.0, 4472.0],
            trend: {
              value: -0.111,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [4472.0, 4074.0],
            trend: {
              value: -0.089,
              type: 'TREND',
              status: 0,
            },
            activity: [6.0],
          },
          {
            data: [4074.0, 4753.0],
            trend: {
              value: 0.167,
              type: 'TREND',
              status: 2,
            },
            activity: [4.0],
          },
          {
            data: [4753.0, 4462.0],
            trend: {
              value: -0.061,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'J7C',
          },
          {
            data: [14814.0, 6615.0],
            trend: {
              value: -0.553,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
          {
            data: [6615.0, 11740.0],
            trend: {
              value: 0.775,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [11740.0, 14162.0],
            trend: {
              value: 0.206,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [14162.0, 12392.0],
            trend: {
              value: -0.125,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [12392.0, 11460.0],
            trend: {
              value: -0.075,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
          {
            data: [11460.0, 10528.0],
            trend: {
              value: -0.081,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [10528.0, 10994.0],
            trend: {
              value: 0.044,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [10994.0, 10808.0],
            trend: {
              value: -0.017,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [10808.0, 15001.0],
            trend: {
              value: 0.388,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [15001.0, 6983.0],
            trend: {
              value: -0.534,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [6983.0, 9311.0],
            trend: {
              value: 0.333,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [9311.0, 13191.0],
            trend: {
              value: 0.417,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
        ],
        [
          {
            data: 'J7E',
          },
          {
            data: [4938.0, 8199.0],
            trend: {
              value: 0.66,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [8199.0, 5963.0],
            trend: {
              value: -0.273,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [5963.0, 6056.0],
            trend: {
              value: 0.016,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [6056.0, 9783.0],
            trend: {
              value: 0.615,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [9783.0, 5311.0],
            trend: {
              value: -0.457,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [5311.0, 6615.0],
            trend: {
              value: 0.246,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [6615.0, 5683.0],
            trend: {
              value: -0.141,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [5683.0, 6708.0],
            trend: {
              value: 0.18,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
          {
            data: [6708.0, 7640.0],
            trend: {
              value: 0.139,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [7640.0, 5141.0],
            trend: {
              value: -0.327,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [5141.0, 5334.0],
            trend: {
              value: 0.038,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
          {
            data: [5334.0, 6983.0],
            trend: {
              value: 0.309,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
        ],
        [
          {
            data: 'J7G',
          },
          {
            data: [2888.0, 2702.0],
            trend: {
              value: -0.064,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
          {
            data: [2702.0, 3075.0],
            trend: {
              value: 0.138,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [3075.0, 2422.0],
            trend: {
              value: -0.212,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
          {
            data: [2422.0, 3354.0],
            trend: {
              value: 0.385,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [3354.0, 1864.0],
            trend: {
              value: -0.444,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [1864.0, 4100.0],
            trend: {
              value: 1.2,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [4100.0, 3354.0],
            trend: {
              value: -0.182,
              type: 'TREND',
              status: 0,
            },
            activity: [4.0],
          },
          {
            data: [3354.0, 2702.0],
            trend: {
              value: -0.194,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [2702.0, 3168.0],
            trend: {
              value: 0.172,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [3168.0, 1843.0],
            trend: {
              value: -0.418,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [1843.0, 2910.0],
            trend: {
              value: 0.579,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [2910.0, 4074.0],
            trend: {
              value: 0.4,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
        ],
        [
          {
            data: 'J7H',
          },
          {
            data: [5218.0, 6056.0],
            trend: {
              value: 0.161,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [6056.0, 5683.0],
            trend: {
              value: -0.062,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [5683.0, 7920.0],
            trend: {
              value: 0.394,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [7920.0, 5218.0],
            trend: {
              value: -0.341,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [5218.0, 5590.0],
            trend: {
              value: 0.071,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [5590.0, 6149.0],
            trend: {
              value: 0.1,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [6149.0, 6988.0],
            trend: {
              value: 0.136,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [6988.0, 3820.0],
            trend: {
              value: -0.453,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [3820.0, 7733.0],
            trend: {
              value: 1.024,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [7733.0, 6304.0],
            trend: {
              value: -0.185,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [6304.0, 5044.0],
            trend: {
              value: -0.2,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [5044.0, 5529.0],
            trend: {
              value: 0.096,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'J7J',
          },
          {
            data: [4752.0, 4565.0],
            trend: {
              value: -0.039,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [4565.0, 4286.0],
            trend: {
              value: -0.061,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [4286.0, 6522.0],
            trend: {
              value: 0.522,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [6522.0, 5590.0],
            trend: {
              value: -0.143,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [5590.0, 3727.0],
            trend: {
              value: -0.333,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [3727.0, 5777.0],
            trend: {
              value: 0.55,
              type: 'TREND',
              status: 2,
            },
            activity: [4.0],
          },
          {
            data: [5777.0, 4752.0],
            trend: {
              value: -0.177,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [4752.0, 5590.0],
            trend: {
              value: 0.176,
              type: 'TREND',
              status: 2,
            },
            activity: [6.0],
          },
          {
            data: [5590.0, 6149.0],
            trend: {
              value: 0.1,
              type: 'TREND',
              status: 2,
            },
            activity: [5.0],
          },
          {
            data: [6149.0, 4753.0],
            trend: {
              value: -0.227,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [4753.0, 3783.0],
            trend: {
              value: -0.204,
              type: 'TREND',
              status: 0,
            },
            activity: [8.0],
          },
          {
            data: [3783.0, 3589.0],
            trend: {
              value: -0.051,
              type: 'TREND',
              status: 0,
            },
            activity: [5.0],
          },
        ],
        [
          {
            data: 'J7L',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'J7N',
          },
          {
            data: [5963.0, 4845.0],
            trend: {
              value: -0.187,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [4845.0, 4472.0],
            trend: {
              value: -0.077,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [4472.0, 6801.0],
            trend: {
              value: 0.521,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [6801.0, 5590.0],
            trend: {
              value: -0.178,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [5590.0, 6988.0],
            trend: {
              value: 0.25,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [6988.0, 4845.0],
            trend: {
              value: -0.307,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [4845.0, 4752.0],
            trend: {
              value: -0.019,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [4752.0, 6988.0],
            trend: {
              value: 0.471,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [6988.0, 5590.0],
            trend: {
              value: -0.2,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [5590.0, 4559.0],
            trend: {
              value: -0.184,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [4559.0, 5141.0],
            trend: {
              value: 0.128,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [5141.0, 6402.0],
            trend: {
              value: 0.245,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'J7P',
          },
          {
            data: [4472.0, 4100.0],
            trend: {
              value: -0.083,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [4100.0, 5963.0],
            trend: {
              value: 0.454,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [5963.0, 4752.0],
            trend: {
              value: -0.203,
              type: 'TREND',
              status: 0,
            },
            activity: [4.0],
          },
          {
            data: [4752.0, 5031.0],
            trend: {
              value: 0.059,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [5031.0, 5963.0],
            trend: {
              value: 0.185,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [5963.0, 4286.0],
            trend: {
              value: -0.281,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [4286.0, 6149.0],
            trend: {
              value: 0.435,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [6149.0, 4659.0],
            trend: {
              value: -0.242,
              type: 'TREND',
              status: 0,
            },
            activity: [5.0],
          },
          {
            data: [4659.0, 4472.0],
            trend: {
              value: -0.04,
              type: 'TREND',
              status: 0,
            },
            activity: [4.0],
          },
          {
            data: [4472.0, 4753.0],
            trend: {
              value: 0.063,
              type: 'TREND',
              status: 2,
            },
            activity: [5.0],
          },
          {
            data: [4753.0, 3783.0],
            trend: {
              value: -0.204,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [3783.0, 5820.0],
            trend: {
              value: 0.538,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'J7R',
          },
          {
            data: [8013.0, 10155.0],
            trend: {
              value: 0.267,
              type: 'TREND',
              status: 2,
            },
            activity: [5.0],
          },
          {
            data: [10155.0, 12671.0],
            trend: {
              value: 0.248,
              type: 'TREND',
              status: 2,
            },
            activity: [12.0],
          },
          {
            data: [12671.0, 12392.0],
            trend: {
              value: -0.022,
              type: 'TREND',
              status: 0,
            },
            activity: [22.0],
          },
          {
            data: [12392.0, 12578.0],
            trend: {
              value: 0.015,
              type: 'TREND',
              status: 2,
            },
            activity: [13.0],
          },
          {
            data: [12578.0, 11087.0],
            trend: {
              value: -0.119,
              type: 'TREND',
              status: 0,
            },
            activity: [9.0],
          },
          {
            data: [11087.0, 9596.0],
            trend: {
              value: -0.134,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [9596.0, 10342.0],
            trend: {
              value: 0.078,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [10342.0, 9597.0],
            trend: {
              value: -0.072,
              type: 'TREND',
              status: 0,
            },
            activity: [28.0],
          },
          {
            data: [9597.0, 12858.0],
            trend: {
              value: 0.34,
              type: 'TREND',
              status: 2,
            },
            activity: [24.0],
          },
          {
            data: [12858.0, 10378.0],
            trend: {
              value: -0.193,
              type: 'TREND',
              status: 0,
            },
            activity: [24.0],
          },
          {
            data: [10378.0, 10863.0],
            trend: {
              value: 0.047,
              type: 'TREND',
              status: 2,
            },
            activity: [12.0],
          },
          {
            data: [10863.0, 13579.0],
            trend: {
              value: 0.25,
              type: 'TREND',
              status: 2,
            },
            activity: [6.0],
          },
        ],
        [
          {
            data: 'J7Y',
          },
          {
            data: [6243.0, 5311.0],
            trend: {
              value: -0.149,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [5311.0, 6895.0],
            trend: {
              value: 0.298,
              type: 'TREND',
              status: 2,
            },
            activity: [6.0],
          },
          {
            data: [6895.0, 6056.0],
            trend: {
              value: -0.122,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [6056.0, 6056.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
            activity: [1.0],
          },
          {
            data: [6056.0, 6802.0],
            trend: {
              value: 0.123,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [6802.0, 5870.0],
            trend: {
              value: -0.137,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [5870.0, 5683.0],
            trend: {
              value: -0.032,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [5683.0, 6895.0],
            trend: {
              value: 0.213,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [6895.0, 6615.0],
            trend: {
              value: -0.041,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [6615.0, 5432.0],
            trend: {
              value: -0.179,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [5432.0, 2910.0],
            trend: {
              value: -0.464,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [2910.0, 6886.0],
            trend: {
              value: 1.366,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'J7Z',
          },
          {
            data: [5404.0, 5870.0],
            trend: {
              value: 0.086,
              type: 'TREND',
              status: 2,
            },
            activity: [5.0],
          },
          {
            data: [5870.0, 5311.0],
            trend: {
              value: -0.095,
              type: 'TREND',
              status: 0,
            },
            activity: [10.0],
          },
          {
            data: [5311.0, 4845.0],
            trend: {
              value: -0.088,
              type: 'TREND',
              status: 0,
            },
            activity: [4.0],
          },
          {
            data: [4845.0, 6336.0],
            trend: {
              value: 0.308,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [6336.0, 4472.0],
            trend: {
              value: -0.294,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
          {
            data: [4472.0, 5218.0],
            trend: {
              value: 0.167,
              type: 'TREND',
              status: 2,
            },
            activity: [4.0],
          },
          {
            data: [5218.0, 7268.0],
            trend: {
              value: 0.393,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [7268.0, 3913.0],
            trend: {
              value: -0.462,
              type: 'TREND',
              status: 0,
            },
            activity: [19.0],
          },
          {
            data: [3913.0, 4006.0],
            trend: {
              value: 0.024,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [4006.0, 5625.0],
            trend: {
              value: 0.404,
              type: 'TREND',
              status: 2,
            },
            activity: [9.0],
          },
          {
            data: [5625.0, 3783.0],
            trend: {
              value: -0.327,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [3783.0, 6401.0],
            trend: {
              value: 0.692,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
        ],
      ],
    },
    quarter: {
      description: 'Q1 2023 - Compared to last 5 quarters',
      headers: [
        {
          label: 'Region',
          subLabel: '',
          type: 'label',
        },
        {
          label: 'Q1',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'Q2',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'Q3',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'Q4',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'Q1',
          subLabel: '2023',
          type: 'dollars',
        },
      ],
      items: [
        [
          {
            data: 'Region Avg',
          },
          {
            data: [7021.527777777777, 12570.763157894737],
            trend: {
              value: 0.79,
              type: 'TREND',
              status: 2,
            },
            activity: [9.192307692307692],
          },
          {
            data: [12570.763157894737, 17739.315789473683],
            trend: {
              value: 0.411,
              type: 'TREND',
              status: 2,
            },
            activity: [12.62962962962963],
          },
          {
            data: [17739.315789473683, 17376.526315789473],
            trend: {
              value: -0.02,
              type: 'TREND',
              status: 0,
            },
            activity: [12.633333333333333],
          },
          {
            data: [17376.526315789473, 18190.552631578947],
            trend: {
              value: 0.047,
              type: 'TREND',
              status: 2,
            },
            activity: [13.379310344827585],
          },
          {
            data: [18190.552631578947, 16187.605263157895],
            trend: {
              value: -0.11,
              type: 'TREND',
              status: 0,
            },
            activity: [9.68],
          },
        ],
        [
          {
            data: 'H7A',
          },
          {
            data: [4518.0, 7634.0],
            trend: {
              value: 0.69,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [7634.0, 9968.0],
            trend: {
              value: 0.306,
              type: 'TREND',
              status: 2,
            },
            activity: [9.0],
          },
          {
            data: [9968.0, 6801.0],
            trend: {
              value: -0.318,
              type: 'TREND',
              status: 0,
            },
            activity: [4.0],
          },
          {
            data: [6801.0, 9224.0],
            trend: {
              value: 0.356,
              type: 'TREND',
              status: 2,
            },
            activity: [14.0],
          },
          {
            data: [9224.0, 9700.0],
            trend: {
              value: 0.052,
              type: 'TREND',
              status: 2,
            },
            activity: [4.0],
          },
        ],
        [
          {
            data: 'H7B',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'H7C',
          },
          {
            data: [2392.0, 5494.0],
            trend: {
              value: 1.297,
              type: 'TREND',
              status: 2,
            },
            activity: [14.0],
          },
          {
            data: [5494.0, 9597.0],
            trend: {
              value: 0.747,
              type: 'TREND',
              status: 2,
            },
            activity: [12.0],
          },
          {
            data: [9597.0, 9597.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
            activity: [27.0],
          },
          {
            data: [9597.0, 10995.0],
            trend: {
              value: 0.146,
              type: 'TREND',
              status: 2,
            },
            activity: [20.0],
          },
          {
            data: [10995.0, 9118.0],
            trend: {
              value: -0.171,
              type: 'TREND',
              status: 0,
            },
            activity: [5.0],
          },
        ],
        [
          {
            data: 'H7E',
          },
          {
            data: [8240.0, 14936.0],
            trend: {
              value: 0.813,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [14936.0, 17702.0],
            trend: {
              value: 0.185,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [17702.0, 14442.0],
            trend: {
              value: -0.184,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [14442.0, 17796.0],
            trend: {
              value: 0.232,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [17796.0, 18524.0],
            trend: {
              value: 0.041,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'H7G',
          },
          {
            data: [3987.0, 11161.0],
            trend: {
              value: 1.799,
              type: 'TREND',
              status: 2,
            },
            activity: [11.0],
          },
          {
            data: [11161.0, 16026.0],
            trend: {
              value: 0.436,
              type: 'TREND',
              status: 2,
            },
            activity: [21.0],
          },
          {
            data: [16026.0, 16398.0],
            trend: {
              value: 0.023,
              type: 'TREND',
              status: 2,
            },
            activity: [18.0],
          },
          {
            data: [16398.0, 17889.0],
            trend: {
              value: 0.091,
              type: 'TREND',
              status: 2,
            },
            activity: [20.0],
          },
          {
            data: [17889.0, 15227.0],
            trend: {
              value: -0.149,
              type: 'TREND',
              status: 0,
            },
            activity: [16.0],
          },
        ],
        [
          {
            data: 'H7H',
          },
          {
            data: [null, 2887.0],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [2887.0, 6522.0],
            trend: {
              value: 1.259,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [6522.0, 5591.0],
            trend: {
              value: -0.143,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [5591.0, 6802.0],
            trend: {
              value: 0.217,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [6802.0, 6208.0],
            trend: {
              value: -0.087,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'H7J',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'H7K',
          },
          {
            data: [0, 0],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [null],
          },
          {
            data: [0, 0],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [null],
          },
          {
            data: [0, 0],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [null],
          },
          {
            data: [0, 0],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [null],
          },
          {
            data: [14814.0, 16877.0],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [1.0],
          },
        ],
        [
          {
            data: 'H7L',
          },
          {
            data: [9302.0, 17240.0],
            trend: {
              value: 0.853,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [17240.0, 29162.0],
            trend: {
              value: 0.692,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [29162.0, 25436.0],
            trend: {
              value: -0.128,
              type: 'TREND',
              status: 0,
            },
            activity: [5.0],
          },
          {
            data: [25436.0, 29257.0],
            trend: {
              value: 0.15,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [29257.0, 25702.0],
            trend: {
              value: -0.122,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'H7M',
          },
          {
            data: [5848.0, 19234.0],
            trend: {
              value: 2.289,
              type: 'TREND',
              status: 2,
            },
            activity: [21.0],
          },
          {
            data: [19234.0, 23200.0],
            trend: {
              value: 0.206,
              type: 'TREND',
              status: 2,
            },
            activity: [46.0],
          },
          {
            data: [23200.0, 20124.0],
            trend: {
              value: -0.133,
              type: 'TREND',
              status: 0,
            },
            activity: [52.0],
          },
          {
            data: [20124.0, 20870.0],
            trend: {
              value: 0.037,
              type: 'TREND',
              status: 2,
            },
            activity: [57.0],
          },
          {
            data: [20870.0, 18041.0],
            trend: {
              value: -0.136,
              type: 'TREND',
              status: 0,
            },
            activity: [20.0],
          },
        ],
        [
          {
            data: 'H7N',
          },
          {
            data: [3456.0, 15417.0],
            trend: {
              value: 3.461,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [15417.0, 23013.0],
            trend: {
              value: 0.493,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [23013.0, 24131.0],
            trend: {
              value: 0.049,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [24131.0, 26647.0],
            trend: {
              value: 0.104,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [26647.0, 22695.0],
            trend: {
              value: -0.148,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'H7P',
          },
          {
            data: [18605.0, 29968.0],
            trend: {
              value: 0.611,
              type: 'TREND',
              status: 2,
            },
            activity: [10.0],
          },
          {
            data: [29968.0, 42207.0],
            trend: {
              value: 0.408,
              type: 'TREND',
              status: 2,
            },
            activity: [11.0],
          },
          {
            data: [42207.0, 44815.0],
            trend: {
              value: 0.062,
              type: 'TREND',
              status: 2,
            },
            activity: [39.0],
          },
          {
            data: [44815.0, 43231.0],
            trend: {
              value: -0.035,
              type: 'TREND',
              status: 0,
            },
            activity: [9.0],
          },
          {
            data: [43231.0, 45586.0],
            trend: {
              value: 0.054,
              type: 'TREND',
              status: 2,
            },
            activity: [15.0],
          },
        ],
        [
          {
            data: 'H7R',
          },
          {
            data: [4253.0, 13314.0],
            trend: {
              value: 2.13,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [13314.0, 16399.0],
            trend: {
              value: 0.232,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [16399.0, 15560.0],
            trend: {
              value: -0.051,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [15560.0, 18635.0],
            trend: {
              value: 0.198,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [18635.0, 14841.0],
            trend: {
              value: -0.204,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'H7S',
          },
          {
            data: [null, 745.0],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [8.0],
          },
          {
            data: [745.0, 839.0],
            trend: {
              value: 0.126,
              type: 'TREND',
              status: 2,
            },
            activity: [5.0],
          },
          {
            data: [839.0, 1304.0],
            trend: {
              value: 0.554,
              type: 'TREND',
              status: 2,
            },
            activity: [14.0],
          },
          {
            data: [1304.0, 1211.0],
            trend: {
              value: -0.071,
              type: 'TREND',
              status: 0,
            },
            activity: [11.0],
          },
          {
            data: [1211.0, 1649.0],
            trend: {
              value: 0.362,
              type: 'TREND',
              status: 2,
            },
            activity: [5.0],
          },
        ],
        [
          {
            data: 'H7T',
          },
          {
            data: [4518.0, 14765.0],
            trend: {
              value: 2.268,
              type: 'TREND',
              status: 2,
            },
            activity: [28.0],
          },
          {
            data: [14765.0, 24317.0],
            trend: {
              value: 0.647,
              type: 'TREND',
              status: 2,
            },
            activity: [26.0],
          },
          {
            data: [24317.0, 23851.0],
            trend: {
              value: -0.019,
              type: 'TREND',
              status: 0,
            },
            activity: [30.0],
          },
          {
            data: [23851.0, 21336.0],
            trend: {
              value: -0.105,
              type: 'TREND',
              status: 0,
            },
            activity: [38.0],
          },
          {
            data: [21336.0, 20853.0],
            trend: {
              value: -0.023,
              type: 'TREND',
              status: 0,
            },
            activity: [9.0],
          },
        ],
        [
          {
            data: 'H7V',
          },
          {
            data: [4785.0, 13581.0],
            trend: {
              value: 1.838,
              type: 'TREND',
              status: 2,
            },
            activity: [21.0],
          },
          {
            data: [13581.0, 19193.0],
            trend: {
              value: 0.413,
              type: 'TREND',
              status: 2,
            },
            activity: [13.0],
          },
          {
            data: [19193.0, 16398.0],
            trend: {
              value: -0.146,
              type: 'TREND',
              status: 0,
            },
            activity: [19.0],
          },
          {
            data: [16398.0, 18821.0],
            trend: {
              value: 0.148,
              type: 'TREND',
              status: 2,
            },
            activity: [10.0],
          },
          {
            data: [18821.0, 13289.0],
            trend: {
              value: -0.294,
              type: 'TREND',
              status: 0,
            },
            activity: [6.0],
          },
        ],
        [
          {
            data: 'H7W',
          },
          {
            data: [2392.0, 11070.0],
            trend: {
              value: 3.628,
              type: 'TREND',
              status: 2,
            },
            activity: [13.0],
          },
          {
            data: [11070.0, 14722.0],
            trend: {
              value: 0.33,
              type: 'TREND',
              status: 2,
            },
            activity: [17.0],
          },
          {
            data: [14722.0, 14629.0],
            trend: {
              value: -0.006,
              type: 'TREND',
              status: 0,
            },
            activity: [10.0],
          },
          {
            data: [14629.0, 15560.0],
            trend: {
              value: 0.064,
              type: 'TREND',
              status: 2,
            },
            activity: [17.0],
          },
          {
            data: [15560.0, 13774.0],
            trend: {
              value: -0.115,
              type: 'TREND',
              status: 0,
            },
            activity: [5.0],
          },
        ],
        [
          {
            data: 'H7X',
          },
          {
            data: [10898.0, 20059.0],
            trend: {
              value: 0.841,
              type: 'TREND',
              status: 2,
            },
            activity: [8.0],
          },
          {
            data: [20059.0, 28604.0],
            trend: {
              value: 0.426,
              type: 'TREND',
              status: 2,
            },
            activity: [22.0],
          },
          {
            data: [28604.0, 25343.0],
            trend: {
              value: -0.114,
              type: 'TREND',
              status: 0,
            },
            activity: [17.0],
          },
          {
            data: [25343.0, 26833.0],
            trend: {
              value: 0.059,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [26833.0, 23665.0],
            trend: {
              value: -0.118,
              type: 'TREND',
              status: 0,
            },
            activity: [19.0],
          },
        ],
        [
          {
            data: 'H7Y',
          },
          {
            data: [798.0, 2595.0],
            trend: {
              value: 2.252,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [2595.0, 5683.0],
            trend: {
              value: 1.19,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [5683.0, 3914.0],
            trend: {
              value: -0.311,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [3914.0, 5871.0],
            trend: {
              value: 0.5,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [5871.0, 4074.0],
            trend: {
              value: -0.306,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'J0N',
          },
          {
            data: [13821.0, 15787.0],
            trend: {
              value: 0.142,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [15787.0, 24784.0],
            trend: {
              value: 0.57,
              type: 'TREND',
              status: 2,
            },
            activity: [4.0],
          },
          {
            data: [24784.0, 24132.0],
            trend: {
              value: -0.026,
              type: 'TREND',
              status: 0,
            },
            activity: [7.0],
          },
          {
            data: [24132.0, 25343.0],
            trend: {
              value: 0.05,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [25343.0, 18914.0],
            trend: {
              value: -0.254,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'J0R',
          },
          {
            data: [7708.0, 16975.0],
            trend: {
              value: 1.202,
              type: 'TREND',
              status: 2,
            },
            activity: [6.0],
          },
          {
            data: [16975.0, 31958.0],
            trend: {
              value: 0.883,
              type: 'TREND',
              status: 2,
            },
            activity: [9.0],
          },
          {
            data: [31958.0, 31306.0],
            trend: {
              value: -0.02,
              type: 'TREND',
              status: 0,
            },
            activity: [10.0],
          },
          {
            data: [31306.0, 36896.0],
            trend: {
              value: 0.179,
              type: 'TREND',
              status: 2,
            },
            activity: [14.0],
          },
          {
            data: [36896.0, 22889.0],
            trend: {
              value: -0.38,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'J5J',
          },
          {
            data: [2392.0, 4522.0],
            trend: {
              value: 0.89,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [4522.0, 5031.0],
            trend: {
              value: 0.113,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [5031.0, 6335.0],
            trend: {
              value: 0.259,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [6335.0, 6150.0],
            trend: {
              value: -0.029,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
          {
            data: [6150.0, 4947.0],
            trend: {
              value: -0.196,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
        ],
        [
          {
            data: 'J5K',
          },
          {
            data: [1329.0, 2116.0],
            trend: {
              value: 0.592,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [2116.0, 2049.0],
            trend: {
              value: -0.032,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [2049.0, 2796.0],
            trend: {
              value: 0.365,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [2796.0, 2329.0],
            trend: {
              value: -0.167,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [2329.0, 2134.0],
            trend: {
              value: -0.084,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'J5L',
          },
          {
            data: [6379.0, 14127.0],
            trend: {
              value: 1.215,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [14127.0, 13789.0],
            trend: {
              value: -0.024,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
          {
            data: [13789.0, 15094.0],
            trend: {
              value: 0.095,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [15094.0, 11367.0],
            trend: {
              value: -0.247,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [11367.0, 14355.0],
            trend: {
              value: 0.263,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
        ],
        [
          {
            data: 'J6W',
          },
          {
            data: [7442.0, 19805.0],
            trend: {
              value: 1.661,
              type: 'TREND',
              status: 2,
            },
            activity: [9.0],
          },
          {
            data: [19805.0, 25995.0],
            trend: {
              value: 0.313,
              type: 'TREND',
              status: 2,
            },
            activity: [12.0],
          },
          {
            data: [25995.0, 26926.0],
            trend: {
              value: 0.036,
              type: 'TREND',
              status: 2,
            },
            activity: [15.0],
          },
          {
            data: [26926.0, 28044.0],
            trend: {
              value: 0.042,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [28044.0, 25605.0],
            trend: {
              value: -0.087,
              type: 'TREND',
              status: 0,
            },
            activity: [8.0],
          },
        ],
        [
          {
            data: 'J6X',
          },
          {
            data: [13024.0, 17410.0],
            trend: {
              value: 0.337,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [17410.0, 20032.0],
            trend: {
              value: 0.151,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [20032.0, 21337.0],
            trend: {
              value: 0.065,
              type: 'TREND',
              status: 2,
            },
            activity: [23.0],
          },
          {
            data: [21337.0, 19846.0],
            trend: {
              value: -0.07,
              type: 'TREND',
              status: 0,
            },
            activity: [8.0],
          },
          {
            data: [19846.0, 16101.0],
            trend: {
              value: -0.189,
              type: 'TREND',
              status: 0,
            },
            activity: [17.0],
          },
        ],
        [
          {
            data: 'J6Y',
          },
          {
            data: [3455.0, 4974.0],
            trend: {
              value: 0.44,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [4974.0, 6802.0],
            trend: {
              value: 0.368,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [6802.0, 6988.0],
            trend: {
              value: 0.027,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [6988.0, 6521.0],
            trend: {
              value: -0.067,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [6521.0, 5626.0],
            trend: {
              value: -0.137,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'J6Z',
          },
          {
            data: [6379.0, 12412.0],
            trend: {
              value: 0.946,
              type: 'TREND',
              status: 2,
            },
            activity: [12.0],
          },
          {
            data: [12412.0, 19473.0],
            trend: {
              value: 0.569,
              type: 'TREND',
              status: 2,
            },
            activity: [18.0],
          },
          {
            data: [19473.0, 17796.0],
            trend: {
              value: -0.086,
              type: 'TREND',
              status: 0,
            },
            activity: [22.0],
          },
          {
            data: [17796.0, 20964.0],
            trend: {
              value: 0.178,
              type: 'TREND',
              status: 2,
            },
            activity: [10.0],
          },
          {
            data: [20964.0, 17749.0],
            trend: {
              value: -0.153,
              type: 'TREND',
              status: 0,
            },
            activity: [10.0],
          },
        ],
        [
          {
            data: 'J7A',
          },
          {
            data: [6379.0, 9204.0],
            trend: {
              value: 0.443,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [9204.0, 15280.0],
            trend: {
              value: 0.66,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [15280.0, 14534.0],
            trend: {
              value: -0.049,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
          {
            data: [14534.0, 13696.0],
            trend: {
              value: -0.058,
              type: 'TREND',
              status: 0,
            },
            activity: [4.0],
          },
          {
            data: [13696.0, 12610.0],
            trend: {
              value: -0.079,
              type: 'TREND',
              status: 0,
            },
            activity: [6.0],
          },
        ],
        [
          {
            data: 'J7B',
          },
          {
            data: [3987.0, 9830.0],
            trend: {
              value: 1.466,
              type: 'TREND',
              status: 2,
            },
            activity: [14.0],
          },
          {
            data: [9830.0, 13882.0],
            trend: {
              value: 0.412,
              type: 'TREND',
              status: 2,
            },
            activity: [6.0],
          },
          {
            data: [13882.0, 14255.0],
            trend: {
              value: 0.027,
              type: 'TREND',
              status: 2,
            },
            activity: [8.0],
          },
          {
            data: [14255.0, 13416.0],
            trend: {
              value: -0.059,
              type: 'TREND',
              status: 0,
            },
            activity: [11.0],
          },
          {
            data: [13416.0, 13289.0],
            trend: {
              value: -0.009,
              type: 'TREND',
              status: 0,
            },
            activity: [10.0],
          },
        ],
        [
          {
            data: 'J7C',
          },
          {
            data: [17012.0, 26992.0],
            trend: {
              value: 0.587,
              type: 'TREND',
              status: 2,
            },
            activity: [4.0],
          },
          {
            data: [26992.0, 32517.0],
            trend: {
              value: 0.205,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [32517.0, 34380.0],
            trend: {
              value: 0.057,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
          {
            data: [34380.0, 36803.0],
            trend: {
              value: 0.07,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [36803.0, 29485.0],
            trend: {
              value: -0.199,
              type: 'TREND',
              status: 0,
            },
            activity: [1.0],
          },
        ],
        [
          {
            data: 'J7E',
          },
          {
            data: [6114.0, 11851.0],
            trend: {
              value: 0.938,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
          {
            data: [11851.0, 20218.0],
            trend: {
              value: 0.706,
              type: 'TREND',
              status: 2,
            },
            activity: [3.0],
          },
          {
            data: [20218.0, 21709.0],
            trend: {
              value: 0.074,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
          {
            data: [21709.0, 20031.0],
            trend: {
              value: -0.077,
              type: 'TREND',
              status: 0,
            },
            activity: [2.0],
          },
          {
            data: [20031.0, 17458.0],
            trend: {
              value: -0.128,
              type: 'TREND',
              status: 0,
            },
            activity: [4.0],
          },
        ],
        [
          {
            data: 'J7G',
          },
          {
            data: [3456.0, 5626.0],
            trend: {
              value: 0.628,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
          {
            data: [5626.0, 8199.0],
            trend: {
              value: 0.457,
              type: 'TREND',
              status: 2,
            },
            activity: [4.0],
          },
          {
            data: [8199.0, 9318.0],
            trend: {
              value: 0.136,
              type: 'TREND',
              status: 2,
            },
            activity: [null],
          },
          {
            data: [9318.0, 9224.0],
            trend: {
              value: -0.01,
              type: 'TREND',
              status: 0,
            },
            activity: [7.0],
          },
          {
            data: [9224.0, 8827.0],
            trend: {
              value: -0.043,
              type: 'TREND',
              status: 0,
            },
            activity: [3.0],
          },
        ],
        [
          {
            data: 'J7H',
          },
          {
            data: [8240.0, 12968.0],
            trend: {
              value: 0.574,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [12968.0, 19659.0],
            trend: {
              value: 0.516,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [19659.0, 16957.0],
            trend: {
              value: -0.137,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [16957.0, 18541.0],
            trend: {
              value: 0.093,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [18541.0, 16877.0],
            trend: {
              value: -0.09,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'J7J',
          },
          {
            data: [6379.0, 9698.0],
            trend: {
              value: 0.52,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [9698.0, 15373.0],
            trend: {
              value: 0.585,
              type: 'TREND',
              status: 2,
            },
            activity: [9.0],
          },
          {
            data: [15373.0, 15094.0],
            trend: {
              value: -0.018,
              type: 'TREND',
              status: 0,
            },
            activity: [7.0],
          },
          {
            data: [15094.0, 16491.0],
            trend: {
              value: 0.093,
              type: 'TREND',
              status: 2,
            },
            activity: [11.0],
          },
          {
            data: [16491.0, 12125.0],
            trend: {
              value: -0.265,
              type: 'TREND',
              status: 0,
            },
            activity: [13.0],
          },
        ],
        [
          {
            data: 'J7L',
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
          {
            data: [],
            trend: {
              value: null,
              type: 'TREND',
              status: -1,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'J7N',
          },
          {
            data: [5583.0, 13686.0],
            trend: {
              value: 1.451,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [13686.0, 16118.0],
            trend: {
              value: 0.178,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [16118.0, 17423.0],
            trend: {
              value: 0.081,
              type: 'TREND',
              status: 2,
            },
            activity: [],
          },
          {
            data: [17423.0, 17330.0],
            trend: {
              value: -0.005,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
          {
            data: [17330.0, 16102.0],
            trend: {
              value: -0.071,
              type: 'TREND',
              status: 0,
            },
            activity: [],
          },
        ],
        [
          {
            data: 'J7P',
          },
          {
            data: [8505.0, 10548.0],
            trend: {
              value: 0.24,
              type: 'TREND',
              status: 2,
            },
            activity: [6.0],
          },
          {
            data: [10548.0, 14815.0],
            trend: {
              value: 0.405,
              type: 'TREND',
              status: 2,
            },
            activity: [11.0],
          },
          {
            data: [14815.0, 15280.0],
            trend: {
              value: 0.031,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [15280.0, 15280.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
            activity: [9.0],
          },
          {
            data: [15280.0, 14356.0],
            trend: {
              value: -0.06,
              type: 'TREND',
              status: 0,
            },
            activity: [8.0],
          },
        ],
        [
          {
            data: 'J7R',
          },
          {
            data: [19669.0, 23740.0],
            trend: {
              value: 0.207,
              type: 'TREND',
              status: 2,
            },
            activity: [23.0],
          },
          {
            data: [23740.0, 35218.0],
            trend: {
              value: 0.483,
              type: 'TREND',
              status: 2,
            },
            activity: [39.0],
          },
          {
            data: [35218.0, 33261.0],
            trend: {
              value: -0.056,
              type: 'TREND',
              status: 0,
            },
            activity: [25.0],
          },
          {
            data: [33261.0, 32797.0],
            trend: {
              value: -0.014,
              type: 'TREND',
              status: 0,
            },
            activity: [59.0],
          },
          {
            data: [32797.0, 34820.0],
            trend: {
              value: 0.062,
              type: 'TREND',
              status: 2,
            },
            activity: [42.0],
          },
        ],
        [
          {
            data: 'J7Y',
          },
          {
            data: [8239.0, 14552.0],
            trend: {
              value: 0.766,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
          {
            data: [14552.0, 18262.0],
            trend: {
              value: 0.255,
              type: 'TREND',
              status: 2,
            },
            activity: [6.0],
          },
          {
            data: [18262.0, 18728.0],
            trend: {
              value: 0.026,
              type: 'TREND',
              status: 2,
            },
            activity: [2.0],
          },
          {
            data: [18728.0, 19193.0],
            trend: {
              value: 0.025,
              type: 'TREND',
              status: 2,
            },
            activity: [1.0],
          },
          {
            data: [19193.0, 15228.0],
            trend: {
              value: -0.207,
              type: 'TREND',
              status: 0,
            },
            activity: [null],
          },
        ],
        [
          {
            data: 'J7Z',
          },
          {
            data: [7975.0, 10616.0],
            trend: {
              value: 0.331,
              type: 'TREND',
              status: 2,
            },
            activity: [7.0],
          },
          {
            data: [10616.0, 16026.0],
            trend: {
              value: 0.51,
              type: 'TREND',
              status: 2,
            },
            activity: [19.0],
          },
          {
            data: [16026.0, 16026.0],
            trend: {
              value: 0.0,
              type: 'TREND',
              status: 1,
            },
            activity: [10.0],
          },
          {
            data: [16026.0, 15187.0],
            trend: {
              value: -0.052,
              type: 'TREND',
              status: 0,
            },
            activity: [26.0],
          },
          {
            data: [15187.0, 15809.0],
            trend: {
              value: 0.041,
              type: 'TREND',
              status: 2,
            },
            activity: [9.0],
          },
        ],
      ],
    },
  },
};

export const territoryOverviewMockNoDemandData = {
  timescaleKeys: [
    {
      id: 'month',
      name: 'Month',
    },
    {
      id: 'quarter',
      name: 'Quarter',
    },
  ],
  timescaleData: {
    month: {
      description: 'April 2023 - Compared to last month',
      headers: [
        {
          label: 'Region',
          subLabel: '',
          type: 'label',
        },
        {
          label: 'Activity',
          subLabel: '',
          type: 'number',
          graph: 'bar',
          chartLabels: [
            'November',
            'December',
            'January',
            'February',
            'March',
            'April',
          ],
        },
        {
          label: 'CONTRAVE',
          subLabel: 'Volume TRx',
          type: 'number',
          graph: 'line',
          chartLabels: [
            'November',
            'December',
            'January',
            'February',
            'March',
            'April',
          ],
        },
        {
          label: 'CONTRAVE',
          subLabel: 'Share TRx',
          type: 'percent',
          graph: 'line',
          chartLabels: [
            'November',
            'December',
            'January',
            'February',
            'March',
            'April',
          ],
        },
        {
          label: 'SAXENDA',
          subLabel: 'Share TRx',
          type: 'percent',
          graph: 'line',
          chartLabels: [
            'November',
            'December',
            'January',
            'February',
            'March',
            'April',
          ],
        },
        {
          label: 'XENICAL',
          subLabel: 'Share TRx',
          type: 'percent',
          graph: 'line',
          chartLabels: [
            'November',
            'December',
            'January',
            'February',
            'March',
            'April',
          ],
        },
      ],
      items: [],
    },
    quarter: {
      description: 'Q2 2023 - Compared to last quarter',
      headers: [
        {
          label: 'Region',
          subLabel: '',
          type: 'label',
        },
        {
          label: 'Activity',
          subLabel: '',
          type: 'number',
          graph: 'bar',
          chartLabels: [
            'Q4-2021',
            'Q1-2022',
            'Q2-2022',
            'Q3-2022',
            'Q4-2022',
            'Q1-2023',
          ],
        },
        {
          label: 'CONTRAVE',
          subLabel: 'Volume TRx',
          type: 'number',
          graph: 'line',
          chartLabels: [
            'Q4-2021',
            'Q1-2022',
            'Q2-2022',
            'Q3-2022',
            'Q4-2022',
            'Q1-2023',
          ],
        },
        {
          label: 'CONTRAVE',
          subLabel: 'Share TRx',
          type: 'percent',
          graph: 'line',
          chartLabels: [
            'Q4-2021',
            'Q1-2022',
            'Q2-2022',
            'Q3-2022',
            'Q4-2022',
            'Q1-2023',
          ],
        },
        {
          label: 'SAXENDA',
          subLabel: 'Share TRx',
          type: 'percent',
          graph: 'line',
          chartLabels: [
            'Q4-2021',
            'Q1-2022',
            'Q2-2022',
            'Q3-2022',
            'Q4-2022',
            'Q1-2023',
          ],
        },
        {
          label: 'XENICAL',
          subLabel: 'Share TRx',
          type: 'percent',
          graph: 'line',
          chartLabels: [
            'Q4-2021',
            'Q1-2022',
            'Q2-2022',
            'Q3-2022',
            'Q4-2022',
            'Q1-2023',
          ],
        },
      ],
      items: [],
    },
  },
};

export const territoryOverviewMockNoSupplyData = {
  timescaleKeys: [
    {
      id: 'month',
      name: 'Month',
    },
    {
      id: 'quarter',
      name: 'Quarter',
    },
  ],
  timescaleData: {
    month: {
      description: 'March 2023 - Compared to last 12 months',
      headers: [
        {
          label: 'Region',
          subLabel: '',
          type: 'label',
        },
        {
          label: 'APR',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'MAY',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'JUN',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'JUL',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'AUG',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'SEP',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'OCT',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'NOV',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'DEC',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'JAN',
          subLabel: '2023',
          type: 'dollars',
        },
        {
          label: 'FEB',
          subLabel: '2023',
          type: 'dollars',
        },
        {
          label: 'MAR',
          subLabel: '2023',
          type: 'dollars',
        },
      ],
      items: [],
    },
    quarter: {
      description: 'Q1 2023 - Compared to last 5 quarters',
      headers: [
        {
          label: 'Region',
          subLabel: '',
          type: 'label',
        },
        {
          label: 'Q1',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'Q2',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'Q3',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'Q4',
          subLabel: '2022',
          type: 'dollars',
        },
        {
          label: 'Q1',
          subLabel: '2023',
          type: 'dollars',
        },
      ],
      items: [],
    },
  },
};
