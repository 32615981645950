import React, { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

const allChoicesAreSelected = (selectedValues, choices) =>
  selectedValues.length === Object.keys(choices).length;

const PopoverWindowCheckboxInput = ({
  options = {},
  id,
  onChange,
  initialOptions,
  buttonText = '',
}) => {
  const [selection, setSelection] = useState(initialOptions);

  const handleCheck = (ev) => {
    setSelection(
      ev.target.checked
        ? [...selection, ev.target.value]
        : selection.filter((x) => x !== ev.target.value)
    );
  };

  const handleClick = () => {
    if (allChoicesAreSelected(selection, options)) {
      setSelection([]);
    } else {
      setSelection(Object.values(options).map((option) => option.id));
    }
  };

  useEffect(() => {
    const newSelection = [...selection];
    onChange(newSelection.sort());
  }, [selection]);

  return (
    <FormGroup
      name={id}
      style={{ display: options.length === 0 ? 'none' : 'flex' }}
    >
      <Button
        data-testid="test-checkbox-button"
        aria-label="checkboxButton"
        variant="text"
        onClick={handleClick}
      >
        {buttonText}
      </Button>

      {Object.values(options).map((option) => (
        <FormControlLabel
          key={option.id}
          control={
            <Checkbox
              checked={selection.includes(option?.id)}
              onClick={handleCheck}
              name={id}
              value={option.id}
            />
          }
          label={option?.label || ''}
        />
      ))}
    </FormGroup>
  );
};

export default PopoverWindowCheckboxInput;
