export const STEPS = {
  dataReady: 'Data Ready',
  insights: 'Insights',
  curation: 'Curation',
  generation: 'CSV Generation',
  qaTesting: 'QA Testing',
  baTesting: 'BA Testing',
  delivery: 'CSV Delivery',
};

export const STATUSES = {
  NOT_STARTED: 'Not started',
  IN_PROGRESS: 'In progress',
  COMPLETED: 'Completed',
};
