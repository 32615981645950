export const nodePrefix = 'node-';
export const leafPrefix = 'leaf-';

export const overwriteURLQueryParam = ({
  queryParams = '',
  paramKey = '',
  paramValue = '',
}) => {
  const searchParams = new URLSearchParams(queryParams);
  searchParams.set(paramKey, paramValue);
  return searchParams.toString();
};

export const isLeaf = (item) => !Object.keys(item)?.includes('nodes');

const leafSegmentIterator = (segments, acc) =>
  segments.map((segment) =>
    isLeaf(segment)
      ? acc.push(segment)
      : leafSegmentIterator(segment.nodes, acc)
  );

export const getLeafSegments = (segments) => {
  const resultingArray = [];
  leafSegmentIterator(segments, resultingArray);
  return resultingArray;
};

export const getHiddenRowTitles = (listItems, keys) => {
  const rawIndexes = Object.keys(keys);
  const indexesToRemove = rawIndexes.map((item) =>
    Number(item.split(/(\ddrop)/)[2])
  );
  const attributionValueNames = listItems.map(
    (listItem) => listItem.primaryData.dataSource
  );

  return attributionValueNames.filter((item, index) =>
    indexesToRemove.includes(index)
  );
};

export const getShowingRowTitles = (listItems, keys) => {
  const rawIndexes = Object.keys(keys);
  const indexesToRemove = rawIndexes.map((item) =>
    Number(item.split(/(\ddrop)/)[2])
  );
  const attributionValueNames = listItems.map(
    (listItem) => listItem.primaryData.dataSource
  );

  return attributionValueNames.filter(
    (item, index) => !indexesToRemove.includes(index)
  );
};

export const getTableViewColumnWithChangedVisibility = ({ newList, oldList }) =>
  Object.entries(newList).find(
    ([key, isVisible]) => oldList[key] !== isVisible
  ) || [];
const segmentPathIterator = (segments, selectedId, acc) =>
  segments.some((segment) => {
    if (isLeaf(segment)) {
      return segment.segmentId === selectedId;
    }

    acc.push(`${nodePrefix}${segment.primaryText}`);

    const validPath = segmentPathIterator(segment.nodes, selectedId, acc);
    if (validPath) return true;

    acc.pop();
    return false;
  });

export const getSelectedIdNodes = (segments, selectedId) => {
  if (!segments || !selectedId) return [];

  const nodePathAcc = [];
  segmentPathIterator(segments, selectedId, nodePathAcc);
  return nodePathAcc;
};

const flattenerIterator = (listToFlatten, acc) =>
  listToFlatten.forEach((segment) => {
    if (isLeaf(segment)) return acc.push(segment);

    const copySegment = { ...segment };
    delete copySegment.nodes;

    acc.push(copySegment);
    return flattenerIterator(segment.nodes, acc);
  });

export const flatSegments = (segments = []) => {
  const resultFlatSegments = [];
  flattenerIterator(segments, resultFlatSegments);
  return resultFlatSegments;
};

export const showAllVisibility = (columns) =>
  columns.reduce((cols, col) => ({ ...cols, [col.field]: true }), {});
