import { createSelector } from '@reduxjs/toolkit';

export const selectProjectProductLineId = (state) =>
  state.projectViewer.projectItem?.metadata?.productLineId;

export const selectIsListButtonBusy = (state) =>
  state.projectViewer.isListButtonBusy;

export const selectProjectId = (state) =>
  state.projectViewer.projectItem.metadata.projectId;

export const selectProjectTableColumns = (state) =>
  state.projectViewer.tableColumns || [];

export const selectSegmentName = (state) =>
  state.projectViewer.selectedSegment?.segmentName;

export const selectMaptualListSelectionLabel = (state) =>
  state.projectViewer.selectedRegion?.label;

export const selectSegments = (state) =>
  state.projectViewer.maptualList?.segments;

export const selectSegmentNames = (state) =>
  state.projectViewer.maptualList?.segments?.map((segment) => segment.name);

export const selectEntityName = (state) =>
  state.projectViewer.entity?.metadata?.entityName;

export const selectMaptualListSegmentName = (state) =>
  state.projectViewer.maptualListSegment || 'All';

export const selectCustomListName = (state) =>
  state.projectViewer.customList || null;

export const selectMaptualListName = (state) =>
  state.projectViewer.maptualList?.metadata?.listName;

export const selectUserCreatedLists = (state) =>
  state.projectViewer.userCreatedLists;

export const selectUserCreatedListsCounts = (state) =>
  state.projectViewer.maptualList?.userCreatedListCounts || {};

export const selectMaptualListFilters = (state) =>
  state.projectViewer.maptualListFilterPreferences?.[
    state.projectViewer.projectItem.metadata.projectId
  ] || {};

export const selectMetricComponentData = createSelector(
  selectEntityName,
  selectMaptualListSegmentName,
  selectMaptualListName,
  (entityName, maptualListSegmentName, maptualListLabel) => ({
    entityName,
    maptualListSegmentName,
    maptualListLabel,
  })
);
