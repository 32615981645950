import React from 'react';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box } from '@mui/material';
import styled from '@emotion/styled';

const NoTerritoryOverviewText = styled(Box)(({ theme }) => ({
  padding: 56,
  color: theme.themeColors.emptyText,
  display: 'flex',
  flexDirection: 'column',
  gap: 13,
  background: 'rgba(47, 48, 53, 0.40)',
  marginTop: 24,
}));

const IconText = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 12,
  justifyContent: 'center',
  '.MuiSvgIcon-root': {
    color: theme.themeColors.emptyText,
    width: 20,
    height: 20,
  },
}));

const SubText = styled(Typography)(() => ({
  fontSize: 12,
}));

const disclaimerComponentMap = {
  INVALID_REGION_TITLE: (
    <Typography variant="h5"> Report not available </Typography>
  ),
  INVALID_REGION_BODY: (
    <>
      <SubText variant="body1">
        We currently only provide regional overview reports at the territory
        level.
      </SubText>
      <SubText variant="body1">
        Use the menu to navigate to a territory to view overview reports.
      </SubText>
    </>
  ),
  NO_CHILD_REGIONS_TITLE: (
    <Typography variant="h5"> Not enough data to show report </Typography>
  ),
  NO_CHILD_REGIONS_BODY: (
    <>
      <SubText variant="caption">
        We currently do not have enough data on this territory to show the
        overview report.
      </SubText>
      <SubText variant="caption">
        Use the menu to navigate to another territory to view overview reports.
      </SubText>
    </>
  ),
  NO_PRODUCT_DATA_TITLE: (
    <Typography variant="h5"> Graph and table not available </Typography>
  ),
  NO_PRODUCT_DATA_BODY: (
    <SubText variant="caption">
      We currently do not have enough data to generate a graph and table for
      this product.
    </SubText>
  ),
};

export const Disclaimer = ({ disclaimerText }) => {
  if (!disclaimerText) return null;

  return (
    <NoTerritoryOverviewText>
      <IconText>
        <InfoOutlinedIcon />
        {disclaimerComponentMap[`${disclaimerText}_TITLE`]}
      </IconText>
      <Box data-testid="disclaimer-text">
        {disclaimerComponentMap[`${disclaimerText}_BODY`]}
      </Box>
    </NoTerritoryOverviewText>
  );
};
