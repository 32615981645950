import { useQuery } from 'react-query';
import { maptualApiInstance } from '../../../../../../../request/config';

export const useRegions = ({ productLineId, territories, isHookEnabled }) => {
  const queryCacheKey = Object.values({
    productLineId,
  });
  return useQuery({
    queryKey: queryCacheKey,
    queryFn: async () => {
      try {
        const response = await getRegions(productLineId, territories);
        return response.data;
      } catch {
        return [];
      }
    },
    enabled: isHookEnabled,
  });
};

export function getRegions(productLineId, territories) {
  const territoriesString = territories
    .map((i) => `territories=${i}`)
    .join('&');
  return maptualApiInstance.get(
    `pulse/product-lines/${productLineId}/regions${
      territoriesString ? `?${territoriesString}` : ''
    }`,
    {
      timeout: 600000,
    }
  );
}
