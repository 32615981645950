import React from 'react';
import { useConfigViewContext } from '../configViewContext';
import { CONFIG_VIEW_MODE } from '../userGroupConfigConstants';
import { ProductTreeProvider } from './productTreeProvider';
import { ProcutTreeCreateContainer } from './productTreeCreate/productTreeCreateContainer';

export const ProductTreePage = () => {
  const { configViewMode } = useConfigViewContext();

  return (
    <>
      {configViewMode === CONFIG_VIEW_MODE.VIEW && <ProductTreeProvider />}
      {configViewMode === CONFIG_VIEW_MODE.CREATE && (
        <ProcutTreeCreateContainer />
      )}
    </>
  );
};
