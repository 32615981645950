import React from 'react';
import 'typeface-roboto';
import makeStyles from '@mui/styles/makeStyles';
import {
  CssBaseline,
  CircularProgress,
  Grow,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    'align-items': 'center',
    flex: 1,
    'flex-direction': 'column',
    'justify-content': 'center',
  },
  labelGroup: {
    'justify-content': 'flex-end',
    'flex-direction': 'column',
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    'text-align': 'left',
    padding: theme.spacing(1),
  },
  paddingBox: {},
  listContainer: {
    marginTop: '20px',
    minWidth: '450px',
    width: '50%',
    height: '400px',
    overflow: 'auto',
  },
  listStyle: {
    width: '100%',
    height: '400px',
  },
  listItemStyle: {
    height: '55px',
    paddingLeft: '35px',
  },
  listTitle: {
    padding: '15px',
  },
}));

export default function IngestionSelectExistingDataSet({
  ingestNav,
  ingest,
  myIndex,
  completeStep,
  onDatasetNameChange,
  onComplete,
}) {
  const classes = useStyles();

  const [selectedDataset, setSelectedDataset] = React.useState('');

  // eslint-disable-next-line no-param-reassign
  onComplete.action = () => {
    onDatasetNameChange(selectedDataset);
    completeStep(myIndex);
  };

  const handleListItemClick = (event, val) => {
    setSelectedDataset(val);
  };

  return (
    <div className={classes.container}>
      <CssBaseline />
      <Grow in>
        <div className={classes.labelGroup}>
          <div className={classes.paddingBox} />
          <Typography className={classes.title} variant="h2" component="h3">
            Select Existing Dataset
          </Typography>
          <Typography className={classes.subtitle} variant="h5">
            for which to add data
          </Typography>
        </div>
      </Grow>
      <div className={classes.listContainer}>
        <List
          className={classes.listStyle}
          dense
          disabled={!ingest.customerDatasets || ingest.isBusy}
        >
          {ingest.customerDatasets ? (
            Object.keys(ingest.customerDatasets).map((datasource, i) => (
              <ListItemButton
                key={i}
                className={classes.listItemStyle}
                dense
                disabled={ingestNav.completedSteps[myIndex] || ingest.isBusy}
                selected={selectedDataset === datasource}
                onClick={(event) => handleListItemClick(event, datasource)}
              >
                <ListItemText className={classes.listTitle}>
                  {datasource}
                </ListItemText>
              </ListItemButton>
            ))
          ) : (
            <CircularProgress />
          )}
        </List>
      </div>
    </div>
  );
}
