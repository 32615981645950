import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import Scrollbar from 'react-scrollbars-custom';
import React from 'react';
import { TestDetailsList } from './testDetailsList';

const TestDetailsContainer = styled(Box)(({ theme: { themeColors } }) => ({
  width: 500,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: themeColors.contentCardAltBackgroundColor,
}));

const TestDetailsScrollbar = styled(Scrollbar)(() => ({
  flexGrow: 2,
  width: '100%',
}));

const TestDetailsHeader = styled(Box)(({ theme: { themeColors } }) => ({
  padding: '15px 30px',
  paddingTop: 30,
  display: 'flex',
  flexDirection: 'row',
  borderBottom: `1px solid ${themeColors.contentCardBorderColor}`,
}));

const TestDetailsHeaderWrapper = styled(Box)(() => ({
  flexGrow: 1,
}));

const TestDetailsTitle = styled(Typography)(() => ({
  marginBottom: 4,
}));

const TestDetailsLoggerHeader = () => (
  <TestDetailsHeader>
    <TestDetailsHeaderWrapper>
      <TestDetailsTitle variant="h3">Data Integrity Tests</TestDetailsTitle>
    </TestDetailsHeaderWrapper>
  </TestDetailsHeader>
);

export const TestDetailLogger = () => (
  <TestDetailsContainer data-testid="events-enabled">
    <TestDetailsLoggerHeader />
    <TestDetailsScrollbar disableTracksWidthCompensation noScrollX>
      <TestDetailsList />
    </TestDetailsScrollbar>
  </TestDetailsContainer>
);
