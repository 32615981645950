import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ themeColors }) => ({
  chartContainerTitle: {
    marginBottom: 10,
    color: themeColors.primaryMaptualListFilteringColor,
  },
}));

export default function ShareChartHeader() {
  const classes = useStyles();
  return (
    <Typography
      variant="h3"
      align="left"
      className={classes.chartContainerTitle}
    >
      Market Share
    </Typography>
  );
}
