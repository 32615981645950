/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { Tab, Tabs } from '@mui/material';
import styled from '@emotion/styled';
import * as ViewerAction from '../../../../actions/projectViewActions';
import Header from './header';
import { Administration } from './administration/Administration';
import { TABS_TO_SHOW } from './constants';
import { Reports } from './reports/reports';

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '100%',
    backgroundColor: themeColors.mainBackground,
  },
}));

const CustomTabs = styled(Tabs)(({ theme: { themeColors } }) => ({
  marginLeft: 32,
  borderRadius: 0,
  minHeight: 52,
  '& .MuiTabs-indicator': {
    backgroundColor: themeColors.primaryColorDark,
    '&:disabled': {
      display: 'none',
      backgroundColor: themeColors.mainSurfaceBackground,
    },
  },
  borderBottom: '1px solid',
  borderColor: themeColors.mainSurfaceBackground,
}));

const CustomTab = styled(Tab)(({ theme: { themeColors } }) => ({
  marginRight: 15,
  height: 45,
  minHeight: 45,
  borderRadius: 8,
  color: themeColors.secondaryTextColor,
  '&.Mui-selected': {
    color: themeColors.primaryTextColor,
  },
  '&:disabled': {
    color: themeColors.secondaryTextColor,
    opacity: 0.3,
  },
}));

function CurationReports({ getRegions }) {
  const classes = useStyles();

  const [selectedTab, setselectedTab] = useState(TABS_TO_SHOW[0]);
  const [projectId, setProjectId] = useState('');
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);

  useEffect(() => {
    async function retrieveProjectRegions() {
      const regionsTree = await getRegions(projectId);
      const flattenedRegionsList = [];
      flattenRegions(regionsTree.value, flattenedRegionsList);
      setRegions(flattenedRegionsList);
    }
    if (projectId) {
      setRegions([]);
      retrieveProjectRegions();
    }
  }, [projectId]);

  return (
    <div className={classes.root}>
      <Header
        viewer
        onParamSelect={({ projectId: selectedProjectId }) => {
          setProjectId(selectedProjectId);
        }}
      />
      <CustomTabs
        data-testid="curation-tab-switcher"
        value={selectedTab.title}
        TabIndicatorProps={{
          sx: {
            display: !projectId ? 'none' : 'unset',
          },
        }}
      >
        {TABS_TO_SHOW.map((tab) => (
          <CustomTab
            disabled={!projectId}
            key={tab.title}
            label={tab.title}
            value={tab.title}
            onClick={() => {
              setselectedTab(tab);
            }}
          />
        ))}
      </CustomTabs>
      {projectId && selectedTab.title === 'Administration' && (
        <Administration projectId={projectId} />
      )}
      {projectId && selectedTab.title === 'Reports' && (
        <Reports
          projectId={projectId}
          regions={regions}
          selectedRegion={selectedRegion}
          setSelectedRegion={setSelectedRegion}
        />
      )}
    </div>
  );
}

const flattenRegions = (regionsTree, output) => {
  output.push({
    name: regionsTree.metadata.listName,
    locality: regionsTree.metadata.locality,
    id: regionsTree.metadata.maptualListId,
  });
  for (const node of regionsTree.nodes || []) {
    flattenRegions(node, output);
  }
};
// export default CurationReports;
const mapStateToProps = (state) => ({
  viewer: state.projectViewer,
});
const mapDispatchToProps = (dispatch) => ({
  getRegions: (projectId) => dispatch(ViewerAction.getMaptualList(projectId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CurationReports);
