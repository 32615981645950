export const isCancelled = (action) =>
  action?.payload?.Error?.message === 'canceled';

export const debouncedRequest = (requestFunction) => {
  let abortController;

  return (args) => {
    if (abortController) abortController.abort();

    abortController = new AbortController();

    return requestFunction({ ...args, signal: abortController.signal });
  };
};
