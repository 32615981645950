import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { CategoricalDataCard } from './categoricalDataCard';

const Wrapper = styled(Box)(({ theme: { themeColors } }) => ({
  marginBottom: 16,
  color: themeColors.primaryMaptualListFilteringColor,
}));

const HorizontalWrapper = styled(Wrapper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gridGap: 15,
}));

const VerticalWrapper = styled(Wrapper)(() => ({
  display: 'grid',
  gridGap: 15,
  gridTemplateColumns: '1fr 1fr',
}));

export const CategoricalDataWrapper = ({ data = {}, objectiveId }) => {
  const [categoricalData, setCategoricalData] = useState([]);

  useEffect(() => {
    if (!objectiveId) {
      setCategoricalData(Object.values(data)[0] || []);
    } else {
      setCategoricalData(data[objectiveId] || []);
    }
  }, [objectiveId]);

  if (!categoricalData.length) {
    return null;
  }

  if (categoricalData.length > 1) {
    return (
      <VerticalWrapper data-testid="vertical-wrapper">
        {categoricalData.map((card) => (
          <CategoricalDataCard
            data={card}
            key={card.title}
            orientation="vertical"
          />
        ))}
      </VerticalWrapper>
    );
  }

  return (
    <HorizontalWrapper data-testid="horizontal-wrapper">
      {categoricalData.map((card) => (
        <CategoricalDataCard data={card} key={card.title} />
      ))}
    </HorizontalWrapper>
  );
};
