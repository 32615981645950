export const isCustomerTerritory = (territory) =>
  territory?.locality === 'TERRITORY' ||
  territory?.locality?.includes('DISTRICT');

export const isCanadianProvince = (territory) =>
  territory?.locality === 'PROVINCE';
const isLeaf = (node) => node.nodes.length === 0;
const addZeroAsLeftDigit = (number) => number.toString().padStart(2, '0');

const americanizeProvinceList = (provinceNode, currentIndex) => ({
  ...provinceNode,
  metadata: {
    ...provinceNode.metadata,
    listName: isLeaf(provinceNode) ? `State ${currentIndex}` : 'States',
  },
  nodes: replaceListNames(provinceNode.nodes, currentIndex),
});

const anonymizeCustomerSpecificTerritory = (
  customerTerritoryNode,
  currentIndex,
  prefix
) => ({
  ...customerTerritoryNode,
  metadata: {
    ...customerTerritoryNode.metadata,
    listName: `Territory ${prefix && `${prefix}.`}${currentIndex}`,
  },
  nodes: replaceListNames(
    customerTerritoryNode.nodes,
    prefix ? `${prefix}.${currentIndex}` : currentIndex
  ),
});

export const replaceListNames = (nodes = [], prefix = '') =>
  nodes.map((node, nodeIndex) => {
    const currentIndex = addZeroAsLeftDigit(nodeIndex + 1);

    if (isCanadianProvince(node.metadata)) {
      return americanizeProvinceList(node, currentIndex);
    }

    if (isCustomerTerritory(node.metadata)) {
      return anonymizeCustomerSpecificTerritory(node, currentIndex, prefix);
    }

    return {
      ...node,
      nodes: replaceListNames(
        node.nodes,
        prefix ? `${prefix}.${currentIndex}` : currentIndex
      ),
    };
  });
