import React from 'react';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  ModalDisplay,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '../../../../../../components/generic/modalDisplay';

const StyledButton = styled(Button)(() => ({
  color: '#D6E2F5',
  height: 32,
  borderRadius: 4,
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
}));

export const ExecutionConfirmationModal = ({
  isModalOpen,
  onClose,
  onConfirm,
  handleUpperClose,
}) => {
  const handleClose = () => {
    onClose();
    handleUpperClose();
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <ModalDisplay
      isOpen={isModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalHeader onClose={handleClose}>
        <Typography variant="h3">Start Curation Run?</Typography>
      </ModalHeader>
      <ModalBody>
        <Typography variant="h3">WARNING</Typography>
        <Typography variant="body1">
          You are about to kick off a LIVE curation run, clicking confirm will
          update customer facing curated lists and begin CSV generation.
        </Typography>
        <Typography variant="body1">
          Please contact a member of the Maverick team or QA team to ensure
          starting an execution is acceptable.
        </Typography>
      </ModalBody>
      <ModalFooter>
        <>
          <StyledButton
            style={{ border: '1px solid #313B49', backgroundColor: '#1F232C' }}
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </StyledButton>
          <StyledButton
            style={{ backgroundColor: '#0D47A0' }}
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleConfirm}
          >
            Confirm
          </StyledButton>
        </>
      </ModalFooter>
    </ModalDisplay>
  );
};
