import { useQuery } from 'react-query';

import { getFeatureTogglesv2 } from '../request/featureToggleRequests';

export function usePreloadFeatureToggles() {
  return useQuery('feature-toggles-v2-query-cache', async () => {
    try {
      const response = await getFeatureTogglesv2();
      const data = JSON.parse(response.data);
      return data;
    } catch {
      return [];
    }
  });
}

export default function useFeatureToggles() {
  const res = useQuery('feature-toggles-v2-query-cache', async () => {
    try {
      const response = await getFeatureTogglesv2();
      const data = JSON.parse(response.data);
      return data;
    } catch {
      return [];
    }
  });

  return function isToggleOn(userGroup, featureName) {
    if (!res.data || !userGroup) {
      // TODO: handle this better
      return false;
    }

    const toggleArr = res.data.toggles.filter((t) => t.name === featureName);
    if (!toggleArr.length) {
      // eslint-disable-next-line no-console
      console.warn(
        `TOGGLE '${featureName}' DOES NOT EXIST - DELETE THE OFFENDING CODE OR CREATE THE TOGGLE`
      );
      return false;
    }
    const toggle = toggleArr[0];
    const clientGroup = userGroup.replace(/[0-9]/g, '').replace('demo', '');

    return toggle.enabledFor.includes(clientGroup);
  };
}
