import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import Snackbar from '@mui/material/Snackbar';
import { ConfigViewContext } from './configViewContext';
import { CONFIG_ALERT_STATUSES } from './configAlertStatuses';

const ALERT_TIMER_IN_SECONDS = 10;

const StyledSnackbar = styled(Snackbar)(
  ({ theme: { themeColors }, status }) => ({
    position: 'absolute',
    transform: 'translateX(-50%)',
    '@media (min-width: 600px)': {
      bottom: 16,
      left: '50%',
    },
    '.MuiSnackbarContent-root': {
      backgroundColor:
        status === CONFIG_ALERT_STATUSES.SUCCESS
          ? themeColors.primary40
          : themeColors.danger40,
      minWidth: 'auto',
    },
    '.MuiSnackbarContent-action': {
      marginRight: 0,
      paddingLeft: 8,
    },
  })
);

const StyledCloseIcon = styled(CloseIcon)({
  width: 20,
  height: 20,
  cursor: 'pointer',
});

export const ConfigAlert = () => {
  const {
    configAlertStatus: status,
    setConfigAlertStatus: setStatus,
    configAlertMessage: message,
    resetConfigAlertTimer: resetTimer,
    setResetConfigAlertTimer: setResetTimer,
  } = useContext(ConfigViewContext);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (!resetTimer) return null;

    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      setStatus(null);
    }, ALERT_TIMER_IN_SECONDS * 1000);
    setTimer(newTimer);
    setResetTimer(false);

    return () => {
      clearTimeout(timer);
    };
  }, [resetTimer]);

  const defaultMessage = () =>
    status === CONFIG_ALERT_STATUSES.SUCCESS
      ? 'Saved changes successfully'
      : 'Cannot save changes. Please try again';

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') return;
    setStatus(null);
  };

  if (!status) return null;

  return (
    <StyledSnackbar
      open={status}
      TransitionComponent={Fade}
      key={Fade.name}
      message={message || defaultMessage()}
      action={<StyledCloseIcon onClick={handleCloseAlert} />}
      status={status}
    />
  );
};
