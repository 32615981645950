import { useEffect, useState } from 'react';

const initialValue = {};
export const useLocalStorageValue = (localStorageKey) => {
  const [value, setValue] = useState(
    () => JSON.parse(localStorage.getItem(localStorageKey)) ?? initialValue
  );

  const setLocalStorageValue = (newValue) => {
    const stringNewValue = JSON.stringify(newValue);
    const stringOldValue = localStorage.getItem(localStorageKey);

    if (stringNewValue !== stringOldValue) {
      localStorage.setItem(localStorageKey, stringNewValue);
      window.dispatchEvent(
        new StorageEvent('storage', {
          key: localStorageKey,
          newValue: stringNewValue,
          oldValue: stringOldValue,
        })
      );
    }
  };

  const removeLocalStorageValue = () => {
    const oldValue = localStorage.getItem(localStorageKey);
    localStorage.removeItem(localStorageKey);

    window.dispatchEvent(
      new StorageEvent('storage', {
        key: localStorageKey,
        newValue: null,
        oldValue,
      })
    );
  };

  const checkStorageChange = (ev) => {
    if (ev?.key && ev.key !== localStorageKey) {
      return;
    }

    const { newValue } = ev;
    const newValueObj = JSON.parse(newValue);

    setValue(newValueObj);
  };

  useEffect(() => {
    checkStorageChange({
      key: localStorageKey,
      newValue: localStorage.getItem(localStorageKey),
    });

    window.addEventListener('storage', checkStorageChange);

    return () => window.removeEventListener('storage', checkStorageChange);
  }, [localStorageKey]);

  return [value, setLocalStorageValue, removeLocalStorageValue];
};
