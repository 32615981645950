import { baseAlbInstance, configProxyApiInstance } from './config';

const templatesRootPath = 'customer-project-templates';

export function getObjectiveTypes() {
  return baseAlbInstance.get('objectiveTypes', { timeout: 30000 });
}

export function getProductLines(productLineId = null) {
  return baseAlbInstance.get(
    `productLine${productLineId ? `/${productLineId}` : ''}`,
    { timeout: 30000 }
  );
}

export function getMarkets() {
  return baseAlbInstance.get('markets', { timeout: 30000 });
}

export function getAvailableMetrics({
  productLine,
  productIds,
  indications,
  therapeuticAreas,
  signal = null,
  filterCriteria = 'volume',
}) {
  return baseAlbInstance.post(
    'metricAvailability',
    {
      metricConfig: {
        productLine,
        productIds,
        indications,
        therapeuticAreas,
        filterCriteria,
      },
      timeout: 1800000,
    },
    {
      signal,
    }
  );
}

export function postNewProject(requestBody) {
  return baseAlbInstance.post('projects', requestBody, {
    timeout: 30000,
  });
}

export const postNewProjectTemplate = (requestBody) =>
  configProxyApiInstance.post(`/${templatesRootPath}`, requestBody);

export const getCustomerProjectTemplates = () =>
  configProxyApiInstance.get(`/${templatesRootPath}`);

export const updateProjectTemplate = ({ id, requestBody }) =>
  configProxyApiInstance.put(`/${templatesRootPath}/${id}`, requestBody);

export const deleteProjectTemplate = (id) =>
  configProxyApiInstance.delete(`/${templatesRootPath}/${id}`);
