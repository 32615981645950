import { useQuery } from 'react-query';

import {
  getEntityOverviewData,
  getEntityOverviewMetadata,
} from '../../../request/entityOverviewRequests';

export const useEntityOverviewMetadata = ({
  marketId,
  productLineId,
  projectId,
  regionId,
  entityType,
}) => {
  const fetchEntityOverviewMetadata = async () => {
    if (marketId && productLineId && projectId && regionId && entityType) {
      const params = {
        marketId,
        productLineId,
        projectId,
        regionId,
        entityType,
      };
      const response = await getEntityOverviewMetadata(params);
      return response?.data;
    }

    return null;
  };

  const { isFetching, data, isError, error } = useQuery({
    queryKey: [
      'entity-overview-metadata',
      productLineId,
      projectId,
      regionId,
      entityType,
    ],
    queryFn: fetchEntityOverviewMetadata,
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error('[ERROR] fetchEntityOverviewMetadata', err);
    },
  });

  return {
    isFetching,
    data,
    isError,
    error,
  };
};

export const useEntityOverviewData = ({
  productLineId,
  projectId,
  regionId,
  entityType,
  objectiveId,
  cadence,
  entitySubtype,
  filters,
}) => {
  const fetchEntityOverviewData = async () => {
    if (
      productLineId &&
      projectId &&
      regionId &&
      entityType &&
      objectiveId &&
      cadence &&
      filters
    ) {
      const params = {
        productLineId,
        projectId,
        regionId,
        entityType,
        objectiveId,
        cadence,
        entitySubtype,
        filters,
      };
      const response = await getEntityOverviewData(params);
      return response?.data;
    }

    return null;
  };

  const { isFetching, data, isError, error } = useQuery({
    queryKey: [
      'entity-overview-data',
      productLineId,
      entityType,
      projectId,
      regionId,
      objectiveId,
      cadence,
      entitySubtype,
      filters,
    ],
    queryFn: fetchEntityOverviewData,
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error('[ERROR] fetchEntityOverviewData', err);
    },
  });

  return {
    isFetching,
    data,
    isError,
    error,
  };
};
