import TuneIcon from '@mui/icons-material/Tune';
import React, { useState, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { Badge, Button } from '@mui/material';
import Typography from '@mui/material/Typography';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { EntityOverviewContext } from '../../data/entityOverviewContext';
import {
  ModalDisplay,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '../../../../components/generic/modalDisplay';
import { formatEntityName } from '../../../../utils/formatEntityName';
import {
  countMultiselectFilters,
  createMultiselectFilters,
} from '../../../../components/fieldView/multiSelectFiltersObject';

const OpenModalButton = styled(Button)(({ theme: { themeColors } }) => ({
  width: 48,
  display: 'flex',
  minWidth: 'fit-content',
  padding: '10px 10px 10px 20px',
  '& .MuiSvgIcon-root': {
    color: themeColors.buttonContentColor,
  },
}));

const StyledBadge = styled(Badge)(({ theme: { themeColors } }) => ({
  minWidth: 56,
  marginRight: 0,
  height: '100%',
  '& .MuiBadge-badge': {
    backgroundColor: themeColors.badgeHighlightedContentColor,
    color: themeColors.neutral10,
  },
}));

const StyledModalBody = styled(ModalBody)(() => ({
  padding: 24,
}));

const SectionTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
  textTransform: 'capitalize',
}));

const FilterSection = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
}));

const FilterDescription = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tertiaryColor,
}));

const CheckboxContainer = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridGap: 9,
  alignItems: 'flex-start',

  '& .MuiFormControlLabel-label': {
    paddingTop: 1.5,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme: { themeColors } }) => ({
    marginLeft: 0,
    color: themeColors.primaryTextColor,
    '.MuiCheckbox-root': {
      padding: 0,
      marginRight: 9,
      alignSelf: 'start',
    },
  })
);

const Spacing = styled('div')(() => ({
  padding: 8,
}));

export const FilterSelector = () => {
  const { metadata, filters, setFilters } = useContext(EntityOverviewContext);

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(filters);

  useEffect(() => {
    if (filters) {
      setSelectedFilters(filters);
    }
  }, [openFilterModal, filters]);

  const handleClickOpenFilterModal = () => {
    setOpenFilterModal(true);
  };
  const handleCloseFilterModal = () => {
    setOpenFilterModal(false);
  };

  const handleApplyFilters = () => {
    setFilters(selectedFilters);
    handleCloseFilterModal();
  };

  return (
    <>
      <StyledBadge badgeContent={countMultiselectFilters(filters)}>
        <OpenModalButton
          data-testid="test-open-filter-button"
          aria-label="openFilterButton"
          variant="outlined"
          onClick={handleClickOpenFilterModal}
          startIcon={<TuneIcon />}
        />
      </StyledBadge>

      <ModalDisplay onClose={handleCloseFilterModal} isOpen={openFilterModal}>
        <ModalHeader onClose={handleCloseFilterModal}>
          Filter Accounts
        </ModalHeader>

        <StyledModalBody>
          {metadata?.parentEntityType && metadata?.parentFilterRegions && (
            <FilterSection>
              <SectionTitle variant="h5">
                {metadata?.parentEntityType}
              </SectionTitle>
              <FilterDescription variant="body1">
                Filter territory by {metadata?.parentEntityType}
              </FilterDescription>
              <Spacing />
              <CheckboxContainer>
                {metadata?.parentFilterRegions?.map((region) => (
                  <StyledFormControlLabel
                    key={region.id}
                    control={
                      <Checkbox
                        name={region.id}
                        onChange={() =>
                          setSelectedFilters(
                            createMultiselectFilters(
                              region.id,
                              formatEntityName(region.label),
                              'regions',
                              selectedFilters
                            )
                          )
                        }
                        checked={Object.keys(
                          selectedFilters?.regions || []
                        ).includes(region.id)}
                      />
                    }
                    label={formatEntityName(region.label)}
                  />
                ))}
              </CheckboxContainer>
            </FilterSection>
          )}
        </StyledModalBody>

        <ModalFooter>
          <Button variant="utility" onClick={handleCloseFilterModal}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleApplyFilters}>
            Apply Filters
          </Button>
        </ModalFooter>
      </ModalDisplay>
    </>
  );
};
