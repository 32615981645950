import React from 'react';
import { Button, styled } from '@mui/material';

const StyledButton = styled(Button)(({ theme: { themeColors } }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: themeColors.utilityContentColor,
}));

export default function ClearCacheButton({
  disabled = false,
  onClick = () => null,
}) {
  return (
    <StyledButton
      variant="utiltiy"
      aria-label="new"
      disabled={disabled}
      onClick={onClick}
    >
      Clear Cache
    </StyledButton>
  );
}
