import '../../styles/LoginPanel.css';
import React from 'react';
import Button from '@mui/material/Button';
import { blue } from '@mui/material/colors';
import styled from '@emotion/styled';
import { ReactComponent as MaptualLogo } from '../../assets/maptual_logo_dark.svg';
import { trackSignInOrOut } from '../../trackers/appEventTracker';
import { themeColors } from '../../styles/fieldViewStyles';

const CenteredDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #15161a;
`;

const LoginContainer = styled.div`
  width: 320px;
`;

const LoginMessage = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;
  font-size: 16px;
  color: #ffffff;
`;

const SignInButton = styled(Button)`
  background-color: ${blue.A700};
  color: ${themeColors.primaryTextColor};
  text-transform: capitalize;
  width: 70%;
`;
export default function LoginPanel() {
  const location = new URLSearchParams(window.location.search);
  const redirectUrl = location.get('redirectUrl');

  return (
    <CenteredDiv>
      <LoginContainer>
        <MaptualLogo style={{ width: '85%', height: 'auto' }} />
        <LoginMessage>
          Guiding humans to improved health journeys through our collective
          experiences
        </LoginMessage>
        <SignInButton
          size="large"
          variant="contained"
          href={redirectUrl ? `/login?redirectUrl=${redirectUrl}` : `/login`}
          onClick={() => {
            trackSignInOrOut('sign in');
          }}
        >
          Sign In
        </SignInButton>
      </LoginContainer>
    </CenteredDiv>
  );
}
