/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { AppContext } from '../../appContext';
import { ROUTES } from '../../../../constants';
import EmptyView from '../../../../components/emptyview/emptyView';
import { PulseContext } from './context/PulseContext';
import { usePulseContext } from './context/usePulseContext';

export const PulseWrapper = ({ children }) => {
  const { profile, isProjectListLoading, projectList } = useContext(AppContext);
  const { projectId, regionId } = useParams();
  const history = useHistory();
  const pulseContext = usePulseContext();

  useEffect(() => {
    if (!projectList?.length) return;
    const selectedProjectId = getSelectedProjectId();
    const projectFromList = projectList.find(
      (proj) => proj.projectId === selectedProjectId
    );
    const project = projectFromList || projectList[0];
    const missingProjectOrRegion = !projectId || !regionId;
    const changedProject = projectId !== project.projectId;

    if (missingProjectOrRegion) {
      const route = `${ROUTES.PULSE}${ROUTES.PROJECTS}/${project.projectId}${ROUTES.REGIONS}/${project.regionalTeamId}${ROUTES.SALES_REPS}`;
      history.push(route);
    } else if (changedProject) {
      // eslint-disable-next-line no-restricted-globals
      const view = location.pathname.split('/').slice(-1).join();
      const route = `${ROUTES.PULSE}${ROUTES.PROJECTS}/${project.projectId}${ROUTES.REGIONS}/${project.regionalTeamId}/${view}`;
      history.push(route);
    }
  }, [projectList, projectId, regionId]);

  const getSelectedProjectId = () => {
    if (projectId) return projectId;

    if (profile?.preference?.lastProjectMetadata?.projectId)
      return profile?.preference?.lastProjectMetadata?.projectId;

    if (projectList?.length && projectList[0].projectId)
      return projectList[0].projectId;

    return null;
  };

  const missingProjectOrRegion = !projectId || !regionId;
  const changedProject = projectId !== getSelectedProjectId();

  if (isProjectListLoading || missingProjectOrRegion || changedProject) {
    return <EmptyView isLoading loadingTitle="Loading Content ..." />;
  }

  return (
    <PulseContext.Provider value={pulseContext}>
      {children}
    </PulseContext.Provider>
  );
};
