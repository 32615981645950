import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import styled from '@emotion/styled';
import { TextField, MenuItem, Box } from '@mui/material';
import FrequencyChartProvider from '../frequencyChart/frequencyChartProvider';
import CurationChartProvider from './charts/curationChartProvider';
import { CURATION_CHARTS } from '../constants';

const useStyles = makeStyles(() => ({
  graphContent: {
    '&:last-child': {
      borderBottom: 'None',
    },
    borderBottom: '1px solid #313B49',
  },
}));

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: '32px',
  padding: '56px 64px',
}));

const ChartContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '32px',
  width: '80%',
  maxWidth: 850,
  alignSelf: 'center',
}));

const StyledTextField = styled(TextField)(({ theme: { themeColors } }) => ({
  borderRadius: 4,
  margin: 0,
  width: '220px',
  fontSize: 14,
  backgroundColor: themeColors.mainBackground,
  '& .MuiInputLabel-root': {
    color: '#FBFCFE',
  },
  '& .MuiOutlinedInput-root fieldset': {
    borderColor: '#8595AD',
  },
}));

export const Reports = ({
  projectId,
  regions,
  selectedRegion,
  setSelectedRegion,
}) => {
  const classes = useStyles();
  return (
    <Container>
      <StyledTextField
        select
        disabled={!projectId || !regions.length}
        id="curation-region-selection-label"
        className={classes.textField}
        value={selectedRegion?.name}
        label="Select Region"
        disableUnderline
        size="small"
        sx={{
          '& .MuiInputLabel-root.Mui-disabled': {
            color: '#8595AD',
          },
        }}
        onChange={(e) => {
          setSelectedRegion(e.target.value);
        }}
      >
        {regions.length
          ? regions.map((item) => (
              <MenuItem key={item.id} value={item}>
                {item.name || ''}
              </MenuItem>
            ))
          : null}
      </StyledTextField>
      <ChartContainer>
        {selectedRegion && (
          <FrequencyChartProvider
            projectId={projectId}
            regionId={selectedRegion.id}
          />
        )}
        {selectedRegion &&
          CURATION_CHARTS.map((i) => (
            <CurationChartProvider
              key={i.title}
              childGraph={i}
              projectId={projectId}
              regionId={selectedRegion.id}
              className={classes.graphContent}
            />
          ))}
      </ChartContainer>
    </Container>
  );
};
