import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import {
  getCurrentUser as getCurrentUserRequest,
  putUserPreference,
} from '../request/requests';
import { trackUser } from '../trackers/appEventTracker';
import DEFAULT_USER_PROFILE from '../models/defaultUserProfile.json';
import DEFAULT_TABLE_PREFERENCES from '../models/defaultTablePreferenceShape.json';
import syncTablePreferences from '../utils/syncTablePreferences';
import {
  setDemoAccountStatus,
  setInternalAccountStatus,
} from '../request/config';

const errorProfile = Object.freeze({
  profileId: 'unknown',
  name: 'Unknown',
  title: 'Error loading profile',
  role: {
    type: 'UNKNOWN',
    subType: 'UNKNOWN',
    permission: 'UNKNOWN',
  },
  imageUri: null,
});

const loadingProfile = Object.freeze({
  profileId: 'Loading',
  name: 'Loading',
  title: '',
  role: {
    type: 'UNKNOWN',
    subType: 'UNKNOWN',
    permission: 'UNKNOWN',
  },
  imageUri: null,
});

export const getCurrentUser = createAsyncThunk(
  'profile/getCurrentUser',
  async () => {
    const response = await getCurrentUserRequest();
    trackUser(response.data.userId);
    return response.data;
  }
);

export const setCurrentUserProfile = createAsyncThunk(
  'profile/setCurrentUser',
  async (preferences) => {
    const response = await putUserPreference(preferences);
    return response;
  }
);

export const profileInitialState = {
  profile: {},
  profileLoaded: false,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState: profileInitialState,
  extraReducers: (builder) => {
    builder
      .addCase('PROJECT_GET_TABLE_RESPONSE_FULFILLED', (state, { payload }) => {
        const { projectId, newProjectTablePreferences } = syncTablePreferences(
          state,
          payload
        );

        if (state.profile.preference?.columnPreferences) {
          state.profile.preference.columnPreferences[projectId] = {
            ...DEFAULT_TABLE_PREFERENCES,
            ...newProjectTablePreferences,
          };
        }
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.profileLoaded = profileInitialState.profileLoaded;
        state.profile = loadingProfile;
      })
      .addCase(getCurrentUser.fulfilled, (state, { payload }) => {
        state.profileLoaded = true;
        state.profile = merge(DEFAULT_USER_PROFILE, payload);
        if (state.profile.role?.specialType === 'TEST') {
          setInternalAccountStatus(true);
        } else if (state.profile.role?.specialType === 'DEMO') {
          setDemoAccountStatus(true);
        }
      })
      .addCase(setCurrentUserProfile.fulfilled, (state, { payload }) => {
        state.profileLoaded = true;
        state.profile = {
          ...state.profile,
          preference: payload,
        };
      })
      .addCase(getCurrentUser.rejected, (state) => {
        state.profileLoaded = profileInitialState.profileLoaded;
        state.profile = errorProfile;
      });
  },
});

export const updateTableColumnPreferences = createAction(
  'PROFILE_TABLE_COL_PREF_CHANGE'
);

export default profileSlice.reducer;
