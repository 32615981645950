import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import styled from '@emotion/styled';
import { useQuery } from 'react-query';
import { ProjectOverview } from '../projectviewer/projectInformation/projectOverview';
import TestDetailTableView from './TestDetailTableView';
import { Wrapper } from '../projectviewer/projectInformation/generic';
import EmptyView from '../emptyview/emptyView';
import { getProjectTestSuiteDetailedStatuses } from '../../request/projectRequests';

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const TableContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 16px;
`;

const CustomTabs = styled(Tabs)(({ theme: { themeColors } }) => ({
  marginBottom: 2,
  borderRadius: 0,
  minHeight: 52,
  '& .MuiTabs-indicator': {
    marginTop: 3,
    backgroundColor: themeColors.primaryColorDark,
  },
  borderBottom: '1px solid',
  borderColor: themeColors.mainSurfaceBackground,
}));

const CustomTab = styled(Tab)(({ theme: { themeColors } }) => ({
  marginRight: 15,
  height: 45,
  borderRadius: 8,
  color: themeColors.secondaryTextColor,
  '&.Mui-selected': {
    color: themeColors.primaryTextColor,
  },
}));

const getProjectTestSuiteStatuses = async (projectId) => {
  const detailedStatuses = await getProjectTestSuiteDetailedStatuses(projectId);
  return detailedStatuses;
};

const DEFAULT_TABS = [
  {
    title: 'Metadata',
    path: 'metadata',
    component: ProjectOverview,
  },
];
export default function TestDetailTabContainer() {
  const [tabView, setTabView] = useState('metadata');

  const path = window.location.pathname.split('/');
  const projectId = path[3];

  const {
    isLoading: isProjectTestSuiteStatusLoading,
    data: projectTestSuiteStatusResponse,
  } = useQuery([`project-quality-checks-${projectId}`], () =>
    getProjectTestSuiteStatuses(projectId)
  );

  if (isProjectTestSuiteStatusLoading) {
    return (
      <Wrapper data-testid="projectOverviewLoading">
        <EmptyView isLoading />
      </Wrapper>
    );
  }

  const testSuiteTabs = projectTestSuiteStatusResponse?.data?.map((result) => ({
    title: result.name,
    path: result.name.replace(' ', '-'),
    component: () =>
      TestDetailTableView({
        passed: result.passedTestCases,
        failed: result.failedTestCases,
      }),
  }));

  const tabs = [...DEFAULT_TABS, ...testSuiteTabs];

  return (
    <ColumnFlex>
      <CustomTabs value={tabView}>
        {tabs?.map((tab) => (
          <CustomTab
            key={tab.title}
            label={tab.title}
            value={tab.path}
            onClick={() => {
              setTabView(tab.path);
            }}
          />
        ))}
      </CustomTabs>
      <RowFlex>
        {tabs?.map(
          (tab, i) =>
            tab.path === tabView && (
              <TableContainer
                style={{ overflowX: 'initial' }}
                key={`${tab.path}-${i}`}
              >
                <tab.component />
              </TableContainer>
            )
        )}
      </RowFlex>
    </ColumnFlex>
  );
}
