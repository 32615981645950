import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTheme } from '@mui/styles';
import styled from '@emotion/styled';
import { FormControl, Select, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getProjectObjectives } from '../../../../../../../../../request/projectRequests';

const StyledFormControl = styled(FormControl)(({ theme: { themeColors } }) => ({
  minWidth: '150px',
  maxWidth: '40%',
  textAlign: 'left',
  '.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
    color: themeColors.primaryMaptualListFilteringColor,
  },
}));

const StyledSelect = styled(Select)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.inputBackground,
  border: `1px solid ${themeColors.buttonBorderColor}`,
  borderRadius: '4px',
  boxShadow: '0px 1px 4px 0px var(--shadow-level-1, rgba(44, 50, 63, 0.20))',
  '.MuiInputBase-input': {
    padding: '8px 14px',
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme: { themeColors } }) => ({
  '&.Mui-selected': {
    backgroundColor: `${themeColors.buttonBorderColor} !important`,
  },
}));

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.menuTextColor,
    marginLeft: '4px',
    width: '20px',
    height: '20px',
  })
);

export const ObjectiveSelector = ({
  projectId,
  objectiveId,
  updateFilterObjective,
}) => {
  const { themeColors } = useTheme();
  const [objective, setObjective] = useState(objectiveId);
  const { data: objectives, isLoading: isObjectivesLoading } = useQuery(
    projectId,
    async () => {
      try {
        const response = await getProjectObjectives({ projectId });
        return response.data;
      } catch {
        return [];
      }
    }
  );

  if (objectiveId && !objective && objectives && objectives.length > 0) {
    const newObjective = objectives.find((i) => i.id === objectiveId);
    setObjective(newObjective);
  }

  useEffect(() => {
    if (objectives && objectives.length > 0) {
      if (objectiveId) {
        handleObjectiveChange(objectiveId);
      } else {
        handleObjectiveChange(objectives[0].id);
      }
    }
  }, [objectives]);

  const handleObjectiveChange = (newObjectiveId) => {
    const newObjective = objectives.find((i) => i.id === newObjectiveId);
    setObjective(newObjective);
    updateFilterObjective(newObjectiveId);
  };

  return !isObjectivesLoading && objectives && objectives.length > 0 ? (
    <StyledFormControl variant="filled">
      <StyledSelect
        disableUnderline
        IconComponent={StyledKeyboardArrowDownIcon}
        value={objective?.id || ''}
        onChange={(e) => handleObjectiveChange(e.target.value)}
        MenuProps={{
          PaperProps: {
            sx: {
              marginTop: '4px',
              padding: '8px',
              backgroundColor: themeColors.inputBackground,
              boxShadow:
                '0px 1px 4px 0px var(--shadow-level-1, rgba(44, 50, 63, 0.20))',
            },
          },
        }}
      >
        {Array.isArray(objectives)
          ? objectives.map((item) => (
              <StyledMenuItem key={item.title} value={item.id}>
                {item.title || ''}
              </StyledMenuItem>
            ))
          : null}
      </StyledSelect>
    </StyledFormControl>
  ) : null;
};
