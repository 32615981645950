import { Typography, Box, Chip, Tooltip, Link } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PendingIcon from '@mui/icons-material/Pending';
import { toCapitalize } from '../../../utils/toCapitalize';

export const STATUS = Object.freeze({
  SUCCESS: 'SUCCESS',
  RUNNING: 'RUNNING',
  PENDING: 'PENDING',
  FAILED: 'FAILURE',
});

const getStatusCounts = (objective) => {
  const counts = {
    [STATUS.SUCCESS]: 0,
    [STATUS.RUNNING]: 0,
    [STATUS.PENDING]: 0,
    [STATUS.FAILED]: 0,
  };
  Object.values(objective).forEach((task) => {
    if (task.status in counts) {
      counts[task.status] += 1;
    }
  });
  return counts;
};

const TooltipWrapper = styled(Box)(() => ({
  maxHeight: '200px',
  overflowY: 'auto',
}));

const TooltipLink = styled(Link)(() => ({
  display: 'block',
  padding: 0,
  background: 'none',
  '&:hover': {
    background: 'none',
  },
}));
const Tooltips = ({ tasks }) => (
  <TooltipWrapper>
    <Box>Task IDs</Box>
    <Box>
      {Object.keys(tasks).map((taskId) => (
        <TooltipLink key={taskId} href={tasks[taskId].cloudWatchLogs}>
          {taskId}
        </TooltipLink>
      ))}
    </Box>
  </TooltipWrapper>
);
const ChipWrapper = styled(Box)(() => ({
  gridGap: '5px',
  display: 'flex',
  fontSize: 10,
  marginTop: '5px',
}));

export const TimelineChips = ({ tasks, taskCounts }) => {
  const labelMap = {
    [STATUS.FAILED]: { label: 'failed', color: 'error' },
    [STATUS.SUCCESS]: { label: 'done', color: 'success' },
    [STATUS.PENDING]: { label: 'pending', color: 'info' },
    [STATUS.RUNNING]: { label: 'running', color: 'info' },
  };

  return (
    <ChipWrapper>
      {Object.entries(taskCounts).map(([key, value], i) => {
        if (value > 0) {
          return (
            <Tooltip
              key={`${i}${key}${value}`}
              title={<Tooltips tasks={tasks} />}
              arrow
            >
              <Chip
                size="small"
                variant="outlined"
                icon={getStatusIcon(key)}
                color={labelMap[key].color}
                data-testid={`${value}-${labelMap[key].label}`}
                label={`${value} ${labelMap[key].label}`}
              />
            </Tooltip>
          );
        }
        return null;
      })}
    </ChipWrapper>
  );
};

const TimelineWrapper = styled(Box)(() => ({
  width: '33%',
  alignItems: 'left',
  textAlign: 'left',
}));

const getStatusIcon = (status, fontSize = 'small') => {
  switch (status) {
    case STATUS.SUCCESS:
      return <CheckCircleIcon fontSize={fontSize} />;
    case STATUS.FAILED:
      return <ErrorIcon fontSize={fontSize} />;
    case STATUS.PENDING:
      return <PendingIcon fontSize={fontSize} />;
    case STATUS.RUNNING:
      return <WatchLaterIcon fontSize={fontSize} />;
    default:
      return null;
  }
};
export const formatContainerName = (containerName) => {
  const wordsByUnderscore = containerName.split('_');
  const wordsByDash = wordsByUnderscore.join('-').split('-');

  return wordsByDash
    .map((word) => (word.length < 4 ? word.toUpperCase() : toCapitalize(word)))
    .join(' ');
};

export const extractStatusByObjective = (data) => {
  const getAllObjectives = Object.entries(data).reduce(
    (result, [containerId, objectives]) => {
      const objectiveIds = Object.keys(objectives);
      let tempRes = { ...result };
      objectiveIds.forEach((objectiveId) => {
        tempRes = {
          ...tempRes,
          [objectiveId]: {
            ...tempRes[objectiveId],
            [containerId]: objectives[objectiveId],
          },
        };
      });
      return tempRes;
    },
    {}
  );
  return getAllObjectives;
};

const TimelinesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}));

export const ProjectStatus = ({ data, objectiveNames }) => {
  const statusByObjective = extractStatusByObjective(data);
  if (!statusByObjective) {
    return null;
  }
  return (
    <>
      <Typography variant="h2">Status</Typography>
      <TimelinesWrapper>
        {Object.entries(statusByObjective).map(
          ([objectiveId, objectiveContainerTasks]) => (
            <TimelineWrapper key={`timeline-${objectiveId}`}>
              <Typography variant="h4">
                {objectiveNames[objectiveId]}
              </Typography>
              <Box>
                <i>{objectiveId}</i>
              </Box>
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                {Object.entries(objectiveContainerTasks).map(
                  ([containerName, containerTasks], i) => (
                    <ContainerTimelineEntry
                      containerName={containerName}
                      containerTasks={containerTasks}
                      isLast={
                        i + 1 === Object.keys(objectiveContainerTasks).length
                      }
                      key={containerName + objectiveId}
                    />
                  )
                )}
              </Timeline>
            </TimelineWrapper>
          )
        )}
      </TimelinesWrapper>
    </>
  );
};

const TimelineChipWrapper = styled(Box)(() => ({
  gridGap: '5px',
  display: 'flex',
  fontSize: 10,
  marginTop: '5px',
}));

const ContainerTimelineEntry = ({ containerTasks, containerName, isLast }) => {
  const totalTasks = Object.keys(containerTasks).length;
  const taskCounts = getStatusCounts(containerTasks);
  if (totalTasks === 0) {
    return null;
  }
  return (
    <TimelineItem>
      <TimelineSeparator>
        {taskCounts[STATUS.SUCCESS] === totalTasks ? (
          <TimelineDot color="success" />
        ) : (
          <TimelineDot />
        )}
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="title1">
          {formatContainerName(containerName)}: {taskCounts[STATUS.SUCCESS]}/
          {totalTasks}
        </Typography>
        <TimelineChipWrapper>
          <TimelineChips tasks={containerTasks} taskCounts={taskCounts} />
        </TimelineChipWrapper>
      </TimelineContent>
    </TimelineItem>
  );
};
