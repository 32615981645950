import React from 'react';

import '@fontsource/roboto';
import 'typeface-roboto';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import Application from './application';
import { AppContext } from './appContext';
import { ThemeContext } from './themeContext';
import { useApplicationContext } from './useApplicationContext';
import { useThemeContext } from './useThemeContext';
import { AxiosInterceptor } from './axiosInterceptor';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const ApplicationWrapper = () => {
  const groupedContext = useApplicationContext();
  const themeContext = useThemeContext();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider value={themeContext}>
        <ThemeProvider theme={themeContext.activeTheme}>
          <AppContext.Provider value={groupedContext}>
            <AxiosInterceptor>
              <Application />
            </AxiosInterceptor>
          </AppContext.Provider>
        </ThemeProvider>
      </ThemeContext.Provider>
    </StyledEngineProvider>
  );
};
