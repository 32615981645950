import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import ChangeChip from './changeChip';

const scoreSize = 40;

export const POWERSCORE_TIER_COLOURS = {
  10: { borderColor: '#FF800B', background: '#FFB168' },
  9: { borderColor: '#F0D438', background: '#FFEF94' },
  7: { borderColor: '#AC39F4', background: '#D38DFF' },
  4: { borderColor: '#2E6EF9', background: '#6FA2F1' },
  1: { borderColor: '#9E9E9E', background: '#C5C5C5' },
  0: { borderColor: '#262626', background: '#888888' },
};

export const getScoreColours = (score) => {
  if (score !== null && score !== undefined) {
    const roundedScore = Math.round(score);
    if (roundedScore === 10) return POWERSCORE_TIER_COLOURS[10];
    if (roundedScore >= 9) return POWERSCORE_TIER_COLOURS[9];
    if (roundedScore >= 7) return POWERSCORE_TIER_COLOURS[7];
    if (roundedScore >= 4) return POWERSCORE_TIER_COLOURS[4];
    if (roundedScore >= 1) return POWERSCORE_TIER_COLOURS[1];
  }
  return POWERSCORE_TIER_COLOURS[0];
};

const PrimaryScore = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'score',
})(({ score }) => ({
  width: scoreSize,
  height: scoreSize,
  borderRadius: '50%',
  borderWidth: 3,
  borderStyle: 'solid',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 600,
  fontSize: 22,
  paddingTop: 1,
  color: 'black',
  gridColumnStart: 1,
  letterSpacing: -0.5,
  gridRowStart: 1,
  ...getScoreColours(score),
}));

const ObjectiveScore = styled(Box, {
  shouldForwardProp: (prop) => !['score', 'zIndex', 'selected'].includes(prop),
})(({ score, selected = false, zIndex = 0 }) => ({
  width: 12,
  height: 12,
  borderRadius: '50%',
  borderWidth: 2,
  borderStyle: 'solid',
  alignItems: 'center',
  justifyContent: 'center',
  borderColor: selected ? '#171b22' : '#171b22',
  background: getScoreColours(score).borderColor,
  marginLeft: -6,
  zIndex,
}));

const PrimaryScoreWrapper = styled(Box)(() => ({
  display: 'grid',
  gridTemplateRows: '100%',
  gridTemplateColumns: '100%',
  height: scoreSize,
  width: scoreSize,
}));

const ObjectiveScoresWrapper = styled(Box)(() => ({
  display: 'flex',
  gridColumnStart: 1,
  gridRowStart: 1,
  alignItems: 'end',
  justifyContent: 'end',
  marginBottom: -2,
  marginRight: -2,
}));

const ScoreWrapper = styled(Box)(() => ({
  width: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const PowerScore = ({
  score = 0,
  scoreChangeValue = 0,
  isSelected = false,
  objectiveScoreList = [],
  style = {},
}) => {
  const showObjectiveScores = objectiveScoreList.length > 1;

  return (
    <ScoreWrapper sx={style}>
      <PrimaryScoreWrapper>
        <PrimaryScore score={score}>
          <p data-testid="test-maptual-score">{score}</p>
        </PrimaryScore>
        <ObjectiveScoresWrapper>
          {showObjectiveScores &&
            objectiveScoreList.map((objectiveScore, i) => (
              <ObjectiveScore
                score={objectiveScore}
                zIndex={objectiveScoreList.length - i}
                key={`objective-${objectiveScore}-${i}`}
                selected={isSelected}
              />
            ))}
        </ObjectiveScoresWrapper>
      </PrimaryScoreWrapper>
      {scoreChangeValue !== 0 ? (
        <ChangeChip change={scoreChangeValue} selected={isSelected} />
      ) : null}
    </ScoreWrapper>
  );
};
