import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grow, Skeleton, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const useStyles = makeStyles(({ insightContentCardStyle, themeColors }) => ({
  root: {
    ...insightContentCardStyle('darkTheme'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '724px',
    width: '100%',
    margin: '0',
  },
  error: {
    ...insightContentCardStyle('darkTheme'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '300px',
    width: '100%',
    margin: '0',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    width: '100%',
    margin: '20px',
  },
  errorMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
  },
  errorMessageRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  errorTitle: {
    margin: '0',
    padding: '0',
    marginLeft: '12px',
    fontSize: 16,
    fontWeight: 500,
    color: themeColors.emptyText,
  },
  errorMessage: {
    margin: '0',
    padding: '0 16px',
    marginTop: '16px',
    fontSize: 12,
    fontWeight: 400,
    color: themeColors.emptyText,
  },
  errorIconStyle: {
    width: '20px',
    height: '20px',
    fill: themeColors.emptyText,
  },
  dashBoardChart: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
  },
  title: {
    height: '25px',
    margin: '0',
    marginBottom: '6px',
    fontSize: 18,
    fontWeight: 500,
    color: themeColors.secondaryTextColor,
    whiteSpace: 'normal',
  },
  mediumRowSkeleton: {
    transform: 'scale(1)',
    margin: '0',
    marginTop: '15px',
    height: '9%',
    width: '100%',
    backgroundColor: '#232429',
  },
  smallRowSkeleton: {
    transform: 'scale(1)',
    margin: '0',
    marginTop: '19px',
    height: '6%',
    width: '29%',
    backgroundColor: '#232429',
  },
  largeRowSkeleton: {
    transform: 'scale(1)',
    margin: '0',
    marginTop: '19px',
    height: '25%',
    width: '100%',
    backgroundColor: '#232429',
  },
}));

const SkeletonChart = ({ style = {}, title = '', error = '' }) => {
  const classes = useStyles();
  if (error?.length)
    return (
      <div className={classes.dashBoardChart} style={style}>
        <Grow
          in
          timeout={800}
          key="skeleton"
          style={{ margin: 0, padding: 30 }}
        >
          <div className={classes.error}>
            <Typography className={classes.title} align="left">
              {title}
            </Typography>
            <div className={classes.errorContainer}>
              <div className={classes.errorMessageContainer}>
                <div className={classes.errorMessageRow}>
                  <ErrorOutlineIcon
                    className={classes.errorIconStyle}
                    data-test-state="check-chart-appears"
                  />
                  <Typography className={classes.errorTitle} align="left">
                    Graph and table not available
                  </Typography>
                </div>
                <div className={classes.errorMessageRow}>
                  <Typography className={classes.errorMessage} align="center">
                    {error}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Grow>
      </div>
    );
  return (
    <div className={classes.dashBoardChart} style={style}>
      <Grow in timeout={800} key="skeleton" style={{ margin: 0, padding: 30 }}>
        <div className={classes.root}>
          <Typography className={classes.title} align="left">
            {title}
          </Typography>
          <Skeleton
            classes={{ root: classes.mediumRowSkeleton }}
            sx={{ bgcolor: '#111215' }}
            animation="wave"
            style={{ height: style?.heightPref }}
          />
          <Skeleton
            classes={{ root: classes.mediumRowSkeleton }}
            sx={{ bgcolor: '#111215' }}
            animation="wave"
            style={{ height: style?.heightPref }}
          />
          <Skeleton
            classes={{ root: classes.smallRowSkeleton }}
            sx={{ bgcolor: '#111215' }}
            animation="wave"
            style={{ height: style?.heightPref }}
          />
          <Skeleton
            classes={{ root: classes.largeRowSkeleton }}
            sx={{ bgcolor: '#111215' }}
            animation="wave"
            style={{ height: style?.heightPref }}
          />
          <Skeleton
            classes={{ root: classes.largeRowSkeleton }}
            sx={{ bgcolor: '#111215' }}
            animation="wave"
            style={{ height: style?.heightPref }}
          />
        </div>
      </Grow>
    </div>
  );
};

export default SkeletonChart;
