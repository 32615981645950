import React, { useContext, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { NewProjectContext } from './newProjectContext';
import { DeployDialogContext } from '../projectviewer/deployCsvDialog/deployDialogContext';
import { PROJECT_CREATION_STEPS } from './common/constants';
import { ProjectModalMode } from './projectModalMode';

const ObjectiveSummaryWrapper = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  gap: '16px',
  width: '100%',
  backgroundColor: themeColors.objectiveSummaryBackgroundColor,
  borderRadius: '4px',
  border: `1px solid ${themeColors.borderLowContrast}`,
  marginBottom: 16,
}));

const MarketShareWrapper = styled('div')({
  paddingTop: '8px',
});

const ObjectiveSummaryHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

const ObjectiveSummaryHeaderTextBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const ObjectiveBasketSummary = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const ObjectiveBasketSummaryText = styled(Box)({
  display: 'flex',
  gap: '6px',
});

const HeaderText = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: '16px',
  fontWeight: 500,
  color: themeColors.objectiveTypeEditButtonColor,
}));

const SubTitleText = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: themeColors.objectiveTypeEditButtonColor,
}));

const ListText = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: themeColors.neutral60,
}));

export function ObjectiveSummary({ objective, number }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { projectModalMode } = useContext(DeployDialogContext);
  const {
    objectives,
    setObjectives,
    setObjectiveType,
    setObjectiveBeingEdited,
    setProjectCreationStep,
    setShareMetric,
    setNonScoringObjective,
  } = useContext(NewProjectContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDelete = (uuidToDelete) => {
    setObjectives(objectives.filter((obj) => obj.uuid !== uuidToDelete));
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showMenu =
    projectModalMode !== ProjectModalMode.viewProject &&
    projectModalMode !== ProjectModalMode.runProjectFromTemplate;

  return (
    <ObjectiveSummaryWrapper>
      <ObjectiveSummaryHeader>
        <ObjectiveSummaryHeaderTextBox>
          <HeaderText>Objective {number}</HeaderText>
          <SubTitleText>
            {objective.objectiveType.typeDisplay} Objective
            {objective.nonScoringObjective ? ' (Non-Scoring Objective)' : ''}
          </SubTitleText>
        </ObjectiveSummaryHeaderTextBox>
        {showMenu && (
          <>
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setObjectiveBeingEdited(objective.uuid);
                  setObjectiveType(objective.objectiveType);
                  setShareMetric({ ...objective?.shareMetric });
                  setNonScoringObjective(objective.nonScoringObjective);
                  setProjectCreationStep(PROJECT_CREATION_STEPS.OBJECTIVE);
                }}
              >
                Edit
              </MenuItem>
              <MenuItem onClick={() => handleDelete(objective.uuid)}>
                Delete
              </MenuItem>
            </Menu>
          </>
        )}
      </ObjectiveSummaryHeader>
      <ObjectiveBasketSummary>
        {objective.baskets.map((basket, index) => (
          <ObjectiveBasketSummaryText key={index}>
            <SubTitleText>{`${basket.basketName}:` || 'Test:'}</SubTitleText>
            <ListText>
              {basket.products
                .map((product) => product.productName.toUpperCase())
                .join(', ')}{' '}
              {basket.indications
                .map((indication) => indication.toUpperCase())
                .join(', ')}{' '}
              {basket.therapeuticAreas
                .map((indication) => indication.toUpperCase())
                .join(', ')}
              {basket.specialties
                .map((indication) => indication.toUpperCase())
                .join(', ')}
            </ListText>
          </ObjectiveBasketSummaryText>
        ))}
        {objective?.shareMetric?.rxType && (
          <MarketShareWrapper>
            <SubTitleText>
              Market Share Metric: {objective.shareMetric.rxType.toUpperCase()}
            </SubTitleText>
          </MarketShareWrapper>
        )}
      </ObjectiveBasketSummary>
    </ObjectiveSummaryWrapper>
  );
}
