import React, { useState } from 'react';
import prettier from 'prettier/standalone';
import parserBabel from 'prettier/parser-babel';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

const formatJsonString = (jsonString) => {
  try {
    return prettier.format(jsonString, {
      parser: 'json',
      plugins: [parserBabel],
    });
  } catch (e) {
    return jsonString;
  }
};

const StyledTextArea = styled('textarea')(
  ({ error, theme: { themeColors } }) => ({
    width: '100%',
    maxWidth: '100%',
    minHeight: '200px',
    verticalAlign: 'top',
    backgroundColor: `${themeColors.modalBackgroundColor}`,
    color: `${themeColors.jsonInputTextColor}`,
    border: error
      ? `1px solid ${themeColors.danger60}`
      : `1px solid ${themeColors.borderLowContrast}`,
    borderRadius: '4px',
    paddingLeft: '16px',
    resize: 'none',
  })
);

const StyledErrorText = styled('div')(({ theme: { themeColors } }) => ({
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  color: `${themeColors.danger60}`,
  marginLeft: 8,
}));

const StyledHeader = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
  marginBottom: 4,
  lineHeight: '135%',
});

const StyledSubheader = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: 16,
  color: themeColors.neutral60,
  marginBottom: 24,
}));

export const JsonInputSection = ({
  value,
  header,
  subheader,
  onChange,
  onBlur,
  error,
  helperText,
  ...textFieldProps
}) => {
  const [jsonInput, setJsonInput] = useState(formatJsonString(value));

  const handleBlur = (e) => {
    const formatted = formatJsonString(jsonInput);
    setJsonInput(formatted);
    onBlur(e);
  };

  const handleChange = (event) => {
    setJsonInput(event.target.value);
    onChange(event);
  };

  return (
    <div>
      <StyledHeader>{header}</StyledHeader>
      <StyledSubheader>{subheader}</StyledSubheader>
      <StyledTextArea
        {...textFieldProps}
        value={jsonInput}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="Enter JSON here"
        error={error}
      />
      {error && (
        <StyledErrorText>{`Invalid JSON: ${helperText}`}</StyledErrorText>
      )}
    </div>
  );
};
