import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import React from 'react';

const StyledSkeletonBar = styled(Skeleton)(() => ({
  transform: 'scale(1)',
  height: 64,
}));

export const TableControlsSkeleton = () => (
  <StyledSkeletonBar animation="wave" />
);
