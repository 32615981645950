import { newDataSteps, addDataSteps } from '../models/ingestNavModel';

function mapSteps(steps) {
  const titleSteps = steps.map((step) => step.title);

  return {
    activeStep: 0,
    titleSteps,
    stepsCount: titleSteps.length,
    completedSteps: {},
    completedStepsCount: 0,
  };
}

const initState = Object.freeze(mapSteps(newDataSteps));

function isLastStep(prevState) {
  return prevState.activeStep === prevState.stepsCount - 1;
}

function allStepsCompleted(prevState) {
  return prevState.completedStepsCount === prevState.stepsCount;
}

function getNextStep(prevState) {
  return isLastStep(prevState) && !allStepsCompleted(prevState)
    ? // It's the last step, but not all steps have been completed,
      // find the first step that has been completed
      prevState.titleSteps.findIndex(
        (step, i) => !(i in prevState.completedSteps)
      )
    : prevState.activeStep + 1;
}

function completeStep(prevState, index) {
  let newState = {};
  if (index === undefined || index === null || Number.isNaN(index))
    // eslint-disable-next-line no-param-reassign
    index = prevState.activeStep;

  if (index >= 0) {
    newState = { ...prevState.completedSteps };
    newState[index] = true;
    const newActiveStep = getNextStep(prevState);
    return {
      ...prevState,
      completedSteps: newState,
      completedStepsCount: Object.keys(newState).length,
      activeStep: newActiveStep,
    };
  }
  return { ...prevState };
}

export default function reducer(
  state = initState,
  action = { type: '', payload: '' }
) {
  let newStates = {};
  switch (action.type) {
    case 'NEW_DATASET':
      newStates = mapSteps(newDataSteps);
      return { ...state, ...newStates };
    case 'ADD_TO_DATASET':
      newStates = mapSteps(addDataSteps);
      return { ...state, ...newStates };
    case 'SET_ACTIVE_STEP':
      if (action.payload >= 0) return { ...state, activeStep: action.payload };
      return { ...state };
    case 'GOTO_NEXT_STEP':
      return { ...state, activeStep: getNextStep(state) };

    case 'RESET_STEPS':
      return {
        ...state,
        completedSteps: {},
        completedStepsCount: 0,
        activeStep: 0,
      };

    case 'COMPLETE_STEP':
    case 'VALIDATE_DATASET_NAME_FULFILLED':
    case 'SAMPLE_DATAFILE_FULFILLED':
    case 'VALIDATE_COLUMN_REVIEW_FULFILLED':
    case 'VALIDATE_KEY_COLUMNS_FULFILLED':
    case 'VALIDATE_JOURNEY_DURATION_FULFILLED':
    case 'DATA_INGEST_CONFIRM_UPLOAD_FULFILLED':
    case 'VALIDATE_DATAFILE_FULFILLED':
    case 'DATA_INGEST_FINALIZE_COLUMN_META':
    case 'SKIP_VALIDATION':
      return completeStep(state, action.payload);
    default:
      return state;
  }
}
