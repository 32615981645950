import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ themeColors }) => ({
  titleText: {
    fontSize: 24,
    fontWeight: 600,
    color: themeColors.primaryTextColor,
    marginTop: '16px',
    marginBottom: '16px',
    whiteSpace: 'normal',
  },
}));

export default function ShareChartTitle({ title }) {
  const classes = useStyles();
  return (
    <Typography noWrap align="left" className={classes.titleText}>
      {title}
    </Typography>
  );
}
