import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import isValidProp from '@emotion/is-prop-valid';
import { TooltipPopover } from '../../shared/TooltipPopover';

const TooltipHeaderBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 3,
}));

const HeaderBox = styled(Box, {
  shouldForwardProp: isValidProp,
})(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const HeaderText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isCaption' || prop !== 'variant',
})(({ theme, isCaption }) => ({
  color: isCaption
    ? theme.themeColors.tertiaryColor
    : theme.themeColors.secondaryTextColor,
  whiteSpace: 'normal',
  textAlign: 'left',
}));

export const EntityTableHeader = ({
  type,
  label,
  subLabel,
  tooltipText,
  tooltipList,
}) => {
  if (tooltipText) {
    return (
      <HeaderBox>
        <TooltipHeaderBox>
          <HeaderText variant="caption" type={type} label={label}>
            <>
              {label}
              <TooltipPopover
                title={label}
                description={tooltipText}
                listData={tooltipList}
              />
            </>
          </HeaderText>
        </TooltipHeaderBox>
        <HeaderText variant="caption" type={type} isCaption>
          {subLabel}
        </HeaderText>
      </HeaderBox>
    );
  }

  return (
    <HeaderBox headerType={label}>
      <HeaderText variant="caption" type={type}>
        {label}
      </HeaderText>
      <HeaderText variant="caption" type={type} isCaption>
        {subLabel}
      </HeaderText>
    </HeaderBox>
  );
};
