/* eslint-disable no-restricted-syntax */
import React, { useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RegionSelectionModal } from '../../../shared/RegionSelectionModal';
import { AppContext } from '../../../../appContext';
import { FieldContext } from '../../fieldContext';
import { APPVIEWS, ROUTES } from '../../../../../../constants';
import { selectEntity } from '../../../../../../actions/projectViewActions';

const sortListsAlphaNum = (a, b) =>
  a.listName.localeCompare(b.listName, 'en', { numeric: true });

const sortRegions = (regions) => {
  const provinces = regions.filter(
    (region) => region.locality.toUpperCase() === 'PROVINCE'
  );
  const territories = regions.filter(
    (region) => region.locality.toUpperCase() === 'TERRITORY'
  );
  const districts = regions.filter((region) =>
    region.locality.toUpperCase().includes('DISTRICT')
  );
  const national = regions.filter((region) =>
    region.listName.toUpperCase().includes('NATIONAL')
  );

  const sortedWithDuplicates = [
    ...[...territories, ...districts].sort(sortListsAlphaNum),
    ...provinces.sort(sortListsAlphaNum),
    ...national,
  ];

  const dedeuplicated = sortedWithDuplicates.filter(
    (v, i, a) => a.findIndex((v2) => v2.listName === v.listName) === i
  );

  return dedeuplicated.filter(
    (region) => region.listName.toUpperCase() !== 'PROVINCIAL'
  );
};

// eslint-disable-next-line no-underscore-dangle
const _findParentRegions = (list, region, regionTree) => {
  if (region.listName === regionTree?.metadata?.listName) {
    return list;
  }
  for (const childRegion of regionTree?.nodes || []) {
    const regionList = (regionTree?.metadata?.locality || '')
      .toLowerCase()
      .includes('national')
      ? list
      : [...list, regionTree?.metadata];
    const val = _findParentRegions(regionList, region, childRegion);
    if (val?.length) return val;
  }
  return [];
};

const getParentRegions = (regions, maptualListTree) => {
  let parentRegions = [];
  for (const region of regions) {
    const currentParentRegions = _findParentRegions(
      [],
      region,
      maptualListTree
    );
    parentRegions = [...parentRegions, ...currentParentRegions];
  }
  const uniqueParentRegions = [...new Set(parentRegions)];
  return uniqueParentRegions;
};

export const RegionModalProvider = ({ isOpen, handleClose }) => {
  const {
    project,
    maptualListMetadata,
    maptualListFlat,
    maptualListHierarchy,
  } = useContext(FieldContext);
  const { profile } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const otherRegions = Object.values(maptualListFlat).filter(
    (region) =>
      profile.role.assignedTerritoryIds?.includes(region.listName) ||
      region.locality === 'PROVINCE' ||
      region.listName.toUpperCase() === 'NATIONAL'
  );
  const otherRegionsParents = useMemo(
    () => getParentRegions(otherRegions, maptualListHierarchy),
    [otherRegions, maptualListHierarchy, project?.metadata?.projectId]
  );

  const assignedRegions = Object.values(maptualListFlat).filter(
    (region) =>
      profile.role.territoryIds?.includes(region.listName) ||
      region.locality === 'PROVINCE' ||
      region.listName.toUpperCase() === 'NATIONAL'
  );
  const assignedRegionParents = useMemo(
    () => getParentRegions(assignedRegions, maptualListHierarchy),
    [assignedRegions, maptualListHierarchy, project?.metadata?.projectId]
  );

  const handleRegionSelection = (region) => {
    if (region.maptualListId === maptualListMetadata.maptualListId) {
      handleClose();
    }
    const view = location.pathname.split('/').slice(6).join('/');
    const route = `${ROUTES.FIELD}${ROUTES.PROJECTS}/${project?.metadata?.projectId}/maptualListId/${region.maptualListId}/${view}`;
    history.push(route);
    dispatch(selectEntity(null));

    handleClose();
  };

  // all regions for admin
  const allRegionsParents = useMemo(
    () =>
      getParentRegions(Object.values(maptualListFlat), maptualListHierarchy),
    [
      Object.values(maptualListFlat),
      maptualListHierarchy,
      project?.metadata?.projectId,
    ]
  );
  let userRegionList = [];
  let regionsForSearch = sortRegions([
    ...Object.values(maptualListFlat),
    ...allRegionsParents,
  ]);
  if (profile?.role?.type !== APPVIEWS.ADMIN) {
    userRegionList = sortRegions([
      ...assignedRegions,
      ...assignedRegionParents,
    ]);
    regionsForSearch = sortRegions([
      ...assignedRegions,
      ...otherRegions,
      ...assignedRegionParents,
      ...otherRegionsParents,
    ]);
  }

  return (
    <RegionSelectionModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleRegionSelection={handleRegionSelection}
      regionsForSearch={regionsForSearch}
      regionList={userRegionList}
    />
  );
};
