export const CONFIG_VIEW_MODE = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  CREATE: 'CREATE',
};

export const NEW_OBJECTIVE_TYPE_TEMP_ID = 'NEW_OBJECTIVE_TYPE_TEMP_ID';
export const NEW_MARKET_TEMP_ID = 'NEW_MARKET_TEMP_ID';
export const NEW_MARKET = {
  marketId: NEW_MARKET_TEMP_ID,
  basketsScoringWeight: {},
  categoricalMetricsScoringWeight: {},
  indications: [],
  market: '',
  metrics: [],
  metricsScoringWeight: {},
  productLines: [],
  regionalTeamId: '',
  specialty: [],
  totalIndications: [],
  trainingSpecialties: [],
  therapeuticArea: [],
};
