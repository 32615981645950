import { useQuery } from 'react-query';
import { getEntityActivityInsights } from '../request/projectRequests';
import useMaptualContext from './useMaptualContext';

const validateParams = (params) => Object.values(params).every(Boolean);

export const useEntityActivityInsights = (entityId) => {
  const { projectId, maptualListId } = useMaptualContext();

  const params = {
    projectId,
    maptualListId,
    entityId,
  };

  const queryCacheKey = Object.values(params);
  return useQuery(
    queryCacheKey,
    async () => {
      const response = await getEntityActivityInsights({
        projectId,
        maptualListId,
        entityId,
      });
      return response.data;
    },
    {
      enabled: validateParams({
        ...params,
        entityActivityInsights: 'entityActivityInsights',
      }),
    }
  );
};
