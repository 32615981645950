import React from 'react';

import styled from '@emotion/styled';
import isValidProp from '@emotion/is-prop-valid';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

const StyledBox = styled(Box, {
  shouldForwardProp: isValidProp,
})(({ bgColor }) => ({
  padding: '2px 4px',
  display: 'flex',
  borderRadius: 3,
  alignItems: 'center',
  gap: 4,
  backgroundColor: bgColor,
  height: 16,
  maxWidth: '50%',
}));

const StyledTypography = styled(Typography, {
  shouldForwardProp: isValidProp,
})(({ textColor }) => ({
  fontSize: 11,
  fontWeight: 500,
  lineHeight: 1,
  color: textColor,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const SegmentChip = ({ segmentLabel, key, darken = false }) => {
  const { themeColors } = useTheme();

  let backgroundColor = themeColors.badgePrimaryColor;
  let color = themeColors.badgePrimaryContentColor;

  if (darken) {
    backgroundColor += '55';
    color += '55';
  }

  return segmentLabel ? (
    <StyledBox
      data-testid="test-segment-chip"
      key={key}
      bgColor={backgroundColor}
    >
      <StyledTypography noWrap textColor={color}>
        {segmentLabel}
      </StyledTypography>
    </StyledBox>
  ) : null;
};
