import React, { useState } from 'react';
import { styled, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Menu from '@mui/material/Menu';
import { ProductTreeDetails } from './productTreeDetails';
import { useConfigViewContext } from '../configViewContext';
import { CONFIG_VIEW_MODE } from '../userGroupConfigConstants';
import { useIsAuthorized } from '../../../hooks/useIsAuthorized';
import { RBAC_PERMISSIONS } from '../../../constants';

const TileWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'scroll',
});

const Tile = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTop: `1px solid ${themeColors.borderLowContrast}`,
  borderBottom: `1px solid ${themeColors.borderLowContrast}`,
  padding: 16,

  ':first-child': {
    borderTop: 'none',
  },

  ':not(:last-child)': {
    borderBottom: 'none',
  },

  ':hover': {
    backgroundColor: `${themeColors.popoverBorderColor}`,
  },
}));

const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  gap: 12,
  padding: 12,
  alignItems: 'center',
});

const LabelText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.objectiveTypeDeleteIconColor,
}));

const StyledContentCopyIcon = styled(ContentCopyIcon)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.navBarButtonIconColor,
  })
);

const StyledDeleteOutlineIcon = styled(DeleteOutlineIcon)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.navBarButtonIconColor,
  })
);

export function ProductTreeView({
  productTrees,
  productLine,
  setDisplayTreeDetails,
  editExistingTree,
  totalProductsList,
  isTreeUpdating,
}) {
  if (productLine) {
    return (
      <ProductTreeDetails
        productTree={productLine}
        setDisplayTreeDetails={setDisplayTreeDetails}
        editExistingTree={editExistingTree}
        isTreeUpdating={isTreeUpdating}
        totalProductsList={totalProductsList}
      />
    );
  }
  return (
    <TileWrapper>
      {productTrees.map((productTree) => (
        <ProductTreeTile
          key={productTree.productLine + productTree.marketName}
          productTree={productTree}
        />
      ))}
    </TileWrapper>
  );
}

export function ProductTreeTile({ productTree }) {
  const { productLineId } = productTree;
  const [anchorEl, setAnchorEl] = useState(null);
  const { setProductLineId, setConfigViewMode } = useConfigViewContext();

  const hasConfigAdminPermissions = useIsAuthorized([
    RBAC_PERMISSIONS.USER_GROUP_CONFIG_ADMIN,
  ]);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <Tile onClick={() => setProductLineId(productLineId)}>
      <Typography variant="h6">
        {productTree.productLine}
        {productTree.marketName ? ` For ${productTree.marketName}` : ''}
      </Typography>
      {hasConfigAdminPermissions && (
        <>
          <MoreVertIcon onClick={handleClick} />

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <StyledMenuItem
              onClick={() => {
                setProductLineId(productLineId);
                setConfigViewMode(CONFIG_VIEW_MODE.CREATE);
              }}
            >
              <StyledContentCopyIcon />
              <LabelText>Duplicate</LabelText>
            </StyledMenuItem>
            <StyledMenuItem onClick={() => {}}>
              <StyledDeleteOutlineIcon />
              <LabelText>Delete Tree</LabelText>
            </StyledMenuItem>
          </Menu>
        </>
      )}
    </Tile>
  );
}
