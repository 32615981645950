import { RudderAnalytics } from '@rudderstack/analytics-js';

const WRITE_KEY = '2erqQdB2cUosd4SznJ0EPpr4z2o';
const DATA_PLANE_URL = 'https://odaiakennkjfup.dataplane.rudderstack.com';

const rudderAnalytics = new RudderAnalytics();
rudderAnalytics.load(WRITE_KEY, DATA_PLANE_URL, {});

const initializeRudderAnalytics = ({ profile }) => {
  rudderAnalytics.ready(() => {
    rudderAnalytics.identify(profile.userId, {
      name: profile.fullName,
      email: profile.email,
      role: profile.role.role,
      subType: profile.role.subType,
      company: profile.organization?.organizationName || '',
      markets: profile.role?.markets || [],
    });
  });
};

export { initializeRudderAnalytics };
