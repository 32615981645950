import padStart from 'lodash/padStart';
import flow from 'lodash/flow';
import { getDemoAccountStatus } from '../../request/config';

const ProvinceAnonymizer = () => {
  let provinceCounter = 1;
  return (region) => {
    const anonymizedRegion = { ...region };
    if (anonymizedRegion?.locality === 'PROVINCE') {
      anonymizedRegion.label = `State ${padStart(provinceCounter, 2, '0')}`;
      provinceCounter += 1;
    }

    return anonymizedRegion;
  };
};

const CustomerTerritoryAnonymizer = () => {
  let territoryCounter = 1;
  return (region) => {
    const anonymizedRegion = { ...region };
    if (
      anonymizedRegion?.locality === 'TERRITORY' ||
      anonymizedRegion?.locality?.includes('DISTRICT')
    ) {
      anonymizedRegion.label = `Territory ${padStart(
        territoryCounter,
        2,
        '0'
      )}`;
      territoryCounter += 1;
    }

    return anonymizedRegion;
  };
};

export const americanizeRegionNames = (regions) => {
  const anonymizeProvince = ProvinceAnonymizer();
  const anonymizeCustomerTerritory = CustomerTerritoryAnonymizer();

  return regions.map(flow(anonymizeProvince, anonymizeCustomerTerritory));
};

export const anonymizeCustomerTerritoriesOnGetProjectSegmentsResponse =
  () => (next) => (action) => {
    const modifiedAction = { ...action };
    const isDemo = getDemoAccountStatus();

    if (isDemo && action.type === 'PROJECT_GET_PROJECT_SEGMENTS_FULFILLED') {
      modifiedAction.payload = {
        ...modifiedAction.payload,
        regions: americanizeRegionNames(modifiedAction?.payload?.regions),
      };
    }

    return next(modifiedAction);
  };
