import { useParams, useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useQuery } from 'react-query';
import { useCuratedList } from '../../../../hooks/useCuratedList';
import {
  addListCountsToMaptualList,
  addNewHcpsToListItems,
  addUserCreatedListPropertiesToMaptualList,
} from '../../../../utils/projectViewReducerUtils';
import { AppContext } from '../../appContext';
import { useMaptualLists } from '../../../fieldview/useMaptualLists';
import { useMaptualList } from '../../../fieldview/useMaptualList';
import {
  getDemoAccountStatus,
  userHasCallPlanAccess,
} from '../../../../request/config';
import { ROUTES, CUSTOM_LISTS } from '../../../../constants';
import { getEntityViewsConfig } from '../../../../request/entityOverviewRequests';
import useFeatureToggles from '../../../../hooks/useFeatureToggles';

const UUID_LENGTH = 36;

const updateMaptualListWithUserCreatedProperties = (
  project,
  maptualList,
  newUserCreatedLists
) => {
  const projectProductLineId = project?.metadata?.productLineId;
  const maptualListItems = maptualList?.list?.items;

  if (!newUserCreatedLists || !newUserCreatedLists?.[projectProductLineId]) {
    return {
      ...maptualList,
      list: {
        ...maptualList?.list,
        items: maptualListItems,
      },
    };
  }

  const updatedHcps = addNewHcpsToListItems(
    maptualListItems,
    newUserCreatedLists[projectProductLineId]
  );

  const updatedMaptualList = {
    ...maptualList,
    list: {
      ...maptualList?.list,
      items: updatedHcps,
    },
  };

  const finalMaptualList = addListCountsToMaptualList(
    updatedMaptualList,
    newUserCreatedLists[projectProductLineId]
  );

  return finalMaptualList;
};

const updateCuratedListWithUserCreatedProperties = (
  baseList,
  userCreatedLists,
  projectItem
) => {
  if (!baseList || !baseList.list) {
    return baseList;
  }

  const productLineUserCreatedLists = userCreatedLists
    ? userCreatedLists[projectItem?.metadata?.productLineId]
    : undefined;

  const maptualListWithUserCreatedListProperties = productLineUserCreatedLists
    ? addUserCreatedListPropertiesToMaptualList(
        baseList,
        productLineUserCreatedLists
      )
    : undefined;

  return maptualListWithUserCreatedListProperties ?? baseList;
};

const censorMaptualListMetadata = (metadata) => {
  if (getDemoAccountStatus()) {
    return { ...metadata, listName: 'Territory' };
  }
  return metadata;
};

export const useFieldContext = () => {
  const { projectList, profile } = useContext(AppContext);

  const [navigationOpen, setNavigationOpen] = useState(false);

  const maptualListFilterPreferences =
    profile?.preference?.maptualListFilterPreferences || {};

  const userCreatedLists = useSelector(
    (state) => state.projectViewer.userCreatedLists
  );

  const territoriesIdLabelMap = useSelector(
    (state) => state.projectViewer.maptualList?.metadata?.childEntities
  );

  const [curatedList, setCuratedList] = useState(null);
  const [isCuratedListLoading, setIsCuratedListLoading] = useState(false);
  const [customListType, setCustomListType] = useState('');

  const params = useParams();
  const history = useHistory();

  const [project, setProject] = useState({});
  const {
    maptualListHierarchy,
    maptualListFlat,
    maptualListObjectives,
    isMaptualListsLoading,
  } = useMaptualLists({
    projectId: project?.metadata?.projectId,
  });
  const [maptualListMetadata, setMaptualListMetadata] = useState({});
  const featureToggles = useFeatureToggles();

  const callPlanAccess =
    userHasCallPlanAccess(profile) &&
    featureToggles(profile.userGroup, 'curatedList');

  const navigateWithQueryParams = (url) => {
    history.push(`${url}${history.location.search}`);
  };

  const getTourId = (queryParamString) =>
    queryParamString.slice(queryParamString.indexOf('product_tour_id') + 16);

  const updateRoute = () => {
    if (params && params[0] === 'projects') {
      let route = `${ROUTES.FIELD}${ROUTES.PROJECTS}`;
      if (
        project?.metadata?.projectId &&
        params?.projectId !== project?.metadata?.projectId
      ) {
        route = `${ROUTES.FIELD}${ROUTES.PROJECTS}/${project.metadata.projectId}`;
        navigateWithQueryParams(route);
      }
      if (
        maptualListMetadata?.maptualListId &&
        params?.maptualListId !== maptualListMetadata.maptualListId
      ) {
        const landingRoute = callPlanAccess ? ROUTES.CALL_PLAN : ROUTES.HCPS;
        route = `${ROUTES.FIELD}${ROUTES.PROJECTS}/${project.metadata.projectId}/maptualListId/${maptualListMetadata.maptualListId}${landingRoute}`;

        // Intercom Tours fallback - start tour when we see the tour link if tour hasnt loaded
        const queryParamString = history.location.search;
        if (queryParamString.includes('product_tour_id')) {
          let retryTourCount = 8;
          const tourStarter = setInterval(() => {
            if (
              !document.querySelector('.intercom-tour-frame') &&
              retryTourCount > 0
            ) {
              window.Intercom('startTour', getTourId(queryParamString));
              retryTourCount++;
            } else {
              clearInterval(tourStarter);
            }
          }, 2000);
          history.push(route);
          return;
        }
        navigateWithQueryParams(route);
      }
    }
  };

  const getSelectedProjectId = () => {
    if (params?.projectId) return params.projectId;

    if (profile?.preference?.lastProjectMetadata?.projectId)
      return profile?.preference?.lastProjectMetadata?.projectId;

    if (projectList?.length && projectList[0].projectId)
      return projectList[0].projectId;

    return null;
  };

  const getSelectedRegionId = () => {
    if (
      params?.maptualListId &&
      Object.keys(maptualListFlat).includes(params?.maptualListId)
    )
      return params.maptualListId;

    if (
      profile?.preference?.lastMaptualListMetadata?.maptualListId &&
      Object.keys(maptualListFlat).includes(
        profile?.preference?.lastMaptualListMetadata?.maptualListId
      )
    )
      return profile?.preference?.lastMaptualListMetadata?.maptualListId;

    if (profile?.role?.territoryIds && profile?.role?.territoryIds.length > 0) {
      const firstAssignedTerritoryName = profile?.role?.territoryIds[0];
      const userTerritoryMetadata = Object.values(maptualListFlat).find(
        (region) => firstAssignedTerritoryName === region.listName
      );
      if (userTerritoryMetadata) {
        return userTerritoryMetadata.maptualListId;
      }
    }
    if (
      Object.keys(maptualListFlat || []).length &&
      Object.values(maptualListFlat)[0].maptualListId
    )
      return Object.values(maptualListFlat)[0].maptualListId;

    return null;
  };

  const [objective, setObjective] = useState({});

  useEffect(() => {
    // TODO remove temp check after migrate routes other than projects
    if (params[0] !== 'projects' || !projectList?.length) return;
    const selectedProjectId = getSelectedProjectId();
    const projectFromList = projectList.find(
      (proj) => proj.projectId === selectedProjectId
    );

    setObjective({});

    if (!projectFromList) {
      setProject({ metadata: projectList[0] });
      return;
    }

    setProject({ metadata: projectFromList });
    setMaptualListMetadata({});
  }, [params?.projectId, projectList]);

  useEffect(() => {
    if (params[0] !== 'projects') return;
    const selectedRegionId = getSelectedRegionId();
    if (!selectedRegionId) return;

    setMaptualListMetadata(
      censorMaptualListMetadata(maptualListFlat[selectedRegionId])
    );
  }, [params?.maptualListId, maptualListFlat]);

  useEffect(() => {
    if (
      !params?.customListType &&
      Object.keys(CUSTOM_LISTS).includes(params?.customListType)
    )
      return;

    setCustomListType(params?.customListType);
  }, [params?.customListType]);

  useEffect(() => {
    if (params[0] !== 'projects') return;
    updateRoute();
  }, [maptualListMetadata, project?.projectId, params[0]]);

  const marketId = project?.metadata?.marketId;
  const { data: entityViews = [] } = useQuery(
    `entityViews-${marketId}`,
    async () => {
      if (marketId) {
        const response = await getEntityViewsConfig(marketId);
        return response?.data.views;
      }

      return [];
    },
    {
      enabled: marketId && marketId.length === UUID_LENGTH,
    }
  );

  const {
    maptualList,
    isMaptualListLoading,
    refetchMaptualList,
    updateMaptualList,
  } = useMaptualList({
    project,
    maptualListId: maptualListMetadata?.maptualListId,
    objectiveId: objective?.id,
    userCreatedLists,
    customListType,
  });

  const { data: curatedListQueried, isLoading: isCuratedListLoadingQueried } =
    useCuratedList(project, maptualListMetadata);

  const updateCuratedListUserCreatedProperties = (newUserLists) => {
    setCuratedList(
      updateCuratedListWithUserCreatedProperties(
        curatedList,
        newUserLists,
        project
      )
    );
  };

  const updateMaptualListUserCreatedProperties = (newUserList) => {
    updateMaptualList(
      updateMaptualListWithUserCreatedProperties(
        project,
        maptualList,
        newUserList
      )
    );
  };

  useEffect(() => {
    if (!curatedListQueried) {
      return;
    }

    const curatedListQueriedWithUserCreatedProperties =
      updateCuratedListWithUserCreatedProperties(
        curatedListQueried,
        userCreatedLists,
        project
      );
    setCuratedList(curatedListQueriedWithUserCreatedProperties);
  }, [curatedListQueried]);

  useEffect(() => {
    setIsCuratedListLoading(isCuratedListLoadingQueried);
  }, [isCuratedListLoadingQueried]);

  const segmentList = maptualList?.segments;

  return {
    curatedList,
    isCuratedListLoading,
    project,
    maptualListHierarchy,
    maptualListFlat,
    maptualListMetadata,
    isMaptualListsLoading,
    updateCuratedListUserCreatedProperties,
    navigationOpen,
    setNavigationOpen,
    territoriesIdLabelMap,
    isMaptualListLoading,
    refetchMaptualList,
    maptualList,
    segmentList,
    updateMaptualListUserCreatedProperties,
    maptualListFilterPreferences,
    maptualListObjectives,
    objective,
    setObjective,
    customListType,
    entityViews,
  };
};
