/* eslint-disable no-restricted-syntax */
import React, { useContext, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';
import Chart from 'react-apexcharts';
import { METRIC_CHART_TAB_CADENCES } from '../tabbedChart/constants';
import { formatPercentForAxis } from '../../../../utils/formatPercent';
import { PREDICTION_PRECISION } from '../trendValues';
import { AppContext } from '../../../../containers/application/appContext';

const graphHeight = 220;
const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    color: '#000000',
  },
}));

export const DATA_LABEL_ARROW_TYPE = Object.freeze({
  UP: 1,
  DOWN: -1,
  NONE: 0,
});

export default function ShareGraph({
  selectedData,
  selectedTab,
  customHeight = graphHeight,
}) {
  const classes = useStyles();
  const { themeColors } = useTheme();
  const curDataList = selectedData?.dataList;

  const { profile } = useContext(AppContext);
  const [showToolbar, setShowToolbar] = useState(false);

  useEffect(() => {
    if (
      profile.role.type !== 'UNKNOWN' &&
      typeof profile.role.permission.ALLOW_CSV_DOWNLOAD === 'boolean'
    ) {
      /**
       * TODO: Using ALLOW_CSV_DOWNLOAD to determine whether we should
       *  show the toolbar (that contains more than just CSV) is misleading.
       *
       *  Action: we should change the userProfileRole response
       */
      setShowToolbar(profile.role.permission.ALLOW_CSV_DOWNLOAD);
    }
  }, [profile.role]);

  function getPredictedValue(primaryData) {
    if (typeof primaryData?.prediction?.value === 'number') {
      return primaryData?.prediction.value;
    }
    if (
      primaryData?.prediction?.secondaryValue.status ===
        PREDICTION_PRECISION.INCREASING_OUTSIDE_RANGE ||
      primaryData?.prediction?.secondaryValue.status ===
        PREDICTION_PRECISION.DECREASING_OUTSIDE_RANGE
    ) {
      return primaryData?.now.value;
    }
    return 0;
  }

  function formatPrediction(seriesIndex) {
    const prediction = series[seriesIndex]?.prediction;
    const predictionStatus = prediction?.secondaryValue.status;

    switch (predictionStatus) {
      case PREDICTION_PRECISION.INCREASING_WITHIN_RANGE:
      case PREDICTION_PRECISION.DECREASING_WITHIN_RANGE:
      case PREDICTION_PRECISION.NO_CHANGE:
        return formatPercentForAxis(series[seriesIndex]?.data?.[2]);
      case PREDICTION_PRECISION.INCREASING_OUTSIDE_RANGE:
        return 'INCR';
      case PREDICTION_PRECISION.DECREASING_OUTSIDE_RANGE:
        return 'DECR';
      case PREDICTION_PRECISION.CANNOT_PREDICT:
        return 'N/A';
      default:
        return undefined;
    }
  }

  function getDataLabelType(seriesIndex, dataPointIndex, w) {
    const prediction = series[seriesIndex]?.prediction?.secondaryValue;
    const predictionStatus = prediction?.status;

    if (
      prediction === undefined ||
      dataPointIndex !== w.config.series[seriesIndex].data.length - 1
    ) {
      return DATA_LABEL_ARROW_TYPE.NONE;
    }

    switch (predictionStatus) {
      case PREDICTION_PRECISION.INCREASING_WITHIN_RANGE:
      case PREDICTION_PRECISION.INCREASING_OUTSIDE_RANGE:
        return DATA_LABEL_ARROW_TYPE.UP;
      case PREDICTION_PRECISION.DECREASING_WITHIN_RANGE:
      case PREDICTION_PRECISION.DECREASING_OUTSIDE_RANGE:
        return DATA_LABEL_ARROW_TYPE.DOWN;
      default:
        return DATA_LABEL_ARROW_TYPE.NONE;
    }
  }

  const series = [];
  let horizontalCategories;
  let max = 0;
  if (selectedTab.value === METRIC_CHART_TAB_CADENCES.ANNUAL) {
    const dates = [];
    for (const dateObject of curDataList?.listHeader || []) {
      if (dateObject?.key !== 'dataSource') {
        dates.push(dateObject?.primaryText);
      }
    }
    horizontalCategories = dates;
    for (const row of curDataList?.listItems || []) {
      const primaryData = row?.primaryData;
      const predictedValue = getPredictedValue(primaryData);
      const rowData = [
        primaryData?.historical?.value,
        primaryData?.now?.value,
        predictedValue,
      ];
      const adjustedRowData = rowData.map((val) => val * 100);
      max = Math.max(...adjustedRowData, max);
      series.push({
        name: primaryData?.dataSource,
        data: adjustedRowData,
        prediction: primaryData?.prediction,
      });
    }
  } else {
    horizontalCategories = curDataList?.listItems[0]?.xaxis?.categories;
    for (const row of curDataList?.listItems || []) {
      const adjustedRowData = row?.series[0]?.data.map((val) => val * 100);
      max = Math.max(...adjustedRowData, max);
      series.push({
        name: row?.series[0]?.name,
        data: adjustedRowData,
      });
    }
  }

  const baseColumnWidth = 10;
  let optimalColumnWidthPercent = series.length + baseColumnWidth + 5;
  if (
    (series && series.length > 2) ||
    (horizontalCategories && horizontalCategories.length > 3)
  ) {
    optimalColumnWidthPercent =
      series.length * (horizontalCategories.length * 2) + baseColumnWidth;
  }

  let maxValue = Math.ceil(max / 10);
  maxValue = maxValue % 2 === 0 ? maxValue * 10 : (maxValue + 1) * 10;
  maxValue = Math.min(maxValue, 100);

  const options = {
    colors: themeColors.graphLegendColors,
    chart: {
      type: 'bar',
      height: customHeight,
      toolbar: {
        show: showToolbar,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: `${optimalColumnWidthPercent}%`,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      formatter(value, { seriesIndex, dataPointIndex, w }) {
        const dataLabelType = getDataLabelType(seriesIndex, dataPointIndex, w);

        if (dataLabelType === DATA_LABEL_ARROW_TYPE.UP) return '▲';
        if (dataLabelType === DATA_LABEL_ARROW_TYPE.DOWN) return '▼';
        return '';
      },
      style: {
        colors: [
          function getColors({ seriesIndex, dataPointIndex, w }) {
            const dataLabelType = getDataLabelType(
              seriesIndex,
              dataPointIndex,
              w
            );

            if (dataLabelType === DATA_LABEL_ARROW_TYPE.UP) return '#34EC5D';
            if (dataLabelType === DATA_LABEL_ARROW_TYPE.DOWN) return '#FF5959';
            return '#000000';
          },
        ],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    grid: {
      show: true,
      borderColor: themeColors.contentCardBorderColor,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      labels: {
        tickPlacement: 'between',
        show: true,
        hideOverlappingLabels: true,
        style: {
          colors: themeColors.primaryMaptualListFilteringColor,
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 400,
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: horizontalCategories || [],
    },
    yaxis: {
      title: {
        text: '',
      },
      max: maxValue,
      tickAmount: 4,
      forceNiceScale: false,
      labels: {
        show: true,
        hideOverlappingLabels: true,
        style: {
          colors: themeColors.primaryMaptualListFilteringColor,
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 400,
        },
        formatter: formatPercentForAxis,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: true,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      theme: 'dark',
      style: {
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: true,
        formatter: undefined,
      },
      y: {
        formatter(value, { seriesIndex, dataPointIndex }) {
          return dataPointIndex === 2 &&
            selectedTab.value === METRIC_CHART_TAB_CADENCES.ANNUAL
            ? formatPrediction(seriesIndex)
            : formatPercentForAxis(value);
        },
      },
      marker: {
        show: true,
      },
      fixed: {
        enabled: false,
        position: 'topRight',
      },
    },
  };

  return (
    <div className={classes.root}>
      <Chart
        options={options}
        series={series}
        type="bar"
        data-testid="test-market-share-data-chart"
        height={customHeight}
        width="100%"
      />
    </div>
  );
}
