import React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { Timeline, History } from '@mui/icons-material';

export const Icon = styled('div')(() => ({
  borderRadius: '100%',
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const VisualSubtitle = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gridGap: '4px',
  marginTop: '4px',
  height: '14px',
  svg: {
    maxHeight: '100%',
    width: '14px',
    color: themeColors.textButtonColor,
  },
}));

export const RecentLabel = () => (
  <VisualSubtitle>
    <History />
    <TimeLabel variant="subtitle1">Recent</TimeLabel>
  </VisualSubtitle>
);

export const PredictedLabel = () => (
  <VisualSubtitle>
    <Timeline />
    <TimeLabel variant="subtitle1">Predicted</TimeLabel>
  </VisualSubtitle>
);

export const Title = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
}));

export const Subtitle = styled(Title)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
  marginBottom: 8,
}));

export const SubText = styled(Title)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
}));

export const TimeLabel = styled(Typography)(() => ({}));

export const MetricDateLabel = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    marginTop: 4,
    color: themeColors.primaryMaptualListFilteringColor,
  })
);
