import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import styled from '@emotion/styled';
import { trackCSVDownloadButtonClick } from '../../../trackers/appEventTracker';
import { getDownloadableUrl } from '../../../slices/downloadableUrl';

const StyledIconButton = styled(IconButton)(({ theme: { themeColors } }) => ({
  width: 24,
  height: 24,
  backgroundColor: themeColors.mainSurfaceBackground,
}));

const DownloadIcon = styled(FileDownloadOutlinedIcon)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.csvDownloadColor,
    width: '14px',
    height: '14px',
  })
);

const CsvDownloadButton = ({ projectId, maptualListId }) => {
  const dispatch = useDispatch();
  const isDownloading = useSelector(
    (state) => state.projectViewer.maptualListDownloadableUrl.pending === true
  );

  const downloadCSV = async () => {
    trackCSVDownloadButtonClick();
    const { error, payload: url } = await dispatch(
      getDownloadableUrl({ projectId, maptualListId })
    );
    return !error && url ? window.open(url) : null;
  };

  return (
    <Tooltip title="Download Results" placement="bottom" enterTouchDelay={400}>
      <StyledIconButton
        aria-label="download maptual list csv button"
        disabled={isDownloading}
        onClick={downloadCSV}
      >
        {isDownloading ? <CircularProgress size={8} /> : <DownloadIcon />}
      </StyledIconButton>
    </Tooltip>
  );
};

export default CsvDownloadButton;
