import { Box, IconButton, styled } from '@mui/material';
import { themeColors as adminColors } from '../../styles/adminStyles';

const iconSizeField = '1.6rem';
const iconSizeSphere = 24;

export const CustomIconButton = styled(IconButton)((props) =>
  props.isField
    ? {
        padding: '0.4rem',
        marginTop: '0.4rem',
        size: 'medium',
        color: 'inherit',
        svg: {
          height: iconSizeField,
          width: iconSizeField,
          margin: 0,
        },
      }
    : {
        svg: {
          height: iconSizeSphere,
          width: iconSizeSphere,
          margin: 0,
        },
        padding: 0,
        color: adminColors.darkTheme.sideNavBarIconColor,
      }
);

export const Separator = styled(Box)({
  flexGrow: 1,
});
