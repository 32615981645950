import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const DropdownText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.offWhiteFontColor,
  fontSize: '18px',
  margin: '6px 0px',
}));

const CustomTreeView = styled(TreeView)(({ theme: { themeColors } }) => ({
  '& .MuiTreeItem-root .MuiTreeItem-content.Mui-selected': {
    backgroundColor: themeColors.entitySelectionHoverBackgroundColor, // Change this to your desired color
  },
}));

export const ProductTree = ({ productLineTree }) => {
  const renderTree = (node) => (
    <TreeItem
      key={node.productId}
      nodeId={node.productId}
      label={<DropdownText>{node.productName.toUpperCase()}</DropdownText>}
    >
      {Array.isArray(node.subCategories)
        ? node.subCategories.map((subCategory) => renderTree(subCategory))
        : null}
    </TreeItem>
  );
  return (
    <CustomTreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {renderTree(productLineTree)}
    </CustomTreeView>
  );
};
