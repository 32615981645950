/* eslint-disable no-restricted-syntax */
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import BarChart from './barchart';
import { useCurationReports } from './hooks/useCurationReports';

function CpsChart({
  title,
  projectId,
  regionId,
  onLoad,
  graphType,
  classGraphContent = '',
  classLoading = '',
}) {
  const { isLoading, data } = useCurationReports({
    projectId,
    regionId,
    report: 'cps',
    graphType,
  });

  if (!isLoading && data?.data?.length && title !== data.data[0].segmentName) {
    onLoad(data.data[0].segmentName);
  }

  return (
    <div className={classGraphContent}>
      {!isLoading && data?.data && (
        <BarChart
          title={title}
          series={formatSeries(data.data)}
          labels={formatLabels(data.data)}
        />
      )}
      {isLoading && (
        <div className={classLoading}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

const formatSeries = (data) => {
  if (!data.length) return [];
  const hcpCountData = { data: data.map((item) => item.hcpCount) };
  return [hcpCountData];
};
const formatLabels = (data) => {
  if (!data.length) return [];
  return data.map((item) => item.segmentType);
};

export default CpsChart;
