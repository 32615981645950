import React from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useTheme } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Icon } from '../entityInsights/insightsGeneric';
import { toCapitalize } from '../../../utils/toCapitalize';

const HelpButtonWrapper = styled(IconButton)(({ theme: { themeColors } }) => ({
  width: 13,
  height: 13,
  color: themeColors.secondaryTextColor,
  svg: {
    width: 14,
    height: 14,
  },
  marginLeft: 4,
  padding: 5,
  boxSizing: 'content-box',
}));

const LevelBarWrapper = styled(Box)(({ theme: { themeColors } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  svg: {
    color: themeColors.rankedCategoricalScaleActiveChevron,
  },
}));

const Bar = styled(Box)(() => ({
  marginTop: -4,
  width: '100%',
  height: 4,
  opacity: 0.4,
  '&.selectedBar': {
    opacity: 1,
  },
}));

const Title = styled(Box)(() => ({
  fontWeight: 500,
  fontSize: 14,
  marginBottom: 4,
  display: 'flex',
  alignItems: 'center',
}));

const EmptyLevelWrapper = styled(Box)(({ theme: { themeColors } }) => ({
  padding: 24,
  backgroundColor: themeColors.surfaceEmpty,
  borderRadius: 3,
  color: themeColors.emptyText,
  marginTop: 14,
}));

const EmptyLevelTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gridGap: 10,
  fontSize: 12,
  justifyContent: 'center',
}));

const HelpButton = ({ intercomId }) => (
  <HelpButtonWrapper onClick={() => window.Intercom('showArticle', intercomId)}>
    <InfoOutlinedIcon />
  </HelpButtonWrapper>
);

const LevelBar = ({ color, isSelected, ...props }) => (
  <LevelBarWrapper {...props}>
    {isSelected ? <ArrowDropDownIcon /> : null}
    <Bar
      sx={{
        background: color,
      }}
      className={isSelected ? 'selectedBar' : ''}
    />
  </LevelBarWrapper>
);

const LEVELS_SETTINGS = {
  level1: { color: '#dc7656' },
  level2: { color: '#dcb656' },
  level3: { color: '#5e708d' },
  level4: { color: '#6aa086' },
  level5: { color: '#34ec5d' },
};

const LevelMeterWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  gridGap: 1,
  alignItems: 'flex-end',
}));

const ContextBox = styled(Box)(({ theme: { themeColors } }) => ({
  borderRadius: 10,
  backgroundColor: themeColors.cardBackgroundColor,
  border: '1px solid',
  borderColor: themeColors.contentCardBorderColor,
}));

const Card = styled(ContextBox)(() => ({
  display: 'flex',
  textAlign: 'left',
  padding: '24px 0 24px 0',
}));

const VerticalCard = styled(Card)(() => ({
  flexDirection: 'column',
  gridGap: 16,
}));

const CardHalf = styled(Box)(() => ({
  padding: '0 24px 0 24px',
  width: '100%',
}));

const CategoryHalf = styled(CardHalf)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gridGap: 14,
}));

const CardIcon = styled(Icon)(({ theme: { themeColors } }) => ({
  color: themeColors.anchorIconsGeneralSecondary,
  backgroundColor: themeColors.anchorIconsGeneralPrimary,
  marginBottom: 8,
}));

export const LevelMeter = ({ selectedCategoryIndex, numberOfCategories }) => (
  <LevelMeterWrapper>
    {[...Array(numberOfCategories).keys()].map((levelNumber) => (
      <LevelBar
        data-testid={
          selectedCategoryIndex === levelNumber
            ? `${levelNumber}-${numberOfCategories}-bar-selected-${levelNumber}`
            : `${levelNumber}-${numberOfCategories}-bar`
        }
        key={`level-${levelNumber}-maxLevel-title`}
        color={LEVELS_SETTINGS[`level${levelNumber + 1}`]?.color}
        isSelected={selectedCategoryIndex === levelNumber}
      />
    ))}
  </LevelMeterWrapper>
);

const CardDescription = ({ title, tooltip, subTitle }) => {
  const { themeColors } = useTheme();
  return (
    <CardHalf>
      <CardIcon>
        <QueryStatsIcon />
      </CardIcon>
      <Title color={themeColors.secondaryTextColor}>
        {title} {tooltip && <HelpButton intercomId={tooltip} />}
      </Title>
      <Typography variant="body2">{subTitle}</Typography>
    </CardHalf>
  );
};

export const formatCategoryTitle = (title) => {
  const words = title.split(' ');
  if (words.length === 1) {
    return title.toUpperCase();
  }

  return words.map((w) => toCapitalize(w)).join(' ');
};

const CardCategories = ({ categoricalData }) => {
  const { themeColors } = useTheme();

  if (!categoricalData.length) {
    return (
      <CategoryHalf>
        <EmptyLevelWrapper>
          <EmptyLevelTitle>No data on patient volume</EmptyLevelTitle>
        </EmptyLevelWrapper>
      </CategoryHalf>
    );
  }

  return (
    <CategoryHalf>
      {categoricalData.map((category, i) => (
        <Box key={`category-card-${i}`}>
          <Title color={themeColors.rankedCategoricalScaleCategory}>
            {formatCategoryTitle(category.title)}
          </Title>
          <Typography
            color={themeColors.rankedCategoricalScaleCategoryLabel}
            variant="body2"
          >
            {category.subTitle}
          </Typography>
          <LevelMeter {...category} />
        </Box>
      ))}
    </CategoryHalf>
  );
};

export const CategoricalDataCard = ({ data, orientation }) => {
  if (orientation === 'vertical') {
    return (
      <VerticalCard data-testid="vertical-card">
        <CardDescription {...data} />
        <CardCategories {...data} />
      </VerticalCard>
    );
  }

  return (
    <Card data-testid="horizontal-card">
      <CardDescription {...data} />
      <Divider light orientation="vertical" />
      <CardCategories {...data} />
    </Card>
  );
};
