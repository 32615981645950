import React, { useContext } from 'react';
import { MenuItem, OutlinedInput } from '@mui/material';

import {
  StyledFieldSubtitle,
  StyledFieldTitle,
  StyledFormControl,
  StyledSelect,
} from './styles/styledComponents';
import { NewProjectContext } from './newProjectContext';

export default function NotchedModalDropdown({
  title,
  subtitle,
  onChange,
  values,
  disabled,
  flip,
  defaultValue = 'Select Product Line',
}) {
  const { projectConfig } = useContext(NewProjectContext);
  return (
    <div>
      <StyledFieldTitle variant="h5">{title}</StyledFieldTitle>
      <StyledFieldSubtitle variant="body1">{subtitle}</StyledFieldSubtitle>
      <StyledFormControl disabled={disabled}>
        <StyledSelect
          input={<OutlinedInput notched />}
          value={values.find(
            (productLine) =>
              productLine.productLineId === projectConfig.productLineId
          )}
          onChange={onChange}
          displayEmpty
          MenuProps={{
            anchorOrigin: {
              vertical: flip ? 'top' : 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: flip ? 'bottom' : 'top',
              horizontal: 'left',
            },
          }}
          renderValue={(value) => (value ? value.productLine : defaultValue)}
        >
          {values.map((productLine) => (
            <MenuItem key={productLine.productLineId} value={productLine}>
              {productLine.productLine}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </div>
  );
}
