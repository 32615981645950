import { createPortal } from 'react-dom';
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Create';
import React from 'react';

const BUTTON_HEIGHT = 40;
export const FeedbackButton = () => (
  <>
    {createPortal(
      <Button
        href="https://forms.gle/6teaVx5f1g3oW8jL9"
        target="_blank"
        sx={{
          position: 'absolute',
          right: -60,
          rotate: '270deg',
          top: `calc(50% - ${BUTTON_HEIGHT}px / 2)`,
        }}
        variant="contained"
        endIcon={<CreateIcon sx={{ mr: 1 }} />}
      >
        Give Feedback
      </Button>,
      document.body
    )}
  </>
);
