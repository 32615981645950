import moment from 'moment';
import { lastContactedIds } from '../components/fieldView/constants';

const THIRTY_DAY_START = 30;
const THIRTY_DAY_END = 59;
const SIXTY_DAY_START = 60;
const SIXTY_DAY_END = 89;
const NINTY_DAYS = 90;

export function filterEntitiesByLastContactedDate(
  list,
  lastContactedThreshold
) {
  const ret = list.filter((item) =>
    checkLastContactedDate(item, lastContactedThreshold)
  );
  return ret;
}

export function checkLastContactedDate(item, lastContactedThreshold) {
  if (item.secondaryText) {
    const dayDifference = moment().diff(
      convertSecondaryTextToDate(item.secondaryText),
      'days'
    );
    if (lastContactedThreshold === lastContactedIds.THIRTY_DAYS) {
      return numberWithinRange(dayDifference, THIRTY_DAY_START, THIRTY_DAY_END);
    }
    if (lastContactedThreshold === lastContactedIds.SIXTY_DAYS) {
      return numberWithinRange(dayDifference, SIXTY_DAY_START, SIXTY_DAY_END);
    }
    if (lastContactedThreshold === lastContactedIds.NINETY_DAYS) {
      return numberGreaterThanThreshold(dayDifference, NINTY_DAYS);
    }
  }

  return false;
}

export function convertSecondaryTextToDate(text) {
  try {
    const splitText = text.split(',').map((item) => item.trim());
    const date = splitText[1];
    const year = splitText[2].split(' ')[0];

    return moment(date.concat(', ', year)).format('YYYY-MM-DD');
  } catch (error) {
    return moment().format('YYYY-MM-DD');
  }
}

export function numberWithinRange(number, start, end) {
  return number >= start && number <= end;
}

export function numberGreaterThanThreshold(number, threshold) {
  return number >= threshold;
}
