/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import styled from '@emotion/styled';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import { Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { PROJECT_STATUS, ROUTES } from '../../../../../../constants';
import { AppContext } from '../../../../appContext';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '343px',
    minWidth: '343px',
    marginBottom: 16,
  },
  selectRoot: {
    width: '100%',
    minHeight: '48px',
    paddingLeft: '8px',
    fontSize: '18px',
    fontWeight: 600,
    borderRadius: '3px',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: theme.themeColors.buttonBackgroundColor,
      '&:before': {
        borderBottom: 'none !important',
      },
    },
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },

    '& .MuiInput-input': {
      background: 'none',
      '&:focus': {
        background: 'none',
      },
    },
    '& .MuiMenu-list': {
      padding: '16px',
    },
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const StyledMenuItem = styled(MenuItem)((props) => ({
  backgroundColor: props.theme.themeColors.paperColor,
  fontColor: props.theme.themeColors.segmentSelectionCaptionColor,
  padding: '12px',
  margin: '0px 16px 2px 16px',
  textAlign: 'left',
  borderRadius: '3px',
  '&.Mui-selected': {
    color: props.theme.themeColors.selectedSegmentColor,
    backgroundColor:
      props.theme.themeColors.segmentSelectionSelectedBackgroundColor,
  },
}));

const SelectPadding = styled('div')((props) => ({
  backgroundColor: props.theme.themeColors.paperColor,
  paddingTop: 8,
}));

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.themeColors.buttonBackgroundColor,
    fontColor: theme.themeColors.offWhiteFontColor,
  },
}))(Tooltip);

export default function ProjectSelector() {
  const { isProjectListLoading, projectList } = useContext(AppContext);
  const classes = useStyles();
  const { projectId: projectIdFromParams } = useParams();
  const history = useHistory();
  const location = useLocation();

  const projectFromParams = projectList.find(
    (item) => item && item.projectId && item.projectId === projectIdFromParams
  );

  const handleChange = (event) => {
    if (
      projectFromParams.projectId !== event.target.value &&
      event.target.value
    ) {
      const newProj = projectList.find(
        (item) =>
          item && item.projectId && item.projectId === event.target.value
      );

      if (newProj) {
        const view = location.pathname.split('/').slice(-1).join();
        const route = `${ROUTES.PULSE}${ROUTES.PROJECTS}/${newProj.projectId}/${view}`;
        history.push(route);
      }
    }
  };

  const projectsSelections =
    Array.isArray(projectList) && projectList.length > 0
      ? projectList.map((item, i) => {
          const ret = {};
          if (!item) {
            ret.projectId = i;
            ret.projectName = 'unknown project';
            return ret;
          }

          ret.projectId = item.projectId;
          ret.projectName = item.projectName;
          if (item.projectStatus !== PROJECT_STATUS.DEPLOYED) {
            ret.projectName = `${String.fromCodePoint(0x1f6e0)} ${
              item.projectName
            }`;
          }

          return ret;
        })
      : null;

  const tempSelections = projectFromParams?.projectId
    ? {
        projectId: projectFromParams.projectId,
        projectName: projectFromParams.projectName,
      }
    : null;

  return (
    <FormControl
      className={classes.root}
      disabled={isProjectListLoading}
      variant="standard"
    >
      <StyledTooltip title={projectFromParams?.projectName} placement="top">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={projectFromParams?.projectId || ''}
          onChange={handleChange}
          data-testid="project-dropdown-selection"
          className={classes.selectRoot}
        >
          <SelectPadding />
          {projectsSelections ? (
            projectsSelections.map((item, i) => (
              <StyledMenuItem
                key={i}
                value={item.projectId}
                data-testid={item.projectName}
              >
                {item.projectName}
              </StyledMenuItem>
            ))
          ) : tempSelections?.[0] ? (
            <StyledMenuItem key={0} value={tempSelections[0].projectId}>
              <div className={classes.statusContainer}>
                {isProjectListLoading ? <CircularProgress size={12} /> : null}
                {tempSelections[0].projectName}
              </div>
            </StyledMenuItem>
          ) : null}
          <SelectPadding />
        </Select>
      </StyledTooltip>
      <div className={classes.statusContainer}>
        {isProjectListLoading ? (
          <CircularProgress
            size={12}
            color="primary"
            style={{ marginRight: 5 }}
          />
        ) : null}
      </div>
    </FormControl>
  );
}
