import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import DropdownSelections from '../dropdownSelections';
import ChipSelection from '../chipSelections';

const ChartContainerTitle = styled(Typography)`
  margin-bottom: 10px;
  color: ${(props) => props.theme.themeColors.primaryMaptualListFilteringColor};
`;

export default function TabbedChartHeader({
  title,
  chartMetadata,
  onChipSelect,
}) {
  const [selectedDropdownIndex, setSelectedDropdownIndex] = useState(0);
  const [selectedChipIndex, setSelectedChip] = useState(0);

  const dropdownOptions = chartMetadata.map(({ dropdownTitle }) => ({
    title: dropdownTitle,
  }));

  const selectedDropdown = chartMetadata[selectedDropdownIndex];
  const chipOptions = selectedDropdown?.chips || [];

  const handleChipSelect = (event, index) => {
    setSelectedChip(index);
    onChipSelect({
      chip: chipOptions[index],
      chipIndex: index,
      dropdownIndex: selectedDropdownIndex,
    });
  };

  useEffect(() => {
    if (chartMetadata && chartMetadata.length > 0) {
      handleChipSelect(null, 0);
    }
  }, [chartMetadata?.[0]?.dropdownTitle]);

  const handleDropdownSelect = (event, index) => {
    setSelectedDropdownIndex(index);

    setSelectedChip(0);
    onChipSelect({
      chip: chartMetadata[index].chips[0],
      chipIndex: 0,
      dropdownIndex: index,
    });
  };

  if (
    chartMetadata.length > 0 &&
    selectedDropdownIndex + 1 > chartMetadata.length
  ) {
    handleDropdownSelect(null, 0);
  }

  return (
    <>
      <ChartContainerTitle variant="h3" align="left">
        {title}
      </ChartContainerTitle>
      <div
        style={{ display: 'flex', justifyContent: 'flex-start' }}
        className="intercom-field-scripts-volume-objective-selector"
      >
        {dropdownOptions && dropdownOptions.length > 1 && (
          <DropdownSelections
            selectionName="Objective"
            options={dropdownOptions}
            selected={selectedDropdownIndex}
            onSelect={handleDropdownSelect}
          />
        )}
      </div>
      <ChipSelection
        options={chipOptions}
        selected={selectedChipIndex}
        onSelect={handleChipSelect}
      />
    </>
  );
}
