import { getDemoAccountStatus } from '../../request/config';
import { replaceListNames } from '../../utils/replaceListNames';

export const anonymizeCustomerTerritoriesOnGetMaptualListsResponse =
  () => (next) => (action) => {
    const modifiedAction = action;
    const isDemo = getDemoAccountStatus();

    if (isDemo && action.type === 'PROJECT_GET_MAPTUAL_LISTS_FULFILLED') {
      const currentPayload = { ...modifiedAction.payload };
      const nationalChildren = currentPayload.nodes;
      const obfuscatedTerritories = replaceListNames(nationalChildren);

      modifiedAction.payload = {
        ...modifiedAction.payload,
        nodes: obfuscatedTerritories,
      };
    }

    return next(modifiedAction);
  };
