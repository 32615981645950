import React, { useContext } from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import { useRegionCrmData } from '../../../salesReps/repTable/table/row/hooks/useRegionCrmData';
import TrendValue, {
  TREND_TYPES,
  PREDICTION_PRECISION,
} from '../../../../../../../../components/fieldView/entitycontents/trendValues';
import { PulseContext } from '../../../../context/PulseContext';
import { getObjectiveProducts } from '../../../../shared/utils';

const StyledContainer = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: themeColors.cardBackgroundColor,
  border: `1px solid ${themeColors.buttonBorderColor}`,
  width: '260px',
  borderRadius: '6px',
  padding: '24px',
  minWidth: 164,
}));

const StyledRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const StyledColumn = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}));

const StyledNumber = styled(Typography)(() => ({
  fontSize: '44px',
  marginTop: '8px',
  lineHeight: 1,
}));
const StyledSecondaryNumber = styled(Typography)(() => ({
  fontSize: '24px',
  marginTop: '8px',
  lineHeight: 1,
}));

const StyledLabel = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: '12px',
  margin: 0,
  marginTop: '16px',
  padding: 0,
  color: themeColors.graphAxisColor,
}));
const StyledSecondaryLabel = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    fontSize: '10px',
    margin: 0,
    marginTop: '4px',
    padding: 0,
    color: themeColors.graphAxisColor,
  })
);

const StyledHeader = styled(Typography)(() => ({
  fontSize: '15px',
  fontWeight: '400',
  textAlign: 'left',
}));

export const CrmOverviewCard = ({ objectiveId, cadence, timestamp }) => {
  const { projectId, territoryId } = useParams();
  const { productLineId, projectTargetProducts } = useContext(PulseContext);

  const { data: crmData, isLoading: isCrmDataLoading } = useRegionCrmData({
    productLineId,
    projectId,
    objectiveId,
    regionId: territoryId.split('?')[0], // todo: fix with routing
    cadence,
    timestamp,
    products: getObjectiveProducts(projectTargetProducts, objectiveId),
  });

  /**
   * @param {*} curated - whether its pulling curated or non curated crm call count
   * @param {*} index: the timestamp index we are looking at (1 is most recemt, 2 is prev week, ...)
   * @returns value - numeric if data exists, otherwise --
   */
  const callCount = ({ curated, index = 1 }) => {
    let value = '--';
    const key = curated ? 'curated' : 'nonCurated';
    if (crmData?.calls?.[key]) {
      const timestamps = Object.keys(crmData?.calls?.[key]);
      const lastTimestamp = timestamps[timestamps.length - index];
      value = crmData.calls[key][lastTimestamp]?.total || 0;
    }

    return value;
  };

  const trend = () => {
    let trendStatus = PREDICTION_PRECISION.NO_CHANGE;
    if (
      isCrmDataLoading ||
      !crmData?.curatedCalls?.length >= 2 ||
      !crmData?.nonCuratedCalled?.length >= 2
    ) {
      return (
        <TrendValue
          type={TREND_TYPES.TREND_CHANGE}
          status={trendStatus}
          value={0}
          filled
          noMargin
        />
      );
    }
    const totalCallCount =
      callCount({ curated: true }) + callCount({ curated: false });
    const prevTotalCallCount =
      callCount({ curated: true, index: 2 }) +
      callCount({ curated: false, index: 2 });

    if (totalCallCount === '----' || prevTotalCallCount === '----') {
      return (
        <TrendValue
          type={TREND_TYPES.TREND_CHANGE}
          status={trendStatus}
          value={0}
          filled
          noMargin
        />
      );
    }
    if (prevTotalCallCount === 0) {
      if (totalCallCount)
        trendStatus = PREDICTION_PRECISION.INCREASING_WITHIN_RANGE;
      return (
        <TrendValue
          type={TREND_TYPES.DIRECTION_ONLY}
          status={trendStatus}
          value={0}
          filled
          noMargin
        />
      );
    }
    const trendValue =
      (totalCallCount - prevTotalCallCount) / prevTotalCallCount;
    if (trendValue > 0)
      trendStatus = PREDICTION_PRECISION.INCREASING_WITHIN_RANGE;
    if (trendValue < 0)
      trendStatus = PREDICTION_PRECISION.DECREASING_WITHIN_RANGE;

    return (
      <TrendValue
        type={TREND_TYPES.TREND_CHANGE}
        status={trendStatus}
        value={trendValue}
        filled
        noMargin
      />
    );
  };

  const cadenceLabel = () => {
    let cadenceString = 'week';
    if (cadence?.toUpperCase() === 'MTH') cadenceString = 'month';
    if (cadence?.toUpperCase() === 'QTR') cadenceString = 'quarter';
    return (
      <StyledSecondaryLabel>from previous {cadenceString}</StyledSecondaryLabel>
    );
  };

  return (
    <StyledContainer>
      <StyledHeader>All HCPs Called</StyledHeader>
      <StyledRow>
        <StyledColumn>
          <StyledLabel>Total</StyledLabel>
          <StyledNumber>
            {isCrmDataLoading
              ? '...'
              : callCount({ curated: true }) + callCount({ curated: false })}
          </StyledNumber>
          {trend()}
          {cadenceLabel()}
        </StyledColumn>
      </StyledRow>
      <StyledRow>
        <StyledColumn>
          <StyledLabel>Curated</StyledLabel>
          <StyledSecondaryNumber>
            {isCrmDataLoading ? '...' : callCount({ curated: true })}
          </StyledSecondaryNumber>
        </StyledColumn>
        <StyledColumn>
          <StyledLabel>Non Curated</StyledLabel>
          <StyledSecondaryNumber>
            {isCrmDataLoading ? '...' : callCount({ curated: false })}
          </StyledSecondaryNumber>
        </StyledColumn>
      </StyledRow>
    </StyledContainer>
  );
};
