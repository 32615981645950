import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { TerritoryTableView } from './territoryTableView';
import { getTerritoryTableData } from '../../request/projectRequests';
import { ObjectiveContext } from './contexts/objectiveContext';
import EmptyView from '../emptyview/emptyView';

const projectCacheKey = (projectId) => `project_tree_table${projectId}`;
export const TerritoryTableProvider = ({ projectId }) => {
  const { selectedObjective, setSelectedObjective, selectedRegion } =
    useContext(ObjectiveContext);

  const { isLoading, data, isError, error } = useQuery(
    projectCacheKey(projectId),
    async () => {
      if (!projectId) return null;
      const response = await getTerritoryTableData({
        projectId,
        maptualListId:
          selectedRegion.label === 'National'
            ? null
            : selectedRegion?.maptualListId,
      });

      return response.data;
    },
    {
      cacheTime: 120000,
    }
  );

  useEffect(() => {
    if (!isLoading && data) {
      const objectives = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [objectiveId, item] of Object.entries(data)) {
        objectives.push({
          id: objectiveId,
          title: item.title,
        });
      }
      if (!selectedObjective) {
        setSelectedObjective(objectives[0]);
      }
    }
  }, [isLoading, data]);

  if (isLoading) return <EmptyView isLoading />;
  if (isError) {
    // eslint-disable-next-line no-console
    console.error('error: ', error);
    return <EmptyView />;
  }

  if (data && selectedObjective?.id && data[selectedObjective.id]) {
    return <TerritoryTableView data={data} />;
  }

  return null;
};
