import React from 'react';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import {
  ModalDisplay,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '../../../../components/generic/modalDisplay';

const StyledLoadingButton = styled(LoadingButton)(
  ({ theme: { themeColors } }) => ({
    textTransform: 'none',
    marginLeft: '8px',
    background: themeColors.maptualListFilteringButtonBackgroundColor,
  })
);

export const CsvDeliveryResetModal = ({
  isModalOpen,
  onClose,
  onConfirm,
  project = null,
}) => {
  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    if (project && project.projectId) {
      onConfirm(project.projectId);
    } else {
      onConfirm();
    }
    onClose();
  };

  return (
    <ModalDisplay
      isOpen={isModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalHeader onClose={handleClose}>
        <Typography variant="h3">Rest Dashboard</Typography>
      </ModalHeader>
      <ModalBody>
        {project && project.projectName ? (
          <Typography variant="body2">
            Are you sure you want to remove project {project.projectName} from
            the CSV Dashboard?
          </Typography>
        ) : (
          <Typography variant="body2">
            Are you sure you want to reset the CSV Dashboard? Projects attached
            to BUs will be unselected and you will have to assign them again.
          </Typography>
        )}
      </ModalBody>
      <ModalFooter>
        <>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <StyledLoadingButton
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleConfirm}
          >
            Confirm and Reset
          </StyledLoadingButton>
        </>
      </ModalFooter>
    </ModalDisplay>
  );
};
