import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { OutlinedInput, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import { userManagementColors } from '../styles/styledComponents';

const StyledSelect = styled(Select)(() => ({
  width: '50%',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: userManagementColors.primaryColor,
    borderWidth: '1px',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: userManagementColors.primaryColor,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: userManagementColors.primaryColor,
    borderWidth: '1px',
  },
  '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: userManagementColors.selectColor,
  },
  '& .MuiInputBase-input.Mui-disabled': {
    '-webkit-text-fill-color': userManagementColors.selectColor,
  },
}));

const StyledInputLabel = styled(InputLabel)(() => ({
  color: userManagementColors.selectColor,
  '&.Mui-focused': {
    color: userManagementColors.selectColor,
  },
  '&.Mui-disabled': {
    color: userManagementColors.selectColor,
  },
}));

const DropdownSelectionInput = ({
  id,
  label,
  options,
  stateVar,
  onChange,
  placeholderText,
  error,
  errorMessage,
  disabled = false,
}) => {
  const handleChange = (e) => {
    onChange(id, e.target.value);
  };

  return (
    <FormControl error={error}>
      <StyledInputLabel disabled={disabled} shrink>
        {label}
      </StyledInputLabel>
      <StyledSelect
        value={stateVar}
        onChange={handleChange}
        disabled={disabled}
        displayEmpty
        input={<OutlinedInput notched label={label} />}
        renderValue={
          stateVar === ''
            ? () => <Typography variant="body1"> {placeholderText}</Typography>
            : undefined
        }
      >
        {options?.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </StyledSelect>
      {error ? <FormHelperText>{errorMessage}</FormHelperText> : null}
    </FormControl>
  );
};
export default DropdownSelectionInput;
