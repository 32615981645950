import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import CardMedia from '@mui/material/CardMedia';
import ConnectIcon from '@mui/icons-material/Power';
import * as layoutStyles from '../../styles/layoutStyles';

const useStyles = makeStyles(() => ({
  titleRow: {
    ...layoutStyles.rowContainerLeftAligned,
  },
  root: {
    display: 'flex',
    height: 160,
    marginRight: 20,
    marginBottom: 20,
  },
  content: {
    ...layoutStyles.columnContainerLeftAligned,
    width: 300,
    height: '100%',
    marginLeft: 15,
    marginTop: 5,
  },
  cover: {
    margin: 20,
    width: 120,
  },
  title: {
    fontSize: 20,
  },
  subtitle: {
    fontSize: 13,
    marginTop: 3,
  },
  buttonStyle: {
    marginTop: 25,
  },
}));

export default function ConnectorCard({
  title,
  subTitle,
  image,
  animateOrder,
}) {
  const classes = useStyles();
  return (
    <Fade in timeout={700 + animateOrder * 150}>
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <div className={classes.titleRow}>
            <Typography component="h5" variant="h5" className={classes.title}>
              {title}
            </Typography>
          </div>
          <Typography
            variant="body1"
            color="textSecondary"
            align="left"
            className={classes.subtitle}
          >
            {subTitle}
          </Typography>
          <Button
            className={classes.buttonStyle}
            size="small"
            color="primary"
            variant="contained"
            startIcon={<ConnectIcon />}
            disabled
          >
            Connect
          </Button>
        </CardContent>

        <CardMedia
          className={classes.cover}
          image={image}
          title={`${title} connector`}
        />
      </Card>
    </Fade>
  );
}

ConnectorCard.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  animateOrder: PropTypes.number.isRequired,
};
