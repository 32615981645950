import React, { memo, useContext } from 'react';
import { Box, Typography, styled, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import withStyles from '@mui/styles/withStyles';
import { MetricEntry } from './metricEntry';
import { StyledMetricsRow } from '../styles/styledComponents';
import { AdminContext } from '../../../containers/application/appViews/adminContext';

const ColumnLabel = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
}));

const EntityLabel = styled(ColumnLabel)({
  fontSize: 14,
  margin: '8px 0px',
});

const StyledVisualizeLabel = styled(ColumnLabel)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const SubtitleText = styled(Box)(({ theme: { themeColors } }) => ({
  marginTop: 4,
  marginBottom: 16,
  color: themeColors.neutral60,
}));

export const StyledMetricsLabelRow = styled(StyledMetricsRow)(
  ({ isProjectBuilderDemoMode }) => ({
    maxWidth: isProjectBuilderDemoMode ? 324 : 436,
    margin: '0 0 16px',
  })
);

const StyledInfoIcon = styled(InfoOutlinedIcon)({
  width: 14,
  height: 14,
});

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.themeColors.tooltipBackgroundColor,
    fontSize: 14,
    fontWeight: 400,
    top: '-5px',
    '& .MuiTooltip-arrow': {
      color: theme.themeColors.tooltipBackgroundColor,
    },
  },
}))(Tooltip);

const GridLabelRow = ({ isProjectBuilderDemoMode }) => (
  <StyledMetricsLabelRow isProjectBuilderDemoMode={isProjectBuilderDemoMode}>
    <ColumnLabel variant="subtitle1">Metric name</ColumnLabel>
    {!isProjectBuilderDemoMode && (
      <ColumnLabel variant="subtitle1">Scoring weight</ColumnLabel>
    )}
    <StyledVisualizeLabel variant="subtitle1">
      Visualize
      <StyledTooltip
        title="Visualize in Field, Insights, CSVs"
        arrow
        disableInteractive
      >
        <StyledInfoIcon />
      </StyledTooltip>
    </StyledVisualizeLabel>
  </StyledMetricsLabelRow>
);

const MetricsGrid = ({
  metricsList,
  basketConfig,
  setBasketConfig,
  entityType = null,
}) => {
  if (!Array.isArray(metricsList)) {
    return (
      <Box data-testid="test-newproject-metrics-list-unreadable">
        Error displaying metrics
      </Box>
    );
  }

  if (metricsList.length === 0) {
    return (
      <Box data-testid="test-newproject-metrics-empty">
        There are no metrics available to display
      </Box>
    );
  }

  return (
    <>
      {metricsList.map((metric, index) => (
        <MetricEntry
          key={index}
          metric={metric}
          basketConfig={basketConfig}
          setBasketConfig={setBasketConfig}
          entityType={entityType}
        />
      ))}
    </>
  );
};

export const MemoizedMetricRenderer = ({
  metricsList,
  basketConfig,
  setBasketConfig,
}) => {
  const { isProjectBuilderDemoMode } = useContext(AdminContext);

  return (
    <Box data-testid="test-newproject-metric-renderer">
      <Typography variant="h5">Metrics</Typography>
      {isProjectBuilderDemoMode ? (
        <SubtitleText>
          Review and manage metrics available for this basket
        </SubtitleText>
      ) : (
        <SubtitleText>
          Assign scoring weight for each available metric
        </SubtitleText>
      )}
      <GridLabelRow isProjectBuilderDemoMode={isProjectBuilderDemoMode} />
      {Object.entries(metricsList).map(([entityType, metrics]) => (
        <>
          <EntityLabel>{entityType.toUpperCase()}</EntityLabel>
          <MetricsGrid
            metricsList={metrics}
            basketConfig={basketConfig}
            setBasketConfig={setBasketConfig}
            entityType={entityType}
          />
        </>
      ))}
    </Box>
  );
};

const MetricRenderer = memo(MemoizedMetricRenderer);
export default MetricRenderer;
