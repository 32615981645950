import { createTheme } from '@mui/material/styles';
import { TypographyDefinitions } from './typography';

const headingCommonStyle = {
  fontWeight: TypographyDefinitions.fontWeight.semiBold,
  lineHeight: TypographyDefinitions.lineHeight.regular,
};

const regularStyle = {
  fontWeight: TypographyDefinitions.fontWeight.regular,
  lineHeight: TypographyDefinitions.lineHeight.regular,
};

const titleCommonStyle = {
  fontWeight: TypographyDefinitions.fontWeight.medium,
  lineHeight: TypographyDefinitions.lineHeight.large,
};

export const themeOptions = ({ themeColors }) => ({
  typography: {
    h1: {
      fontSize: 36,
      ...headingCommonStyle,
    },
    h2: {
      fontSize: 25.5,
      fontWeight: TypographyDefinitions.fontWeight.semiBold,
      lineHeight: TypographyDefinitions.lineHeight.medium,
    },
    h3: {
      fontSize: 20.25,
      ...headingCommonStyle,
    },
    h4: {
      fontSize: 18,
      ...headingCommonStyle,
    },
    h5: {
      fontSize: 16,
      fontWeight: TypographyDefinitions.fontWeight.medium,
      lineHeight: TypographyDefinitions.lineHeight.large,
    },
    h6: {
      fontSize: 15,
      fontWeight: TypographyDefinitions.fontWeight.medium,
      lineHeight: TypographyDefinitions.lineHeight.regular,
    },
    title1: {
      fontSize: 16,
      ...titleCommonStyle,
    },
    title2: {
      fontSize: 14,
      ...titleCommonStyle,
    },
    title3: {
      fontSize: 14,
      fontWeight: TypographyDefinitions.fontWeight.medium,
      lineHeight: TypographyDefinitions.lineHeight.regular,
    },
    subtitle1: {
      fontSize: 12,
      ...regularStyle,
    },
    subtitle2: {
      fontSize: 11,
      ...regularStyle,
    },
    body1: {
      fontSize: 16,
      ...regularStyle,
    },
    body2: {
      fontSize: 14,
      ...regularStyle,
    },
    caption: {
      fontSize: 12,
      ...regularStyle,
    },
    label: {
      fontSize: 12,
      fontWeight: TypographyDefinitions.fontWeight.regular,
      lineHeight: TypographyDefinitions.lineHeight.extraLarge,
    },
  },
  components: {
    MuiToggleButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            textTransform: 'none',
            color: themeColors.buttonContentColor,
            backgroundColor: themeColors.buttonBackgroundColor,
            borderColor: `${themeColors.buttonBorderColor}`,
            fontSize: 16,
            fontWeight: 400,
            height: 40,
            padding: '8px 12px',
            '&.Mui-selected': {
              backgroundColor: themeColors.buttonActiveBackgroundColor,
              color: themeColors.buttonActiveContentColor,
              '&:hover': {
                backgroundColor: themeColors.buttonActiveBackgroundColor,
                color: themeColors.buttonActiveContentColor,
              },
            },
            '&:hover': {
              backgroundColor: themeColors.buttonHoverBackgroundColor,
              color: themeColors.buttonHoverContentColor,
            },
            '&.MuiToggleButtonGroup-middleButton': {
              borderColor: `${themeColors.buttonBorderColor}`,
            },
            '&.MuiToggleButtonGroup-lastButton': {
              borderColor: `${themeColors.buttonBorderColor}`,
            },
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: themeColors.buttonBackgroundColor,
            color: themeColors.buttonContentColor,
            borderWidth: 1,
            borderRadius: 3,
            borderColor: themeColors.buttonBorderColor,
            fontWeight: 400,
            fontSize: 14,
            lineHeight: 1.2,
            '.MuiSvgIcon-root': {
              color: themeColors.neutral60,
            },
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
            '&:hover': {
              backgroundColor: themeColors.buttonBorderColor,
              borderColor: themeColors.buttonBorderColor,
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            textTransform: 'none',
            color: themeColors.primaryTextColor,
            backgroundColor:
              themeColors.maptualListFilteringButtonBackgroundColor,
            borderRadius: '3px',
            padding: '8px 16px',
            fontWeight: 400,
          },
        },
        {
          props: { variant: 'text' },
          style: {
            textTransform: 'none',
            width: 'fit-content',
            color: themeColors.textButtonColor,
            padding: '8px 16px',
            ':hover': {
              background: themeColors.buttonBackgroundColor,
            },
            borderRadius: '3px',
          },
        },
        {
          props: { variant: 'utility' },
          style: {
            textTransform: 'none',
            width: 'fit-content',
            color: themeColors.utilityContentColor,
            padding: '8px 16px',
            ':hover': {
              background: themeColors.utilityHoverSurfaceColor,
              color: themeColors.utilityHoverContentColor,
            },
            borderRadius: '3px',
          },
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        elevation8: {
          backgroundColor: themeColors.paperColor,
          backgroundImage: 'none',
          border: `1px solid ${themeColors.buttonBorderColor}`,
          boxShadow: '0px 16px 70px rgba(0, 0, 0, 0.5)',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 32,
          height: 20,
          padding: 0,
        },
        switchBase: {
          padding: 0,
          '&.Mui-checked': {
            transform: 'translateX(12px)',
            '.MuiSwitch-thumb': {
              backgroundColor:
                themeColors.maptualListFilteringButtonBackgroundColor,
            },
            '.MuiSwitch-thumb:before': {
              backgroundImage: `url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33333 8.08333L0 4.75L1.16667 3.58333L3.33333 5.75L8.83333 0.25L10 1.41667L3.33333 8.08333Z' fill='%23FBFCFE'/%3E%3C/svg%3E");`,
            },
            '+.MuiSwitch-track': {
              opacity: 1,
              borderColor:
                themeColors.maptualListFilteringButtonBackgroundColor,
              backgroundColor: 'transparent',
            },
            '&.Mui-disabled': {
              '.MuiSwitch-thumb': {
                backgroundColor: themeColors.buttonBorderColor,
              },
              '.MuiSwitch-thumb:before': {
                backgroundImage: `url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33333 8.08333L0 4.75L1.16667 3.58333L3.33333 5.75L8.83333 0.25L10 1.41667L3.33333 8.08333Z' fill='%235E708D'/%3E%3C/svg%3E");`,
              },
              '+.MuiSwitch-track': {
                borderColor: themeColors.buttonBorderColor,
              },
            },
          },
          '&.Mui-disabled': {
            '.MuiSwitch-thumb': {
              backgroundColor: themeColors.buttonBorderColor,
            },
            '+.MuiSwitch-track': {
              opacity: 1,
              borderColor: themeColors.buttonBorderColor,
            },
          },
        },
        thumb: {
          backgroundColor: themeColors.neutral60,
          ':before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          },
        },
        track: {
          opacity: 1,
          border: `1px solid ${themeColors.neutral60}`,
          backgroundColor: 'transparent',
          borderRadius: 10,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          display: 'grid',
        },
        paper: {
          backgroundColor: themeColors.paperColor,
        },
        menu: {
          '& .MuiPaper-root': {
            backgroundColor: themeColors.paperColor,
            backgroundImage: 'none',
            border: `1px solid ${themeColors.buttonBorderColor}`,
            boxShadow:
              '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)',
          },
          '& .MuiMenuItem-root': {
            padding: '8px 16px',
            color: themeColors.primary99,
            ':hover': {
              backgroundColor: themeColors.segmentSelectionHoverBackgroundColor,
            },
            '+.MuiDivider-root': {
              margin: 0,
              borderColor: themeColors.buttonBorderColor,
            },
            '& .MuiInputLabel-root': {
              color: themeColors.primary99,
            },
          },
        },
        filterForm: {
          '& .MuiFormLabel-root': {
            color: themeColors.neutral60,
          },
        },
        panelHeader: {
          padding: '16px 24px 0',
        },
        panelFooter: {
          padding: '8px 12px',
          '& .MuiButton-root': {
            color: themeColors.csvDownloadColor,
          },
        },
        columnsPanel: {
          padding: '12px 24px 4px',
        },
        columnsPanelRow: {
          margin: '8px 0',
          padding: 0,
          '& .MuiFormControlLabel-root': {
            marginLeft: 0,
          },
          '.MuiSwitch-root': {
            marginRight: 7,
            '.MuiSwitch-switchBase': {
              padding: 0,
              '&.Mui-checked': {
                transform: 'translateX(12px)',
              },
              '.MuiSwitch-thumb': {
                width: 20,
                height: 20,
              },
            },
          },
        },
        columnHeader: {
          color: themeColors.primaryMaptualListFilteringColor,
        },
        withBorderColor: {
          borderColor: themeColors.borderLowContrast,
        },
        row: {
          '&:hover, &.Mui-selected:hover': {
            backgroundColor: themeColors.tableHoverColor,
          },
          '&.Mui-selected': {
            backgroundColor:
              themeColors.segmentSelectionSelectedBackgroundColor,
          },
        },
        cell: {
          color: themeColors.primary99,
          borderColor: themeColors.borderLowContrast,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
          fontWeight: 500,
          color: themeColors.primaryTextColor,
        },
      },
    },
  },
});

export const maptualTheme = createTheme(themeOptions);
