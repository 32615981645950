import { useQuery } from 'react-query';
import { useState, useEffect } from 'react';
import useMaptualContext from './useMaptualContext';
import { getCategoricalDataByHCP } from '../request/categoricalDataRequests';

export const removeDuplicateCategories = (categoricalData) => {
  const lookupTable = {};

  const uniqueCategoricalData = categoricalData.reduce(
    (newCategoricalData, category) => {
      const lookupKey = category.title + category.index;

      if (!lookupTable[lookupKey]) {
        lookupTable[lookupKey] = true;
        return [...newCategoricalData, category];
      }
      return newCategoricalData;
    },
    []
  );
  return uniqueCategoricalData;
};

export const filterCategoricalData = (data) =>
  Object.entries(data).reduce((result, [objectiveId, objectiveData]) => {
    if (!objectiveData.length) {
      return result;
    }
    const filteredCards = objectiveData.reduce((nonEmptyCards, card) => {
      const uniqueCategoricalData = removeDuplicateCategories(
        card.categoricalData
      );

      return [
        ...nonEmptyCards,
        { ...card, categoricalData: uniqueCategoricalData },
      ];
    }, []);
    return { ...result, [objectiveId]: filteredCards };
  }, {});

export const useCategoricalData = () => {
  const { entityId, projectId } = useMaptualContext();
  const { isLoading, data: response } = useQuery(
    `categoricalData-${entityId}${projectId}`,
    async () => {
      try {
        if (!projectId || !entityId) return null;

        const res = await getCategoricalDataByHCP(projectId, entityId);
        return res;
      } catch (err) {
        return err;
      }
    }
  );

  const [categoricalData, setCategoricalData] = useState({});

  useEffect(() => {
    if (!isLoading && response && response.status === 200) {
      const formattedData = response.data;
      setCategoricalData(filterCategoricalData(formattedData));
    }
  }, [isLoading, response]);

  return { isLoading, categoricalData };
};
