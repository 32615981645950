import { maptualApiInstance } from './config';

export function getEntityViewsConfig(marketId) {
  return maptualApiInstance.get(
    `/field/product-lines/none/entity-views-config`,
    {
      params: {
        market_id: marketId,
      },
      timeout: 30000,
    }
  );
}

export function getEntityOverviewMetadata({
  marketId,
  productLineId,
  projectId,
  regionId,
  entityType,
}) {
  return maptualApiInstance.get(
    `field/product-lines/${productLineId}/projects/${projectId}/regions/${regionId}/entity-overview/metadata`,
    {
      params: {
        market_id: marketId,
        entity_type: entityType,
      },
      timeout: 30000,
    }
  );
}

export function getEntityOverviewData({
  productLineId,
  projectId,
  regionId,
  entityType,
  objectiveId,
  cadence,
  entitySubtype,
  filters,
}) {
  return maptualApiInstance.get(
    `field/product-lines/${productLineId}/projects/${projectId}/regions/${regionId}/entity-overview`,
    {
      params: {
        entity_type: entityType,
        objective_id: objectiveId,
        cadence,
        entity_subtype: entitySubtype,
        filters,
      },
      timeout: 60000,
    }
  );
}

export function getEntityDrawerMetadata({
  productLineId,
  marketId,
  projectId,
  regionId,
  entityType,
  entityId,
}) {
  return maptualApiInstance.get(
    `field/product-lines/${productLineId}/projects/${projectId}/regions/${regionId}/entity-overview/drawer-metadata`,
    {
      params: {
        market_id: marketId,
        entity_type: entityType,
        entity_id: entityId,
      },
      timeout: 30000,
    }
  );
}

export function getEntityDrawerChartData({
  productLineId,
  projectId,
  regionId,
  objectiveId,
  chartType,
  metric,
  cadence,
  entityType,
  entityId,
}) {
  return maptualApiInstance.get(
    `field/product-lines/${productLineId}/projects/${projectId}/regions/${regionId}/entity-overview/drawer-chart`,
    {
      params: {
        objective_id: objectiveId,
        metric,
        cadence,
        entity_type: entityType,
        entity_id: entityId,
        chart_type: chartType,
      },
      timeout: 30000,
    }
  );
}

export function getEntityDrawerProfile({
  productLineId,
  marketId,
  projectId,
  regionId,
  entityType,
  entityId,
}) {
  return maptualApiInstance.get(
    `field/product-lines/${productLineId}/projects/${projectId}/regions/${regionId}/entity-overview/drawer-profile`,
    {
      params: {
        market_id: marketId,
        entity_type: entityType,
        entity_id: entityId,
      },
      timeout: 30000,
    }
  );
}

export function getEntityDrawerTimeline({
  productLineId,
  projectId,
  regionId,
  entityType,
  entityId,
}) {
  return maptualApiInstance.get(
    `field/product-lines/${productLineId}/projects/${projectId}/regions/${regionId}/entity-overview/drawer-timeline`,
    {
      params: {
        entity_type: entityType,
        entity_id: entityId,
      },
      timeout: 30000,
    }
  );
}
