import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { clearCache } from '../request/projectRequests';

export const CLEAR_CACHE_STATES = {
  FULFILLED: 'fulfilled',
  PENDING: 'pending',
  ERROR: 'rejected',
  CONFIRM: 'confirm',
  IDLE: 'idle',
};

export const adminViewInitialState = {
  clearCacheProjectName: '',
  clearCacheProjectId: '',
  clearCacheState: CLEAR_CACHE_STATES.IDLE,
};

export const clearProjectCache = createAsyncThunk(
  'CLEAR_PROJECT_CACHE',
  async ({ projectId, clearCacheFlag }) => clearCache(projectId, clearCacheFlag)
);

export const closeClearProjectCacheDialog = createAction(
  'CLOSE_CLEAR_PROJECT_CACHE_DIALOG'
);

export const showConfirmationDialog = createAction(
  'SHOW_CONFIRMATION_DIALOG',
  (projectName, projectId) => ({
    payload: {
      projectName,
      projectId,
    },
  })
);

const adminView = createSlice({
  name: 'adminView',
  initialState: adminViewInitialState,
  extraReducers: (builder) => {
    builder
      .addCase(clearProjectCache.pending, (state) => {
        state.clearCacheState = CLEAR_CACHE_STATES.PENDING;
      })
      .addCase(clearProjectCache.fulfilled, (state) => {
        state.clearCacheState = CLEAR_CACHE_STATES.FULFILLED;
        state.clearCacheProjectName = '';
        state.clearCacheProjectId = '';
      })
      .addCase(clearProjectCache.rejected, (state) => {
        state.clearCacheState = CLEAR_CACHE_STATES.ERROR;
        state.clearCacheProjectName = '';
        state.clearCacheProjectId = '';
      })
      .addCase(closeClearProjectCacheDialog, (state) => {
        state.clearCacheState = CLEAR_CACHE_STATES.IDLE;
      })
      .addCase(showConfirmationDialog, (state, action) => {
        state.clearCacheProjectName = action.payload.projectName;
        state.clearCacheProjectId = action.payload.projectId;
        state.clearCacheState = CLEAR_CACHE_STATES.CONFIRM;
      });
  },
});

export default adminView.reducer;
