import { useContext } from 'react';
import { useQuery } from 'react-query';
import { AppContext } from '../containers/application/appContext';
import * as requests from '../request/projectRequests';
import { deserializeMaptualList } from '../utils/projectViewReducerUtils';
import { getDemoAccountStatus } from '../request/config';
import useFeatureToggles from './useFeatureToggles';

const validateParams = (params) => Object.values(params).every(Boolean);
export const useCuratedList = (project, maptualListMetadata) => {
  const { profile } = useContext(AppContext);

  const maptualListId = maptualListMetadata?.maptualListId;
  const projectId = project?.metadata?.projectId;

  const productLineId = project?.metadata?.productLineId;

  const featureToggles = useFeatureToggles();

  const params = {
    projectId,
    regionId: maptualListId,
  };

  const isDemoAccount = getDemoAccountStatus();
  const isCuratedListOn =
    featureToggles(profile.userGroup, 'curatedList') || isDemoAccount;

  const queryCacheKey = Object.values(params);
  return useQuery(
    queryCacheKey,
    async () => {
      try {
        const response = await requests.getCuratedList(params);

        const maptualList = deserializeMaptualList(response.data);

        return maptualList;
      } catch {
        return [];
      }
    },
    {
      enabled: validateParams({
        ...params,
        productLineId,
        isCuratedListEnabled: isCuratedListOn,
      }),
    }
  );
};
