import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Grow,
  Step,
  Stepper,
  StepButton,
  Typography,
} from '@mui/material';
import * as IngestAction from '../../actions/ingestAction';
import QuickCloseButton from '../../components/generic/quickCloseButton';
import IngestionNewData from '../../components/ingestionpage/ingestionNewData';
import IngestionNameData from '../../components/ingestionpage/ingestionNameData';
import IngestionSelectData from '../../components/ingestionpage/ingestionSelectData';
import IngestionReviewData from '../../components/ingestionpage/ingestionReviewData';
import IngestionKeyColumns from '../../components/ingestionpage/ingestionKeyColumns';
import IngestionConfirmation from '../../components/ingestionpage/ingestionConfirmation';
import IngestionSelectExistingDataSet from '../../components/ingestionpage/ingestionSelectExistingDataSet';
import ProgressionControls from './ProgressionControls';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    'flex-direction': 'column',
  },
  stepButton: {
    margin: '24px 0px',
    padding: 0,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const mapStateToProps = (state) => ({
  ingest: state.ingest,
  isNewDataset: state.ingest.isNewDataset,
  ingestNav: state.ingestNav,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveStep: (index) => dispatch(IngestAction.setActiveStep(index)),
  completeStep: (index) => dispatch(IngestAction.completeStep(index)),
  resetSteps: () => dispatch(IngestAction.resetSteps()),

  newOrAddDataset: (actionType) =>
    dispatch(IngestAction.newOrAddDataset(actionType)),
  validateDatasetName: (ingestModel, pageIndex) =>
    dispatch(IngestAction.validateDatasetName(ingestModel, pageIndex)),
  getExistingDatasets: () => dispatch(IngestAction.getExistingDatasets()),
  onDatasetNameChange: (name) =>
    dispatch(IngestAction.onDatasetNameChange(name)),
  setDataFile: (file, fileName) =>
    dispatch(IngestAction.setDataFile(file, fileName)),
  sampleDataFile: (file) => dispatch(IngestAction.sampleDataFile(file)),
  validateDataFile: (file, dataColumns) =>
    dispatch(IngestAction.validateDataFile(file, dataColumns)),
  setInclude: (index, val) => dispatch(IngestAction.setInclude(index, val)),
  setColFriendlyName: (index, val) =>
    dispatch(IngestAction.setColFriendlyName(index, val)),
  validColumnReview: (ingestModel, pageIndex) =>
    dispatch(IngestAction.validColumnReview(ingestModel, pageIndex)),
  validKeyColumns: (ingestModel, pageIndex) =>
    dispatch(IngestAction.validKeyColumns(ingestModel, pageIndex)),
  setColEncryption: (index, val) =>
    dispatch(IngestAction.setColEncryption(index, val)),
  validJourneyDuration: (durationLabel, pageIndex) =>
    dispatch(IngestAction.validJourneyDuration(durationLabel, pageIndex)),
  confirmDataUpload: (dataset, file, isNewDataset, percentageUpdater) =>
    dispatch(
      IngestAction.confirmDataUpload(
        dataset,
        file,
        isNewDataset,
        percentageUpdater
      )
    ),
  updateDataUploadProgress: (percentage) =>
    dispatch(IngestAction.updateDataUploadProgress(percentage)),
  getExistingColumns: () => dispatch(IngestAction.getExistingColumns()),
  updateColumnMeta: (metaData) =>
    dispatch(IngestAction.updateColumnMeta(metaData)),
  finalizeColumnMeta: () => dispatch(IngestAction.finalizeColumnMeta()),

  skipValidation: () => dispatch(IngestAction.skipValidation()),
  clearErrorMsg: () => dispatch(IngestAction.clearErrorMsg()),
  gotoNextStep: () => dispatch(IngestAction.gotoNextStep()),
});

export const INGESTION_STEPS_MAP_NEW = [
  'CHOOSE_DATASET',
  'SET_DATASET_NAME',
  'SELECT_DATASET_FILE_UPLOAD',
  'REVIEW_DATASET',
  'IDENTIFY_COLUMNS',
  'CONFIRMATION',
];

export const INGESTION_STEPS_MAP_ADD = [
  'CHOOSE_DATASET',
  'SELECT_EXISTING_DATASET',
  'SELECT_DATASET_FILE_UPLOAD',
  'CONFIRMATION',
];

const IngestionSteps = {
  CHOOSE_DATASET: {
    title: 'New or Add Data',
    content: 'Guided Data Ingestion for Historical Data',
    page: (props) => <IngestionNewData {...props} />,
  },
  SET_DATASET_NAME: {
    title: 'Dataset Name',
    content: 'Please Enter a Unique Name for your Dataset',
    page: (props) => <IngestionNameData {...props} />,
  },
  SELECT_DATASET_FILE_UPLOAD: {
    title: 'Select Data File',
    content: 'Select a data file you would like to preview and upload',
    page: (props) => <IngestionSelectData {...props} />,
  },
  REVIEW_DATASET: {
    title: 'Review and Customize Data',
    content: 'Please review the data you are about the upload',
    page: (props) => <IngestionReviewData {...props} />,
  },
  IDENTIFY_COLUMNS: {
    title: 'Identify Key Columns',
    content: 'Please identify the required key columns',
    page: (props) => <IngestionKeyColumns {...props} />,
  },
  CONFIRMATION: {
    title: 'Confirmation',
    content:
      'Please confirm everything and then we will get on uploading your data',
    page: (props) => <IngestionConfirmation {...props} />,
  },
  SELECT_EXISTING_DATASET: {
    title: 'Select Existing Dataset',
    content: 'Select the existing for which to add data',
    page: (props) => <IngestionSelectExistingDataSet {...props} />,
  },
};

function IngestionGuide(props) {
  const classes = useStyles();
  const { isNewDataset, ingestNav, resetSteps, setActiveStep, skipValidation } =
    props;

  const ingestionStepsMap = isNewDataset
    ? INGESTION_STEPS_MAP_NEW
    : INGESTION_STEPS_MAP_ADD;

  const allStepsCompleted = () =>
    ingestNav.completedStepsCount === ingestNav.stepsCount;

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const performCompletion = {
    action: () => {
      skipValidation();
    },
  };

  const handleComplete = () => {
    performCompletion.action();
  };

  const renderSteps = (index, ...renderProps) =>
    isNewDataset
      ? IngestionSteps[ingestionStepsMap[index]].page(...renderProps)
      : IngestionSteps[ingestionStepsMap[index]].page(...renderProps);

  return (
    <div className={classes.root}>
      <QuickCloseButton />
      {renderSteps(ingestNav.activeStep, {
        ...props,
        myIndex: ingestNav.activeStep,
        onComplete: performCompletion,
      })}
      <div>
        {allStepsCompleted() ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={resetSteps}>Reset</Button>
          </div>
        ) : (
          <ProgressionControls handleComplete={handleComplete} />
        )}
      </div>
      <Stepper activeStep={ingestNav.activeStep}>
        {ingestionStepsMap.map((key, index) => (
          <Grow in timeout={500} key={key}>
            <Step key={key}>
              <StepButton
                className={classes.stepButton}
                onClick={() => handleStep(index)}
                completed={ingestNav.completedSteps[index]}
              >
                {IngestionSteps[key].title}
              </StepButton>
            </Step>
          </Grow>
        ))}
      </Stepper>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(IngestionGuide);
