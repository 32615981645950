/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */

import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Fade from '@mui/material/Fade';
import { ProjectContext } from '../../contexts/projectContexts';
import Section from './section';
import SemiOpaqueChip from '../chips/semiOpaqueChip';
import CollapsibleCard from '../cards/collapsibleCard';
import CheckListItem from '../lists/checkListItem';
import IconedListItem from '../lists/iconedListItem';
import IconedButton from '../input/iconedButton';
import SimpleCard from '../cards/simpleCard';
import TimeSeries from '../datavisualization/timeSeries';
import RadarChart from '../datavisualization/radarChart';
import DataLabel from '../labels/dataLabel';
import ToggleContent from '../cards/toggleContent';
import DonutChart from '../datavisualization/donutChart';
import BarGraphLabel from '../labels/barGraphLabel';
import ContentBox from '../cards/contentBox';
import Journey from '../datavisualization/journey';
import DropDownContent from '../cards/dropDownContent';
import JourneyFunnel from '../datavisualization/journeyFunnel';
import DashboardCard from '../datavisualization/dashboardCard';
import { XGridTables } from '../tables/xGridTables';
import ExpandButton from '../input/expandButton';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  horRoot: {
    flexGrow: 1,
    height: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  horContainer: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  vertContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export function Content(props) {
  const classes = useStyles();
  const { customeStyles = {}, match, containerStyle = {} } = props;
  const { content, isColumn = true } = props;
  const context = useContext(ProjectContext);

  return (
    <Fade in {...{ timeout: 1000 }}>
      <div
        className={isColumn ? classes.root : classes.horRoot}
        style={containerStyle}
      >
        {content && Object.keys(content)
          ? Object.keys(content).map((components) => {
              switch (components) {
                case 'timeSeriesGraphs':
                  return (
                    <div
                      className={classes.horContainer}
                      style={{ ...customeStyles }}
                      key={components}
                    >
                      {content[components].map((item, i) => (
                        <TimeSeries {...item} match={match} key={i} />
                      ))}
                    </div>
                  );

                case 'semiOpaqueChips':
                  return (
                    <div
                      className={classes.horContainer}
                      style={{ ...customeStyles, marginBottom: '1vh' }}
                      key={components}
                    >
                      {content[components].map((item, i) => (
                        <SemiOpaqueChip {...item} match={match} key={i} />
                      ))}
                    </div>
                  );

                case 'collapsibleCards':
                  return (
                    <div
                      className={classes.horContainer}
                      style={{ alignItems: 'flex-start', ...customeStyles }}
                      key={components}
                    >
                      {content[components].map((item, i) => (
                        <CollapsibleCard {...item} match={match} key={i} />
                      ))}
                    </div>
                  );

                case 'simpleCards':
                  return (
                    <div
                      className={classes.horContainer}
                      style={{ alignItems: 'flex-start', ...customeStyles }}
                      key={components}
                    >
                      {content[components].map((item, i) => (
                        <SimpleCard {...item} match={match} key={i} />
                      ))}
                    </div>
                  );

                case 'xGridTables':
                  return (
                    <div
                      className={classes.vertContainer}
                      style={{ height: '100%', ...customeStyles }}
                      key={components}
                    >
                      {content[components].map((item, i) => (
                        <XGridTables {...item} match={match} key={i} />
                      ))}
                    </div>
                  );

                case 'checkList':
                  return (
                    <div
                      className={classes.vertContainer}
                      style={{ ...customeStyles }}
                      key={components}
                    >
                      {content[components].map((item, i) => (
                        <CheckListItem {...item} match={match} key={i} />
                      ))}
                    </div>
                  );

                case 'iconedList':
                  return (
                    <div
                      className={classes.vertContainer}
                      style={{ ...customeStyles }}
                      key={components}
                    >
                      {content[components].map((item, i) => (
                        <IconedListItem {...item} match={match} key={i} />
                      ))}
                    </div>
                  );

                case 'buttons':
                  return (
                    <div
                      className={
                        isColumn ? classes.vertContainer : classes.horContainer
                      }
                      style={{ ...customeStyles }}
                      key={components}
                    >
                      {content[components].map((item, i) => (
                        <IconedButton {...item} match={match} key={i} />
                      ))}
                    </div>
                  );
                case 'expandButton':
                  return (
                    <div
                      className={
                        isColumn ? classes.vertContainer : classes.horContainer
                      }
                      style={{ ...customeStyles }}
                      key={components}
                    >
                      <ExpandButton
                        isExpanded={context.isAnalysisExpanded}
                        handleExpandClick={context.setAnalysisExpanded}
                      />
                    </div>
                  );
                case 'sections':
                  return (
                    <div
                      className={classes.vertContainer}
                      style={{ ...customeStyles }}
                      key={components}
                    >
                      {content[components].map((item, i) => (
                        <Section {...item} match={match} key={i} />
                      ))}
                    </div>
                  );

                case 'labels':
                  return (
                    <div
                      className={
                        isColumn ? classes.vertContainer : classes.horContainer
                      }
                      style={isColumn ? {} : { marginRight: '1rem' }}
                      key={components}
                    >
                      {content[components].map((item, i) => (
                        <DataLabel
                          {...item}
                          isColumn={isColumn}
                          match={match}
                          key={i}
                        />
                      ))}
                    </div>
                  );
                case 'carousels':
                  return (
                    <div className={classes.vertContainer} key={components}>
                      <ToggleContent
                        toggleContent={content[components]}
                        isColumn={isColumn}
                        match={match}
                      />
                    </div>
                  );
                case 'pieGraphs':
                  return (
                    <div
                      className={
                        isColumn ? classes.vertContainer : classes.horContainer
                      }
                      style={{ ...customeStyles }}
                      key={components}
                    >
                      {content[components].map((item, i) => (
                        <DonutChart
                          {...item}
                          isColumn={isColumn}
                          match={match}
                          key={i}
                        />
                      ))}
                    </div>
                  );
                case 'radarGraphs':
                  return (
                    <div
                      className={
                        isColumn ? classes.vertContainer : classes.horContainer
                      }
                      style={{ ...customeStyles }}
                      key={components}
                    >
                      {content[components].map((item, i) => (
                        <RadarChart
                          {...item}
                          isColumn={isColumn}
                          match={match}
                          key={i}
                        />
                      ))}
                    </div>
                  );
                case 'dashboardCards':
                  return (
                    <div
                      className={classes.horContainer}
                      style={{ ...customeStyles }}
                      key={components}
                    >
                      {content[components].map((item, i) => (
                        <DashboardCard {...item} match={match} key={i} />
                      ))}
                    </div>
                  );
                case 'contentBoxes':
                  return (
                    <div
                      className={classes.horContainer}
                      style={{ ...customeStyles, alignItems: 'flex-start' }}
                      key={components}
                    >
                      {content[components].map((item, i) => (
                        <ContentBox
                          {...item}
                          isColumn={isColumn}
                          match={match}
                          key={i}
                          index={i}
                        />
                      ))}
                    </div>
                  );
                case 'barGraphs':
                case 'barGraphLabels':
                  return (
                    <div
                      className={
                        isColumn ? classes.vertContainer : classes.horContainer
                      }
                      style={{ ...customeStyles }}
                      key={components}
                    >
                      {content[components].map((item, i) => (
                        <BarGraphLabel
                          {...item}
                          isColumn={isColumn}
                          match={match}
                          key={i}
                        />
                      ))}
                    </div>
                  );
                case 'dropDown':
                  return (
                    <DropDownContent
                      {...content[components]}
                      match={match}
                      key={0}
                    />
                  );
                case 'journeySequence': {
                  const journeySequence = {
                    journeySequence: [...content[components]],
                  };
                  return <Journey {...journeySequence} match={match} key={0} />;
                }
                case 'journeyFunnel': {
                  const journeyFunnel = {
                    journeyFunnel: [...content[components]],
                  };
                  return (
                    <JourneyFunnel {...journeyFunnel} match={match} key={0} />
                  );
                }
                default:
                  return null;
              }
            })
          : null}
      </div>
    </Fade>
  );
}
