import React from 'react';
import Chart from 'react-apexcharts';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import { InfoOutlined } from '@mui/icons-material';

const NoChart = styled(Box)(({ theme: { themeColors } }) => ({
  padding: '24px',
  background: themeColors.contentCardBackgroundColor,
  margin: '16px',
  borderRadius: '3px',
  color: themeColors.emptyText,
}));

const NoChartTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gridGap: '10px',
  justifyContent: 'center',
  marginBottom: 16,
}));

const ChartWrapper = styled('div')(({ theme: { themeColors } }) => ({
  alignSelf: 'center',
  width: '100%',
  padding: '0 15px',
  '.apexcharts-tooltip.apexcharts-theme-dark': {
    backgroundColor: themeColors.segmentSelectionSelectedBackgroundColor,
    opacity: 1,
    lineHeight: 1,
    fontSize: 12,
    borderRadius: 5,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    'span.tooltip-label': {
      color: themeColors.primaryMaptualListFilteringColor,
    },
  },
}));

export const seriesIsEmpty = (series) => !(series?.data[0] && series?.data[1]);

export default function InsightsAreaChart({ series, isPercentage = false }) {
  const { themeColors } = useTheme();
  const options = {
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    chart: {
      redrawOnParentResize: true,
      toolbar: {
        show: false,
      },
      sparkline: { enabled: true },
    },

    forecastDataPoints: {
      count: 1,
      fillOpacity: 1,
      strokeWidth: 2,
      dashArray: 6,
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      width: 2,
      dashArray: 0,
      colors: [themeColors.textButtonColor],
    },
    yaxis: {
      min: 0,
      max: Math.max(...series.data) * 1.1,
      labels: {
        show: false,
      },
      floating: true,
      crosshairs: {
        show: false,
      },
    },
    colors: [themeColors.insightsAreaChartPrimary],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 0.5,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0,
        colorStops: [
          {
            offset: 0,
            color: themeColors.insightsAreaChartPrimary,
            opacity: 1,
          },
          {
            offset: 70,
            color: themeColors.insightsAreaChartSecondary,
            opacity: 0.24,
          },
          {
            offset: 100,
            color: themeColors.insightsAreaChartSecondary,
            opacity: 0,
          },
        ],
      },
      opacity: 0.75,
    },
    xaxis: {
      labels: {
        show: false,
      },
      crosshairs: {
        show: true,
      },
      numeric: true,
    },
    markers: {
      size: 5,
      colors: themeColors.textButtonColor,
      strokeWidth: 0,
      hover: {
        sizeOffset: 3,
      },
    },
    tooltip: {
      enabled: true,
      shared: false,
      followCursor: false,
      intersect: false,
      theme: 'dark',
      style: {
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: false,
        formatter: undefined,
      },
      y: {
        show: true,
        formatter: (value) =>
          `<span class="tooltip-value">${value}${
            isPercentage ? '%' : ''
          }</span>`,
        title: {
          formatter: (seriesName, { dataPointIndex }) => {
            if (dataPointIndex === 0) {
              return `<span class="tooltip-label">Historical</span>`;
            }
            if (dataPointIndex === 1) {
              return `<span class="tooltip-label">Recent</span>`;
            }
            return `<span class="tooltip-label">Predicted</span>`;
          },
        },
      },
      marker: {
        show: false,
      },
      fixed: {
        enabled: false,
      },
    },
  };

  if (seriesIsEmpty(series)) {
    return (
      <NoChart>
        <NoChartTitle variant="h5">
          <InfoOutlined />
          Graph not available for HCP.
        </NoChartTitle>
        <Typography variant="subtitle1">
          We currently do not have enough data to generate a graph for this HCP
        </Typography>
      </NoChart>
    );
  }
  return (
    <ChartWrapper>
      <Chart series={[series]} options={options} width="100%" height="200px" />
    </ChartWrapper>
  );
}
