import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../../appContext';
import { useLatestTimestamp } from '../../../pulse/context/hooks/useLatestTimestamp';
import { useProjectTargetProducts } from '../../../pulse/context/hooks/useProjectTargetProducts';

export const useCallPlanContext = () => {
  const { projectList } = useContext(AppContext);
  const { projectId, maptualListId: regionId } = useParams();

  const projectFromParams = projectList.find(
    (item) => item && item.projectId && item.projectId === projectId
  );
  const productLineId = projectFromParams?.productLineId || '';
  const { data: latestCurationTimestamp, isLoading: isTimestampLoading } =
    useLatestTimestamp(productLineId, regionId, true);
  const { data: projectTargetProducts, isLoading: isProductsLoading } =
    useProjectTargetProducts(productLineId, projectId);

  return {
    productLineId,
    latestCurationTimestamp,
    isTimestampLoading,
    projectTargetProducts,
    isProductsLoading,
    projectId,
  };
};
