/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { darkThemeColors } from '../../styles/colorConfig';
import LinkController from '../../containers/links/linkController';

const useStyles = makeStyles((theme) => ({
  root: {},
  primaryText: {
    fontSize: 17,
    fontWeight: 700,
    color: theme.palette.text.secondary,
    margin: '0.5rem',
  },
  primaryTextHyper: {
    fontSize: 17,
    fontWeight: 700,
    margin: '0.5rem',
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
      textDecoration: 'underline',
    },
  },
  secondaryText: {
    fontSize: 13,
    fontWeight: 400,
    margin: '0.5rem',
    marginLeft: 0,
    color: theme.palette.text.secondary,
  },
  secondaryTextHyper: {
    fontSize: 13,
    fontWeight: 400,
    margin: '0.5rem',
    marginLeft: 0,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
      textDecoration: 'underline',
    },
  },
  tertiaryText: {
    fontSize: 11,
    fontWeight: 500,
    margin: '0.5rem',
    marginLeft: 0,
    color: darkThemeColors.tertiaryColor,
  },
  tertiaryTextHyper: {
    fontSize: 11,
    fontWeight: 500,
    margin: '0.5rem',
    marginLeft: 0,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
      textDecoration: 'underline',
    },
  },
}));

export default function SectionLabel({
  text,
  type,
  customStyle = null,
  match,
}) {
  const classes = useStyles();

  const controllerRef = useRef(null);
  const controllerClick = () => {
    if (controllerRef) controllerRef.current.click();
  };

  return text && type ? (
    typeof text === 'string' ? (
      <Typography
        variant="h5"
        align="left"
        className={classes[type]}
        style={customStyle || {}}
      >
        {text}
      </Typography>
    ) : (
      <>
        <Typography
          variant="h5"
          align="left"
          className={classes[`${type}Hyper`]}
          style={customStyle || {}}
          onClick={controllerClick}
        >
          {text.primaryText ? text.primaryText.toUpperCase() : ''}
        </Typography>
        <LinkController
          ref={controllerRef}
          type={type}
          {...text}
          match={match}
        />
      </>
    )
  ) : null;
}
