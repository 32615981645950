import styled from '@emotion/styled';
import Box from '@mui/material/Box';

const PrimaryContentBox = styled(Box)(({ theme: { themeColors } }) => ({
  borderRadius: 10,
  backgroundColor: themeColors.contentCardAltBackgroundColor,
  border: '1px solid',
  borderColor: themeColors.contentCardBorderColor,
}));

export default PrimaryContentBox;
