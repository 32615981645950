/* eslint-disable no-restricted-syntax */
import React, { useContext, useState, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useHcpCrmData } from './hooks/useHcpCrmData';
import { PulseContext } from '../../../../context/PulseContext';
import ProgressBar from '../../../salesReps/repTable/table/row/progressBar/ProgressBar';
import { NumberContainer } from './numberContainer';
import { useHcpMetricData } from './hooks/useHcpMetricData';
import { GraphCellContainer } from '../../../../../../../shared/graphs/graphCellContainer';
import {
  getTrendValue,
  formatChartLabels,
  getObjectiveProducts,
  getObjectiveBasketName,
  formatLastContactedDate,
} from '../../../../shared/utils';
import { Row } from '../../../../../../../shared/entitiesTable/Row';

const StyledCardColumnSection = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  gap: 48,
  alignItems: 'center',
  padding: 16,
  borderLeft: `1px solid ${themeColors.buttonBorderColor}`,
  borderRight: `1px solid ${themeColors.buttonBorderColor}`,
  minWidth: 175,
}));

const GraphCellContainerProvider = ({
  row,
  isLoading,
  data,
  metric,
  cadence,
  objectiveBasketName,
  metricType,
}) => {
  const MemoizedData = useMemo(
    () =>
      data?.length > 0
        ? {
            data: data.map((v) => v.value),
            trend: getTrendValue(
              data[data.length - 2].value,
              data[data.length - 1].value
            ),
          }
        : {},
    [data, metricType]
  );
  const MemoizedLabels = useMemo(
    () =>
      formatChartLabels(
        cadence,
        data?.map((v) => v.timestamp)
      ),
    [cadence, data]
  );
  const dataKeyTitle = metricType.charAt(0).toUpperCase() + metricType.slice(1);
  return (
    <GraphCellContainer
      key={`${metricType}-${objectiveBasketName}`}
      isLoading={isLoading}
      basicColor
      row={MemoizedData}
      valueType={metricType === 'volume' ? 'number' : 'percentNotMultiplied'}
      graphType="line"
      chartLabels={MemoizedLabels}
      graphTitle={`${row.entity.name} ${objectiveBasketName} ${dataKeyTitle} ${metric?.name}`}
      yTitle={`${objectiveBasketName} ${dataKeyTitle} ${metric?.name}`}
      tooltipDataPointTitle={`${dataKeyTitle} ${metric?.name}`}
    />
  );
};

const EmptyGraph = () => (
  <GraphCellContainer
    key="empty"
    isLoading={false}
    row={{ data: [0], trend: { status: 1, trend: null } }}
    valueType="number"
    graphType="line"
  />
);

const getClosestMetricToCadence = ({ metricCadences, targetCadence }) => {
  if (!metricCadences || metricCadences.length === 0) return null;
  const exactMetric = metricCadences.find(
    (metricCadence) => metricCadence.value === targetCadence
  );
  if (exactMetric) {
    return exactMetric;
  }

  return metricCadences[0];
};

const rxGraphs = (
  data,
  metricType,
  row,
  metric,
  cadence,
  isLoading,
  selectedMetric,
  projectTargetProducts,
  objectiveBasketName
) => {
  const res = [];
  if (!isLoading && !data) return null;
  if (!projectTargetProducts || !projectTargetProducts.length) return null;
  if ((!selectedMetric || !data) && !isLoading) {
    res.push(<EmptyGraph key={`${metricType}-${objectiveBasketName}`} />);
  } else {
    res.push(
      <GraphCellContainerProvider
        key={`${metricType}-${objectiveBasketName}`}
        row={row}
        isLoading={isLoading}
        data={data}
        metric={metric}
        cadence={cadence}
        objectiveBasketName={objectiveBasketName}
        metricType={metricType}
      />
    );
  }
  return <>{res.map((Graph) => Graph)}</>;
};

export const HcpRowProvider = ({
  objectiveId,
  metric = {},
  metricType,
  cadence,
  row,
  isLoading,
  regionName,
}) => {
  const { projectId, territoryId } = useParams();
  const [nonCuratedCalls, setNonCuratedCalls] = useState();
  const [curatedCalls, setCuratedCalls] = useState();
  const [curatedCount, setCuratedCount] = useState();
  const [volumeData, setVolumeData] = useState(null);
  const [shareData, setShareData] = useState(null);
  const [volumeGraphs, setVolumeGraphs] = useState(null);
  const [shareGraphs, setShareGraphs] = useState(null);

  const {
    productLineId,
    projectTargetProducts,
    isProductsLoading,
    latestCurationTimestamp,
  } = useContext(PulseContext);

  const objectiveProducts = getObjectiveProducts(
    projectTargetProducts,
    objectiveId
  );

  const { data: crmData, isLoading: isCrmDataLoading } = useHcpCrmData({
    productLineId,
    projectId,
    regionId: territoryId,
    objectiveId,
    entityId: row.entity.id,
    products: objectiveProducts,
    latestCurationTimestamp,
  });

  const selectedMetric = getClosestMetricToCadence({
    metricCadences: metric?.cadence,
    targetCadence: cadence,
  });

  const { data: metricData, isLoading: isMetricDataLoading } = useHcpMetricData(
    {
      productLineId,
      projectId,
      objectiveId,
      hcpId: row.entity.id,
      metricId: selectedMetric?.metricId,
      metricType: metric?.type,
      products: objectiveProducts,
      cadence,
      timestamp: selectedMetric?.lastTimestamp,
      tableName: selectedMetric?.tableName,
    }
  );

  useEffect(() => {
    if (metricData?.volume && metricData?.share) {
      setVolumeData(metricData.volume);
      setShareData(metricData.share);
    }
  }, [metricData]);

  useEffect(() => {
    setVolumeGraphs(
      rxGraphs(
        volumeData,
        'volume',
        row,
        metric,
        cadence,
        isMetricDataLoading || isProductsLoading,
        selectedMetric,
        projectTargetProducts,
        getObjectiveBasketName(projectTargetProducts, objectiveId)
      )
    );
  }, [volumeData, objectiveProducts.join(), isMetricDataLoading]);

  useEffect(() => {
    setShareGraphs(
      rxGraphs(
        shareData,
        'share',
        row,
        metric,
        cadence,
        isMetricDataLoading || isProductsLoading,
        selectedMetric,
        projectTargetProducts,
        getObjectiveBasketName(projectTargetProducts, objectiveId)
      )
    );
  }, [shareData, objectiveProducts.join(), isMetricDataLoading]);

  useEffect(() => {
    if (
      crmData &&
      crmData?.nonCuratedCalls !== undefined &&
      crmData?.curatedCalls !== undefined &&
      crmData?.timesCurated !== undefined
    ) {
      setNonCuratedCalls(crmData.nonCuratedCalls);
      setCuratedCalls(crmData.curatedCalls);
      setCuratedCount(crmData.timesCurated);
    }
  }, [crmData]);

  const handleRowClick = () => {
    window.open(
      `/sphere/projects/${projectId}/entities/${row.id}/maptualList/${territoryId}/${regionName}`,
      '_blank'
    );
  };

  return (
    <Row
      row={row}
      objectiveId={objectiveId}
      cadence={cadence}
      key={row.id}
      isShimmer={isLoading}
      onRowClick={handleRowClick}
      showPowerscore
      rowTitle={row.entity.name}
      productLineId={productLineId}
      showCustomListIcon
      rowSubtitle={`${row.entity?.specialty} - ${formatLastContactedDate(
        row.entity?.lastContactedDate
      )}`}
    >
      <StyledCardColumnSection>
        <NumberContainer
          isLoading={isCrmDataLoading || isProductsLoading}
          value={nonCuratedCalls + curatedCalls || 0}
        />
        <ProgressBar
          isLoading={isCrmDataLoading || isProductsLoading}
          numerator={curatedCalls}
          denominator={curatedCount}
        />
      </StyledCardColumnSection>

      <StyledCardColumnSection>
        {metricType?.includes('volume') && volumeGraphs}
        {metricType?.includes('share') && shareGraphs}
      </StyledCardColumnSection>
    </Row>
  );
};
