import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { TerritoryOverviewContext } from './territoryOverviewContext';
import { Settings } from './components/Settings';
import { DemandTable } from './demand/components/DemandTable';
import { Disclaimer } from './components/Disclaimer';
import {
  isTerritoryOverviewItemsEmpty,
  isTerritoryOverviewProductEmpty,
} from './utils';
import { SupplyTable } from './supply/components/SupplyTable';
import { SupplyChart } from './supply/components/SupplyChart';
import { SkeletonChartContent } from '../../components/generic/SkeletonChartContent';
import { Distribution, disclaimerOptions } from './constants';
import { SkeletonBars } from '../../components/generic/skeletonBars';
import { RegionSelection } from '../application/appViews/field/navigation/regionSelection/RegionSelection';

const ContainerBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  height: '100%',
}));

const Content = styled(Box)(() => ({
  width: '100%',
  minWidth: 560,
  padding: '24px 16px',
  display: 'flex',
  flexDirection: 'column',
}));

const Title = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 18,
  textAlign: 'left',
  marginTop: 14,
}));

const TableContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
}));

const ChartContainer = styled(Box)(() => ({
  marginBottom: -20,
  overflow: 'hidden',
}));

const SupplyContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  overflow: 'auto',
}));

const TitleContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const RegionButtonContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: 34,
}));

export const Container = () => {
  const {
    data,
    metadataStatus,
    isDataLoading,
    isMetadataLoading,
    selectedTimeframeID,
    distributionType,
    distributionTypesAvailable,
    projectName,
    regionId,
    territoryName,
  } = useContext(TerritoryOverviewContext);

  const [disclaimerText, setDisclaimerText] = useState();
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    if (regionId && projectName) {
      setShowSettings(false);
      setDisclaimerText(null);
    }
  }, [regionId, projectName]);

  useEffect(() => {
    if (
      (disclaimerText &&
        disclaimerText === disclaimerOptions.NO_CHILD_REGIONS) ||
      disclaimerText === disclaimerOptions.NO_CHILD_REGIONS
    ) {
      setDisclaimerText(null);
    }

    if (disclaimerText || !data || !selectedTimeframeID) {
      return;
    }

    const regionHasNoChildren = isTerritoryOverviewItemsEmpty(
      data,
      selectedTimeframeID
    );

    const productIsEmpty = isTerritoryOverviewProductEmpty(
      data,
      selectedTimeframeID
    );

    const isDistributionAvailable =
      distributionTypesAvailable?.supply || distributionTypesAvailable?.demand;

    if (regionHasNoChildren) {
      setDisclaimerText(disclaimerOptions.NO_CHILD_REGIONS);
    } else if (productIsEmpty || !isDistributionAvailable) {
      setDisclaimerText(disclaimerOptions.NO_PRODUCT_DATA);
    } else {
      setDisclaimerText(null);
    }
  }, [data, selectedTimeframeID, distributionTypesAvailable]);

  useEffect(() => {
    const isDistributionAvailable =
      distributionTypesAvailable?.supply || distributionTypesAvailable?.demand;

    if (!isMetadataLoading && metadataStatus === 200) {
      setShowSettings(true);
      setDisclaimerText(null);
    } else if (metadataStatus === 204) {
      setShowSettings(false);
      setDisclaimerText(disclaimerOptions.INVALID_REGION);
    } else if (!isMetadataLoading && !isDistributionAvailable) {
      setShowSettings(false);
      setDisclaimerText(disclaimerOptions.NO_PRODUCT_DATA);
    } else {
      setShowSettings(false);
      setDisclaimerText(null);
    }
  }, [isMetadataLoading, metadataStatus]);

  const [isDemandLoading, setIsDemandLoading] = useState(true);
  const [isSupplyLoading, setIsSupplyLoading] = useState(true);

  return (
    <ContainerBox
      data-testid="territory-overview-container"
      className="intercom-field-territory-overview-page"
    >
      <Content>
        <RegionButtonContainer>
          <RegionSelection regionName={territoryName} />
        </RegionButtonContainer>
        <TitleContainer>
          <Title>Territory Overview</Title>
        </TitleContainer>

        <TableContainer>
          {!isMetadataLoading ? (
            <>
              {showSettings && (
                <Settings
                  setIsDemandLoading={setIsDemandLoading}
                  setIsSupplyLoading={setIsSupplyLoading}
                />
              )}

              {disclaimerText && !isDataLoading && (
                <Disclaimer disclaimerText={disclaimerText} />
              )}

              {isDataLoading && <SkeletonBars />}

              {!isDataLoading && !disclaimerText && (
                <>
                  {distributionType === Distribution.DEMAND && (
                    <DemandTable
                      isDemandLoading={isDemandLoading}
                      setIsDemandLoading={setIsDemandLoading}
                    />
                  )}

                  {distributionType === Distribution.SUPPLY && (
                    <SupplyContainer>
                      <ChartContainer data-testid="supply-chart">
                        <SupplyChart isLoading={isSupplyLoading} />
                      </ChartContainer>
                      <SupplyTable
                        isLoading={isSupplyLoading}
                        setIsLoading={setIsSupplyLoading}
                      />
                    </SupplyContainer>
                  )}
                </>
              )}
            </>
          ) : (
            <SkeletonChartContent />
          )}
        </TableContainer>
      </Content>
    </ContainerBox>
  );
};
