import React, { useMemo, useState } from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import { Switch as MuiSwitch } from '@mui/material';
import { useQuery } from 'react-query';
import { SentryRoute } from '../../../sentryRoute';
import { RBAC_PERMISSIONS, ROUTES } from '../../../constants';
import LoginHandler from '../loginHandler';

import NavBar from '../../../components/app/navBar';
import { ContentWrapper } from '../contentWrapper';
import ProjectViewer from '../../projectviewer/projectViewer';
import PhysicianView from '../../physicianview/physicianView';
import IngestionGuide from '../../ingestionguide/IngestionGuide';
import DataConnectorView from '../../../components/dataconnectors/dataconnectors';
import ConstructionView from '../../../components/constructionview/constructionView';
import { UserManagementView } from '../../../components/usermanagement/userManagementView';
import { NAVBAR_DRAWER_WIDTH } from './constants';
import CurationReports from './curation';
import { CsvDeliveryContainer } from './csvDelivery/CsvDeliveryContainer';
import { AdminContext } from './adminContext';
import { getDemoAccountStatus } from '../../../request/config';
import { FeatureToggleViewProvider } from '../../../components/featureToggle/FeatureToggleViewProvider';
import { TestStatusIcon } from '../../../components/testDetails/testStatusIcon';
import { ConfigViewProvider } from '../../../components/configview/configViewProvider';
import { getTestSuiteStatus } from '../../../request/projectRequests';
import DataReport from './dataReport';
import { useIsAuthorized } from '../../../hooks/useIsAuthorized';

export const StyledHeader = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px 3vw 15px 15px',
  borderBottom: '1px solid',
  borderColor: themeColors.borderMidContrast,
}));

export const UserGroupTitle = styled('div')(({ theme: { themeColors } }) => ({
  color: themeColors.menuTextColor,
  fontWeight: 500,
  fontSize: 20,
}));

const Container = styled('div')(({ theme: { themeColors } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: NAVBAR_DRAWER_WIDTH,
  background: themeColors.mainBackground,
  borderColor: themeColors.borderMidContrast,
  overflowX: 'auto',
}));

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 140px;
  justify-content: space-between;
`;

export const Admin = ({ profile, appView }) => {
  const isDemoAccount = getDemoAccountStatus();

  const hasProjectAdminPermissions = useIsAuthorized([
    RBAC_PERMISSIONS.PROJECTS_ADMIN,
  ]);

  const getOverallTestSuiteStatuses = async () => {
    const statuses = await getTestSuiteStatus();
    return statuses;
  };

  const [isProjectBuilderDemoMode, setIsProjectBuilderDemoMode] =
    useState(true);
  const [
    showProjectBuilderDemoModeToggle,
    setShowProjectBuilderDemoModeToggle,
  ] = useState(false);

  const contextValues = useMemo(
    () => ({
      isProjectBuilderDemoMode,
      setIsProjectBuilderDemoMode,
      setShowProjectBuilderDemoModeToggle,
    }),
    [
      isProjectBuilderDemoMode,
      setIsProjectBuilderDemoMode,
      setShowProjectBuilderDemoModeToggle,
    ]
  );

  const { isLoading: isTestSuiteStatusLoading, data: testSuiteStatusResponse } =
    useQuery([`quality-report-checks`], () => getOverallTestSuiteStatuses());

  const formatToolTipMessage = (failedTests, status) =>
    (!failedTests || failedTests.length === 0) && status !== 'warning'
      ? 'All test suites passed'
      : `${formatFailedTests(failedTests)} test suites failed`;

  const formatFailedTests = (failedTests) => {
    if (failedTests.length === 2) return failedTests.join(' & ');

    const lastTwoFailedTests = failedTests.slice(-2);
    const remainingFailedTests = failedTests.slice(0, -2);

    return [...remainingFailedTests, lastTwoFailedTests.join(' & ')].join(', ');
  };

  return (
    <AdminContext.Provider value={contextValues}>
      <Router>
        <SentryRoute
          path={ROUTES.ADMIN}
          render={(innerProps) => (
            <NavBar
              navBarWidth={NAVBAR_DRAWER_WIDTH}
              appView={appView}
              {...innerProps}
            />
          )}
        />
        <Container>
          {!isDemoAccount && (
            <StyledHeader>
              <StyledContainer>
                <UserGroupTitle>{profile.userGroup}</UserGroupTitle>
                {hasProjectAdminPermissions &&
                  !isTestSuiteStatusLoading &&
                  (testSuiteStatusResponse?.data?.userGroupStatus
                    ?.errorMessage ? (
                    <TestStatusIcon
                      status="warning"
                      toolTipMsg={
                        testSuiteStatusResponse?.data?.userGroupStatus
                          .errorMessage
                      }
                    />
                  ) : (
                    <TestStatusIcon
                      status={
                        testSuiteStatusResponse?.data?.userGroupStatus.status
                      }
                      toolTipMsg={formatToolTipMessage(
                        testSuiteStatusResponse?.data?.userGroupStatus
                          .failedTests,
                        testSuiteStatusResponse?.data?.userGroupStatus.status
                      )}
                    />
                  ))}
              </StyledContainer>
              {showProjectBuilderDemoModeToggle && (
                <MuiSwitch
                  checked={isProjectBuilderDemoMode}
                  onClick={() => {
                    setIsProjectBuilderDemoMode(!isProjectBuilderDemoMode);
                  }}
                />
              )}
            </StyledHeader>
          )}
          <ContentWrapper>
            <Switch>
              <SentryRoute
                path={`${ROUTES.ADMIN}${ROUTES.PROJECTS}`}
                render={(innerProps) => (
                  <ProjectViewer {...innerProps} profile={profile} />
                )}
              />
              <SentryRoute exact path={ROUTES.ROOT}>
                <Redirect to={`${ROUTES.ADMIN}${ROUTES.PROJECTS}`} />
              </SentryRoute>
              <SentryRoute exact path={ROUTES.INDEX_HTML}>
                <Redirect to={`${ROUTES.ADMIN}${ROUTES.PROJECTS}`} />
              </SentryRoute>
              <SentryRoute exact path={ROUTES.ADMIN}>
                <Redirect to={`${ROUTES.ADMIN}${ROUTES.PROJECTS}`} />
              </SentryRoute>
              <SentryRoute path={ROUTES.PROJECTS}>
                <Redirect to={`${ROUTES.ADMIN}${ROUTES.PROJECTS}`} />
              </SentryRoute>
              <SentryRoute path={ROUTES.USERS}>
                <Redirect to={`${ROUTES.ADMIN}${ROUTES.USERS}`} />
              </SentryRoute>
              <SentryRoute
                exact
                path={ROUTES.LOGIN}
                render={() => (
                  <LoginHandler
                    redirectTo={`${ROUTES.ADMIN}${ROUTES.PROJECTS}`}
                  />
                )}
              />
              <SentryRoute
                path={`${ROUTES.ADMIN}/hcp/:analysisId/:physicianId?`}
                render={(innerProps) => (
                  <PhysicianView {...innerProps} profile={profile} />
                )}
              />
              <SentryRoute
                path={`${ROUTES.ADMIN}/datasets/adddata/`}
                render={(innerProps) => (
                  <IngestionGuide {...innerProps} startIndex={0} />
                )}
              />
              <SentryRoute
                path={`${ROUTES.ADMIN}/datasets/dataconnectors/`}
                render={(innerProps) => (
                  <DataConnectorView {...innerProps} profile={profile} />
                )}
              />
              <SentryRoute
                path={`${ROUTES.ADMIN}/settings/`}
                render={(innerProps) => <ConstructionView {...innerProps} />}
              />
              <SentryRoute
                path={`${ROUTES.ADMIN}/users/`}
                render={(innerProps) => <UserManagementView {...innerProps} />}
              />
              <SentryRoute
                path={`${ROUTES.ADMIN}/config/`}
                render={(innerProps) => (
                  <ConfigViewProvider {...innerProps} profile={profile} />
                )}
              />
              <SentryRoute
                path={`${ROUTES.ADMIN}/FeatureToggleView`}
                render={(innerProps) => (
                  <FeatureToggleViewProvider
                    {...innerProps}
                    profile={profile}
                  />
                )}
              />
              <SentryRoute
                path={ROUTES.ADMIN_CURATION}
                render={(innerProps) => <CurationReports {...innerProps} />}
              />
              <SentryRoute
                path={ROUTES.ADMIN_CSV_DELIVERY}
                render={(innerProps) => (
                  <CsvDeliveryContainer {...innerProps} />
                )}
              />
              <SentryRoute
                path={`${ROUTES.ADMIN}${ROUTES.DATA_REPORT}`}
                render={(innerProps) => <DataReport {...innerProps} />}
              />
            </Switch>
          </ContentWrapper>
        </Container>
      </Router>
    </AdminContext.Provider>
  );
};
