import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { getMaptualListsMetadata } from '../../request/projectRequests';
import {
  isCanadianProvince,
  isCustomerTerritory,
  replaceListNames,
} from '../../utils/replaceListNames';
import { getDemoAccountStatus } from '../../request/config';

export const useMaptualLists = ({ projectId }) => {
  const getMaptualLists = async () => {
    if (!projectId) return { data: {} };

    try {
      const { data } = await getMaptualListsMetadata(projectId);

      let regionsFlat = data?.regionsFlat;
      let hierarchyList = { ...data.regionsTree };
      let objectivesList = [...data.objectives];

      if (getDemoAccountStatus()) {
        const nationalChildren = hierarchyList?.nodes;
        const obfuscatedTerritories = replaceListNames(nationalChildren);
        hierarchyList = {
          ...hierarchyList,
          nodes: obfuscatedTerritories,
        };

        regionsFlat = regionsFlat.map((region, index) => {
          if (isCanadianProvince(region)) {
            return { ...region, listName: `State ${index}` };
          }
          if (isCustomerTerritory(region)) {
            return { ...region, listName: `Territory ${index}` };
          }
          return region;
        });

        objectivesList = objectivesList.map((obj, i) => ({
          ...obj,
          name: `Objective ${i + 1}`,
        }));
      }
      const flattenedList = regionsFlat.reduce(
        (result, region) => ({ ...result, [region.maptualListId]: region }),
        {}
      );

      return {
        flat: flattenedList,
        hierarchy: hierarchyList,
        objectives: objectivesList,
      };
    } catch (err) {
      return {};
    }
  };

  const {
    isLoading: isMaptualListsLoading,
    data: maptualListsData,
    refetch: refetchMaptualLists,
  } = useQuery(['maptual-lists', projectId], getMaptualLists);

  const [maptualListHierarchy, setMaptualListHierarchy] = useState({});
  const [maptualListObjectives, setMaptualListObjectives] = useState([]);
  const [maptualListFlat, setMaptualListFlat] = useState({});

  useEffect(() => {
    if (!isMaptualListsLoading && maptualListsData) {
      setMaptualListHierarchy(maptualListsData?.hierarchy);
      setMaptualListObjectives(maptualListsData?.objectives);
      setMaptualListFlat(maptualListsData?.flat || {});
    }
  }, [isMaptualListsLoading, maptualListsData]);

  return {
    maptualListHierarchy,
    maptualListFlat,
    maptualListObjectives,
    isMaptualListsLoading,
    refetchMaptualLists,
  };
};
