import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { EntityDrawerContext } from './data/entityDrawerContext';
import { ERROR_TYPES_ENUM } from '../../constants';
import { EntityDrawerError } from './EntityDrawerError';
import { useEntityDrawerTimeline } from './data/useEntityDrawerData';
import { DrawerTimelineSkeleton } from '../skeletons/DrawerTimelineSkeleton';
import { PowerScore } from '../../../../components/fieldView/powerScore';

const getBorderColor = (themeColors, eventType, scoreChange = null) => {
  if (eventType === 'score_change') {
    if (scoreChange > 0) {
      return themeColors.timelineScoreIncreaseBorderColor;
    }
    if (scoreChange < 0) {
      return themeColors.timelineScoreDecreaseBorderColor;
    }
  }

  return themeColors.timelineActivityBorderColor;
};

export const EntityDrawerTimelineTab = () => {
  const { themeColors } = useTheme();

  const { productLineId, projectId, regionId, entityType, entityId } =
    useContext(EntityDrawerContext);

  const { data, isFetching, isError } = useEntityDrawerTimeline({
    productLineId,
    projectId,
    regionId,
    entityType,
    entityId,
  });

  if (isFetching) {
    return (
      <Box p={3}>
        <DrawerTimelineSkeleton />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box p={3}>
        <EntityDrawerError
          errorType={ERROR_TYPES_ENUM.RETRY}
          retryType="timeline"
        />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap={2} p={3}>
      {data?.timeline &&
        data?.timeline.map((timelineEvent, index) => (
          <Box
            key={index}
            bgcolor={themeColors.timelineActivitySurfaceColor}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            p={2}
            borderLeft={`solid 2px ${getBorderColor(
              themeColors,
              timelineEvent.type,
              timelineEvent.data.maptualScoreChange
            )}`}
          >
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography
                variant="subtitle1"
                color={themeColors.timelineActivityContentSecondaryColor}
              >
                {timelineEvent.timestamp}
              </Typography>

              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography
                  variant="h5"
                  color={themeColors.timelineActivityContentPrimaryColor}
                >
                  {timelineEvent.primaryText}
                </Typography>
                <Typography
                  variant="body2"
                  color={themeColors.timelineActivityContentSecondaryColor}
                >
                  {timelineEvent.secondaryText}
                </Typography>
              </Box>
            </Box>

            {timelineEvent.type === 'score_change' ? (
              <PowerScore
                score={timelineEvent.data.maptualDisplayScore}
                scoreChangeValue={timelineEvent.data.maptualScoreChange}
              />
            ) : (
              <Box /> // For other event types
            )}
          </Box>
        ))}
    </Box>
  );
};
