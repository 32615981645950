export default function formatPercent(number, cappedValue = 0) {
  if (typeof number !== 'number') return number;
  if (cappedValue) {
    return number > cappedValue ? `> ${cappedValue}%` : `${number}%`;
  }
  return `${(Math.round(number * 10000) / 100).toString()}%`;
}

export function formatPercentForAxis(number) {
  if (typeof number !== 'number') return number;
  return `${(Math.round(number * 10) / 10).toString()}%`;
}
