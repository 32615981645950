import React, { useRef, useState } from 'react';
import { useTheme } from '@mui/styles';
import { Bar } from 'react-chartjs-2';
import { Tooltip, createTooltip } from '../tooltip';

export const SparklineBarGraph = ({
  data,
  labels,
  expanded = false,
  yTitle = '',
  tooltipDataPointTitle = '',
}) => {
  const [tooltip, setTooltip] = useState({
    trend: {},
    opacity: 0,
    top: 0,
    left: 0,
    date: '',
    value: '',
    display: 'none',
  });
  const { themeColors } = useTheme();
  const chartRef = useRef(null);

  const chartData = {
    labels,
    datasets: [
      {
        tick: false,
        scale: false,
        label: '',
        data,
        fill: false,
        backgroundColor: themeColors.graphBlue,
        hoverBackgroundColor: themeColors.graphBlue,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    animation: expanded,
    scales: {
      x: {
        display: expanded,
        ticks: {
          color: themeColors.graphAxisColor,
        },
      },
      y: {
        title: {
          display: !!yTitle,
          text: yTitle,
          font: 12,
          color: themeColors.graphAxisColor,
        },
        ticks: {
          color: themeColors.graphAxisColor,
          padding: 8,
        },
        grid: {
          display: expanded,
          color: themeColors.graphGridColor,
        },
        display: expanded,
      },
    },
    legend: {
      display: false,
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          if (!expanded) return;
          createTooltip({
            context,
            tooltip,
            updateTooltip: (newTooltipData) => setTooltip(newTooltipData),
          });
        },
      },
    },
  };

  return (
    <>
      <Bar options={options} data={chartData} ref={chartRef} />
      {expanded && (
        <Tooltip
          tooltip={tooltip}
          tooltipDataPointTitle={tooltipDataPointTitle}
        />
      )}
    </>
  );
};
