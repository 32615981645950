import React, { useContext, useEffect, useState } from 'react';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { TerritoryOverviewContext } from '../../territoryOverviewContext';
import {
  territoryOverviewDataExists,
  calculateLabelColumnWidth,
  getTerritoryRoute,
  isEmptyDemandItem,
} from '../../utils';
import { DemandTableHeader } from './DemandTableHeader';
import { DemandTableCell } from './DemandTableCell';
import { SkeletonBars } from '../../../../components/generic/skeletonBars';

const TableWrapper = styled(Box)(() => ({
  overflow: 'hidden',
  height: '100%',
  '>.MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor':
    {
      paddingTop: 0,
    },
}));

const StyledDatagrid = styled(DataGridPremium)(
  ({ theme: { themeColors } }) => ({
    '.MuiDataGrid-root': {
      overflow: 'visible',
    },
    '.MuiDataGrid-columnHeader': {
      background: themeColors.mainBackground,
    },
    '.MuiDataGrid-columnHeaders': {
      color: '#C2CAD6',
      borderColor: themeColors.borderLowContrast,
    },
    '.MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${themeColors.borderLowContrast}`,
      padding: 10,
    },
    '.MuiDataGrid-pinnedColumns, .MuiDataGrid-pinnedColumnHeaders': {
      borderRight: `1px solid ${themeColors.borderLowContrast}`,
      background: themeColors.mainBackground,
      '.MuiDataGrid-cell': {
        boxSizing: 'border-box',
        paddingRight: '1rem',
      },
    },
    '.MuiDataGrid-row': {
      fontWeight: 500,
    },
    '.MuiDataGrid-row > [data-colindex="0"]:hover': {
      background: themeColors.segmentSelectionHoverBackgroundColor,
      cursor: 'pointer',
    },
    '.MuiDataGrid-row.Mui-hovered': {
      background: 'none',
    },
    '.MuiDataGrid-iconSeparator': {
      color: themeColors.secondaryTextColor,
      display: 'none',
    },
    '& .MuiDataGrid-cell:hover': {
      color: 'primary.main',
    },
    '.MuiDataGrid-cell--textLeft': {
      paddingTop: 10,
      paddingBottom: 10,
      textAlign: 'flex-start',
    },
    '.MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
      overflow: 'visible',
      alignItems: 'center',
    },
    borderColor: themeColors.borderLowContrast,
    border: '0',
    boxShadow: 'none',
    paddingTop: 20,
    '.MuiDataGrid-virtualScroller': {
      '&::-webkit-scrollbar': {
        width: 14,
        height: 14,
      },
      '&::-webkit-scrollbar-track': {
        background: '#1F232C',
        border: 'none',
        boxShadow: 'none',
      },
      '&::-webkit-scrollbar-corner': {
        background: '#1F232C',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#455368',
        borderRadius: 100,
        border: `3px solid #1F232C`,
        '&:hover': {
          background: '#C2CAD6',
        },
      },
    },
    '.MuiDataGrid-footerContainer': {
      border: 'none',
      justifyContent: 'left',
    },
  })
);

const DataGridColumnsInitialState = {
  pinnedColumns: { left: ['0'] },
  pagination: {
    paginationModel: { pageSize: 25, page: 0 },
  },
};
const DataGridOptions = {
  padding: 'dense',
};

export const getDemandCellValue = (type, rowData) => {
  if (!rowData || (Array.isArray(rowData) && rowData.length === 0)) {
    return undefined;
  }

  if (type === 'label') {
    return rowData;
  }
  return Number(rowData[rowData.length - 1]);
};

export const DemandTable = ({ isDemandLoading, setIsDemandLoading }) => {
  const {
    data,
    isDataLoading,
    selectedTimeframeID,
    regionId: maptualListId,
    projectId,
    isMetadataLoading,
  } = useContext(TerritoryOverviewContext);

  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();

  const [rowData, setRowData] = useState();
  const [colData, setColData] = useState();

  useEffect(() => {
    const hasTerritoryOverviewData = territoryOverviewDataExists(
      data,
      selectedTimeframeID
    );

    if (hasTerritoryOverviewData) {
      const timescaleData = data.timescaleData[selectedTimeframeID];
      const labelColWidth = calculateLabelColumnWidth(timescaleData);
      const tableHeaders = timescaleData?.headers;

      const tempRows = [];
      const tempColumns = [];

      setIsDemandLoading(true);

      timescaleData?.items.forEach((item, index) => {
        if (!item.every(isEmptyDemandItem)) {
          const response = {};
          for (let i = 0; i < tableHeaders?.length; i++) {
            response[i] = item[i];
          }
          response.id = index;
          tempRows.push(response);
        }
      });

      tableHeaders?.forEach(
        ({ label, subLabel, type, graph, chartLabels }, index) => {
          tempColumns.push({
            field: `${index}`,
            minWidth: type === 'label' ? labelColWidth : 140,
            maxWidth: type === 'label' ? '20rem' : 300,
            renderHeader: () => DemandTableHeader({ label, subLabel }),
            flex: 1,
            sortable: true,
            sortingOrder: ['desc', 'asc', null],
            valueGetter: ({ row }) =>
              getDemandCellValue(type, row[index]?.data),
            renderCell: ({ row }) =>
              DemandTableCell({
                row: row[index],
                type,
                graph,
                chartLabels,
                metricType: subLabel,
                modalGraphTitle: label,
              }),
          });
        }
      );

      setRowData(tempRows);
      setColData(tempColumns);
    }

    return () => {
      setRowData();
      setColData();
    };
  }, [data, selectedTimeframeID]);

  useEffect(() => {
    if (rowData && colData) {
      setRows(rowData);
      setColumns(colData);
    }

    return () => {
      setRows();
      setColumns();
    };
  }, [rowData, colData]);

  useEffect(() => {
    if (rows && columns && isDemandLoading) {
      setIsDemandLoading(false);
    }
  }, [rows, columns, isDemandLoading, data]);

  const onCellClick = (params) => {
    if (params.field !== '0') {
      return;
    }

    const route = getTerritoryRoute(params?.row, maptualListId, projectId);
    window.open(route);
  };

  return isDemandLoading || isDataLoading || isMetadataLoading ? (
    <SkeletonBars />
  ) : (
    <TableWrapper>
      <StyledDatagrid
        columns={columns || []}
        rows={rows || []}
        disableSelectionOnClick
        disableColumnMenu
        rowHeight={50}
        disableMultipleSelection
        loading={isDemandLoading || !rows || !columns}
        initialState={DataGridColumnsInitialState}
        options={DataGridOptions}
        onCellClick={onCellClick}
        disableRowSelectionOnClick
        pagination
        rowBuffer={columns?.length || 0}
      />
    </TableWrapper>
  );
};
