import { useQuery } from 'react-query';
import { maptualApiInstance } from '../../../../../../../../../request/config';

export const useHcpCrmData = ({
  productLineId,
  projectId,
  regionId,
  entityId,
  products,
  latestCurationTimestamp,
}) => {
  const queryCacheKey = Object.values({
    key: 'hcp-crm-data',
    productLineId,
    projectId,
    regionId,
    entityId,
    products,
  });
  return useQuery(queryCacheKey, async () => {
    try {
      const response = await getHcpCrmData({
        productLineId,
        projectId,
        regionId,
        entityId,
        products,
        latestCurationTimestamp,
      });
      return response.data;
    } catch {
      return null;
    }
  });
};

export function getHcpCrmData({
  productLineId,
  projectId,
  regionId,
  entityId,
  products,
  latestCurationTimestamp,
}) {
  if (
    !productLineId ||
    !projectId ||
    !regionId ||
    !entityId ||
    !products ||
    !products.length ||
    !latestCurationTimestamp
  ) {
    return Promise.reject();
  }
  const productsString = products.map((i) => `products=${i}`).join('&');
  return maptualApiInstance.get(
    `pulse/crm/product-lines/${productLineId}/projects/${projectId}/regions/${regionId}/entities/${entityId}?${productsString}&timestamp=${latestCurationTimestamp}`,
    {
      timeout: 600000,
    }
  );
}
