import React from 'react';
import styled from '@emotion/styled';
import {
  Snackbar,
  SnackbarContent,
  Fade,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledSnackbarContent = styled(SnackbarContent)(
  ({ theme: { themeColors } }) => ({
    backgroundColor: themeColors.alertPrimaryInfoColor,
    color: themeColors.alertButtonHighContrastDefaultContent,
    fontColor: themeColors.alertPrimaryInfoDescriptionColor,
    '.MuiSnackbarContent-action': {
      paddingLeft: '4px',
    },
  })
);

const StyledButton = styled(Button)(({ theme: { themeColors } }) => ({
  color: themeColors.alertButtonHighContrastDefaultContent,
  '&:hover': {
    backgroundColor: themeColors.alertButtonHighContrastHoverSurface,
  },
}));

const StyledIconButton = styled(IconButton)(({ theme: { themeColors } }) => ({
  color: themeColors.alertButtonHighContrastDefaultContent,
  '&:hover': {
    backgroundColor: themeColors.alertButtonHighContrastHoverSurface,
  },
}));

export const SnackbarDisplay = ({
  key,
  isOpen,
  handleClose,
  handleUndo,
  handleExited,
  message,
  showXIcon = true,
  showUndoButton = true,
  duration = 5000,
}) => {
  const action = (
    <>
      {showUndoButton && (
        <StyledButton
          size="small"
          onClick={() => {
            handleUndo();
            handleClose();
          }}
        >
          Undo
        </StyledButton>
      )}
      {showXIcon && (
        <StyledIconButton size="small" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </StyledIconButton>
      )}
    </>
  );
  return (
    <Snackbar
      key={key}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={isOpen}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      TransitionComponent={Fade}
      autoHideDuration={duration}
    >
      <StyledSnackbarContent message={message} action={action} />
    </Snackbar>
  );
};
