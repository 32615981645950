import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Accordion,
  Button,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import MovingIcon from '@mui/icons-material/Moving';
import { Star } from '@mui/icons-material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ApartmentIcon from '@mui/icons-material/Apartment';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import { ReactComponent as VacationIcon } from '../../../../../../assets/vacation.svg';
import { ReactComponent as HcpButtonIcon } from './stethoscope.svg';
import { ROUTES } from '../../../../../../constants';
import {
  getDemoAccountStatus,
  userHasCallPlanAccess,
} from '../../../../../../request/config';
import { AppContext } from '../../../../appContext';
import { FieldContext } from '../../fieldContext';
import useFeatureToggles from '../../../../../../hooks/useFeatureToggles';

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '0px 16px',
}));

const StyledViewListButton = styled(Button)((props) => ({
  width: '100%',
  padding: '12px',
  minHeight: '24px',
  justifyContent: 'flex-start',
  color: '#D6E2F5',
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '24px',
  backgroundColor: props.isActive
    ? props.theme.themeColors.buttonBorderColor
    : 'none',
  '&:hover': {
    backgroundColor: props.isActive
      ? props.theme.themeColors.buttonBorderColor
      : props.theme.themeColors.buttonBackgroundColor,
  },
  '& .MuiSvgIcon-root': {
    color: props.disabled ? 'unset' : props.theme.themeColors.listIconColor,
  },
}));

const StyledAccordion = styled(Accordion)((props) => ({
  backgroundColor: '#15161A',
  backgroundImage: 'none',
  boxShadow: 'none',
  '&:before': {
    background: 'none',
  },
  color: props.theme.themeColors.buttonSecondaryBackgroundColour,
  marginTop: '16px !important',
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  padding: 0,
}));

const StyledAccordionHeader = styled(Typography)((props) => ({
  color: props.theme.themeColors.buttonGroupHeaderColor,
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
}));

const StyledButtonContent = styled.div(() => ({
  display: 'flex',
  width: '100%',
}));
const StyledButtonCount = styled.div(() => ({
  marginLeft: 'auto',
}));

const ENTITY_OVERVIEW_ROUTES = {
  outlet: `${ROUTES.ENTITY_OVERVIEW}${ROUTES.OUTLET}`,
  direct_account: `${ROUTES.ENTITY_OVERVIEW}${ROUTES.DIRECT_ACCOUNT}`,
};

const ENTITY_OVERVIEW_ICONS = {
  outlet: <ApartmentIcon />,
  direct_account: <WorkOutlineOutlinedIcon />,
};

export const ViewsList = ({ project }) => {
  const history = useHistory();
  const location = useLocation();
  const featureToggles = useFeatureToggles();
  const { profile } = useContext(AppContext);
  const { maptualListMetadata, maptualList, entityViews } =
    useContext(FieldContext);

  const isDemoAccount = getDemoAccountStatus();
  const routeContains = (route) => location.pathname.includes(route);
  const handleViewListButtonClick = (view) => {
    if (routeContains(view)) {
      return;
    }
    const route = `${ROUTES.FIELD}${ROUTES.PROJECTS}/${project.metadata.projectId}/maptualListId/${maptualListMetadata.maptualListId}${view}`;
    history.push(route);
  };

  return (
    <Container>
      {(isDemoAccount ||
        (userHasCallPlanAccess(profile) &&
          featureToggles(profile.userGroup, 'curatedList'))) && (
        <StyledViewListButton
          isActive={routeContains(ROUTES.CALL_PLAN)}
          startIcon={<TrackChangesIcon />}
          onClick={() => handleViewListButtonClick(ROUTES.CALL_PLAN)}
        >
          Call Plan
        </StyledViewListButton>
      )}
      <StyledViewListButton
        isActive={routeContains(ROUTES.HCPS)}
        startIcon={<HcpButtonIcon />}
        onClick={() => handleViewListButtonClick(ROUTES.HCPS)}
      >
        HCPs
      </StyledViewListButton>

      {featureToggles(profile.userGroup, 'showAccountView') &&
        entityViews.map((view) => {
          const entityViewRoute = ENTITY_OVERVIEW_ROUTES[view.entityType];

          return (
            <StyledViewListButton
              key={view.entityType}
              isActive={routeContains(entityViewRoute)}
              startIcon={ENTITY_OVERVIEW_ICONS[view.entityType]}
              onClick={() => handleViewListButtonClick(entityViewRoute)}
            >
              {view.displayName}
            </StyledViewListButton>
          );
        })}

      {featureToggles(profile.userGroup, 'showTerritoryOverview') && (
        <StyledViewListButton
          isActive={routeContains(ROUTES.TERRITORY_OVERVIEW)}
          startIcon={<MovingIcon />}
          onClick={() => handleViewListButtonClick(ROUTES.TERRITORY_OVERVIEW)}
        >
          Territory Overview
        </StyledViewListButton>
      )}
      <StyledAccordion disableGutters="true" defaultExpanded="true">
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledAccordionHeader>HCP Custom Lists</StyledAccordionHeader>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <StyledViewListButton
            isActive={routeContains(`${ROUTES.CUSTOM}${ROUTES.STARRED}`)}
            startIcon={<Star />}
            onClick={() =>
              handleViewListButtonClick(`${ROUTES.CUSTOM}${ROUTES.STARRED}`)
            }
          >
            <StyledButtonContent>
              <div>Starred</div>
              <StyledButtonCount>
                {maptualList?.userCreatedListCounts?.starred || ''}
              </StyledButtonCount>
            </StyledButtonContent>
          </StyledViewListButton>
          <StyledViewListButton
            isActive={routeContains(`${ROUTES.CUSTOM}${ROUTES.NO_SEE}`)}
            startIcon={<VisibilityOffIcon />}
            onClick={() =>
              handleViewListButtonClick(`${ROUTES.CUSTOM}${ROUTES.NO_SEE}`)
            }
          >
            <StyledButtonContent>
              <div>No See</div>
              <StyledButtonCount>
                {maptualList?.userCreatedListCounts?.noSee || ''}
              </StyledButtonCount>
            </StyledButtonContent>
          </StyledViewListButton>
          <StyledViewListButton
            isActive={routeContains(
              `${ROUTES.CUSTOM}${ROUTES.LONG_TERM_LEAVE}`
            )}
            startIcon={<VacationIcon />}
            onClick={() =>
              handleViewListButtonClick(
                `${ROUTES.CUSTOM}${ROUTES.LONG_TERM_LEAVE}`
              )
            }
          >
            <StyledButtonContent>
              <div>Long term leave</div>
              <StyledButtonCount>
                {maptualList?.userCreatedListCounts?.longTermLeave || ''}
              </StyledButtonCount>
            </StyledButtonContent>
          </StyledViewListButton>
        </StyledAccordionDetails>
      </StyledAccordion>
    </Container>
  );
};
