import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { CallPlanContext } from './context/CallPlanContext';
import { CADENCE } from '../../pulse/shared/constants';
import { formatTimestamp } from '../../pulse/shared/utils';

const StyledTypography = styled(Typography)(({ theme: { themeColors } }) => ({
  minWidth: '75px',
  fontSize: 16,
  fontWeight: 500,
  color: themeColors.primaryTextColor,
  alignSelf: 'center',
}));

export const CallPlanTimestamp = () => {
  const { latestCurationTimestamp } = useContext(CallPlanContext);

  if (!latestCurationTimestamp) {
    return null;
  }

  const listTime =
    typeof latestCurationTimestamp === 'string'
      ? formatTimestamp({
          timestamp: latestCurationTimestamp,
          cadence: CADENCE.WEEK,
        })
      : 'No';

  return <StyledTypography>{listTime} List</StyledTypography>;
};
