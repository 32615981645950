/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import styled from '@emotion/styled';
import {
  Autocomplete,
  TextField,
  Popper,
  Paper,
  List,
  MenuItem,
} from '@mui/material';

const StyledAutocomplete = styled(Autocomplete)({
  marginTop: 8,
  marginBottom: 16,
});

const StyledPaper = styled(Paper)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.buttonBackgroundColor,
  backgroundImage: 'none',
  boxShadow: '0px 16px 70px 0px rgba(0, 0, 0, 0.50)',
}));

const StyledTextField = styled(TextField)(() => ({
  '.MuiOutlinedInput-root': {
    padding: 0,
  },
  '.MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '15px 12px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
}));

const StyledOptionList = styled(List)(({ theme: { themeColors } }) => ({
  padding: '16px 0px !important',
  border: `1px solid ${themeColors.popoverBorderColor}`,
  boxShadow: '0px 16px 70px 0px rgba(0, 0, 0, 0.50)',
  borderRadius: 4,
}));

const StyledOption = styled(MenuItem)(({ theme: { themeColors } }) => ({
  padding: '12px !important',
  color: themeColors.segmentSelectionCaptionColor,
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '24px',
  borderRadius: 3,
}));

const RegionListPopper = (props) => (
  <Popper
    {...props}
    placement="bottom"
    popperOptions={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
      ],
    }}
  />
);

export const RegionSearch = ({ regionsForSearch, handleRegionSelection }) => (
  <StyledAutocomplete
    id="region-selection-combo-box"
    disablePortal
    options={regionsForSearch || []}
    getOptionLabel={(option) => option.listName}
    onChange={(event, value, reason) => {
      if (reason === 'selectOption') handleRegionSelection(value);
    }}
    clearIcon={null}
    forcePopupIcon={false}
    noOptionsText="No regions found that match your search."
    renderInput={(props) => (
      <StyledTextField
        {...props}
        id="region-selection-input"
        label="Find other regions"
      />
    )}
    PopperComponent={RegionListPopper}
    PaperComponent={(props) => <StyledPaper {...props} />}
    ListboxComponent={(props) => <StyledOptionList {...props} />}
    renderOption={(props, option) => (
      <StyledOption {...props}>{option.listName}</StyledOption>
    )}
  />
);
