import { Button, styled, Typography } from '@mui/material';
import React, { useContext } from 'react';
import {
  ModalBody,
  ModalDisplay,
  ModalFooter,
  ModalHeader,
} from '../generic/modalDisplay';
import { ConfigViewContext } from './configViewContext';
import { LoadingIcon } from './styledComponents';

const ModalTypography = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.objectiveTypeDeleteIconColor,
}));

export function NodeDeleteModal({
  isOpen,
  onClose,
  editExistingTree,
  node,
  attachedMarketId,
  setEditedNode,
  isTreeUpdating,
}) {
  const nodeData = {
    productName: !node?.isNewNode ? node?.productName : '',
    friendlyName: !node?.isNewNode ? node?.friendlyName : '',
    level: node?.level,
    productId: node?.productId,
  };
  const { productLineId } = useContext(ConfigViewContext);
  const handleNodeDelete = () => {
    const requestBody = {
      productLine: productLineId,
      customerMarketId: attachedMarketId,
      operation: 'DELETE',
      ...nodeData,
    };
    editExistingTree({
      requestBody,
      cleanupFunction: () => {
        onClose();
        setEditedNode(null);
      },
      message: `Successfully deleted ${node?.friendlyName} node`,
    });
  };
  return (
    <ModalDisplay isOpen={isOpen}>
      <ModalHeader onClose={onClose}>Delete Node Confirmation</ModalHeader>
      <ModalBody>
        <ModalTypography variant="body1">
          Are you sure you want to delete {node?.friendlyName} node? All
          children nodes will also be deleted and you cannot undo this action.
        </ModalTypography>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleNodeDelete}>
          {isTreeUpdating ? (
            <>
              Deleting Node
              <LoadingIcon size="11px" />
            </>
          ) : (
            'Confirm and Delete'
          )}
        </Button>
      </ModalFooter>
    </ModalDisplay>
  );
}
