import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';
import { getLegendColors, lighten } from './utils';
import { TypographyDefinitions } from '../../../../styles/typography';
import { trackTimeframeTableRowSelection } from '../../../../trackers/appEventTracker';
import { selectMetricComponentData } from '../../../../selectors/project';
import formatPercent from '../../../../utils/formatPercent';
import toKebabCase from '../../../../utils/toKebabCase';

const PointerRow = styled(TableRow)`
  cursor: pointer;
`;

const Flex = styled.div`
  display: flex;
`;

const LegendContentCell = styled(Flex)`
  align-items: center;
  gap: 10px;
  padding: 10px 10px 12px 10px;
  background-color: ${(props) =>
    props.isSelected
      ? props.theme.themeColors.selectedTableRowColor
      : props.theme.themeColors.contentCardBackgroundColor};
  border-radius: ${(props) => {
    if (
      props.isSelected &&
      props.isFirstSelectedRow &&
      props.isLastSelectedRow
    ) {
      return '8px 0px 0px 8px';
    }
    if (props.isSelected && props.isFirstSelectedRow) {
      return '8px 0px 0px 0px';
    }
    if (props.isSelected && props.isLastSelectedRow) {
      return '0px 0px 0px 8px';
    }
    return '0px';
  }};
`;

const LegendMarker = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  aspect-ratio: 1/1;
  background-color: ${(props) => props.backgroundColor};
`;

const LegendTitle = styled(Typography)`
  font-weight: ${TypographyDefinitions.fontWeight.regular};
  line-height: 1.1;
  color: ${(props) => props.color};
  ${(props) =>
    props.isSelected &&
    props.isFirstElement &&
    `font-weight: ${TypographyDefinitions.fontWeight.medium}`};
`;

const headerPrimaryText = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 1.2,
  wrapBreak: 'break-word',
  whiteSpace: 'normal',
};

const headerSecondaryText = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: 1.3,
};

const RegularCell = styled(TableCell)`
  vertical-align: top;
  min-width: 80px;
  border-bottom: 0;
  background: ${(props) =>
    props.isSelected
      ? props.theme.themeColors.selectedTableRowColor
      : props.theme.themeColors.contentCardBackgroundColor};
  color: ${(props) => props.color};

  &:last-child {
    border-radius: ${(props) =>
      props.isLastHeader ? '8px 8px 0px 0px' : '0px'};
  }
`;

const DataCell = styled(RegularCell)`
  padding: 10px;
  text-align: initial;

  &:last-child {
    ${(props) => {
      if (props.isFirstRowLastCell && props.isLastRowLastCell) {
        return 'border-radius: 0px 8px 8px 0px;';
      }
      if (props.isFirstRowLastCell) {
        return 'border-radius: 0px 8px 0px 0px;';
      }
      if (props.isLastRowLastCell) {
        return 'border-radius: 0px 0px 8px 0px;';
      }
      return '';
    }}
  }
`;

const RoundedStickyCell = styled(RegularCell)`
  position: sticky;
  left: 0px;
  min-width: 220px;
  padding: 0px;
  background-color: ${(props) =>
    props.theme.themeColors.contentCardBackgroundColor};
  &:last-child {
    background: ${(props) =>
      props.isSelected
        ? props.theme.themeColors.selectedRowNowColumn
        : props.theme.themeColors.contentCardHighlightColor};

    border-radius: ${(props) => (props.isSelected ? '0px 0px 0px 8px' : '0px')};
  }
}`;

const StickyCell = styled(RegularCell)`
  position: sticky;
  left: 0px;
  min-width: 220px;
  padding: 0px;
`;

const HeaderStickyCell = styled(StickyCell)`
  vertical-align: bottom;
  padding: 10px 0px 5px;
  background-color: ${(props) =>
    props.theme.themeColors.contentCardBackgroundColor};
`;

const StickyCellContainer = styled.div`
  background-color: ${(props) =>
    props.theme.themeColors.contentCardBackgroundColor};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: contents;
`;

const HeaderTableRow = styled(TableRow)`
  vertical-align: bottom;
`;

const HeaderCell = styled(RegularCell)`
  padding: 10px 8px 5px;
  text-align: initial;
  background-color: ${(props) =>
    props.theme.themeColors.contentCardBackgroundColor};
`;

const CustomTableRow = ({
  isSelected,
  onRowSelect,
  row,
  rowIndex,
  metricComponentType,
  timeframeSelection,
}) => {
  const { entityName, maptualListSegmentName, maptualListLabel } = useSelector(
    selectMetricComponentData
  );
  const { themeColors } = useTheme();

  const filteredRow = isSelected ? row : { series: [row.series[0]] };

  const colorSelector = (isFirstElement) =>
    isFirstElement
      ? themeColors.primaryTextColor
      : lighten(themeColors.primaryTextColor);
  const showCappedPercentage =
    metricComponentType === 'scriptsData' &&
    filteredRow.series[0].name.toLowerCase().includes('iz rate');

  return filteredRow.series.map((seriesItem, seriesItemIndex) => {
    const isFirstInnerRow = seriesItemIndex === 0;
    const isLastInnerRow = seriesItemIndex === filteredRow.series.length - 1;
    const legendColors = getLegendColors(rowIndex, themeColors);
    const isFirstElement = seriesItemIndex === 0;

    return (
      <PointerRow
        key={`${seriesItem.name}-${seriesItemIndex}`}
        onClick={(event) => {
          onRowSelect(event, rowIndex);
          trackTimeframeTableRowSelection({
            metricComponentType,
            primaryLegendTitle: seriesItem.name,
            timeframeSelectionName: timeframeSelection,
            entityName,
            segmentName: maptualListSegmentName,
            maptualListSelectionLabel: maptualListLabel,
          });
        }}
      >
        <RoundedStickyCell isSelected={isSelected}>
          <StickyCellContainer>
            <LegendContentCell
              isSelected={isSelected}
              isFirstSelectedRow={isFirstInnerRow}
              isLastSelectedRow={isLastInnerRow}
            >
              <LegendMarker backgroundColor={legendColors[seriesItemIndex]} />
              <LegendTitle
                color={colorSelector(isFirstElement)}
                isFirstElement={isFirstElement}
                isSelected={isSelected}
              >
                {seriesItem.name}
              </LegendTitle>
            </LegendContentCell>
          </StickyCellContainer>
        </RoundedStickyCell>
        {seriesItem.data.map((dataItem, dataItemIndex) => {
          const isLastRowItem = dataItemIndex === seriesItem.data.length - 1;
          const isLastRowLastCell =
            isSelected && isLastInnerRow && isLastRowItem;
          const isFirstRowLastCell =
            isSelected && isLastRowItem && isFirstInnerRow;
          return (
            <DataCell
              key={`${dataItem}-${dataItemIndex}`}
              align="center"
              isSelected={isSelected}
              color={colorSelector(isFirstElement)}
              isLastRowLastCell={isLastRowLastCell}
              isFirstRowLastCell={isFirstRowLastCell}
            >
              <Typography variant="body1">
                {metricComponentType === 'scriptsData' && !showCappedPercentage
                  ? dataItem
                  : formatPercent(dataItem, showCappedPercentage ? 75 : null)}
              </Typography>
            </DataCell>
          );
        })}
      </PointerRow>
    );
  });
};

const scrollTableToTheRight = () => {
  const myDiv = document.getElementsByClassName('MuiTableContainer-root')[0];
  myDiv.scrollLeft = myDiv.scrollWidth;
};

const HistoricalTable = ({
  data,
  onRowSelect,
  rowSelected,
  metricComponentType,
  timeframeSelection,
}) => {
  useEffect(() => {
    scrollTableToTheRight();
  }, []);
  const { themeColors } = useTheme();

  if (!data) return null;

  const isLastRowSelected = rowSelected === data.length - 1;
  return (
    <TableContainer
      className={`intercom-field-${toKebabCase(
        metricComponentType
      )}-historical-table`}
    >
      <Table lastRowSelected={isLastRowSelected}>
        <TableHead>
          <HeaderTableRow>
            <HeaderStickyCell
              color={themeColors.primaryMaptualListFilteringColor}
            >
              <Typography
                sx={{
                  ...headerPrimaryText,
                  color: themeColors.primaryMaptualListFilteringColor,
                }}
              >
                {metricComponentType === 'scriptsData'
                  ? 'SCRIPTS DATA SOURCE(S)'
                  : 'SHARE DATA SOURCE(S)'}
              </Typography>
            </HeaderStickyCell>
            {data[rowSelected].xaxis.categories.map(
              (category, categoryIndex) => {
                const lastIndex = data[rowSelected].xaxis.categories.length - 1;
                const isLastHeader = categoryIndex === lastIndex;
                const [month, year] = category.split(' ');
                return (
                  <HeaderCell
                    key={`${category}-${categoryIndex}`}
                    isLastHeader={isLastHeader}
                    align="center"
                    color={
                      isLastHeader
                        ? themeColors.primaryTextColor
                        : themeColors.primaryMaptualListFilteringColor
                    }
                  >
                    <Typography
                      sx={{
                        ...headerPrimaryText,
                        color: themeColors.primaryMaptualListFilteringColor,
                      }}
                    >
                      {month.toUpperCase()}
                    </Typography>
                    <Typography
                      sx={{
                        ...headerSecondaryText,
                        color: themeColors.primaryMaptualListFilteringColor,
                      }}
                    >
                      {isLastHeader ? `NOW` : year}
                    </Typography>
                  </HeaderCell>
                );
              }
            )}
          </HeaderTableRow>
        </TableHead>

        <TableBody>
          {data.map((row, rowIndex) => (
            <CustomTableRow
              key={`${row.series[0].title}-${rowIndex}`}
              onRowSelect={onRowSelect}
              row={row}
              rowIndex={rowIndex}
              isSelected={rowSelected === rowIndex}
              metricComponentType={metricComponentType}
              timeframeSelection={timeframeSelection}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HistoricalTable;
