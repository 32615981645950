import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { IconButton, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { EntityOverviewContext } from '../../data/entityOverviewContext';

const SearchBox = styled(Box)(({ theme: { themeColors } }) => ({
  width: 200,
  border: `1px solid ${themeColors.textFieldSelectionsDefaultBorderColor}`,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  padding: '8px 12px',
  gap: 5,
  ':hover': {
    borderColor: themeColors.textFieldSelectionsHoverBorderColor,
  },
  ':focus-within': {
    border: `2px solid ${themeColors.textFieldSelectionsActiveBorderColor}`,
  },
}));

const StyledSearchIcon = styled(SearchIcon)(({ theme: { themeColors } }) => ({
  color: themeColors.textFieldSelectionsDefaultIconColor,
  width: 16,
  height: 16,
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme: { themeColors } }) => ({
  color: themeColors.textFieldSelectionsDefaultIconColor,
  width: 16,
  height: 16,
}));

const SearchInput = styled(InputBase)({
  '& input::placeholder': {
    opacity: 1,
  },
});

export const Search = () => {
  const { entityType, setSearchValue } = useContext(EntityOverviewContext);
  const [displayValue, setDisplayValue] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    setDisplayValue('');
  }, [entityType]);

  const handleSearch = (event) => {
    setDisplayValue(event.target.value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      setSearchValue(event.target.value);
    }, 300);

    setTimeoutId(newTimeoutId);
  };

  const handleClear = () => {
    setDisplayValue('');
    setSearchValue(null);
  };

  return (
    <SearchBox>
      <StyledSearchIcon />
      <SearchInput
        placeholder="Search..."
        value={displayValue}
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleSearch}
      />
      {displayValue && displayValue.length > 0 && (
        <IconButton onClick={handleClear}>
          <StyledCloseIcon />
        </IconButton>
      )}
    </SearchBox>
  );
};
