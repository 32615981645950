export const lighten = (color) => `${color}88`;

export const getLegendColors = (index, themeColors) => [
  themeColors.graphLegendColors[index],
  lighten(themeColors.graphLegendColors[index]),
  themeColors.tertiaryColor,
];

export const filterMetricData = (metricData, activeObjective) =>
  metricData.filter(
    (dropdown) =>
      dropdown?.objectiveId === activeObjective?.objectiveId ||
      dropdown?.nonScoringObjective
  );
