import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as requests from '../request/userManagementRequests';

export const getUsersData = createAsyncThunk(
  'userManagement/getUsersData',
  async (_, thunkApi) => {
    try {
      const response = await requests.getUsersData();
      return response.data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Something went wrong with the request', { err });
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  'userManagement/deleteUser',
  async (userId, thunkApi) => {
    try {
      const response = await requests.deleteUser(userId);
      return response.data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Something went wrong with the request', { err });
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'userManagement/resetPassword',
  async (userId, thunkApi) => {
    try {
      const userData = { id: userId };
      const response = await requests.resetPassword(userData);
      return response.data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Something went wrong with the request', { err });
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const sendInviteEmail = createAsyncThunk(
  'userManagement/sendInviteEmail',
  async (userId, thunkApi) => {
    try {
      const userData = { id: userId };
      const response = await requests.sendInviteEmail(userData);
      return response.data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Something went wrong with the request', { err });
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const getCustomerFormData = createAsyncThunk(
  'userManagement/getCustomerFormData',
  async (_, thunkApi) => {
    try {
      const response = await requests.getCustomerFormData();
      return response.data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.toJSON());
      // eslint-disable-next-line no-console
      console.warn('Something went wrong with the request', { err });
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const postUser = createAsyncThunk(
  'userManagement/postUser',
  async (userData, thunkApi) => {
    try {
      const response = await requests.postUser(userData);
      return response.data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Something went wrong with the request', { err });
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const putUser = createAsyncThunk(
  'userManagement/putUser',
  async (userData, thunkApi) => {
    try {
      const response = await requests.putUser(userData);
      return response.data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Something went wrong with the request', { err });
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const patchProject = createAsyncThunk(
  'userManagement/patchProject',
  async (projectData, thunkApi) => {
    try {
      const response = await requests.patchProject(projectData);
      return response.data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Something went wrong with the request', { err });
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const initialState = {
  usersData: [],
  customerFormData: {},
  isUserTableBusy: false,
  userTableStatus: 'IDLE',
  userModalStatus: 'IDLE',
  userPasswordResetStatus: 'IDLE',
  sendInviteEmailStatus: 'IDLE',
  userRequestStatus: 'IDLE',
  projectRequestStatus: 'IDLE',
  isUserModalOpen: false,
  errorMessage: '',
  message: '',
  status: 'IDLE',
  userId: null,
};

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    editUser: (state, action) => {
      state.userId = action.payload;
      state.isUserModalOpen = true;
      if (state.userModalStatus !== 'PENDING') {
        state.userModalStatus = 'IDLE';
      }
    },
    createUser: (state) => {
      state.userId = null;
      state.isUserModalOpen = true;
      if (state.userModalStatus !== 'PENDING') {
        state.userModalStatus = 'IDLE';
      }
    },
    closeUserModal: (state) => {
      state.isUserModalOpen = false;
      state.userRequestStatus = 'IDLE';
      state.userId = null;
    },
    clearStatus: (state, action) => {
      state[action.payload] = 'IDLE';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerFormData.pending, (state) => {
        state.userModalStatus = 'PENDING';
        state.errorMessage = '';
      })
      .addCase(getCustomerFormData.fulfilled, (state, action) => {
        state.userModalStatus = 'SUCCESS';
        state.customerFormData = action.payload;
        state.errorMessage = '';
      })
      .addCase(getCustomerFormData.rejected, (state, action) => {
        state.userModalStatus = 'FAILED';
        state.customerFormData = initialState.customerFormData;
        state.errorMessage = action.payload;
      })
      .addCase(getUsersData.pending, (state) => {
        state.userTableStatus = 'PENDING';
        state.errorMessage = '';
      })
      .addCase(getUsersData.fulfilled, (state, action) => {
        state.userTableStatus = 'SUCCESS';
        state.errorMessage = '';
        state.usersData = action.payload;
      })
      .addCase(getUsersData.rejected, (state, action) => {
        state.userTableStatus = 'ERROR';
        state.usersData = initialState.usersData;
        state.errorMessage = action.payload;
      })
      .addCase(postUser.pending, (state) => {
        state.errorMessage = '';
        state.userRequestStatus = 'PENDING';
      })
      .addCase(postUser.fulfilled, (state) => {
        state.errorMessage = '';
        state.userRequestStatus = 'SUCCESS';
      })
      .addCase(postUser.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.userRequestStatus = 'FAILED';
      })
      .addCase(putUser.pending, (state) => {
        state.errorMessage = '';
        state.userRequestStatus = 'PENDING';
      })
      .addCase(putUser.fulfilled, (state) => {
        state.errorMessage = '';
        state.userRequestStatus = 'SUCCESS';
      })
      .addCase(putUser.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.userRequestStatus = 'FAILED';
      })
      .addCase(deleteUser.pending, (state) => {
        state.userRequestStatus = 'PENDING';
        state.errorMessage = '';
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.userRequestStatus = 'SUCCESS';
        state.errorMessage = '';
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.userRequestStatus = 'ERROR';
        state.errorMessage = action.payload;
      })
      .addCase(resetPassword.pending, (state) => {
        state.userPasswordResetStatus = 'PENDING';
        state.errorMessage = '';
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.userPasswordResetStatus = 'SUCCESS';
        state.errorMessage = '';
        state.message = action.payload;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.userPasswordResetStatus = 'ERROR';
        state.errorMessage = action.payload;
      })
      .addCase(sendInviteEmail.pending, (state) => {
        state.sendInviteEmailStatus = 'PENDING';
        state.errorMessage = '';
      })
      .addCase(sendInviteEmail.fulfilled, (state, action) => {
        state.sendInviteEmailStatus = 'SUCCESS';
        state.errorMessage = '';
        state.message = action.payload;
      })
      .addCase(sendInviteEmail.rejected, (state, action) => {
        state.sendInviteEmailStatus = 'ERROR';
        state.errorMessage = action.payload;
      })
      .addCase(patchProject.pending, (state, action) => {
        state.errorMessage = action.payload;
        state.projectRequestStatus = 'PENDING';
        state.errorMessage = '';
      })
      .addCase(patchProject.fulfilled, (state) => {
        state.projectRequestStatus = 'SUCCESS';
        state.errorMessage = '';
      })
      .addCase(patchProject.rejected, (state, action) => {
        state.projectRequestStatus = 'ERROR';
        state.errorMessage = action.payload;
      });
  },
});

export const { editUser, createUser, closeUserModal, clearStatus } =
  userManagementSlice.actions;

export default userManagementSlice.reducer;
