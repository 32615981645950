import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Switch as MuiSwitch } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export const ToggleTileContainer = styled('div')(() => ({
  padding: '16px',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  background: '#16191F',
  border: '1px solid #313B49',
  alignItems: 'center',
}));

export const ToggleMetadataContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
}));

export const ToggleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
  alignItems: 'center',
}));

export const ToggleName = styled('div')(() => ({
  fontSize: '14px',
  fontWeight: '500',
  color: '#E0E4EB',
  textAlign: 'left',
}));

export const ToggleDescription = styled('div')(() => ({
  fontSize: '14px',
  fontWeight: '400',
  color: '#8595AD',
  textAlign: 'left',
}));

export const FeatureToggleTile = ({
  isGlobal,
  name,
  description,
  isEnabled,
  handleOnToggleChange,
  selectedClientGroup,
  onToggleDelete,
  onEnableToggleForAllClientGroups,
  onDisableToggleForAllClientGroups,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEnableForAll = () => {
    onEnableToggleForAllClientGroups(name);
    handleClose();
  };

  const handleDisableForAll = () => {
    onDisableToggleForAllClientGroups(name);
    handleClose();
  };

  const handleDelete = () => {
    onToggleDelete(name);
    handleClose();
  };
  return (
    <ToggleTileContainer>
      <ToggleContainer>
        {!isGlobal && (
          <MuiSwitch
            checked={isEnabled}
            onChange={() =>
              handleOnToggleChange({
                name,
                isEnabled: !isEnabled,
                clientGroup: selectedClientGroup,
              })
            }
          />
        )}
        <ToggleMetadataContainer>
          <ToggleName>{name}</ToggleName>
          <ToggleDescription>{description}</ToggleDescription>
        </ToggleMetadataContainer>
      </ToggleContainer>
      {isGlobal && (
        <>
          <MoreVertIcon onClick={handleClick} />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {/* <MenuItem onClick={handleClose}>Edit</MenuItem> */}
            <MenuItem onClick={handleEnableForAll}>
              Toggle On For All UserGroups
            </MenuItem>
            <MenuItem onClick={handleDisableForAll}>
              Toggle Off For All Usergroups
            </MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Menu>
        </>
      )}
    </ToggleTileContainer>
  );
};
