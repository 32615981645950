import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import GraphTabs from './graphTabs';

const useStyles = makeStyles(() => ({
  header: {
    height: '22px',
    fontSize: 18,
    fontWeight: 600,
    color: '#E0E4EB',
    marginBottom: '16px',
  },
  subHeaderRow: {
    display: 'flex',
    height: 'fit-content',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  titleContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#A3AFC2',
    fontWeight: 500,
    textAlign: 'left',
    marginBottom: '8px',
  },
  subTitle: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#8595AD',
    fontWeight: 400,
    textAlign: 'left',
  },
  tabContainer: {
    width: '152px',
    maxWidth: '152px',
    height: '40px',
  },
}));

function FrequencyChartHeader({
  chartTabs,
  selectedTab,
  handleTabSelect,
  segmentName,
  isLoading,
}) {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.header}>Frequency Breakdown</Typography>
      <div className={classes.subHeaderRow}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title}>
            {`Regional Customer Guidance Segments Distribution${
              segmentName ? ` - ${segmentName}` : ''
            }`}
          </Typography>
          <Typography className={classes.subTitle}>
            Average dynamic frequency of HCPs in each customer guidance segment
          </Typography>
        </div>
        <div className={classes.tabContainer}>
          {!isLoading && (
            <GraphTabs
              tabs={chartTabs}
              selectedTab={selectedTab}
              handleTabChange={(tab) => {
                handleTabSelect(tab);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default FrequencyChartHeader;
