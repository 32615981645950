import { getDemoAccountStatus } from '../../request/config';

export const indicationsReplacementLookupTable = {
  SpA: 'Indication 01',
  RA: 'Indication 02',
  PsA: 'Indication 03',
  PsO: 'Indication 04',
  AS: 'Indication 05',
  Diab: 'Indication 06',
  Diabetes: 'Indication 06',
  AOM: 'Indication 07',
  Obesity: 'Indication 08',
  ONC: 'Indication 09',
  OAB: 'Indication 10',
  'GLP-1': 'Indication 11',
};

const regexIndicatorBuilder = (indication) => `(\\b)${indication}(\\b)`;

export function replaceIndications(source = '') {
  const indicationKeys = Object.keys(indicationsReplacementLookupTable);
  let result = source;

  indicationKeys.forEach((indication) => {
    result = result.replace(
      new RegExp(regexIndicatorBuilder(indication), 'i'),
      indicationsReplacementLookupTable[indication]
    );
  });

  return result;
}

export function anonymizeInsightsSummaries(summaries = []) {
  return summaries.map((summary) => ({
    ...summary,
    description: replaceIndications(summary.description),
  }));
}

export const anonymizeGetEntityResponse = () => (next) => (action) => {
  const modifiedAction = action;
  const isDemo = getDemoAccountStatus();

  if (
    isDemo &&
    action.type === 'PROJECT_GET_ENTITY_BY_MAPTUALLIST_ID_FULFILLED'
  ) {
    const { metadata } = modifiedAction.payload;
    const currentHCPId = metadata.entityId;
    const fakeFullAddress = `Address ${currentHCPId}`;

    modifiedAction.payload = {
      ...modifiedAction.payload,
      entityJourney: {
        ...modifiedAction.payload.entityJourney,
        timeline: modifiedAction.payload.entityJourney.timeline.map(
          (timelineItem) => ({
            ...timelineItem,
            secondaryText: replaceIndications(timelineItem.secondaryText),
          })
        ),
      },
      entityRawData: {
        ...modifiedAction.payload.entityRawData,
      },
      entityUData: {
        ...modifiedAction.payload.entityUData,
        education: {
          ...modifiedAction.payload.entityUData.education,
          undergradUniversity: 'University Name',
          graduateUniversity: modifiedAction.payload.entityUData.education
            .graduateUniversity
            ? 'University Name'
            : '',
        },
        contactInfo: {
          address: fakeFullAddress,
          email: 'myemail@fake.email',
          phone: '1-800-555-9999',
        },
        entityName: {
          firstName: 'Entity',
          lastName: `Name ${currentHCPId}`,
          middleName: '',
        },
        location: {
          ...modifiedAction.payload.entityUData.location,
          hospitalName: `Downtown Hospital ${currentHCPId}`,
          locationName: `Hospital Location`,
          locationSubUnit: `Sub Unit ${currentHCPId}`,
          address: fakeFullAddress,
          cityTown: 'Townsville',
          continent: 'Across the seven seas',
          country: 'Atlantis',
          countryShort: 'AT',
          hospital: `Downtown Hospital ${currentHCPId}`,
          stateProv: 'AT',
          stateProvShort: 'AT',
          zipPostal: 'ZIP CODE',
        },
        originalUserId: currentHCPId,
        socialMedia: {
          linkedIn: {
            connectionCount: '500+',
            url: 'https://www.linkedin.com/',
          },
        },
        specialty: {
          ...modifiedAction.payload.entityUData.specialty,
          primary: 'Specialty',
          secondary: 'SPC',
        },
      },
      metadata: {
        ...modifiedAction.payload.metadata,
        entityName: `Entity Name ${currentHCPId}`,
      },
      maptualInsights: {
        ...modifiedAction.payload.maptualInsights,
      },
    };
  }

  return next(modifiedAction);
};
