import React from 'react';
import { Button, styled } from '@mui/material';

const StyledButton = styled(Button)({
  fontSize: 14,
  lineHeight: '140%',
});

export default function NewProjectTemplateButton({
  disabled = false,
  onClick = () => null,
}) {
  return (
    <StyledButton
      variant="outlined"
      aria-label="new"
      disabled={disabled}
      onClick={onClick}
    >
      New Template
    </StyledButton>
  );
}
