import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getDemoAccountStatus, handleSpecialTypes } from '../../request/config';
// import { getFeatureTogglesv2 } from '../../request/projectRequests';
import { getCurrentUser } from '../../request/requests';
import { trackUser } from '../../trackers/appEventTracker';
import { APPVIEWS } from '../../constants';
import { getProjectList as getProjects } from '../../request/projectRequests';
import { anonymizeProjectList } from '../../middlewares/anonymize/getProject';
import { sortByNewestCreation } from '../../utils/sortProjects';

const loadingProfile = Object.freeze({
  profileId: 'Loading',
  name: 'Loading',
  title: '',
  role: {
    type: 'UNKNOWN',
    subType: 'UNKNOWN',
    permission: 'UNKNOWN',
  },
  imageUri: null,
});

const initialSettingsState = Object.freeze({
  enableEventLogger: false,
  showEvents: false,
});

const getUserProfile = async () => {
  const response = await getCurrentUser();
  trackUser(response.data.userId);
  return response.data;
};

const getProjectList = async () => {
  const response = await getProjects();
  return response.data;
};
export const useApplicationContext = () => {
  const [events, setEvents] = useState([]);
  const [settings, setSettings] = useState(initialSettingsState);
  const [profile, setProfile] = useState(loadingProfile);
  const {
    isLoading: isProfileLoading,
    isError: isProfileError,
    data: userProfile,
    error: profileError,
  } = useQuery('user-profile', getUserProfile);

  useEffect(() => {
    if (!isProfileLoading && !!userProfile) {
      setProfile(userProfile);
      handleSpecialTypes(userProfile);

      const isDemoAccount = getDemoAccountStatus();
      if (userProfile?.role?.type === APPVIEWS.ADMIN && !isDemoAccount) {
        setSettings({ ...settings, enableEventLogger: true });
      }
    }
    if (isProfileError && !!profileError) {
      // eslint-disable-next-line no-console
      console.error(
        '[ERROR] Fetching user profile information has failed',
        profileError
      );
      setProfile({ error: profileError });
    }
  }, [isProfileLoading, userProfile]);

  const [projectList, setProjectList] = useState([]);

  const {
    isLoading: isProjectListLoading,
    data: projectListData,
    refetch: refetchProjectList,
  } = useQuery(['project-list', profile?.userId], getProjectList);

  useEffect(() => {
    if (userProfile && !isProjectListLoading && !!projectListData) {
      const isDemoAccount = getDemoAccountStatus();
      const sortedProjectList = sortByNewestCreation(projectListData);
      if (isDemoAccount) {
        setProjectList(anonymizeProjectList(sortedProjectList));
      } else {
        setProjectList(sortedProjectList);
      }
    }
  }, [isProjectListLoading, projectListData, userProfile]);

  const updateUserPreferences = (newPreferences) => {
    setProfile({
      ...profile,
      preference: newPreferences,
    });
  };

  return {
    profile,
    events,
    settings,
    projectList,
    isProjectListLoading,
    setEvents,
    setSettings,
    refetchProjectList,
    updateUserPreferences,
  };
};
