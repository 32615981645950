import React from 'react';
import { Typography, Button } from '@mui/material';
import styled from '@emotion/styled';

const StyledRegionSelectionButton = styled(Button)(
  ({ theme: { themeColors } }) => ({
    display: 'flex',
    height: '34px',
    padding: '8px 12px',
    alignItems: 'center',
    backgroundColor: themeColors.maptualListFilteringBadgeColor,
    color: themeColors.badgePrimaryColor,
    borderRadius: 4,
    border: `1px solid ${themeColors.buttonBorderColor}`,
    background: themeColors.buttonBackgroundColor,
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: themeColors.segmentSelectionHoverBackgroundColor,
    },
  })
);

const StyledRegionName = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.segmentSelectionCaptionColor,
  fontFamily: 'Roboto',
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
}));

export const RegionSelectionButton = ({ regionName, onClick }) => (
  <StyledRegionSelectionButton
    aria-label="navigation"
    onClick={() => onClick()}
  >
    <StyledRegionName>{regionName}</StyledRegionName>
  </StyledRegionSelectionButton>
);
