import React, { useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CircularProgress from '@mui/material/CircularProgress';
import LinkController from '../../containers/links/linkController';

const useStyles = makeStyles(() => ({
  downloadIcon: {
    marginRight: 10,
  },
  margin: {
    margin: 0,
    paddingRight: 10,
    width: '100%',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function DownloadButton({
  type = 'URL_HYPERLINK',
  linkLocation = 'EXTERNAL',
  linkUrl,
  match,
  buttonTitle = 'Download Snapshot',
  style = {},
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const controllerRef = useRef();

  const handleButtonClick = () => {
    if (controllerRef?.current) controllerRef.current.click();
  };

  return (
    <div className={classes.wrapper}>
      <Button
        variant="contained"
        color="primary"
        aria-label="download"
        startIcon={<CloudDownloadIcon className={classes.downloadIcon} />}
        disabled={loading}
        onClick={handleButtonClick}
        style={style}
      >
        {buttonTitle}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
      <LinkController
        setLoading={setLoading}
        ref={controllerRef}
        type={type}
        linkLocation={linkLocation}
        linkUrl={linkUrl}
        match={match}
      />
    </div>
  );
}
