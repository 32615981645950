import ryan from '../../assets/demo/ryan.jpeg';
import kelly from '../../assets/demo/kelly.jpeg';
import michael from '../../assets/demo/michael.jpeg';
import karin from '../../assets/demo/karin.jpeg';
import federico from '../../assets/demo/federico.jpeg';
import cletus from '../../assets/demo/cletus.jpeg';
import alex from '../../assets/demo/alex.jpeg';
import zhao from '../../assets/demo/zhao.jpeg';
import nadine from '../../assets/demo/nadine.jpeg';
import ash from '../../assets/demo/ash.jpeg';

export const DemoObjectives = [
  {
    analysisCategory: '',
    isBusy: false, // if the analysis has not been finished set this to true, otherwise set it to false even for errored case
    isErrored: false, // set to true when error is detected
    parentIsBusy: false,
    analysisId: '09405eb37ba94107a1a411ac99534c50', // uuid
    analysisGoal: '',
    analysisType: 'NEXT_BEST_ACTION', // see analysisType information below
    analysisName: 'Trinza Conversion from Sustenna',
    analysisIcon: '',
  },
  {
    analysisCategory: '',
    isBusy: false, // if the analysis has not been finished set this to true, otherwise set it to false even for errored case
    isErrored: false, // set to true when error is detected
    parentIsBusy: false,
    analysisId: '09405eb37ba94107a1a411ac99534c51', // uuid
    analysisGoal: '',
    analysisType: 'NEXT_BEST_ACTION', // see analysisType information below
    analysisName: 'Pysch Orals to Injectables',
    analysisIcon: '',
  },
  {
    analysisCategory: '',
    isBusy: false, // if the analysis has not been finished set this to true, otherwise set it to false even for errored case
    isErrored: false, // set to true when error is detected
    parentIsBusy: false,
    analysisId: '09405eb37ba94107a1a411ac99534c53', // uuid
    analysisGoal: '',
    analysisType: 'NEXT_BEST_ACTION', // see analysisType information below
    analysisName: 'Eloqua Campaign',
    analysisIcon: '',
  },
  {
    analysisCategory: '',
    isBusy: false, // if the analysis has not been finished set this to true, otherwise set it to false even for errored case
    isErrored: false, // set to true when error is detected
    parentIsBusy: false,
    analysisId: '09405eb37ba94107a1a411ac99534c54', // uuid
    analysisGoal: '',
    analysisType: 'NEXT_BEST_ACTION', // see analysisType information below
    analysisName: 'Rheumatoid Email Effectivenss',
    analysisIcon: '',
  },
];

export const DemoDoctors = [
  {
    clientId: 'd34a22f90', // unique clientId
    firstName: 'Ryan',
    middleName: '',
    preferredName: '',
    lastName: 'Todd',
    title: 'Dr.', // Dr., Mr., Miss, etc
    contactInfo: {
      // contact info could be flexible to support multiple entrees in the future
      cell: '',
      phone: '416-494-2344',
      email: 'ryan.todd@gmail.com',
      address: '5 Main Street, Calgary',
    },
    staticInfo: {
      Gender: 'Male',
      Language: 'English',
      Province: 'AB',
      City: 'Calgary',
      'Address Indicator': '',
      Specialty: 'PSY',
      Specialty2: '',
      'Graduation Year': '2017',
    }, // Static information related to specific industry or business
    imageUri: ryan, // client profile picture
  },
  {
    clientId: 'd34a22f91', // unique clientId
    firstName: 'Kelly',
    middleName: '',
    preferredName: '',
    lastName: 'Brogan',
    title: 'Dr.', // Dr., Mr., Miss, etc
    contactInfo: {
      // contact info could be flexible to support multiple entrees in the future
      cell: '',
      phone: '416-494-1234',
      email: 'kelly.brogan@gmail.com',
      address: '1211 Yonge Street, Toronto',
    },
    staticInfo: {
      Gender: 'Female',
      Language: 'English',
      Province: 'ON',
      City: 'Hamilton',
      'Address Indicator': '',
      Specialty: 'PSY',
      Specialty2: '',
      'Graduation Year': '2000',
    }, // Static information related to specific industry or business
    imageUri: kelly, // client profile picture
  },
  {
    clientId: 'd34a22f92', // unique clientId
    firstName: 'Michael',
    middleName: '',
    preferredName: '',
    lastName: 'Colleton',
    title: 'Dr.', // Dr., Mr., Miss, etc
    contactInfo: {
      // contact info could be flexible to support multiple entrees in the future
      cell: '',
      phone: '416-494-1234',
      email: 'michael.colleton@gmail.com',
      address: '2421 Bloor West, Etobicoke',
    },
    staticInfo: {
      Gender: 'Male',
      Language: 'English',
      Province: 'ON',
      City: 'Etobicoke',
      'Address Indicator': '',
      Specialty: 'PSY',
      Specialty2: '',
      'Graduation Year': '2014',
    }, // Static information related to specific industry or business
    imageUri: michael, // client profile picture
  },
  {
    clientId: 'd34a22f93', // unique clientId
    firstName: 'Karin',
    middleName: '',
    preferredName: '',
    lastName: 'Brown',
    title: 'Dr.', // Dr., Mr., Miss, etc
    contactInfo: {
      // contact info could be flexible to support multiple entrees in the future
      cell: '',
      phone: '416-494-1234',
      email: 'karin.brown@gmail.com',
      address: '100 Dundas St., Toronto',
    },
    staticInfo: {
      Gender: 'Female',
      Language: 'English',
      Province: 'ON',
      City: 'Toronto',
      'Address Indicator': '',
      Specialty: 'PSY',
      Specialty2: '',
      'Graduation Year': '2001',
    }, // Static information related to specific industry or business
    imageUri: karin, // client profile picture
  },
  {
    clientId: 'd34a22f94', // unique clientId
    firstName: 'Ash',
    middleName: '',
    preferredName: '',
    lastName: 'Bender',
    title: 'Dr.', // Dr., Mr., Miss, etc
    contactInfo: {
      // contact info could be flexible to support multiple entrees in the future
      cell: '',
      phone: '416-494-1234',
      email: 'ash.bender@gmail.com',
      address: '342 Spadina Ave, Toronto',
    },
    staticInfo: {
      Gender: 'Male',
      Language: 'English',
      Province: 'ON',
      City: 'Toronto',
      'Address Indicator': '',
      Specialty: 'PSY',
      Specialty2: '',
      'Graduation Year': '2004',
    }, // Static information related to specific industry or business
    imageUri: ash, // client profile picture
  },
  {
    clientId: 'd34a22f95', // unique clientId
    firstName: 'Federico',
    middleName: '',
    preferredName: '',
    lastName: 'Allodi',
    title: 'Dr.', // Dr., Mr., Miss, etc
    contactInfo: {
      // contact info could be flexible to support multiple entrees in the future
      cell: '',
      phone: '416-494-1234',
      email: 'federico.allodi@gmail.com',
      address: '21 Nelson St., Toronto',
    },
    staticInfo: {
      Gender: 'Male',
      Language: 'English',
      Province: 'ON',
      City: 'Toronto',
      'Address Indicator': '',
      Specialty: 'PSY',
      Specialty2: '',
      'Graduation Year': '1966',
    }, // Static information related to specific industry or business
    imageUri: federico, // client profile picture
  },
  {
    clientId: 'd34a22f96', // unique clientId
    firstName: 'Cletus',
    middleName: 'Chike',
    preferredName: '',
    lastName: 'Okonkwo',
    title: 'Dr.', // Dr., Mr., Miss, etc
    contactInfo: {
      // contact info could be flexible to support multiple entrees in the future
      cell: '',
      phone: '509-494-1234',
      email: 'cletus.okonkwo@gmail.com',
      address: '102 columbia st, Kitchener',
    },
    staticInfo: {
      Gender: 'Male',
      Language: 'English',
      Province: 'ON',
      City: 'Kitchener',
      'Address Indicator': '',
      Specialty: 'PSY',
      Specialty2: '',
      'Graduation Year': '2009',
    }, // Static information related to specific industry or business
    imageUri: cletus, // client profile picture
  },
  {
    clientId: 'd34a22f97', // unique clientId
    firstName: 'Alexandra',
    middleName: '',
    preferredName: '',
    lastName: 'Cristian',
    title: 'Dr.', // Dr., Mr., Miss, etc
    contactInfo: {
      // contact info could be flexible to support multiple entrees in the future
      cell: '',
      phone: '416-494-1234',
      email: 'alexandra.cristian@gmail.com',
      address: '67 Queen St. West, Toronto',
    },
    clientProducts: ['Invega', 'Invega Sustenna', 'Invega Trinza'],
    staticInfo: {
      Gender: 'Female',
      Language: 'English',
      Province: 'ON',
      City: 'Toronto',
      'Address Indicator': '',
      Specialty: 'PSY',
      Specialty2: '',
      'Graduation Year': '2007',
    }, // Static information related to specific industry or business
    imageUri: alex, // client profile picture
  },
  {
    clientId: 'd34a22f98', // unique clientId
    firstName: 'Si Yu',
    middleName: '',
    preferredName: '',
    lastName: 'Zhao',
    title: 'Dr.', // Dr., Mr., Miss, etc
    contactInfo: {
      // contact info could be flexible to support multiple entrees in the future
      cell: '',
      phone: '416-494-1234',
      email: 'si.zhao@gmail.com',
      address: '1123 Hwy 7, Markham',
    },
    staticInfo: {
      Gender: 'Male',
      Language: 'English, Chinese',
      Province: 'ON',
      City: 'Markham',
      'Address Indicator': '',
      Specialty: 'FAMILY',
      Specialty2: '',
      'Graduation Year': '2017',
    }, // Static information related to specific industry or business
    imageUri: zhao, // client profile picture
  },
  {
    clientId: 'd34a22f99', // unique clientId
    firstName: 'Nadine',
    middleName: '',
    preferredName: '',
    lastName: 'Laraya',
    title: 'Dr.', // Dr., Mr., Miss, etc
    contactInfo: {
      // contact info could be flexible to support multiple entrees in the future
      cell: '',
      phone: '416-494-1234',
      email: 'nadine.laraya@gmail.com',
      address: '1123 Hwy 7, Markham',
    },
    staticInfo: {
      Gender: 'Female',
      Language: 'French',
      Province: 'ON',
      City: 'Toronto',
      'Address Indicator': '',
      Specialty: 'FAMILY',
      Specialty2: '',
      'Graduation Year': '2008',
    }, // Static information related to specific industry or business
    imageUri: nadine, // client profile picture
  },
];

const taskItems = {
  '823f2fa343bfff': [
    {
      taskItemId: 'a34bf60293e0',
      taskClient: { ...DemoDoctors[0] },
      taskReasons: ['priority: P1', 'years since grad', 'segment: Partners'],
      taskConfidence: 4.0,
      taskAlternatives: [
        {
          taskName: 'Template - Sales Teplate A001 ',
          taskId: '843e2fa343bb46',
          taskConfidence: 3.5,
        },
        {
          taskName: 'Template - Sales Teplate A002 ',
          taskId: '843e2fa343bb47',
          taskConfidence: 2.0,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e1',
      taskClient: { ...DemoDoctors[1] },
      taskReasons: ['years since grad', 'province: ON', 'interest: EMG'],
      taskConfidence: 3.5,
      taskAlternatives: [
        {
          taskName: 'Short Call',
          taskId: '823f2fa343bb41',
          taskConfidence: 3.1,
        },
        {
          taskName: 'Template - Sales Teplate A001 ',
          taskId: '843e2fa343bb46',
          taskConfidence: 3.0,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e2',
      taskClient: { ...DemoDoctors[2] },
      taskReasons: [
        'physician status: A',
        'province: ON',
        'emailed 3 months ago',
      ],
      taskConfidence: 3.9,
      taskAlternatives: [
        {
          taskName: 'Short Call',
          taskId: '823f2fa343bb41',
          taskConfidence: 3.7,
        },
        {
          taskName: 'Template - Sales Teplate A002 ',
          taskId: '843e2fa343bb47',
          taskConfidence: 1.8,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e3',
      taskClient: { ...DemoDoctors[3] },
      taskReasons: [
        'emailed 1 month ago',
        'specialty: PSY',
        'called 1 month ago',
      ],
      taskConfidence: 3.6,
      taskAlternatives: [
        {
          taskName: 'Short Call',
          taskId: '823f2fa343bb41',
          taskConfidence: 3.3,
        },
        {
          taskName: 'Template - FollowUp Template F004',
          taskId: '843e2fa343bb49',
          taskConfidence: 2.6,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e4',
      taskClient: { ...DemoDoctors[4] },
      taskReasons: [
        'emailed 1 month ago',
        'physician status: A',
        'province: ON',
      ],
      taskConfidence: 3.0,
      taskAlternatives: [
        {
          taskName: 'Short Call',
          taskId: '823f2fa343bb41',
          taskConfidence: 2.8,
        },
        {
          taskName: 'Template - Sales Teplate A002 ',
          taskId: '843e2fa343bb47',
          taskConfidence: 2.7,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e5',
      taskClient: { ...DemoDoctors[5] },
      taskReasons: [
        'precondition prev 3 months',
        'interest: PSY',
        'province: ON',
      ],
      taskConfidence: 2.9,
      taskAlternatives: [
        {
          taskName: 'Short Call',
          taskId: '823f2fa343bb41',
          taskConfidence: 2.5,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e6',
      taskClient: { ...DemoDoctors[6] },
      taskReasons: ['years since grad', 'interest: PSY', 'interest: EMG'],
      taskConfidence: 3.2,
      taskAlternatives: [
        {
          taskName: 'Short Call',
          taskId: '823f2fa343bb41',
          taskConfidence: 3.0,
        },
        {
          taskName: 'Template - Sales Teplate A001 ',
          taskId: '843e2fa343bb46',
          taskConfidence: 2.2,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e7',
      taskClient: { ...DemoDoctors[7] },
      taskReasons: [
        'called 2 weeks ago',
        'years since grad',
        'segment: Partners',
      ],
      taskConfidence: 4.0,
      taskAlternatives: [
        {
          taskName: 'Template - Sales Teplate A001 ',
          taskId: '843e2fa343bb46',
          taskConfidence: 3.8,
        },
        {
          taskName: 'Template - Sales Teplate A002 ',
          taskId: '843e2fa343bb47',
          taskConfidence: 3.6,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e8',
      taskClient: { ...DemoDoctors[8] },
      taskReasons: ['specialty2: GP/FM', 'years since grad', 'interest: PSY'],
      taskConfidence: 2.9,
      taskAlternatives: [
        {
          taskName: 'Template - Sales Teplate A001 ',
          taskId: '843e2fa343bb46',
          taskConfidence: 2.3,
        },
        {
          taskName: 'Template - Sales Teplate A002 ',
          taskId: '843e2fa343bb47',
          taskConfidence: 2.2,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e9',
      taskClient: { ...DemoDoctors[9] },
      taskReasons: ['specialty2: GP/FM', 'called 2 weeks ago', 'interest: PSY'],
      taskConfidence: 3.4,
      taskAlternatives: [
        {
          taskName: 'Template - Sales Teplate A001 ',
          taskId: '843e2fa343bb46',
          taskConfidence: 2.2,
        },
        {
          taskName: 'Template - Sales Teplate A002 ',
          taskId: '843e2fa343bb47',
          taskConfidence: 1.5,
        },
      ],
    },
  ],
  '843e2fa343bb46': [
    {
      taskItemId: 'a34bf60293e5',
      taskClient: { ...DemoDoctors[5] },
      taskReasons: [
        'precondition prev 3 months',
        'interest: PSY',
        'province: ON',
      ],
      taskConfidence: 2.9,
      taskAlternatives: [
        {
          taskName: 'Short Call',
          taskId: '823f2fa343bb41',
          taskConfidence: 2.5,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e6',
      taskClient: { ...DemoDoctors[6] },
      taskReasons: ['years since grad', 'interest: PSY', 'interest: EMG'],
      taskConfidence: 3.2,
      taskAlternatives: [
        {
          taskName: 'Short Call',
          taskId: '823f2fa343bb41',
          taskConfidence: 3.0,
        },
        {
          taskName: 'Template - Sales Teplate A001 ',
          taskId: '843e2fa343bb46',
          taskConfidence: 2.2,
        },
      ],
    },
  ],
  '843e2fa343bb47': [
    {
      taskItemId: 'a34bf60293e9',
      taskClient: { ...DemoDoctors[9] },
      taskReasons: ['specialty2: GP/FM', 'called 2 weeks ago', 'interest: PSY'],
      taskConfidence: 3.4,
      taskAlternatives: [
        {
          taskName: 'Template - Sales Teplate A001 ',
          taskId: '843e2fa343bb46',
          taskConfidence: 2.2,
        },
        {
          taskName: 'Template - Sales Teplate A002 ',
          taskId: '843e2fa343bb47',
          taskConfidence: 1.5,
        },
      ],
    },
  ],
  '843e2fa343bb48': [
    {
      taskItemId: 'a34bf60293e1',
      taskClient: { ...DemoDoctors[1] },
      taskReasons: ['years since grad', 'province: ON', 'interest: EMG'],
      taskConfidence: 3.5,
      taskAlternatives: [
        {
          taskName: 'Short Call',
          taskId: '823f2fa343bb41',
          taskConfidence: 3.1,
        },
        {
          taskName: 'Template - Sales Teplate A001 ',
          taskId: '843e2fa343bb46',
          taskConfidence: 3.0,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e2',
      taskClient: { ...DemoDoctors[2] },
      taskReasons: [
        'physician status: A',
        'province: ON',
        'emailed 3 months ago',
      ],
      taskConfidence: 3.9,
      taskAlternatives: [
        {
          taskName: 'Short Call',
          taskId: '823f2fa343bb41',
          taskConfidence: 3.7,
        },
        {
          taskName: 'Template - Sales Teplate A002 ',
          taskId: '843e2fa343bb47',
          taskConfidence: 1.8,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e3',
      taskClient: { ...DemoDoctors[3] },
      taskReasons: [
        'emailed 1 month ago',
        'specialty: PSY',
        'called 1 month ago',
      ],
      taskConfidence: 3.6,
      taskAlternatives: [
        {
          taskName: 'Short Call',
          taskId: '823f2fa343bb41',
          taskConfidence: 3.3,
        },
        {
          taskName: 'Template - FollowUp Template F004',
          taskId: '843e2fa343bb49',
          taskConfidence: 2.6,
        },
      ],
    },
  ],
  '843e2fa343bb49': [
    {
      taskItemId: 'a34bf60293e0',
      taskClient: { ...DemoDoctors[0] },
      taskReasons: ['priority: P1', 'years since grad', 'segment: Partners'],
      taskConfidence: 4.0,
      taskAlternatives: [
        {
          taskName: 'Template - Sales Teplate A001 ',
          taskId: '843e2fa343bb46',
          taskConfidence: 3.5,
        },
        {
          taskName: 'Template - Sales Teplate A002 ',
          taskId: '843e2fa343bb47',
          taskConfidence: 2.0,
        },
      ],
    },
  ],
  '823f2fa343bb41': [
    {
      taskItemId: 'a34bf60293e2',
      taskClient: { ...DemoDoctors[2] },
      taskReasons: [
        'physician status: A',
        'province: ON',
        'emailed 3 months ago',
      ],
      taskConfidence: 3.9,
      taskAlternatives: [
        {
          taskName: 'Short Call',
          taskId: '823f2fa343bb41',
          taskConfidence: 3.7,
        },
        {
          taskName: 'Template - Sales Teplate A002 ',
          taskId: '843e2fa343bb47',
          taskConfidence: 1.8,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e3',
      taskClient: { ...DemoDoctors[3] },
      taskReasons: [
        'emailed 1 month ago',
        'specialty: PSY',
        'called 1 month ago',
      ],
      taskConfidence: 3.6,
      taskAlternatives: [
        {
          taskName: 'Short Call',
          taskId: '823f2fa343bb41',
          taskConfidence: 3.3,
        },
        {
          taskName: 'Template - FollowUp Template F004',
          taskId: '843e2fa343bb49',
          taskConfidence: 2.6,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e4',
      taskClient: { ...DemoDoctors[4] },
      taskReasons: [
        'emailed 1 month ago',
        'physician status: A',
        'province: ON',
      ],
      taskConfidence: 3.0,
      taskAlternatives: [
        {
          taskName: 'Short Call',
          taskId: '823f2fa343bb41',
          taskConfidence: 2.8,
        },
        {
          taskName: 'Template - Sales Teplate A002 ',
          taskId: '843e2fa343bb47',
          taskConfidence: 2.7,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e5',
      taskClient: { ...DemoDoctors[5] },
      taskReasons: [
        'precondition prev 3 months',
        'interest: PSY',
        'province: ON',
      ],
      taskConfidence: 2.9,
      taskAlternatives: [
        {
          taskName: 'Short Call',
          taskId: '823f2fa343bb41',
          taskConfidence: 2.5,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e6',
      taskClient: { ...DemoDoctors[6] },
      taskReasons: ['years since grad', 'interest: PSY', 'interest: EMG'],
      taskConfidence: 3.2,
      taskAlternatives: [
        {
          taskName: 'Short Call',
          taskId: '823f2fa343bb41',
          taskConfidence: 3.0,
        },
        {
          taskName: 'Template - Sales Teplate A001 ',
          taskId: '843e2fa343bb46',
          taskConfidence: 2.2,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e7',
      taskClient: { ...DemoDoctors[7] },
      taskReasons: [
        'called 2 weeks ago',
        'years since grad',
        'segment: Partners',
      ],
      taskConfidence: 4.0,
      taskAlternatives: [
        {
          taskName: 'Template - Sales Teplate A001 ',
          taskId: '843e2fa343bb46',
          taskConfidence: 3.8,
        },
        {
          taskName: 'Template - Sales Teplate A002 ',
          taskId: '843e2fa343bb47',
          taskConfidence: 3.6,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e8',
      taskClient: { ...DemoDoctors[8] },
      taskReasons: ['specialty2: GP/FM', 'years since grad', 'interest: PSY'],
      taskConfidence: 2.9,
      taskAlternatives: [
        {
          taskName: 'Template - Sales Teplate A001 ',
          taskId: '843e2fa343bb46',
          taskConfidence: 2.3,
        },
        {
          taskName: 'Template - Sales Teplate A002 ',
          taskId: '843e2fa343bb47',
          taskConfidence: 2.2,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e9',
      taskClient: { ...DemoDoctors[9] },
      taskReasons: ['specialty2: GP/FM', 'called 2 weeks ago', 'interest: PSY'],
      taskConfidence: 3.4,
      taskAlternatives: [
        {
          taskName: 'Template - Sales Teplate A001 ',
          taskId: '843e2fa343bb46',
          taskConfidence: 2.2,
        },
        {
          taskName: 'Template - Sales Teplate A002 ',
          taskId: '843e2fa343bb47',
          taskConfidence: 1.5,
        },
      ],
    },
  ],
  '843e2fa343bb42': [
    {
      taskItemId: 'a34bf60293e8',
      taskClient: { ...DemoDoctors[8] },
      taskReasons: ['specialty2: GP/FM', 'years since grad', 'interest: PSY'],
      taskConfidence: 2.9,
      taskAlternatives: [
        {
          taskName: 'Template - Sales Teplate A001 ',
          taskId: '843e2fa343bb46',
          taskConfidence: 2.3,
        },
        {
          taskName: 'Template - Sales Teplate A002 ',
          taskId: '843e2fa343bb47',
          taskConfidence: 2.2,
        },
      ],
    },
    {
      taskItemId: 'a34bf60293e9',
      taskClient: { ...DemoDoctors[9] },
      taskReasons: ['specialty2: GP/FM', 'called 2 weeks ago', 'interest: PSY'],
      taskConfidence: 3.4,
      taskAlternatives: [
        {
          taskName: 'Template - Sales Teplate A001 ',
          taskId: '843e2fa343bb46',
          taskConfidence: 2.2,
        },
        {
          taskName: 'Template - Sales Teplate A002 ',
          taskId: '843e2fa343bb47',
          taskConfidence: 1.5,
        },
      ],
    },
  ],
};

export const DemoTasks = [
  {
    taskName: 'Eloqua Campaign',
    taskId: '823f2fa343bfff',
    taskIcon: 'Loyalty',
    taskCount: 10,
    taskItems: [...taskItems['823f2fa343bfff']],
  },
  {
    taskName: 'Email',
    taskId: '843e2fa343bb45',
    taskIcon: 'Email',
    taskCount: 7,
    taskItems: [],
    subTasks: [
      {
        taskName: 'Template - Sales Teplate A001',
        taskId: '843e2fa343bb46',
        taskIcon: '',
        taskCount: 2,
        taskItems: [...taskItems['843e2fa343bb46']],
      },
      {
        taskName: 'Template - Sales Teplate A002',
        taskId: '843e2fa343bb47',
        taskIcon: '',
        taskCount: 1,
        taskItems: [...taskItems['843e2fa343bb47']],
      },
      {
        taskName: 'Template - Sales Teplate A003',
        taskId: '843e2fa343bb48',
        taskIcon: '',
        taskCount: 3,
        taskItems: [...taskItems['843e2fa343bb48']],
      },
      {
        taskName: 'Template - FollowUp Template F004',
        taskId: '843e2fa343bb49',
        taskIcon: '',
        taskCount: 1,
        taskItems: [...taskItems['843e2fa343bb49']],
      },
    ],
  },
  {
    taskName: 'Call',
    taskId: '823f2fa343bb40',
    taskIcon: 'Phone',
    taskCount: 10,
    taskItems: [],
    subTasks: [
      {
        taskName: 'Short Call',
        taskId: '823f2fa343bb41',
        taskIcon: '',
        taskCount: 8,
        taskItems: [...taskItems['823f2fa343bb41']],
      },
      {
        taskName: 'Commercial Call',
        taskId: '843e2fa343bb42',
        taskIcon: '',
        taskCount: 2,
        taskItems: [...taskItems['843e2fa343bb42']],
      },
    ],
  },
  {
    taskName: 'No Actions Required',
    taskId: '823f2fa343bc3',
    taskIcon: null, // 'NotificationsPaused',
    taskCount: 0,
    taskItems: [],
    isNoAction: true,
  },
];

export const DemoFullClientData = {
  clientProfile: { ...DemoDoctors[7] },
  clientGoals: [
    {
      metadata: {
        analysisCategory: '',
        isBusy: false, // if the analysis has not been finished set this to true, otherwise set it to false even for errored case
        isErrored: false, // set to true when error is detected
        parentIsBusy: false,
        analysisId: '09405eb37ba94107a1a411ac99534c50', // uuid
        analysisGoal: '',
        analysisType: 'NEXT_BEST_ACTION', // see analysisType information below
        analysisName: 'Trinza Conversion from Sustenna',
        analysisIcon: '',
      },
      goalProduct: ['Invega Trinza'],
      segment: {},
      historicalJourney: {
        journeySequence: [
          {
            activityType: 'Call',
            activityIcon: 'Phone', // This is assigned manually to activities types, must use valid material icon names
            ctxColumn: 'Call Type',
            ctxValue: 'Short Call',
            timeStamp: 1547056552, // UTC timestamp , can be omitted for historical representative journeys
            timeGapToNextEvent: 1586, // millisecond value,
            timeGapBin: 0,
            count: 1,
          },
          {
            activityType: 'Product Order',
            activityIcon: 'ShoppingCart', // This is assigned manually to activities types, must use valid material icon names
            ctxColumn: 'Product',
            ctxValue: 'Sustenna',
            timeStamp: 1548498652, // UTC timestamp , can be omitted for representative journeys
            timeGapToNextEvent: 1586, // millisecond value,
            timeGapBin: 2,
            count: 1,
          },
          {
            activityType: 'Email',
            activityIcon: 'Email', // This is assigned manually to activities types, must use valid material icon names
            ctxColumn: 'Template',
            ctxValue: 'LAI Treatment Guidelines',
            timeStamp: 1568892772, // UTC timestamp , can be omitted for representative journeys
            timeGapToNextEvent: 1586, // millisecond value,
            timeGapBin: 1,
            count: 1,
          },
          {
            activityType: 'Product Start',
            activityIcon: 'AddShoppingCart', // This is assigned manually to activities types, must use valid material icon names
            ctxColumn: 'Product',
            ctxValue: 'Trinza',
            timeStamp: 1572963712, // UTC timestamp , can be omitted for representative journeys
            timeGapToNextEvent: 1586, // millisecond value,
            timeGapBin: 3,
            count: 1,
          },
          {
            activityType: 'Call',
            activityIcon: 'Phone', // This is assigned manually to activities types, must use valid material icon names
            ctxColumn: 'Call Type',
            ctxValue: 'Short Call',
            timeStamp: 1586023297, // UTC timestamp , can be omitted for representative journeys
            timeGapToNextEvent: 1586, // millisecond value,
            timeGapBin: 1,
            count: 1,
          },
          {
            activityType: 'Eloqua Campaign',
            activityIcon: 'Loyalty', // This is assigned manually to activities types, must use valid material icon names
            ctxColumn: 'Type',
            ctxValue: 'LAT Awareness Campaign',
            timeStamp: 1580411032, // UTC timestamp , can be omitted for representative journeys
            timeGapToNextEvent: 1586, // millisecond value,
            timeGapBin: 0,
            count: 1,
          },
        ],
        timeGapBinCount: 5,
      },
      recommendedJourneys: [
        {
          journeySequence: [
            {
              activityType: 'Call',
              activityIcon: 'Phone', // This is assigned manually to activities types, must use valid material icon names
              ctxColumn: 'Call Type',
              ctxValue: 'Short Call',
              timeStamp: 1586023297, // UTC timestamp , can be omitted for historical representative journeys
              timeGapToNextEvent: 1586, // millisecond value,
              timeGapBin: 0,
              count: 1,
            },
            {
              activityType: 'Eloqua Campaign',
              activityIcon: 'Loyalty', // This is assigned manually to activities types, must use valid material icon names
              ctxColumn: 'Type',
              ctxValue: 'LAT Awareness Campaign',
              timeStamp: 1586790960, // UTC timestamp , can be omitted for representative journeys
              timeGapToNextEvent: 1586, // millisecond value,
              timeGapBin: 2,
              count: 3,
            },
            {
              activityType: 'Call',
              activityIcon: 'Phone', // This is assigned manually to activities types, must use valid material icon names
              ctxColumn: 'Call Type',
              ctxValue: 'Short Call',
              timeStamp: 1596792960, // UTC timestamp , can be omitted for historical representative journeys
              timeGapToNextEvent: 1586, // millisecond value,
              timeGapBin: 0,
              count: 1,
            },
            {
              activityType: 'Product Start',
              activityIcon: 'AddShoppingCart', // This is assigned manually to activities types, must use valid material icon names
              ctxColumn: 'Product',
              ctxValue: 'Trinza',
              timeStamp: 1591024560, // UTC timestamp , can be omitted for representative journeys
              timeGapToNextEvent: 1586, // millisecond value,
              timeGapBin: 1,
              count: 3,
            },
          ],
          timeGapBinCount: 5,
          recommendationReasons: [
            'physician status: A',
            'province: ON',
            'emailed 3 months ago',
          ],
          recommendationLevel: 4.0,
        },
        {
          journeySequence: [
            {
              activityType: 'Call',
              activityIcon: 'Phone', // This is assigned manually to activities types, must use valid material icon names
              ctxColumn: 'Call Type',
              ctxValue: 'Short Call',
              timeStamp: 1586023297, // UTC timestamp , can be omitted for historical representative journeys
              timeGapToNextEvent: 1586, // millisecond value,
              timeGapBin: 0,
              count: 1,
            },
            {
              activityType: 'Email',
              activityIcon: 'Email', // This is assigned manually to activities types, must use valid material icon names
              ctxColumn: 'Template',
              ctxValue: 'LAI Treatment Guidelines',
              timeStamp: 1586023297, // UTC timestamp , can be omitted for representative journeys
              timeGapToNextEvent: 1586, // millisecond value,
              timeGapBin: 1,
              count: 1,
            },
            {
              activityType: 'Product Start',
              activityIcon: 'AddShoppingCart', // This is assigned manually to activities types, must use valid material icon names
              ctxColumn: 'Product',
              ctxValue: 'Trinza',
              timeStamp: 1586790960, // UTC timestamp , can be omitted for representative journeys
              timeGapToNextEvent: 1586, // millisecond value,
              timeGapBin: 1,
              count: 3,
            },
          ],
          timeGapBinCount: 5,
          recommendationReasons: [
            'specialty2: GP/FM',
            'years since grad',
            'interest: PSY',
          ],
          recommendationLevel: 3.1,
        },
        {
          journeySequence: [
            {
              activityType: 'Email',
              activityIcon: 'Email', // This is assigned manually to activities types, must use valid material icon names
              ctxColumn: 'Template',
              ctxValue: 'LAI Treatment Guidelines',
              timeStamp: 1586023297, // UTC timestamp , can be omitted for representative journeys
              timeGapToNextEvent: 1586, // millisecond value,
              timeGapBin: 1,
              count: 0,
            },
            {
              activityType: 'Email',
              activityIcon: 'Email', // This is assigned manually to activities types, must use valid material icon names
              ctxColumn: 'Template',
              ctxValue: 'LAI Treatment Follow Up',
              timeStamp: 1586023297, // UTC timestamp , can be omitted for representative journeys
              timeGapToNextEvent: 1586, // millisecond value,
              timeGapBin: 2,
              count: 1,
            },
            {
              activityType: 'Product Start',
              activityIcon: 'AddShoppingCart', // This is assigned manually to activities types, must use valid material icon names
              ctxColumn: 'Product',
              ctxValue: 'Trinza',
              timeStamp: 1586023297, // UTC timestamp , can be omitted for representative journeys
              timeGapToNextEvent: 1586, // millisecond value,
              timeGapBin: 0,
              count: 3,
            },
          ],
          timeGapBinCount: 5,
          recommendationReasons: [
            'specialty2: GP/FM',
            'years since grad',
            'interest: PSY',
          ],
          recommendationLevel: 2.3,
        },
      ],
    },
    {
      metadata: {
        analysisCategory: '',
        isBusy: false, // if the analysis has not been finished set this to true, otherwise set it to false even for errored case
        isErrored: false, // set to true when error is detected
        parentIsBusy: false,
        analysisId: '09405eb37ba94107a1a411ac99534c51', // uuid
        analysisGoal: '',
        analysisType: 'NEXT_BEST_ACTION', // see analysisType information below
        analysisName: 'Pysch Orals to Injectables',
        analysisIcon: '',
      },
    },
    {
      metadata: {
        analysisCategory: '',
        isBusy: false, // if the analysis has not been finished set this to true, otherwise set it to false even for errored case
        isErrored: false, // set to true when error is detected
        parentIsBusy: false,
        analysisId: '09405eb37ba94107a1a411ac99534c53', // uuid
        analysisGoal: '',
        analysisType: 'NEXT_BEST_ACTION', // see analysisType information below
        analysisName: 'Eloqua Campaign',
        analysisIcon: '',
      },
    },
  ],
};
