import {
  ENTITY_TYPES_ENUM,
  HEADER_LABEL_DEFAULT_SIZE,
  SCORE_COLUMN_WIDTH,
  TABLE_HEADER_TYPES_ENUM,
  SEGMENT_COLUMN_WIDTH,
} from '../constants';
import { EntityTableCell } from './EntityTableCell';
import { EntityTableHeader } from './EntityTableHeader';

export const getCellValue = (type, rowData) => {
  if (type === TABLE_HEADER_TYPES_ENUM.SCORE) {
    return rowData?.maptualFractionalScore;
  }
  if (type === TABLE_HEADER_TYPES_ENUM.LABEL) {
    return rowData?.name;
  }
  if (type === TABLE_HEADER_TYPES_ENUM.SEGMENT) {
    return rowData.join('');
  }

  return Number(rowData[rowData.length - 1]);
};

const isEmptyItem = (item) =>
  Array.isArray(item?.data) &&
  item.data.every((data) => data === null) &&
  item.trend &&
  item.trend.value === null &&
  item.trend.type === 'TREND' &&
  item.trend.status === -1;

export const buildRows = (headers, items) => {
  const rows = [];

  items?.forEach((item, index) => {
    if (!item.every(isEmptyItem)) {
      const headerToItemMap = {};

      for (let i = 0; i < headers?.length; i++) {
        headerToItemMap[i] = item[i];
      }

      headerToItemMap.id = index;
      rows.push(headerToItemMap);
    }
  });

  return rows;
};

export const buildColumns = (headers) => {
  const columns = [];

  headers?.forEach(
    (
      { label, subLabel, type, graph, chart, tooltipText, tooltipList },
      index
    ) => {
      const { titlePrefix: chartTitlePrefix, labels: chartLabels } =
        chart || {};

      let headerColumn = {
        field: `${index}`,
        maxHeight: '100%',
        renderHeader: () =>
          EntityTableHeader({
            label,
            subLabel,
            type,
            tooltipText,
            tooltipList,
          }),
        sortingOrder: ['desc', 'asc', null],
        valueGetter: ({ row }) => getCellValue(type, row[index]?.data),
        renderCell: ({ row }) =>
          EntityTableCell({
            row: row[index],
            type,
            graph,
            chartLabels,
            label,
            subLabel,
            chartTitlePrefix,
          }),
      };

      switch (type) {
        case TABLE_HEADER_TYPES_ENUM.SCORE:
          headerColumn = {
            ...headerColumn,
            minWidth: SCORE_COLUMN_WIDTH,
            sortComparator: (value1, value2) => value1 - value2,
          };
          break;

        case TABLE_HEADER_TYPES_ENUM.LABEL:
          headerColumn = {
            ...headerColumn,
            sortComparator: (value1, value2) => value1.localeCompare(value2),
          };
          break;
        case TABLE_HEADER_TYPES_ENUM.SEGMENT:
          headerColumn = {
            ...headerColumn,
            sortComparator: (value1, value2) => value1.localeCompare(value2),
            width: SEGMENT_COLUMN_WIDTH,
          };
          break;

        default:
          headerColumn = {
            ...headerColumn,
            width: label.length > HEADER_LABEL_DEFAULT_SIZE ? 180 : 136,
          };
          break;
      }

      columns.push(headerColumn);
    }
  );

  return columns;
};

export const getSpecializedHeaders = (entityType) => {
  switch (entityType) {
    case ENTITY_TYPES_ENUM.OUTLET:
    case ENTITY_TYPES_ENUM.DIRECT_ACCOUNT:
      return [
        {
          label: '',
          subLabel: '',
          type: 'drawer',
        },
      ];

    default:
      return [];
  }
};

export const getSpecializedItem = (entityType) => {
  switch (entityType) {
    case ENTITY_TYPES_ENUM.OUTLET:
    case ENTITY_TYPES_ENUM.DIRECT_ACCOUNT:
      return {
        data: {
          drawerOpener: true,
        },
      };

    default:
      return [];
  }
};

export const buildTableItems = (serverItems, specializedItem) => {
  if (!serverItems) {
    return [];
  }

  if (!specializedItem && serverItems.length > 0) {
    return serverItems;
  }

  return serverItems?.map((item) => [...item, specializedItem]);
};

export const isNotNumeric = (value) => !/^\d+$/.test(value);

export const filterRowsBySearchValue = (headers, rows, searchValue) => {
  const labelColumnIndex = headers.findIndex((obj) => obj.type === 'label');
  const lowerCaseSearchValue = searchValue.toLowerCase();

  return rows.filter((row) => {
    const rowData = row[labelColumnIndex].data;
    const combinedString = Object.values(rowData)
      .filter((value) => isNotNumeric(value))
      .join('')
      .toLowerCase();
    return combinedString.includes(lowerCaseSearchValue);
  });
};
