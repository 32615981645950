import moment from 'moment';

export const getFullDateString = (utc) => {
  const dt = moment.unix(utc).utc();
  return dt.format('DD MMMM, YYYY');
};

export const parseDateStringWithResolution = (utc, resolution = '') => {
  const dt = moment.unix(utc).utc();

  switch (resolution) {
    case 'Y':
      return dt.format('YYYY');
    case 'M':
      return dt.format('MMM YYYY');
    case 'h':
    case 'm':
      return dt.format('llll');
    case 's':
      return dt.format('ddd, MMM D, YYYY, h:mm:ss a');
    case 'f':
      return dt.format('MMMM Do, YYYY');
    case 'D':
    default:
      return dt.format('ddd, MMM D, YYYY');
  }
};
