import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useMutation, useQuery } from 'react-query';
import {
  getCustomerFormData,
  getUsersData,
  createUser,
  clearStatus,
  closeUserModal,
} from '../../slices/userManagement';
import UserDataModal from './userDataModal';
import { UserTable } from './userTable';
import getUserManagementState from '../../hooks/userManagement';
import { UserDomainModal } from './userDomainModal';
import {
  getCustomerDomains,
  updateCustomerDomains,
} from '../../request/configViewRequests';
import { UserDomainModalPostSave } from './userDomainModalPostSave';

const UserManagementContainer = styled('div')(() => ({
  height: '100%',
  width: '100%',
  padding: 80,
  paddingTop: 30,
  alignItems: 'center',
}));

const Toolbar = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
}));

const UserTableContainer = styled('div')(() => ({
  height: '100%',
  width: '100%',
}));

const Title = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 24,
  textAlign: 'left',
}));

const StyledButton = styled(Button)({
  marginRight: 0,
  width: 'max-content',
  justifySelf: 'right',
});

const ButtonRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 8,
});

const CustomerDomainsModals = {
  EDIT: 'EDIT',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const UserManagementView = () => {
  const dispatch = useDispatch();
  const state = getUserManagementState();
  const [domains, setDomains] = useState([]);
  const [domainManagementModal, setDomainManagementModal] = useState(null);
  const domainsQuery = useQuery('domains', async () => {
    const response = await getCustomerDomains();
    return response.data;
  });
  const { mutate: onSaveDomains, isLoading: domainsAreSaving } = useMutation(
    (requestBody) => updateCustomerDomains(requestBody),
    {
      onSuccess: (data, variables) => {
        setDomains(variables);
        setDomainManagementModal(CustomerDomainsModals.SUCCESS);
      },
      onError: () => {
        setDomainManagementModal(CustomerDomainsModals.ERROR);
      },
    }
  );

  useEffect(() => {
    const fetchDataAndSetState = async () => {
      const newData = await domainsQuery.refetch();
      setDomains(newData.data);
    };
    fetchDataAndSetState();
    dispatch(getUsersData());
    dispatch(getCustomerFormData());
  }, []);

  useEffect(() => {
    if (state.userTableStatus === 'SUCCESS') {
      dispatch(clearStatus('userTableStatus'));
    }

    if (state.userModalStatus === 'SUCCESS') {
      dispatch(clearStatus('userModalStatus'));
    }

    if (state.userRequestStatus === 'SUCCESS') {
      dispatch(getUsersData());
      dispatch(closeUserModal());
      dispatch(clearStatus('userRequestStatus'));
    }
  }, [state]);

  return (
    <UserManagementContainer>
      <Toolbar>
        <Title>Manage Users</Title>
        <ButtonRow>
          <StyledButton
            variant="outlined"
            onClick={() => setDomainManagementModal(CustomerDomainsModals.EDIT)}
          >
            Email Domains
          </StyledButton>
          <StyledButton
            data-testid="test-add-user"
            aria-label="add"
            variant="contained"
            color="primary"
            onClick={() => dispatch(createUser())}
          >
            Add User
          </StyledButton>
        </ButtonRow>
        <UserDataModal />
        <UserDomainModal
          isOpen={domainManagementModal === CustomerDomainsModals.EDIT}
          domains={domains}
          onSaveDomains={onSaveDomains}
          domainsAreSaving={domainsAreSaving}
          closeForm={() => {
            setDomainManagementModal(null);
          }}
        />
        <UserDomainModalPostSave
          isOpen={
            domainManagementModal === CustomerDomainsModals.SUCCESS ||
            domainManagementModal === CustomerDomainsModals.ERROR
          }
          error={domainManagementModal === CustomerDomainsModals.ERROR}
          closeForm={() => {
            setDomainManagementModal(null);
          }}
        />
      </Toolbar>
      <UserTableContainer>
        <UserTable />
      </UserTableContainer>
    </UserManagementContainer>
  );
};
