export const themeColors = {
  darkTheme: {
    mainBackground: '#15161A',
    adminPanelPrimaryBackground: '#1C2027',
    adminPanelPrimaryBtnBackground: '#1F232C',
    adminPanelHoverBtnBackground: '#313B49',
    adminPanelDisabledBtnText: '#5E708D',
    adminPanelDisabledBtnBackground: '#1F232C',
    adminCardHeader: '#FCFCFD',
    adminCardSubheader: '#E0E4EB',
    adminCardBodyText: '#8595AD',
    borderLowContrast: '#313B49',
    maptualPageTitle: '#E0E4EB',
    profileBarColors: {
      fontPrimary: '#8595AD',
    },
    sideNavBarIconColor: '#8595AD',
  },
  lightTheme: {},
};
