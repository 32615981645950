export const makeWordTitleCase = (word) => {
  const lowerCaseWord = word.toLowerCase();
  return `${lowerCaseWord.charAt(0).toUpperCase()}${lowerCaseWord.slice(1)}`;
};

export const addSpacesBetweenSeparators = (entityName) =>
  entityName
    .replace(/ - /g, '-')
    .replace(/-/g, ' - ')
    .replace(/ \+ /g, '+')
    .replace(/\+/g, ' + ')
    .replace(/ \/ /g, '/')
    .replace(/\//g, ' / ');

export const isWordFSACode = (word) => {
  const fSAPattern = /^[A-Za-z]\d[A-Za-z]$/;
  return fSAPattern.test(word);
};

export const formatEntityName = (entityName) => {
  const spacedName = addSpacesBetweenSeparators(entityName);
  const words = spacedName.split(/\s+/);

  return words
    .map((word) =>
      isWordFSACode(word) ? word.toUpperCase() : makeWordTitleCase(word)
    )
    .join(' ');
};
