const newDataSteps = Object.freeze([
  {
    title: 'New or Add Data',
  },
  {
    title: 'Dataset Name',
  },
  {
    title: 'Select Data File',
  },
  {
    title: 'Review and Customize Data',
  },
  {
    title: 'Identify Key Columns',
  },
  {
    title: 'Confirmation',
  },
]);

const addDataSteps = Object.freeze([
  {
    title: 'New or Add Data',
  },
  {
    title: 'Select Existing Dataset',
  },
  {
    title: 'Select Data File',
  },
  {
    title: 'Confirmation',
  },
]);

export { newDataSteps, addDataSteps };
