import React from 'react';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import NoData from '@mui/icons-material/CloudOff';
import styled from '@emotion/styled';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import { Box, Skeleton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import { darkThemeColors } from '../../../styles/colorConfig';
import { PROJECT_STATUS } from '../../../constants';
import { themeColors as adminColors } from '../../../styles/adminStyles';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 'auto',
    padding: 16,
  },
  textStyle: {
    margin: 0,
    padding: 0,
  },
  textStyleDisabled: {
    margin: 0,
    padding: 0,
    color: theme.palette.text.disabled,
  },
  mediaStyle: {
    width: '100%',
    height: '12rem',
    minHeight: '12rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconStyle: {
    width: '3rem',
    height: '3rem',
    marginBottom: '1rem',
    color: darkThemeColors.tertiaryColor,
  },
  mediaText: {
    color: darkThemeColors.tertiaryColor,
    maxWidth: '10rem',
    fontWeight: 500,
  },
}));

const StyledCardContent = styled(CardContent)({
  height: '100%',
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
});

const ObjectiveList = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: '8px',
  padding: 16,
});

const CardBodyText = styled('span')({
  color: adminColors.darkTheme.adminCardBodyText,
  fontWeight: 400,
});

const ObjectiveTypeText = styled('span')({
  color: adminColors.darkTheme.adminCardSubheader,
  fontWeight: 600,
});

const CardBodyWrapper = styled('div')({
  textAlign: 'left',
});

const TaskIconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ theme, color }) => ({
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  gridGap: 10,
  marginBottom: 4,
  marginTop: 'auto',
  padding: '6px 12px',
  borderLeft: `4px solid ${theme.palette?.[color]?.main || 'white'}`,
}));

const StyledTooltip = withStyles({
  tooltip: {
    fontSize: '14px',
    backgroundColor: '#313B49',
  },
  arrow: {
    color: '#313B49',
  },
})(Tooltip);

export const TaskStatusBadge = ({ status }) => {
  let color = 'white';
  let msg = '';
  let Icon = null;

  switch (status) {
    case 'warning':
      color = 'warning';
      msg = 'Deployable, contains task failures';
      Icon = ErrorOutlineOutlinedIcon;
      break;
    case 'running':
      color = 'primary';
      msg = 'Project is still executing';
      Icon = PendingOutlinedIcon;
      break;
    case 'success':
      color = 'success';
      msg = 'Project can be deployed';
      Icon = CheckCircleOutlineOutlinedIcon;
      break;
    case 'failure':
      color = 'error';
      msg = 'Not deployable, contains task failures';
      Icon = CancelOutlinedIcon;
      break;
    default:
      break;
  }

  if (!Icon) {
    return null;
  }

  return (
    <TaskIconWrapper color={color}>
      <Icon fontSize="small" color={color} />
      <Typography variant="body2">{msg}</Typography>
    </TaskIconWrapper>
  );
};

const AutomatedReportBadge = ({ status, tooltipMsg, message }) => {
  let color = 'white';
  let Icon = null;
  switch (status) {
    case 'warning':
      color = 'warning';
      Icon = ErrorOutlineOutlinedIcon;
      break;
    case 'success':
      color = 'success';
      Icon = CheckCircleOutlineOutlinedIcon;
      break;
    case 'failure':
      color = 'error';
      Icon = CancelOutlinedIcon;
      break;
    default:
      break;
  }

  if (!Icon) {
    return null;
  }

  return (
    <TaskIconWrapper color={color}>
      <StyledTooltip title={tooltipMsg} arrow placement="top">
        <Icon fontSize="small" color={color} />
      </StyledTooltip>
      <Typography variant="body2">{message}</Typography>
    </TaskIconWrapper>
  );
};

const formatFailedTests = (failedTests) => {
  if (failedTests?.length === 2) return failedTests?.join(' & ');

  const lastTwoFailedTests = failedTests?.slice(-2);
  const remainingFailedTests = failedTests?.slice(0, -2);

  return [...remainingFailedTests, lastTwoFailedTests.join(' & ')].join(', ');
};
const formatToolTipMessage = (failedTests) =>
  failedTests?.length === 0
    ? 'All test suites passed'
    : `${formatFailedTests(failedTests)} failed`;

const formatMessage = (totalPassedTests, totalTestSuites) => {
  if (totalTestSuites === 0) {
    return 'No test suites have been run';
  }

  return `${totalPassedTests}/${totalTestSuites} test suites passed`;
};

export default function ProjectCardContent({
  isBusy,
  projectStatus,
  taskStatus,
  isAdmin = false,
  objectives = [],
  testSuiteStatusResponse,
  isTestSuiteStatusLoading,
}) {
  const classes = useStyles();

  const busyComponent = () => (
    <div className={classes.mediaStyle}>
      <CircularProgress className={classes.iconStyle} />
      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        className={classes.mediaText}
      >
        Creating Project ...
      </Typography>
    </div>
  );

  const createdComponent = () => (
    <div className={classes.mediaStyle}>
      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        className={classes.mediaText}
      >
        Project Currently Running
      </Typography>
    </div>
  );

  const nonDeployedComponent = () => (
    <div className={classes.mediaStyle}>
      <NoData className={classes.iconStyle} />
      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        className={classes.mediaText}
      >
        Currently Insufficient Performance Data
      </Typography>
    </div>
  );

  const deployedComponent = () => (
    <div
      style={{
        width: '100%',
        height: '12rem',
        borderBottom: '2px solid green',
      }}
    >
      &nbsp;
    </div>
  );

  const ProjectPerformance = () => {
    if (isBusy) return busyComponent();
    if (projectStatus === PROJECT_STATUS.READY) return nonDeployedComponent();
    if (projectStatus === PROJECT_STATUS.DEPLOYED) return deployedComponent();

    return createdComponent();
  };

  return (
    <StyledCardContent>
      {isAdmin ? null : (
        <CardMedia className={classes.media}>
          <ProjectPerformance />
        </CardMedia>
      )}
      {isAdmin ? (
        <>
          <ObjectiveList>
            {objectives.map((objectiveFullName, i) => {
              const [objectiveType, objectiveName] =
                objectiveFullName.split(':');
              return (
                <CardBodyWrapper key={`${objectiveName}-${i}`}>
                  <ObjectiveTypeText>
                    {objectiveType}:{' '}
                    <CardBodyText>{objectiveName}</CardBodyText>
                  </ObjectiveTypeText>
                </CardBodyWrapper>
              );
            })}
          </ObjectiveList>
          <div>
            <TaskStatusBadge status={taskStatus} />
            {isTestSuiteStatusLoading && (
              <Skeleton variant="rounded" animation="wave" height={32} />
            )}
            {testSuiteStatusResponse &&
              ('errorMessage' in testSuiteStatusResponse ? (
                <AutomatedReportBadge
                  status="warning"
                  tooltipMsg="Error"
                  message={testSuiteStatusResponse?.errorMessage}
                />
              ) : (
                <AutomatedReportBadge
                  status={testSuiteStatusResponse?.status}
                  tooltipMsg={formatToolTipMessage(
                    testSuiteStatusResponse?.failedTests
                  )}
                  message={formatMessage(
                    testSuiteStatusResponse?.totalPassedTests,
                    testSuiteStatusResponse?.totalTestSuites
                  )}
                />
              ))}
          </div>
        </>
      ) : null}
    </StyledCardContent>
  );
}
