import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Select, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { EntityOverviewContext } from '../../data/entityOverviewContext';

const ObjectiveSelect = styled(Select)(({ theme: { themeColors } }) => ({
  minWidth: 237,
  maxWidth: 340,
  backgroundColor: themeColors.buttonBackgroundColor,
  color: themeColors.menuTextColor,
  '.MuiSelect-select': {
    padding: '8px 12px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: themeColors.buttonBorderColor,
  },
  svg: {
    color: themeColors.buttonContentColor,
  },
}));

export const ObjectiveSelector = () => {
  const { metadata, objectiveId, setObjectiveId } = useContext(
    EntityOverviewContext
  );

  const handleObjectiveChange = (event) => {
    setObjectiveId(event.target.value);
  };

  return (
    <ObjectiveSelect
      key={objectiveId}
      value={objectiveId}
      onChange={handleObjectiveChange}
      IconComponent={KeyboardArrowDownIcon}
    >
      {Array.isArray(metadata?.objectives) &&
        metadata?.objectives.map((objective) => (
          <MenuItem key={objective.id} value={objective.id}>
            {objective.label}
          </MenuItem>
        ))}
    </ObjectiveSelect>
  );
};
