import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getMaptualListURL } from '../request/projectRequests';

export const getDownloadableUrl = createAsyncThunk(
  'fieldView/getDownloadableURLByMaptualListId',
  async ({ projectId, maptualListId }, thunkApi) => {
    try {
      const response = await getMaptualListURL({ projectId, maptualListId });
      return response.data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Something went wrong with the request', { err });
      return thunkApi.rejectWithValue(err.message);
    }
  }
);

export const downloadableUrlInitialState = {
  pending: false,
  data: null,
  error: null,
};

const downloadableUrlSlice = createSlice({
  name: 'maptualListDownloadableUrl',
  initialState: downloadableUrlInitialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDownloadableUrl.pending, (state) => {
        state.maptualListDownloadableUrl.pending = true;
        state.maptualListDownloadableUrl.data = null;
        state.maptualListDownloadableUrl.error = null;
      })
      .addCase(getDownloadableUrl.fulfilled, (state, action) => {
        state.maptualListDownloadableUrl.pending = false;
        state.maptualListDownloadableUrl.data = action.payload;
        state.maptualListDownloadableUrl.error = null;
      })
      .addCase(getDownloadableUrl.rejected, (state, action) => {
        state.maptualListDownloadableUrl.pending = false;
        state.maptualListDownloadableUrl.data = null;
        state.maptualListDownloadableUrl.error = action.error.message;
      });
  },
});

export const { name } = downloadableUrlSlice;

export default downloadableUrlSlice.reducer;
