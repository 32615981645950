import React from 'react';
import {
  ModalDisplay,
  ModalHeader,
  ModalBody,
} from '../../../../components/generic/modalDisplay';
import { RegionSelectionList } from './RegionSelectionList';
import { RegionSearch } from './RegionSearch';

export const RegionSelectionModal = ({
  isOpen,
  handleClose,
  handleRegionSelection,
  regionsForSearch,
  regionList,
}) => (
  <ModalDisplay
    onClose={() => handleClose()}
    isOpen={isOpen}
    style={{ width: '35%', maxHeight: '95%' }}
    positionTop
  >
    <ModalHeader onClose={() => handleClose()} largeCloseIcon={false}>
      Change Region
    </ModalHeader>
    <ModalBody notScrollable>
      <RegionSearch
        regionsForSearch={regionsForSearch}
        handleRegionSelection={handleRegionSelection}
      />
    </ModalBody>
    <ModalBody>
      <RegionSelectionList
        regionList={regionList}
        handleRegionSelection={handleRegionSelection}
      />
    </ModalBody>
  </ModalDisplay>
);
