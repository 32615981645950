import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Tab, Tabs } from '@mui/material';
import { EntityOverviewContext } from '../../data/entityOverviewContext';
import { CADENCES_ENUM } from '../../constants';

const StyledCadenceTabs = styled(Tabs)({
  display: 'flex',
  alignItems: 'center',
  minHeight: 'initial',
  '.MuiTabs-indicator': {
    display: 'none',
  },
});

const StyledTab = styled(Tab)(({ theme: { themeColors } }) => ({
  minHeight: 'auto',
  padding: '8px 12px',
  backgroundColor: themeColors.buttonBackgroundColor,
  color: themeColors.buttonContentColor,
  border: `1px solid ${themeColors.buttonActiveBorderColor}`,
  marginRight: -1,
  textTransform: 'none',
  fontSize: 16,
  fontWeight: 400,
  '&.Mui-selected': {
    color: themeColors.buttonActiveContentColor,
    backgroundColor: themeColors.buttonSecondaryBackgroundColour,
  },
  '.MuiTab-iconWrapper': {
    margin: '0 6px 0 0',
  },
  '&:first-of-type': {
    borderRadius: '4px 0px 0px 4px',
  },
  '&:last-of-type': {
    borderRadius: '0px 4px 4px 0px',
    marginRight: 0,
    minWidth: 34,
  },
}));

export const CadenceSelectorTabs = () => {
  const { metadata, isMetadataLoading, objectiveId, cadence, setCadence } =
    useContext(EntityOverviewContext);

  const [availableCadences, setAvailableCadences] = useState([]);

  useEffect(() => {
    if (!isMetadataLoading && metadata && objectiveId) {
      const cadenceKeys = metadata.objectives?.find(
        (obj) => obj.id === objectiveId
      )?.cadences;

      const cadences = [];

      cadenceKeys.forEach((cadenceKey) => {
        const cadenceName = CADENCES_ENUM[cadenceKey];
        cadences.push({ id: cadenceKey, name: cadenceName });
      });

      setAvailableCadences(cadences);
    }
  }, [isMetadataLoading, metadata, objectiveId]);

  const onTabClick = (tabId) => {
    setCadence(tabId);
  };

  return (
    <Box>
      <StyledCadenceTabs value={cadence || 0}>
        {availableCadences &&
          availableCadences.map((cadenceTab) => {
            const { id, name } = cadenceTab;

            return (
              <StyledTab
                key={`${id}-key`}
                label={name}
                value={id}
                onClick={() => {
                  onTabClick(id);
                }}
              />
            );
          })}
      </StyledCadenceTabs>
    </Box>
  );
};
