import React from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { MetricSelector } from '../../../salesReps/repTable/table/controls/metricSelector';
import { MetricTypeSelector } from '../../../salesReps/repTable/table/controls/metricTypeSelector';
import { CuratedTypeSelector } from './curatedTypeSelector';

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 16,
  gap: 16,
  alignItems: 'center',
}));

export const Controls = ({
  updateFilterMetric,
  updateFilterMetricType,
  updateFilterCuratedType,
  objectiveId,
  metric,
  metricTypes,
}) => {
  const { projectId } = useParams();
  return (
    <Container>
      <CuratedTypeSelector updateFilterCuratedType={updateFilterCuratedType} />
      {objectiveId && (
        <MetricSelector
          project={projectId}
          objective={objectiveId}
          updateFilterMetric={updateFilterMetric}
          entityType="hcp"
        />
      )}
      {metric && metricTypes && (
        <MetricTypeSelector
          metricTypes={metricTypes}
          updateFilterMetricType={updateFilterMetricType}
        />
      )}
    </Container>
  );
};
