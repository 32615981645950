import React, { memo } from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { useTheme } from '@mui/styles';
import TrendValue from '../../../../components/fieldView/entitycontents/trendValues';
import { SparklineLineGraph } from './lineGraph';
import { getColorFromTrend } from '../../../../utils/getColorFromTrend';
import { ValueContainer } from '../ValueContainer';

const LineGraphBox = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  marginRight: 8,
  maxWidth: 90,
}));

const FillerBox = styled('div')(() => ({
  width: 90,
}));

const MemoizedLineGraph = memo(({ data, labels, color }) => (
  <SparklineLineGraph data={data} labels={labels} color={color} />
));
MemoizedLineGraph.displayName = 'MemoizedLineGraph';

export const LineGraphContainer = ({
  row,
  valueType,
  chartLabels,
  basicColor = false,
}) => {
  const { themeColors } = useTheme();
  const { data, trend } = row;
  const invalidData = data.every((d) => d === 0);

  return (
    <>
      <LineGraphBox data-testid={`sales-rep-line-graph-${data.join('-')}`}>
        {invalidData && <FillerBox />}
        {!invalidData && (
          <MemoizedLineGraph
            data={data}
            labels={chartLabels}
            color={
              basicColor
                ? themeColors.dataGeneralColor
                : getColorFromTrend(trend, themeColors)
            }
          />
        )}
      </LineGraphBox>
      <ValueContainer data={data} valueType={valueType}>
        <TrendValue
          type={trend.type}
          status={trend.status}
          value={trend?.value}
          noMargin
        />
      </ValueContainer>
    </>
  );
};
