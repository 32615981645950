import React from 'react';
import styled from '@emotion/styled';
import { SignOutButton } from '../buttons/signOutButton';
import { AppSelectionButton } from '../buttons/appSelectionButton';
import { Preferences } from '../buttons/preferences';

const StyledContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: theme.themeColors.paperColor,
  border: `1px solid ${theme.themeColors.borderLowContrast}`,
  borderRadius: '4px',
  padding: '16px',
}));

const isAdminUser = (profile) => profile?.role?.type === 'ADMIN';

export default function UserMenuOptions({ profile, selectApp }) {
  return (
    profile && (
      <StyledContainer>
        {isAdminUser(profile) && <Preferences />}
        <AppSelectionButton selectApp={selectApp} />
        <SignOutButton />
      </StyledContainer>
    )
  );
}
