import React from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { SentryRoute } from '../../../sentryRoute';
import { ROUTES } from '../../../constants';
import LoginHandler from '../loginHandler';
import NavBar from '../../../components/app/navBar';
import { ContentWrapper } from '../contentWrapper';
import HcpInfoContainer from '../../hcpinfoview/hcpInfoContainer';
import NavigationTracking from '../../../trackers/navigationTracking';
import HeadOfficeViewer from '../../headofficeview/headOfficeViewer';
import { NAVBAR_DRAWER_WIDTH } from './constants';

export const Sphere = ({ profile, appView }) => (
  <Router>
    <SentryRoute
      path={ROUTES.SPHERE}
      render={(innerProps) => (
        <NavBar
          navBarWidth={NAVBAR_DRAWER_WIDTH}
          appView={appView}
          {...innerProps}
        />
      )}
    />
    <ContentWrapper>
      <Switch>
        <SentryRoute
          path={`${ROUTES.SPHERE}${ROUTES.PROJECTS}/:projectId/entities/:entityId/maptualList/:maptualListId/:regionLabel`}
          render={(innerProps) => (
            <HcpInfoContainer {...innerProps} profile={profile} />
          )}
        />
        <SentryRoute
          exact
          path={ROUTES.LOGIN}
          render={() => (
            <LoginHandler redirectTo={`${ROUTES.SPHERE}${ROUTES.PROJECTS}`} />
          )}
        />
        <NavigationTracking profile={profile}>
          <SentryRoute
            path={`${ROUTES.SPHERE}${ROUTES.PROJECTS}`}
            render={(innerProps) => (
              <HeadOfficeViewer {...innerProps} profile={profile} />
            )}
          />
          <SentryRoute exact path={ROUTES.ROOT}>
            <Redirect to={`${ROUTES.SPHERE}${ROUTES.PROJECTS}`} />
          </SentryRoute>
          <SentryRoute exact path={ROUTES.INDEX_HTML}>
            <Redirect to={`${ROUTES.SPHERE}${ROUTES.PROJECTS}`} />
          </SentryRoute>
          <SentryRoute exact path={ROUTES.SPHERE}>
            <Redirect to={`${ROUTES.SPHERE}${ROUTES.PROJECTS}`} />
          </SentryRoute>
          <SentryRoute path={ROUTES.PROJECTS}>
            <Redirect to={`${ROUTES.SPHERE}${ROUTES.PROJECTS}`} />
          </SentryRoute>
        </NavigationTracking>
      </Switch>
    </ContentWrapper>
  </Router>
);
