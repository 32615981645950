import { baseAlbInstance } from './config';

export function getExistingDatasets() {
  return baseAlbInstance.get('/datasets', { timeout: 15000 });
}

export function uploadNewDatasetMeta(metaData) {
  return baseAlbInstance.post('/datasets', JSON.stringify(metaData), {
    timeout: 16000,
  });
}

export function getExistingColumns() {
  return baseAlbInstance.get('/columnData', { timeout: 15000 });
}

export function triggerShopifyIngestion() {
  baseAlbInstance.get('/shopifyDatasets', { timeout: 15000 }).catch((error) => {
    // eslint-disable-next-line no-console
    console.log(error);
  });
}
