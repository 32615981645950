import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import styled from '@emotion/styled';

import { columns } from './userTableColumns';
import { editUser } from '../../slices/userManagement';
import DeleteUserModal from './deleteUserModal';
import getUserManagementState from '../../hooks/userManagement';
import UserTableMenu from './userTableMenu';
import PasswordResetModal from './passwordResetModal';
import SendInviteEmailPopover from './sendInviteEmailPopover';
import { userManagementColors } from './styles/styledComponents';

const StyledDatagrid = styled(DataGridPremium)(
  ({ theme: { themeColors } }) => ({
    '.MuiDataGrid-columnsContainer': {
      backgroundColor: 'white',
    },
    '.MuiDataGrid-columnHeaderTitle': {
      color: themeColors.menuTextColor,
    },
    '.MuiDataGrid-columnHeaders': {
      borderColor: themeColors.borderMidContrast,
    },
    '.MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${themeColors.borderLowContrast}`,
      padding: 10,
    },
    '.MuiDataGrid-iconSeparator': {
      color: themeColors.secondaryTextColor,
      display: 'none',
    },
    '& .MuiDataGrid-cell:hover': {
      color: 'primary.main',
    },
    svg: {
      color: userManagementColors.iconColor,
    },
    borderColor: themeColors.borderLowContrast,
    backgroundColor: themeColors.contentCardBackgroundColor,
    border: '0',
    boxShadow: 'none',
  })
);

const popoverInitState = {
  anchor: null,
  inviteEmailSent: false,
  isMenuOpen: false,
  isDeleteModalOpen: false,
  isPasswordResetModalOpen: false,
  isSendInviteEmailPopoverOpen: false,
  name: '',
  userId: null,
};

export const UserTable = () => {
  const state = getUserManagementState();
  const [popover, setPopover] = useState(popoverInitState);
  const dispatch = useDispatch();

  const handleClose = () => {
    setPopover(popoverInitState);
  };

  const handleClick = (params, ev) => {
    if (params.field === 'edit') {
      dispatch(editUser(params.row.id));
    }

    if (params.field === 'sendInviteEmail') {
      setPopover({
        anchor: ev.currentTarget,
        name: `${params.row.firstName} ${params.row.lastName}`,
        userId: params.row.id,
        inviteEmailSent: params.row?.inviteEmailSent || false,
        isMenuOpen: false,
        isDeleteModalOpen: false,
        isPasswordResetModalOpen: false,
        isSendInviteEmailPopoverOpen: true,
      });
    }

    if (params.field === 'menu') {
      setPopover({
        anchor: ev.currentTarget,
        name: `${params.row.firstName} ${params.row.lastName}`,
        userId: params.row.id,
        inviteEmailSent: false,
        isMenuOpen: true,
        isDeleteModalOpen: false,
        isPasswordResetModalOpen: false,
        isSendInviteEmailPopoverOpen: false,
      });
    }
  };

  const handleDeleteUser = () => {
    setPopover({ ...popover, isDeleteModalOpen: true });
  };

  const handlePasswordReset = () => {
    setPopover({ ...popover, isPasswordResetModalOpen: true });
  };

  return (
    <>
      <StyledDatagrid
        columns={columns}
        hideFooterPagination
        disableSelectionOnClick
        hideFooterSelectedRowCount
        rowHeight={50}
        rows={state.usersData}
        hideFooterRowCount
        disableMultipleSelection
        loading={state.userTableStatus === 'PENDING'}
        onCellClick={handleClick}
        options={{
          padding: 'dense',
        }}
      />
      <SendInviteEmailPopover
        {...popover}
        onClose={() => setPopover({ ...popoverInitState, name: popover.name })}
      />
      <UserTableMenu
        handleClose={handleClose}
        open={popover.isMenuOpen}
        anchorEl={popover.anchor}
        handleDeleteUser={handleDeleteUser}
        handlePasswordReset={handlePasswordReset}
      />
      <DeleteUserModal
        {...popover}
        onClose={() => setPopover({ ...popoverInitState, name: popover.name })}
      />
      <PasswordResetModal
        {...popover}
        onClose={() => setPopover({ ...popoverInitState, name: popover.name })}
      />
    </>
  );
};
