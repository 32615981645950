import { configProxyApiInstance, maptualApiInstance } from './config';

export const getCustomerMetrics = () =>
  configProxyApiInstance.get('/customer-metrics');

export const getCustomerDomains = () =>
  configProxyApiInstance.get('/customer-domains');

export const getCustomerObjectiveTypes = () =>
  configProxyApiInstance.get('/customer-objective-types');

export const getMetricsForMarket = (regionalTeamId) =>
  configProxyApiInstance.get(
    `/utilities/metrics-from-customer-market/${regionalTeamId}`
  );

export const getCustomerSegments = () =>
  configProxyApiInstance.get(`/customer-segments`);

export const getCustomerProductLines = () =>
  configProxyApiInstance.get(`customer-product-lines`);

export const createCustomerMarket = (requestBody) =>
  configProxyApiInstance.post(`customer-markets`, requestBody);

export const updateCustomerMarket = (id, requestBody) =>
  configProxyApiInstance.put(`customer-markets/${id}`, requestBody);

export const updateCustomerObjectiveType = (objectiveTypeId, requestBody) =>
  configProxyApiInstance.put(
    `customer-objective-types/${objectiveTypeId}`,
    requestBody
  );

export const updateCustomerDomains = (requestBody) =>
  configProxyApiInstance.put('customer-domains', requestBody);

export const addCustomerObjectiveType = (requestBody) =>
  configProxyApiInstance.post('customer-objective-types', requestBody);

export const updateCustomerSegment = (id, requestBody) =>
  configProxyApiInstance.put(`customer-segments/${id}`, requestBody);

export const getCustomerProductTrees = () =>
  configProxyApiInstance.get('customer-product-trees');

export function getProducts() {
  return maptualApiInstance.get(`core/products`);
}

export const createProductTree = (requestBody) =>
  configProxyApiInstance.post('customer-product-trees', requestBody);

export const updateProductTree = (requestBody) =>
  configProxyApiInstance.put('customer-product-trees', requestBody);

export const cloneProductTree = (requestBody) =>
  configProxyApiInstance.post('customer-product-trees/clone', requestBody);

export const attachProductTree = (productLineId, requestBody) =>
  configProxyApiInstance.post(
    `customer-product-trees/${productLineId}/attach`,
    requestBody
  );
