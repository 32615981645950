import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { AccountCard } from '../../../../../components/accountCard/accountCard';
import { NavigationButton } from '../../navigation/navigationButton';
import ProjectSelector from './projectSelector/projectSelector';
import { ViewsList } from './viewList/ViewsList';

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    !['navigationOpen', 'navigationOpenChanged'].includes(prop),
})((props) => ({
  display: 'flex',
  height: '100%',
  maxWidth: '375px',
  width: '375px',
  marginLeft: '-375px',
  flexDirection: 'column',
  backgroundColor: props.theme.themeColors.mainBackground,
  borderRight: `1px solid ${props.theme.themeColors.borderLowContrast}`,
  ...(props.navigationOpenChanged && {
    animation: props.navigationOpen ? '0.5s slide' : '0.5s slideOut',
  }),
  animationFillMode: 'forwards',
  '@keyframes slide': {
    from: { marginLeft: '-375px' },
    to: { marginLeft: '0px' },
  },
  '@keyframes slideOut': {
    from: { marginLeft: '0px' },
    to: { marginLeft: '-375px' },
  },
}));

const Root = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  padding: '32px 16px 0px 16px',
}));

const StyledButtonContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  marginLeft: '16px',
  marginTop: '26px',
}));

export const Navigation = () => {
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [prevNavigationOpen, setPrevNavigationOpen] = useState(false);
  const [navigationOpenChanged, setNavigationOpenChanged] = useState(false);

  useEffect(() => {
    if (!!navigationOpen !== !!prevNavigationOpen) {
      setNavigationOpenChanged(true);
      setPrevNavigationOpen(!!navigationOpen);
    } else {
      setNavigationOpenChanged(false);
    }
  }, [navigationOpen]);
  const handleNavigationClick = () => {
    setNavigationOpen(!navigationOpen);
  };
  return (
    <Wrapper
      navigationOpen={navigationOpen}
      navigationOpenChanged={navigationOpenChanged}
    >
      <Root>
        <StyledButtonContainer>
          <NavigationButton handleClick={handleNavigationClick} />
        </StyledButtonContainer>
        <ProjectSelector />
        <ViewsList />
      </Root>
      <AccountCard />
    </Wrapper>
  );
};
