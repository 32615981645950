export function formatDateToString(date) {
  if (!date) {
    return '';
  }
  if (typeof date === 'string') {
    return date;
  }
  return `${date.getDate()} ${date.toLocaleString('en-US', {
    month: 'long',
  })} ${date.getFullYear()}`;
}
