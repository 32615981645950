import { useQuery } from 'react-query';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { getAvailableMetrics } from '../../../request/newProjectRequests';
import { NewProjectContext } from '../newProjectContext';
import { ShareMetricsDropdown } from './shareMetricsDropdown';

const defaultBasket = {
  products: [],
  therapeuticAreas: [],
  indications: [],
};

export function ShareMetricsProvider({ setShareMetricsProviderLoading }) {
  const [shareMetrics, setShareMetrics] = useState([]);
  const filterCriteria = 'share';
  const {
    baskets,
    projectConfig,
    objectiveBeingEdited,
    shareMetric,
    setShareMetric,
  } = useContext(NewProjectContext);
  const targetBasket =
    baskets.find((b) => b.basketType === 'TO_PRODUCT') || defaultBasket;
  const competitorBasket =
    baskets.find((b) => b.basketType === 'PRODUCT_FROM') || defaultBasket;
  const products = useMemo(
    () => competitorBasket?.products.concat(targetBasket?.products),
    [baskets]
  );
  const therapeuticAreas = useMemo(
    () =>
      competitorBasket?.therapeuticAreas.concat(targetBasket?.therapeuticAreas),
    [baskets]
  );
  const indications = useMemo(
    () => competitorBasket?.indications.concat(targetBasket.indications),
    [baskets]
  );
  const metricParams = {
    productIds: products?.map((product) => product.productId),
    therapeuticAreas,
    indications,
    productLine: projectConfig.productLineId,
    filterCriteria: 'share',
  };

  const queryCacheKey = Object.values(metricParams)
    .map((value) => JSON.stringify(value))
    .join('_');
  const { refetch, isLoading, isRefetching } = useQuery(
    queryCacheKey,
    async ({ signal }) => {
      const response = await getAvailableMetrics({
        ...metricParams,
        signal,
        filterCriteria,
      });
      setShareMetrics(JSON.parse(response.data));
    },
    { enabled: false }
  );
  useEffect(() => {
    if (products?.length > 0) {
      refetch();
    }
  }, [products, therapeuticAreas, indications, projectConfig.productLineId]);

  useEffect(() => {
    setShareMetricsProviderLoading(isLoading || isRefetching);
  }, [isLoading, isRefetching]);

  if (!targetBasket.products.length) {
    return <ShareMetricsDropdown disabled metrics={shareMetrics} />;
  }

  return (
    <ShareMetricsDropdown
      metrics={shareMetrics}
      setShareMetric={setShareMetric}
      editedObjectiveShareMetricId={
        objectiveBeingEdited && shareMetric?.uuid ? shareMetric.uuid : null
      }
    />
  );
}
