import { maptualApiInstance } from './config';

export function postUser(requestBody) {
  return maptualApiInstance.post('/usermanagement', requestBody, {
    timeout: 30000,
  });
}

export function putUser(requestBody) {
  return maptualApiInstance.put(
    `/usermanagement/user/${requestBody.id}`,
    requestBody,
    {
      timeout: 30000,
    }
  );
}

export function patchProject(requestBody) {
  return maptualApiInstance.patch(
    `/usermanagement/users/project`,
    requestBody,
    { timeout: 60000 }
  );
}

export function deleteUser(id) {
  return maptualApiInstance.delete(`/usermanagement/user/${id}`);
}

export function resetPassword(requestBody) {
  return maptualApiInstance.put('/usermanagement/reset-password', requestBody, {
    timeout: 30000,
  });
}

export function sendInviteEmail(requestBody) {
  return maptualApiInstance.put(
    '/usermanagement/send-invite-email',
    requestBody,
    {
      timeout: 30000,
    }
  );
}

export function getCustomerFormData() {
  return maptualApiInstance.get('/usermanagement/customer-form', {
    timeout: 15000,
  });
}

export function getUsersData() {
  return maptualApiInstance.get('/usermanagement/users', {
    timeout: 15000,
  });
}

export function getUserPermissions() {
  return maptualApiInstance.get(`/usermanagement/user-permissions`);
}
