import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { darkThemeColors } from '../../styles/colorConfig';
import SemiOpaqueChip from '../chips/semiOpaqueChip';

const useStyles = makeStyles((theme) => ({
  horContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '0.25rem',
  },
  verContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0.25rem',
  },
  iconConatiner: {
    minWidth: 15,
    minHeight: 15,
    width: 15,
    height: 15,
    marginRight: 15,
  },
  icon: {
    width: '100%',
    height: '100%',
    color: theme.palette.text.secondary,
  },
  primaryText: {
    align: 'left',
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.text.primary,
    padding: 0,
    margin: 0,
    marginRight: '0.25rem',
    marginBottom: '0.125rem',
    lineHeight: 1.1,
  },
  secondaryText: {
    align: 'left',
    fontSize: 11,
    fontWeight: 500,
    color: darkThemeColors.tertiaryColor,
    padding: 0,
    margin: 0,
    marginRight: '0.25rem',
    marginBottom: '0.125rem',
    lineHeight: 1.1,
  },
  chipContainer: {
    width: 'auto',
  },
}));

export default function IconedListItem({
  primaryText,
  secondaryText,
  icon = null,
  semiOpaqueChip,
  match,
}) {
  const classes = useStyles();

  const StyledIcon = () => icon || null;
  return (
    <div className={classes.horContainer}>
      <div className={classes.iconConatiner}>
        {icon ? <StyledIcon className={classes.icon} /> : <div>&nbsp;</div>}
      </div>
      <div className={classes.verContainer}>
        <Typography className={classes.primaryText}>{primaryText}</Typography>
        <Typography className={classes.secondaryText}>
          {secondaryText}
        </Typography>
      </div>
      <div className={classes.chipContainer}>
        {semiOpaqueChip ? (
          <SemiOpaqueChip {...semiOpaqueChip} match={match} />
        ) : (
          <div>&nbsp;</div>
        )}
      </div>
    </div>
  );
}
