/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Switch } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';
import Divider from '@mui/material/Divider';
import { SentryRoute } from '../../sentryRoute';
import AnalysisItem from '../analysisview/analysisItem';
import EmptyView from '../emptyview/emptyView';
import TestDetailTabContainer from '../testDetails/testDetailTabContainer';

const useStyles = makeStyles(() => ({
  scrollAreaStyle: {
    width: '100%',
    height: '100%',
  },
  root: {
    width: '100%',
    height: '100%',
    paddingRight: '3vw',
    paddingLeft: '2vw',
    paddingTop: '2vh',
    paddingBottom: '1vh',
  },
  columnContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 20,
  },
}));

export default function ProjectFeatureView({
  getAnalysis,
  match,
  viewer: { selectedFeature, analysisList, fullAnalysisItem, isAnalysisBusy },
}) {
  const classes = useStyles();

  const isLeafElipsized =
    selectedFeature &&
    !selectedFeature.subFeatureSets &&
    Array.isArray(analysisList) &&
    analysisList.length === 1 &&
    analysisList[0].isEllipsized;

  const getFullAnalysisItem = () => {
    if (
      isLeafElipsized &&
      analysisList[0].metadata &&
      analysisList[0].metadata.analysisId
    ) {
      getAnalysis(analysisList[0].metadata.analysisId);
    }
  };

  useEffect(() => {
    getFullAnalysisItem();
  }, [selectedFeature]);

  const isListSingleItem = (list) =>
    list && Array.isArray(list) && list.length === 1;
  const specialItem = (analysisItem) =>
    analysisItem.tabSection || analysisItem.headeredSection;
  const isSingleXGridTable = (analysisItem) =>
    isListSingleItem(analysisItem.sections) &&
    analysisItem.sections[0].content.xGridTables;

  // eslint-disable-next-line default-param-last
  const singleAnalysisView = (external = false, renderProps) =>
    isAnalysisBusy || !fullAnalysisItem ? (
      <EmptyView
        isLoading={isAnalysisBusy}
        refreshAction={() => {
          const searchParams = new URLSearchParams(renderProps.location.search);
          getAnalysis(
            renderProps.match.params.externalAnalysisId,
            searchParams.get('userId')
          );
        }}
      />
    ) : fullAnalysisItem &&
      (fullAnalysisItem.headeredSection ||
        fullAnalysisItem.tabSection ||
        isSingleXGridTable(fullAnalysisItem)) ? (
      <AnalysisItem
        analysisItem={fullAnalysisItem}
        match={renderProps.match}
        external={external}
      />
    ) : (
      <Scrollbar
        disableTracksWidthCompensation
        noScrollX
        className={classes.scrollAreaStyle}
      >
        <AnalysisItem
          analysisItem={fullAnalysisItem}
          match={renderProps.match}
          external={external}
        />
      </Scrollbar>
    );

  return (
    <div className={classes.root}>
      <Switch>
        <SentryRoute
          path={match.path}
          render={(renderProps) =>
            match.params.externalAnalysisId ? (
              singleAnalysisView(true, renderProps)
            ) : isLeafElipsized ? (
              singleAnalysisView(false, renderProps)
            ) : isListSingleItem(analysisList) ? (
              <AnalysisItem
                analysisItem={analysisList[0]}
                match={renderProps.match}
              />
            ) : (
              <Scrollbar
                disableTracksWidthCompensation
                noScrollX
                className={classes.scrollAreaStyle}
              >
                <div className={classes.columnContainer}>
                  <TestDetailTabContainer />
                  {selectedFeature && Array.isArray(analysisList)
                    ? analysisList.map((item, i) => (
                        <React.Fragment key={i}>
                          <AnalysisItem
                            analysisItem={item}
                            key={i}
                            match={renderProps.match}
                            isMultiListItem
                            customStyle={
                              specialItem(item) ? { marginBottom: '3vh' } : {}
                            }
                          />
                          {analysisList.length - 1 === i ||
                          specialItem(item) ? null : (
                            <Divider
                              variant="middle"
                              style={{ marginBottom: '4vh' }}
                            />
                          )}
                        </React.Fragment>
                      ))
                    : null}
                </div>
              </Scrollbar>
            )
          }
        />
      </Switch>
    </div>
  );
}
