import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import ChartTabs from '../shareTabbedChart/chartTabs';
import ChartController from './chartController';
import {
  integerWithCommas,
  numberWithCommas,
} from '../../../../modules/commonModule';
import TabDataListTable from './tabDataList';
import { METRIC_CHART_TAB_CADENCES } from './constants';

export default function TabbedChartContent({
  filteredData,
  metricComponentType,
}) {
  const [selectedTab, setSelectedTab] = useState(
    filteredData?.dataList?.graphTabs[0]
  );
  const [tableRowSelected, setTableRowSelected] = useState(0);
  const [selectedTabData, setSelectedTabData] = useState(
    getTabDataFromId(selectedTab?.id)
  );

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
    setSelectedTabData(getTabDataFromId(tab?.id));
  };

  const handleTableRowSelect = (event, index) => {
    if (index !== tableRowSelected) {
      setTableRowSelected(index);
    }
  };

  useEffect(() => {
    setTableRowSelected(0);
    const newSelectedTabIndex = filteredData?.dataList?.graphTabs?.findIndex(
      (tabData) => tabData.name === selectedTab?.name
    );
    const tabIndex = newSelectedTabIndex === -1 ? 0 : newSelectedTabIndex;
    handleTabSelect(filteredData?.dataList?.graphTabs[tabIndex]);
  }, [filteredData]);

  function getTabDataFromId(tabId) {
    const selectedTabObject = filteredData?.dataList?.graphData?.find(
      (tabData) => Object.keys(tabData)[0] === tabId
    );
    return !selectedTabObject ? null : Object.values(selectedTabObject)?.[0];
  }

  if (
    filteredData?.dataList?.graphTabs.findIndex(
      (graphTab) => graphTab?.id === selectedTab?.id
    ) === -1
  ) {
    const newSelectedTabIndex = filteredData?.dataList?.graphTabs?.findIndex(
      (tabData) => tabData.name === selectedTab?.name
    );
    const tabIndex = newSelectedTabIndex === -1 ? 0 : newSelectedTabIndex;
    handleTabSelect(filteredData?.dataList?.graphTabs[tabIndex]);
  }
  const matTabId = filteredData?.dataList?.graphTabs.find(
    (tabData) => tabData.value === METRIC_CHART_TAB_CADENCES.ANNUAL
  )?.id;
  const matTabData = getTabDataFromId(matTabId);

  return (
    <>
      <Typography
        variant="h2"
        color="primary.contrastText"
        gutterBottom
        align="left"
        sx={{ marginTop: '16px', marginBottom: '16px' }}
      >
        {filteredData?.title}
      </Typography>
      <ChartTabs
        tabs={filteredData?.dataList?.graphTabs}
        selectedTab={selectedTab}
        setCurrentTab={handleTabSelect}
        annualTargetRow={matTabData?.listItems?.[tableRowSelected]}
        metricComponentType={metricComponentType}
      />
      <ChartController
        currentTab={selectedTab}
        rowSelected={tableRowSelected}
        dataList={selectedTabData}
        numberFormatter={
          filteredData?.isFractional ? numberWithCommas : integerWithCommas
        }
      />
      <TabDataListTable
        selectedData={selectedTabData}
        rowSelected={tableRowSelected}
        onRowSelect={handleTableRowSelect}
        metricComponentType={metricComponentType}
        timeframeSelection={selectedTab}
      />
    </>
  );
}
