/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, CircularProgress } from '@mui/material';
import { useCurationReports } from '../reports/charts/hooks/useCurationReports';
import { themeColors } from '../../../../../styles/fieldViewStyles';
import FrequencyChartHeader from './frequencyChartHeader';
import FrequencyBarChart from './frequencyBarChart';
import { DEFAULT_TABS } from './constants';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderBottom: '1px solid #313B49',
    paddingBottom: '24px',
  },
  graphLoading: {
    display: 'flex',
    minHeight: '527px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    background: themeColors.mainSurfaceBackground,
    padding: '0px 8px',
    borderRadius: 4,
  },
  graphContent: {
    display: 'flex',
    width: '100%',
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorMessage: {
    textAlign: 'center',
    marginTop: '16px',
  },
}));

function FrequencyChartProvider({ projectId, regionId }) {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(DEFAULT_TABS.ALL);

  const { isLoading, data } = useCurationReports({
    projectId,
    regionId,
    report: 'frequency',
  });

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  let selectedData;
  let chartTabs;
  if (!isLoading) {
    selectedData = data.data.find((val) => val.graphTab === selectedTab);
    chartTabs = data.data.map((val) => val.graphTab);
  }

  return (
    (isLoading || data?.data) && (
      <div className={classes.root}>
        <FrequencyChartHeader
          chartTabs={chartTabs}
          selectedTab={selectedTab}
          handleTabSelect={handleTabSelect}
          segmentName={selectedData?.data?.[0]?.segmentName}
          isLoading={isLoading}
        />
        {isLoading && (
          <div className={classes.graphLoading}>
            <CircularProgress />
          </div>
        )}
        {!isLoading && (
          <div className={classes.graphContent}>
            {selectedData && (
              <FrequencyBarChart
                selectedTab={selectedTab}
                chartTabs={chartTabs}
                series={formatSeries(selectedData, selectedTab)}
              />
            )}
            {!selectedData && (
              <Typography className={classes.errorMessage}>
                No data available for the selected tab
              </Typography>
            )}
          </div>
        )}
      </div>
    )
  );
}

const formatSeries = (data, selectedTab) => {
  const seriesData =
    selectedTab === DEFAULT_TABS.ALL
      ? formatAverageSeries(data)
      : formatSegmentSeries(data);
  return [{ name: 'Frequency', data: seriesData }];
};

const formatAverageSeries = (data) => {
  const averageFrequencyData = [];
  for (const val of data.data || []) {
    averageFrequencyData.push({
      x: val.cps,
      y: val.averageFrequency,
      goals: [
        {
          name: 'Target',
          value: val.targetFrequency,
          strokeHeight: 2,
          strokeColor: '#FBFCFE',
        },
      ],
    });
  }
  return averageFrequencyData;
};

const formatSegmentSeries = (data) => {
  const segmentFrequencyData = [];
  for (const val of data.data || []) {
    segmentFrequencyData.push({
      x: val.index,
      y: val.frequency,
      goals: [
        {
          name: 'Target',
          value: val.targetFrequency,
          strokeHeight: 2,
          strokeColor: '#FBFCFE',
        },
      ],
    });
  }
  return segmentFrequencyData;
};

export default FrequencyChartProvider;
