import { TAB_PATHS } from '../components/headoffice/constants';

export const searchAction = (keyword) => ({
  type: 'HEAD_OFFICE_VIEW/SEARCH',
  payload: keyword,
});

export const closeSearch = () => ({
  type: 'HEAD_OFFICE_VIEW/CLOSE_SEARCH',
});

export const setTabView = (tabView = TAB_PATHS.TABLE) => ({
  type: 'HEAD_OFFICE_VIEW/SET_TAB_VIEW',
  payload: tabView,
});

export function setSegment(segmentId = '') {
  return {
    type: 'HEAD_OFFICE_VIEW/SET_SEGMENT',
    payload: segmentId,
  };
}
