export const createMultiselectFilters = (id, label, filterType, oldFilters) => {
  const newFilters = { ...oldFilters };
  const newFilterConfig = { ...oldFilters[filterType] };
  if (newFilters[filterType]?.[id]) {
    delete newFilterConfig[id];
    newFilters[filterType] = newFilterConfig;
  } else {
    newFilters[filterType] = {
      ...newFilters[filterType],
      [id]: {
        label,
        id,
      },
    };
  }
  if (Object.keys(newFilters[filterType]).length === 0) {
    delete newFilters[filterType];
  }
  return newFilters;
};

export const countMultiselectFilters = (filters) => {
  if (Object.keys(filters).length === 0) {
    return 0;
  }
  let count = 0;

  const traverse = (filter) => {
    if (
      Object.keys(filter).every(
        (key) => typeof filter[key] !== 'object' || filter[key] === null
      )
    ) {
      count++;
    } else {
      Object.keys(filter).forEach((key) => {
        if (typeof filter[key] === 'object' && filter[key] !== null) {
          traverse(filter[key]);
        }
      });
    }
  };

  traverse(filters);
  return count;
};
