import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Switch as MuiSwitch } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import {
  ModalDisplayCustomStyle,
  StyledTextField,
} from '../newproject/styles/styledComponents';
import {
  ModalDisplay,
  ModalFooter,
  ModalHeader,
} from '../generic/modalDisplay';

export const StyledModalBody = styled('div')(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
  height: 356,
  overflow: 'auto',
  padding: 20,
}));

export const CreateToggleModal = ({ isOpen, onClose, onSave }) => {
  const [newToggle, setNewToggle] = useState({
    name: '',
    description: '',
    isEnabled: false,
    type: '',
  });

  const handleOnClose = () => {
    setNewToggle({
      name: '',
      description: '',
      isEnabled: false,
      type: '',
    });
    onClose();
  };

  const handleOnSave = () => {
    onSave(newToggle);
    setNewToggle({
      name: '',
      description: '',
      isEnabled: false,
      type: '',
    });
    onClose();
  };

  const isSaveDisabled =
    !newToggle.name || !newToggle.description || !newToggle.type;
  return (
    <ModalDisplay isOpen={isOpen} style={ModalDisplayCustomStyle}>
      <ModalHeader onClose={handleOnClose}>Create New Toggle</ModalHeader>
      <StyledModalBody>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <div>Name and Description</div>{' '}
          <div>Type the name and description for the feature toggle</div>
          <StyledTextField
            focused
            label="Toggle Name"
            value={newToggle.name}
            onChange={(e) =>
              setNewToggle({
                ...newToggle,
                name: e.target.value,
              })
            }
          />
          <StyledTextField
            focused
            label="Description"
            value={newToggle.description}
            onChange={(e) =>
              setNewToggle({
                ...newToggle,
                description: e.target.value,
              })
            }
          />
          <div>Initial State</div>
          <div>Select the initial state for this toggle</div>
          <MuiSwitch
            checked={newToggle.isEnabled}
            onClick={() =>
              setNewToggle({ ...newToggle, isEnabled: !newToggle.isEnabled })
            }
          />
          <div>Toggle Type</div>
          <div>
            Determine the type for this feature toggle
            <RadioGroup
              onChange={(e) =>
                setNewToggle({ ...newToggle, type: e.target.value })
              }
            >
              <FormControlLabel
                value="MODULE"
                control={<Radio />}
                label="Module Toggle"
              />
              <FormControlLabel
                value="RELEASE"
                control={<Radio />}
                label="Release Toggle"
              />
            </RadioGroup>
          </div>
        </div>
      </StyledModalBody>
      <ModalFooter>
        <Button
          variant="contained"
          onClick={() => {
            handleOnSave(newToggle);
            onClose();
          }}
          disabled={isSaveDisabled}
        >
          Save Toggle
        </Button>
      </ModalFooter>
    </ModalDisplay>
  );
};
