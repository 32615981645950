import React, { useState } from 'react';
import { TextField, Chip } from '@mui/material';
import styled from '@emotion/styled';
import { Autocomplete } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

const StyledChip = styled(Chip)(({ disabled, theme: { themeColors } }) => ({
  borderColor: themeColors.borderMidContrast,
  fontSize: 14,
  color: disabled ? themeColors.buttonBorderColor : 'initial',
  '& .MuiChip-deleteIcon': {
    color: disabled ? themeColors.buttonBorderColor : themeColors.neutral60,
  },
  '& .MuiChip-label': {
    color: themeColors.segmentSelectionCaptionColor,
  },
}));

const StyledAutocomplete = styled(Autocomplete)(
  ({ theme: { themeColors } }) => ({
    width: '100%',
    '& label.Mui-focused': {
      color: themeColors.neutral60,
    },
    '&.Mui-focused .MuiAutocomplete': {
      borderColor: themeColors.neutral60,
      borderWidth: '1px',
    },
  })
);

const StyledTextField = styled(TextField)(({ theme: { themeColors } }) => ({
  '& label.Mui-focused': {
    color: themeColors.neutral60,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      border: `1px solid ${themeColors.neutral60}`,
    },
  },
  '& input::placeholder': {
    opacity: 1,
    color: themeColors.neutral60,
  },
}));

export function TextFieldWithChipsInside({ selectedItems, setSelectedItems }) {
  const [inputValue, setInputValue] = useState('');
  const handleKeyPress = (event) => {
    const { key, target } = event;
    const isEnterOrCommaKey = key === 'Enter' || key === ',';
    const newValue = target.value.trim();

    if (!isEnterOrCommaKey || !newValue) return;

    const newItems = newValue.includes(',')
      ? newValue.split(',').map((element) => element.trim())
      : [newValue];

    setSelectedItems((prevItems) => [...prevItems, ...newItems]);
    setInputValue('');
  };

  const handleInputChange = (event) => {
    if (event.target.value === ',') return;
    setInputValue(event.target.value);
  };

  return (
    <StyledAutocomplete
      multiple
      value={selectedItems}
      onChange={(e, value, reason) => {
        if (reason === 'removeOption' || reason === 'clear') {
          setSelectedItems(value);
        }
      }}
      freeSolo
      inputValue={inputValue}
      options={[]}
      renderInput={(params) => (
        <StyledTextField
          notched
          focused
          {...params}
          label="Domains"
          placeholder="Add Domain"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <StyledChip
            key={option}
            variant="outlined"
            label={option}
            deleteIcon={<CloseIcon />}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
}
