export const inlineContainer = {
  height: '100%',
  width: '100%',
  display: 'inline',
  'overflow-x': 'hidden',
  'overflow-y': 'auto',
  'text-align': 'center',
  align: 'center',
};

export const rowContainerNoWrap = {
  width: '100%',
  height: 'auto',
  display: 'flex',
  'flex-direction': 'Row',
  'flex-wrap': 'nowrap',
  'align-items': 'baseline',
  'align-content': 'center',
  marginTop: '40px',
  marginBottom: '0',
  marginLeft: 50,
};

export const rowContainerWrapped = {
  width: '100%',
  height: 'auto',
  display: 'flex',
  'flex-direction': 'Row',
  'flex-wrap': 'wrap',
};

export const rowContainerLeftAligned = {
  width: '100%',
  height: 'auto',
  display: 'flex',
  'flex-direction': 'Row',
  'justify-content': 'flex-start',
  'align-items': 'flex-start',
  'align-content': 'flex-start',
};

const tileHeight = 240;

export const columnContainer = {
  width: '100%',
  height: (tileHeight / 7) * 4,
  display: 'flex',
  'flex-direction': 'Column',
  'flex-wrap': 'wrap',
  'justify-content': 'flex-start',
  'align-items': 'flex-start',
  'align-content': 'flex-start',
};

export const columnContainerCentered = {
  width: 'auto',
  height: 'auto',
  display: 'flex',
  'flex-direction': 'Column',
  'justify-content': 'center',
  'align-items': 'baseline',
  'align-content': 'center',
};

export const columnContainerLeftAligned = {
  width: 'auto',
  height: 'auto',
  display: 'flex',
  'flex-direction': 'Column',
  'justify-content': 'flex-start',
  'align-items': 'flex-start',
  'align-content': 'flex-start',
};
