import React from 'react';
import { useFieldContext } from './useFieldContext';
import { FieldContext } from './fieldContext';

export const FieldWrapper = ({ children }) => {
  const fieldContext = useFieldContext();

  return (
    <FieldContext.Provider value={fieldContext}>
      {children}
    </FieldContext.Provider>
  );
};
