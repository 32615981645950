export const formatCellValue = (value, type, decimalPlaces = 0) => {
  const dollarFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  const multiplier = decimalPlaces ? 10 ** decimalPlaces : 1;

  switch (type) {
    case 'percent':
      return `${Math.round(value * 100 * multiplier) / multiplier}%`;
    case 'percentNotMultiplied':
      return `${Math.round(value * multiplier) / multiplier}%`;
    case 'percentNoSymbol':
      return Math.round(value * 100 * multiplier) / multiplier;
    case 'dollar':
    case 'dollars':
      return dollarFormat.format(Math.round(value));
    case 'number':
    case 'unit':
      return Math.round(value * multiplier) / multiplier;
    default:
      return value;
  }
};

export const formatModalValue = (value, modalType) => {
  switch (modalType) {
    case 'percent':
      return Math.round(value * 100);
    case 'percentNotMultiplied':
    case 'number':
    case 'unit':
    case 'dollar':
    case 'dollars':
      return Math.round(value);
    default:
      return value;
  }
};

export const getCellValue = (rowData, type) => {
  if (!rowData || rowData.length === 0) return '-';

  const value = Number(rowData[rowData.length - 1]);

  return formatCellValue(value, type);
};
