import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Scrollbar from 'react-scrollbars-custom';
import * as ViewerAction from '../../actions/projectViewActions';
import AnalysisItem from '../../components/analysisview/analysisItem';
import EmptyView from '../../components/emptyview/emptyView';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    'min-height': '100%',
    width: '100%',
    display: 'flex',
    'flex-direction': 'column',
    paddingLeft: '1vw',
    paddingRight: '1vw',
    paddingTop: '1vh',
    paddingBottom: '1vh',
  },
  scrollAreaStyle: {
    width: '100%',
    height: '100%',
  },
}));

const mapStateToProps = (state) => ({
  viewer: state.projectViewer,
});
const mapDispatchToProps = (dispatch) => ({
  getAnalysis: (analysisId, userId = null) =>
    dispatch(ViewerAction.getAnalysis(analysisId, userId)),
});

function isMatchingPhysician(metadata, analysisId, physicianId) {
  return metadata.analysisId === analysisId && metadata.userId === physicianId;
}

function PhysicianView({
  match,
  viewer: { fullAnalysisItem, isAnalysisBusy },
  getAnalysis,
}) {
  const classes = useStyles();

  const { analysisId, physicianId } = match.params;

  useEffect(() => {
    if (
      !fullAnalysisItem ||
      !isMatchingPhysician(fullAnalysisItem.metadata, analysisId, physicianId)
    ) {
      getAnalysis(analysisId, physicianId);
    }
  }, []);

  const isListSingleItem = (list) =>
    list && Array.isArray(list) && list.length === 1;
  const isSingleXGridTable = (analysisItem) =>
    isListSingleItem(analysisItem.sections) &&
    analysisItem.sections[0].content.xGridTables;

  return (
    <div className={classes.root}>
      {(isAnalysisBusy || !fullAnalysisItem) && (
        <EmptyView
          isLoading={isAnalysisBusy}
          refreshAction={() => {
            getAnalysis(analysisId, physicianId);
          }}
        />
      )}
      {!isAnalysisBusy &&
      fullAnalysisItem &&
      (fullAnalysisItem.headeredSection ||
        fullAnalysisItem.tabSection ||
        isSingleXGridTable(fullAnalysisItem)) ? (
        <AnalysisItem
          analysisItem={fullAnalysisItem}
          match={match}
          external={false}
        />
      ) : (
        <Scrollbar
          disableTracksWidthCompensation
          noScrollX
          className={classes.scrollAreaStyle}
        >
          <AnalysisItem
            analysisItem={fullAnalysisItem}
            match={match}
            external={false}
          />
        </Scrollbar>
      )}
    </div>
  );
}

PhysicianView.propTypes = {
  getAnalysis: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      analysisId: PropTypes.number,
      physicianId: PropTypes.number,
    }),
  }),
  viewer: PropTypes.shape({
    fullAnalysisItem: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        headeredSection: PropTypes.string,
        metadata: PropTypes.shape({
          analysisId: PropTypes.number,
          userId: PropTypes.number,
        }),
        tabSection: PropTypes.string,
      }),
    ]),
    isAnalysisBusy: PropTypes.bool,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianView);
