import React, { useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Stack, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import SwapVertIcon from '@mui/icons-material/SwapVert';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { CardBodyText, ObjectiveTypeText } from '../styledComponents';
import { toCapitalize } from '../../../utils/toCapitalize';
import { DeployDialogContext } from '../deployCsvDialog/deployDialogContext';
import { ProjectModalMode } from '../../newproject/projectModalMode';
import { templateToProject } from '../../newproject/newProjectTransformation';
import { useIsAuthorized } from '../../../hooks/useIsAuthorized';
import { RBAC_PERMISSIONS } from '../../../constants';

const TemplateCard = styled(Stack)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.templateCardBackground,
  borderRadius: 4,
  padding: '16px',
  width: 400,
  border: `1px solid ${themeColors.projectCardBorder}`,
  gap: 32,
}));

const StyledIconButton = styled(IconButton)({
  margin: '-4px -8px -4px',
});

const CardBodyWrapper = styled('div')({
  textAlign: 'left',
});

const W100Stack = styled(Stack)({
  width: '100%',
});
export const formatObjectives = (objectives) => {
  const objectiveTitles = [];
  if (objectives) {
    objectives.forEach((obj) => {
      let title = `${toCapitalize(obj?.typeDisplay)}: `;
      const { baskets } = obj;
      const titleSegments = {};
      baskets.forEach((basket) => {
        const { productFriendlyName, paramType, productLines } = basket;
        if (productFriendlyName) {
          titleSegments[paramType] = productFriendlyName;
        } else if (productLines) {
          titleSegments[paramType] = productLines
            .map((p) => toCapitalize(p.productName, [' ', '-']))
            .join(', ');
        }
      });

      if (titleSegments.PRODUCT_FROM && titleSegments.TO_PRODUCT) {
        title += `${titleSegments.TO_PRODUCT} vs. ${titleSegments.PRODUCT_FROM}`;
      } else {
        title += `${titleSegments.TO_PRODUCT || ''}${
          titleSegments.PRODUCT_FROM || ''
        }`;
      }
      if (objectiveTitles.indexOf(title) === -1) {
        objectiveTitles.push(title);
      }
    });
  }
  return objectiveTitles;
};

export const ProjectTemplateCard = ({ template, onDelete }) => {
  const {
    setProjectModalMode,
    setActiveProject,
    setShowProjectDialog,
    setActiveTemplate,
  } = useContext(DeployDialogContext);
  const formattedObjectives = formatObjectives(template.objectives);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClose = () => setAnchorEl(null);
  const hasProjectAdminPermissions = useIsAuthorized([
    RBAC_PERMISSIONS.PROJECTS_ADMIN,
  ]);
  const initiateProjectFlow = (modalMode) => {
    setShowProjectDialog(true);
    setProjectModalMode(modalMode);
    setActiveProject(templateToProject(template));
    setActiveTemplate(template);
    setAnchorEl(null);
  };

  return (
    <TemplateCard spacing={1} alignItems="flex-start">
      <W100Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack alignItems="flex-start">
          <Typography variant="h5" align="left">
            {template.templateName}
          </Typography>
          <Typography variant="body2" color="#E0E4EB">
            {template.marketName}
          </Typography>
        </Stack>
        <StyledIconButton
          size="large"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <MoreVertIcon />
        </StyledIconButton>
        <Menu
          id="project-more-actions-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() => {
              initiateProjectFlow(ProjectModalMode.templateEdit);
            }}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            Edit Template
          </MenuItem>
          {hasProjectAdminPermissions && (
            <MenuItem
              onClick={() => {
                initiateProjectFlow(ProjectModalMode.runProjectFromTemplate);
              }}
            >
              <ListItemIcon>
                <SwapVertIcon />
              </ListItemIcon>
              Run project from template
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              onDelete();
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <DeleteOutlineIcon />
            </ListItemIcon>
            Delete Template
          </MenuItem>
        </Menu>
      </W100Stack>
      <Stack gap={0.5}>
        {formattedObjectives.map((objectiveFullName, i) => {
          const [objectiveType, objectiveName] = objectiveFullName.split(':');
          return (
            <CardBodyWrapper key={`${objectiveName}-${i}`}>
              <ObjectiveTypeText>
                {objectiveType}: <CardBodyText>{objectiveName}</CardBodyText>
              </ObjectiveTypeText>
            </CardBodyWrapper>
          );
        })}
      </Stack>
    </TemplateCard>
  );
};
