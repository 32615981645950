import React, { useContext, useEffect, useState } from 'react';
import {
  DataGridPremium,
  useGridApiRef,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { TerritoryOverviewContext } from '../../territoryOverviewContext';
import {
  territoryOverviewDataExists,
  calculateLabelColumnWidth,
  isSupplyTableRowEmpty,
  getTerritoryRoute,
  isTerritoryOverviewProductEmpty,
  getTimeframeName,
} from '../../utils';
import { SupplyTableHeader } from './SupplyTableHeader';
import { SupplyTableCell } from './SupplyTableCell';
import { SkeletonBars } from '../../../../components/generic/skeletonBars';
import { trackTerritoryOverviewEntityDataVisualized } from '../../../../trackers/mixpanel';

const lineColors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'];

const getLineColor = (index) => lineColors[index % lineColors.length];

const generateRowStyles = () => {
  const stylesObject = {};
  [...Array(lineColors.length).keys()].forEach((index) => {
    stylesObject[`& .selectedSupplyTableRow--${index}`] = {
      '& .Mui-checked': {
        color: getLineColor(index),
      },
    };
  });
  return stylesObject;
};

const StyledDatagrid = styled(DataGridPremium)(
  ({ theme: { themeColors } }) => ({
    '.MuiDataGrid-root': {
      overflow: 'auto',
    },
    '.MuiDataGrid-columnHeader': {
      color: '#8595AD',
      '&:last-child': {
        color: '#FFFFFF',
      },
    },
    '.MuiDataGrid-columnHeaders': {
      borderColor: themeColors.borderLowContrast,
    },
    '.MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `none`,
    },
    '.MuiDataGrid-pinnedColumns, .MuiDataGrid-pinnedColumnHeaders': {
      borderRight: `1px solid ${themeColors.borderLowContrast}`,
      background: themeColors.mainBackground,
      '.MuiDataGrid-cell': {
        paddingRight: '1rem',
        boxSizing: 'border-box',
      },
    },
    '.MuiDataGrid-row': {
      fontWeight: 500,
      boxSizing: 'content-box',
    },
    '.MuiDataGrid-iconSeparator': {
      color: themeColors.secondaryTextColor,
      display: 'none',
    },
    '& .MuiDataGrid-cell:hover': {
      color: 'primary.main',
    },
    '.MuiDataGrid-row.Mui-selected.Mui-hovered, .MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-hovered':
      {
        background: 'none',
      },
    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
      {
        display: 'none',
      },
    '.MuiDataGrid-cell--textRight > :first-of-type > :first-of-type': {
      justifyContent: 'flex-end',
    },
    '.territorySupplyTableRow:has(>.territoryCell-0:hover), .territorySupplyTableRow:has(>.territoryCell-0:hover) + .activitySupplyTableRow':
      {
        backgroundColor: themeColors.segmentSelectionHoverBackgroundColor,
        cursor: 'pointer',
      },
    '.activitySupplyTableRow:has(>.activityCell-0:hover), .territorySupplyTableRow:has(+ .activitySupplyTableRow>.activityCell-0:hover)':
      {
        backgroundColor: themeColors.segmentSelectionHoverBackgroundColor,
        cursor: 'pointer',
      },
    '.MuiDataGrid-cell': {
      overflow: 'visible',
      alignItems: 'center',
    },
    '.activitySupplyTableRow': {
      color: '#8595AD',
      fontSize: 12,
      borderBottom: `1px solid ${themeColors.borderLowContrast}`,
    },
    '.territoryCell': { paddingTop: 15, paddingBottom: 4 },
    '.activityCell': {
      paddingTop: 0,
      paddingBottom: 10,
      color: '#8595AD',
    },
    ...generateRowStyles(),
    borderColor: themeColors.borderLowContrast,
    border: '0',
    boxShadow: 'none',
    paddingTop: 20,
    '.MuiDataGrid-virtualScroller': {
      '&::-webkit-scrollbar': {
        width: 14,
        height: 14,
      },
      '&::-webkit-scrollbar-track': {
        background: '#1F232C',
        border: 'none',
        boxShadow: 'none',
      },
      '&::-webkit-scrollbar-corner': {
        background: '#1F232C',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#455368',
        borderRadius: 100,
        border: `3px solid #1F232C`,
        '&:hover': {
          background: '#C2CAD6',
        },
      },
    },
    '.MuiDataGrid-footerContainer': {
      border: 'none',
    },
  })
);

const DataGridColumnsInitialState = {
  pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, '0'] },
};

const DataGridOptions = {
  padding: 'dense',
};

const TableWrapper = styled(Box)(() => ({
  overflow: 'auto',
  '>.MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor':
    {
      paddingTop: 0,
    },
}));

export const formatCellName = (params, averageActivityRow) => {
  if (params.row.id === averageActivityRow) {
    return 'activityCell';
  }

  if (params.row.id === 0) {
    return 'territoryCell';
  }

  if (params.row.id > averageActivityRow) {
    return `activityCell activityCell-${params.field}`;
  }

  return `territoryCell territoryCell-${params.field}`;
};

export const makeTableRowPairs = (items, headerCount) => {
  let rowMap = {};

  const tableRows = items.reduce((rowsAcc, item, index) => {
    const response = {};
    const activityResponse = {};
    let regionId = '';

    for (let i = 0; i < headerCount; i++) {
      response[i] = { data: item[i].data, trend: item[i].trend };
      activityResponse[i] = {
        activity: item[i].activity ?? 'Activity',
      };
      if (!item[i].activity) {
        regionId = i;
      }
    }

    const row = [
      {
        ...response,
        id: index,
        color: getLineColor(index),
      },
      {
        ...activityResponse,
        id: index + items.length,
        color: getLineColor(index),
      },
    ];

    rowMap = { ...rowMap, [index]: row[0] };

    if (
      isSupplyTableRowEmpty(row[0], regionId) &&
      isSupplyTableRowEmpty(row[1], regionId)
    ) {
      return rowsAcc;
    }

    rowsAcc.push(row);
    return rowsAcc;
  }, []);

  return { tableRows, rowMap };
};

const findSelectedRows = (rows, selectedSupplyRowNumbers) =>
  selectedSupplyRowNumbers
    .map((id) => rows?.find((row) => row?.id === id))
    .filter((row) => row);

export const sortTimescaleData = (items, sortModel) => {
  if (sortModel.length === 0) return items;

  const avgRow = items[0];
  const sortedItems = [...items.slice(1)];

  sortModel.forEach(({ field, sort }) => {
    sortedItems.sort((a, b) => {
      const direction = sort === 'asc' ? 1 : -1;
      const data1 = a[0][field].data[0];
      const data2 = b[0][field].data[0];

      if (data1 > data2) return direction;
      if (data1 < data2) return -direction;
      return 0;
    });
  });

  return [avgRow, ...sortedItems];
};

export const SupplyTable = ({ isLoading, setIsLoading }) => {
  const {
    data,
    selectedTimeframeID,
    updateSelectedSupplyRows,
    regionId: maptualListId,
    projectId,
    distributionType,
    categoryOptions,
    selectedCategory,
    metricOptions,
    selectedMetric,
  } = useContext(TerritoryOverviewContext);

  const dataGridApiRef = useGridApiRef();
  const [coordinates, setCoordinates] = useState({ rowIndex: 0, colIndex: 0 });
  const [intermediateRowPairs, setIntermediateRowPairs] = useState();
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const [selectedSupplyRowIds, setSelectedSupplyRowIds] = useState([]);
  const [averageActivityRowIndex, setAverageActivityRowIndex] =
    useState(undefined);
  const [labelHeaderId, setLabelHeaderId] = useState('');
  const [rowMapById, setRowMapById] = useState({});
  const [sortModel, setSortModel] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    setSelectedSupplyRowIds([]);
    setAverageActivityRowIndex(undefined);
    const hasTerritoryOverviewData =
      territoryOverviewDataExists(data, selectedTimeframeID) &&
      !isTerritoryOverviewProductEmpty(data, selectedTimeframeID);

    if (hasTerritoryOverviewData) {
      const newIntermediateRowPairs = {};
      Object.keys(data.timescaleData).forEach((key) => {
        const timescaleData = data.timescaleData[key];
        const { tableRows, rowMap } = makeTableRowPairs(
          timescaleData.items,
          timescaleData.headers.length
        );
        newIntermediateRowPairs[key] = tableRows;
        setRowMapById(rowMap);
      });
      setIntermediateRowPairs(newIntermediateRowPairs);
    }
  }, [data]);

  useEffect(() => {
    setCoordinates({
      rowIndex: 0,
      colIndex: columns?.length || 0,
    });
  }, [columns]);

  useEffect(() => {
    if (rows && columns) {
      setIsLoading(false);
    }
  }, [rows, columns]);

  useEffect(() => {
    if (intermediateRowPairs) {
      const rowPairs = intermediateRowPairs[selectedTimeframeID];
      const sortedTimescaleData = sortTimescaleData(rowPairs, sortModel);
      const newRows = sortedTimescaleData.flat();
      setRows(newRows);

      const timescaleData = data.timescaleData[selectedTimeframeID];
      const labelColWidth = calculateLabelColumnWidth(timescaleData);
      const tableHeaders = timescaleData.headers;
      const tableLabelHeaderId = tableHeaders.findIndex(
        (item) => item.type === 'label'
      );
      setLabelHeaderId(tableLabelHeaderId);
      setColumns(
        tableHeaders?.map(({ label, subLabel, type }, index) => ({
          field: `${index}`,
          minWidth: type === 'label' ? labelColWidth : 140,
          maxWidth: type === 'label' ? '20rem' : 300,
          renderHeader: () =>
            SupplyTableHeader({
              label,
              subLabel,
            }),
          type: type === 'label' ? 'string' : 'number',
          flex: 1,
          sortable: true,
          renderCell: ({ row }) => SupplyTableCell({ row: row[index], type }),
        }))
      );

      const newSelectedRows = findSelectedRows(newRows, selectedSupplyRowIds);
      updateSelectedSupplyRows(newSelectedRows);

      const totalNumberOfRegions = timescaleData.items.length;
      setAverageActivityRowIndex(totalNumberOfRegions);
    }
  }, [intermediateRowPairs, sortModel]);

  useEffect(() => {
    if (isLoading || !dataGridApiRef?.current || !coordinates) return;

    const unsubscribe = dataGridApiRef.current.subscribeEvent(
      'scrollPositionChange',
      () => {
        dataGridApiRef.current.scrollToIndexes(coordinates);
        unsubscribe();
      }
    );
  }, [dataGridApiRef?.current, coordinates]);

  useEffect(() => {
    if (isLoading || !dataGridApiRef?.current) return;
    const emptySortModel = [];
    dataGridApiRef.current.setSortModel(emptySortModel);
  }, [selectedTimeframeID]);

  useEffect(() => {
    const defaultSupplyRowNumbers = [0, averageActivityRowIndex];

    if (data && distributionType && categoryOptions && metricOptions) {
      defaultSupplyRowNumbers.forEach((rowNumber) => {
        const entityName = rows?.find((row) => row?.id === 0)[0].data;
        const dataType =
          rowNumber < averageActivityRowIndex ? 'Metric' : 'Activity';

        trackTerritoryOverviewEntityDataVisualized(
          [
            getTimeframeName(data, selectedTimeframeID),
            distributionType,
            categoryOptions[selectedCategory].label,
            metricOptions[selectedMetric].label,
          ],
          'Display',
          entityName,
          dataType,
          true
        );
      });
    }

    setSelectedSupplyRowIds(defaultSupplyRowNumbers);
  }, [averageActivityRowIndex]);

  useEffect(() => {
    updateSelectedSupplyRows(findSelectedRows(rows, selectedSupplyRowIds));
  }, [selectedSupplyRowIds]);

  const isTableRowSelectable = ({ row }) =>
    !isSupplyTableRowEmpty(row, labelHeaderId);

  const onRowSelection = (newSelection) => {
    const addedRows = newSelection.filter(
      (x) => !selectedSupplyRowIds.includes(x)
    );

    const removedRows = selectedSupplyRowIds.filter(
      (x) => !newSelection.includes(x)
    );
    const updatedRowIds = [...addedRows, ...removedRows];

    const parentId =
      updatedRowIds[0] < averageActivityRowIndex
        ? updatedRowIds[0]
        : updatedRowIds[0] - averageActivityRowIndex;

    const entityName = rows?.find((row) => row?.id === parentId)[0].data;

    const action = addedRows.length > 0 ? 'Display' : 'Hide';

    const dataType =
      updatedRowIds[0] < averageActivityRowIndex ? 'Metric' : 'Activity';

    trackTerritoryOverviewEntityDataVisualized(
      [
        getTimeframeName(data, selectedTimeframeID),
        distributionType,
        categoryOptions[selectedCategory].label,
        metricOptions[selectedMetric].label,
      ],
      action,
      entityName,
      dataType,
      false
    );

    setSelectedSupplyRowIds(newSelection);
  };

  const onCellClick = (params, ev) => {
    const isHeaderCell = params.field === '0';
    const isDataGridCell = Array.from(ev.target.classList).includes(
      'MuiDataGrid-cell'
    );
    const isRegionAvgRow =
      params.id === averageActivityRowIndex || params.id === 0;
    if (!isHeaderCell || !isDataGridCell || isRegionAvgRow) {
      return;
    }

    const clickedRow =
      params.id >= averageActivityRowIndex
        ? rowMapById[params.id - averageActivityRowIndex]
        : params.row;

    const route = getTerritoryRoute(clickedRow, maptualListId, projectId);

    window.open(route);
  };

  return isLoading ? (
    <SkeletonBars />
  ) : (
    <TableWrapper>
      <StyledDatagrid
        apiRef={dataGridApiRef}
        columns={columns || []}
        hideFooter
        columnHeaderHeight={52}
        disableSelectionOnClick
        disableColumnMenu
        rowHeight={36}
        getRowHeight={({ id }) => (id >= averageActivityRowIndex ? 30 : 55)}
        isRowSelectable={isTableRowSelectable}
        rows={rows || []}
        disableMultipleSelection
        loading={isLoading}
        sortingMode="server"
        sortingOrder={['desc', 'asc', null]}
        onSortModelChange={setSortModel}
        initialState={DataGridColumnsInitialState}
        options={DataGridOptions}
        checkboxSelection
        disableRowSelectionOnClick
        rowSelectionModel={selectedSupplyRowIds}
        onRowSelectionModelChange={onRowSelection}
        getRowClassName={({ row }) =>
          `selectedSupplyTableRow--${
            (row.id >= averageActivityRowIndex
              ? row.id - averageActivityRowIndex
              : row.id) % lineColors.length
          } ${
            row.id >= averageActivityRowIndex
              ? `activitySupplyTableRow ${row.field}`
              : 'territorySupplyTableRow'
          }`
        }
        getCellClassName={(params) =>
          formatCellName(params, averageActivityRowIndex)
        }
        onCellClick={onCellClick}
      />
    </TableWrapper>
  );
};
