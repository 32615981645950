import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  Skeleton,
  Box,
  Typography,
  Badge,
  Tooltip,
  List,
  ListItem,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const Wrapper = styled(Box)(({ width }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  gap: '4px',
  maxWidth: 'fit-content',
  minWidth: width,
}));

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
}));

const TooltipContainer = styled(List)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.buttonActiveBackgroundColor,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  rowGap: '2px',
  padding: '4px 8px',

  '.MuiList-root': {
    padding: 0,
  },
}));

const StyledBadge = styled(Badge)(({ theme: { themeColors } }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: themeColors.badgePrimaryColor,
  padding: '2px 4px',
  borderRadius: '3px',
  width: 'fit-content',
  maxWidth: '120px',
}));

const StyledTypography = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.badgePrimaryContentColor,
  fontSize: '11px',
  fontWeight: 400,
  lineHeight: '12px',
}));

const StyledListItem = styled(ListItem)(({ theme: { themeColors } }) => ({
  color: themeColors.buttonHoverContentColor,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16px',
  padding: '4px 0px',
}));

const LoadingContainer = styled(Box)(() => ({
  margin: 'auto',
}));

const StyledSkeleton = styled(Skeleton)(({ theme: { themeColors } }) => ({
  color: themeColors.buttonContentColor,
  transform: 'none',
}));

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    position: 'relative',
    top: '-7px',
    fontSize: '14px',
    fontWeight: 400,
    color: theme.themeColors.buttonHoverContentColor,
    backgroundColor: theme.themeColors.buttonActiveBackgroundColor,
  },
  arrow: {
    color: theme.themeColors.buttonHoverBorderColor,
  },
}))(Tooltip);

const tooltipContent = (content) => (
  <TooltipContainer>
    {content.map((value) => (
      <StyledListItem key={value.id}>{value.title}</StyledListItem>
    ))}
  </TooltipContainer>
);

const GenericTooltip = ({ title, children }) => (
  <StyledTooltip title={title} placement="bottom" enterTouchDelay={400} arrow>
    {children}
  </StyledTooltip>
);

export const BadgeContainer = ({
  content,
  isLoading,
  maxItems = 2,
  width = 150,
}) => {
  const [initialContent, setInitialContent] = useState([]);
  const [ellipsesTooltipContent, setEllipsesTooltipContent] = useState(null);

  useEffect(() => {
    setInitialContent(content.slice(0, maxItems));
    setEllipsesTooltipContent(tooltipContent(content));
  }, [content]);

  return (
    <Wrapper width={width}>
      {isLoading && (
        <LoadingContainer>
          <StyledSkeleton height={35} width={width} />
        </LoadingContainer>
      )}
      {!isLoading &&
        initialContent &&
        initialContent.length > 0 &&
        initialContent.map((value, i) => (
          <Container key={value.id}>
            <GenericTooltip title={value.title}>
              <StyledBadge>
                <StyledTypography noWrap>{value.title}</StyledTypography>
              </StyledBadge>
            </GenericTooltip>
            {i === initialContent.length - 1 &&
              content.length !== initialContent.length && (
                <GenericTooltip title={ellipsesTooltipContent}>
                  <StyledBadge>
                    <StyledTypography>{`+${
                      content.length - initialContent.length
                    }`}</StyledTypography>
                  </StyledBadge>
                </GenericTooltip>
              )}
          </Container>
        ))}
    </Wrapper>
  );
};
