import axios from 'axios';
import { maptualApiInstance } from './config';
import { Distribution } from '../containers/territoryOverview/constants';

export const getTerritoryOverviewData = ({
  projectId,
  regionId,
  distribution,
  categoryId,
  metric,
}) => {
  let suffix = '';

  if (distribution === Distribution.DEMAND) {
    suffix = `objective/${categoryId}/metric/${metric}`;
  } else {
    suffix = `product/${categoryId}`;
  }
  return maptualApiInstance
    .get(
      `field/product-lines/TODO/projects/${projectId}/regions/${regionId}/territory-overview/${distribution}/${suffix}`,
      { timeout: 60000 }
    )
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          if (error.response.data) {
            return axios.get(error.response.data, { timeout: 60000 });
          }
          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    });
};

export const getTerritoryOverviewMetadata = ({ projectId, regionId }) =>
  maptualApiInstance
    .get(
      `field/product-lines/TODO/projects/${projectId}/regions/${regionId}/territory-overview/metadata`,
      { timeout: 60000 }
    )
    .then((res) =>
      res.status === 200 ? { ...res, data: JSON.parse(res.data) } : res
    );
