export const PROJECT_REFRESH_MODAL_STATES = {
  REFRESH: 'refresh',
  IN_PROGRESS: 'in progress',
  ACCEPT: 'accept',
  REJECT: 'reject',
  ERROR: 'error',
  LOADING: 'loading',
  SUCCESS: 'success',
  REJECTED: 'rejected',
};
