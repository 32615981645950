import { Box, styled, Input, InputAdornment, Checkbox } from '@mui/material';
import React, { useContext, useState } from 'react';
import { ObjectiveModalActionsContext } from '../newProjectContext';
import { StyledMetricsRow } from '../styles/styledComponents';
import { AdminContext } from '../../../containers/application/appViews/adminContext';

const StyledMetricEntryRow = styled(StyledMetricsRow)({
  padding: '10px 0px 10px 20px',
});

const StyledMetricName = styled(Box)({
  fontSize: 14,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const StyledCheckbox = styled(Checkbox)(({ theme: { themeColors } }) => ({
  width: 20,
  height: 20,
  padding: 0,
  borderRadius: 0,
  color: themeColors.primaryMaptualListFilteringColor,
}));

export const validate = {
  scoringWeight: {
    whileTyping: (value) => value.match(/^((?!0)(\d*)|0)$/),
    finishedTyping: (value) => value.match(/^(\d+)$/),
  },
};

const ScoringWeightField = styled(Input)({
  width: 50,
});

export const MetricEntry = ({
  metric,
  basketConfig,
  setBasketConfig,
  entityType = null,
}) => {
  const {
    metricFriendlyName,
    metricRxType,
    scoringWeight: metricScoringWeight,
    visualize: visualizeStatus,
  } = metric;
  const { isProjectBuilderDemoMode } = useContext(AdminContext);
  const { updateBaskets } = useContext(ObjectiveModalActionsContext);

  const [scoringWeight, setScoringWeight] = useState(metricScoringWeight);
  const [scoringWeightAsPercentage, setScoringWeightAsPercentage] = useState(
    (scoringWeight * 100).toString()
  );
  const [visualize, setVisualize] = useState(visualizeStatus);

  const handleScoringWeightChange = (newValueAsPercentage) => {
    setScoringWeightAsPercentage(newValueAsPercentage);
    setScoringWeight(parseFloat(newValueAsPercentage) / 100);
    const updatedMetrics = basketConfig.metrics[entityType].map((m) =>
      m.metricRxType === metricRxType
        ? {
            ...m,
            scoringWeight: parseFloat(newValueAsPercentage) / 100,
          }
        : m
    );

    const newBasketState = {
      ...basketConfig,
      metrics: {
        ...basketConfig.metrics,
        [entityType]: updatedMetrics,
      },
    };
    setBasketConfig(newBasketState);
    updateBaskets(newBasketState);
  };

  const handleVisualizationChange = (event) => {
    setVisualize(event.target.checked);
    const updatedMetrics = basketConfig.metrics[entityType].map((m) =>
      m.metricRxType === metricRxType
        ? {
            ...m,
            visualize: event.target.checked,
          }
        : m
    );
    const newBasketState = {
      ...basketConfig,
      metrics: {
        ...basketConfig.metrics,
        [entityType]: updatedMetrics,
      },
    };
    setBasketConfig(newBasketState);
    updateBaskets(newBasketState);
  };

  return (
    <StyledMetricEntryRow
      isProjectBuilderDemoMode={isProjectBuilderDemoMode}
      data-testid="test-newproject-metrics-content"
    >
      <StyledMetricName>{metricFriendlyName}</StyledMetricName>

      {!isProjectBuilderDemoMode && (
        <ScoringWeightField
          value={scoringWeightAsPercentage}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          error={
            +!validate.scoringWeight.finishedTyping(scoringWeightAsPercentage)
          }
          onChange={(e) => {
            const newValue = e.target.value;
            if (validate.scoringWeight.whileTyping(newValue)) {
              handleScoringWeightChange(newValue);
            }
          }}
          data-testid="test-newproject-metric-scoringweight-field"
        />
      )}

      <Box>
        <StyledCheckbox
          checked={visualize}
          onChange={handleVisualizationChange}
        />
      </Box>
    </StyledMetricEntryRow>
  );
};
