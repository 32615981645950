import TreeItem from '@mui/lab/TreeItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox, styled } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useMemo, useState, useRef } from 'react';

const StyledExpandMoreIcon = styled(ExpandMoreIcon)({
  marginLeft: '16px',
  height: '24px',
  width: '24px',
});
const StyledChevronRightIcon = styled(ChevronRightIcon)({
  marginLeft: '16px',
  height: '24px',
  width: '24px',
});

const StyledCheckbox = styled(Checkbox)({
  padding: '3px',
});

const StyledFormControlLabel = styled(FormControlLabel)({
  marginLeft: '8px',
  gap: '8px',
});

const StyledTreeItem = styled(TreeItem)({});

export const productAncestorsDict = {};
export const generateProductAncestors = (node, ancestors = []) => {
  if (!node.productId) {
    return;
  }
  productAncestorsDict[node.productId] = ancestors;
  if (node.subCategories && node.subCategories.length > 0) {
    const updatedAncestors = ancestors.concat(node.productId);
    node.subCategories.forEach((subcategory) =>
      generateProductAncestors(subcategory, updatedAncestors)
    );
  }
};

export const generateExpandedNodes = (selectedProducts) => {
  const defaultExpandedProductsSet = new Set([
    ...selectedProducts.map((product) => product.productId),
  ]);
  selectedProducts.forEach((product) => {
    productAncestorsDict[product.productId].forEach((productId) =>
      defaultExpandedProductsSet.add(productId)
    );
  });
  return Array.from(defaultExpandedProductsSet);
};

export const ProductTree = ({
  productLineTree,
  selectedProducts,
  setSelectedProducts,
  onProductChange,
  setProductMenuDropdownDirectionFlip,
}) => {
  const productMenuDropdownRef = useRef(null);
  const [productMenuDropdownTop, setProductMenuDropdownTop] = useState(0);
  function handleOnChange(shouldRemoveFromList, node) {
    const array = shouldRemoveFromList
      ? selectedProducts.filter((value) => value.productId !== node.productId)
      : [
          ...selectedProducts,
          { productName: node.productName, productId: node.productId },
        ];
    setSelectedProducts(array);
    onProductChange(array);
  }
  const renderTree = (node) =>
    node?.productId && node?.productName ? (
      <StyledTreeItem
        key={node.productId}
        nodeId={node.productId}
        label={
          <StyledFormControlLabel
            control={
              <StyledCheckbox
                checked={selectedProducts.some(
                  (item) => item.productId === node.productId
                )}
                onChange={() =>
                  handleOnChange(
                    selectedProducts.some(
                      (item) => item.productId === node.productId
                    ),
                    node
                  )
                }
                onClick={(e) => e.stopPropagation()}
              />
            }
            label={node.productName.toUpperCase()}
            key={node.productId}
          />
        }
      >
        {Array.isArray(node.subCategories)
          ? node.subCategories.map((subCategory) => renderTree(subCategory))
          : null}
      </StyledTreeItem>
    ) : (
      <StyledTreeItem key="" nodeId="" label="" />
    );

  const defaultExpandedProducts = useMemo(() => {
    if (!productLineTree) return [];
    generateProductAncestors(productLineTree);
    return generateExpandedNodes(selectedProducts);
  }, [productLineTree]);

  const resizeProductMenu = () => {
    // productMenuDropdownRef requires setTimeout to properly reflect state of component
    // if code becomes too complex/bugged, alternate easy approach described in ODAIA-5591
    setTimeout(() => {
      const productMenuDropdownHeight =
        productMenuDropdownRef.current.clientHeight;
      const maxAdmissibleHeight = window.innerHeight - 20;
      const heightAdjustment =
        maxAdmissibleHeight -
        (productMenuDropdownTop + productMenuDropdownHeight);
      if (heightAdjustment < 0) {
        setProductMenuDropdownDirectionFlip(true);
        productMenuDropdownRef.current.style.top = `${
          heightAdjustment + productMenuDropdownTop
        }`;
      } else {
        setProductMenuDropdownDirectionFlip(false);
        productMenuDropdownRef.current.style.top = '0';
      }
    }, 300);
  };

  return (
    <TreeView
      defaultCollapseIcon={<StyledExpandMoreIcon />}
      defaultExpandIcon={<StyledChevronRightIcon />}
      defaultExpanded={defaultExpandedProducts}
      ref={productMenuDropdownRef}
      onNodeToggle={() => resizeProductMenu()}
      onNodeSelect={() => {
        // productMenuDropdownRef requires setTimeout to properly reflect state of component
        setTimeout(() => {
          setProductMenuDropdownTop(
            productMenuDropdownRef.current.getBoundingClientRect().top
          );
        }, 300);
      }}
    >
      {renderTree(productLineTree)}
    </TreeView>
  );
};
