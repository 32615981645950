import { usePermissionsQuery } from '../request/queries';

export function usePreloadAuthorization() {
  return usePermissionsQuery();
}

export function useIsAuthorized(requiredPermissions = []) {
  const queryResponse = usePermissionsQuery();

  if (!Array.isArray(requiredPermissions)) {
    throw new Error(
      'useIsAuthorized requiredPermissions must be an array of strings'
    );
  }

  function isAuthorized(permissions) {
    if (!queryResponse.data || !permissions) {
      return false;
    }
    return permissions.every((permission) =>
      queryResponse.data.includes(permission)
    );
  }
  if (requiredPermissions.length === 0) {
    return isAuthorized;
  }
  return isAuthorized(requiredPermissions);
}
