import React from 'react';
import { Box, styled } from '@mui/material';
import { SegmentChip } from '../../../shared/SegmentChip';
import { SEGMENT_COLUMN_WIDTH } from '../../constants';

const Wrapper = styled(Box)({
  display: 'flex',
  gap: 8,
  flexWrap: 'wrap',
  width: SEGMENT_COLUMN_WIDTH,
  maxHeight: 40,
  overflow: 'hidden',
});

export const EntityTableSegmentCell = ({ data }) => (
  <Wrapper>
    {data.map((segment, index) => (
      <SegmentChip segmentLabel={segment} key={`segment-chip-${index}`} />
    ))}
  </Wrapper>
);
