import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Fade from '@mui/material/Fade';
import EmptyView from '../emptyview/emptyView';
import { EntityMaptualInsights } from './entityMaptualInsights';
import { useCategoricalData } from '../../hooks/useCategoricalData';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 2,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function HCPEntityPage({
  entity,
  entityMetadata,
  isEntityBusy,
  getEntity,
  getShare,
  getInsights,
  maptualListId,
  entityType,
  isMaptualListBusy,
  projectId,
  displayUserCreatedListsButton = false,
}) {
  const classes = useStyles();
  const maptualInsights = entity?.maptualInsights || null;

  const { categoricalData } = useCategoricalData();

  const isLoaded =
    entity &&
    entityMetadata &&
    entity.metadata?.entityId &&
    entity.metadata?.entityId === entityMetadata.entityId &&
    !isEntityBusy &&
    !isMaptualListBusy;

  return (
    <Fade in timeout={600} key={entity?.metadata?.entityId || '0'}>
      <div className={classes.root}>
        {isLoaded ? (
          <EntityMaptualInsights
            entityMetadata={entityMetadata}
            entity={entity}
            maptualListId={maptualListId}
            categoricalData={categoricalData}
            maptualTopChannels={
              maptualInsights ? maptualInsights.maptualTopChannels : null
            }
            displayUserCreatedListsButton={displayUserCreatedListsButton}
          />
        ) : (
          <EmptyView
            isLoading={isEntityBusy || isMaptualListBusy}
            loadingTitle={
              (isMaptualListBusy && 'Waiting for the List to Load ...') ||
              entityMetadata?.primaryText ||
              'Loading HCP ...'
            }
            refreshAction={() => {
              try {
                if (
                  maptualListId &&
                  entityType &&
                  entityMetadata?.entityId &&
                  projectId
                ) {
                  getInsights(
                    entityMetadata?.entityId,
                    projectId,
                    maptualListId
                  );
                  getEntity(
                    entityType,
                    entityMetadata?.entityId,
                    maptualListId,
                    projectId,
                    true
                  );
                  getShare(
                    entityType,
                    entityMetadata?.entityId,
                    maptualListId,
                    projectId
                  );
                }
              } catch (error) {
                // eslint-disable-next-line no-console
                console.error('[ERROR] Reload HCP Page Error ', error);
              }
            }}
          />
        )}
      </div>
    </Fade>
  );
}

HCPEntityPage.propTypes = {
  entity: PropTypes.shape({
    entityUData: PropTypes.any,
    maptualInsights: PropTypes.shape({
      maptualScoreNormalized: PropTypes.any,
      maptualTopChannels: PropTypes.any,
    }),
    metadata: PropTypes.shape({
      entityId: PropTypes.string,
    }),
  }),
  entityMetadata: PropTypes.shape({
    entityId: PropTypes.string,
    primaryText: PropTypes.string,
  }),
  isEntityBusy: PropTypes.bool,
  getEntity: PropTypes.func.isRequired,
  getShare: PropTypes.func.isRequired,
  getInsights: PropTypes.func.isRequired,
  maptualListId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entityType: PropTypes.string,
  isMaptualListBusy: PropTypes.bool,
  projectId: PropTypes.string,
};
