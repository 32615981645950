const initState = {
  keyword: null,
  isSearchActive: false,
  rows: null,
  isLoading: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case 'HEAD_OFFICE_VIEW/GET_SEARCH_RESPONSE_FULFILLED':
      return {
        ...state,
        rows: action.payload.rows,
        isLoading: false,
      };
    case 'HEAD_OFFICE_VIEW/GET_SEARCH_RESPONSE_REJECTED':
      return {
        ...state,
        rows: null,
        isLoading: false,
      };
    case 'HEAD_OFFICE_VIEW/GET_SEARCH_RESPONSE_PENDING':
      return {
        ...state,
        isLoading: true,
      };
    case 'HEAD_OFFICE_VIEW/SEARCH':
      return {
        ...state,
        keyword: action.payload,
        isSearchActive: true,
      };
    case 'HEAD_OFFICE_VIEW/CLOSE_SEARCH':
      return {
        ...state,
        keyword: null,
        isSearchActive: false,
      };
    default:
      return state;
  }
}
