import { useQuery } from 'react-query';
import { useContext } from 'react';
import { getCurationEvents } from '../../../../../../../request/projectRequests';
import { AppContext } from '../../../../../appContext';

export const useCurationEvents = ({ projectId, mode, timestamp }) => {
  const { projectList } = useContext(AppContext);
  const productLineId = projectList.find(
    (proj) => proj.projectId === projectId
  )?.productLineId;
  const queryCacheKey = Object.values({
    productLineId,
    mode,
    timestamp,
  });
  return useQuery(
    queryCacheKey,
    async () => {
      try {
        const response = await getCurationEvents({
          productLineId,
          projectId,
          mode,
          timestamp,
        });
        return response;
      } catch {
        return [];
      }
    },
    {
      cacheTime: 0,
      refetchInterval: 30000,
    }
  );
};
