import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { blueGrey, red, green, grey } from '@mui/material/colors';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import moment from 'moment';
import SemiOpaqueChip from '../chips/semiOpaqueChip';
import { darkThemeColors } from '../../styles/colorConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 470,
    height: 'auto',
  },
  box: {
    width: 180,
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    backgroundColor: grey.A400,
    borderRadius: 3,
  },
  timelineDotStyle: {
    backgroundColor: darkThemeColors.tertiaryColor,
  },
  timeText: {
    fontSize: 12,
    fontWeight: 500,
    color: darkThemeColors.tertiaryColor,
  },
  primaryText: {
    fontSize: 14,
    fontWeight: 800,
    color: theme.palette.text.secondary,
  },
  secondaryText: {
    fontSize: 13,
    fontWeight: 500,
    color: darkThemeColors.tertiaryColor,
  },
  tertiaryText: {
    fontSize: 12,
    fontWeight: 500,
    color: darkThemeColors.tertiaryColor,
  },
  ctxColumnText: {
    fontSize: 12,
    fontWeight: 800,
    color: darkThemeColors.tertiaryColor,
  },
  ctxValueText: {
    fontSize: 12,
    fontWeight: 500,
    color: darkThemeColors.tertiaryColor,
  },
}));

const formatUnixTime = (timeStamp) =>
  moment.unix(timeStamp).format('ddd, MMM D YYYY');

const getActivityStyle = (type, activityCategory, semiOpaqueChip) => {
  if (activityCategory === 'HISTORICAL_SYNTHESIZED') {
    if (semiOpaqueChip) {
      switch (semiOpaqueChip.colorTone) {
        case 'POSITIVE': {
          switch (type) {
            case 'primaryText':
              return { color: green[50] };
            case 'secondaryText':
              return { color: green[200] };
            case 'tertiaryText':
              return { color: green[300] };
            default:
              return { color: green[400] };
          }
        }
        case 'NEGATIVE': {
          switch (type) {
            case 'primaryText':
              return { color: red[50] };
            case 'secondaryText':
              return { color: red[200] };
            case 'tertiaryText':
              return { color: red[300] };
            default:
              return { color: red[300] };
          }
        }
        default: {
          switch (type) {
            case 'primaryText':
              return { color: blueGrey[50] };
            case 'secondaryText':
              return { color: blueGrey[200] };
            case 'tertiaryText':
              return { color: blueGrey[300] };
            default:
              return { color: blueGrey[400] };
          }
        }
      }
    } else {
      switch (type) {
        case 'primaryText':
          return { color: blueGrey[50] };
        case 'secondaryText':
          return { color: blueGrey[200] };
        case 'tertiaryText':
          return { color: blueGrey[300] };
        default:
          return { color: blueGrey[400] };
      }
    }
  }
  return {};
};

const getActivityContainerStyle = (activityCategory, semiOpaqueChip) => {
  if (activityCategory === 'HISTORICAL_SYNTHESIZED') {
    if (semiOpaqueChip) {
      switch (semiOpaqueChip.colorTone) {
        case 'POSITIVE':
          return { backgroundColor: green[800] };
        case 'NEGATIVE':
          return { backgroundColor: red[800] };
        default:
          return { backgroundColor: blueGrey[900] };
      }
    } else return { backgroundColor: blueGrey[900] };
  }
  return {};
};

export default function Journey({ journeySequence }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {Array.isArray(journeySequence) ? (
        <Timeline align="alternate">
          {journeySequence.map((item, i) => {
            const { primaryText, secondaryText, tertiaryText, match } = item;
            const {
              activityCategory = 'HISTORICAL',
              activityIcon = null,
              ctxColumn,
              ctxValue,
              timeStamp,
              semiOpaqueChip,
            } = item;
            return (
              <TimelineItem key={i}>
                <TimelineOppositeContent>
                  <Typography className={classes.timeText}>
                    {timeStamp && !Number.isNaN(timeStamp)
                      ? formatUnixTime(timeStamp)
                      : timeStamp ?? null}
                  </Typography>
                  {semiOpaqueChip ? (
                    <SemiOpaqueChip {...semiOpaqueChip} match={match} />
                  ) : null}
                </TimelineOppositeContent>

                <TimelineSeparator>
                  <TimelineDot className={classes.timelineDotStyle}>
                    {activityIcon || null}
                  </TimelineDot>
                  {i < journeySequence.length - 1 ? (
                    <TimelineConnector
                      style={{ backgroundColor: darkThemeColors.tertiaryColor }}
                    />
                  ) : null}
                </TimelineSeparator>

                <TimelineContent>
                  <div
                    className={classes.box}
                    style={getActivityContainerStyle(
                      activityCategory,
                      semiOpaqueChip
                    )}
                  >
                    {primaryText ? (
                      <Typography
                        className={classes.primaryText}
                        noWrap
                        align="left"
                        style={getActivityStyle(
                          'primaryText',
                          activityCategory,
                          semiOpaqueChip
                        )}
                      >
                        {primaryText}
                      </Typography>
                    ) : null}
                    {secondaryText ? (
                      <Typography
                        className={classes.secondaryText}
                        noWrap
                        align="left"
                        style={getActivityStyle(
                          'secondaryText',
                          activityCategory,
                          semiOpaqueChip
                        )}
                      >
                        {secondaryText}
                      </Typography>
                    ) : null}
                    {tertiaryText ? (
                      <Typography
                        className={classes.tertiaryText}
                        noWrap
                        align="left"
                        style={getActivityStyle(
                          'tertiaryText',
                          activityCategory,
                          semiOpaqueChip
                        )}
                      >
                        {tertiaryText}
                      </Typography>
                    ) : null}
                    {ctxColumn ? (
                      <Typography
                        className={classes.ctxColumnText}
                        noWrap
                        align="left"
                        style={getActivityStyle(
                          'ctxColumn',
                          activityCategory,
                          semiOpaqueChip
                        )}
                      >
                        {ctxColumn}
                      </Typography>
                    ) : null}
                    {ctxValue ? (
                      <Typography
                        className={classes.ctxValueText}
                        align="left"
                        style={getActivityStyle(
                          'ctxValue',
                          activityCategory,
                          semiOpaqueChip
                        )}
                      >
                        {ctxValue}
                      </Typography>
                    ) : null}
                  </div>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      ) : null}
    </div>
  );
}
