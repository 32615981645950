import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';

import { EntityDrawerError } from '../EntityDrawerError';
import {
  CADENCES_ENUM,
  ERROR_TYPES_ENUM,
  UNITS_ENUM,
} from '../../../constants';
import {
  formatYAxisValue,
  getCadences,
  getMaxValue,
  getUnits,
} from '../drawerHelpers';
import { createTooltip, INITIAL_TOOLTIP_STATE, Tooltip } from '../Tooltip';
import {
  GraphControls,
  GraphSection,
  GraphTitle,
} from './sharedStyledComponents';
import { useEntityDrawerChartData } from '../data/useEntityDrawerData';
import { EntityDrawerContext } from '../data/entityDrawerContext';
import { CHART_TYPES_ENUM } from '../../../../shared/graphs/constants';
import { DrawerChartSkeleton } from '../../skeletons/DrawerChartSkeleton';
import { TabGroup } from '../EntityDrawerChartTabs';
import { TooltipPopover } from '../../../../shared/TooltipPopover';

const formatTimestampLabel = (timestamp, cadence) => {
  if (cadence === 'wk') {
    return timestamp.split(',')[0];
  }

  if (cadence === 'mth' || cadence === 'qtr') {
    return timestamp.split(' ')[0];
  }

  return timestamp;
};

const createHoverLine = (chart, strokeColor) => {
  const x = chart.tooltip.caretX;
  const { ctx } = chart;
  const topY = chart.scales.y.top;
  const bottomY = chart.scales.y.bottom;
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(x, topY);
  ctx.lineTo(x, bottomY);
  ctx.lineWidth = 1;
  ctx.setLineDash([3, 3]);
  ctx.strokeStyle = strokeColor;
  ctx.stroke();
  ctx.restore();
};

export const EntityDrawerLineGraphCard = ({
  sectionTitle,
  metrics,
  objectiveId,
}) => {
  const { entityType, entityId, regionId, projectId, productLineId } =
    useContext(EntityDrawerContext);

  const [availableCadences, setAvailableCadences] = useState([]);
  const [availableUnits, setAvailableUnits] = useState([]);
  const [cadence, setCadence] = useState();
  const [unit, setUnit] = useState();
  const [selectedMetric, setSelectedMetric] = useState();

  useEffect(() => {
    const cadences = getCadences(metrics);
    const units = getUnits(metrics);

    setAvailableCadences(cadences);
    setAvailableUnits(units);
  }, [metrics]);

  useEffect(() => {
    if (availableCadences && availableCadences.length > 0) {
      const defaultCadence = availableCadences[0];
      const selectedCadenceNotAvailable = !availableCadences.find(
        (c) => c.id === cadence
      );

      if (!cadence || selectedCadenceNotAvailable) {
        setCadence(defaultCadence?.id);
      }
    }
  }, [availableCadences]);

  useEffect(() => {
    if (availableUnits && availableUnits.length > 0) {
      const defaultUnit = availableUnits[0];
      const selectedUnitNotAvailable = !availableUnits.find(
        (u) => u.id === unit
      );

      if (!unit || selectedUnitNotAvailable) {
        setUnit(defaultUnit.id);
      }
    }
  }, [availableUnits]);

  useEffect(() => {
    if (unit && cadence) {
      const metricObject = metrics.find(
        (metric) => metric.unit === unit && metric.cadences.includes(cadence)
      );

      setSelectedMetric(metricObject);
    } else if (unit) {
      const metricObject = metrics.find((metric) => metric.unit === unit);

      setSelectedMetric(metricObject);
    }
  }, [cadence, unit]);

  const { data, isFetching, isError } = useEntityDrawerChartData({
    productLineId,
    projectId,
    regionId,
    objectiveId,
    chartType: CHART_TYPES_ENUM.LINE,
    metric: selectedMetric?.rxType,
    cadence,
    entityType,
    entityId,
  });

  const [graphTitle, setGraphTitle] = useState();
  useEffect(() => {
    if (data && cadence) {
      setGraphTitle(`${data?.title}, ${CADENCES_ENUM[cadence]}`);
    }
  }, [data, cadence]);

  return (
    <GraphSection>
      <Typography variant="h4">{sectionTitle}</Typography>
      <GraphControls>
        <TabGroup availableTabs={availableUnits} tab={unit} setTab={setUnit} />
        <TabGroup
          availableTabs={availableCadences}
          tab={cadence}
          setTab={setCadence}
        />
      </GraphControls>

      {isFetching && <DrawerChartSkeleton />}
      {isError && (
        <EntityDrawerError
          errorType={ERROR_TYPES_ENUM.RETRY}
          retryType="chart"
        />
      )}

      {!!data && (
        <>
          <GraphTitle>
            <Typography variant="h6">{graphTitle}</Typography>
            <TooltipPopover
              title={data?.title}
              description={selectedMetric?.tooltipText}
            />
          </GraphTitle>

          <Box height="240px" position="relative">
            <LineGraph
              unit={UNITS_ENUM[unit]}
              rawData={data}
              cadence={cadence}
            />
          </Box>
        </>
      )}
    </GraphSection>
  );
};

const LineGraph = ({ unit, rawData, cadence }) => {
  const { themeColors } = useTheme();

  const [tooltip, setTooltip] = useState(INITIAL_TOOLTIP_STATE);

  let labelData = [];
  if (rawData?.recentYear?.length > 0) {
    labelData = rawData.recentYear;
  } else if (rawData?.previousYear?.length > 0) {
    labelData = rawData.previousYear;
  }

  const labels = labelData.map((item) =>
    formatTimestampLabel(item.timestamp, cadence)
  );
  const data = {
    labels,
    datasets: [],
  };

  if (rawData?.recentYear?.length > 0) {
    data.datasets.push({
      label: 'Recent year',
      data: rawData?.recentYear?.map((item) => item.data),
      borderColor: themeColors.dataGeneralColor,
      pointBackgroundColor: themeColors.dataGeneralColor,
      pointStyle: 'circle',
      trends: rawData?.recentYear?.map((item) => item.trend),
      timestamps: rawData?.recentYear?.map((item) => item.timestamp),
    });
  }

  if (rawData?.previousYear?.length > 0) {
    data.datasets.push({
      label: 'Previous year',
      data: rawData?.previousYear?.map((item) => item.data),
      borderColor: themeColors.dataGeneralColor3,
      pointBackgroundColor: themeColors.dataGeneralColor3,
      pointStyle: 'circle',
      trends: rawData?.previousYear?.map((item) => item.trend),
      timestamps: rawData?.previousYear?.map((item) => item.timestamp),
    });
  }

  const max = getMaxValue(data);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
          boxHeight: 8,
          color: themeColors.tertiaryColor,
          padding: 12,
        },
        onClick: () => {},
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          createTooltip({
            context,
            currentTooltip: tooltip,
            updateTooltip: (newTooltipData) => setTooltip(newTooltipData),
            unit,
          });
        },
      },
    },
    scales: {
      y: {
        min: 0,
        max,
        border: {
          display: false,
        },
        grid: {
          drawTicks: false,
          color: themeColors.borderPrimaryColor,
          lineWidth: 1,
        },
        ticks: {
          callback: (value) => formatYAxisValue(value, unit),
          color: themeColors.tertiaryColor,
          padding: 10,
          stepSize: Math.ceil(max / 5),
          maxTicksLimit: 6,
        },
        title: {
          display: !!unit,
          text: unit,
          font: 12,
          color: themeColors.tertiaryColor,
        },
      },
      x: {
        border: {
          display: false,
        },
        grid: {
          drawTicks: true,
          drawOnChartArea: false,
          color: themeColors.dividerPrimaryColor,
        },
        ticks: {
          color: (context) => {
            const { index } = context;
            const { ticks } = context.chart.scales.x;
            if (index === ticks.length - 1) {
              return themeColors.primaryTextColor;
            }
            return themeColors.tertiaryColor;
          },
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  };

  const plugin = {
    afterDraw(chart) {
      // eslint-disable-next-line no-underscore-dangle
      if (chart?.tooltip?.caretX && chart.tooltip._active?.length) {
        createHoverLine(chart, themeColors.markerLine);
      }
    },
  };

  if (
    rawData?.recentYear?.length === 0 &&
    rawData?.previousYear?.length === 0
  ) {
    return <EntityDrawerError errorType={ERROR_TYPES_ENUM.NO_DATA} />;
  }

  return (
    <>
      <Line plugins={[plugin]} options={options} data={data} />
      <Tooltip tooltip={tooltip} title={rawData?.title} />
    </>
  );
};
