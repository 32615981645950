/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-throw-literal */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-console */
import Papa from 'papaparse';
import moment from 'moment';
import { uploadNewDatasetMeta } from '../request/ingestRequests';
import { ColModel, ColTagTypeModel } from '../models/ingestDataModel';
import { encryptChunk, compressChunk } from './dataIngestor';

const sampleSize = 8;
const maxFileSampleSizeBeforeChunking = 100 * 1024 * 1024; // 100 MB
// Data Ingestion Upload Chunck Size 5MB
Papa.LocalChunkSize = 1024 * 1024 * 1;

function createColumnizedData(result) {
  const col = [];
  const displayCol = [];
  const displayData = [];

  const hasHeader = Array.isArray(result.data[0]) === false;
  const numColumns = hasHeader
    ? Object.values(result.data[0]).length
    : result.data[0].length;
  const colFields = hasHeader
    ? Object.keys(result.data[0])
    : result.meta.fields;

  displayCol.push({
    title: 'Columns Name',
    field: 'friendlyColumnName',
    editable: 'onUpdate',
  });

  for (let i = 0; i < numColumns; i++) {
    const useDefault =
      colFields === undefined ||
      colFields === null ||
      colFields[i] === undefined ||
      colFields[i] === null ||
      colFields[i] === '';
    col.push({
      ...ColModel,
      friendlyName: useDefault ? `Column #${i}` : colFields[i].trim(),
      columnIndex: i,
      nameInOriginalSource:
        colFields[i] === '' ? `untitled_${i}` : colFields[i],
      columnIndexOriginalSource: i,
      hasHeader,
    });

    displayData.push({});
  }

  const table = Object.values(result.data);

  for (let row = 0; row < table.length; row++) {
    const tableRow = hasHeader ? Object.values(table[row]) : table[row];
    for (let i = 0; i < tableRow.length; i++) {
      displayData[i][`sample${row}`] = tableRow[i];
    }
    displayCol.push({
      title: `Sample ${row + 1}`,
      field: `sample${row}`,
      editable: 'never',
    });
  }

  return {
    columnMeta: col,
    columnDisplay: displayCol,
    columnSamples: displayData,
  };
}

function isCsvSampleValid(sample, dataColumns) {
  const sampleColumnNames = sample.meta.fields;

  // Fail if csv sample has different number of columns than data source
  if (sampleColumnNames.length !== dataColumns.length) {
    return {
      isValid: false,
      errorReason: `CSV file has ${sampleColumnNames.length} column, expected ${dataColumns.length}`,
    };
  }

  for (let i = 0; i < sampleColumnNames.length; i += 1) {
    // Check column names match
    const originalColumnName = dataColumns[i].nameInOriginalSource;
    if (sampleColumnNames[i] !== originalColumnName) {
      const trueOriginalName = sampleColumnNames[i].startsWith('untitled')
        ? ''
        : sampleColumnNames[i];
      return {
        isValid: false,
        errorReason: `Expected column ${i} to be named "${originalColumnName}", found "${trueOriginalName}" instead`,
      };
    }

    // Check column type matches for each sample row
    const columnName = sampleColumnNames[i];
    for (let j = 0; j < sample.data.length; j += 1) {
      const cell = sample.data[j][columnName];
      if (cell !== '') {
        const validTypes = {
          string: typeof cell === 'string' || cell instanceof String,
          int: Number.isInteger(parseFloat(cell)),
          float: !Number.isNaN(cell),
          bool: cell.toLowerCase() === 'true' || cell.toLowerCase() === 'false',
          datetime: moment(cell, 'YYYY-MM-DD', true).isValid(),
          categorical: typeof cell === 'string' || cell instanceof String,
          categoricalInt: Number.isInteger(parseFloat(cell)),
          categoricalFloat: !Number.isNaN(cell),
          smallint: Number.isInteger(parseFloat(cell)),
          bigint: Number.isInteger(parseFloat(cell)),
        };
        const columnType = dataColumns[i].odaiaMetadataId;
        if (!validTypes[columnType]) {
          return {
            isValid: false,
            errorReason: `cell in column "${sampleColumnNames[i]}" at row ${
              j + 1
            } with value "${cell}", is NOT a valid "${columnType}"`,
          };
        }
      }
    }
  }
  return { isValid: true };
}

export function sampleBlob(file) {
  return new Promise((resolve) => {
    if (file === undefined || file === null)
      throw { Error: 'Please make sure you have selected a valid data file.' };

    if (file.size <= maxFileSampleSizeBeforeChunking) {
      Papa.parse(file, {
        preview: sampleSize,
        header: true,
        complete: (result) => {
          resolve(createColumnizedData(result));
        },
        error: (error) => {
          console.log(error);
          throw { Error: error.message };
        },
      });
    } else {
      let chunkedFile;
      // When file size is too large, regular sampling cannot handle it properly, so we chunk the file first and let it sample after
      Papa.parse(file, {
        header: true,
        chunk: (results, parser) => {
          chunkedFile = results.data;
          parser.abort();
          if (chunkedFile) {
            chunkedFile = Papa.unparse(chunkedFile);
            Papa.parse(chunkedFile, {
              preview: sampleSize,
              header: true,
              complete: (result) => {
                resolve(createColumnizedData(result));
              },
              error: (error) => {
                console.log(error);
                throw { Error: error.message };
              },
            });
          } else {
            throw { Error: 'Chunking large file for sampling failed!' };
          }
        },
        error: (err, inputElem, reason) => {
          console.log('=================  ERROR ----> ', err, reason);
          throw { Error: err };
        },
      });
    }
  });
}

export function validateBlob(file, dataColumns) {
  return new Promise((resolve, reject) => {
    if (file === undefined || file === null)
      throw { Error: 'Please make sure you have selected a valid data file.' };

    if (file.size <= maxFileSampleSizeBeforeChunking) {
      Papa.parse(file, {
        preview: sampleSize,
        header: true,
        transformHeader: (header, i) =>
          header === '' ? `untitled_${i}` : header,
        complete: (result) => {
          const csvCheck = isCsvSampleValid(result, dataColumns);
          if (csvCheck.isValid) {
            resolve();
          } else {
            reject({ Error: csvCheck.errorReason });
          }
        },
        error: (error) => {
          console.log(error);
          throw { Error: error.message };
        },
      });
    } else {
      let chunkedFile;
      Papa.parse(file, {
        header: true,
        transformHeader: (header, i) =>
          header === '' ? `untitled_${i}` : header,
        chunk: (results, parser) => {
          chunkedFile = results.data;
          parser.abort();
          if (chunkedFile) {
            chunkedFile = Papa.unparse(chunkedFile);
            Papa.parse(chunkedFile, {
              preview: sampleSize,
              header: true,
              transformHeader: (header, i) =>
                header === '' ? `untitled_${i}` : header,
              complete: (result) => {
                const csvCheck = isCsvSampleValid(result, dataColumns);
                if (csvCheck.isValid) {
                  resolve();
                } else {
                  reject({ Error: csvCheck.errorReason });
                }
              },
              error: (error) => {
                console.log(error);
                throw { Error: error.message };
              },
            });
          } else {
            throw { Error: 'Chunking large file for sampling failed!' };
          }
        },
        error: (err, inputElem, reason) => {
          console.log('=================  ERROR ----> ', err, reason);
          throw { Error: err };
        },
      });
    }
  });
}

function isDuplicateColumnValid(column, samples) {
  for (let i = 0; i < samples.length; i += 1) {
    const sampleCell = samples[i];
    const validTypes = {
      string: typeof sampleCell === 'string' || sampleCell instanceof String,
      int: Number.isInteger(parseFloat(sampleCell)),
      float: !Number.isNaN(sampleCell),
      bool:
        sampleCell.toLowerCase() === 'true' ||
        sampleCell.toLowerCase() === 'false',
      datetime: moment(sampleCell, 'YYYY-MM-DD', true).isValid(),
      categorical:
        typeof sampleCell === 'string' || sampleCell instanceof String,
      categoricalInt: Number.isInteger(parseFloat(sampleCell)),
      categoricalFloat: !Number.isNaN(sampleCell),
      smallint: Number.isInteger(parseFloat(sampleCell)),
      bigint: Number.isInteger(parseFloat(sampleCell)),
    };
    if (!validTypes[column.odaiaMetadataId]) {
      const trueOriginalName = column.nameInOriginalSource.startsWith(
        'untitled'
      )
        ? ''
        : column.nameInOriginalSource;
      return {
        isValid: false,
        errorReason: `cell in column "${trueOriginalName}" at row ${
          i + 1
        } with value "${sampleCell}", is NOT a valid "${
          column.odaiaMetadataId
        }"`,
      };
    }
  }
  return { isValid: true };
}

function isKeyCol(col) {
  const keyColTags = ['USERID', 'TIMESTAMP', 'ACTIVITY'];
  return keyColTags.includes(col.colTag);
}

export function validateExistingColumns(ingestModel) {
  return new Promise(async (resolve, reject) => {
    if (ingestModel.columnMeta.filter((item) => !item.isExcluded).length < 2) {
      reject({ Error: 'You must select at least 2 columns.' });
    } else if (
      ingestModel.txtInputErrMsg &&
      ingestModel.txtInputErrMsg.message
    ) {
      reject({ Error: ingestModel.txtInputErrMsg.message });
    } else {
      const userIds = ingestModel.columnMeta.find(
        (col) => col.colTag === ColTagTypeModel.USER_ID
      );
      const timestamps = ingestModel.columnMeta.filter(
        (col) => col.colTag === ColTagTypeModel.ACTIVITY_TIMESTAMP
      );
      const activities = ingestModel.columnMeta.filter(
        (col) => col.colTag === ColTagTypeModel.ACTIVITY_LABEL_ID
      );

      if (userIds && timestamps.length === 0 && activities.length === 0) {
        ingestModel.columnMeta.forEach((item) => {
          if (item.colTag !== ColTagTypeModel.USER_ID) {
            // eslint-disable-next-line no-param-reassign
            item.colTag = ColTagTypeModel.CONTEXTUAL_DATA_STATIC;
          }
        });
      }

      const duplicateColumns = [];
      const selectedColumns = ingestModel.columnMeta.map((newCol) => {
        if (newCol.friendlyName in ingestModel.existingColumnsMap) {
          const existingCol =
            ingestModel.existingColumnsMap[newCol.friendlyName];
          if (!isKeyCol(newCol) && isKeyCol(existingCol)) {
            return newCol;
          }
          if (newCol.colTag !== '' && newCol.colTag !== existingCol.colTag) {
            return newCol;
          }
          const dupCol = { ...newCol };
          dupCol.colTag = existingCol.colTag;
          dupCol.odaiaMetadataId = existingCol.odaiaMetadataId;
          duplicateColumns.push(dupCol);
          return dupCol;
        }
        return newCol;
      });

      // Type check existing column samples from csv
      let validColCheck = { isValid: true };
      for (let i = 0; i < duplicateColumns.length; i += 1) {
        const currCol = duplicateColumns[i];
        const samples = Object.values(
          ingestModel.columnSamples[currCol.columnIndexOriginalSource]
        );
        validColCheck = isDuplicateColumnValid(currCol, samples);
        if (!validColCheck.isValid) {
          break;
        }
      }

      if (validColCheck.isValid) {
        resolve(selectedColumns);
      } else {
        reject(new Error(validColCheck.errorReason));
      }
    }
  });
}

export async function ingestNewData(
  dataset,
  file,
  isNewDataset,
  percentageUpdater
) {
  return new Promise(async (resolve, reject) => {
    if (
      file === undefined ||
      file === null ||
      dataset === undefined ||
      dataset === null
    )
      throw { Error: 'Dataset or data file is not found!' };

    const fileSize = file.size;
    let completionPercent = 0.0;

    if (isNewDataset) {
      const response = await uploadNewDatasetMeta(dataset);
      console.log(response);
    }

    Papa.parse(file, {
      header: true,
      quoteChar: '"',
      escapeChar: '"',
      chunk: (results) => {
        encryptChunk(results, dataset.dataColumns)
          .then((encryptedData) => {
            compressChunk(encryptedData, dataset.datasetName);
          })
          .then(() => {
            if (
              results.meta.cursor >= fileSize ||
              fileSize - results.meta.cursor < 1024
            ) {
              completionPercent = 100.0;
              percentageUpdater(completionPercent);
              resolve();
            } else {
              completionPercent = Math.round(
                (results.meta.cursor / fileSize) * 100
              );
              percentageUpdater(completionPercent);
            }
          })
          .catch((error) => {
            reject(new Error(error));
          });
      },
      error: (err, inputElem, reason) => {
        console.log('=================  ERROR ----> ', err, reason);
      },
      complete: () => {},
    });
  });
}
