export const defaultTablePreferences = {
  visibility: {
    A_DATA_nbrx_volume_now: true,
    bestChannel: true,
    bestChannelSource: true,
    fullName: true,
    lastContactedMethod: true,
    score: true,
    specialty: true,
    territory: true,
    share_historical_trend: true,
    share_now: true,
  },
  filter: {
    items: [],
    linkOperator: 'and',
  },
};
