import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import { PowerScore } from '../../../../components/fieldView/powerScore';

export const EntityTableScoreCell = ({
  maptualDisplayScore,
  maptualScoreChange,
}) => {
  const theme = useTheme();

  return (
    <Box ml={theme.spacing(2)}>
      <PowerScore
        score={maptualDisplayScore}
        scoreChangeValue={maptualScoreChange}
      />
    </Box>
  );
};
