import { useQuery } from 'react-query';
import { maptualApiInstance } from '../../../../../../request/config';

export const useProjectTargetProducts = (productLineId, projectId) =>
  useQuery({
    queryKey: [
      'product-line',
      productLineId,
      'project',
      projectId,
      'target-products',
    ],
    queryFn: async () => {
      try {
        const response = await getProjectTargetProducts(
          productLineId,
          projectId
        );
        return response.data;
      } catch {
        return null;
      }
    },
  });

const getProjectTargetProducts = (productLineId, projectId) => {
  if (!productLineId || !projectId) {
    return Promise.reject();
  }
  return maptualApiInstance.get(
    `pulse/product-lines/${productLineId}/projects/${projectId}/target-products`,
    {
      timeout: 20000,
    }
  );
};
