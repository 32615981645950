export function deepFlatMaptualLists(lists, layer = 0) {
  const ret = [];
  const newLayer = layer + 1;
  const layeredRet = { ...lists };
  layeredRet.layer = layer;

  if (Array.isArray(lists?.nodes) && lists.nodes.length > 0) {
    Array.prototype.push.apply(
      ret,
      lists.nodes.map((item) => deepFlatMaptualLists(item, newLayer)).flat()
    );
  }

  if (layeredRet?.nodes) delete layeredRet.nodes;

  ret.push(layeredRet);

  return ret;
}

export function flatMaptualLists(lists) {
  const flatLists = deepFlatMaptualLists(lists);

  const ret = {};
  if (Array.isArray(flatLists)) {
    const sortedList = flatLists.sort((a, b) => a.layer - b.layer);

    sortedList.forEach((item) => {
      if (item.metadata?.maptualListId) {
        ret[item.metadata.maptualListId] = item;
      }
    });
  }

  return ret;
}
