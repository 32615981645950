import React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useLocation } from 'react-router-dom';

const StyledHeader = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  padding: '64px 16px 32px 16px',
  borderBottom: `1px solid ${themeColors.buttonBorderColor}`,
}));

const StyledLabel = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: '20px',
  fontWeight: '600',
  textAlign: 'left',
  color: themeColors.primaryTextColor,
  textTransform: 'capitalize',
}));

const IconContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 8px 8px 2px',
}));

const StyledLink = styled('div')(() => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
}));

const getSalesRepLink = (path) => `${path.split('sales-reps')[0]}sales-reps`;

const navigateToSalesRep = (location, history) => {
  history.push(getSalesRepLink(location.pathname));
};

const StyledArrow = styled(ArrowBackIcon)(() => ({
  width: '24px',
  height: '24px',
}));

export const Header = ({ headerTitle }) => {
  const routeHistory = useHistory();
  const routeLocation = useLocation();

  return (
    <StyledHeader>
      <StyledLink
        onClick={() => navigateToSalesRep(routeLocation, routeHistory)}
      >
        <IconContainer>
          <StyledArrow />
        </IconContainer>
        <StyledLabel>{headerTitle?.toLowerCase()}</StyledLabel>
      </StyledLink>
    </StyledHeader>
  );
};
