import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { InfiniteEntitiesTable } from '../../../../../shared/entitiesTable/InfiniteEntitiesTable';
import { Controls } from './table/controls/controls';
import { HcpRowProvider } from './table/row/hcpRowProvider';
import { CADENCE } from '../../shared/constants';
import { PulseContext } from '../../context/PulseContext';

const TableWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const initialColumns = [
  {
    id: 'score',
    label: 'Power Score',
    visible: true,
    width: 40,
  },
  {
    id: 'name',
    label: 'Name',
    visible: true,
    width: 150,
  },
];

const activityColumns = [
  {
    id: 'totalCalls',
    subLabel: 'Total Calls',
    visible: true,
    width: 70,
  },
  {
    id: 'curatedHcpsCalled',
    subLabel: '% Called When Curated',
    visible: true,
    width: 140,
  },
];

export const HcpTableProvider = ({
  objectiveId,
  timestamp,
  cadence,
  regionName,
}) => {
  const { territoryId } = useParams();
  const [metric, setMetric] = useState();
  const [metricTypes] = useState(['volume', 'share']);
  const [metricType, setMetricType] = useState([]);
  const [curatedType, setCuratedType] = useState([]);
  const { productLineId, projectTargetProducts, projectId } =
    useContext(PulseContext);

  const metricColumns = [
    {
      id: 'volume',
      label: `${metric?.name || 'Loading'}`,
      sublabel: `${metric?.name || ''} Volume`,
      visible: metricType?.includes('volume'),
      width: 140,
    },
    {
      id: 'share',
      label: `${metric?.name || 'Loading'}`,
      sublabel: `${metric?.name || ''} Share`,
      visible: metricType?.includes('share'),
      width: 140,
    },
  ];

  const updateFilterMetric = (newMetric) => {
    if (newMetric.type !== metric) {
      setMetric(newMetric);
    }
  };

  const updateFilterMetricType = (newMetricType) => {
    if (newMetricType !== metricType) {
      setMetricType(newMetricType);
    }
  };

  const updateFilterCuratedType = (newCuratedType) => {
    if (newCuratedType !== curatedType) {
      setCuratedType(newCuratedType);
    }
  };

  return (
    <TableWrapper>
      <Controls
        updateFilterMetric={updateFilterMetric}
        updateFilterMetricType={updateFilterMetricType}
        updateFilterCuratedType={updateFilterCuratedType}
        objectiveId={objectiveId}
        metric={metric}
        metricTypes={metricTypes}
      />
      <InfiniteEntitiesTable
        regionId={territoryId}
        objectiveId={objectiveId}
        timestamp={timestamp}
        metric={metric}
        metricType={metricType}
        curatedType={curatedType}
        cadence={cadence}
        hcpView
        pageSize={10}
        regionName={regionName}
        RowContentComponent={HcpRowProvider}
        initialColumns={initialColumns}
        metricColumns={metricColumns}
        activityColumns={activityColumns}
        activitySubtitle="Quarter to Date"
        activityCadence={CADENCE.DAY}
        showPowerscore
        productLineId={productLineId}
        projectTargetProducts={projectTargetProducts}
        projectId={projectId}
      />
    </TableWrapper>
  );
};
