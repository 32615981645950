/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState, useContext } from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { userCreatedListOptions } from '../../../components/fieldView/entitySelection/userCreatedListsConfig';
import { AppContext } from '../../application/appContext';

const StickyStyledRowLabel = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: 150,
  minWidth: 150,
  maxWidth: 150,
  position: 'sticky',
  zIndex: 1,
  height: 63,
  boxSizing: 'content-box',
}));

const TitleContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '2px',
}));

const StyledTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
  textAlign: 'left',
  textTransform: 'capitalize',
}));

const StyledSubTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tertiaryColor,
  textAlign: 'left',
}));

const ListIcon = styled('div')(({ theme: { themeColors } }) => ({
  svg: {
    marginTop: '3px',
    color: themeColors.tertiaryColor,
    width: '20px',
    height: '18px',
  },
}));

const getCustomListMembership = (entityId, userCreatedListEntities) => {
  const lists = [];
  for (const key of Object.keys(userCreatedListEntities || {})) {
    if (userCreatedListEntities[key].includes(entityId)) {
      lists.push(key);
    }
  }
  return lists;
};

export const RowLabel = ({
  entityId,
  title,
  subTitle,
  productLineId,
  showCustomListIcon,
}) => {
  const { profile } = useContext(AppContext);
  const [entityCustomLists, setEntityCustomLists] = useState([]);
  const userCreatedListEntities = profile?.userCreatedLists?.[productLineId];

  useEffect(() => {
    const newEntityCustomLists = getCustomListMembership(
      entityId,
      userCreatedListEntities
    );
    setEntityCustomLists(newEntityCustomLists);
  }, [entityId, userCreatedListEntities]);

  return (
    <StickyStyledRowLabel>
      <TitleContainer>
        {showCustomListIcon &&
          entityCustomLists.map((listName) => (
            <ListIcon key={listName}>
              {userCreatedListOptions[listName].icon}
            </ListIcon>
          ))}
        <StyledTitle variant="title3">{title?.toLowerCase()}</StyledTitle>
      </TitleContainer>
      {subTitle && (
        <StyledSubTitle variant="subtitle1">{subTitle}</StyledSubTitle>
      )}
    </StickyStyledRowLabel>
  );
};
