/* eslint-disable no-restricted-syntax */
import React from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import {
  CADENCE_SINGLE_NAME_MAPPING,
  CADENCE,
} from '../../application/appViews/pulse/shared/constants';
import {
  getObjectiveBasketName,
  formatTimestamp,
} from '../../application/appViews/pulse/shared/utils';
import ActivityColumns, {
  InitialColumns,
  MetricColumns,
} from './HeaderColumns';

const ColumnsRow = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  minWidth: '100%',
  width: 'fit-content',
  maxHeight: '182px',
  flexGrow: 1,
  flexDirection: 'row',
  padding: '16px 0px 0px 0px',
  gap: 32,
  borderBottom: `1px solid ${themeColors.buttonBorderColor}`,
}));

const SectionContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const HeadingContainer = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '16px',
  border: `1px solid ${themeColors.buttonBorderColor}`,
  borderBottom: 'none',
  borderRadius: '10px 10px 0px 0px',
  flexGrow: 1,
  minHeight: 124,
  minWidth: 175,
}));

const TitleContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginBottom: 'auto',
}));

const SectionTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.utilityContentColor,
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '150%',
  padding: '8px 12px',
}));

const HeadingTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tableHeaderContentColorHighlight,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '120%',
}));

const HeadingSubtitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tableHeaderContentColor,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '120%',
}));

export const TableHeader = ({
  objectiveId,
  initialColumns,
  metricColumns,
  activityColumns,
  metricCadence,
  metric,
  activitySubtitle,
  activityCadence,
  latestCurationTimestamp,
  projectTargetProducts,
}) => {
  const currentMetric = metric?.cadence?.find((m) => m.value === metricCadence);

  return (
    <ColumnsRow>
      <InitialColumns cols={initialColumns} />
      <SectionContainer>
        <SectionTitle>Activity</SectionTitle>
        <HeadingContainer>
          <TitleContainer>
            <HeadingTitle>{activitySubtitle}</HeadingTitle>
            <HeadingSubtitle>
              {formatTimestamp({
                timestamp: latestCurationTimestamp,
                cadence: activityCadence,
              })}
            </HeadingSubtitle>
          </TitleContainer>
          <ActivityColumns activityColumns={activityColumns} />
        </HeadingContainer>
      </SectionContainer>
      {metricColumns?.length > 0 && (
        <SectionContainer>
          <SectionTitle>My Products</SectionTitle>
          <HeadingContainer>
            <TitleContainer>
              <HeadingTitle>
                Latest {CADENCE_SINGLE_NAME_MAPPING[metricCadence]}
              </HeadingTitle>
              <HeadingSubtitle>
                {formatTimestamp({
                  timestamp: currentMetric?.lastTimestamp,
                  cadence:
                    metricCadence === CADENCE.QUARTER
                      ? CADENCE.PREV_QUARTER
                      : metricCadence,
                  nextWeek: false,
                })}
              </HeadingSubtitle>
            </TitleContainer>
            {projectTargetProducts && (
              <MetricColumns
                metricColumns={metricColumns}
                objectiveBasketName={getObjectiveBasketName(
                  projectTargetProducts,
                  objectiveId
                )}
              />
            )}
          </HeadingContainer>
        </SectionContainer>
      )}
    </ColumnsRow>
  );
};
