import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Timeline from './timeline/Timeline';
import { useCurationEvents } from './timeline/hooks/useCurationEvents';
import UsefulLinks from './usefulLinks';
import { CurationExeuction } from './executions/curationExecution';
// import MapChart from './map/MapChart';

const Root = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '56px 64px',
  rowGap: 32,
}));

const TimelineContainer = styled('div')(() => ({
  height: '100%',
}));

export const Administration = ({ projectId }) => {
  const testSessionData = JSON.parse(
    sessionStorage.getItem(`curation-test-events-${projectId}`)
  );
  const liveSessionData = JSON.parse(
    sessionStorage.getItem(`curation-live-events-${projectId}`)
  );
  const [testEvents, setTestEvents] = useState(testSessionData || []);
  const [liveEvents, setLiveEvents] = useState(liveSessionData || []);
  const timestamp = null;
  const { data: testEventsData } = useCurationEvents({
    projectId,
    mode: 'test',
    timestamp,
  });
  const { data: liveEventsData } = useCurationEvents({
    projectId,
    mode: 'live',
    timestamp,
  });

  useEffect(() => {
    setTestEvents([]);
    setLiveEvents([]);
  }, [projectId]);

  useEffect(() => {
    if (
      testEventsData?.data?.curation_events &&
      testEventsData.data.curation_events !== testEvents
    ) {
      const events = testEventsData.data.curation_events;
      sessionStorage.setItem(
        `curation-test-events-${projectId}`,
        JSON.stringify(events)
      );
      setTestEvents(events);
    }
  }, [testEventsData]);

  useEffect(() => {
    if (
      liveEventsData?.data?.curation_events &&
      liveEventsData.data.curation_events !== testEvents
    ) {
      const events = liveEventsData.data.curation_events;
      sessionStorage.setItem(
        `curation-live-events-${projectId}`,
        JSON.stringify(events)
      );
      setLiveEvents(events);
    }
  }, [liveEventsData]);

  return (
    <Root>
      <UsefulLinks />
      <TimelineContainer>
        <CurationExeuction
          projectId={projectId}
          executionMode="live"
          events={liveEvents}
        />
        <Timeline events={liveEvents} />
      </TimelineContainer>
      <TimelineContainer style={{ marginTop: 64 }}>
        <CurationExeuction
          projectId={projectId}
          executionMode="test"
          events={testEvents}
        />
        <Timeline events={testEvents} />
      </TimelineContainer>
      {/* <div>
        <MapChart projectId={projectId} />
      </div> */}
    </Root>
  );
};
