import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import ChannelIconProvider from '../channelIconProvider';
import { PowerScore } from '../powerScore';
import { parseDateStringWithResolution } from '../../../utils/dateParser';
import { ReactComponent as MaptualLogo } from '../../../assets/maptual_Icon_Colour.svg';

const segmentIconStyle = {
  padding: 10,
  width: 40,
  height: 40,
};

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  secondaryText: {
    fontSize: 14,
    fontWeight: 500,
    color: themeColors.secondaryTextColor,
    width: '100%',
    lineHeight: 1.15,
    marginBottom: 5,
  },

  descriptionText: {
    fontSize: 12,
    fontWeight: 400,
    color: themeColors.secondaryTextColor,
    width: '100%',
    lineHeight: 1.2,
  },
  timeStampText: {
    color: themeColors.secondaryTextColor,
    marginBottom: 5,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    backgroundColor: themeColors.contentCardBackgroundColor,
    padding: 10,
    paddingLeft: 10,
    marginBottom: 15,
    borderLeftStyle: 'solid',
    borderLeftWidth: 2,
    borderLeftColor: themeColors.contentCardBorderColor,
  },
  itemContent: {
    flexDirection: 'column',
    flexGrow: 2,
  },
  primaryTextContainer: {
    display: 'flex',
    alignContent: 'center',
    marginBottom: 5,
    padding: 0,
  },
  itemVisualContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 75,
    minWidth: 75,
    maxWidth: 75,
  },
  iconStyle: {
    padding: 10,
    width: 40,
    height: 40,
    borderRadius: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: themeColors.tertiaryColor,
    color: themeColors.primaryTextColor,
    backgroundColor: themeColors.dropDownBackground,
  },
  iconChip: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 3,
    backgroundColor: themeColors.menuTextColorContrasting,
    fontSize: 10,
    fontWeight: 600,
  },
}));

export default function ProfileJourneyView({ entityJourney }) {
  const classes = useStyles();
  const { themeColors } = useTheme();
  const { timeline = [] } = entityJourney;

  const baseIconStyle = {
    padding: 10,
    width: 40,
    height: 40,
    borderRadius: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: themeColors.tertiaryColor,
    color: themeColors.primaryTextColor,
    backgroundColor: themeColors.dropDownBackground,
  };

  const getActivityColor = (eventCategory, eventType) => {
    if (eventCategory === 'MAPTUAL') {
      switch (eventType) {
        case 'MAPTUAL_SEGMENT_JOIN':
          return {
            borderLeftColor: themeColors.maptualSegmentJoinEventColor,
            backgroundColor: `${themeColors.maptualSegmentJoinEventColor}11`,
          };
        case 'MAPTUAL_SEGMENT_LEAVE':
          return {
            borderLeftColor: themeColors.maptualSegmentLeaveEventColor,
            backgroundColor: `${themeColors.maptualSegmentLeaveEventColor}11`,
          };
        case 'MAPTUAL_SCORE_UPDATE':
          return {
            borderLeftColor: themeColors.maptualScoreEventColor,
            backgroundColor: `${themeColors.maptualScoreEventColor}11`,
          };
        case 'ENTITY_PAGE_VISIT':
          return {
            borderLeftColor: themeColors.maptualUserEventColor,
            backgroundColor: `${themeColors.maptualUserEventColor}11`,
          };
        default:
          return {};
      }
    } else {
      return {};
    }
  };

  const getPrimaryTextColor = (eventCategory, eventType) => {
    if (eventCategory === 'MAPTUAL') {
      switch (eventType) {
        case 'MAPTUAL_SEGMENT_JOIN':
          return {
            color: themeColors.maptualSegmentJoinEventTextColor,
          };
        case 'MAPTUAL_SEGMENT_LEAVE':
          return {
            color: themeColors.maptualSegmentLeaveEventTextColor,
          };
        case 'MAPTUAL_SCORE_UPDATE':
          return { color: themeColors.maptualScoreEventTextColor };
        case 'ENTITY_PAGE_VISIT':
          return { color: themeColors.maptualUserEventTextColor };
        default:
          return {};
      }
    } else {
      return {};
    }
  };

  const getSecondaryTextColor = (eventCategory, eventType) => {
    if (eventCategory === 'MAPTUAL') {
      switch (eventType) {
        case 'MAPTUAL_SEGMENT_JOIN':
          return {
            color: `${themeColors.maptualSegmentJoinEventTextColor}65`,
          };
        case 'MAPTUAL_SEGMENT_LEAVE':
          return {
            color: `${themeColors.maptualSegmentLeaveEventTextColor}65`,
          };
        case 'MAPTUAL_SCORE_UPDATE':
          return {
            color: `${themeColors.maptualScoreEventTextColor}65`,
          };
        case 'ENTITY_PAGE_VISIT':
          return {
            color: `${themeColors.maptualUserEventTextColor}65`,
          };
        default:
          return {};
      }
    } else {
      return {};
    }
  };

  const isCrmEvent = (eventCategory) => {
    switch (eventCategory) {
      case 'SALES':
      case 'MARKETING':
      case 'MSL':
      case 'CRM':
        return true;
      default:
        return false;
    }
  };

  const getCrmBackgroundColor = (eventCategory) => {
    switch (eventCategory) {
      case 'MARKETING':
      case 'SALES':
      case 'MSL':
        return themeColors.mainBackground;
      default:
        return themeColors.mainSurfaceBackground;
    }
  };

  const getCrmColor = (eventCategory) => {
    switch (eventCategory) {
      case 'MARKETING':
        return themeColors.maptualMarketingEventTextColor;
      case 'SALES':
      case 'MSL':
        return themeColors.maptualSalesEventColor;
      default:
        return themeColors.secondaryTextColor;
    }
  };

  return entityJourney ? (
    <div className={classes.root}>
      {timeline.map((item, i) => {
        const {
          timestamp = '',
          timestampResolution = '',
          eventMetadata = {},
          primaryText = '',
          secondaryText = '',
          eventData = '',
          description = '',
          icon = '',
        } = item;
        const {
          eventCategory = '',
          eventType = '',
          eventChannel = '',
        } = eventMetadata;
        const formatedDate = parseDateStringWithResolution(
          timestamp,
          timestampResolution
        );

        return (
          <div
            className={classes.itemContainer}
            key={i}
            style={getActivityColor(eventCategory, eventType)}
          >
            <div className={classes.itemContent}>
              <Typography
                className={classes.timeStampText}
                variant="caption"
                component="p"
                style={getSecondaryTextColor(eventCategory, eventType)}
              >
                {formatedDate}
              </Typography>
              <div className={classes.primaryTextContainer}>
                {isCrmEvent(eventCategory) ? (
                  <div
                    className={classes.iconChip}
                    style={{
                      backgroundColor: getCrmBackgroundColor(eventCategory),
                      color: getCrmColor(eventCategory),
                    }}
                  >
                    {eventCategory}
                  </div>
                ) : null}
              </div>
              <div className={classes.primaryTextContainer}>
                <Typography
                  variant="h6"
                  style={getPrimaryTextColor(eventCategory, eventType)}
                >
                  {primaryText}
                </Typography>
              </div>

              <Typography
                className={classes.secondaryText}
                style={getSecondaryTextColor(eventCategory, eventType)}
              >
                {secondaryText}
              </Typography>
              <Typography
                className={classes.descriptionText}
                style={getSecondaryTextColor(eventCategory, eventType)}
              >
                {description}
              </Typography>
            </div>
            <div className={classes.itemVisualContent}>
              {isCrmEvent(eventCategory) ? (
                <ChannelIconProvider
                  channelName={eventChannel}
                  iconType={icon}
                  style={baseIconStyle}
                />
              ) : null}

              {eventCategory === 'MAPTUAL' &&
              eventType === 'MAPTUAL_SCORE_UPDATE' ? (
                <PowerScore
                  score={eventData.maptualDisplayScore}
                  scoreChangeValue={eventData.maptualScoreChange}
                  objectiveScoreList={eventData.maptualObjectiveScores}
                />
              ) : null}

              {eventCategory === 'MAPTUAL' &&
              eventType === 'ENTITY_PAGE_VISIT' ? (
                <MaptualLogo style={{ ...segmentIconStyle, padding: 5 }} />
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  ) : null;
}
