import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import styled from '@emotion/styled';
import CachedIcon from '@mui/icons-material/Cached';
import { useQueryClient } from 'react-query';
import { ERROR_TYPES_ENUM } from '../../constants';

const ErrorContainer = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  padding: '48px 56px',
  flexDirection: 'column',
  gap: 16,
  borderRadius: 4,
  background: themeColors.surfaceEmpty,
  alignItems: 'center',
  height: '100%',
  justifyContent: 'center',
}));

const ErrorTextContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  textAlign: 'center',
});

const ErrorText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.emptyText,
  whiteSpace: 'pre-line',
}));

const RetryButton = styled(Button)({
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  padding: '8px 12px',
});

const RetryIcon = styled(CachedIcon)(({ theme: { themeColors } }) => ({
  width: 20,
  height: 20,
  '&.MuiSvgIcon-root': {
    color: themeColors.buttonContentColor,
  },
}));

const errorHeader = (errorType) => {
  switch (errorType) {
    case ERROR_TYPES_ENUM.NO_DATA:
      return 'Report not available';
    case ERROR_TYPES_ENUM.RETRY:
      return 'No content loaded';
    default:
      return '';
  }
};

const errorBody = (errorType) => {
  switch (errorType) {
    case ERROR_TYPES_ENUM.RETRY:
      return 'Sorry, we are having trouble loading the requested data. Please try again later.';
    case ERROR_TYPES_ENUM.NO_DATA:
      return 'We currently do not have enough data to generate a graph.';
    default:
      return '';
  }
};

export const EntityDrawerError = ({ errorType, retryType = '' }) => {
  const queryClient = useQueryClient();

  const handleRetry = () => {
    switch (retryType) {
      case 'metadata':
        queryClient.refetchQueries('entity-drawer-metadata');
        break;
      case 'chart':
        queryClient.refetchQueries('entity-drawer-chart-data');
        break;
      case 'profile':
        queryClient.refetchQueries('entity-drawer-profile');
        break;
      case 'timeline':
        queryClient.refetchQueries('entity-drawer-timeline');
        break;
      default:
        break;
    }
  };

  return (
    <ErrorContainer>
      <ErrorTextContainer>
        <ErrorText variant="title3">{errorHeader(errorType)}</ErrorText>
        <ErrorText variant="subtitle1">{errorBody(errorType)}</ErrorText>
      </ErrorTextContainer>
      {errorType === ERROR_TYPES_ENUM.RETRY && (
        <RetryButton variant="outlined" onClick={handleRetry}>
          <RetryIcon />
          Retry
        </RetryButton>
      )}
    </ErrorContainer>
  );
};
