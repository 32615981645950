import { baseAlbInstance } from './config';

export function initiateProjectRefresh({ projectId }) {
  return baseAlbInstance.get(
    `/projects/${projectId}/projectRefresh`,
    { projectId },
    { timeout: 30000 }
  );
}

export function acceptProjectRefresh({ projectId }) {
  return baseAlbInstance.get(
    `/projects/${projectId}/projectRefreshAccept`,
    { projectId },
    { timeout: 30000 }
  );
}
export function rejectProjectRefresh({ projectId }) {
  return baseAlbInstance.get(
    `/projects/${projectId}/projectRefreshReject`,
    { projectId },
    { timeout: 30000 }
  );
}
