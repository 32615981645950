import { useContext, useEffect, useState } from 'react';

import { FieldContext } from '../../application/appViews/field/fieldContext';
import {
  useEntityOverviewData,
  useEntityOverviewMetadata,
} from './useEntityOverviewData';
import { DEFAULT_ENTITY_CADENCE, ERROR_TYPES_ENUM } from '../constants';
import { AppContext } from '../../application/appContext';
import {
  buildTableItems,
  getSpecializedHeaders,
  getSpecializedItem,
} from '../components/tableHelpers';

export const useEntityOverviewContext = (entityType) => {
  const { projectList } = useContext(AppContext);
  const { project, maptualListMetadata, isMaptualListsLoading } =
    useContext(FieldContext);

  const projectId = project?.metadata?.projectId;
  const maptualListId = maptualListMetadata?.maptualListId;
  const regionName = maptualListMetadata?.listName;

  const { productLineId, marketId } =
    projectList?.find((proj) => proj.projectId === projectId) || {};

  const [searchValue, setSearchValue] = useState();

  // ============================ METADATA ============================ //

  const [currentEntityType, setCurrentEntityType] = useState();
  const [entitySubtype, setEntitySubtype] = useState();
  const [isMetadataLoading, setIsMetadataLoading] = useState(true);

  const {
    data: metadata,
    isFetching: isMetadataFetching,
    isError: isMetadataError,
    error: metadataError,
  } = useEntityOverviewMetadata({
    marketId,
    productLineId,
    projectId,
    regionId: maptualListId,
    entityType,
  });

  useEffect(() => {
    if (isMaptualListsLoading && isMetadataFetching) {
      setIsMetadataLoading(true);
    }

    if (
      !isMaptualListsLoading &&
      isMetadataFetching !== undefined &&
      isMetadataFetching !== isMetadataLoading
    ) {
      setIsMetadataLoading(isMetadataFetching);
    }
  }, [isMetadataFetching, isMaptualListsLoading]);

  useEffect(() => {
    setEntitySubtype(null);
    if (entityType !== undefined && entityType !== currentEntityType) {
      setCurrentEntityType(entityType);
    }
  }, [entityType]);

  useEffect(() => {
    if (searchValue !== null && entityType && maptualListMetadata) {
      setSearchValue(null);
    }
  }, [entityType, maptualListMetadata]);

  // ============================ DATA ============================ //

  const [objectiveId, setObjectiveId] = useState();
  const [cadence, setCadence] = useState();
  const [filters, setFilters] = useState({});
  const [data, setData] = useState();

  useEffect(() => {
    setFilters({});
  }, [maptualListId]);

  useEffect(() => {
    if (!isMetadataFetching && metadata) {
      if (!objectiveId) {
        const objectives = metadata?.objectives;
        const defaultObjectiveId =
          objectives && objectives.length > 0 ? objectives[0].id : null;

        setObjectiveId(defaultObjectiveId);
      }

      if (metadata.entitySubtypes?.length > 0) {
        setEntitySubtype(metadata.entitySubtypes[0]);
      }
    }
  }, [metadata, isMetadataFetching]);

  useEffect(() => {
    if (!isMetadataFetching && metadata && objectiveId) {
      const cadences = metadata?.objectives.find(
        (o) => o.id === objectiveId
      ).cadences;

      const defaultCadence =
        cadences && cadences.length > 0
          ? cadences.find((c) => c === DEFAULT_ENTITY_CADENCE)
          : null;

      if (!cadence || !cadences.includes(cadence)) {
        setCadence(defaultCadence);
      }
    }
  }, [metadata, isMetadataFetching, objectiveId]);

  const {
    data: serverData,
    isFetching: isDataFetching,
    isError: isDataError,
    error: dataError,
  } = useEntityOverviewData({
    productLineId,
    projectId,
    regionId: maptualListId,
    entityType,
    objectiveId,
    cadence,
    entitySubtype,
    filters,
  });

  useEffect(() => {
    if (!isDataFetching && serverData && serverData.headers?.length > 0) {
      const { headers: serverHeaders, items: serverItems } = serverData;
      const specializedHeaders = getSpecializedHeaders(entityType);

      const specializedItem = getSpecializedItem(entityType);
      const tableItems = buildTableItems(serverItems, specializedItem);

      setData({
        ...serverData,
        headers: [...serverHeaders, ...specializedHeaders],
        items: tableItems,
      });
    }
  }, [serverData, isDataFetching]);

  // ============================ ERRORS ============================ //

  const [errorType, setErrorType] = useState(null);

  useEffect(() => {
    setErrorType(null);

    if (isMetadataError) {
      setErrorType(
        metadataError?.response?.status === 422
          ? ERROR_TYPES_ENUM.NON_TERRITORY
          : ERROR_TYPES_ENUM.RETRY
      );
    } else if (isDataError) {
      setErrorType(ERROR_TYPES_ENUM.RETRY);
    } else {
      const noTableData = serverData?.items?.length === 0;
      if (noTableData) {
        setErrorType(
          Object.keys(filters).length > 0
            ? ERROR_TYPES_ENUM.NO_FILTER_DATA
            : ERROR_TYPES_ENUM.NO_DATA
        );
      }
    }
  }, [
    isMetadataFetching,
    isDataFetching,
    filters,
    serverData,
    metadataError,
    dataError,
  ]);

  // ============================ RETURN ============================ //

  return {
    entityType: currentEntityType,
    isMetadataLoading,
    isDataFetching,
    metadata,
    data,
    regionName,
    objectiveId,
    setObjectiveId,
    cadence,
    setCadence,
    entitySubtype,
    setEntitySubtype,
    filters,
    setFilters,
    isMetadataError,
    isDataError,
    errorType,
    setErrorType,
    searchValue,
    setSearchValue,
  };
};
