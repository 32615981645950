import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import {
  ModalDisplayCustomStyle,
  StyledModalBody,
} from '../../newproject/styles/styledComponents';
import {
  ModalDisplay,
  ModalFooter,
  ModalHeader,
} from '../../generic/modalDisplay';

export const TemplateDeleteModal = ({
  templateName,
  onClose,
  isOpen,
  isLoading,
  onConfirm,
}) => (
  <ModalDisplay
    isOpen={isOpen}
    style={ModalDisplayCustomStyle}
    onClose={onClose}
  >
    <ModalHeader
      onClose={onClose}
      shouldShowBottomBorder={false}
      shouldShowXIcon={false}
    >
      Confirmation
    </ModalHeader>
    <StyledModalBody>
      <Box>
        Are you sure you want to delete{' '}
        <Typography fontSize="inherit" component="span" color="white">
          {templateName}
        </Typography>{' '}
        template?
      </Box>
    </StyledModalBody>
    <ModalFooter>
      <Button onClick={onClose} variant="outlined">
        Close
      </Button>
      <Button
        onClick={onConfirm}
        variant="contained"
        disabled={isLoading}
        endIcon={isLoading ? <CircularProgress size="16px" /> : undefined}
      >
        Confirm
      </Button>
    </ModalFooter>
  </ModalDisplay>
);
