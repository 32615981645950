import { useMutation, useQueryClient } from 'react-query';
import {
  attachProductTree,
  cloneProductTree,
  createProductTree,
} from './configViewRequests';

import {
  deleteProjectTemplate,
  postNewProjectTemplate,
  updateProjectTemplate,
} from './newProjectRequests';
import { queryKeys } from './queries';

export const useCreateProductTreeMutation = (options) =>
  useMutation((requestBody) => createProductTree(requestBody), options);

export const useCloneProductTreeMutation = (options) =>
  useMutation((requestBody) => cloneProductTree(requestBody), options);

export const useAttachProductTreeMutation = (options) =>
  useMutation(
    ({ productLineId, requestBody }) =>
      attachProductTree(productLineId, requestBody),
    options
  );

export const useCreateProjectTemplateMutation = (options = {}) => {
  const queryClient = useQueryClient();
  return useMutation(postNewProjectTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.projectTemplates);
    },
    ...options,
  });
};

export const useUpdateProjectTemplateMutation = (options = {}) => {
  const queryClient = useQueryClient();
  return useMutation(updateProjectTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.projectTemplates);
    },
    ...options,
  });
};
export const useDeleteProjectTemplateMutation = (options = {}) => {
  const queryClient = useQueryClient();
  return useMutation(deleteProjectTemplate, {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.projectTemplates);
      options?.onSuccess();
    },
  });
};
