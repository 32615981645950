import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import ChangeCircleRoundedIcon from '@mui/icons-material/ChangeCircleRounded';
import PendingRoundedIcon from '@mui/icons-material/PendingRounded';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';

const STEPS = [
  {
    id: 0,
    title: 'started',
    description: 'curation pipeline started',
    type: 'MAJOR',
  },
  {
    id: 1,
    title: 'alignment',
    description: 'alignment complete',
    type: 'MAJOR',
  },
  {
    id: 2,
    title: 'hydration',
    description: 'hydration complete',
    type: 'MAJOR',
  },
  {
    id: 3,
    title: 'validation',
    description: 'validation complete',
    type: 'MAJOR',
  },
  {
    id: 4,
    title: 'publisher',
    description: 'publisher complete',
    type: 'MAJOR',
  },
];

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export default function Timeline({ events }) {
  const fullEventSteps = () => {
    if (events.length >= STEPS.length) {
      return events;
    }
    // failure state
    if (events[events.length - 1]?.status === 'FAILURE') {
      return [...events, ...STEPS.slice(events.length, STEPS.length)];
    }

    // empty state
    if (!events.length) {
      const newEvents = [...STEPS];
      newEvents[0].status = 'PENDING';
      return newEvents;
    }

    // running state
    const runningEvent = {
      ...STEPS[events.length],
      status: 'RUNNING',
    };
    const newEvents = [
      ...events,
      runningEvent,
      ...STEPS.slice(events.length + 1, STEPS.length),
    ];

    return newEvents;
  };

  const majorIconSize = '28px';
  const minorIconSize = '18px';
  // eslint-disable-next-line consistent-return
  const stepIcon = (status, type) => {
    if (!status) return <PendingRoundedIcon style={{ color: 'grey' }} />;
    if (status.toUpperCase() === 'PENDING')
      return (
        <PlayCircleFilledOutlinedIcon
          style={{
            color: 'lightgrey',
            width:
              type.toUpperCase() === 'MAJOR' ? majorIconSize : minorIconSize,
            height:
              type.toUpperCase() === 'MAJOR' ? majorIconSize : minorIconSize,
          }}
        />
      );
    if (status.toUpperCase() === 'SUCCESS')
      return (
        <CheckCircleRoundedIcon
          style={{
            color: '#336fcc',
            width:
              type.toUpperCase() === 'MAJOR' ? majorIconSize : minorIconSize,
            height:
              type.toUpperCase() === 'MAJOR' ? majorIconSize : minorIconSize,
          }}
        />
      );
    if (status.toUpperCase() === 'FAILURE')
      return (
        <ReportProblemRoundedIcon
          style={{
            color: 'red',
            width:
              type.toUpperCase() === 'MAJOR' ? majorIconSize : minorIconSize,
            height:
              type.toUpperCase() === 'MAJOR' ? majorIconSize : minorIconSize,
          }}
        />
      );
    if (status.toUpperCase() === 'RUNNING')
      return (
        <ChangeCircleRoundedIcon
          style={{ color: 'lightgrey' }}
          sx={{
            animation: 'spin 4s linear infinite',
            '@keyframes spin': {
              '0%': {
                transform: 'rotate(360deg)',
              },
              '100%': {
                transform: 'rotate(0deg)',
              },
            },
            width:
              type.toUpperCase() === 'MAJOR' ? majorIconSize : minorIconSize,
            height:
              type.toUpperCase() === 'MAJOR' ? majorIconSize : minorIconSize,
          }}
        />
      );
  };

  const secondaryLabel = (stepEvent) => {
    if (stepEvent.status && stepEvent.status.toUpperCase() === 'FAILURE') {
      return (
        <Typography variant="caption" color="error">
          {stepEvent.description}
        </Typography>
      );
    }

    if (stepEvent.status && stepEvent.status.toUpperCase() === 'RUNNING') {
      return (
        <Typography variant="caption" color="lightgrey">
          Running
        </Typography>
      );
    }

    if (stepEvent.status && stepEvent.status.toUpperCase() === 'PENDING') {
      return (
        <Typography variant="caption" color="lightgrey">
          Pending
        </Typography>
      );
    }

    return null;
  };

  const populateSteps = () => {
    const results = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const stepEvent of fullEventSteps()) {
      results.push(
        <Step key={stepEvent.id}>
          <StepLabel
            error={
              stepEvent.status && stepEvent.status.toUpperCase() === 'FAILURE'
            }
            optional={secondaryLabel(stepEvent)}
            icon={stepIcon(stepEvent?.status, stepEvent?.type)}
          >
            {stepEvent?.type?.toUpperCase() === 'MINOR' ? (
              <span
                style={{
                  position: 'absolute',
                  marginTop: '20px',
                  fontSize: '10px',
                  marginLeft: '-50px',
                }}
              >
                {capitalizeFirstLetter(stepEvent.title)}
              </span>
            ) : (
              capitalizeFirstLetter(stepEvent.title)
            )}
          </StepLabel>
        </Step>
      );
    }
    return results;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={events.length - 1}>{populateSteps()}</Stepper>
    </Box>
  );
}
