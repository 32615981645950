import { useSelector } from 'react-redux';
import {
  selectMaptualListSelectionLabel,
  selectSegmentName,
} from '../../selectors/project';

const useSphereContext = () => {
  const segmentName = useSelector(selectSegmentName);
  const maptualListSelectionLabel = useSelector(
    selectMaptualListSelectionLabel
  );

  return { segmentName, maptualListSelectionLabel };
};

export default useSphereContext;
