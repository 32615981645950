import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { getMaptualList } from '../../request/projectRequests';
import {
  addUserCreatedListPropertiesToMaptualList,
  deserializeMaptualList,
} from '../../utils/projectViewReducerUtils';
import { filterForEntitiesBySelectedUserCreatedList } from '../../utils/maptualListEntityFiltering';

export const useMaptualList = ({
  project,
  maptualListId,
  objectiveId,
  userCreatedLists,
  customListType,
}) => {
  const projectId = project?.metadata?.projectId;

  const fetchMaptualList = async () => {
    if (!projectId || !maptualListId) return {};
    const maptualList = await getMaptualList(
      projectId,
      maptualListId,
      objectiveId
    );

    return maptualList;
  };

  const {
    isLoading: isMaptualListLoading,
    data: maptualListData,
    refetch: refetchMaptualList,
    isError: isMaptualListError,
  } = useQuery(
    ['maptual-list', maptualListId, projectId, objectiveId],
    fetchMaptualList
  );

  const [maptualList, setMaptualList] = useState(null);

  useEffect(() => {
    if (isMaptualListError) {
      setMaptualList({});
    } else if (!isMaptualListLoading && maptualListData?.data) {
      const deserializedMaptualList = deserializeMaptualList(
        maptualListData.data
      );

      if (!deserializedMaptualList) {
        setMaptualList({});
        return;
      }

      const productLineUserCreatedLists = userCreatedLists
        ? userCreatedLists[project?.metadata?.productLineId]
        : undefined;

      const maptualListWithUserCreatedListProperties =
        productLineUserCreatedLists
          ? addUserCreatedListPropertiesToMaptualList(
              deserializedMaptualList,
              productLineUserCreatedLists
            )
          : undefined;

      const newMaptualList =
        maptualListWithUserCreatedListProperties ?? deserializedMaptualList;

      if (customListType) {
        setMaptualList({
          ...newMaptualList,
          list: {
            ...newMaptualList.list,
            items: filterForEntitiesBySelectedUserCreatedList(
              newMaptualList.list.items,
              customListType
            ),
          },
        });
        return;
      }

      setMaptualList(newMaptualList);
    }
  }, [maptualListData, isMaptualListLoading, customListType]);

  const updateMaptualList = (newMaptualList) => {
    setMaptualList({
      ...newMaptualList,
      list: {
        items: customListType
          ? filterForEntitiesBySelectedUserCreatedList(
              newMaptualList.list.items,
              customListType
            )
          : newMaptualList.list.items,
      },
    });
  };

  return {
    isMaptualListLoading,
    maptualList,
    refetchMaptualList,
    setMaptualList,
    updateMaptualList,
  };
};
