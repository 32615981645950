import { useQuery } from 'react-query';
import { useContext } from 'react';
import * as requests from '../request/projectRequests';
import useMaptualContext from './useMaptualContext';
import { censorScriptsMetadata } from '../modules/demoCensor';
import { AppContext } from '../containers/application/appContext';

const validateParams = (params) =>
  Object.values(params).every(
    (value) => value !== undefined && value !== null && value !== ''
  );
export const useMetricScriptsMetadata = (extraParams) => {
  const { entityId, maptualListId, projectId } = useMaptualContext();
  const { projectList } = useContext(AppContext);
  const productLineId = projectList.find(
    (proj) => proj.projectId === projectId
  )?.productLineId;

  const params = {
    entityId,
    maptualListId,
    projectId,
    productLineId,
  };

  const queryCacheKey = Object.values(params);
  return useQuery(
    queryCacheKey,
    async () => {
      const response = await requests.getScriptsMetadata(params);
      return censorScriptsMetadata(response.data);
    },
    { enabled: validateParams(params), ...extraParams }
  );
};
