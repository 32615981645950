import React, { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  getCustomerProductLines,
  updateCustomerSegment,
} from '../../request/configViewRequests';
import { SegmentEdit } from './segmentEdit';
import { ConfigViewContext } from './configViewContext';
import { CONFIG_ALERT_STATUSES } from './configAlertStatuses';

export function SegmentEditProvider({
  selectedSegment,
  setEditView,
  segmentOrders,
  rawSegments,
  setSegments,
}) {
  const { showConfigAlert } = useContext(ConfigViewContext);

  const { data: productLines, isLoading: productLinesLoading } = useQuery(
    'productLines',
    async () => {
      const response = await getCustomerProductLines();
      return response.data;
    }
  );
  const { mutate: onSaveSegment, isLoading: segmentIsSaving } = useMutation(
    ({ segmentId, requestBody }) =>
      updateCustomerSegment(segmentId, requestBody),
    {
      onSuccess: (data, variables) => {
        const { requestBody: segmentToUpdate } = variables;
        updateCustomerSegments(
          rawSegments,
          segmentToUpdate.segmentId,
          segmentToUpdate
        );
        setEditView(false);
        showConfigAlert(
          CONFIG_ALERT_STATUSES.SUCCESS,
          `Saved changes to ${segmentToUpdate.primaryText}`
        );
      },
      onError: () => {
        showConfigAlert(CONFIG_ALERT_STATUSES.ERROR);
      },
    }
  );

  function updateCustomerSegments(
    segmentsTree,
    segmentIdToReplace,
    newSegment
  ) {
    const updatedTree = JSON.parse(JSON.stringify(segmentsTree));

    function updateSegmentsInPlace(tree) {
      for (let i = 0; i < tree.length; i++) {
        const item = tree[i];

        if ('segmentId' in item && item.segmentId === segmentIdToReplace) {
          Object.keys(item).forEach((key) => delete item[key]);
          Object.assign(item, newSegment);
          return updatedTree;
        }

        if ('nodes' in item) {
          item.nodes = updateSegmentsInPlace(item.nodes);
        }
      }

      return tree;
    }

    setSegments(updateSegmentsInPlace(updatedTree));
  }

  if (!productLinesLoading && productLines) {
    return (
      <SegmentEdit
        selectedSegment={selectedSegment}
        setEditView={setEditView}
        productLines={productLines}
        onSaveSegment={onSaveSegment}
        segmentOrders={segmentOrders}
        segmentIsSaving={segmentIsSaving}
      />
    );
  }
  return null;
}
