/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import styled from '@emotion/styled';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import { Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { PROJECT_STATUS } from '../../../../../../constants';
import { useLocalStorageValue } from '../../../../../../hooks/useLocalStorageValue';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '343px',
    minWidth: '343px',
    margin: '34px 16px 13px 16px',
  },
  selectRoot: {
    width: '100%',
    minHeight: '48px',
    paddingLeft: '8px',
    fontSize: '18px',
    fontWeight: 600,
    borderRadius: '3px',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: theme.themeColors.buttonBackgroundColor,
      '&:before': {
        borderBottom: 'none !important',
      },
    },
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },

    '& .MuiInput-input': {
      background: 'none',
      '&:focus': {
        background: 'none',
      },
    },
    '& .MuiMenu-list': {
      padding: '16px',
    },
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const StyledMenuItem = styled(MenuItem)((props) => ({
  backgroundColor: props.theme.themeColors.paperColor,
  fontColor: props.theme.themeColors.segmentSelectionCaptionColor,
  padding: '12px',
  margin: '0px 16px 2px 16px',
  textAlign: 'left',
  borderRadius: '3px',
  '&.Mui-selected': {
    color: props.theme.themeColors.selectedSegmentColor,
    backgroundColor:
      props.theme.themeColors.segmentSelectionSelectedBackgroundColor,
  },
}));

const SelectPadding = styled('div')((props) => ({
  backgroundColor: props.theme.themeColors.paperColor,
  paddingTop: 8,
}));

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.themeColors.buttonBackgroundColor,
    fontColor: theme.themeColors.offWhiteFontColor,
  },
}))(Tooltip);

export default function ProjectSelector({
  projects,
  project,
  setProjectItem,
  selectMaptualList,
  isBusy,
  isMaptualListsBusy,
  isMaptualListBusy,
}) {
  const classes = useStyles();
  const [selection, setSelection] = useState({});
  const [, , removeMaptualListMetadata] = useLocalStorageValue(
    'maptualListMetadata'
  );

  useEffect(() => {
    if (project?.metadata) {
      setSelection(project);
    }
  }, [project]);

  const handleChange = (event) => {
    if (selection !== undefined && selection !== null) {
      const selectedProjectId = selection.metadata.projectId;
      if (
        selectedProjectId !== event.target.value &&
        event.target.value &&
        Array.isArray(projects)
      ) {
        const newProj = projects.find(
          (item) =>
            item && item.projectId && item.projectId === event.target.value
        );

        if (newProj) {
          setProjectItem({ metadata: { ...newProj } });
          removeMaptualListMetadata();
        }

        if (selectMaptualList) selectMaptualList(null);
      }
    }
    // [TODO] delete current loaded list, probably need to reset function that cleans a bunch of stuff
    // [TODO] set default project metadata? and move the logic here into the IF checks above when get Project?
  };

  const projectsSelections =
    Array.isArray(projects) && projects.length > 0
      ? projects.map((item, i) => {
          const ret = {};
          if (!item) {
            ret.projectId = i;
            ret.projectName = 'unknown project';
            return ret;
          }

          ret.projectId = item.projectId;
          ret.projectName = item.projectName;
          if (item.projectStatus !== PROJECT_STATUS.DEPLOYED) {
            ret.projectName = `${String.fromCodePoint(0x1f6e0)} ${
              item.projectName
            }`;
          }

          return ret;
        })
      : null;

  const tempSelections = project?.metadata
    ? {
        projectId: project.metadata.projectId,
        projectName: project.metadata.projectName,
      }
    : null;

  return (
    <FormControl
      className={classes.root}
      disabled={isBusy || isMaptualListsBusy || isMaptualListBusy}
      variant="standard"
    >
      <StyledTooltip title={selection?.metadata?.projectName} placement="top">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selection?.metadata?.projectId || ''}
          onChange={handleChange}
          data-testid="project-dropdown-selection"
          className={classes.selectRoot}
        >
          <SelectPadding />
          {projectsSelections ? (
            projectsSelections.map((item, i) => (
              <StyledMenuItem
                key={i}
                value={item.projectId}
                data-testid={item.projectName}
              >
                {item.projectName}
              </StyledMenuItem>
            ))
          ) : tempSelections?.[0] ? (
            <StyledMenuItem key={0} value={tempSelections[0].projectId}>
              <div className={classes.statusContainer}>
                {isBusy ? <CircularProgress size={12} /> : null}
                {tempSelections[0].projectName}
              </div>
            </StyledMenuItem>
          ) : null}
          <SelectPadding />
        </Select>
      </StyledTooltip>
      <div className={classes.statusContainer}>
        {isBusy || isMaptualListsBusy || isMaptualListBusy ? (
          <CircularProgress
            size={12}
            color="primary"
            style={{ marginRight: 5 }}
          />
        ) : null}
      </div>
    </FormControl>
  );
}
