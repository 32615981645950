import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';

import { Button } from '@mui/material';
import { EntityOverviewContext } from '../data/entityOverviewContext';
import { CadenceSelectorTabs } from './tableControls/CadenceSelectorTabs';
import { EntitySubtypeSelectorTabs } from './tableControls/EntitySubtypeSelectorTabs';
import { ObjectiveSelector } from './tableControls/ObjectiveSelector';
import { LONG_FORM_CADENCE_ENUM } from '../constants';
import { FilterSelector } from './tableControls/FilterSelector';
import { FilterChips } from './tableControls/FilterChips';
import { Search } from './tableControls/Search';

const HeaderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
});

const ControlsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  textAlign: 'left',
});

const StyledControlsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
  alignItems: 'center',
  flexWrap: 'wrap',
});

const StyledControls = styled(Box)({
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
});

const FilterWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
  height: 40,
});

const StyledFilterControls = styled(Box)({
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const Disclaimer = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.graphAxisColor,
}));

const ClearFiltersButton = styled(Button)({
  whiteSpace: 'nowrap',
});

export const EntityControls = () => {
  const { metadata, cadence, filters, setFilters } = useContext(
    EntityOverviewContext
  );

  const handleClearFilters = () => {
    setFilters({});
  };

  return (
    <HeaderContainer>
      <ControlsContainer>
        <StyledControls>
          <StyledControlsBox>
            <ObjectiveSelector />
            <CadenceSelectorTabs />
            {metadata?.entitySubtypes?.length > 0 && (
              <EntitySubtypeSelectorTabs />
            )}
          </StyledControlsBox>

          <FilterWrapper>
            <Search />
            <FilterSelector />
          </FilterWrapper>
        </StyledControls>
        <Disclaimer variant="subtitle1">{`Compared to previous ${LONG_FORM_CADENCE_ENUM[cadence]}`}</Disclaimer>
      </ControlsContainer>
      {Object.keys(filters).length > 0 && (
        <StyledFilterControls>
          <FilterChips />
          <ClearFiltersButton variant="utility" onClick={handleClearFilters}>
            Clear Filters
          </ClearFiltersButton>
        </StyledFilterControls>
      )}
    </HeaderContainer>
  );
};
