import React from 'react';
import styled from '@emotion/styled';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { ROUTES } from '../../../../../../constants';

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const StyledViewListButton = styled(Button, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
})((props) => ({
  width: '100%',
  padding: '12px',
  minHeight: '24px',
  justifyContent: 'flex-start',
  color: props.theme.themeColors.navBarButtonTextColor,
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '24px',
  backgroundColor: props.isActive
    ? props.theme.themeColors.buttonBorderColor
    : 'none',
  '&:hover': {
    backgroundColor: props.isActive
      ? props.theme.themeColors.buttonBorderColor
      : props.theme.themeColors.buttonBackgroundColor,
  },
  '& .MuiSvgIcon-root': {
    color: props.disabled
      ? 'unset'
      : props.theme.themeColors.navBarButtonIconColor,
  },
}));

export const ViewsList = () => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const routeContains = (route) => location.pathname.includes(route);
  const handleViewListButtonClick = (view) => {
    if (routeContains(view)) {
      return;
    }
    const route = `${ROUTES.PULSE}${ROUTES.PROJECTS}/${params?.projectId}${view}`;
    history.push(route);
  };

  return (
    <Container>
      <StyledViewListButton
        isActive={routeContains(ROUTES.SALES_REPS)}
        startIcon={<ConnectWithoutContactIcon />}
        onClick={() => handleViewListButtonClick(ROUTES.SALES_REPS)}
      >
        Sales Reps
      </StyledViewListButton>
    </Container>
  );
};
