import React from 'react';
import { FormControlLabel, Switch, FormGroup } from '@mui/material';
import styled from '@emotion/styled';
import { Title, userManagementColors } from '../styles/styledComponents';

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  '&.MuiFormControlLabel-root': {
    marginTop: '-32px',
  },
  paddingLeft: 10,

  '.MuiFormControlLabel-label': {
    color: userManagementColors.primaryColor,
    paddingLeft: 10,
  },
}));

const PermissionToggle = ({
  id,
  label,
  isChecked,
  handleChange,
  toggleText,
}) => {
  const handleToggle = (val) => {
    const value = { [label]: val };
    handleChange(id, value);
  };
  return (
    <>
      <Title variant="h5">Permissions</Title>
      <FormGroup>
        <StyledFormControlLabel
          control={
            <Switch
              checked={isChecked}
              onChange={(_, val) => handleToggle(val)}
              name={id}
            />
          }
          label={toggleText}
        />
      </FormGroup>
    </>
  );
};

export default PermissionToggle;
