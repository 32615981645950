/* eslint-disable no-restricted-syntax */
import React from 'react';
import Chart from 'react-apexcharts';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#A3AFC2',
    fontWeight: 500,
    textAlign: 'left',
    marginBottom: '8px',
  },
}));

function BarChart({ title, series, labels, yAxisTitle, xAxisTitle }) {
  const { themeColors } = useTheme();
  const classes = useStyles();

  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      height: '100%',
      background: '#191C24',
    },
    plotOptions: {
      bar: {
        columnWidth: '75%',
      },
    },
    colors: themeColors.graphLegendColors.slice(0, series.length),
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    xaxis: {
      title: {
        text: xAxisTitle,
        style: {
          color: themeColors.graphAxisColor,
        },
      },
      categories: labels,
      labels: {
        style: {
          fontSize: '12px',
          colors: Array(labels.length).fill(themeColors.graphAxisColor),
        },
      },
      axisBorder: {
        show: true,
        color: '#52627A',
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: yAxisTitle,
        offsetX: 8,
        style: {
          color: themeColors.graphAxisColor,
        },
      },
      y: 2,
      labels: {
        style: {
          fontSize: '12px',
          colors: [themeColors.graphAxisColor],
        },
      },
      axisBorder: {
        show: true,
        color: '#52627A',
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      show: true,
      borderColor: '#313B49',
    },
    legend: {
      show: series.length > 1,
      position: 'bottom',
      horizontalAlign: 'left',
      labels: {
        colors: themeColors.graphAxisColor,
      },
      markers: {
        width: 8,
        height: 8,
        radius: 14,
        offsetX: -2,
      },
      itemMargin: {
        vertical: 15,
      },
    },
    tooltip: {
      enabled: series.length > 1,
      shared: true,
      intersect: false,
      inverseOrder: false,
      theme: 'dark',
      onDatasetHover: {
        highlightDataSeries: false,
      },
      marker: {
        show: true,
      },
    },
  };

  return (
    // apex charts cannot be properly rendered in parent flex container, so this ensures it doesnt
    <div className={classes.container}>
      {title && <Typography className={classes.title}>{title}</Typography>}
      <Chart options={options} series={series} type="bar" />
    </div>
  );
}

export default BarChart;
