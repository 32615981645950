import { differenceBy, intersectionBy } from 'lodash';
import { createColumns } from '../components/headoffice/tableview/dynamicColumns';
import { defaultTablePreferences } from '../components/headoffice/tableview/defaultTablePreferences';
import DEFAULT_TABLE_PREFERENCES from '../models/defaultTablePreferenceShape.json';

export const getProjectIdFromProfileRoute = (state) => {
  const currentRoute = state.profile.preference?.route;

  if (!currentRoute) {
    return '';
  }
  const parts = currentRoute.split('/');
  const projectId = parts[3].split('?')[0];
  return projectId;
};

export function getCurrentColumnPreferencesFromState(state, projectId) {
  let currentColumnPreferences =
    state.profile.preference?.columnPreferences?.[projectId];

  if (!currentColumnPreferences) {
    currentColumnPreferences = DEFAULT_TABLE_PREFERENCES;
  }

  return { ...DEFAULT_TABLE_PREFERENCES, ...currentColumnPreferences };
}

export function getColumnListFromTableColumnConfig(columnConfig) {
  const dataGridColumnConfig = createColumns(columnConfig);
  return dataGridColumnConfig.map((col) => col.field);
}

export function syncOrderPreferences(
  currentColumnPreferences,
  supportedColumnOrder
) {
  const overlappingColumns = intersectionBy(
    currentColumnPreferences.order,
    supportedColumnOrder
  );
  const additionalColumns = differenceBy(
    supportedColumnOrder,
    currentColumnPreferences.order
  );

  const newOrder = overlappingColumns.concat(additionalColumns);
  return newOrder;
}

export function syncVisibilityPreferences(
  columnsToInclude,
  currentColumnPreferences
) {
  const newVisibility = {};
  columnsToInclude.forEach((col) => {
    if (col in currentColumnPreferences.visibility) {
      newVisibility[col] = currentColumnPreferences.visibility[col];
    } else {
      newVisibility[col] = defaultTablePreferences.visibility[col] || false;
    }
  });
  return newVisibility;
}

function syncTablePreferences(state, payload) {
  const projectId = getProjectIdFromProfileRoute(state);
  const currentColumnPreferences = getCurrentColumnPreferencesFromState(
    state,
    projectId
  );
  const supportedColumnOrder = getColumnListFromTableColumnConfig(
    payload.columnConfig
  );
  const newOrder = syncOrderPreferences(
    currentColumnPreferences,
    supportedColumnOrder
  );
  const newVisibility = syncVisibilityPreferences(
    newOrder,
    currentColumnPreferences
  );
  const newProjectTablePreferences = {
    ...currentColumnPreferences,
    order: newOrder,
    visibility: newVisibility,
  };
  return { projectId, newProjectTablePreferences };
}

export default syncTablePreferences;
