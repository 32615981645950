import React from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { SentryRoute } from '../../../sentryRoute';
import { ROUTES } from '../../../constants';
import LoginHandler from '../loginHandler';
import AppSelectView from '../../../components/app/appSelectView';

export const AppSelection = ({ maptualAccess, selectApp }) => (
  <Router>
    <Switch>
      <SentryRoute exact path={ROUTES.ROOT}>
        <Redirect to={ROUTES.APP_SELECTION} />
      </SentryRoute>
      <SentryRoute exact path={ROUTES.INDEX_HTML}>
        <Redirect to={ROUTES.APP_SELECTION} />
      </SentryRoute>
      <SentryRoute exact path={ROUTES.PROJECTS}>
        <Redirect to={ROUTES.APP_SELECTION} />
      </SentryRoute>
      <SentryRoute
        exact
        path={ROUTES.LOGIN}
        render={() => <LoginHandler redirectTo={ROUTES.APP_SELECTION} />}
      />
      <SentryRoute
        path={ROUTES.APP_SELECTION}
        render={() => (
          <AppSelectView maptualAccess={maptualAccess} selectApp={selectApp} />
        )}
      />
    </Switch>
  </Router>
);
