import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import {
  ModalDisplay,
  ModalFooter,
  ModalHeader,
} from '../generic/modalDisplay';
import { ModalDisplayCustomStyle } from './styles/styledComponents';
import { NewProjectContext } from './newProjectContext';
import { DeployDialogContext } from '../projectviewer/deployCsvDialog/deployDialogContext';
import { ProjectModalMode } from './projectModalMode';

const StyledModalBody = styled('div')(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
  padding: '5px 20px 45px',
}));

const StyledProjectDetails = styled('span')(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
}));

const headerMapping = {
  clone: 'Project Cloning in Progress',
  [ProjectModalMode.templateCreate]: 'Template Creation Complete',
  [ProjectModalMode.templateEdit]: 'Changes Saved',
  default: 'Project Creation in Progress',
};

export default function NewProjectSuccessModal({ isOpen, onClose }) {
  const { projectModalMode } = useContext(DeployDialogContext);
  const { projectConfig } = useContext(NewProjectContext);

  const renderContent = () => {
    if (projectModalMode === ProjectModalMode.templateCreate) {
      return (
        <>
          <StyledProjectDetails>
            {projectConfig.projectName}
          </StyledProjectDetails>{' '}
          is now created. You can now close this modal.
        </>
      );
    }
    if (projectModalMode === ProjectModalMode.templateEdit) {
      return (
        <>
          Changes to{' '}
          <StyledProjectDetails>
            {projectConfig.projectName}
          </StyledProjectDetails>{' '}
          are saved. You can now close this modal.
        </>
      );
    }
    return (
      <>
        <StyledProjectDetails>{projectConfig.projectName}</StyledProjectDetails>{' '}
        is now in progress. Please check back in later when project is
        completed.
      </>
    );
  };

  return (
    <ModalDisplay isOpen={isOpen} style={ModalDisplayCustomStyle}>
      <ModalHeader
        onClose={onClose}
        shouldShowBottomBorder={false}
        shouldShowXIcon={false}
      >
        {headerMapping[projectModalMode] || headerMapping.default}
      </ModalHeader>
      <StyledModalBody>{renderContent()}</StyledModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </ModalFooter>
    </ModalDisplay>
  );
}
