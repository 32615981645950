import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

export function FormActions({ onCancel, disableSubmit, isLoading }) {
  return (
    <Stack direction="row" justifyContent="end" gap={1} py={2}>
      <Button variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        type="submit"
        color="primary"
        disabled={disableSubmit}
        variant="contained"
        endIcon={isLoading ? <CircularProgress size="16px" /> : undefined}
      >
        {isLoading ? 'Creating' : 'Create and save'}
      </Button>
    </Stack>
  );
}
