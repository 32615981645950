import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import isValidProp from '@emotion/is-prop-valid';
import { ActivityDescriptionPopover } from '../../components/ActivityDescriptionPopover';

const ActivityHeaderBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 3,
}));

const HeaderBox = styled(Box, {
  shouldForwardProp: isValidProp,
})(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const HeaderText = styled(Typography)(() => ({
  whiteSpace: 'normal',
  textAlign: 'left',
}));

export const DemandTableHeader = ({ label, subLabel }) => (
  <Box>
    {label === 'Activity' ? (
      <ActivityHeaderBox>
        <HeaderText variant="caption">Activity</HeaderText>
        <ActivityDescriptionPopover />
      </ActivityHeaderBox>
    ) : (
      <HeaderBox headerType={label}>
        <HeaderText variant="caption">{label}</HeaderText>
        <HeaderText variant="caption">{subLabel}</HeaderText>
      </HeaderBox>
    )}
  </Box>
);
