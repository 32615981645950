export function sortByNewestCreation(projects) {
  return [...projects].sort((a, b) => {
    const dateA = new Date(a.creationDate * 1000);
    const dateB = new Date(b.creationDate * 1000);

    dateA.setHours(0, 0, 0, 0);
    dateB.setHours(0, 0, 0, 0);

    if (dateB.getTime() === dateA.getTime()) {
      return a.projectName
        ?.toLowerCase()
        ?.localeCompare(b.projectName?.toLowerCase());
    }

    return dateB - dateA;
  });
}
