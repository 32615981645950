import { useHistory } from 'react-router-dom';
import { trackSignIn } from '../../trackers/mixpanel';

const LoginHandler = ({ redirectTo }) => {
  const history = useHistory();
  history.push(redirectTo);
  trackSignIn();

  return null;
};

export default LoginHandler;
