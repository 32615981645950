import React, { useState, useContext } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';
import styled from '@emotion/styled';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { AppContext } from '../../containers/application/appContext';

const EventAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,

  '&:not(:last-child)': {
    borderBottom: 0,
  },
  borderWidth: '1px 0px',
  '&:before': {
    display: 'none',
  },
  background: 'none',
  padding: '0 15',
}));

const EventAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: '5px 30px 5px 20px',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    gridGap: 10,
  },
}));

const EventAccordionDetails = styled(AccordionDetails)(
  ({ theme: { themeColors } }) => ({
    paddingTop: '15px',
    backgroundColor: themeColors.codeBlockBackground,
    border: `1px solid ${themeColors.contentCardBorderColor}`,
    fontSize: 14,
  })
);

const CodeBlock = styled('pre')(({ theme: { themeColors } }) => ({
  fontFamily: 'Roboto, sans-serif',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  margin: 0,
  padding: 15,
  paddingTop: 0,
  color: themeColors.selectedSegmentColor,
  maxHeight: 500,
  overflowY: 'scroll',
  fontSize: 12,
}));

const Label = styled(Typography)(({ theme: { themeColors } }) => ({
  background: themeColors.buttonBorderColor,
  color: themeColors.buttonSecondaryBackgroundColour,
  padding: '5px 10px',
  borderRadius: 25,
  '&.fading': {
    animation: '2s fadeOut',
    animationFillMode: 'forwards',
  },
  '&.hidden': {
    opacity: 0,
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
    },
    '80%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
}));

const CopyButton = ({ event }) => {
  const [isFading, setIsFading] = useState(false);

  const waitTimeout = () => {
    const timeId = setTimeout(() => {
      setIsFading(false);
    }, 2000);

    return () => {
      clearTimeout(timeId);
    };
  };

  return (
    <>
      <Label variant="subtitle1" className={isFading ? 'fading' : 'hidden'}>
        Copied!
      </Label>

      <IconButton
        onClick={() => {
          setIsFading(true);
          waitTimeout();
          navigator.clipboard.writeText(JSON.stringify(event, null, 4));
        }}
      >
        <ContentCopyIcon />
      </IconButton>
    </>
  );
};

export const StatusIndicator = ({ status }) => {
  const { themeColors, colorToneMapping } = useTheme();
  const EventStatusColors = Object.freeze({
    PENDING: themeColors.textButtonColor,
    OK: colorToneMapping.POSITIVE,
    ERR: colorToneMapping.NEGATIVE,
    NONE: colorToneMapping.NEUTRAL,
    WARN: themeColors.graphLegendColors[1],
  });
  if (!status) {
    return (
      <WatchLaterIcon
        data-testid="request-pending"
        style={{ color: EventStatusColors.PENDING }}
      />
    );
  }
  if (status >= 400) {
    return (
      <ErrorIcon
        data-testid="request-error"
        style={{ color: EventStatusColors.ERR }}
      />
    );
  }

  if (status >= 300) {
    return (
      <RemoveCircleIcon
        data-testid="request-warn"
        style={{
          color: EventStatusColors.WARN,
        }}
      />
    );
  }

  if (status >= 200) {
    return (
      <CheckCircleIcon
        data-testid="request-success"
        style={{ color: EventStatusColors.OK }}
      />
    );
  }

  return (
    <HelpIcon
      data-testid="request-unknown"
      style={{ color: EventStatusColors.NONE }}
    />
  );
};

const StatusBar = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'end',
  color: themeColors.buttonSecondaryBackgroundColour,
  textAlign: 'left',
  paddingLeft: 15,
  svg: {
    fontSize: '0.8em',
    color: themeColors.buttonSecondaryBackgroundColour,
  },
}));

const DownloadButton = ({ event }) => (
  <IconButton
    href={`data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(event)
    )}`}
    download={`${event.queryStringParameters.user_group}_event.json`}
  >
    <FileDownloadOutlinedIcon />
  </IconButton>
);

export const PlaceholderBox = styled(Box)(({ theme: { themeColors } }) => ({
  padding: 20,
  textAlign: 'center',
  color: themeColors.textButtonColor,
  '&.noEvents': {
    background: 'rgba(47, 48, 53, 0.4)',
    margin: 15,
    borderRadius: 5,
  },
}));

export const formatTime = (date) => {
  const timestamp = date
    .toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    })
    .split(' ');
  const meridian = timestamp[1];
  const time = timestamp[0][0] === '0' ? timestamp[0].slice(1) : timestamp[0];
  return `${time}${meridian.toLowerCase()}`;
};

export const formatTitle = (path) => {
  const url = path.split('?')[0];
  const pathWithoutIds = url
    .split('/')
    .filter((p) => !/\d/.test(p) && p.length > 0);
  return `/${pathWithoutIds.join('/')}`;
};

const SummaryHeader = styled(Typography)(() => ({
  display: 'flex',
  alignContent: 'center',
  flexWrap: 'wrap',
  '&.url': {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const GrowTitle = styled(Typography)(() => ({
  flexGrow: 1,
}));
export const EventLoggerList = () => {
  const { events } = useContext(AppContext);

  if (events.length === 0) {
    return (
      <PlaceholderBox className="noEvents" data-testid="no-events">
        <Typography variant="h5">No events to show at the moment.</Typography>
      </PlaceholderBox>
    );
  }
  return (
    <Box data-testid="events">
      {events.map((ev) => (
        <EventAccordion key={ev.key}>
          <EventAccordionSummary expandIcon={<KeyboardArrowRightIcon />}>
            <SummaryHeader className="url" variant="title1">
              {formatTitle(ev.event.path)}
            </SummaryHeader>
            <SummaryHeader variant="subtitle1" color="textSecondary">
              {formatTime(ev.date)}
            </SummaryHeader>
            <StatusIndicator status={ev.status} />
          </EventAccordionSummary>
          <EventAccordionDetails>
            <StatusBar>
              <GrowTitle variant="subtitle1">
                Response Code: {ev.status ?? 'PENDING'}
              </GrowTitle>
              <CopyButton event={ev.event} />
              <DownloadButton event={ev.event} />
            </StatusBar>
            <CodeBlock>
              <code>{JSON.stringify(ev.event, null, 2)}</code>
            </CodeBlock>
          </EventAccordionDetails>
        </EventAccordion>
      ))}
    </Box>
  );
};
