import React from 'react';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useTheme } from '@mui/styles';
import styled from '@emotion/styled';
import { defaultTablePreferences } from './defaultTablePreferences';

export const isScoredHcp = (score) => score !== -1;

const StyledDatagrid = styled(DataGridPremium)(({ theme }) => ({
  '.scoreChangeCell': {
    justifyContent: 'center',
  },
  '.scoreCell': {
    justifyContent: 'center',
  },
  '.MuiDataGrid-columnsContainer': {
    backgroundColor: 'white',
  },
  '.MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.themeColors.contentCardBorderColor}`,
  },
  '.MuiDataGrid-iconSeparator': {
    color: theme.themeColors.secondaryTextColor,
  },
  '.MuiDataGrid-footerContainer': {
    flexDirection: 'row-reverse',
  },
  '.MuiDataGrid-main': {
    maxHeight: 'calc(100vh - 340px)', // This is a hacky fix needed from upgrading V5 -> v6 to prevent grid loading full page
  },
  '.MuiDataGrid-virtualScroller': {
    // Needed from v5 -> v6 updagrade for empty tables to give space to "no rows"
    minHeight: '56px',
  },
}));

export default function TableView({
  columns,
  rows,
  columnVisibilityModel = defaultTablePreferences.visibility,
  onColumnVisibilityModelChange,
  toolbar = null,
  disableMultipleSelection = true,
  onRowClick,
  onColumnOrderChange,
  onColumnFilterChange,
  columnOrderModel = [],
  columnFilterModel = defaultTablePreferences.filter,
}) {
  const { themeColors } = useTheme();
  const columnOrderSorter = (a, b) => {
    if (
      columnOrderModel.indexOf(a.field) === -1 &&
      columnOrderModel.indexOf(b.field) === -1
    ) {
      return 0;
    }
    if (columnOrderModel.indexOf(a.field) === -1) {
      return 1;
    }

    if (columnOrderModel.indexOf(b.field) === -1) {
      return -1;
    }

    return (
      columnOrderModel.indexOf(a.field) - columnOrderModel.indexOf(b.field)
    );
  };

  return (
    <StyledDatagrid
      className="intercom-sphere-hcp-view-table"
      columns={[...columns].sort(columnOrderSorter)}
      columnVisibilityModel={columnVisibilityModel}
      disableMultipleSelection={disableMultipleSelection}
      disableColumnReorder
      filterModel={columnFilterModel}
      getCellClassName={(params) => {
        if (params.field === 'scoreChange') {
          return 'scoreChangeCell';
        }
        if (params.field === 'score') {
          return 'scoreCell';
        }
        return '';
      }}
      getRowId={(row) => row.user_id}
      hideFooterPagination
      hideFooterSelectedRowCount
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      onFilterModelChange={onColumnFilterChange}
      onRowClick={onRowClick}
      onColumnOrderChange={onColumnOrderChange}
      rowHeight={70}
      rows={rows}
      slots={toolbar && { toolbar }}
      sx={{
        boxShadow: 2,
        border: 1,
        borderColor: themeColors.contentCardBorderColor,
        backgroundColor: themeColors.contentCardBackgroundColor,
        '& .MuiDataGrid-cell:hover': {
          color: 'primary.main',
        },
        display: 'grid', // these two lines are hacky fix for v5 -> v6 upgfrade to ensure width doesnt permanently grow
        gridTemplateRows: 'auto 1f auto',
      }}
    />
  );
}
