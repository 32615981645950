import { useQuery } from 'react-query';
import { getCustomerProjectTemplates, getMarkets } from './newProjectRequests';
import { getCustomerProductTrees, getProducts } from './configViewRequests';
import { getUserPermissions } from './userManagementRequests';

export const queryKeys = {
  markets: 'markets',
  products: 'products',
  productTrees: 'productTrees',
  projectTemplates: 'projectTemplates',
  userPermissions: 'userPermissions',
};

export const useMarketsQuery = (options) =>
  useQuery(
    queryKeys.markets,
    async () => {
      const response = await getMarkets();
      return JSON.parse(response.data);
    },
    options
  );

export const useProductsQuery = (options) =>
  useQuery(
    queryKeys.products,
    async () => {
      const response = await getProducts();
      return response.data;
    },
    options
  );

export const useProductTreesQuery = (options) =>
  useQuery(
    queryKeys.productTrees,
    async () => {
      const response = await getCustomerProductTrees();
      return response.data;
    },
    options
  );

export const useProductTemplatesQuery = (options) =>
  useQuery(
    queryKeys.projectTemplates,
    async () => {
      const response = await getCustomerProjectTemplates();
      return response.data;
    },
    options
  );

export const usePermissionsQuery = (options) =>
  useQuery(
    queryKeys.userPermissions,
    async () => {
      const response = await getUserPermissions();
      return response.data;
    },
    options
  );
