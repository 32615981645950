import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { FieldContext } from '../fieldContext';
import {
  DescriptionBody,
  ListSelectionDescription,
  ListSelectionItemButton,
  ListSelectionOptions,
  ListSelectionPopover,
  ListSelectionPopoverButton,
  ListSelectionPopoverContent,
} from './listSelectionComponents';
import { trackObjectiveSelected } from '../../../../../trackers/mixpanel';

const OVERALL_POWERSCORE_INTERCOM_ID = 8614512;

export const ObjectiveSelectionWrapper = styled(Box)(
  ({ theme: { themeColors } }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '40px',
    backgroundColor: themeColors.buttonBackgroundColor,
    border: '1px solid',
    borderColor: themeColors.buttonBorderColor,
    borderRadius: 4,
  })
);

const DescriptionText = styled(DescriptionBody)(() => ({
  fontSize: 14,
}));

const NestedListSelectionItemButton = styled(ListSelectionItemButton)(() => ({
  paddingLeft: 32,
}));

const OVERALL_LIST = { id: null, name: 'Overall PowerScore' };

export const ObjectiveSelection = () => {
  const { maptualListObjectives, setObjective } = useContext(FieldContext);

  const [activeObjectiveIndex, setActiveObjectiveIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectionOptions, setSelectionOptions] = useState([OVERALL_LIST]);

  useEffect(() => {
    setActiveObjectiveIndex(0);
    trackObjectiveSelected(
      'Overall PowerScore',
      true,
      'PowerList Objective Selector Dropdown'
    );
    setSelectionOptions([OVERALL_LIST, ...(maptualListObjectives ?? [])]);
  }, [maptualListObjectives]);

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleObjectiveSelection = (objectiveIndex) => {
    setObjective(selectionOptions[objectiveIndex]);
    setActiveObjectiveIndex(objectiveIndex);

    const objectiveName =
      objectiveIndex === 0
        ? 'Overall PowerScore'
        : `Objective ${objectiveIndex}`;
    trackObjectiveSelected(
      objectiveName,
      false,
      'PowerList Objective Selector Dropdown'
    );
    handleDropdownClose();
  };

  const getPopoverTopPosition = () => {
    if (!anchorEl) return 0;
    return anchorEl.offsetTop + anchorEl.clientHeight;
  };

  const getPopoverLeftPosition = () => {
    if (!anchorEl) return 0;
    const parentOffset = anchorEl.offsetParent?.offsetLeft ?? 0;
    return anchorEl.offsetLeft + parentOffset;
  };

  const id = open ? 'transitions-popper' : undefined;

  const handleLearnMoreClick = () => {
    window.Intercom('showArticle', OVERALL_POWERSCORE_INTERCOM_ID);
  };

  if (!maptualListObjectives?.length || maptualListObjectives.length < 2)
    return null;

  return (
    <ObjectiveSelectionWrapper
      className="intercom-field-objective-selector"
      data-testid="objective-selection"
    >
      <ListSelectionPopoverButton
        data-testid="objective-selection-popover-button"
        label={selectionOptions[activeObjectiveIndex].name}
        onClickAction={handleDropdownClick}
      />
      <ListSelectionPopover
        anchorTop={getPopoverTopPosition()}
        id={id}
        open={open}
        onClose={handleDropdownClose}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: getPopoverTopPosition(),
          left: getPopoverLeftPosition(),
        }}
      >
        <ListSelectionPopoverContent data-testid="objective-selection-popover">
          <ListSelectionDescription
            showLearnMoreButton
            onLearnMoreClick={handleLearnMoreClick}
          >
            <DescriptionText>
              The overall PowerScore is a comprehensive score that considers all
              product-level PowerScores. It does not consider product priority.
            </DescriptionText>
          </ListSelectionDescription>
          <ListSelectionOptions selection={activeObjectiveIndex}>
            <ListSelectionItemButton
              value={0}
              key="objective-selection-overall-powerscore"
              onClick={() => handleObjectiveSelection(0)}
            >
              Overall PowerScore
            </ListSelectionItemButton>
            {maptualListObjectives.map((option, i) => (
              <NestedListSelectionItemButton
                value={i + 1}
                key={`objective-selection-${option.id}`}
                onClick={() => handleObjectiveSelection(i + 1)}
              >
                {option.name}
              </NestedListSelectionItemButton>
            ))}
          </ListSelectionOptions>
        </ListSelectionPopoverContent>
      </ListSelectionPopover>
    </ObjectiveSelectionWrapper>
  );
};
