import React from 'react';
import styled from '@emotion/styled';
import { Typography, Skeleton } from '@mui/material';
import toKebabCase from '../../../../utils/toKebabCase';

const InsightContainer = styled.div`
  background-color: ${(props) =>
    props.theme.themeColors.contentCardBackgroundColor};
  border: 1px solid;
  border-color: ${(props) => props.theme.themeColors.contentCardBorderColor};
  border-radius: 7px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
`;

const MinimalWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const MinimalInsightContent = styled.div`
  margin-left: auto;
`;

const InsightIconContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background-color: ${(props) =>
    props.theme.themeColors.insightsIconBackground};
  color: ${(props) => props.theme.themeColors.insightsIcon};
  margin-bottom: 8px;
`;

const InsightTitle = styled(Typography)`
  text-align: left;
  color: ${(props) => props.theme.themeColors.secondaryTextColor};
  font-weight: 500;
`;
const MinimalInsightTitle = styled(InsightTitle)`
  width: fit-content;
`;

const InsightSubTitle = styled(Typography)`
  text-align: left;
  margin-top: 6px;
  color: ${(props) => props.theme.themeColors.primaryMaptualListFilteringColor};
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
`;
const LoadingItem = styled(Skeleton)`
  transform: scale(1);
  margin: 0;
  margin-bottom: 8px;
  background-color: #1c2027;
  background-color: ${(props) => props.theme.themeColors.mainBackground};
`;

const LoadingTitle = styled(LoadingItem)`
  height: 16px;
`;
const LoadingIcon = styled(LoadingItem)`
  height: 40px;
  width: 40px;
  border-radius: 40px;
`;
const LoadingSubTitle = styled(LoadingItem)`
  height: 60px;
`;
const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
const MessagingIcon = styled(InsightIconContainer)`
  height: 24px;
  width: 24px;
  border-radius: 20px;
`;

export const InsightsCard = ({
  title,
  subtitle,
  icon,
  children,
  isLoading,
  minimal = false,
  messaging = false,
}) => {
  let InsightIcon = null;
  if (icon) {
    InsightIcon = styled(icon)`
      height: ${!messaging ? '24px' : '16px'};
      width: ${!messaging ? '24px' : '16px'};
      margin-top: ${!messaging ? '8px' : '4px'};
    `;
  }

  if (isLoading) {
    return (
      <InsightContainer>
        <LoadingIcon animation="wave" />
        <LoadingTitle animation="wave" />
        <LoadingSubTitle animation="wave" />
      </InsightContainer>
    );
  }

  if (messaging) {
    return (
      <InsightContainer>
        <HeaderRow>
          {icon && (
            <MessagingIcon>
              <InsightIcon />
            </MessagingIcon>
          )}
          <InsightTitle variant="title3">{title}</InsightTitle>
        </HeaderRow>
        <InsightSubTitle>{subtitle}</InsightSubTitle>
        {children}
      </InsightContainer>
    );
  }

  if (minimal) {
    return (
      <InsightContainer
        className={`intercom-field-${toKebabCase(title)}-insights-card`}
      >
        <MinimalWrapper>
          <MinimalInsightTitle variant="title3">{title}</MinimalInsightTitle>
          <MinimalInsightContent>{children}</MinimalInsightContent>
        </MinimalWrapper>
      </InsightContainer>
    );
  }

  return (
    <InsightContainer>
      {icon && (
        <InsightIconContainer>
          <InsightIcon />
        </InsightIconContainer>
      )}
      <InsightTitle variant="title3">{title}</InsightTitle>
      <InsightSubTitle>{subtitle}</InsightSubTitle>
      {children}
    </InsightContainer>
  );
};
