export const useFieldViewDeepLinkPreferences = ({
  project,
  preference,
  projects,
  redirectUrl,
}) => {
  let projectId = null;

  let projectMetadataPref = null;
  let maptualListMetadataPref = null;
  let maptualListSegmentPref = null;
  let entityMetadataPref = null;
  if (redirectUrl) {
    const pathSegments = redirectUrl.split('/');

    const projectIdIndex =
      pathSegments.indexOf('projects') !== -1 &&
      pathSegments.indexOf('projects') + 1;

    const maptualListIdIndex =
      pathSegments.indexOf('maptualListId') !== -1 &&
      pathSegments.indexOf('maptualListId') + 1;

    const entityIdIndex =
      pathSegments.indexOf('entityId') !== -1 &&
      pathSegments.indexOf('entityId') + 1;

    const maptualListId = pathSegments[maptualListIdIndex];
    const entityId = pathSegments[entityIdIndex]?.toString();
    projectId = pathSegments[projectIdIndex];

    projectMetadataPref =
      projects?.filter((p) => p.projectId === projectId)[0] || null;

    maptualListMetadataPref = projectMetadataPref
      ? {
          maptualListId,
          listName: '',
        }
      : null;
    maptualListSegmentPref = null;

    entityMetadataPref =
      entityId && projectMetadataPref
        ? {
            entityId,
            entityType: 'HCP',
          }
        : null;

    return {
      projectMetadataPref,
      maptualListMetadataPref,
      maptualListSegmentPref,
      entityMetadataPref,
    };
  }

  projectId = project?.metadata?.projectId || null;

  if (projectId && projectId !== preference?.lastProjectMetadata?.projectId) {
    projectMetadataPref = project.metadata;
    maptualListMetadataPref = null;
  } else {
    projectMetadataPref = preference?.lastProjectMetadata || null;
    maptualListMetadataPref = preference?.lastMaptualListMetadata || null;
  }

  maptualListSegmentPref = preference?.lastSegmentSelection || null;
  entityMetadataPref = preference?.lastEntityMetadata || null;

  return {
    projectMetadataPref,
    maptualListMetadataPref,
    maptualListSegmentPref,
    entityMetadataPref,
  };
};
