import React, { useContext } from 'react';
import { Box, Button, styled, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { isEqual } from 'lodash';
import { LoadingIcon } from './styledComponents';
import { DETAIL_DISPLAY } from './detailDisplay';
import { ConfigViewContext } from './configViewContext';
import { FormAutocomplete } from './components/form/FormAutocomplete';
import { FormTextField } from './components/form/FormTextField';
import { ModalSectionHeader } from '../generic/modalSectionHeader';

const StyledProductContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: 24,
  gap: 16,
  textAlign: 'left',
  overflowY: 'scroll',
});

const SaveSelectionWrapper = styled('div')({
  display: 'flex',
  gap: 8,
  marginTop: 8,
});

const StyledButton = styled(Button)(({ theme: { themeColors } }) => ({
  '&:disabled': {
    backgroundColor: themeColors.contentCardAltBackgroundColor,
  },
}));

const StyledDeleteButton = styled(Button)(({ theme: { themeColors } }) => ({
  '&:hover': {
    backgroundColor: themeColors.dangerHoverBackground,
    '& .MuiTypography-root': {
      color: themeColors.dangerHoverText,
    },
    '& .MuiSvgIcon-root': {
      color: themeColors.dangerHoverText,
    },
  },
}));

const LabelTypography = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.danger60,
  fontSize: 14,
}));

const StyledDeleteIcon = styled(DeleteOutlineIcon)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.danger60,
  })
);

const ButtonRowWrapper = styled('div')(({ isNewNode }) => ({
  display: 'flex',
  justifyContent: isNewNode ? 'flex-end' : 'space-between',
}));

export function NodeEdit({
  editedNode,
  setEditedNode,
  handlePlaceholderDelete,
  node,
  setDisplayTreeDetails,
  attachedMarketId,
  editExistingTree,
  isTreeUpdating,
  totalProductsList,
  setIsDeleteModalOpen,
}) {
  const { productLineId } = useContext(ConfigViewContext);
  const initialData = {
    productName: !node.isNewNode ? node.productName : '',
    friendlyName: !node.isNewNode ? node.friendlyName : '',
    level: node.level,
    productId: node.productId,
  };
  const { control, formState, handleSubmit, setValue, watch } = useForm({
    defaultValues: initialData,
    mode: 'all',
  });
  const formValues = watch();
  const canSave = !isEqual(initialData, formValues) && formState.isValid;

  const onSave = handleSubmit((formData) => {
    const requestBody = {
      ...formData,
      parentNodeId: node.nodeParentId,
      productLine: productLineId,
      customerMarketId: attachedMarketId,
      operation: node.isNewNode ? 'CREATE' : 'UPDATE',
    };
    editExistingTree({
      requestBody,
      cleanupFunction: () => {
        setEditedNode(null);
      },
      message: `${
        node.isNewNode ? 'Successfully created' : 'Saved changes to'
      } ${formData.friendlyName} node`,
    });
  });

  const saveVerbiage = (isNewNode) => (isNewNode ? 'Create and Save' : 'Save');

  return (
    <StyledProductContainer>
      <ModalSectionHeader
        title="Node Information"
        subtitle="Add defining information for this node"
      />
      <FormAutocomplete
        name="productName"
        control={control}
        rules={{ required: true }}
        autoCompleteProps={{
          freeSolo: true,
          fullWidth: true,
          options: totalProductsList,
        }}
        onChange={(event, newValue, { field, formState: currentFormState }) => {
          field.onChange(newValue);
          setValue('friendlyName', newValue ?? '', {
            shouldValidate: currentFormState.touchedFields.friendlyName,
          });
        }}
        textFieldProps={{
          label: 'Product Name',
          placeholder: 'Add Product Name',
          required: true,
        }}
      />

      <FormTextField
        name="friendlyName"
        control={control}
        rules={{ required: true }}
        label="Friendly name"
        placeholder="Add Friendly Name"
        fullWidth
        required
      />
      <FormTextField
        name="level"
        control={control}
        rules={{ required: true }}
        label="Level"
        placeholder="Add level"
        fullWidth
        required
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        disabled
        placeholder={node.isNewNode ? 'Save Node to Generate' : node.productId}
        label="Product ID"
      />
      <ButtonRowWrapper isNewNode={!!node.isNewNode}>
        {!node.isNewNode && (
          <StyledDeleteButton
            variant="text"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            <LabelTypography>Delete Node</LabelTypography> <StyledDeleteIcon />
          </StyledDeleteButton>
        )}
        <SaveSelectionWrapper>
          <Button
            onClick={() => {
              handlePlaceholderDelete(editedNode);
              setDisplayTreeDetails(DETAIL_DISPLAY.HIDE);
              setEditedNode(null);
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <StyledButton
            variant="contained"
            disabled={!canSave}
            onClick={onSave}
          >
            {isTreeUpdating ? (
              <>
                Saving
                <LoadingIcon size="11px" />
              </>
            ) : (
              saveVerbiage(node.isNewNode)
            )}
          </StyledButton>
        </SaveSelectionWrapper>
      </ButtonRowWrapper>
    </StyledProductContainer>
  );
}
