import React, { useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { PersonOutlineOutlined, Star } from '@mui/icons-material';
import { ButtonGroup, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/styles';
import styled from '@emotion/styled';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popover from '@mui/material/Popover';
import Badge from '@mui/material/Badge';

import { useParams } from 'react-router-dom';
import { PowerScore } from './powerScore';
import EntityJourney from './entityJourney';
import { trackJourneySelection } from '../../trackers/appEventTracker';
import { useToggleHcpInList } from '../../hooks/useToggleHcpInList';
import ListMembershipSelection from './listMembershipSelection';
import { getCurrentUserCreatedLists } from '../../request/requests';
import { EntityTitleBarContext } from './entityTitleBarContext';
import { AppContext } from '../../containers/application/appContext';

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    width: '100%',
    height: 100,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderColor: themeColors.contentCardBorderColor,
    maxWidth: 750,
    alignItems: 'flex-end',
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '16px',
  },
  colContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 11.5,
    gap: 5,
  },
  horiContainer: {
    flexGrow: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    minHeight: 33,
  },
}));

const InverseStar = styled(Star)(({ theme: { themeColors } }) => ({
  fill: themeColors.maptualListInverseIconColor,
  fontSize: 16,
}));
const SecondaryButton = styled(Button)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.buttonSecondaryBackgroundColour,
  padding: 0,
  width: '47px',
  height: '36px',
}));
const SecondaryDropdownButton = styled(SecondaryButton)(
  ({ theme: { themeColors } }) => ({
    backgroundColor: themeColors.buttonSecondaryBackgroundColour,
    padding: 0,
    width: '42px',
    height: '36px',
  })
);
const SmallIconButton = styled(Button)(() => ({
  height: '36px',
  '.MuiSvgIcon-root': {
    fontSize: 16,
  },
}));
const ButtonContainer = styled('div')(() => ({ display: 'flex', gap: '10px' }));
const StyledPopover = styled(Popover)(() => ({
  marginTop: 9,
}));
const StyledBadge = styled(Badge)(({ theme: { themeColors } }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: themeColors.maptualListFilteringBadgeColor,
    color: themeColors.badgePrimaryColor,
  },
}));
const StyledButtonGroup = styled(ButtonGroup)(() => ({
  width: 121,
  justifyContent: 'flex-end',
}));
const InverseDropdownIcon = styled(ArrowDropDownIcon)(
  ({ theme: { themeColors } }) => ({
    fill: themeColors.maptualListInverseIconColor,
  })
);
const DropdownButton = styled(Button)(() => ({
  width: 42,
}));
const TitleWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export function EntityTitleBarContainer({
  metadata,
  entity,
  entityUData,
  maptualScoreNormalized,
  displayUserCreatedListsButton,
  objectiveScores,
}) {
  const { profile, projectList } = useContext(AppContext);
  const { projectId } = useParams();

  const project = projectList.find((proj) => proj.projectId === projectId);
  const projectProductLineId = project?.productLineId;

  const queryCacheKey = `user-created-lists-${projectProductLineId}`;
  const hcpId = metadata?.entityId;

  const fetchUserCreatedLists = async () => {
    const response = await getCurrentUserCreatedLists(
      profile?.userId,
      projectProductLineId
    );

    return response.data;
  };

  const {
    isLoading: isCreatedListsLoading,
    isError: isCreatedListsError,
    data: userCreatedLists,
    error: createdListsError,
  } = useQuery(queryCacheKey, fetchUserCreatedLists);

  const [userLists, setUserLists] = useState({
    starred: false,
    noSee: false,
    longTermLeave: false,
  });

  const [isStarButtonBusy, setIsStarButtonBusy] = useState(
    isCreatedListsLoading
  );

  useEffect(() => {
    if (!isCreatedListsLoading && !isCreatedListsError) {
      const starredList = userCreatedLists?.starred || [];
      const noSeeList = userCreatedLists?.noSee || [];
      const longTermLeaveList = userCreatedLists?.longTermLeave || [];

      setUserLists({
        [projectProductLineId]: { ...userCreatedLists },
        starred: starredList.includes(hcpId),
        noSee: noSeeList.includes(hcpId),
        longTermLeave: longTermLeaveList.includes(hcpId),
      });

      setIsStarButtonBusy(false);
    }

    if (createdListsError) {
      // eslint-disable-next-line no-console
      console.error('[ERROR] Fetching User Created Lists', createdListsError);
    }
  }, [hcpId, projectProductLineId, userCreatedLists, isCreatedListsLoading]);

  return (
    <EntityTitleBarContext.Provider value={userLists}>
      <EntityTitleBar
        metadata={metadata}
        entity={entity}
        maptualScoreNormalized={maptualScoreNormalized}
        entityUData={entityUData}
        isStarButtonBusy={isStarButtonBusy}
        setIsStarButtonBusy={setIsStarButtonBusy}
        displayUserCreatedListsButton={displayUserCreatedListsButton}
        objectiveScores={objectiveScores}
      />
    </EntityTitleBarContext.Provider>
  );
}

const InverseStarButton = ({ toggleHcpStar }) => (
  <SecondaryButton
    aria-label="InverseStarButton"
    variant="outlined"
    onClick={toggleHcpStar}
  >
    <InverseStar />
  </SecondaryButton>
);

const OutlinedStarButton = ({ toggleHcpStar }) => (
  <SmallIconButton
    variant="outlined"
    onClick={toggleHcpStar}
    startIcon={<Star />}
  >
    Star
  </SmallIconButton>
);

const StarButton = ({ hcpId, isStarButtonBusy, setIsStarButtonBusy }) => {
  if (isStarButtonBusy) {
    return (
      <SmallIconButton variant="outlined">
        <CircularProgress
          data-testid="circular-progress-list-button"
          color="inherit"
          size="16px"
        />
      </SmallIconButton>
    );
  }

  const updateUserCreatedList = useToggleHcpInList(
    hcpId,
    'starred',
    'Primary Button'
  );

  const toggleHcpStar = () => {
    setIsStarButtonBusy(true);

    try {
      updateUserCreatedList();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[ERROR] toggleHcpStar failed', {
        error,
        hcpId,
      });
    }
  };

  const { starred } = useContext(EntityTitleBarContext);

  if (starred) {
    return <InverseStarButton toggleHcpStar={toggleHcpStar} />;
  }

  return <OutlinedStarButton toggleHcpStar={toggleHcpStar} />;
};

export default function EntityTitleBar({
  metadata,
  entity,
  entityUData,
  maptualScoreNormalized,
  isStarButtonBusy,
  setIsStarButtonBusy,
  displayUserCreatedListsButton,
  objectiveScores,
}) {
  const userLists = useContext(EntityTitleBarContext);

  const classes = useStyles();
  const location = entityUData?.location || {};
  const {
    address = null,
    cityTown = '',
    stateProvShort = '',
    locationName = null,
  } = location;

  const [showDrawer, setShowDrawer] = useState(false);
  const { themeColors } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonGroupRef = useRef();

  const openListSelection = () => {
    setAnchorEl(buttonGroupRef.current);
  };

  const closeListSelection = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'simple-popover' : undefined;

  const toggleDrawer = (value) => {
    if (value !== showDrawer) setShowDrawer(value);
  };

  const activeListCount = Object.values(userLists).filter(
    (list) => list === true
  ).length;

  const anchorDrawerToContainerProps = {
    PaperProps: { style: { position: 'absolute', textAlign: 'left' } },
    BackdropProps: { style: { position: 'absolute' } },
    ModalProps: {
      container: document.getElementById('main-content'),
      style: {
        position: 'absolute',
      },
      slotProps: {
        backdrop: {
          style: {
            position: 'fixed',
          },
        },
      },
      keepMounted: true,
    },
  };

  return metadata ? (
    <div className={classes.root}>
      <TitleWrapper>
        <div
          className={`${classes.colContainer} intercom-field-entity-title-bar-power-score`}
        >
          {maptualScoreNormalized === undefined && '--'}

          {maptualScoreNormalized >= 0 ? (
            <PowerScore
              score={maptualScoreNormalized}
              objectiveScoreList={objectiveScores}
            />
          ) : null}
        </div>
        <div
          className={`${classes.colContainer} intercom-field-entity-title-bar-entity-info`}
        >
          <Typography
            noWrap
            variant="h3"
            className="test-hcp-info-name"
            align="left"
          >
            {`${metadata.entityName}`}
          </Typography>
          <Typography
            noWrap
            variant="body2"
            className="test-hcp-info-address"
            style={{
              maxWidth: 300,
              color: themeColors.primaryMaptualListFilteringColor,
            }}
            align="left"
          >
            {address ||
              (locationName
                ? `${locationName}, ${cityTown}, ${stateProvShort}`
                : `${cityTown}, ${stateProvShort}`)}
          </Typography>
        </div>
      </TitleWrapper>
      <div className={classes.horiContainer}>
        <ButtonContainer>
          {displayUserCreatedListsButton ? (
            <>
              <StyledButtonGroup
                variant="outlined"
                ref={buttonGroupRef}
                className="intercom-field-user-created-lists-button"
              >
                <StarButton
                  hcpId={metadata?.entityId}
                  isStarButtonBusy={isStarButtonBusy}
                  setIsStarButtonBusy={setIsStarButtonBusy}
                />

                {activeListCount > 0 ? (
                  <StyledBadge
                    badgeContent={activeListCount}
                    data-testid="ListMembershipBadge"
                  >
                    <SecondaryDropdownButton onClick={openListSelection}>
                      <InverseDropdownIcon data-testid="InverseArrowDropDownButton" />
                    </SecondaryDropdownButton>
                  </StyledBadge>
                ) : (
                  <DropdownButton onClick={openListSelection}>
                    <ArrowDropDownIcon data-testid="ArrowDropDownButton" />
                  </DropdownButton>
                )}
              </StyledButtonGroup>
              <StyledPopover
                id={popoverId}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={closeListSelection}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: { width: '262px' },
                }}
              >
                <ListMembershipSelection hcpId={metadata?.entityId} />
              </StyledPopover>
            </>
          ) : null}

          <SmallIconButton
            className="intercom-field-connect-button"
            data-testid="test-hcp-profile"
            aria-label="expand"
            style={{
              marginRight: 0,
            }}
            variant="outlined"
            startIcon={<PersonOutlineOutlined />}
            onClick={() => {
              toggleDrawer(true);
              trackJourneySelection(metadata.entityName);
            }}
          >
            Connect
          </SmallIconButton>
        </ButtonContainer>
        <Drawer
          anchor="right"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          {...anchorDrawerToContainerProps}
          sx={{ zIndex: 1201 }}
        >
          {showDrawer && <EntityJourney entity={entity} />}
        </Drawer>
      </div>
    </div>
  ) : null;
}
