import React, { useState } from 'react';

import { useQuery, useQueryClient } from 'react-query';
import { styled, Box, Button, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CsvDeliveryStepper } from './CsvDeliveryStepper';
import { CsvDeliveryProjectSelector } from './CsvDeliveryProjectSelector';
import { CsvDeliveryResetModal } from './CsvDeliveryResetModal';
import {
  getDeliveryStatuses,
  resetCsvDeliveryDashboard,
} from '../../../../request/csvDeliveryDashboard';

const FlexColumnBase = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const DeliveryContainerStyled = styled(FlexColumnBase)({
  padding: '26px 36px 60px 42px',
  gap: 40,
});

const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledButton = styled(Button)({
  height: 32,
});

const StepperContainer = styled(FlexColumnBase)({
  gap: 36,
});

const StyledDivider = styled(Divider)({
  margin: '36px 0 24px',
});

export const CsvDeliveryContainer = () => {
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const { data: projects = [] } = useQuery({
    queryKey: ['deliveryStatuses'],
    queryFn: async () => {
      const response = await getDeliveryStatuses();
      return response.data.statuses;
    },
  });

  const resetProjects = async () => {
    await resetCsvDeliveryDashboard();
    await queryClient.refetchQueries('deliveryStatuses');
    setIsResetModalOpen(false);
  };

  const isLastProject = (index) => index === projects.length - 1;

  return (
    <DeliveryContainerStyled>
      <Header>
        <Typography variant="h3">CSV Dashboard</Typography>
        <StyledButton
          variant="contained"
          onClick={() => setIsResetModalOpen(true)}
        >
          Reset dashboard
        </StyledButton>
      </Header>

      <Box>
        {projects.map((project, i) => (
          <React.Fragment key={project.userGroup + project.businessUnit}>
            {project.projectId ? (
              <StepperContainer>
                <CsvDeliveryStepper project={project} />
              </StepperContainer>
            ) : (
              <CsvDeliveryProjectSelector
                userGroup={project.userGroup}
                businessUnit={project.businessUnit}
              />
            )}
            {!isLastProject(i) && <StyledDivider />}
          </React.Fragment>
        ))}
      </Box>
      <CsvDeliveryResetModal
        isModalOpen={isResetModalOpen}
        onConfirm={resetProjects}
        onClose={() => setIsResetModalOpen(false)}
      />
    </DeliveryContainerStyled>
  );
};
