export function toCapitalize(str, delimiters = []) {
  let result = '';
  if (str && str.length) {
    [...str].forEach((letter, i) => {
      if (i === 0 || delimiters.includes(str[i - 1])) {
        result += letter.toUpperCase();
      } else {
        result += letter.toLowerCase();
      }
    });
  }

  return result;
}
