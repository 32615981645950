import { useContext, useEffect } from 'react';
import { baseAlbInstance, currentEnvironment } from '../../request/config';
import { AppContext } from './appContext';

export const formatConfiguration = (environment) => {
  if (environment === 'prod') {
    return {
      name: environment.toUpperCase(),
      pdbName: 'physician_prod',
      ddbTable: 'customers',
      ddbUserTable: 'pharma-customer-users',
      ddbAnalysisTable: 'pharma-analysis',
      ddbTranslationTable: 'pharma-translations',
      ddbConstantsTable: 'pharma-constants',
      ddbTaskStatusTable: 'pharma-task-status',
      s3Bucket: 'odaia-secure-datapackage-bucket',
      userpoolId: 'us-east-1_LnusTHQSe',
      webappURL: 'odaia-webserver-lb-1235623845.us-east-1.elb.amazonaws.com',
    };
  }
  return {
    name: environment.toUpperCase(),
    pdbName: 'physician_dev',
    ddbTable: `customers-${environment}`,
    ddbUserTable: `pharma-customer-users-${environment}`,
    ddbAnalysisTable: `pharma-analysis-${environment}`,
    ddbTranslationTable: `pharma-translations-${environment}`,
    ddbConstantsTable: `pharma-constants-${environment}`,
    ddbTaskStatusTable: `pharma-task-status-${environment}`,
    s3Bucket: `odaia-secure-datapackage-bucket-${environment}`,
    userpoolId: 'us-east-1_LnusTHQSe',
    webappURL: 'odaia-webserver-lb-1235623845.us-east-1.elb.amazonaws.com',
  };
};
export const formatEvent = ({
  method,
  url,
  host,
  data,
  params = {},
  profile = {},
}) => {
  const environment = host === 'localhost' ? 'demo' : host;
  const configuration = formatConfiguration(environment);
  const urlNoLeadingBackslash = url[0] === '/' ? url.slice(1) : url;
  const httpQuery = urlNoLeadingBackslash.split('?');
  const pathArray = httpQuery[0].split('/').slice(1);
  const searchParams =
    httpQuery.length > 1 ? new URLSearchParams(httpQuery[1]) : [];

  let body = '';
  if (typeof data === 'string' || data instanceof String) {
    body = data;
  } else if (data) {
    body = JSON.stringify(data);
  }

  const event = {
    httpMethod: method.toUpperCase(),
    path: `/alb2apigw-proxy-lm/${urlNoLeadingBackslash}`,
    queryStringParameters: {
      path: pathArray,
      user_name: profile?.customerUserName || '',
      user_group: profile?.userGroup || '',
      ...Object.fromEntries(searchParams),
      ...params,
      target: environment.toUpperCase(),
      configuration,
    },
    body,
    isBase64Encoded: false,
  };
  return event;
};

export const recordAxiosEvent = (request, host, profile, currentEvents) => {
  if (request.url.startsWith('http')) {
    return currentEvents;
  }

  const event = formatEvent({
    ...request,
    host,
    profile,
  });
  const eventLog = {
    event,
    status: null,
    key: request.requestEventLoggerId,
    date: request.requestEventTimestamp,
  };
  return [eventLog, ...currentEvents].slice(0, 20);
};

export const completeAxiosEvent = (currentEvents, response) => {
  if (!response?.config?.requestEventLoggerId) {
    return currentEvents;
  }
  const newEvents = [...currentEvents];

  for (let i = 0; i < newEvents.length; i += 1) {
    if (newEvents[i].key === response.config.requestEventLoggerId) {
      newEvents[i] = { ...newEvents[i], status: response.status };
      break;
    }
  }
  return newEvents;
};

export const AxiosInterceptor = ({ children }) => {
  const { profile, setEvents, settings } = useContext(AppContext);

  const handleResponseError = async (error) => {
    setEvents((prevEvents) => completeAxiosEvent(prevEvents, error.response));
    return Promise.reject(error);
  };

  const handleResponse = async (response) => {
    setEvents((prevEvents) => completeAxiosEvent(prevEvents, response));
    return response;
  };
  const handleRequestError = async (error) => {
    Promise.reject(error);
  };

  const handleRequest = async (request) => {
    const requestEventLoggerId = `${request.url}-${Math.floor(
      Math.random() * 1000000
    )}`;
    const requestWithId = {
      ...request,
      requestEventLoggerId,
      requestEventTimestamp: new Date(),
    };

    setEvents((prevEvents) =>
      recordAxiosEvent(requestWithId, currentEnvironment, profile, prevEvents)
    );
    return requestWithId;
  };

  const instances = [baseAlbInstance];
  const clearInterceptors = () => {
    instances.forEach((instance) => {
      // eslint-disable-next-line no-param-reassign
      instance.interceptors.request.handlers = [];
      // eslint-disable-next-line no-param-reassign
      instance.interceptors.response.handlers = [];
    });
  };

  const initializeInterceptors = () => {
    instances.forEach((instance) => {
      instance.interceptors.request.use(handleRequest, handleRequestError);
      instance.interceptors.response.use(handleResponse, handleResponseError);
    });
  };

  useEffect(() => {
    if (!settings.enableEventLogger) {
      clearInterceptors();
      setEvents([]);
      return null;
    }
    clearInterceptors();
    initializeInterceptors();

    return () => {
      clearInterceptors();
    };
  }, [settings]);

  return children;
};
