/* eslint-disable no-restricted-syntax */
import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useHcpCrmData } from '../../../../pulse/views/rep/table/row/hooks/useHcpCrmData';
import { useEntitySegments } from './hooks/useEntitySegments';
import { CallPlanContext } from '../../context/CallPlanContext';
import { formatLastContactedDate } from '../../../../pulse/shared/utils';

import ProgressBar from '../../../../pulse/views/salesReps/repTable/table/row/progressBar/ProgressBar';
import { NumberContainer } from '../../../../pulse/views/rep/table/row/numberContainer';
import { Row } from '../../../../../../shared/entitiesTable/Row';
import { PowerScore } from '../../../../../../../components/fieldView/powerScore';
import { BadgeContainer } from './BadgeContainer';

const StyledCardColumnSection = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  gap: 48,
  alignItems: 'center',
  padding: 16,
  borderLeft: `1px solid ${themeColors.buttonBorderColor}`,
  borderRight: `1px solid ${themeColors.buttonBorderColor}`,
  minWidth: 175,
}));

const ScoreContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledInitialColumn = styled('div')(() => ({
  width: 150,
  minWidth: 150,
  marginLeft: 16,
  display: 'flex',
}));

const StyledTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
  textAlign: 'left',
  textTransform: 'capitalize',
  marginLeft: 8,
}));

export const CallPlanRowProvider = ({
  objectiveId,
  cadence,
  row,
  isLoading,
  rank,
  onRowClick,
}) => {
  const { projectId, maptualListId: territoryId } = useParams();
  const [nonCuratedCalls, setNonCuratedCalls] = useState();
  const [curatedCalls, setCuratedCalls] = useState();
  const [curatedCount, setCuratedCount] = useState();
  const [segments, setSegments] = useState([]);

  const {
    productLineId,
    projectTargetProducts,
    isProductsLoading,
    latestCurationTimestamp,
  } = useContext(CallPlanContext);

  const objectiveProducts = projectTargetProducts.reduce(
    (allProducts, product) => allProducts.concat(product.products),
    []
  );

  const isSingleProduct = projectTargetProducts?.length === 1;

  const { data: crmData, isLoading: isCrmDataLoading } = useHcpCrmData({
    productLineId,
    projectId,
    regionId: territoryId,
    entityId: row.entity.id,
    products: objectiveProducts,
    latestCurationTimestamp,
  });

  const { data: entitySegments, isLoading: isSegmentsLoading } =
    useEntitySegments({
      productLineId,
      objectiveId: row.objectiveId,
      regionId: territoryId,
      entityId: row.entity.id,
    });

  useEffect(() => {
    if (entitySegments) {
      setSegments(entitySegments);
    }
  }, [entitySegments]);

  useEffect(() => {
    if (
      crmData &&
      crmData?.nonCuratedCalls !== undefined &&
      crmData?.curatedCalls !== undefined &&
      crmData?.timesCurated !== undefined
    ) {
      setNonCuratedCalls(crmData.nonCuratedCalls);
      setCuratedCalls(crmData.curatedCalls);
      setCuratedCount(crmData.timesCurated);
    }
  }, [crmData]);

  return (
    <Row
      row={row}
      objectiveId={objectiveId}
      cadence={cadence}
      key={row.id}
      isShimmer={isLoading}
      onRowClick={() => onRowClick(row.entity.id)}
      showPowerscore={isSingleProduct}
      rowTitle={row.entity.name}
      rowSubtitle={`${row.entity?.specialty} - ${formatLastContactedDate(
        row.entity?.lastContactedDate
      )}`}
      showRank
      rank={rank}
      productLineId={productLineId}
      showCustomListIcon
    >
      <StyledInitialColumn>
        <ScoreContainer>
          <PowerScore score={Math.round(row.score)} />
          <StyledTitle variant="body2">
            {row.product?.toLowerCase()}
          </StyledTitle>
        </ScoreContainer>
      </StyledInitialColumn>
      <StyledCardColumnSection>
        <ProgressBar
          isLoading={isCrmDataLoading || isProductsLoading}
          numerator={curatedCalls}
          denominator={curatedCount}
        />
        <NumberContainer
          isLoading={isCrmDataLoading || isProductsLoading}
          value={nonCuratedCalls + curatedCalls || 0}
        />
        <BadgeContainer
          content={segments.filter(
            (segment) => segment.type.toUpperCase() !== 'CUSTOMER'
          )}
          isLoading={isSegmentsLoading}
        />
        <BadgeContainer
          content={segments.filter(
            (segment) => segment.type.toUpperCase() === 'CUSTOMER'
          )}
          isLoading={isSegmentsLoading}
        />
      </StyledCardColumnSection>
    </Row>
  );
};
