import React, { useContext } from 'react';
import { Button, Typography } from '@mui/material';
import styled from '@emotion/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ModeIcon from '@mui/icons-material/Mode';
import { ConfigViewContext } from '../configViewContext';

import { DETAIL_DISPLAY } from '../detailDisplay';
import { useIsAuthorized } from '../../../hooks/useIsAuthorized';
import { RBAC_PERMISSIONS } from '../../../constants';

const Title = styled('div')(({ theme: { themeColors } }) => ({
  height: 70,
  borderBottom: `1px solid ${themeColors.borderLowContrast}`,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  padding: 25,
}));

const StyledButton = styled(Button)(({ theme: { themeColors } }) => ({
  '&:hover': {
    color: themeColors.objectiveTypeEditButtonColor,
    cursor: 'pointer',
  },
}));

const StyledEditButton = styled(Button)(({ theme: { themeColors } }) => ({
  '&:hover': {
    color: themeColors.adminCardHeader,
    cursor: 'pointer',
  },
}));

const StyledBackButton = styled(ArrowBackIcon)({
  '&:hover': {
    cursor: 'pointer',
  },
});

const Wrapper = styled('div')({
  display: 'flex',
  gap: 16,
  alignItems: 'center',
});

const TreeDetailsWrapper = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  height: 86,
  padding: 24,
  textAlign: 'left',
  borderBottom: `1px solid ${themeColors.borderLowContrast}`,
  justifyContent: 'space-between',
}));

const StyledBodyText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
}));
const SecondaryText = styled(Typography)(
  ({ theme: { themeColors }, disabled }) => ({
    color: disabled ? themeColors.borderLowContrast : themeColors.neutral60,
  })
);

const StyledEditIcon = styled(ModeIcon)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
  fontSize: '16px',
  marginLeft: '4px',
}));

export function ProductTreeTitle({
  productLine,
  displayTreeDetails,
  setDisplayTreeDetails,
}) {
  const { setProductLineId } = useContext(ConfigViewContext);
  const hasConfigAdminPermissions = useIsAuthorized([
    RBAC_PERMISSIONS.USER_GROUP_CONFIG_ADMIN,
  ]);

  return (
    <>
      <Title>
        <Wrapper>
          <StyledBackButton onClick={() => setProductLineId('')} />
          <Typography variant="h4">
            {productLine.productLine}
            {productLine.marketName ? ` For ${productLine.marketName}` : ''}
          </Typography>
        </Wrapper>
        <StyledButton
          disabled={displayTreeDetails === DETAIL_DISPLAY.DISABLE}
          onClick={() => {
            setDisplayTreeDetails((prev) =>
              prev === DETAIL_DISPLAY.HIDE
                ? DETAIL_DISPLAY.SHOW
                : DETAIL_DISPLAY.HIDE
            );
          }}
        >
          <SecondaryText
            variant="body2"
            disabled={displayTreeDetails === DETAIL_DISPLAY.DISABLE}
          >
            {displayTreeDetails === DETAIL_DISPLAY.SHOW
              ? 'Collapse Tree Details'
              : 'View Tree Details'}
          </SecondaryText>
        </StyledButton>
      </Title>
      {displayTreeDetails === DETAIL_DISPLAY.SHOW && (
        <TreeDetailsWrapper>
          <div>
            <StyledBodyText variant="body2">
              Product Line: {productLine.productLine}
            </StyledBodyText>
            <StyledBodyText variant="body2">
              Product Line ID: {productLine.productLineId}
            </StyledBodyText>
          </div>
          {hasConfigAdminPermissions && (
            <StyledEditButton onClick={() => {}}>
              <SecondaryText variant="body2">Edit</SecondaryText>
              <StyledEditIcon />
            </StyledEditButton>
          )}
        </TreeDetailsWrapper>
      )}
    </>
  );
}
