export const TypographyDefinitions = {
  fontWeight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  lineHeight: {
    regular: 1.2,
    medium: 1.3,
    large: 1.35,
    extraLarge: 1.6,
  },
};
