import { getDemoAccountStatus } from '../../request/config';

export const indicationsReplacementLookupTable = {
  SpA: 'Indication 01',
  RA: 'Indication 02',
  PsA: 'Indication 03',
  PsO: 'Indication 04',
  AS: 'Indication 05',
  Diab: 'Indication 06',
  Diabetes: 'Indication 06',
  AOM: 'Indication 07',
  Obesity: 'Indication 08',
  ONC: 'Indication 09',
  OAB: 'Indication 10',
  'GLP-1': 'Indication 11',
};

const regexIndicatorBuilder = (indication) => `(\\b)${indication}(\\b)`;

export function replaceIndications(source = '') {
  const indicationKeys = Object.keys(indicationsReplacementLookupTable);
  let result = source;

  indicationKeys.forEach((indication) => {
    result = result.replace(
      new RegExp(regexIndicatorBuilder(indication), 'i'),
      indicationsReplacementLookupTable[indication]
    );
  });

  return result;
}

export function anonymizeInsightsSummaries(summaries = []) {
  return summaries.map((summary) => ({
    ...summary,
    description: replaceIndications(summary.description),
  }));
}

export const anonymizeGetScriptsResponse = () => (next) => (action) => {
  const modifiedAction = action;

  const isDemo = getDemoAccountStatus();

  if (
    isDemo &&
    action.type === 'PROJECT_GET_SCRIPTS_BY_MAPTUALLIST_ID_FULFILLED'
  ) {
    modifiedAction.payload = {
      ...modifiedAction.payload,
      complete: modifiedAction.payload.complete.map((script) => ({
        ...script,
        title: replaceIndications(script.title),
        productMetricPairings: script.productMetricPairings?.map((metric) => ({
          ...metric,
          productName: replaceIndications(metric.productName),
          title: replaceIndications(metric.title),
        })),
        dataList: {
          ...script.dataList,
          listItems: script.dataList?.listItems.map((listItem) => ({
            ...listItem,
            primaryData: {
              ...listItem.primaryData,
              dataSource: replaceIndications(listItem.primaryData.dataSource),
            },
          })),
        },
      })),
    };
  }

  return next(modifiedAction);
};
