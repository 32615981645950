import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { ConfigViewContext } from './configViewContext';
import { getProductLines } from '../../request/newProjectRequests';
import { ConfigView } from './configView';
import { CONFIG_VIEW_MODE } from './userGroupConfigConstants';
import { ConfigViewThemeProvider } from './configViewThemeProvider';

export const ConfigViewProvider = ({ profile }) => {
  const [metricsTableSorting, setMetricsTableSorting] = useState([]);
  const [metricsTableColumnVisibility, setMetricsTableColumnVisibility] =
    useState({});

  const [productLineId, setProductLineId] = useState('');
  const [regionalTeamId, setRegionalTeamId] = useState('');
  const [configAlertStatus, setConfigAlertStatus] = useState(null);
  const [configAlertMessage, setConfigAlertMessage] = useState(null);
  const [resetConfigAlertTimer, setResetConfigAlertTimer] = useState(false);
  const [configViewMode, setConfigViewMode] = useState(CONFIG_VIEW_MODE.VIEW);
  const productLinesQuery = useQuery(
    productLineId,
    async () => {
      const response = await getProductLines(productLineId);
      return JSON.parse(response.data);
    },
    { enabled: !!productLineId }
  );

  const showConfigAlert = (status, message = null) => {
    setConfigAlertStatus(status);
    setConfigAlertMessage(message);
    setResetConfigAlertTimer(true);
  };

  const dataContextValues = useMemo(
    () => ({
      productLinesQuery,
      productLineId,
      setProductLineId,
      regionalTeamId,
      setRegionalTeamId,
      metricsTableSorting,
      setMetricsTableSorting,
      metricsTableColumnVisibility,
      setMetricsTableColumnVisibility,
      configAlertStatus,
      setConfigAlertStatus,
      configAlertMessage,
      resetConfigAlertTimer,
      setResetConfigAlertTimer,
      showConfigAlert,
      configViewMode,
      setConfigViewMode,
    }),
    [
      productLinesQuery.data,
      productLineId,
      regionalTeamId,
      metricsTableSorting,
      metricsTableColumnVisibility,
      configAlertStatus,
      configAlertMessage,
      resetConfigAlertTimer,
      configViewMode,
    ]
  );

  return (
    <ConfigViewThemeProvider>
      <ConfigViewContext.Provider value={dataContextValues}>
        <ConfigView profile={profile} />
      </ConfigViewContext.Provider>
    </ConfigViewThemeProvider>
  );
};
