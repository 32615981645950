import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function useMaptualContext() {
  const appView = useSelector((state) => state.app.appView);
  const isStandalonePage = useSelector(
    (state) => state?.projectViewer?.isStandalonePage
  );
  const entityId = useSelector(
    (state) => state?.projectViewer?.entityMetadata?.entityId
  );
  const { projectId, maptualListId } = useParams();

  return { entityId, maptualListId, projectId, appView, isStandalonePage };
}
