import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import styled from '@emotion/styled';
import { Drawer } from '@mui/material';
import { TestDetailLogger } from './testDetailDrawer';
import { CustomIconButton } from '../app/navBarCommon';

const StyledTooltip = withStyles({
  tooltip: {
    fontSize: '14px',
    backgroundColor: '#313B49',
  },
  arrow: {
    color: '#313B49',
  },
})(Tooltip);

const TestDetailDrawer = styled(Drawer)(({ theme: { themeColors } }) => ({
  backgroudColor: themeColors.contentCardBorderColor,
  textAlign: 'left',
}));

export const TestStatusIcon = ({ status, toolTipMsg }) => {
  const [settings, setSettings] = useState(false);
  let color = 'white';
  let Icon = null;

  const setOpen = (val) => {
    setSettings(val);
  };

  switch (status) {
    case 'warning':
      color = 'warning';
      Icon = ErrorOutlineOutlinedIcon;
      break;
    case 'success':
      color = 'success';
      Icon = CheckCircleOutlineOutlinedIcon;
      break;
    case 'failure':
      color = 'error';
      Icon = CancelOutlinedIcon;
      break;
    default:
      break;
  }

  if (!Icon) {
    return null;
  }

  return (
    <div>
      <CustomIconButton
        onClick={() => {
          setOpen(!settings);
        }}
      >
        <StyledTooltip title={toolTipMsg} arrow placement="top">
          <Icon sx={{ margin: '3px 10px' }} fontSize="small" color={color} />
        </StyledTooltip>
      </CustomIconButton>
      <TestDetailDrawer
        anchor="right"
        variant="persistent"
        open={settings}
        onClose={() => {
          setOpen(false);
        }}
        PaperProps={{ style: { zIndex: 1300 } }}
      >
        <TestDetailLogger />
      </TestDetailDrawer>
    </div>
  );
};
