import React, { useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import LinkController from '../../containers/links/linkController';

const useStyles = makeStyles(() => ({
  button: {
    width: '100%',
  },
  icon: {
    width: '65%',
    height: '65%',
  },
}));

export default function IconedButton({
  icon = null,
  primaryText,
  type,
  linkLocation,
  linkUrl,
  analysisId,
  userId = null,
  match,
}) {
  const classes = useStyles();

  const controllerRef = useRef(null);
  const controllerClick = () => {
    if (controllerRef && controllerRef.current) controllerRef.current.click();
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={icon}
        className={classes.button}
        onClick={controllerClick}
      >
        {primaryText}
      </Button>
      <LinkController
        ref={controllerRef}
        type={type}
        linkLocation={linkLocation}
        linkUrl={linkUrl}
        analysisId={analysisId}
        userId={userId}
        match={match}
      />
    </>
  );
}
