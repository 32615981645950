import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { darkThemeColors } from '../../styles/colorConfig';
import SemiOpaqueChip from '../chips/semiOpaqueChip';
import { numberWithCommas } from '../../modules/commonModule';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    height: 'auto',
  },
  box: {
    width: 180,
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
  },
  timelineDotStyle: {
    backgroundColor: darkThemeColors.tertiaryColor,
  },
  oppositionContainer: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  countStyle: {
    fontSize: 12,
    fontWeight: 800,
    lineHeight: 1.1,
    color: theme.palette.text.secondary,
  },
  countText: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.1,
    color: darkThemeColors.tertiaryColor,
  },
  primaryText: {
    fontSize: 14,
    fontWeight: 800,
    color: theme.palette.text.secondary,
  },
  secondaryText: {
    fontSize: 13,
    fontWeight: 500,
    color: darkThemeColors.tertiaryColor,
  },
  tertiaryText: {
    fontSize: 12,
    fontWeight: 500,
    color: darkThemeColors.tertiaryColor,
  },
  ctxColumnText: {
    fontSize: 12,
    fontWeight: 800,
    color: darkThemeColors.tertiaryColor,
  },
  ctxValueText: {
    fontSize: 12,
    fontWeight: 500,
    color: darkThemeColors.tertiaryColor,
  },
}));

export default function JourneyFunnel({ journeyFunnel }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {Array.isArray(journeyFunnel) ? (
        <Timeline>
          {journeyFunnel.map((item, i) => {
            const { primaryText, secondaryText, tertiaryText, match } = item;
            const { count, countText, semiOpaqueChip } = item;
            return (
              <TimelineItem key={i}>
                <TimelineOppositeContent>
                  <div className={classes.oppositionContainer}>
                    <Typography className={classes.countStyle} align="right">
                      {numberWithCommas(count)}
                    </Typography>
                    <Typography className={classes.countText} align="right">
                      {countText}
                    </Typography>
                    {semiOpaqueChip ? (
                      <SemiOpaqueChip
                        {...semiOpaqueChip}
                        match={match}
                        color={blue[800]}
                      />
                    ) : null}
                  </div>
                </TimelineOppositeContent>

                <TimelineSeparator>
                  <TimelineDot className={classes.timelineDotStyle} />
                  {i < journeyFunnel.length - 1 ? (
                    <TimelineConnector
                      style={{ backgroundColor: darkThemeColors.tertiaryColor }}
                    />
                  ) : null}
                </TimelineSeparator>

                <TimelineContent>
                  <div className={classes.box}>
                    {primaryText ? (
                      <Typography className={classes.primaryText} align="left">
                        {primaryText}
                      </Typography>
                    ) : null}
                    {secondaryText ? (
                      <Typography
                        className={classes.secondaryText}
                        noWrap
                        align="left"
                      >
                        {secondaryText}
                      </Typography>
                    ) : null}
                    {tertiaryText ? (
                      <Typography
                        className={classes.tertiaryText}
                        noWrap
                        align="left"
                      >
                        {tertiaryText}
                      </Typography>
                    ) : null}
                  </div>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      ) : null}
    </div>
  );
}
