import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import Fade from '@mui/material/Fade';
import { useTheme } from '@mui/styles';
import { ObjectiveContext } from './contexts/objectiveContext';
import CustomToolbar from './tableview/customToolbar/customToolbar';
import EmptyView from '../emptyview/emptyView';

const defaultColumnVisibility = {
  Segment: true,
  'Segment Size': true,
};
const defaultColumnOrderModel = (columns) => {
  const firstCols = ['Segment', 'Segment Size'];
  const columnOrder = [...firstCols];
  columns.forEach((column) => {
    if (!firstCols.includes(column)) {
      columnOrder.push(column);
    }
  });
  return columnOrder;
};
const defaultColumnVisibilityModel = (columns) => {
  const columnVisibility = {};
  Object.keys(defaultColumnVisibility).forEach((column) => {
    columnVisibility[column] = defaultColumnVisibility[column];
  });
  columns.forEach((column) => {
    if (!Object.keys(columnVisibility).includes(column)) {
      columnVisibility[column] = false;
    }
  });
  return columnVisibility;
};
const StyledDatagrid = styled(DataGridPremium)(({ theme }) => ({
  '.scoreChangeCell': {
    justifyContent: 'center',
  },
  '.scoreCell': {
    justifyContent: 'center',
  },
  '.MuiDataGrid-columnsContainer': {
    backgroundColor: 'white',
  },
  '.MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.themeColors.contentCardBorderColor}`,
  },
  '.MuiDataGrid-iconSeparator': {
    color: theme.themeColors.secondaryTextColor,
  },
  '.MuiDataGrid-footerContainer': {
    flexDirection: 'row-reverse',
  },
  '.MuiDataGrid-main': {
    maxHeight: 'calc(100vh - 340px)', // This is a hacky fix needed from upgrading V5 -> v6 to prevent grid loading full page
  },
  '.MuiDataGrid-virtualScroller': {
    // Needed from v5 -> v6 updagrade for empty tables to give space to "no rows"
    minHeight: '56px',
  },
}));

const TableViewContentContainer = styled('div')(() => ({
  maxHeight: '100%',
  width: '100%',
}));

export const SegmentsTableView = ({ data }) => {
  const { selectedObjective } = useContext(ObjectiveContext);
  const { themeColors } = useTheme();

  let columns;
  let rows;
  if (selectedObjective?.id) {
    columns = data[selectedObjective.id].columns;
    rows = data[selectedObjective.id].rows;
  } else {
    columns = Object.values(data)[0].columns;
    rows = Object.values(data)[0].rows;
  }

  const uniqueRows = rows.filter(
    (row, index) =>
      rows.findIndex((firstRow) => firstRow.Segment === row.Segment) === index
  );

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    defaultColumnVisibilityModel(columns)
  );

  const handleColumnVisibilityModelChange = (newModel) => {
    setColumnVisibilityModel(newModel);
  };

  const dataIsGridSafe =
    Array.isArray(Object.values(data)[0].columns) &&
    uniqueRows.length === rows.length;

  if (!dataIsGridSafe) {
    return (
      <Fade in timeout={600} key="loading">
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <EmptyView />
        </div>
      </Fade>
    );
  }

  return (
    dataIsGridSafe && (
      <Fade in timeout={500}>
        <TableViewContentContainer>
          <StyledDatagrid
            className="intercom-sphere-segment-view-table"
            columns={columns
              .map((column) => ({
                field: column,
                headerName: column,
                flex: 1,
              }))
              .sort(
                (a, b) =>
                  defaultColumnOrderModel(columns).indexOf(a.field) -
                  defaultColumnOrderModel(columns).indexOf(b.field)
              )}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
            hideFooterPagination
            disableSelectionOnClick
            hideFooterSelectedRowCount
            rowHeight={70}
            rows={rows.map((row) => ({
              id: row.Segment,
              ...row,
            }))}
            slots={{ toolbar: CustomToolbar }}
            getCellClassName={(params) => {
              if (params.field === 'scoreChange') {
                return 'scoreChangeCell';
              }
              if (params.field === 'score') {
                return 'scoreCell';
              }
              return '';
            }}
            sx={{
              boxShadow: 2,
              border: 1,
              borderColor: themeColors.contentCardBorderColor,
              backgroundColor: themeColors.contentCardBackgroundColor,
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              display: 'grid', // these two lines are hacky fix for v5 -> v6 upgfrade to ensure width doesnt permanently grow
              gridTemplateRows: 'auto 1f auto',
            }}
          />
        </TableViewContentContainer>
      </Fade>
    )
  );
};
