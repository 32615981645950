import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import isValidProp from '@emotion/is-prop-valid';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Tooltip,
  tooltipClasses,
} from '@mui/material';

import { DropdownOptions } from './DropdownOptions';
import { ReactComponent as MonthIcon } from '../../../assets/tab_monthly_icon.svg';
import { ReactComponent as QuarterIcon } from '../../../assets/tab_quarterly_icon.svg';
import { TerritoryOverviewContext } from '../territoryOverviewContext';
import { trackTerritoryOverviewOptionSet } from '../../../trackers/mixpanel';
import { getTimeframeName } from '../utils';
import { Distribution } from '../constants';

const SettingsBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 12,
}));

const SettingsSelector = styled(Box)(() => ({
  display: 'flex',
  marginTop: 24,
  gridGap: 16,
  flexWrap: 'wrap',
}));

const DateTextDescription = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 14,
  textAlign: 'left',
  color: '#8595AD',
  marginTop: 12,
}));

const SettingsTabs = styled(Tabs)(({ theme: { themeColors } }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: 40,
  backgroundColor: 'rgba(68, 83, 106, 0.22)',
  '.MuiTab-root.Mui-selected': {
    color: themeColors.csvDownloadColor,
    borderColor: themeColors.csvDownloadColor,
  },
  svg: {
    height: 20,
  },
  '.MuiTabs-indicator': {
    display: 'none',
  },

  '.MuiTabs-flexContainer': {
    height: 40,
  },
}));

const TimeframeTab = styled(Tab)(() => ({
  minHeight: 'auto',
  padding: '8px 10px',
  border: '1px solid #313B49',
  boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.12)',
  textTransform: 'none',
  color: 'white',
  fontSize: 16,
  fontWeight: 400,
  '.MuiTab-iconWrapper': {
    margin: '0 6px 0 0',
  },

  '&:first-of-type': {
    borderRadius: '4px 0px 0px 4px',
  },

  '&:last-of-type': {
    borderRadius: '0px 4px 4px 0px',
  },
}));

const validateProp = (prop) => prop === 'icon' || isValidProp(prop);

const DisabledTimeframeTab = styled(Tab, { shouldForwardProp: validateProp })(
  ({ theme: { themeColors } }) => ({
    display: 'flex',
    flexDirection: 'row',
    opacity: 1,
    minHeight: 'auto',
    padding: '8px 10px',
    border: `1px solid ${themeColors.borderLowContrast}`,
    boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.12)',
    textTransform: 'none',
    color: themeColors.borderLowContrast,
    fontSize: 16,
    cursor: 'not-allowed',

    '.MuiTab-iconWrapper': {
      margin: '0 6px 0 0',
    },

    '&:first-of-type': {
      borderRadius: '4px 0px 0px 4px',
    },

    '&:last-of-type': {
      borderRadius: '0px 4px 4px 0px',
    },
  })
);

const getTabCursor = (isCurrentSelection, optionAvailable) => {
  if (!optionAvailable) {
    return 'not-allowed';
  }

  return isCurrentSelection ? 'default' : 'pointer';
};

const DistributionTypeTab = styled(Tab, { shouldForwardProp: isValidProp })(
  ({ theme: { themeColors }, optionAvailable, isCurrentSelection }) => {
    const availableColor = optionAvailable
      ? 'white'
      : themeColors.borderLowContrast;

    return {
      opacity: 1,
      minHeight: 'auto',
      padding: '8px 10px',
      border: `1px solid ${availableColor}`,
      boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.12)',
      textTransform: 'none',
      color: availableColor,
      fontSize: 16,
      cursor: getTabCursor(isCurrentSelection, optionAvailable),

      '.MuiTab-iconWrapper': {
        margin: '0 6px 0 0',
      },

      '&:first-of-type': {
        borderRadius: '4px 0px 0px 4px',
      },

      '&:last-of-type': {
        borderRadius: '0px 4px 4px 0px',
      },
    };
  }
);

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#313B49',
    color: '#FFF',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
  },
}));

const TIMEFRAME_ICONS_MAP = {
  Month: MonthIcon,
  Quarter: QuarterIcon,
};

const DistributionTypeSelectorTabs = ({
  setIsDemandLoading,
  setIsSupplyLoading,
}) => {
  const types = [Distribution.DEMAND, Distribution.SUPPLY];
  const {
    distributionTypesAvailable,
    distributionType,
    updateDistributionType,
    setDataResponse,
  } = useContext(TerritoryOverviewContext);

  const onTabClick = (newDistributionType) => {
    if (distributionType === newDistributionType) {
      return;
    }

    setDataResponse(undefined);

    if (newDistributionType === Distribution.DEMAND) {
      setIsDemandLoading(true);
    }

    if (newDistributionType === Distribution.SUPPLY) {
      setIsSupplyLoading(true);
    }

    updateDistributionType(newDistributionType);
  };

  return (
    types && (
      <SettingsTabs value={distributionType}>
        {types.map((type) => {
          const firstCharCapitalized =
            type.charAt(0).toUpperCase() + type.slice(1);

          const optionAvailable = distributionTypesAvailable[type];

          return optionAvailable ? (
            <DistributionTypeTab
              key={`${type}-key`}
              label={firstCharCapitalized}
              value={type}
              onClick={() => {
                onTabClick(type);
              }}
              isCurrentSelection={
                distributionType.toUpperCase() === type.toUpperCase()
              }
              optionAvailable={optionAvailable}
            />
          ) : (
            <CustomTooltip
              title="We do not have the data to generate this report."
              arrow
            >
              <DistributionTypeTab
                key={`${type}-key`}
                label={firstCharCapitalized}
                value={type}
                optionAvailable={optionAvailable}
              />
            </CustomTooltip>
          );
        })}
      </SettingsTabs>
    )
  );
};

const TABS = [
  { id: 'month', name: 'Month' },
  { id: 'quarter', name: 'Quarter' },
];

const TimeframeSelectorTabs = ({ changeDescription }) => {
  const {
    data,
    selectedTimeframeID,
    setSelectedTimeframeID,
    distributionType,
    categoryOptions,
    selectedCategory,
    metricOptions,
    selectedMetric,
    timeframeOptionsAvailable,
  } = useContext(TerritoryOverviewContext);

  const onTabClick = (tabId) => {
    changeDescription(data?.timescaleData[tabId]?.description);

    const newTimeframe = getTimeframeName(data, tabId);

    const oldTimeframe = getTimeframeName(data, selectedTimeframeID);

    trackTerritoryOverviewOptionSet(
      'Time Range',
      newTimeframe,
      oldTimeframe,
      [
        newTimeframe,
        distributionType,
        categoryOptions[selectedCategory]?.label,
        metricOptions[selectedMetric]?.label,
      ],
      false
    );

    setSelectedTimeframeID(tabId);
  };

  return (
    <Box>
      <SettingsTabs value={selectedTimeframeID || 0}>
        {TABS.map((tab) => {
          const Icon = TIMEFRAME_ICONS_MAP[tab.name];

          const { id, name } = tab;

          const optionAvailable =
            timeframeOptionsAvailable.length > 0 &&
            timeframeOptionsAvailable?.includes(id);

          return optionAvailable ? (
            <TimeframeTab
              key={id}
              label={name}
              value={id}
              iconPosition="start"
              icon={<Icon />}
              onClick={() => {
                onTabClick(id);
              }}
            />
          ) : (
            <CustomTooltip
              title="We do not have the data to generate this report."
              arrow
            >
              <DisabledTimeframeTab
                key={`${id}-key`}
                label={name}
                value={id}
                icon={<Icon />}
                iconPosition="start"
              />
            </CustomTooltip>
          );
        })}
      </SettingsTabs>
    </Box>
  );
};

export const Settings = ({ setIsDemandLoading, setIsSupplyLoading }) => {
  const { data, metadata, selectedTimeframeID } = useContext(
    TerritoryOverviewContext
  );

  const [timeframeDescription, setTimeframeDescription] = useState('');

  useEffect(() => {
    const newDescription =
      data?.timescaleData?.[selectedTimeframeID]?.description;

    if (typeof newDescription === 'string' && newDescription.trim() !== '') {
      setTimeframeDescription(newDescription);
    }
  }, [data]);

  return (
    <SettingsBox>
      {metadata && (
        <>
          <SettingsSelector>
            <TimeframeSelectorTabs
              changeDescription={setTimeframeDescription}
            />

            <DistributionTypeSelectorTabs
              setIsDemandLoading={setIsDemandLoading}
              setIsSupplyLoading={setIsSupplyLoading}
            />
            <DropdownOptions />
          </SettingsSelector>
          {timeframeDescription && (
            <DateTextDescription>{timeframeDescription}</DateTextDescription>
          )}
        </>
      )}
    </SettingsBox>
  );
};
