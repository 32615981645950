import { Star } from '@mui/icons-material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React from 'react';
import { ReactComponent as VacationIcon } from '../../../assets/vacation.svg';

export const userCreatedListOptions = {
  starred: {
    id: 'starred',
    label: 'Starred',
    description: 'HCPs you wish to track',
    segmentDescription:
      'HCPs that you have identified as those you would like to focus on',
    icon: <Star />,
  },
  noSee: {
    id: 'noSee',
    label: 'No-See',
    description: 'HCPs you cannot contact',
    segmentDescription:
      'HCPs that you have identified as those you cannot contact',
    icon: <VisibilityOffIcon />,
  },
  longTermLeave: {
    id: 'longTermLeave',
    label: 'Long-Term Leave',
    description: 'HCPs that are on leave',
    segmentDescription:
      'HCPs that you have identified as those that are on leave',
    icon: <VacationIcon />,
  },
};

export const availabilityOptions = [
  {
    id: 'noSee',
    label: 'No-See',
    switchDescription: 'Include No-See HCPs',
  },
  {
    id: 'longTermLeave',
    label: 'Long-Term Leave',
    switchDescription: 'Include Long-Term Leave HCPs',
  },
];
