import React from 'react';
import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';

const StyledSkeletonBar = styled(Skeleton)(() => ({
  transform: 'scale(1)',
  marginTop: 10,
  height: 250,
}));

export const DrawerChartSkeleton = () => <StyledSkeletonBar animation="wave" />;
