import React from 'react';
import moment from 'moment';
import TrendValue, {
  roundPercentage,
  TREND_TYPES,
} from '../../fieldView/entitycontents/trendValues';
import ChangeChip from '../../fieldView/changeChip';
import { PowerScore } from '../../fieldView/powerScore';

const scoreSortASC = (val1, val2) => val1 - val2;
const percentStringFromValue = (value) => `${value.toFixed(1)}%`;
const trendFormatter = (cell) => {
  if (typeof cell.value === 'string') {
    return cell.value;
  }
  return cell.value ? percentStringFromValue(cell.value) : '';
};
const createPredictionTrendFormatting = (field, header) => ({
  field,
  headerName: header,
  minWidth: 250,
  flex: 1,
  valueFormatter: (cell) => trendFormatter(cell),
  type: 'number',
  renderCell: (cell) => (
    <TrendValue
      type={cell.row?.[field]?.type}
      value={cell.row?.[field]?.value}
      status={cell.row?.[field]?.status}
    />
  ),
  valueGetter: (params) => {
    if (params?.row?.[field]) {
      const { type, value } = params.row[field];

      if (type === TREND_TYPES.TREND_ONLY) {
        if (value === 1) {
          return 'INCR';
        }
        if (value === -1) {
          return 'DECR';
        }
      }
      return roundPercentage(value * 100);
    }
    return '-';
  },
});

const createHistoricalTrendFormatting = (field, header) => ({
  field,
  headerName: header,
  type: 'number',
  flex: 1,
  minWidth: 165,
  valueFormatter: (cell) => trendFormatter(cell),
  renderCell: (cell) => (
    <TrendValue
      type={cell.row?.[field]?.type}
      value={cell.row?.[field]?.value}
      status={cell.row?.[field]?.status}
    />
  ),
  valueGetter: (params) => {
    if (params?.row?.[field]?.value) {
      return roundPercentage(params.row[field].value * 100);
    }
    return '-';
  },
});

const createBasicStringFormatting = (field, header) => ({
  field,
  headerName: header,
  flex: 1,
});

const createBasicNumberFormatting = (field, header) => ({
  field,
  headerName: header,
  flex: 1,
  type: 'number',
});

const createLeftAlignNumberFormatting = (field, header) => ({
  ...createBasicNumberFormatting(field, header),
  headerAlign: 'left',
  align: 'left',
});

const createNumWithZeroAsDashFormatting = (field, header) => ({
  ...createBasicNumberFormatting(field, header),
  valueGetter: (param) => (param?.value !== 0 ? param?.value : '-'),
  valueFormatter: (param) => param?.value?.toString()?.replace(',', ''),
});

const createDateFormatting = (field, header) => ({
  field,
  headerName: header,
  type: 'date',
  flex: 1,
  minWidth: 135,
  valueFormatter: (param) =>
    param?.value ? moment(param?.value).format('ddd, MMM D, YYYY') : null,
  valueGetter: (param) =>
    param?.value ? moment(param?.value).format('ddd, MMM D, YYYY') : null,
});

const createScoreFormatting = (field, header) => ({
  ...createBasicNumberFormatting(field, header),
  sortComparator: scoreSortASC,
  minWidth: 110,
  headerAlign: 'center',
  renderCell: (cell) =>
    cell.row?.[field] && cell.row?.[field] === -1 ? (
      <> - </>
    ) : (
      <PowerScore
        score={Math.round(cell.row?.[field])}
        style={{ alignItems: 'center' }}
      />
    ),
});
const createScoreChangeFormatting = (field, header) => ({
  ...createBasicNumberFormatting(field, header),
  headerAlign: 'center',
  width: 170,
  renderCell: (cell) => <ChangeChip change={cell.row?.[field]} />,
});

const createNumMinWidth200Formatting = (field, header) => ({
  ...createBasicNumberFormatting(field, header),
  minWidth: 200,
});

const createNumMinWidth120Formatting = (field, header) => ({
  ...createBasicNumberFormatting(field, header),
  minWidth: 120,
});

const createMinWidth105Formatting = (field, header) => ({
  ...createBasicStringFormatting(field, header),
  minWidth: 105,
});

const createMinWidth180Formatting = (field, header) => ({
  ...createBasicStringFormatting(field, header),
  minWidth: 180,
});

const createMinWidth250Formatting = (field, header) => ({
  ...createBasicStringFormatting(field, header),
  minWidth: 250,
});

const displayTypeToFormatterMap = {
  basicString: createBasicStringFormatting,
  basicNumber: createBasicNumberFormatting,
  numberAlignLeft: createLeftAlignNumberFormatting,
  numberWithZeroAsDash: createNumWithZeroAsDashFormatting,
  date: createDateFormatting,
  score: createScoreFormatting,
  scoreChange: createScoreChangeFormatting,
  numberMinWidth200: createNumMinWidth200Formatting,
  numberMinWidth120: createNumMinWidth120Formatting,
  historicalScriptTrend: createHistoricalTrendFormatting,
  predictionScriptTrend: createPredictionTrendFormatting,
  minWidth105: createMinWidth105Formatting,
  minWidth180: createMinWidth180Formatting,
  minWidth250: createMinWidth250Formatting,
};

export const createColumns = (columnConfig) => {
  const columns = [];
  columnConfig.forEach((col) => {
    const { field, headerName, displayType } = col;
    if (displayType in displayTypeToFormatterMap) {
      const formatter = displayTypeToFormatterMap[displayType];
      columns.push(formatter(field, headerName));
    } else if (displayType !== 'DoNotDisplay') {
      // eslint-disable-next-line no-console
      console.error(`${displayType} not in formatter map`);
    }
  });
  return columns;
};
