/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import * as IngestAction from '../../actions/ingestAction';
import { getDemoAccountStatus } from '../../request/config';

const StyledButton = styled(Button)`
  margin-right: 16px;
`;

const ProgressionControls = ({ handleComplete }) => {
  const dispatch = useDispatch();
  const setActiveStep = (index) => dispatch(IngestAction.setActiveStep(index));

  const ingestNav = useSelector((state) => state.ingestNav);
  const isIngestBusy = useSelector((state) => state.ingest.isBusy);
  const isDemoAccount = getDemoAccountStatus();

  const isLastStep = () =>
    ingestNav.completedStepsCount === ingestNav.stepsCount - 1;

  const handleBack = () => setActiveStep(ingestNav.activeStep - 1);

  return (
    <div style={{ marginBottom: '1%' }}>
      <StyledButton disabled={ingestNav.activeStep === 0} onClick={handleBack}>
        Back
      </StyledButton>
      {ingestNav.completedSteps[ingestNav.activeStep] ? (
        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => dispatch(IngestAction.gotoNextStep())}
        >
          Next
        </StyledButton>
      ) : null}
      {ingestNav.activeStep !== ingestNav.titleSteps.length &&
        (ingestNav.completedSteps[ingestNav.activeStep] ? (
          <Typography variant="caption" sx={{ display: 'inline-block' }}>
            Step {ingestNav.activeStep + 1} already completed
          </Typography>
        ) : isIngestBusy ? (
          <CircularProgress size={30} thickness={5} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleComplete}
            disabled={isLastStep() && isDemoAccount}
          >
            {isLastStep() ? 'Finish' : 'Complete Step'}
          </Button>
        ))}
    </div>
  );
};

export default ProgressionControls;
