import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { useLocation, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useQuery } from 'react-query';
import { Header } from './Header';
import { HcpTableProvider } from './hcpTableProvider';
import { Controls } from './controls/controls';
import { CardRow } from './cardRow/cardRow';
import { PulseContext } from '../../context/PulseContext';
import { maptualApiInstance } from '../../../../../../request/config';
import { getProjectObjectives } from '../../../../../../request/projectRequests';
import { useRegions } from '../salesReps/regionSelector/useRegions';
import { AppContext } from '../../../../appContext';
import { trackPulseRegionView } from '../../../../../../trackers/mixpanel';

const StyledPage = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: themeColors.mainBackground,
  flexGrow: 1,
  overflow: 'hidden',
  overflowY: 'auto',
}));

const StyledContentScroll = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 16px',
  overflowX: 'auto',
}));

const StyledContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: '24px 0px',
  maxWidth: '1100px',
}));

const StyledHeader = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: '500',
  textAlign: 'left',
  width: '100%',
  marginBottom: '12px',
}));

export const Rep = () => {
  const { latestCurationTimestamp, marketName, productLineId } =
    useContext(PulseContext);
  const location = useLocation();
  const [cadence, setCadence] = useState();
  const [timestamp, setTimestamp] = useState();
  const [objectiveId, setObjectiveId] = useState();
  const [headerTitle, setHeaderTitle] = useState();
  const { territoryId, projectId, regionId } = useParams();
  const { profile } = useContext(AppContext);

  const fetchHeaderTitle = async (fetchRegionId) => {
    const response = await maptualApiInstance.get(
      `core/product-lines/${productLineId}/regions/${fetchRegionId}/metadata`,
      {
        timeout: 5000,
      }
    );
    return response?.data;
  };

  const { data, isLoading } = useQuery({
    queryKey: territoryId,
    queryFn: () => fetchHeaderTitle(territoryId),
    enabled: !!productLineId,
  });

  const { data: objectives } = useQuery(projectId, async () => {
    try {
      const response = await getProjectObjectives({ projectId });
      return response.data;
    } catch {
      return [];
    }
  });

  const { data: allRegions } = useRegions({
    productLineId,
    territories: [],
    isHookEnabled: !!productLineId,
  });

  useEffect(() => {
    if (
      !objectiveId ||
      !objectives ||
      !regionId ||
      !territoryId ||
      !allRegions ||
      allRegions.length === 0 ||
      !marketName ||
      !profile?.userGroup ||
      !data?.regionName
    )
      return;
    trackPulseRegionView({
      userGroup: profile.userGroup,
      marketName,
      objectiveName:
        objectives.find((objective) => objective.id === objectiveId).title ||
        'no objective selected',
      districtName: allRegions?.find((i) => i.id === regionId)?.listName,
      regionName: data?.regionName,
    });
  }, [
    objectiveId,
    objectives,
    regionId,
    allRegions,
    marketName,
    profile,
    territoryId,
    data,
  ]);

  useEffect(() => {
    if (!isLoading && data?.repName) {
      setHeaderTitle(data?.repName);
    }
  }, [data]);

  useEffect(() => {
    setHeaderTitle(location.repName);
  }, []);

  useEffect(() => {
    if (timestamp !== latestCurationTimestamp)
      setTimestamp(latestCurationTimestamp);
  }, [latestCurationTimestamp]);

  const updateFilterObjective = (newObjectiveId) => {
    if (newObjectiveId !== objectiveId) setObjectiveId(newObjectiveId);
  };

  if (location.objectiveId && !objectiveId) {
    updateFilterObjective(location.objectiveId);
  }
  if (location.cadence && !cadence) {
    setCadence(location.cadence);
  }

  return (
    <StyledPage>
      <Header headerTitle={headerTitle} />
      <StyledContentScroll>
        <StyledContent>
          <Controls
            timestamp={timestamp}
            cadence={cadence}
            updateCadence={(newCadence) => setCadence(newCadence)}
            updateTimestamp={(newTimestamp) => setTimestamp(newTimestamp)}
            objectiveId={objectiveId}
            updateFilterObjective={updateFilterObjective}
          />
          <StyledHeader>Activity Overview</StyledHeader>
          <CardRow
            objectiveId={objectiveId}
            cadence={cadence}
            timestamp={timestamp}
          />
          <StyledHeader>HCPs Called</StyledHeader>
          <HcpTableProvider
            regionName={data?.regionName}
            objectiveId={objectiveId}
            timestamp={timestamp}
            cadence={cadence}
          />
        </StyledContent>
      </StyledContentScroll>
    </StyledPage>
  );
};
