import React from 'react';
import styled from '@emotion/styled';
import makeStyles from '@mui/styles/makeStyles';
import MetricScriptsFieldViewProvider from './tabbedChart/metricScriptsFieldViewProvider';
import MetricScriptsSphereViewProvider from './tabbedChart/metricScriptsSphereViewProvider';
import useMaptualContext from '../../../hooks/useMaptualContext';
import { APPVIEWS } from '../../../constants';

const ChartContainer = styled.div`
  margin: 0;
  background-color: ${(props) =>
    props.theme.themeColors.contentCardBackgroundColor};
  border: 1px solid;
  border-color: ${(props) => props.theme.themeColors.contentCardBorderColor};
  border-radius: 7px;
  color-scheme: dark;
`;

const useStyles = makeStyles(() => ({
  tabbedDashBoardChart: {
    margin: '0',
    padding: '0',
    marginBottom: 16,
  },
}));

export default function MetricScriptsChart({
  style,
  metricComponentType,
  data = [],
  activeObjective = {},
}) {
  const classes = useStyles();
  const { appView, isStandalonePage } = useMaptualContext();
  return (
    <div className={classes.tabbedDashBoardChart} style={style}>
      <ChartContainer>
        {appView === APPVIEWS.FIELD || isStandalonePage ? (
          <MetricScriptsFieldViewProvider
            metricComponentType={metricComponentType}
            style={{ padding: '20px' }}
            activeObjective={activeObjective}
          />
        ) : (
          <MetricScriptsSphereViewProvider
            metricComponentType={metricComponentType}
            style={{ padding: '20px' }}
            data={data}
          />
        )}
      </ChartContainer>
    </div>
  );
}
