import ReactGA from 'react-ga';
import { ENVIRONMENTS } from '../constants';

let isGAInitialized = false;

export function initializeReactGA(options = {}) {
  const trackingId = 'UA-183638773-2';

  if (isGAInitialized) return;

  ReactGA.initialize(trackingId, {
    gaOptions: options,
    testMode: process.env.NODE_ENV === ENVIRONMENTS.TEST,
  });
  isGAInitialized = true;
  ReactGA.set(options);
  ReactGA.event({
    category: options.Environment,
    action: 'sign in',
    label: options['User Name'],
  });
}

export function trackProjectSelection(projectId) {
  ReactGA.event({
    category: 'project_selection',
    action: 'select',
    label: projectId,
  });
}

export function trackTabNavigation(tabName) {
  ReactGA.event({
    category: 'tab_navigation',
    action: 'click',
    label: tabName,
  });
}

export function trackSecondaryTabNavigation(tabName) {
  ReactGA.event({
    category: 'secondary_tab_navigation',
    action: 'click',
    label: tabName,
  });
}

export function trackUser(userName) {
  ReactGA.event({
    category: 'user',
    action: 'sign in',
    label: userName,
  });
}
