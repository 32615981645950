import React from 'react';
import { Button } from '@mui/material';
import {
  ModalDisplay,
  ModalHeader,
  ModalFooter,
} from '../generic/modalDisplay';
import {
  StyledModalBody,
  ModalDisplayCustomStyle,
} from './styles/styledComponents';

const customModalStyle = {
  ...ModalDisplayCustomStyle,
  minWidth: 300,
  maxWidth: 450,
};

export default function NewProjectCloseModal({
  isOpen,
  onClose,
  handleDiscard,
}) {
  return (
    <ModalDisplay isOpen={isOpen} style={customModalStyle}>
      <ModalHeader
        onClose={onClose}
        shouldShowBottomBorder={false}
        shouldShowXIcon={false}
      >
        Discard Unsaved Changes?
      </ModalHeader>
      <StyledModalBody>
        Configuration selections for this project will not be saved upon
        closing.
      </StyledModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleDiscard} variant="contained">
          Confirm and Discard
        </Button>
      </ModalFooter>
    </ModalDisplay>
  );
}
