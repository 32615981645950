import { useState, useEffect } from 'react';
import { theme, lightTheme } from './theme';
// import { useLocalStorageValue } from '../../hooks/useLocalStorageValue';

const ThemeNameOptions = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};

export const useThemeContext = () => {
  const [activeTheme, setActiveTheme] = useState(theme);
  const [activeThemeName, setActiveThemeName] = useState(ThemeNameOptions.DARK);
  // const [savedTheme, setSavedTheme] = useLocalStorageValue('theme');

  useEffect(() => {
    setActiveThemeByName(ThemeNameOptions.DARK);

    // TEMP removal of theme detection from browser
    // if (
    //   savedTheme &&
    //   savedTheme === ThemeNameOptions.LIGHT &&
    //   activeThemeName !== ThemeNameOptions.LIGHT
    // ) {
    //   setActiveThemeByName(ThemeNameOptions.LIGHT);
    //   return;
    // }
    // if (
    //   window &&
    //   window.matchMedia &&
    //   window.matchMedia('(prefers-color-scheme: dark)').matches
    // ) {
    //   setActiveThemeByName(ThemeNameOptions.DARK);
    //   return;
    // }
    // if (
    //   window &&
    //   window.matchMedia &&
    //   window.matchMedia('(prefers-color-scheme: light)').matches
    // ) {
    //   setActiveThemeByName(ThemeNameOptions.LIGHT);
    // }
  }, []);

  const setActiveThemeByName = (name) => {
    if (name === ThemeNameOptions.LIGHT) {
      setActiveTheme(lightTheme);
      setActiveThemeName(ThemeNameOptions.LIGHT);
      // setSavedTheme(ThemeNameOptions.LIGHT);
    } else {
      setActiveTheme(theme);
      setActiveThemeName(ThemeNameOptions.DARK);
      // setSavedTheme(ThemeNameOptions.DARK);
    }
  };

  return {
    activeTheme,
    activeThemeName,
    setActiveThemeByName,
    ThemeNameOptions,
  };
};
