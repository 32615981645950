import React, { useState, useRef } from 'react';
import { useTheme } from '@mui/styles';
import { Line } from 'react-chartjs-2';
import { formatCellValue } from '../utils';
import { Tooltip, createTooltip } from '../tooltip';

export const SparklineLineGraph = ({
  data,
  labels,
  color,
  valueType,
  expanded = false,
  yTitle = '',
  tooltipDataPointTitle = '',
}) => {
  const [tooltip, setTooltip] = useState({
    trend: {},
    opacity: 0,
    top: 0,
    left: 0,
    date: '',
    value: '',
    display: 'none',
  });
  const { themeColors } = useTheme();
  const chartRef = useRef(null);

  const chartData = {
    labels,
    datasets: [
      {
        tick: false,
        scale: false,
        pointStyle: expanded,
        label: '',
        data,
        fill: false,
        borderColor: color,
        borderWidth: 2,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    animation: expanded,
    scales: {
      x: {
        display: expanded,
        ticks: {
          color: themeColors.graphAxisColor,
        },
      },
      y: {
        max: (scale) =>
          scale.chart.data.datasets.reduce((acc, curr) => {
            const max = Math.max(...curr.data);
            return max > acc ? max : acc;
          }, Number.MIN_SAFE_INTEGER),
        min: (scale) =>
          scale.chart.data.datasets.reduce((acc, curr) => {
            const min = Math.min(...curr.data);
            return min < acc ? min : acc;
          }, Number.MAX_SAFE_INTEGER),
        title: {
          display: !!yTitle,
          text: yTitle,
          font: 12,
          color: themeColors.graphAxisColor,
        },
        ticks: {
          color: themeColors.graphAxisColor,
          padding: 8,
          callback: (value) => formatCellValue(value, valueType, 1),
        },
        grid: {
          display: expanded,
          color: themeColors.graphGridColor,
        },
        display: expanded,
      },
    },
    legend: {
      display: false,
    },
    interaction: {
      mode: 'point',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          if (!expanded) return;
          createTooltip({
            context,
            tooltip,
            updateTooltip: (newTooltipData) => setTooltip(newTooltipData),
          });
        },
      },
    },
  };

  return (
    <>
      <Line data={chartData} ref={chartRef} options={options} />
      {expanded && (
        <Tooltip
          tooltip={tooltip}
          tooltipDataPointTitle={tooltipDataPointTitle}
          valueType={valueType}
        />
      )}
    </>
  );
};
