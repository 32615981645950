import { createTheme } from '@mui/material/styles';
import { red as secondary } from '@mui/material/colors';
import { darkThemeColors } from '../../styles/colorConfig';
import { customStyles } from '../../styles/fieldViewStyles';
import { themeOptions } from '../../styles/theme';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: darkThemeColors.primaryColorLight,
      main: darkThemeColors.primaryColor,
      dark: darkThemeColors.primaryColorDark,
      contrastText: customStyles.themeColors.darkTheme.primaryTextColor,
    },
    secondary,
  },
  insightContentCardStyle: customStyles.insightContentCardStyle,
  contentPrimaryTextStyle: customStyles.contentPrimaryTextStyle,
  contentSecondaryTextStyle: customStyles.contentSecondaryTextStyle,
  colorToneMapping: customStyles.colorToneMapping,
  themeColors: { ...customStyles.themeColors.darkTheme },
  ...themeOptions({ themeColors: customStyles.themeColors.darkTheme }),
});

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: darkThemeColors.primaryColorLight,
      main: darkThemeColors.primaryColor,
      dark: darkThemeColors.primaryColorDark,
      contrastText: customStyles.themeColors.lightTheme.primaryTextColor,
    },
    secondary,
  },
  insightContentCardStyle: customStyles.insightContentCardStyle,
  contentPrimaryTextStyle: customStyles.contentPrimaryTextStyle,
  contentSecondaryTextStyle: customStyles.contentSecondaryTextStyle,
  colorToneMapping: customStyles.colorToneMapping,
  themeColors: {
    ...customStyles.themeColors.lightTheme,
  },
  ...themeOptions({ themeColors: customStyles.themeColors.lightTheme }),
});
