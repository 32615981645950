import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Call from '@mui/icons-material/Call';
import Email from '@mui/icons-material/Email';
import Drafts from '@mui/icons-material/Drafts';
import Group from '@mui/icons-material/Group';
import VoiceChat from '@mui/icons-material/VoiceChat';
import Restaurant from '@mui/icons-material/Restaurant';
import ShoppingBasket from '@mui/icons-material/ShoppingBasket';
import Sms from '@mui/icons-material/Sms';
import Event from '@mui/icons-material/Event';
import Forum from '@mui/icons-material/Forum';
import People from '@mui/icons-material/People';
import Devices from '@mui/icons-material/Devices';
import Vaccines from '@mui/icons-material/Vaccines';
import Message from '@mui/icons-material/Message';
import SupervisorAccount from '@mui/icons-material/SupervisorAccount';
import Facebook from '@mui/icons-material/Facebook';
import Twitter from '@mui/icons-material/Twitter';
import QueryStats from '@mui/icons-material/QueryStats';
import Approval from '@mui/icons-material/Approval';
import AttachEmail from '@mui/icons-material/AttachEmail';
import VideoCameraFront from '@mui/icons-material/VideoCameraFront';
import CallOutlined from '@mui/icons-material/CallOutlined';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import DraftsOutlined from '@mui/icons-material/DraftsOutlined';
import GroupOutlined from '@mui/icons-material/GroupOutlined';
import VoiceChatOutlined from '@mui/icons-material/VoiceChatOutlined';
import RestaurantOutlined from '@mui/icons-material/RestaurantOutlined';
import ShoppingBasketOutlined from '@mui/icons-material/ShoppingBasketOutlined';
import SmsOutlined from '@mui/icons-material/SmsOutlined';
import EventOutlined from '@mui/icons-material/EventOutlined';
import ForumOutlined from '@mui/icons-material/ForumOutlined';
import PeopleOutlined from '@mui/icons-material/PeopleOutlined';
import DevicesOutlined from '@mui/icons-material/DevicesOutlined';
import VaccinesOutlined from '@mui/icons-material/VaccinesOutlined';
import MessageOutlined from '@mui/icons-material/MessageOutlined';
import SupervisorAccountOutlined from '@mui/icons-material/SupervisorAccountOutlined';
import FacebookOutlined from '@mui/icons-material/FacebookOutlined';
import QueryStatsOutlined from '@mui/icons-material/QueryStatsOutlined';
import ApprovalOutlined from '@mui/icons-material/ApprovalOutlined';
import AttachEmailOutlined from '@mui/icons-material/AttachEmailOutlined';
import VideoCameraFrontOutlined from '@mui/icons-material/VideoCameraFrontOutlined';
import { useTheme } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const CHANNEL_TYPE = Object.freeze({
  EMAIL_SENT: 'EMAIL_SENT',
  EMAIL_OPEN: 'EMAIL_OPEN',
  CALL: 'CALL',
  F2F: 'F2F',
  F2F_VIRTUAL: 'F2F_VIRTUAL',
  VIRTUAL: 'VIRTUAL',
  LAUNCH_N_LEARN: 'LAUNCH_N_LEARN',
  SAMPLE_DROP: 'SAMPLE_DROP',
  TEXT_MESSAGE: 'TEXT_MESSAGE',
  EVENTS: 'EVENTS',
  PEER_INTERACTION: 'PEER_INTERACTION',
  PERSONAL_REMOTE: 'PERSONAL_REMOTE',
  SAMPLES: 'SAMPLES',
  WEBINAR_EMAIL: 'WEBINAR_EMAIL',
  CONN_RX_MSGS: 'CONN_RX_MSGS',
  PTO_VISITS: 'PTO_VISITS',
  FACEBOOK: 'FACEBOOK',
  TWITTER: 'TWITTER',
  PAID_SEARCH: 'PAID_SEARCH',
  PULSEPOINT: 'PULSEPOINT',
  UNKNOWN: 'UNKNOWN',
});

const ICONS_FILLED = Object.freeze({
  EMAIL_SENT: Email,
  EMAIL_OPEN: Drafts,
  CALL: Call,
  F2F: Group,
  F2F_VIRTUAL: VideoCameraFront,
  VIRTUAL: VoiceChat,
  LAUNCH_N_LEARN: Restaurant,
  SAMPLE_DROP: ShoppingBasket,
  TEXT_MESSAGE: Sms,
  EVENTS: Event,
  PEER_INTERACTION: People,
  PERSONAL_REMOTE: Devices,
  SAMPLES: Vaccines,
  WEBINAR_EMAIL: AttachEmail,
  CONN_RX_MSGS: Message,
  PTO_VISITS: SupervisorAccount,
  FACEBOOK: Facebook,
  TWITTER: Twitter,
  PAID_SEARCH: QueryStats,
  PULSEPOINT: Approval,
  UNKNOWN: Forum,
});

const ICONS_OUTLINED = Object.freeze({
  EMAIL_SENT: EmailOutlined,
  EMAIL_OPEN: DraftsOutlined,
  CALL: CallOutlined,
  F2F: GroupOutlined,
  F2F_VIRTUAL: VideoCameraFrontOutlined,
  VIRTUAL: VoiceChatOutlined,
  LAUNCH_N_LEARN: RestaurantOutlined,
  SAMPLE_DROP: ShoppingBasketOutlined,
  TEXT_MESSAGE: SmsOutlined,
  EVENTS: EventOutlined,
  PEER_INTERACTION: PeopleOutlined,
  PERSONAL_REMOTE: DevicesOutlined,
  SAMPLES: VaccinesOutlined,
  WEBINAR_EMAIL: AttachEmailOutlined,
  CONN_RX_MSGS: MessageOutlined,
  PTO_VISITS: SupervisorAccountOutlined,
  FACEBOOK: FacebookOutlined,
  TWITTER: Twitter,
  PAID_SEARCH: QueryStatsOutlined,
  PULSEPOINT: ApprovalOutlined,
  UNKNOWN: ForumOutlined,
});

export const getIconComp = (ch, style, outlined = false) => {
  const iconType = getChannelType(ch);
  const Icon = outlined ? ICONS_OUTLINED[iconType] : ICONS_FILLED[iconType];
  return <Icon style={style} />;
};

const getChannelType = (ch) => {
  switch (ch) {
    case 'email':
    case 'EMAIL_SENT':
      return CHANNEL_TYPE.EMAIL_SENT;
    case 'email opened':
    case 'email open':
    case 'email_opened':
    case 'email_open':
    case 'EMAIL_OPENED':
    case 'EMAIL_READ':
      return CHANNEL_TYPE.EMAIL_OPEN;
    case 'PHONE_CALL':
    case 'call':
    case 'phone':
    case 'phone call':
    case 'web call':
      return CHANNEL_TYPE.CALL;
    case 'F2F':
    case 'f2f':
    case 'face-to-face':
    case 'face2face':
    case 'f2f accompanied':
      return CHANNEL_TYPE.F2F;
    case 'f2f virtual':
      return CHANNEL_TYPE.F2F_VIRTUAL;
    case 'VIRTUAL':
    case 'virtual':
    case 'virtual meeting':
      return CHANNEL_TYPE.VIRTUAL;
    case 'launch and learn':
    case 'launch_and_learn':
    case 'LAUNCH_N_LEARN':
      return CHANNEL_TYPE.LAUNCH_N_LEARN;
    case 'sample drop':
    case 'sample_drop':
    case 'SAMPLE_DROP':
      return CHANNEL_TYPE.SAMPLE_DROP;
    case 'sms':
    case 'text':
    case 'text message':
    case 'messaging':
    case 'TEXT_MESSAGE':
      return CHANNEL_TYPE.TEXT_MESSAGE;
    case 'event':
    case 'events':
    case 'EVENTS':
      return CHANNEL_TYPE.EVENTS;
    case 'PEER_INTERACTION':
      return CHANNEL_TYPE.PEER_INTERACTION;
    case 'PERSONAL_REMOTE':
      return CHANNEL_TYPE.PERSONAL_REMOTE;
    case 'SAMPLES':
    case 'samples':
    case 'samples kit':
      return CHANNEL_TYPE.SAMPLES;
    case 'copd email':
    case 'asthma email':
    case 'webinar email':
      return CHANNEL_TYPE.WEBINAR_EMAIL;
    case 'conn rx msg':
    case 'connective rx messages':
      return CHANNEL_TYPE.CONN_RX_MSGS;
    case 'pto visit':
    case 'pto visits':
      return CHANNEL_TYPE.PTO_VISITS;
    case 'facebook':
    case 'facebook impression':
      return CHANNEL_TYPE.FACEBOOK;
    case 'twitter':
    case 'twitter impression':
      return CHANNEL_TYPE.TWITTER;
    case 'paid search':
    case 'paid search impression':
      return CHANNEL_TYPE.PAID_SEARCH;
    case 'pulsepoint':
    case 'pulsepoint impression':
      return CHANNEL_TYPE.PULSEPOINT;
    default:
      return CHANNEL_TYPE.UNKNOWN;
  }
};
export default function ChannelIconProvider({
  channelName,
  style = {},
  iconType,
  showName = false,
  containerStyle = {},
}) {
  const classes = useStyles();
  const { themeColors } = useTheme();
  const lowerCaseCh = channelName ? channelName?.toLowerCase() : '';
  const { width = 25 } = style;
  const { marginLeft = 0, marginTop = 0 } = containerStyle;

  return (
    <Tooltip title={channelName} aria-label="top 3 channels">
      <div
        className={classes.root}
        style={{
          marginLeft,
          marginTop,
          width: Math.round(width * 1.5),
          maxWidth: Math.round(width * 1.5),
        }}
      >
        {getIconComp(iconType || lowerCaseCh, style)}
        {showName ? (
          <Typography
            noWrap
            style={{
              marginTop: 5,
              maxWidth: Math.round(width * 1.5),
              fontSize: 11,
              fontWeight: 400,
              color: themeColors.primaryTextColor,
            }}
            align="center"
          >
            {channelName}
          </Typography>
        ) : null}
      </div>
    </Tooltip>
  );
}

ChannelIconProvider.propTypes = {
  channelName: PropTypes.string,
  style: PropTypes.shape({
    width: PropTypes.number,
  }),
  iconType: PropTypes.string,
  showName: PropTypes.bool,
  containerStyle: PropTypes.shape({
    marginLeft: PropTypes.number,
    marginTop: PropTypes.number,
  }),
};
