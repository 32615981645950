export function formatListForDisplay(list) {
  if (list.length === 1) {
    return list[0];
  }
  if (list.length === 2) {
    return list.join(' and ');
  }
  const lastItem = list.pop();
  return `${list.join(', ')}, and ${lastItem}`;
}
