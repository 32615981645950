export const disclaimerOptions = {
  INVALID_REGION: 'INVALID_REGION',
  NO_CHILD_REGIONS: 'NO_CHILD_REGIONS',
  NO_PRODUCT_DATA: 'NO_PRODUCT_DATA',
};

export const Distribution = {
  SUPPLY: 'supply',
  DEMAND: 'demand',
};

export const DISTRIBUTION_CONFIG = {
  [Distribution.DEMAND]: {
    category: 'objectives',
    dropdownLabel: 'Objective',
  },
  [Distribution.SUPPLY]: {
    category: 'products',
    dropdownLabel: 'Product',
  },
};
