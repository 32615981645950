import JSZip from 'jszip';
import Papa from 'papaparse';
import * as requests from '../request/requests';

function createUUID() {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

async function compressChunk(content, datasetName) {
  // eslint-disable-next-line no-console
  console.log('Compression started');

  const zip = new JSZip();
  zip.file(`${Date.now()}_${createUUID()}_datachunk.csv`, content);
  const zipcontent = await zip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE',
  });
  // eslint-disable-next-line no-console
  console.log('Compression finished');
  // eslint-disable-next-line no-console
  console.log(`zip size: ${(zipcontent.size / 1024 / 1024).toFixed(2)} MB`);

  return requests.getPresignedS3UrlandUpload(
    zipcontent,
    'datachunk.zip',
    datasetName
  );
}

async function encryptChunk(chunkData) {
  const hasHeader = chunkData.meta.fields && chunkData.meta.fields.length > 0;
  const csv = Papa.unparse(chunkData.data, { hearder: hasHeader });

  return csv;
}

export { compressChunk, encryptChunk };
