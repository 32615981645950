/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ReturnIcon from '@mui/icons-material/ArrowBack';

import Section from './section';
import HeaderedSection from './headeredSection';
import TabSection from './tabSection';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  container: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function AnalysisItem({
  analysisItem,
  match,
  external,
  customStyle = null,
  isMultiListItem = false,
}) {
  const classes = useStyles();
  const routeHistory = useHistory();

  const { sections, headeredSection, tabSection } = analysisItem;

  const isAnchored = headeredSection || tabSection;

  const injectableControl = external ? (
    <div className={classes.container}>
      <IconButton
        onClick={() => {
          routeHistory.goBack();
        }}
        size="large"
      >
        <ReturnIcon color="primary" />
      </IconButton>
    </div>
  ) : null;

  return (
    <div className={classes.root} style={customStyle || {}}>
      {isAnchored && (
        <>
          {headeredSection ? (
            <HeaderedSection
              {...headeredSection}
              match={match}
              injectableControl={injectableControl}
              isMultiListItem={isMultiListItem}
            />
          ) : null}
          {tabSection ? (
            <TabSection
              {...tabSection}
              match={match}
              injectableControl={injectableControl}
              isMultiListItem={isMultiListItem}
            />
          ) : null}
        </>
      )}
      {!isAnchored && Object.keys(analysisItem).length >= 0 ? (
        <>
          {injectableControl}
          {Array.isArray(sections)
            ? sections.map((item, i) => (
                <Section {...item} match={match} key={i} />
              ))
            : null}
        </>
      ) : null}
    </div>
  );
}

AnalysisItem.propTypes = {
  analysisItem: PropTypes.shape({
    sections: PropTypes.array,
    headeredSection: PropTypes.any,
    tabSection: PropTypes.any,
  }),
  match: PropTypes.object,
  external: PropTypes.bool,
  customStyle: PropTypes.object,
  isMultiListItem: PropTypes.bool,
};
