export const DEFAULT_ENTITY_CADENCE = 'mth';

export const CADENCES_ENUM = {
  wk: 'Weekly',
  mth: 'Monthly',
  qtr: 'Quarterly',
  yr: 'Annually',
  mat: 'MAT',
};

export const UNITS_ENUM = {
  unit: 'Units',
  dollar: 'Dollars',
};

export const TABLE_HEADER_TYPES_ENUM = {
  LABEL: 'label',
  SCORE: 'score',
  UNIT: 'unit',
  RATE: 'rate',
  SEGMENT: 'segment',
  DRAWER: 'drawer',
};

export const LONG_FORM_CADENCE_ENUM = {
  wk: 'week',
  mth: 'month',
  qtr: 'quarter',
  yr: 'year',
  mat: 'month',
};

export const ERROR_TYPES_ENUM = {
  NON_TERRITORY: 'NON_TERRITORY',
  NO_DATA: 'NO_DATA',
  NO_FILTER_DATA: 'NO_FILTER_DATA',
  NO_SEARCH_DATA: 'NO_SEARCH_DATA',
  RETRY: 'RETRY',
};

export const HEADER_LABEL_DEFAULT_SIZE = 16;
export const SCORE_COLUMN_WIDTH = 115;
export const SEGMENT_COLUMN_WIDTH = 208;

export const ENTITY_TYPES_ENUM = {
  OUTLET: 'outlet',
  DIRECT_ACCOUNT: 'direct_account',
};
