import React, { useEffect } from 'react';
import Div100vh from 'react-div-100vh';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import { useDispatch, useSelector } from 'react-redux';

import './styles/App.css';
import { getLogin } from './slices/login';
import LoginPanel from './components/login/loginPanel';
import { ApplicationWrapper } from './containers/application/applicationWrapper';
import useFeatureToggles from './hooks/useFeatureToggles';

const AppContainer = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.app.isLoggedIn);
  useFeatureToggles();

  useEffect(() => {
    // -================ SETTING LICENSES ==================
    LicenseInfo.setLicenseKey(
      '6b8d7fd8b7cdfd6d9e9ae06cdf9037e5Tz03MTQzMixFPTE3MjE5Mjc4MDMwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
    );

    dispatch(getLogin());
  }, []);

  return (
    <div className="App">
      <Div100vh>
        {isLoggedIn ? (
          <div style={{ height: '100%' }}>
            <ApplicationWrapper />
          </div>
        ) : (
          <LoginPanel />
        )}
      </Div100vh>
    </div>
  );
};

export default AppContainer;
