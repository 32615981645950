import React, { useState } from 'react';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import {
  ModalDisplay,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '../generic/modalDisplay';

const StyledLoadingButton = styled(LoadingButton)(
  ({ theme: { themeColors } }) => ({
    textTransform: 'none',
    marginLeft: '8px',
    background: themeColors.maptualListFilteringButtonBackgroundColor,
  })
);

export const ToggleDeleteModal = ({ isModalOpen, onClose, onConfirm }) => {
  const [confirmationText, setConfirmationText] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  const handleInputChange = (event) => {
    const text = event.target.value;
    setConfirmationText(text);
    setIsButtonEnabled(text.toLowerCase() === 'delete');
  };

  return (
    <ModalDisplay
      isOpen={isModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalHeader onClose={handleClose}>
        <Typography variant="h3">Delete Feature Toggle?</Typography>
      </ModalHeader>
      <ModalBody>
        <Typography variant="h3">WARNING</Typography>
        <Typography variant="body2">
          If you are not a member of the engineering team with explicit
          knowledge that the toggle you are about to delete is not used in any
          other part of the application, you should not delete this toggle.
        </Typography>
        <Typography variant="body2">
          Feature toggles can have wide-reaching effects in the application and
          deleting one without knowing the full scope of its use can cause
          service outages on the platform.
        </Typography>
        <input
          type="text"
          placeholder="Type 'delete' to confirm"
          value={confirmationText}
          onChange={handleInputChange}
        />
      </ModalBody>
      <ModalFooter>
        <>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <StyledLoadingButton
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleConfirm}
            disabled={!isButtonEnabled}
          >
            Delete
          </StyledLoadingButton>
        </>
      </ModalFooter>
    </ModalDisplay>
  );
};
