import { v4 as uuidv4 } from 'uuid';
import { formatDateToString } from './common/formatters';

const projectConfigBase = {
  projectName: '',
  productLineName: '',
  productLineId: '',
};

const objectiveBase = {
  objectiveType: {
    features: [],
    typeDisplay: '',
    icon: null,
    typeDescription: '',
    metrics: [],
    params: {
      products: [],
    },
    type: '',
  },
  baskets: [],
  uuid: '',
};

const basketSortKey = {
  TO_PRODUCT: 1,
  PRODUCT_FROM: 2,
  ANALOUGE_PRODUCT: 3,
  PRECURSOR_PRODUCT: 4,
};

export const IMPORT_TYPES = {
  CLONE_WITH_SCORES: 'cloneWithScores',
  CLONE_WITHOUT_SCORES: 'cloneWithoutScores',
};

export const transformExistingToNew = (
  existingProject,
  isCloneWithoutScoreMigration = false
) => {
  const projectConfig = {
    ...projectConfigBase,
    projectName: existingProject.projectName,
    projectImportId: existingProject.projectId,
    productLineId: existingProject.productLineId,
    productLineName: existingProject.productLine,
    dueDate: existingProject.dueDate,
    changeLog: existingProject.changeLog,
    importType: isCloneWithoutScoreMigration
      ? IMPORT_TYPES.CLONE_WITHOUT_SCORES
      : IMPORT_TYPES.CLONE_WITH_SCORES,
  };
  const objectives = existingProject.objectives.map((objective) => ({
    ...objectiveBase,
    ...(objective?.shareMetric?.uuid && objective?.shareMetric?.rxType
      ? { shareMetric: objective.shareMetric }
      : {}),
    nonScoringObjective: !!objective.nonScoringObjective,
    objectiveType: {
      typeDisplay: objective.typeDisplay,
      params: {
        products: objective.params.products.map((param) => ({
          paramType: param.paramType,
          productLine: null,
          paramText: param.paramText,
          productFriendlyName: param.productFriendlyName,
        })),
      },
      type: objective.type,
    },
    baskets: objective.params.products
      .slice()
      .sort((a, b) => basketSortKey[a.paramType] - basketSortKey[b.paramType])
      .map((param, index) => ({
        basketName: param.productFriendlyName,
        basketText: param.paramText,
        basketType: param.paramType,
        basketKey: index,
        uuid: uuidv4(),
        basketScoringWeight: param.basketScoringWeight,
        therapeuticAreas: param.therapeuticAreas || [],
        products: param.productLines,
        indications: param.indications || [],
        specialties: param.specialties || [],
        metrics: setMetricStructure(param),
      })),
    uuid: uuidv4(),
  }));
  return {
    projectConfig,
    objectives,
  };
};

const setMetricStructure = (basketConfig) => {
  if (!basketConfig.entityMetricsScoringWeight) {
    return {
      hcp: basketConfig.metricsScoringWeight
        ? Object.entries(basketConfig.metricsScoringWeight).map(
            ([rxType, scoringWeight]) => ({
              metricRxType: rxType,
              scoringWeight,
              visualize: basketConfig.displayableMetrics
                ? rxType in basketConfig.displayableMetrics
                : true,
            })
          )
        : [],
    };
  }
  const res = {};
  Object.entries(basketConfig.entityMetricsScoringWeight).forEach(
    ([entityName, entityScoringWeights]) => {
      res[entityName] = Object.entries(entityScoringWeights).map(
        ([rxType, scoringWeight]) => ({
          metricRxType: rxType,
          scoringWeight,
          visualize:
            basketConfig?.entityDisplayableMetrics?.[entityName] &&
            rxType in basketConfig.entityDisplayableMetrics[entityName],
        })
      );
    }
  );
  return res;
};

const basketFormDataToPayload = (basket) => ({
  basketScoringWeight: basket.basketScoringWeight,
  metricsScoringWeight: basket.metrics?.hcp
    ? newMetricStructure(basket.metrics.hcp)
    : {},
  entityMetricsScoringWeight: formatEntityMetricsScoringWeight(basket.metrics),
  displayableMetrics: displayableMetricsStructure(
    basket.metrics?.hcp ? basket.metrics.hcp : []
  ),
  entityDisplayableMetrics: entityDisplayableMetricsStructure(basket.metrics),
  specialties: basket.specialties,
  indications: basket.indications,
  therapeuticAreas: basket.therapeuticAreas,
  paramType: basket.basketType,
  paramText: basket.basketText,
  productFriendlyName: basket.basketName,
  productLines: basket.products,
});

export const transformNewToExisting = ({ projectConfig, objectives }) => ({
  projectName: projectConfig.projectName,
  projectImportId: projectConfig.projectImportId || undefined,
  productLineId: projectConfig.productLineId,
  dueDate: formatDateToString(projectConfig.dueDate),
  changeLog: projectConfig.changeLog,
  importType: projectConfig.importType || '',
  objectives: objectives.map((objective) => ({
    type: objective.objectiveType.type,
    typeDisplay: objective.objectiveType.typeDisplay,
    icon: objective.objectiveType.icon,
    ...(objective?.shareMetric?.uuid && objective?.shareMetric?.rxType
      ? { shareMetric: objective.shareMetric }
      : {}),
    nonScoringObjective: objective.nonScoringObjective,
    params: {
      products: objective.baskets.map(basketFormDataToPayload),
    },
  })),
});

export const transformToTemplatePayload = ({ projectConfig, objectives }) => ({
  templateName: projectConfig.projectName,
  productLineId: projectConfig.productLineId,
  marketId: projectConfig.marketId,
  marketName: projectConfig.productLineName,
  objectives: objectives.map((objective) => ({
    type: objective.objectiveType.type,
    typeDisplay: objective.objectiveType.typeDisplay,
    nonScoringObjective: objective.nonScoringObjective,
    baskets: objective.baskets.map(basketFormDataToPayload),
    indications: objective.indications ?? [],
    ...(objective?.shareMetric?.uuid && objective?.shareMetric?.rxType
      ? { shareMetric: objective.shareMetric }
      : {}),
    therapeuticAreas: objective.therapeuticAreas ?? [],
  })),
});

/*
  Useful for performing a diff between the existing template
  and the edited version to see if something changed.
 */
export const transformTemplateEntityToPayload = (template) => {
  const templateCopy = { ...template };
  delete templateCopy.id;
  delete templateCopy.createdAt;
  delete templateCopy.updatedAt;
  templateCopy.objectives = templateCopy.objectives.map(
    ({ shareMetric, ...rest }) => ({
      ...rest,
      ...(shareMetric ? { shareMetric } : {}),
    })
  );
  return templateCopy;
};

const formatEntityMetricsScoringWeight = (entityMetricsScoringWeight) => {
  const newEntityMetricsScoringWeight = {};
  Object.entries(entityMetricsScoringWeight).forEach(([key, value]) => {
    newEntityMetricsScoringWeight[key] = newMetricStructure(value);
  });
  return newEntityMetricsScoringWeight;
};

const newMetricStructure = (metrics) =>
  metrics.reduce((acc, metric) => {
    const { metricRxType, scoringWeight } = metric;
    return {
      ...acc,
      [metricRxType]: scoringWeight,
    };
  }, {});

const displayableMetricsStructure = (metrics) => {
  const metricsToVisualize = metrics.filter((metric) => metric.visualize);

  // Order descending by scoring weight, and alphabetically by metricRxType if a tie
  const orderedMetrics = metricsToVisualize.sort(
    (a, b) =>
      b.scoringWeight - a.scoringWeight ||
      a.metricRxType.localeCompare(b.metricRxType)
  );

  return Object.fromEntries(
    orderedMetrics.map((metric, index) => [metric.metricRxType, index + 1])
  );
};

const entityDisplayableMetricsStructure = (metrics) =>
  Object.entries(metrics).reduce((acc, [metricEntityType, metricList]) => {
    acc[metricEntityType] = displayableMetricsStructure(metricList);
    return acc;
  }, {});
export const templateToProject = (template) => ({
  ...template,
  projectName: template.templateName,
  templateName: template.templateName,
  productLineName: template.marketName,
  objectives: template.objectives.map((objective) => ({
    ...objective,
    params: {
      products: objective.baskets,
    },
  })),
});
