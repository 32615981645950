export const designColors = {
  spacing: {
    'spacing-0-25': { type: 'number', value: 2 },
    'spacing-0-5': { type: 'number', value: 4 },
    'spacing-1-0': { type: 'number', value: 8 },
    'spacing-1-5': { type: 'number', value: 12 },
    'spacing-2-0': { type: 'number', value: 16 },
    'spacing-2-5': { type: 'number', value: 20 },
    'spacing-3-0': { type: 'number', value: 24 },
    'spacing-4-0': { type: 'number', value: 32 },
    'spacing-4-5': { type: 'number', value: 36 },
    'spacing-5-0': { type: 'number', value: 40 },
    'spacing-5-5': { type: 'number', value: 44 },
    'spacing-6-0': { type: 'number', value: 48 },
  },
  color: {
    mixpanel: {
      1: '#336fcc',
      2: '#ea7353',
      3: '#8dd9d3',
      4: '#ecb647',
      5: '#9c5462',
      6: '#7ab5ee',
      7: '#f1ac75',
      8: '#337093',
      9: '#529b6d',
      10: '#f1b6ab',
      11: '#b77ad2',
      12: '#68aca5',
    },
    dark: {
      0: '#15161a',
      5: '#16191f',
      8: '#171b22',
      11: '#191c24',
      16: '#1c2027',
      22: '#1f232c',
      transparent: {
        0: 'rgba(44, 50, 63, 0.0000)',
        1: 'rgba(44, 50, 63, 0.2000)',
        2: 'rgba(44, 50, 63, 0.3000)',
      },
    },
    light: {
      0: '#ffffff',
      2: '#f7f9fc',
      5: '#f0f4fb',
      8: '#e9eff9',
      11: '#e2eaf8',
      12: '#e0e9f7',
      transparent: {
        1: 'rgba(182, 203, 236, 0.1000)',
        2: 'rgba(182, 203, 236, 0.2000)',
        3: 'rgba(182, 203, 236, 0.3000)',
        4: 'rgba(182, 203, 236, 0.4000)',
      },
    },
    blue: {
      0: '#050614',
      10: '#04152f',
      20: '#093271',
      30: '#0d47a0',
      40: '#1a5cc3',
      50: '#336fcc',
      60: '#5c8cd6',
      70: '#85a9e0',
      80: '#adc5eb',
      85: '#c1d3f0',
      90: '#d6e2f5',
      95: '#ebf1fa',
      99: '#fbfcfe',
    },
    neutral: {
      0: '#0a0c10',
      10: '#14181f',
      15: '#252c37',
      20: '#313b49',
      30: '#455368',
      40: '#52627a',
      50: '#5e6f8c',
      60: '#8595ad',
      70: '#a3afc2',
      80: '#c2cad6',
      90: '#e0e4eb',
      95: '#f0f2f5',
      99: '#fcfcfd',
      100: '#ffffff',
      transparent50: {
        0: 'rgba(94, 111, 140, 0.0000)',
        1: 'rgba(94, 111, 140, 0.1000)',
        2: 'rgba(94, 111, 140, 0.2000)',
        3: 'rgba(94, 111, 140, 0.3000)',
        4: 'rgba(102, 122, 153, 0.4000)',
      },
      transparent90: {
        0: 'rgba(224, 228, 235, 0.0000)',
        1: 'rgba(224, 228, 235, 0.1000)',
        2: 'rgba(224, 228, 235, 0.2000)',
        3: 'rgba(224, 228, 235, 0.3000)',
        4: 'rgba(224, 228, 235, 0.4000)',
      },
      transparent20: {
        0: 'rgba(49, 59, 73, 0.0000)',
        1: 'rgba(49, 59, 73, 0.1000)',
        2: 'rgba(49, 59, 73, 0.2000)',
        3: 'rgba(49, 59, 73, 0.3000)',
        4: 'rgba(49, 59, 73, 0.4000)',
      },
    },
    red: {
      0: '#170308',
      10: '#2d060f',
      20: '#5a0c1e',
      30: '#87122d',
      40: '#cb103d',
      50: '#e11e4b',
      60: '#e74b6f',
      70: '#ed7893',
      80: '#f3a5b7',
      90: '#f9d2db',
      95: '#fce9ed',
      99: '#fefafb',
    },
    green: {
      0: '#07130d',
      10: '#0e251a',
      20: '#1d4934',
      30: '#2b6e4e',
      40: '#399368',
      50: '#48b782',
      60: '#6cc69b',
      70: '#91d4b4',
      80: '#b6e2cd',
      90: '#daf1e6',
      95: '#edf8f3',
      99: '#fbfefd',
    },
    yellow: {
      0: '#161104',
      10: '#2b2108',
      20: '#554111',
      30: '#7f621a',
      40: '#a98323',
      50: '#d3a42c',
      60: '#dcb656',
      70: '#e5c880',
      80: '#eddbab',
      90: '#f6edd5',
      95: '#fbf6ea',
      99: '#fefdfb',
    },
    'duo-blue': {
      0: '#00112c',
      10: '#001b46',
      15: '#02245a',
      20: '#033075',
      30: '#083e92',
      40: '#0f50b5',
      50: '#0765c2',
      60: '#167bc5',
      70: '#4294cf',
      80: '#71b1e0',
      85: '#9bd0ee',
      90: '#b6e1f8',
      95: '#d5effd',
      99: '#ebf8ff',
    },
    'duo-purple': {
      0: '#070613',
      10: '#0f0a29',
      15: '#1b0f3d',
      20: '#311862',
      30: '#4e238b',
      40: '#6f2cb1',
      50: '#933fcf',
      60: '#b85cd6',
      70: '#d185e0',
      80: '#e6adeb',
      85: '#ecc1f0',
      90: '#f5d6f5',
      95: '#faebf9',
      99: '#fefbfd',
    },
    'duo-orange': {
      0: '#200b06',
      10: '#33120b',
      15: '#3d1a0f',
      20: '#622b18',
      30: '#883a20',
      40: '#af4624',
      50: '#c15a2a',
      60: '#d7781f',
      70: '#e79c44',
      80: '#f0c56e',
      85: '#eedb9e',
      90: '#f2e6c0',
      95: '#f5f0d6',
      99: '#fefefb',
    },
  },
  border: {
    'radius-0-25': { type: 'number', value: 2 },
    'radius-0-5': { type: 'number', value: 4 },
    'radius-1': { type: 'number', value: 8 },
    'radius-2': { type: 'number', value: 16 },
  },
  dark: {
    alertButton: {
      lowContrast: {
        default: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#a3afc2' },
        hover: { surface: 'rgba(94, 111, 140, 0.1000)', content: '#e0e4eb' },
        active: { surface: 'rgba(94, 111, 140, 0.2000)', content: '#e0e4eb' },
        disabled: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#313b49' },
      },
      highContrast: {
        default: { surface: 'rgba(49, 59, 73, 0.0000)', content: '#e0e4eb' },
        hover: { surface: 'rgba(49, 59, 73, 0.1000)', content: '#e0e4eb' },
        active: { surface: 'rgba(49, 59, 73, 0.2000)', content: '#e0e4eb' },
        disabled: {
          surface: 'rgba(49, 59, 73, 0.0000)',
          content: 'rgba(224, 228, 235, 0.4000)',
        },
      },
      highContrastInverse: {
        default: { surface: 'rgba(224, 228, 235, 0.0000)', content: '#14181f' },
        hover: { surface: 'rgba(224, 228, 235, 0.2000)', content: '#14181f' },
        active: { surface: 'rgba(224, 228, 235, 0.4000)', content: '#14181f' },
        disabled: {
          surface: 'rgba(224, 228, 235, 0.0000)',
          content: 'rgba(49, 59, 73, 0.3000)',
        },
      },
    },
    alerts: {
      primary: {
        info: {
          fill: '#1a5cc3',
          icon: '#e0e4eb',
          title: '#e0e4eb',
          description: '#e0e4eb',
        },
        danger: {
          fill: '#cb103d',
          icon: '#e0e4eb',
          title: '#e0e4eb',
          description: '#e0e4eb',
        },
        caution: {
          fill: '#d3a42c',
          icon: '#14181f',
          title: '#14181f',
          description: '#14181f',
        },
        success: {
          fill: '#399368',
          icon: '#14181f',
          title: '#14181f',
          description: '#14181f',
        },
      },
      secondary: {
        info: {
          fill: '#1f232c',
          border: '#1a5cc3',
          icon: '#5c8cd6',
          title: '#e0e4eb',
          description: '#c2cad6',
        },
        danger: {
          fill: '#1f232c',
          border: '#cb103d',
          icon: '#e74b6f',
          title: '#e0e4eb',
          description: '#c2cad6',
        },
        caution: {
          fill: '#1f232c',
          border: '#d3a42c',
          icon: '#d3a42c',
          title: '#e0e4eb',
          description: '#c2cad6',
        },
        success: {
          fill: '#1f232c',
          border: '#399368',
          icon: '#6cc69b',
          title: '#e0e4eb',
          description: '#c2cad6',
        },
      },
    },
    'anchor-icons': {
      'segment-general': { primary: '#093271', secondary: '#85a9e0' },
      'segment-negative': { primary: '#554111', secondary: '#d3a42c' },
      general: { primary: '#313b49', secondary: '#c2cad6' },
    },
    badge: {
      default: { surface: '#252c37', content: '#a3afc2' },
      active: { surface: '#52627a', content: '#f0f2f5' },
      highlighted: { surface: '#1a5cc3', content: '#f0f2f5' },
    },
    border: { primary: '#252c37', focus: '#85a9e0' },
    checkbox: {
      selected: '#1a5cc3',
      unselected: '#8595ad',
      check: '#fcfcfd',
      disabled: '#313b49',
      checkDisabled: '#52627a',
    },
    chart: {
      lines: '#252c37',
      axisLabel: '#8595ad',
      dataLabel: '#8595ad',
      dataLabelActive: '#e0e4eb',
      legendLabel: '#8595ad',
      title: '#e0e4eb',
      markerLine: '#8595ad',
      markerBorder: '#fcfcfd',
    },
    chip: {
      choice: {
        default: { surface: '#1f232c', content: '#c2cad6', border: '#313b49' },
        hover: { surface: '#252c37', content: '#e0e4eb', border: '#313b49' },
        active: {
          surface: '#313b49',
          content: '#fcfcfd',
          icon: '#fcfcfd',
          border: '#313b49',
        },
        disabled: {
          surface: 'rgba(44, 50, 63, 0.2000)',
          content: '#313b49',
          border: '#313b49',
        },
      },
      input: {
        default: {
          surface: '#1f232c',
          content: '#c2cad6',
          icon: '#c2cad6',
          border: '#313b49',
        },
        hover: {
          surface: '#1f232c',
          content: '#c2cad6',
          icon: '#ffffff',
          border: '#313b49',
        },
        disabled: {
          surface: '#1f232c',
          content: '#c2cad6',
          icon: '#313b49',
          border: '#313b49',
        },
        error: {
          surface: '#1f232c',
          content: '#c2cad6',
          icon: '#c2cad6',
          border: '#e74b6f',
        },
      },
    },
    data: {
      categorical: {
        1: '#336fcc',
        2: '#ea7353',
        3: '#8dd9d3',
        4: '#ecb647',
        5: '#9c5462',
        6: '#7ab5ee',
        7: '#f1ac75',
        8: '#337093',
        9: '#529b6d',
        10: '#f1b6ab',
        11: '#b77ad2',
        12: '#68aca5',
      },
      divergent: {
        1: '#b6e1f8',
        2: '#71b1e0',
        3: '#4294cf',
        4: '#167bc5',
        5: '#0765c2',
        '-1': '#f0c56e',
        '-2': '#e79c44',
        '-3': '#d7781f',
        '-4': '#c15a2a',
        '-5': '#af4624',
      },
      general: {
        1: '#b6e1f8',
        2: '#71b1e0',
        3: '#4294cf',
        4: '#167bc5',
        5: '#0765c2',
      },
      competition: {
        1: '#f0c56e',
        2: '#e79c44',
        3: '#d7781f',
        4: '#c15a2a',
        5: '#af4624',
      },
      precursor: {
        1: '#f5d6f5',
        2: '#e6adeb',
        3: '#d185e0',
        4: '#b85cd6',
        5: '#933fcf',
      },
      unfocused: { 1: '#c2cad6', 2: '#a3afc2', 3: '#8595ad', 4: '#5e6f8c' },
      trend: {
        positive: {
          default: { surface: 'rgba(9, 180, 98, 0.1500)', content: '#09b462' },
          active: { surface: '#09b462', content: '#14181f' },
        },
        negative: {
          default: {
            surface: 'rgba(255, 83, 104, 0.1500)',
            content: '#ff5368',
          },
          active: { surface: '#ff5368', content: '#14181f' },
        },
        none: {
          surface: '#252c37',
          contentPrimary: '#e0e4eb',
          contentSecondary: '#e0e4eb',
        },
      },
    },
    divider: { primary: '#252c37' },
    feedback: {
      info: {
        primary: '#1a5cc3',
        secondary: '#5c8cd6',
        textOnPrimary: '#e0e4eb',
      },
      danger: {
        primary: '#cb103d',
        secondary: '#e74b6f',
        textOnPrimary: '#e0e4eb',
      },
      success: {
        primary: '#399368',
        secondary: '#6cc69b',
        textOnPrimary: '#14181f',
      },
      caution: {
        primary: '#d3a42c',
        secondary: '#dcb656',
        textOnPrimary: '#14181f',
      },
    },
    general: { scrollbar: { track: '#1f232c', button: '#5e6f8c' } },
    HCPListItem: {
      default: {
        surface: '#15161a',
        contentPrimary: '#e0e4eb',
        contentSecondary: '#8595ad',
        icon: '#8595ad',
      },
      hover: {
        surface: '#252c37',
        contentPrimary: '#e0e4eb',
        contentSecondary: '#8595ad',
        icon: '#8595ad',
      },
      active: {
        surface: '#313b49',
        contentPrimary: '#e0e4eb',
        contentSecondary: '#c2cad6',
        icon: '#e0e4eb',
      },
    },
    HCPListItemSidebar: {
      scoreIncrease: '#09b462',
      scoreDecrease: '#ff5368',
      noChangeDefault: '#15161a',
      noChangeActive: '#313b49',
      noChangeHover: '#252c37',
    },
    HCPTimelineItem: {
      activity: {
        surface: '#191c24',
        contentPrimary: '#e0e4eb',
        contentSecondary: '#8595ad',
        iconBackground: '#252c37',
        iconBorder: '#313b49',
        border: '#c2cad6',
      },
      scoreIncrease: {
        surface: '#191c24',
        contentPrimary: '#e0e4eb',
        contentSecondary: '#8595ad',
        iconBackground: '#252c37',
        iconBorder: '#313b49',
        border: '#09b462',
      },
      scoreDecrease: {
        surface: '#191c24',
        contentPrimary: '#e0e4eb',
        contentSecondary: '#8595ad',
        iconBackground: '#252c37',
        iconBorder: '#313b49',
        border: '#e11e4b',
      },
    },
    inputs: {
      primary: {
        default: { surface: '#0d47a0', content: '#d6e2f5' },
        hover: { surface: '#1a5cc3', content: '#ebf1fa' },
        focused: { surface: '#1a5cc3', content: '#ebf1fa', border: '#fbfcfe' },
        active: { surface: '#1a5cc3', content: '#d6e2f5' },
        disabled: { surface: 'rgba(44, 50, 63, 0.2000)', content: '#313b49' },
      },
      secondary: {
        default: {
          surface: '#1f232c',
          content: '#c2cad6',
          iconPrimary: '#c2cad6',
          iconSecondary: '#5e6f8c',
          border: '#313b49',
        },
        hover: {
          surface: '#252c37',
          content: '#e0e4eb',
          iconPrimary: '#e0e4eb',
          iconSecondary: '#5e6f8c',
          border: '#313b49',
        },
        focus: {
          surface: '#313b49',
          content: '#e0e4eb',
          iconPrimary: '#e0e4eb',
          iconSecondary: '#5e6f8c',
          border: '#e0e4eb',
        },
        active: {
          surface: '#313b49',
          content: '#fcfcfd',
          iconPrimary: '#fcfcfd',
          iconSecondary: '#5e6f8c',
          border: '#313b49',
        },
        disabled: { surface: 'rgba(44, 50, 63, 0.2000)', content: '#313b49' },
      },
      danger: {
        default: { surface: '#5a0c1e', content: '#f3a5b7', border: '#87122d' },
        hover: { surface: '#87122d', content: '#f9d2db', border: '#87122d' },
        focus: { surface: '#87122d', content: '#fce9ed', border: '#fefafb' },
        active: { surface: '#87122d', content: '#fce9ed', border: '#fefafb' },
        disabled: { surface: 'rgba(44, 50, 63, 0.2000)', content: '#313b49' },
      },
      dangerUtility: {
        default: { content: '#e74b6f' },
        hover: { surface: '#5a0c1e', content: '#f3a5b7', border: '#5a0c1e' },
        focus: { surface: '#5a0c1e', content: '#f3a5b7', border: '#fefafb' },
        active: { surface: '#5a0c1e', content: '#f9d2db', border: '#5a0c1e' },
        disabled: { content: '#313b49' },
      },
      utility: {
        default: {
          surface: 'rgba(94, 111, 140, 0.1000)',
          content: '#a3afc2',
          border: 'rgba(94, 111, 140, 0.1000)',
        },
        hover: {
          surface: 'rgba(94, 111, 140, 0.1000)',
          content: '#e0e4eb',
          border: 'rgba(94, 111, 140, 0.1000)',
        },
        focus: { surface: '#313b49', content: '#e0e4eb', border: '#e0e4eb' },
        active: {
          surface: 'rgba(94, 111, 140, 0.2000)',
          content: '#fcfcfd',
          border: 'rgba(94, 111, 140, 0.2000)',
        },
        disabled: { surface: 'rgba(44, 50, 63, 0.2000)', content: '#313b49' },
      },
    },
    loadingSpinner: { primary: '#336fcc' },
    powerscore: {
      text: '#0a0c10',
      border: '#171b22',
      legendary: { primary: '#ff800b', secondary: '#ffb168' },
      artifact: { primary: '#f0d438', secondary: '#ffef94' },
      epic: { primary: '#ac39f4', secondary: '#d38dff' },
      rare: { primary: '#2e6ef9', secondary: '#6fa2f1' },
      common: { primary: '#9e9e9e', secondary: '#c5c5c5' },
      zero: { primary: '#262626', secondary: '#888888' },
      'no-score': { primary: '#888888', secondary: '#171b22' },
    },
    progressBar: {
      barProgress: '#336fcc',
      barSurface: '#252c37',
      primaryMetric: '#e0e4eb',
      secondaryMetric: '#8595ad',
    },
    radio: { selected: '#1a5cc3', unselected: '#8595ad', disabled: '#313b49' },
    rankedCategoricalScale: {
      activeChevron: '#e0e4eb',
      category: '#e0e4eb',
      categoryLabel: '#8595ad',
    },
    steppers: {
      notStarted: {
        icon: '#455368',
        stepNumber: '#15161a',
        title: '#8595ad',
        description: '#313b49',
      },
      autoStart: { icon: '#5c8cd6', title: '#e0e4eb', description: '#8595ad' },
      manualStart: {
        icon: '#5c8cd6',
        stepNumber: '#15161a',
        title: '#e0e4eb',
        description: '#8595ad',
      },
      stepComplete: {
        icon: '#5c8cd6',
        title: '#e0e4eb',
        description: '#8595ad',
      },
      stepperComplete: {
        icon: '#6cc69b',
        title: '#e0e4eb',
        description: '#8595ad',
      },
      error: { icon: '#e74b6f', title: '#e0e4eb', description: '#8595ad' },
      caution: { icon: '#dcb656', title: '#e0e4eb', description: '#8595ad' },
      divider: { divider: '#313b49' },
    },
    surface: {
      page: '#15161a',
      card: '#171b22',
      panel: '#171b22',
      modal: '#16191f',
      popover: '#16191f',
      empty: '#1f232c',
    },
    switch: {
      selected: '#1a5cc3',
      unselected: '#8595ad',
      check: '#fcfcfd',
      disabled: '#313b49',
      checkDisabled: '#52627a',
    },
    tab: {
      default: { content: '#c2cad6' },
      hover: { indicator: '#455368', content: '#e0e4eb' },
      active: { indicator: '#5c8cd6', content: '#e0e4eb' },
    },
    tabObjective: {
      default: {
        surface: '#1f232c',
        content: '#c2cad6',
        iconPrimary: '#c2cad6',
        iconSecondary: '#5e6f8c',
        border: '#313b49',
      },
      hover: {
        surface: '#252c37',
        content: '#e0e4eb',
        iconPrimary: '#e0e4eb',
        iconSecondary: '#5e6f8c',
        border: '#313b49',
      },
      active: {
        surface: '#313b49',
        content: '#fcfcfd',
        iconPrimary: '#fcfcfd',
        iconSecondary: '#5e6f8c',
        border: '#313b49',
      },
    },
    table: {
      surface: 'rgba(94, 111, 140, 0.0000)',
      headerBorder: '#455368',
      bodyBorder: '#313b49',
      headerContent: '#8595ad',
      headerContentHighlight: '#c2cad6',
      row: {
        default: { surface: 'rgba(94, 111, 140, 0.0000)' },
        hover: { surface: '#252c37', content: '#e0e4eb' },
        selected: { surface: '#313b49', content: '#e0e4eb' },
        disabled: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#313b49' },
      },
      footerContent: '#e0e4eb',
      cell: {
        default: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#e0e4eb' },
        hover: { surface: '#252c37', content: '#e0e4eb' },
        selected: { surface: '#313b49', content: '#e0e4eb' },
        editing: {
          surface: 'rgba(94, 111, 140, 0.0000)',
          content: '#e0e4eb',
          border: '#5c8cd6',
        },
        disabled: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#313b49' },
        highlighted: { surface: '#252c37', content: '#e0e4eb' },
      },
      rowCard: {
        default: { surface: '#16191f', border: '#313b49' },
        highlight: { surface: '#252c37' },
        hover: { surface: '#252c37', content: '#e0e4eb' },
        selected: { surface: '#313b49', content: '#e0e4eb' },
        disabled: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#313b49' },
      },
      cellHeader: {
        default: {
          surface: 'rgba(94, 111, 140, 0.0000)',
          content: '#e0e4eb',
          contentSecondary: '#8595ad',
        },
        hover: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#e0e4eb' },
        selected: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#e0e4eb' },
        disabled: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#313b49' },
        highlighted: { surface: '#252c37', content: '#e0e4eb' },
      },
    },
    text: {
      infoLink: { primary: '#5c8cd6', secondary: '#d6e2f5' },
      general: {
        primary: '#e0e4eb',
        secondary: '#c2cad6',
        tertiary: '#8595ad',
        highlight: '#85a9e0',
        disabled: '#313b49',
        empty: '#8595ad',
      },
      success: { primary: '#6cc69b', secondary: '#daf1e6' },
      caution: { primary: '#dcb656', secondary: '#f6edd5' },
      danger: { primary: '#e74b6f', secondary: '#f9d2db' },
    },
    textFieldSelections: {
      default: {
        border: '#8595ad',
        text: '#e0e4eb',
        icon: '#e0e4eb',
        labelText: '#8595ad',
        helperText: '#8595ad',
        requiredTag: '#8595ad',
      },
      hover: {
        border: '#e0e4eb',
        text: '#e0e4eb',
        icon: '#e0e4eb',
        labelText: '#8595ad',
        helperText: '#8595ad',
        requiredTag: '#8595ad',
      },
      active: {
        border: '#5c8cd6',
        text: '#e0e4eb',
        icon: '#e0e4eb',
        labelText: '#5c8cd6',
        helperText: '#5c8cd6',
        requiredTag: '#5c8cd6',
      },
      error: {
        border: '#e74b6f',
        text: '#e0e4eb',
        icon: '#e0e4eb',
        labelText: '#e74b6f',
        helperText: '#e74b6f',
        requiredTag: '#e74b6f',
        alertIcon: '#e74b6f',
      },
      disabled: {
        border: '#455368',
        text: '#313b49',
        icon: '#313b49',
        labelText: '#313b49',
        helperText: '#313b49',
        requiredTag: '#313b49',
      },
    },
    shadow: { 'level-1': 'rgba(44, 50, 63, 0.2000)' },
    tooltip: { surface: '#313b49', content: '#e0e4eb' },
    notificationBadge: { surface: '#e0e4eb', content: '#14181f' },
  },
  light: {
    alertButton: {
      lowContrast: {
        default: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#5e6f8c' },
        hover: { surface: 'rgba(182, 203, 236, 0.2000)', content: '#455368' },
        active: { surface: 'rgba(182, 203, 236, 0.3000)', content: '#14181f' },
        disabled: {
          surface: 'rgba(182, 203, 236, 0.1000)',
          content: '#a3afc2',
        },
      },
      highContrast: {
        default: { surface: 'rgba(49, 59, 73, 0.0000)', content: '#e0e4eb' },
        hover: { surface: 'rgba(49, 59, 73, 0.1000)', content: '#e0e4eb' },
        active: { surface: 'rgba(49, 59, 73, 0.2000)', content: '#e0e4eb' },
        disabled: { surface: 'rgba(49, 59, 73, 0.0000)', content: '#313b49' },
      },
      highContrastInverse: {
        default: { surface: 'rgba(224, 228, 235, 0.0000)', content: '#14181f' },
        hover: { surface: 'rgba(224, 228, 235, 0.2000)', content: '#14181f' },
        active: { surface: 'rgba(224, 228, 235, 0.4000)', content: '#14181f' },
        disabled: {
          surface: 'rgba(224, 228, 235, 0.0000)',
          content: 'rgba(49, 59, 73, 0.3000)',
        },
      },
    },
    alerts: {
      primary: {
        info: {
          fill: '#1a5cc3',
          icon: '#e0e4eb',
          title: '#e0e4eb',
          description: '#e0e4eb',
        },
        danger: {
          fill: '#cb103d',
          icon: '#e0e4eb',
          title: '#e0e4eb',
          description: '#e0e4eb',
        },
        caution: {
          fill: '#dcb656',
          icon: '#14181f',
          title: '#14181f',
          description: '#14181f',
        },
        success: {
          fill: '#48b782',
          icon: '#14181f',
          title: '#14181f',
          description: '#14181f',
        },
      },
      secondary: {
        info: {
          fill: '#ffffff',
          border: '#336fcc',
          icon: '#336fcc',
          title: '#14181f',
          description: '#5e6f8c',
        },
        danger: {
          fill: '#ffffff',
          border: '#e11e4b',
          icon: '#e74b6f',
          title: '#14181f',
          description: '#5e6f8c',
        },
        caution: {
          fill: '#ffffff',
          border: '#dcb656',
          icon: '#d3a42c',
          title: '#14181f',
          description: '#455368',
        },
        success: {
          fill: '#ffffff',
          border: '#48b782',
          icon: '#48b782',
          title: '#14181f',
          description: '#667a99',
        },
      },
    },
    'anchor-icons': {
      'segment-general': { primary: '#c1d3f0', secondary: '#0d47a0' },
      'segment-negative': { primary: '#eddbab', secondary: '#7f621a' },
      general: { primary: '#e0e4eb', secondary: '#455368' },
    },
    badge: {
      default: { surface: '#e0e4eb', content: '#52627a' },
      active: { surface: '#52627a', content: '#f0f2f5' },
      highlighted: { surface: '#1a5cc3', content: '#f0f2f5' },
    },
    border: { primary: '#e0e4eb', focus: '#1a5cc3' },
    checkbox: {
      selected: '#1a5cc3',
      unselected: '#8595ad',
      check: '#fcfcfd',
      disabled: '#c2cad6',
      checkDisabled: '#8595ad',
    },
    chart: {
      lines: '#e0e4eb',
      axisLabel: '#5e6f8c',
      dataLabel: '#5e6f8c',
      dataLabelActive: '#14181f',
      legendLabel: '#5e6f8c',
      title: '#14181f',
      markerLine: '#5e6f8c',
      markerBorder: '#14181f',
    },
    chip: {
      choice: {
        default: { surface: '#f0f4fb', content: '#52627a', border: '#c2cad6' },
        hover: { surface: '#e9eff9', content: '#04152f', border: '#c2cad6' },
        active: {
          surface: '#d6e2f5',
          content: '#093271',
          icon: '#093271',
          border: '#adc5eb',
        },
        disabled: {
          surface: 'rgba(182, 203, 236, 0.1000)',
          content: '#a3afc2',
          border: '#a3afc2',
        },
      },
      input: {
        default: {
          surface: '#f0f4fb',
          content: '#52627a',
          icon: '#52627a',
          border: '#c2cad6',
        },
        hover: {
          surface: '#f0f4fb',
          content: '#52627a',
          icon: '#050614',
          border: '#c2cad6',
        },
        disabled: {
          surface: '#f0f4fb',
          content: '#52627a',
          icon: '#a3afc2',
          border: '#c2cad6',
        },
        error: {
          surface: '#f0f4fb',
          content: '#52627a',
          icon: '#52627a',
          border: '#e74b6f',
        },
      },
    },
    data: {
      categorical: {
        1: '#336fcc',
        2: '#ea7353',
        3: '#8dd9d3',
        4: '#ecb647',
        5: '#9c5462',
        6: '#7ab5ee',
        7: '#f1ac75',
        8: '#337093',
        9: '#529b6d',
        10: '#f1b6ab',
        11: '#b77ad2',
        12: '#68aca5',
      },
      divergent: {
        1: '#083e92',
        2: '#0f50b5',
        3: '#0765c2',
        4: '#167bc5',
        5: '#4294cf',
        '-1': '#622b18',
        '-2': '#883a20',
        '-3': '#af4624',
        '-4': '#c15a2a',
        '-5': '#d7781f',
      },
      general: {
        1: '#4294cf',
        2: '#167bc5',
        3: '#0765c2',
        4: '#0f50b5',
        5: '#083e92',
      },
      competition: {
        1: '#d7781f',
        2: '#c15a2a',
        3: '#af4624',
        4: '#883a20',
        5: '#622b18',
      },
      precursor: {
        1: '#b85cd6',
        2: '#933fcf',
        3: '#6f2cb1',
        4: '#4e238b',
        5: '#311862',
      },
      unfocused: { 1: '#5e6f8c', 2: '#52627a', 3: '#455368', 4: '#313b49' },
      trend: {
        positive: {
          default: { surface: '#daf1e6', content: '#2b6e4e' },
          active: { surface: '#2b6e4e', content: '#f0f2f5' },
        },
        negative: {
          default: { surface: 'rgba(178, 27, 63, 0.1500)', content: '#b21b3f' },
          active: { surface: '#b21b3f', content: '#f0f2f5' },
        },
        none: {
          surface: '#e0e4eb',
          contentPrimary: '#14181f',
          contentSecondary: '#14181f',
        },
      },
    },
    divider: { primary: '#e0e4eb' },
    feedback: {
      info: {
        primary: '#1a5cc3',
        secondary: '#5c8cd6',
        textOnPrimary: '#e0e4eb',
      },
      danger: {
        primary: '#cb103d',
        secondary: '#e74b6f',
        textOnPrimary: '#14181f',
      },
      success: {
        primary: '#399368',
        secondary: '#6cc69b',
        textOnPrimary: '#14181f',
      },
      caution: {
        primary: '#d3a42c',
        secondary: '#dcb656',
        textOnPrimary: '#14181f',
      },
    },
    general: { scrollbar: { track: '#e0e4eb', button: '#5e6f8c' } },
    HCPListItem: {
      default: {
        surface: '#ffffff',
        contentPrimary: '#14181f',
        contentSecondary: '#5e6f8c',
        icon: '#5e6f8c',
      },
      hover: {
        surface: '#e9eff9',
        contentPrimary: '#04152f',
        contentSecondary: '#5e6f8c',
        icon: '#5e6f8c',
      },
      active: {
        surface: '#d6e2f5',
        contentPrimary: '#14181f',
        contentSecondary: '#455368',
        icon: '#093271',
      },
    },
    HCPListItemSidebar: {
      scoreIncrease: '#2b6e4e',
      scoreDecrease: '#b21b3f',
      noChangeDefault: '#ffffff',
      noChangeActive: '#d6e2f5',
      noChangeHover: '#e9eff9',
    },
    HCPTimelineItem: {
      activity: {
        surface: '#f7f9fc',
        contentPrimary: '#14181f',
        contentSecondary: '#5e6f8c',
        iconBackground: '#f0f2f5',
        iconBorder: '#c2cad6',
        border: '#c2cad6',
      },
      scoreIncrease: {
        surface: '#f7f9fc',
        contentPrimary: '#14181f',
        contentSecondary: '#5e6f8c',
        iconBackground: '#f0f2f5',
        iconBorder: '#e0e4eb',
        border: '#2b6e4e',
      },
      scoreDecrease: {
        surface: '#f7f9fc',
        contentPrimary: '#14181f',
        contentSecondary: '#5e6f8c',
        iconBackground: '#f0f2f5',
        iconBorder: '#e0e4eb',
        border: '#e11e4b',
      },
    },
    inputs: {
      primary: {
        default: { surface: '#0d47a0', content: '#d6e2f5' },
        hover: { surface: '#1a5cc3', content: '#d6e2f5' },
        focused: { surface: '#1a5cc3', content: '#d6e2f5', border: '#14181f' },
        active: { surface: '#1a5cc3', content: '#d6e2f5' },
        disabled: {
          surface: 'rgba(182, 203, 236, 0.1000)',
          content: '#a3afc2',
        },
      },
      secondary: {
        default: {
          surface: '#f0f4fb',
          content: '#52627a',
          iconPrimary: '#04152f',
          iconSecondary: '#8595ad',
          border: '#c2cad6',
        },
        hover: {
          surface: '#e9eff9',
          content: '#455368',
          iconPrimary: '#455368',
          iconSecondary: '#a3afc2',
          border: '#c2cad6',
        },
        focus: {
          surface: '#e0e9f7',
          content: '#14181f',
          iconPrimary: '#14181f',
          iconSecondary: '#8595ad',
          border: '#14181f',
        },
        active: {
          surface: '#d6e2f5',
          content: '#093271',
          iconPrimary: '#093271',
          iconSecondary: '#5e6f8c',
          border: '#adc5eb',
        },
        disabled: {
          surface: 'rgba(182, 203, 236, 0.1000)',
          content: '#a3afc2',
        },
      },
      danger: {
        default: { surface: '#fce9ed', content: '#87122d', border: '#f3a5b7' },
        hover: { surface: '#f9d2db', content: '#87122d', border: '#f3a5b7' },
        focus: { surface: '#fce9ed', content: '#87122d', border: '#5a0c1e' },
        active: { surface: '#f9d2db', content: '#87122d', border: '#f9d2db' },
        disabled: {
          surface: 'rgba(182, 203, 236, 0.1000)',
          content: '#a3afc2',
        },
      },
      dangerUtility: {
        default: { content: '#e11e4b' },
        hover: { surface: '#fce9ed', content: '#cb103d', border: '#fce9ed' },
        focus: { surface: '#fce9ed', content: '#87122d', border: '#5a0c1e' },
        active: { surface: '#fce9ed', content: '#87122d', border: '#fce9ed' },
        disabled: { content: '#a3afc2' },
      },
      utility: {
        default: { surface: '#e0e9f7', content: '#5e6f8c', border: '#adc5eb' },
        hover: {
          surface: 'rgba(182, 203, 236, 0.2000)',
          content: '#455368',
          border: 'rgba(182, 203, 236, 0.2000)',
        },
        focus: { surface: '#e0e9f7', content: '#14181f', border: '#14181f' },
        active: {
          surface: 'rgba(182, 203, 236, 0.3000)',
          content: '#093271',
          border: 'rgba(182, 203, 236, 0.3000)',
        },
        disabled: {
          surface: 'rgba(182, 203, 236, 0.1000)',
          content: '#a3afc2',
        },
      },
    },
    loadingSpinner: { primary: '#336fcc' },
    powerscore: {
      text: '#0a0c10',
      border: '#ffffff',
      legendary: { primary: '#ff800b', secondary: '#ffb168' },
      artifact: { primary: '#f0d438', secondary: '#ffef94' },
      epic: { primary: '#ac39f4', secondary: '#d38dff' },
      rare: { primary: '#2e6ef9', secondary: '#6fa2f1' },
      common: { primary: '#9e9e9e', secondary: '#c5c5c5' },
      zero: { primary: '#262626', secondary: '#888888' },
      'no-score': { primary: '#888888', secondary: '#171b22' },
    },
    progressBar: {
      barProgress: '#336fcc',
      barSurface: '#e0e4eb',
      primaryMetric: '#14181f',
      secondaryMetric: '#8595ad',
    },
    radio: { selected: '#1a5cc3', unselected: '#8595ad', disabled: '#c2cad6' },
    rankedCategoricalScale: {
      activeChevron: '#14181f',
      category: '#14181f',
      categoryLabel: '#5e6f8c',
    },
    steppers: {
      notStarted: {
        icon: '#8595ad',
        stepNumber: '#ffffff',
        title: '#5e6f8c',
        description: '#a3afc2',
      },
      autoStart: { icon: '#5c8cd6', title: '#14181f', description: '#5e6f8c' },
      manualStart: {
        icon: '#5c8cd6',
        stepNumber: '#ffffff',
        title: '#14181f',
        description: '#5e6f8c',
      },
      stepComplete: {
        icon: '#5c8cd6',
        title: '#14181f',
        description: '#5e6f8c',
      },
      stepperComplete: {
        icon: '#6cc69b',
        title: '#14181f',
        description: '#5e6f8c',
      },
      error: { icon: '#e74b6f', title: '#14181f', description: '#5e6f8c' },
      caution: { icon: '#dcb656', title: '#14181f', description: '#5e6f8c' },
      divider: { divider: '#c2cad6' },
    },
    surface: {
      page: '#ffffff',
      card: '#f7f9fc',
      panel: '#f0f4fb',
      modal: '#ffffff',
      popover: '#f0f4fb',
      empty: '#e0e9f7',
    },
    switch: {
      selected: '#1a5cc3',
      unselected: '#8595ad',
      check: '#fcfcfd',
      disabled: '#c2cad6',
      checkDisabled: '#8595ad',
    },
    tab: {
      default: { content: '#455368' },
      hover: { indicator: '#a3afc2', content: '#14181f' },
      active: { indicator: '#5c8cd6', content: '#14181f' },
    },
    tabObjective: {
      default: {
        surface: '#f0f4fb',
        content: '#52627a',
        iconPrimary: '#04152f',
        iconSecondary: '#8595ad',
        border: '#c2cad6',
      },
      hover: {
        surface: '#e9eff9',
        content: '#04152f',
        iconPrimary: '#04152f',
        iconSecondary: '#a3afc2',
        border: '#c2cad6',
      },
      active: {
        surface: '#d6e2f5',
        content: '#093271',
        iconPrimary: '#093271',
        iconSecondary: '#5e6f8c',
        border: '#85a9e0',
      },
    },
    table: {
      surface: 'rgba(94, 111, 140, 0.0000)',
      headerBorder: '#c2cad6',
      bodyBorder: '#e0e4eb',
      headerContent: '#5e6f8c',
      headerContentHighlight: '#455368',
      row: {
        default: { surface: 'rgba(94, 111, 140, 0.0000)' },
        hover: { surface: '#e9eff9', content: '#14181f' },
        selected: { surface: '#d6e2f5', content: '#14181f' },
        disabled: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#a3afc2' },
      },
      footerContent: '#14181f',
      cell: {
        default: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#14181f' },
        hover: { surface: '#e9eff9', content: '#14181f' },
        selected: { surface: '#d6e2f5', content: '#14181f' },
        editing: {
          surface: 'rgba(94, 111, 140, 0.0000)',
          content: '#14181f',
          border: '#1a5cc3',
        },
        disabled: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#a3afc2' },
        highlighted: { surface: '#e9eff9', content: '#14181f' },
      },
      rowCard: {
        default: { surface: '#f0f4fb', border: '#313b49' },
        highlight: { surface: '#e9eff9' },
        hover: { surface: '#e9eff9', content: '#14181f' },
        selected: { surface: '#d6e2f5', content: '#14181f' },
        disabled: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#a3afc2' },
      },
      cellHeader: {
        default: {
          surface: 'rgba(94, 111, 140, 0.0000)',
          content: '#14181f',
          contentSecondary: '#5e6f8c',
        },
        hover: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#14181f' },
        selected: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#14181f' },
        disabled: { surface: 'rgba(94, 111, 140, 0.0000)', content: '#a3afc2' },
        highlighted: { surface: '#e9eff9', content: '#14181f' },
      },
    },
    text: {
      infoLink: { primary: '#336fcc', secondary: '#093271' },
      general: {
        primary: '#14181f',
        secondary: '#455368',
        tertiary: '#5e6f8c',
        highlight: '#336fcc',
        disabled: '#a3afc2',
        empty: '#455368',
      },
      success: { primary: '#48b782', secondary: '#1d4934' },
      caution: { primary: '#a98323', secondary: '#554111' },
      danger: { primary: '#cb103d', secondary: '#5a0c1e' },
    },
    textFieldSelections: {
      default: {
        border: '#a3afc2',
        text: '#14181f',
        icon: '#14181f',
        labelText: '#5e6f8c',
        helperText: '#5e6f8c',
        requiredTag: '#5e6f8c',
      },
      hover: {
        border: '#455368',
        text: '#14181f',
        icon: '#14181f',
        labelText: '#5e6f8c',
        helperText: '#5e6f8c',
        requiredTag: '#5e6f8c',
      },
      active: {
        border: '#336fcc',
        text: '#14181f',
        icon: '#14181f',
        labelText: '#336fcc',
        helperText: '#336fcc',
        requiredTag: '#336fcc',
      },
      error: {
        border: '#e11e4b',
        text: '#14181f',
        icon: '#14181f',
        labelText: '#e11e4b',
        helperText: '#e11e4b',
        requiredTag: '#e11e4b',
        alertIcon: '#e11e4b',
      },
      disabled: {
        border: '#a3afc2',
        text: '#a3afc2',
        icon: '#a3afc2',
        labelText: '#a3afc2',
        helperText: '#a3afc2',
        requiredTag: '#a3afc2',
      },
    },
    shadow: { 'level-1': 'rgba(44, 50, 63, 0.0000)' },
    tooltip: { surface: '#313b49', content: '#e0e4eb' },
    notificationBadge: { surface: '#455368', content: '#fcfcfd' },
  },
};
