import React, { useEffect, useState, useContext } from 'react';
import styled from '@emotion/styled';
import {
  Tooltip,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { withStyles } from '@mui/styles';
import { FieldContext } from '../../../containers/application/appViews/field/fieldContext';
import { trackPreCallInsightFeedback } from '../../../trackers/mixpanel';
import { SnackbarDisplay } from '../../generic/snackbar';

const FeedbackWrapper = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'row',
  borderRadius: '4px',
  border: `1px solid ${themeColors.contentCardBorderColor}`,
  padding: '8px 16px',
  alignItems: 'center',
  alignSelf: 'flex-end',
  width: 'fit-content',
  gap: 16,
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  width: 'fit-content',
  height: 40,
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
}));

const StyledToggleButton = styled(ToggleButton)(
  ({ theme: { themeColors } }) => ({
    width: 40,
    height: 40,
    backgroundColor: themeColors.contentCardBackgroundColor,
    border: 'none',
    borderRadius: '4px',
    '&.MuiToggleButtonGroup-lastButton': {
      border: 'none',
    },
  })
);

const StyledTypography = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tertiaryColor,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '120%',
}));

const StyledSnackbarTitle = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    textAlign: 'left',
    color: themeColors.alertPrimaryInfoTitleColor,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',
    marginBottom: '4px',
  })
);

const StyledSnackbarBody = styled(Typography)(({ theme: { themeColors } }) => ({
  textAlign: 'left',
  color: themeColors.alertPrimaryInfoDescriptionColor,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '120%',
}));

const SELECTION_STATES = {
  HELPFUL: 'HELPFUL',
  NOT_HELPFUL: 'NOT HELPFUL',
  NONE: 'NONE',
};

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: '14px',
    color: theme.themeColors.buttonHoverContentColor,
    backgroundColor: theme.themeColors.buttonHoverBorderColor,
  },
  arrow: {
    color: theme.themeColors.buttonHoverBorderColor,
  },
}))(Tooltip);

const GenericTooltip = ({ title, children }) => (
  <StyledTooltip
    title={title}
    placement="top"
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
        ],
      },
    }}
    arrow
  >
    {children}
  </StyledTooltip>
);

export const FeedbackBox = ({ insight, objective, entityId }) => {
  const { project } = useContext(FieldContext);
  const [snackPack, setSnackPack] = useState([]);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [selectionState, setSelectionState] = useState(SELECTION_STATES.NONE);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setIsSnackBarOpen(true);
    } else if (snackPack.length && messageInfo && isSnackBarOpen) {
      // Close an active snack when a new one is added
      setIsSnackBarOpen(false);
    }
  }, [snackPack, messageInfo, isSnackBarOpen]);

  const updateSelection = ({ selection }) => {
    trackPreCallInsightFeedback({
      userInput: selection,
      entityId,
      project,
      objective,
      insight,
    });
    setSelectionState(selection);
  };

  const handleFeedbackButtonClick = (event, selection) => {
    const newSelection = selection || SELECTION_STATES.NONE;
    if (newSelection === SELECTION_STATES.NONE) {
      setIsSnackBarOpen(false);
    }
    if (
      newSelection === SELECTION_STATES.HELPFUL ||
      newSelection === SELECTION_STATES.NOT_HELPFUL
    ) {
      const newMessage = `Insight marked as
        ${selection === SELECTION_STATES.NOT_HELPFUL ? ' not ' : ' '}
        helpful.`;
      setSnackPack((prev) => [
        ...prev,
        { message: newMessage, key: new Date().getTime() },
      ]);
    }
    updateSelection({ selection: newSelection });
  };

  return (
    <>
      <FeedbackWrapper>
        <StyledTypography>Is this insight helpful?</StyledTypography>
        <StyledToggleButtonGroup
          value={selectionState}
          exclusive
          onChange={handleFeedbackButtonClick}
        >
          <GenericTooltip title="Helpful">
            <StyledToggleButton
              value={SELECTION_STATES.HELPFUL}
              variant="contained"
            >
              {selectionState === SELECTION_STATES.HELPFUL ? (
                <ThumbUpAltIcon />
              ) : (
                <ThumbUpOffAltIcon />
              )}
            </StyledToggleButton>
          </GenericTooltip>
          <GenericTooltip title="Not Helpful">
            <StyledToggleButton
              value={SELECTION_STATES.NOT_HELPFUL}
              variant="contained"
            >
              {selectionState === SELECTION_STATES.NOT_HELPFUL ? (
                <ThumbDownAltIcon />
              ) : (
                <ThumbDownOffAltIcon />
              )}
            </StyledToggleButton>
          </GenericTooltip>
        </StyledToggleButtonGroup>
      </FeedbackWrapper>
      <SnackbarDisplay
        key={
          messageInfo
            ? `precall-feedback-${entityId}-${messageInfo.key}`
            : undefined
        }
        isOpen={isSnackBarOpen}
        handleClose={() => setIsSnackBarOpen(false)}
        handleUndo={() =>
          handleFeedbackButtonClick(null, SELECTION_STATES.NONE)
        }
        handleExited={() => {
          setMessageInfo(undefined);
        }}
        message={
          messageInfo ? (
            <>
              <StyledSnackbarTitle>{messageInfo.message}</StyledSnackbarTitle>
              <StyledSnackbarBody>
                Your feedback helps us improve future insights.
              </StyledSnackbarBody>
            </>
          ) : undefined
        }
      />
    </>
  );
};
