import React from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import SearchResults from '../../components/headoffice/searchResults';
import { closeSearch } from '../../actions/headOfficeViewActions';
import EmptyView from '../../components/emptyview/emptyView';
import { getNationalMaptualList } from '../../selectors/lists';
import { ROUTES } from '../../constants';
import { isScoredHcp } from '../../components/headoffice/tableview/tableView';

const mapStateToProps = (state) => ({
  keyword: state.projectSearch.keyword,
  rows: state.projectSearch.rows,
  columnPreferences: state.app.profile.preference.columnPreferences,
  projectRegions: state.projectViewer.projectRegions,
  columns: state.projectViewer.tableColumns,
  isLoading: state.projectSearch.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onCloseSearch: () => dispatch(closeSearch()),
});

const ProjectSearchResultsContainer = ({
  keyword,
  rows,
  columns,
  onCloseSearch,
  columnPreferences,
  projectRegions,
  isLoading,
}) => {
  const match = useRouteMatch(`${ROUTES.SPHERE}${ROUTES.PROJECTS}/:projectId`);
  const handleRowClick = ({ id: entityId, row }) => {
    if (!isScoredHcp(row.score)) return;
    const nationalMaptualList = getNationalMaptualList(projectRegions);

    window.open(
      `${match.url}/entities/${entityId}/maptualList/${nationalMaptualList.maptualListId}/${nationalMaptualList.label}`
    );
  };

  if (isLoading) return <EmptyView isLoading />;
  return (
    <SearchResults
      columns={columns}
      keyword={keyword}
      hcpList={rows || []}
      onCloseSearch={onCloseSearch}
      columnVisibilityModel={
        columnPreferences[match?.params?.projectId]?.visibility
      }
      columnOrderModel={columnPreferences[match?.params?.projectId]?.order}
      onRowClick={handleRowClick}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectSearchResultsContainer);
