import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Box, Typography, Divider } from '@mui/material';
import { useEntityOverviewContext } from './data/useEntityOverviewContext';
import { EntityOverviewContext } from './data/entityOverviewContext';
import { RegionSelection } from '../application/appViews/field/navigation/regionSelection/RegionSelection';
import { EntityTable } from './components/EntityTable';
import { EntityControls } from './components/EntityControls';
import { EntityError } from './components/EntityError';
import { TableControlsSkeleton } from './components/skeletons/TableControlsSkeleton';
import { TableSkeleton } from './components/skeletons/TableSkeleton';

const FlexColumnBase = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const Root = styled(FlexColumnBase)(({ theme: { themeColors } }) => ({
  height: '100%',
  width: '100%',
  background: themeColors.mainBackground,
  overflow: 'auto',
}));

const ContainerBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  height: '100%',
}));

const Content = styled(FlexColumnBase)(() => ({
  width: '100%',
  minWidth: 560,
}));

const Header = styled(FlexColumnBase)(() => ({
  padding: 16,
  paddingBottom: 0,
  width: '100%',
  minWidth: 560,
  gap: 16,
}));

const Body = styled(FlexColumnBase)(() => ({
  padding: 16,
  width: '100%',
  minWidth: 560,
  maxHeight: '90%',
  display: 'flex',
  flexDirection: 'column',
  gap: 28,
  overflow: 'auto',
}));

const Title = styled(Typography)(() => ({
  textAlign: 'left',
}));

const TitleContainer = styled(FlexColumnBase)(() => ({
  gap: 10,
}));

const StyledDivider = styled(Divider)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.graphGridColor,
  margin: '2px -16px',
}));

const TableContainer = styled(FlexColumnBase)(() => ({
  height: '100%',
  overflow: 'hidden',
}));

const RegionButtonContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: 34,
}));

const ErrorContainer = styled(Box)({
  marginTop: 10,
});

export const EntityOverview = ({ title, entityType }) => {
  const entityOverviewContext = useEntityOverviewContext(entityType);

  return (
    <Root>
      <EntityOverviewContext.Provider value={entityOverviewContext}>
        <Container title={title} />
      </EntityOverviewContext.Provider>
    </Root>
  );
};

export const Container = ({ title }) => {
  const { regionName } = useContext(EntityOverviewContext);

  return (
    <ContainerBox data-testid="entity-overview-container">
      <Content>
        <Header>
          <RegionButtonContainer>
            <RegionSelection regionName={regionName} />
          </RegionButtonContainer>
          <TitleContainer>
            <Title variant="h3">{title}</Title>
            <StyledDivider />
          </TitleContainer>
        </Header>

        <ContainerBody />
      </Content>
    </ContainerBox>
  );
};

export const ContainerBody = () => {
  const { isMetadataLoading, isDataFetching, isMetadataError, isDataError } =
    useContext(EntityOverviewContext);

  if (isMetadataError || isDataError) {
    return (
      <Body>
        <ErrorContainer>
          <EntityError />
        </ErrorContainer>
      </Body>
    );
  }

  return (
    <Body>
      {isMetadataLoading ? <TableControlsSkeleton /> : <EntityControls />}

      {isMetadataLoading || isDataFetching ? (
        <TableSkeleton />
      ) : (
        <TableContainer>
          <EntityTable />
        </TableContainer>
      )}
    </Body>
  );
};
