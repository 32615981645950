import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useHistory, useLocation } from 'react-router-dom';
import { FieldContext } from '../fieldContext';
import { NavigationButton } from './navigationButton/NavigationButton';
import ProjectSelector from './projectSelection/projectSelector';
import { ROUTES } from '../../../../../constants';
import { AppContext } from '../../../appContext';
import { ViewsList } from './viewsList/ViewsList';
import { AccountCard } from '../../../../../components/accountCard/accountCard';

const NavigationRoot = styled('div')((props) => ({
  height: '100%',
  maxWidth: '375px',
  width: '375px',
  marginLeft: '-375px',
  display: 'flex',
  flexDirection: 'column',
  borderRight: `1px solid ${props.theme.themeColors.borderLowContrast}`,
  backgroundColor: props.theme.themeColors.mainBackground,
  ...(props.navigationOpenChanged && {
    animation: props.navigationOpen ? '0.5s slide' : '0.5s slideOut',
  }),
  animationFillMode: 'forwards',
  '@keyframes slide': {
    from: { marginLeft: '-375px' },
    to: { marginLeft: '0px' },
  },
  '@keyframes slideOut': {
    from: { marginLeft: '0px' },
    to: { marginLeft: '-375px' },
  },
}));

const StyledButtonContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  marginLeft: '16px',
  marginTop: '24px',
}));

export const Navigation = () => {
  const { navigationOpen, project: projectItem } = useContext(FieldContext);
  const [prevNavigationOpen, setPrevNavigationOpen] = useState(false);
  const [navigationOpenChanged, setNavigationOpenChanged] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { isProjectListLoading: isProjectsLoading, projectList: projects } =
    useContext(AppContext);

  const setProjectItemFull = (proj) => {
    if (location.pathname.includes(`${ROUTES.FIELD}${ROUTES.PROJECTS}`)) {
      const route = `${ROUTES.FIELD}${ROUTES.PROJECTS}/${proj.metadata.projectId}`;
      history.push(route);
    }
  };

  useEffect(() => {
    if (!!navigationOpen !== !!prevNavigationOpen) {
      setNavigationOpenChanged(true);
      setPrevNavigationOpen(!!navigationOpen);
    } else {
      setNavigationOpenChanged(false);
    }
  }, [navigationOpen]);

  return (
    <NavigationRoot
      navigationOpen={navigationOpen}
      navigationOpenChanged={navigationOpenChanged}
    >
      <StyledButtonContainer>
        <NavigationButton />
      </StyledButtonContainer>
      <ProjectSelector
        projects={projects}
        project={projectItem}
        setProjectItem={setProjectItemFull}
        isBusy={isProjectsLoading}
      />
      <ViewsList project={projectItem} />
      <AccountCard />
    </NavigationRoot>
  );
};
