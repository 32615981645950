import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Drawer } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { EntityError } from './EntityError';
import { EntityOverviewContext } from '../data/entityOverviewContext';
import {
  buildColumns,
  buildRows,
  filterRowsBySearchValue,
} from './tableHelpers';
import { EntityOverviewDrawer } from './drawer';
import { findRowDataInfo } from './drawer/drawerHelpers';
import { ENTITY_TYPES_ENUM, ERROR_TYPES_ENUM } from '../constants';

const TABLE_PAGE_SIZE = 25;

const TableWrapper = styled(Box)(() => ({
  overflow: 'hidden',
  width: '100%',
  height: '100%',
}));

const StyledDatagrid = styled(DataGridPremium, {
  shouldForwardProp: (prop) => prop !== 'dynamicRowStyles',
})(({ theme: { themeColors, spacing }, dynamicRowStyles }) => ({
  ...dynamicRowStyles,
  border: '0',
  boxShadow: 'none',

  '.MuiDataGrid-root': {
    overflow: 'visible',
  },

  '.MuiDataGrid-iconSeparator': {
    display: 'none',
  },

  '.MuiDataGrid-columnHeaders': {
    color: themeColors.neutral80,
    borderColor: themeColors.borderLowContrast,
    height: 'fit-content',
    paddingBottom: spacing(1.5),
    maxHeight: '100%',
  },

  '.MuiDataGrid-iconButtonContainer': {
    width: 28,
  },

  '.MuiDataGrid-columnHeaderDraggableContainer': {
    alignItems: 'end',
  },

  '.MuiDataGrid-row.Mui-hovered': {
    background: 'none',
  },

  '.MuiDataGrid-virtualScroller': {
    '&::-webkit-scrollbar': {
      width: 14,
      height: 14,
    },
    '&::-webkit-scrollbar-track': {
      background: themeColors.generalScrollbarTrackColor,
      border: 'none',
      boxShadow: 'none',
    },
    '&::-webkit-scrollbar-corner': {
      background: themeColors.generalScrollbarTrackColor,
    },
    '&::-webkit-scrollbar-thumb': {
      background: themeColors.neutral30,
      borderRadius: 100,
      border: `3px solid ${themeColors.generalScrollbarTrackColor}`,
      '&:hover': {
        background: themeColors.neutral80,
      },
    },
  },

  '.MuiDataGrid-footerContainer': {
    border: 'none',
    justifyContent: 'left',
  },

  '.MuiDataGrid-pinnedColumns, .MuiDataGrid-pinnedColumnHeaders': {
    background: themeColors.mainBackground,
    boxShadow: 'none',
  },
}));

const StyledDrawer = styled(Drawer)(({ theme: { themeColors } }) => ({
  '.MuiDrawer-paper': {
    backgroundColor: themeColors.modalBackgroundColor,
  },
}));

const ErrorContainer = styled(Box)({
  marginTop: 16,
});

const DataGridColumnsInitialState = {
  pagination: {
    paginationModel: { pageSize: TABLE_PAGE_SIZE, page: 0 },
  },
};

export const EntityTable = () => {
  const { isDataFetching, data, entityType, searchValue, setErrorType } =
    useContext(EntityOverviewContext);
  const [isTableLoading, setIsTableLoading] = useState(true);

  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();

  const [nameColumnIndex, setNameColumnIndex] = useState();
  const [drawerOpenerColumnIndex, setDrawerOpenerColumnIndex] = useState();
  const [dynamicRowStyles, setDynamicRowStyles] = useState();
  const [dynamicRowStylesLoading, setDynamicRowStylesLoading] = useState(true);

  useEffect(() => {
    if (
      !isDataFetching &&
      data &&
      data.headers?.length > 0 &&
      data.items?.length > 0
    ) {
      setIsTableLoading(true);

      const { headers, items } = data;
      const tempColumns = buildColumns(headers);
      const tempRows = buildRows(headers, items);

      setColumns(tempColumns);

      if (searchValue) {
        const filteredRowsBySearchValue = filterRowsBySearchValue(
          data.headers,
          tempRows,
          searchValue
        );
        if (filteredRowsBySearchValue.length === 0) {
          setErrorType(ERROR_TYPES_ENUM.NO_SEARCH_DATA);
        }
        setRows(filteredRowsBySearchValue);
      } else {
        setErrorType(null);
        setRows(tempRows);
      }
    }
  }, [isDataFetching, data, searchValue]);

  useEffect(() => {
    if (rows?.length > 0 && columns?.length > 0 && entityType) {
      setIsTableLoading(false);

      Object.entries(rows[0]).forEach(([key, value]) => {
        if (value?.data && Object.keys(value.data).includes('name')) {
          setNameColumnIndex(key);
        }

        if (value?.data && value.data.drawerOpener) {
          setDrawerOpenerColumnIndex(key);
        }
      });
    }
  }, [rows, columns, entityType]);

  useEffect(() => {
    if (
      nameColumnIndex !== undefined &&
      drawerOpenerColumnIndex !== undefined
    ) {
      setDynamicRowStyles({
        '.MuiDataGrid-row': {
          [`> [data-colindex="${nameColumnIndex}"]:hover`]: {
            textDecoration: 'underline',
            cursor: 'pointer',
          },

          [`> [data-colindex="${drawerOpenerColumnIndex}"]:hover`]: {
            cursor: 'pointer',
          },
        },
      });

      setDynamicRowStylesLoading(false);
    }
  }, [nameColumnIndex, drawerOpenerColumnIndex]);

  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState();

  const onCellClick = (params) => {
    if (
      params.field === nameColumnIndex ||
      params.field === drawerOpenerColumnIndex
    ) {
      const name = findRowDataInfo(Object.entries(params?.row), 'name');
      const address = findRowDataInfo(Object.entries(params?.row), 'address');
      const id = findRowDataInfo(Object.entries(params?.row), 'id');

      setDrawerData({ name, address, id, entityType });
    }
  };

  useEffect(() => {
    if (drawerData) {
      setShowDrawer(true);
    }
  }, [drawerData]);

  const noTableData = data?.items?.length === 0;
  const noRowData = rows?.length === 0;
  if (noTableData || noRowData) {
    return (
      <ErrorContainer>
        <EntityError />
      </ErrorContainer>
    );
  }

  return (
    !isDataFetching &&
    !isTableLoading &&
    !dynamicRowStylesLoading && (
      <>
        <TableWrapper>
          <StyledDatagrid
            columns={columns || []}
            rows={rows || []}
            disableSelectionOnClick
            disableColumnMenu
            rowHeight={72}
            columnHeaderHeight={65}
            disableMultipleSelection
            loading={isDataFetching || !rows || !columns}
            initialState={{
              ...DataGridColumnsInitialState,
              pinnedColumns: { right: [`${columns.length - 1}`] },
              sorting: entityType !== ENTITY_TYPES_ENUM.DIRECT_ACCOUNT && {
                sortModel: [{ field: '0', sort: 'desc' }],
              },
            }}
            onCellClick={onCellClick}
            disableRowSelectionOnClick
            rowBuffer={columns?.length || 0}
            pagination
            disableColumnReorder
            disableColumnResize
            autosizeOnMount
            dynamicRowStyles={dynamicRowStyles}
          />
        </TableWrapper>
        <StyledDrawer
          anchor="right"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          PaperProps={{
            elevation: 0,
          }}
          ModalProps={{
            style: {
              position: 'absolute',
            },
            slotProps: {
              backdrop: {
                style: {
                  position: 'fixed',
                },
              },
            },
          }}
        >
          <EntityOverviewDrawer
            drawerData={drawerData}
            setDrawerVisibility={setShowDrawer}
          />
        </StyledDrawer>
      </>
    )
  );
};
