import styled from '@emotion/styled';
import { Box, Skeleton } from '@mui/material';
import React from 'react';

const StyledSkeletonBar = styled(Skeleton)(() => ({
  transform: 'scale(1)',
  height: 105,
}));

export const DrawerTimelineSkeleton = () => (
  <Box display="flex" flexDirection="column" gap={2}>
    <StyledSkeletonBar animation="wave" />
    <StyledSkeletonBar animation="wave" />
    <StyledSkeletonBar animation="wave" />
  </Box>
);
