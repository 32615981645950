import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import Fade from '@mui/material/Fade';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { ObjectiveContext } from './contexts/objectiveContext';
import CustomToolbar from './tableview/customToolbar/customToolbar';
import formatTerritoryTableValue from '../../utils/formatTerritoryTableValue';
import EmptyView from '../emptyview/emptyView';

const formatColumns = (columns, themeColors) =>
  columns.map((column) => {
    if (column.includes('Rx Lift')) {
      return {
        field: column,
        headerName: column,
        flex: 1,
        renderCell: (params) => {
          const { value } = params;
          if (value === '-') {
            return <Typography>{value}</Typography>;
          }
          if (value.startsWith('-')) {
            return (
              <Typography
                style={{
                  color: themeColors.decreaseColorMain,
                  fontSize: 14,
                }}
              >
                {value}
              </Typography>
            );
          }
          return (
            <Typography
              style={{
                color: themeColors.increaseColorMain,
                fontSize: 14,
              }}
            >
              {value}
            </Typography>
          );
        },
      };
    }
    return {
      field: column,
      headerName: column,
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            fontSize: 14,
          }}
        >
          {formatTerritoryTableValue(params.value)}
        </Typography>
      ),
    };
  });

const defaultColumnVisibility = {
  'Historical IQVIA Regional SPDI NBRx Volume Target': true,
  'Historical IQVIA Regional SPDI TRx Volume Target': true,
  'Face to Face Call Rx Lift % Change': true,
  'P2P Speaker Rx Lift % Change': true,
};

const defaultColumnVisibilityModel = (columns) => {
  const columnVisibility = {};
  Object.keys(defaultColumnVisibility).forEach((column) => {
    columnVisibility[column] = defaultColumnVisibility[column];
  });
  columns.forEach((column) => {
    if (!Object.keys(columnVisibility).includes(column)) {
      columnVisibility[column] = false;
    }
  });
  return columnVisibility;
};

const filterColumns = (columns) => {
  const columnsToRemove = ['id', 'entity_id', 'path'];
  return columns.filter((column) => !columnsToRemove.includes(column));
};

const TableViewContentContainer = styled('div')(() => ({
  maxHeight: '100%',
  width: '100%',
}));

const rowsAndColumns = (selectedObjective, data) => {
  let columns;
  let rows;
  if (selectedObjective?.id) {
    columns = data[selectedObjective.id].columns;
    rows = data[selectedObjective.id].rows;
  } else {
    columns = Object.values(data)[0].columns;
    rows = Object.values(data)[0].rows;
  }
  const uniqueRows = rows.filter(
    (row, index) =>
      rows.findIndex((firstRow) => firstRow.path === row.path) === index
  );

  return { columns, rows, uniqueRows };
};

const StyledDatagrid = styled(DataGridPremium)(() => ({
  '.MuiDataGrid-main': {
    maxHeight: 'calc(100vh - 340px)', // This is a hacky fix needed from upgrading V5 -> v6 to prevent grid loading full page
  },
  '.MuiDataGrid-virtualScroller': {
    // Needed from v5 -> v6 updagrade for empty tables to give space to "no rows"
    minHeight: '56px',
  },
}));

export const TerritoryTableView = ({ data }) => {
  const { selectedObjective } = useContext(ObjectiveContext);
  const { themeColors } = useTheme();

  const { columns, rows, uniqueRows } = rowsAndColumns(selectedObjective, data);
  const filteredColumns = filterColumns(columns);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    defaultColumnVisibilityModel(filteredColumns)
  );

  const handleColumnVisibilityModelChange = (newModel) => {
    setColumnVisibilityModel(newModel);
  };

  const dataIsGridSafe =
    Array.isArray(columns) && uniqueRows.length === rows.length;

  if (!dataIsGridSafe) {
    return (
      <Fade in timeout={600} key="loading">
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <EmptyView />
        </div>
      </Fade>
    );
  }

  return (
    <Fade in timeout={500}>
      <TableViewContentContainer>
        <StyledDatagrid
          className="intercom-sphere-territory-view-table"
          treeData
          getRowId={(row) => row.entity_id}
          getTreeDataPath={(row) => row.path.split('/')}
          rows={rows}
          columns={formatColumns(filteredColumns, themeColors)}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          slots={{ toolbar: CustomToolbar }}
          hideFooterPagination
          disableSelectionOnClick
          hideFooterSelectedRowCount
          rowHeight={70}
          sx={{
            boxShadow: 2,
            border: 1,
            borderColor: themeColors.contentCardBorderColor,
            backgroundColor: themeColors.contentCardBackgroundColor,
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
            display: 'grid', // these two lines are hacky fix for v5 -> v6 upgfrade to ensure width doesnt permanently grow
            gridTemplateRows: 'auto 1f auto',
          }}
        />
      </TableViewContentContainer>
    </Fade>
  );
};
