import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { TypographyDefinitions } from '../../../../styles/typography';
import { addSpacesBetweenSeparators } from '../../../../utils/formatEntityName';

const StyledNameCellTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isCaption' || prop !== 'variant',
})(({ theme, isCaption }) => ({
  fontWeight: isCaption
    ? TypographyDefinitions.fontWeight.regular
    : TypographyDefinitions.fontWeight.medium,
  color: isCaption
    ? theme.themeColors.tertiaryColor
    : theme.themeColors.secondaryTextColor,
}));

export const EntityTableNameCell = ({ entityName, address }) => (
  <Box textAlign="left">
    <StyledNameCellTypography variant="body2">
      {addSpacesBetweenSeparators(entityName.toUpperCase())}
    </StyledNameCellTypography>
    <StyledNameCellTypography variant="caption" isCaption>
      {address}
    </StyledNameCellTypography>
  </Box>
);
