/* eslint-disable no-empty */
/*
=========================================================================================
======================  Array & Maps Manipulation   =====================================
=========================================================================================
*/

/**
 * Sum reducer used to calculate the sum of an array using its reduce() function.
 *
 * @param {number} accumulator
 * @param {number} currentValue
 * @return {number} The sum of new accumulator
 */
export const sumReducer = (accumulator, currentValue) =>
  accumulator + currentValue;

/**
 * Get the max value range for an array.
 *
 * Given a single-level array [] or double-level array [[],[],],
 * the range is calculated between max and min values of the entire array.
 *
 * @param {array} source The input array.
 * @return {number} The max range
 */
export const getMaxRange = (source) => {
  let maxValue = 0;
  let minValue = 0;
  source.forEach((item) => {
    const sum = Array.isArray(Object.values(item)[0])
      ? Object.values(item)[0].reduce(sumReducer)
      : Object.values(item)[0];
    maxValue = sum > maxValue ? sum : maxValue;
    minValue = sum < minValue ? sum : minValue;
  });
  return maxValue - minValue;
};

export const sortStats = (stats, reduce = true) => {
  if (stats && Array.isArray(stats)) {
    stats.sort((a, b) => {
      let aTotal = Object.values(a)[0];
      let bTotal = Object.values(b)[0];

      if (Array.isArray(aTotal)) {
        aTotal = reduce ? aTotal.reduce(sumReducer) : aTotal[0];
      }

      if (Array.isArray(bTotal)) {
        bTotal = reduce ? bTotal.reduce(sumReducer) : bTotal[0];
      }

      if (aTotal > bTotal) return -1;
      if (aTotal < bTotal) return 1;
      return 0;
    });
  }

  return stats;
};

export const findMaxValue = (source) => {
  let maxValue = 0;
  let minValue = 0;

  source.forEach((item) => {
    const values = Object.values(item);

    if (values && Array.isArray(values)) {
      values.forEach((subVal) => {
        if (subVal && Array.isArray(subVal)) {
          subVal.forEach((leafVal) => {
            maxValue = leafVal > maxValue ? leafVal : maxValue;
            minValue = leafVal < minValue ? leafVal : minValue;
          });
        } else if (subVal) {
          maxValue = subVal > maxValue ? subVal : maxValue;
          minValue = subVal < minValue ? subVal : minValue;
        }
      });
    }
  });
  return maxValue - minValue;
};

export const findMinValue = (source) => {
  let maxValue = 0;
  let minValue = Infinity;

  source.forEach((item) => {
    const values = Object.values(item);

    if (values && Array.isArray(values)) {
      values.forEach((subVal) => {
        if (subVal && Array.isArray(subVal)) {
          subVal.forEach((leafVal) => {
            maxValue = leafVal > maxValue ? leafVal : maxValue;
            minValue = leafVal < minValue ? leafVal : minValue;
          });
        } else if (subVal) {
          maxValue = subVal > maxValue ? subVal : maxValue;
          minValue = subVal < minValue ? subVal : minValue;
        }
      });
    }
  });
  return minValue;
};

/*
=========================================================================================
======================  String Manipulation   =====================================
=========================================================================================
*/

/**
 * Used to ensure routing URLs do not end with / so we can have consistent url building schema
 * @param {string} url the url to be trimmed
 * @return {string} newly trimmed url
 */
export const trimUrl = (url) => {
  if (url) {
    if (url.length > 1 && url.endsWith('/'))
      return url.substring(0, url.length - 1);
    return url;
  }

  return '/';
};

/*
=========================================================================================
==================================  Data Parsing   ======================================
=========================================================================================
*/

export const parseDataRows = (samples) => {
  const rows = [];

  if (samples && samples.length > 0) {
    try {
      samples.forEach((item) => {
        rows.push(Object.values(item));
      });
    } catch {}
  }
  return rows;
};

export const parseDataColumns = (samples) => {
  let columns = [];
  if (samples && samples.length > 0) {
    try {
      columns = Object.keys(samples[0]);
    } catch {}
  }
  return columns;
};

/*
=========================================================================================
==================================  Date Parsing   ======================================
=========================================================================================
*/

export const formatDate = (date) => {
  if (date) {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = date.getFullYear();
    return `${mm}-${dd}-${yyyy}`;
  }
  return '';
};

export function numberWithCommas(x) {
  let ret = x;
  if (x) {
    if (x < 100) ret = x.toFixed(2);
    else ret = x.toFixed(0);

    return ret.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '';
}

export function integerWithCommas(x) {
  let ret = x;
  if (x) {
    ret = x.toFixed(0);
    return ret.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  if (Number(x) === 0) {
    return 0;
  }
  return '';
}
