import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  LocalFloristOutlined,
  SwitchAccessShortcut,
  RampRight,
  CallReceived,
  Sports,
} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import PrimaryContentBox from '../../generic/primaryContentBox';
import { RecentLabel, PredictedLabel, Icon } from './insightsGeneric';
import { ReactComponent as SwitchOutIcon } from '../../../assets/switch_out_icon.svg';

const SEGMENT_CONFIG = {
  grower: {
    icon: <LocalFloristOutlined />,
    type: 'positive',
  },
  rising_star: {
    icon: <SwitchAccessShortcut />,
    type: 'positive',
  },
  switch_in: {
    icon: <RampRight />,
    type: 'positive',
  },
  switch_out: {
    icon: <SwitchOutIcon />,
    type: 'negative',
  },
  shrinker: {
    icon: <CallReceived />,
    type: 'negative',
  },
  starter: {
    icon: <Sports />,
    type: 'positive',
  },
};

export const headerToId = (header) => {
  if (!header) return null;
  return header
    .split(' ')
    .map((x) => x.toLowerCase())
    .join('_');
};
const SegmentsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 16,
  gridGap: 16,
}));

const SegmentCard = styled(PrimaryContentBox)(({ theme: { themeColors } }) => ({
  padding: 20,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  color: themeColors.primaryMaptualListFilteringColor,
  backgroundColor: themeColors.contentCardBackgroundColor,
}));

const SegmentSectionScore = styled(Box)(() => ({
  textAlign: 'left',
  display: 'flex',
}));

const SegmentSectionTitle = styled(Box)(() => ({
  gridGap: 16,
  display: 'grid',
  gridTemplateColumns: '40px 1fr',
  padding: '4px 0',
  maxWidth: '75%',
  textAlign: 'left',
}));

const StyledHeaderTypography = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.secondaryTextColor,
  })
);

const SegmentIcon = styled(Icon)(({ theme: { themeColors } }) => ({
  '&.negative': {
    backgroundColor: themeColors.insightsSegmentNegativeIconBackground,
    color: themeColors.insightsSegmentNegativeIconColor,
  },
  '&.positive': {
    backgroundColor: themeColors.insightsSegmentPositiveIconBackground,
    color: themeColors.insightsSegmentPositiveIconColor,
  },
  alignSelf: 'center',
}));

const Score = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  width: 120,
  alignItems: 'center',
  justifyContent: 'space-between',
  '.MuiTypography-subtitle1': {
    color: themeColors.offWhiteFontColor,
  },
}));
const ScoreTitle = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  alignItems: 'end',
  lineHeight: 1,
  width: 32,
  color: themeColors.offWhiteFontColor,
}));

const ScoreValue = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 600,
  alignItems: 'flex-end',
  display: 'flex',
  lineHeight: 1.1,
}));

const ScoreMax = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: 12,
  marginLeft: 2,
  color: themeColors.primaryMaptualListFilteringColor,
}));

const SegmentScoreVisual = ({ visual }) => {
  const value = Math.round(visual.primary);
  return (
    <Score>
      {visual.type === 'RECENT' ? <RecentLabel /> : null}
      {visual.type === 'PRED' ? <PredictedLabel /> : null}
      <ScoreTitle>
        {value <= 0 ? (
          <ScoreValue>--</ScoreValue>
        ) : (
          <>
            <ScoreValue>{value}</ScoreValue>
            <ScoreMax>/10</ScoreMax>
          </>
        )}
      </ScoreTitle>
    </Score>
  );
};

const SegmentScoreWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gridGap: 4,
  alignSelf: 'center',
}));

const SegmentDescription = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  justifyContent: 'center',
}));

const InsightSegmentCard = ({ card }) => {
  const cardConfig = SEGMENT_CONFIG[headerToId(card?.cardHeader)];
  if (!cardConfig) {
    return null;
  }

  return (
    <SegmentCard>
      <SegmentSectionTitle>
        <SegmentIcon className={cardConfig.type}>{cardConfig.icon}</SegmentIcon>
        <SegmentDescription>
          <StyledHeaderTypography variant="h6" fontWeight={600}>
            {card.cardHeader}
          </StyledHeaderTypography>
          <Typography variant="body2" mt={0.5}>
            {card.description}
          </Typography>
        </SegmentDescription>
      </SegmentSectionTitle>
      <SegmentSectionScore>
        <SegmentScoreWrapper>
          {card.visuals.map((visual) => (
            <SegmentScoreVisual
              key={`visual-${visual?.type?.toLowerCase()}`}
              visual={visual}
            />
          ))}
        </SegmentScoreWrapper>
      </SegmentSectionScore>
    </SegmentCard>
  );
};

const ALLOWED_HEADERS = ['Switch In', 'Switch Out', 'Rising Star'];

export const filterSegmentCards = (objectiveCards) =>
  objectiveCards.filter((card) => {
    const isAllowedCard =
      ALLOWED_HEADERS.includes(card.cardHeader) ||
      card.productGroup === 'target';

    return card.cardType === 'segment' && isAllowedCard;
  });

export default function InsightsSegments({ objectiveCards }) {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const cardList = filterSegmentCards(objectiveCards);

    if (cardList.length > 0) {
      cardList.sort((a, b) => {
        const bScore = b?.visuals?.[0].primary || 0;
        const aScore = a?.visuals?.[0].primary || 0;
        return bScore - aScore;
      });
      setCards(cardList);
    } else {
      setCards([]);
    }
  }, [objectiveCards]);

  return (
    <SegmentsWrapper
      className="intercom-field-objective-segments"
      data-testid="insightsObjectiveSegments"
    >
      {cards.map((card) => (
        <InsightSegmentCard
          key={`${headerToId(card.cardHeader)}-${card.productGroup}`}
          card={card}
        />
      ))}
    </SegmentsWrapper>
  );
}
