/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import * as ViewerAction from '../../actions/projectViewActions';
import HCPEntityPage from '../../components/fieldView/hcpEntityPage';
import { useMetricScriptsMetadata } from '../../hooks/useMetricScriptsMetadata';
import { AppContext } from '../application/appContext';

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    height: '100%',
    'min-height': '100%',
    display: 'flex',
    backgroundColor: themeColors.mainBackground,
  },
}));

const mapStateToProps = (state) => ({
  viewer: state.projectViewer,
});

const mapDispatchToProps = (dispatch) => ({
  getEntity: (entityType, entityId, maptualListId, projectId, appType) =>
    dispatch(
      ViewerAction.getEntity({
        entityType,
        entityId,
        maptualListId,
        projectId,
        appType,
      })
    ),
  getShare: (entityType, entityId, maptualListId, projectId) =>
    dispatch(
      ViewerAction.getShare({
        entityType,
        entityId,
        maptualListId,
        projectId,
      })
    ),
  selectEntity: (entityMetadata) =>
    dispatch(ViewerAction.selectEntity(entityMetadata)),
  getInsights: (entityId, projectId, maptualListId) =>
    dispatch(ViewerAction.getInsights({ entityId, projectId, maptualListId })),
});

const eagerLoadMetricScriptsData = () => useMetricScriptsMetadata();

function HcpInfoContainer({
  viewer,
  match,
  getEntity,
  getShare,
  getInsights,
  appType = 'sphere',
}) {
  const classes = useStyles();

  const { entity, isEntityBusy, isMaptualListBusy } = viewer;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      ViewerAction.initializeStandaloneEntityPage({
        entityId: match.params.entityId,
        maptualListId: match.params.maptualListId,
        projectId: match.params.projectId,
        isStandalonePage: true,
      })
    );
  }, []);

  eagerLoadMetricScriptsData();

  useEffect(() => {
    getEntity(
      'HCP',
      match.params.entityId,
      match.params.maptualListId,
      match.params.projectId,
      appType
    );
  }, []);

  useEffect(
    () =>
      getShare(
        'HCP',
        match.params.entityId,
        match.params.maptualListId,
        match.params.projectId
      ),
    []
  );

  const { profile } = useContext(AppContext);

  useEffect(
    () =>
      getInsights(
        match.params.entityId,
        match.params.projectId,
        match.params.maptualListId
      ),
    [profile.userGroup]
  );

  return (
    <div className={classes.root}>
      <HCPEntityPage
        entity={entity}
        entityMetadata={entity?.metadata}
        isEntityBusy={isEntityBusy}
        getEntity={getEntity}
        getShare={getShare}
        getInsights={getInsights}
        entityType="HCP"
        maptualListId={match.params.maptualListId}
        isMaptualListBusy={isMaptualListBusy}
        projectId={match.params.projectId}
      />
    </div>
  );
}

HcpInfoContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      entityId: PropTypes.string,
      maptualListId: PropTypes.string,
      projectId: PropTypes.string,
      regionLabel: PropTypes.string,
    }),
  }),
  viewer: PropTypes.shape({
    entityMetadata: PropTypes.object,
    entity: PropTypes.object,
    isEntityBusy: PropTypes.bool,
    isMaptualListBusy: PropTypes.bool,
  }),
  profile: PropTypes.shape({
    role: PropTypes.object,
  }),
  getEntity: PropTypes.func.isRequired,
  getShare: PropTypes.func.isRequired,
  getInsights: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HcpInfoContainer);
