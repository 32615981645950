export const APPVIEWS = {
  ADMIN: 'ADMIN',
  FIELD: 'FIELD',
  SPHERE: 'SPHERE',
  HEADOFFICE_OLDNAME: 'HEADOFFICE',
  LABS: 'LABS',
  PULSE: 'PULSE',
};

export const ROUTES = {
  APP_SELECTION: '/appSelection',
  LOGIN: '/login',
  ADMIN: '/admin',
  FIELD: '/field',
  SPHERE: '/sphere',
  PULSE: '/pulse',
  PROJECTS: '/projects',
  USERS: '/users',
  ROOT: '/',
  INDEX_HTML: '/index.html',
  NEW_PROJECT: '/new-analysis',
  SETTINGS: '/settings',
  TERRITORY_OVERVIEW: '/territory-overview',
  CONFIG: '/config',
  ADMIN_CURATION: '/admin/curation',
  ADMIN_CSV_DELIVERY: '/admin/csv-delivery',
  CURATION: '/curation',
  HCPS: '/hcps',
  CUSTOM: '/custom',
  STARRED: '/starred',
  NO_SEE: '/noSee',
  LONG_TERM_LEAVE: '/longTermLeave',
  DATA_REPORT: '/dataReport',
  SALES_REPS: '/sales-reps',
  REGIONS: '/regions',
  ENTITY_OVERVIEW: '/entity-overview',
  OUTLET: '/outlet',
  DIRECT_ACCOUNT: '/direct-account',
  CALL_PLAN: '/call-plan',
};

export const CUSTOM_LISTS = {
  STARRED: 'starred',
  NO_SEE: 'noSee',
  LONG_TERM_LEAVE: 'longTermLeave',
};

export const CUSTOM_LISTS_NAMES = {
  starred: 'Starred HCPs',
  noSee: 'No-See HCPs',
  longTermLeave: 'Long term leave HCPs',
  hcps: 'HCPs',
};

export const ENVIRONMENTS = {
  TEST: 'test',
  PROD: 'prod',
  DEVELOPMENT: 'development',
};

export const PROJECT_STATUS = {
  READY: 'ready',
  DEPLOYED: 'deployed',
  CREATED: 'created',
};

export const PROJECT_REFRESH_STATUS = {
  VALID: 'valid',
  TEST: 'test',
  IN_PROGRESS: 'in progress',
};

export const PATCH_USERMANAGEMENT_PROJECT_ACTIONS = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};

export const RBAC_PERMISSIONS = {
  VIEW_FE_EVENT_LOG: 'view-fe-event-log',
  PROJECTS_ADMIN: 'projects-admin',
  FEATURE_TOGGLES_ADMIN: 'feature-toggles-admin',
  VIEW_DATA_REPORTS: 'view-data-reports',
  VIEW_DATA_CONNECTORS: 'view-data-connectors',
  USER_GROUP_CONFIG_ADMIN: 'user-group-config-admin',
  CURATION_ADMIN: 'curation-admin',
};
