import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@mui/material/MobileStepper';
import Scrollbar from 'react-scrollbars-custom';
import ProfilePicNLinks from './entityProfile/profilePicNLinks';
import ProfileDetailsAndConnections from './entityProfile/profileDetailsAndConnections';
import ProfileJourneyView from './entityProfile/profileJourneyView';
import { trackEntityProfileDetailSelection } from '../../trackers/appEventTracker';

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    width: 435,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: themeColors.journeyDrawerBackgroundColor,
  },
  scrollView: {
    flexGrow: 2,
    width: '100%',
    height: '100%',
  },
  swipeContent: {
    maxWidth: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginBottom: 5,
  },
  stepperStyle: {
    margin: 0,
    borderRadius: 10,
    padding: 0,
    marginTop: 10,
    backgroundColor: themeColors.mainBackground,
  },
  dot: {
    backgroundColor: themeColors.buttonBackgroundColor,
  },
  dotActive: {
    backgroundColor: themeColors.buttonContentColor,
  },
  stepperButton: {
    color: themeColors.buttonContentColor,
    '&:hover': {
      backgroundColor: themeColors.buttonBackgroundColor,
    },
  },
}));

const ScrollElement = styled(Box)(() => ({
  padding: 30,
}));
export default function EntityJourney({ entity }) {
  const swipeableViewRef = useRef(null);
  const classes = useStyles();
  const theme = useTheme();
  const stepCount = 2;
  const [activeStep, setActiveStep] = React.useState(0);
  const { metadata = {} } = entity;
  const { entityName = '' } = metadata;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep + 1 === 1) {
      // TODO proper mapping steps to know which view is triggered dynamically
      trackEntityProfileDetailSelection(entityName);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const [connectionsLoaded, setConnectionsLoaded] = useState(false);
  useEffect(() => {
    if (connectionsLoaded) swipeableViewRef.current.updateHeight();
  }, [connectionsLoaded]);

  return entity ? (
    <div className={classes.root}>
      <Scrollbar
        disableTracksWidthCompensation
        noScrollX
        className={`${classes.scrollView} intercom-field-entity-journey`}
      >
        <ScrollElement>
          <ProfilePicNLinks entityUData={entity.entityUData} />
          <div style={{ width: '100%' }}>
            <MobileStepper
              variant="dots"
              steps={stepCount}
              position="static"
              activeStep={activeStep}
              classes={{
                root: classes.stepperStyle,
                dot: classes.dot,
                dotActive: classes.dotActive,
              }}
              nextButton={
                <Button
                  className={classes.stepperButton}
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === stepCount - 1}
                >
                  Next
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  className={classes.stepperButton}
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </div>
          <div
            className={classes.swipeContent}
            style={{
              flexDirection: 'column',
              alignItems: 'stretch',
              marginBottom: 5,
              marginTop: 5,
            }}
          >
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              animateHeight
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              ignoreNativeScroll
              ref={swipeableViewRef}
            >
              <ProfileJourneyView entityJourney={entity.entityJourney} />
              <ProfileDetailsAndConnections
                entityUData={entity.entityUData}
                connections={entity.connections}
                onConnectionsLoaded={() => {
                  if (!connectionsLoaded) {
                    setConnectionsLoaded(true);
                  }
                }}
              />
            </SwipeableViews>
          </div>
        </ScrollElement>
      </Scrollbar>
    </div>
  ) : null;
}

EntityJourney.propTypes = {
  entity: PropTypes.shape({
    metadata: PropTypes.shape({
      entityName: PropTypes.string,
    }),
    entityUData: PropTypes.shape({
      location: PropTypes.shape({
        address: PropTypes.string,
        cityTown: PropTypes.string,
        stateProvShort: PropTypes.string,
        locationName: PropTypes.string,
      }),
      specialty: PropTypes.shape({
        primary: PropTypes.string,
      }),
    }),
    entityJourney: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    connections: PropTypes.array,
  }),
};
