import React from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { ObjectiveSelector } from './objectiveSelector';
import { MetricSelector } from './metricSelector';
import { CadenceSelector } from './cadenceSelector';
import { MetricTypeSelector } from './metricTypeSelector';

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 24,
  gap: 16,
  alignItems: 'center',
}));

export const Controls = ({
  updateFilterObjective,
  updateFilterMetric,
  updateFilterCadence,
  updateFilterMetricType,
  objectiveId,
  metric,
  metricTypes,
}) => {
  const { projectId } = useParams();

  return (
    <Container>
      <ObjectiveSelector
        projectId={projectId}
        updateFilterObjective={updateFilterObjective}
      />
      <CadenceSelector updateFilterCadence={updateFilterCadence} />
      {objectiveId && (
        <MetricSelector
          project={projectId}
          objective={objectiveId}
          updateFilterMetric={updateFilterMetric}
          entityType="regional team"
        />
      )}
      {metric && metricTypes && (
        <MetricTypeSelector
          metricTypes={metricTypes}
          updateFilterMetricType={updateFilterMetricType}
        />
      )}
    </Container>
  );
};
