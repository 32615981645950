import React from 'react';
import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import { PowerScore } from '../../components/fieldView/powerScore';

const StyledTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme: { themeColors }, isSelected }) => ({
  color: isSelected
    ? themeColors.primaryTextColor
    : themeColors.buttonContentColor,
}));

export const StyledMultiObjectivesRow = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'objectiveCount',
})(({ theme, objectiveCount }) => ({
  width: '100%',
  display: 'flex',
  gridGap: theme.spacing(1),
  flexWrap: objectiveCount >= 4 ? 'wrap' : 'nowrap',
}));

const ObjectiveCard = styled(Button)(
  ({ theme: { themeColors, spacing }, objectiveCount }) => ({
    display: 'flex',
    justifyContent: 'start',
    flexBasis: objectiveCount >= 4 ? '45%' : '100%',
    flexGrow: objectiveCount >= 4 ? '1' : '0',
    maxWidth: objectiveCount >= 4 ? '49%' : 'none',
    padding: 16,
    gridGap: spacing(1.5),
    alignItems: 'center',
    color: themeColors.buttonContentColor,
    backgroundColor: themeColors.buttonBackgroundColor,
    border: `1px solid ${themeColors.contentCardBorderColor}`,
    borderRadius: 5,
    ':hover, &.Mui-focusVisible': {
      background: themeColors.buttonBackgroundColor,
    },
    '&.selectedObjective': {
      backgroundColor: themeColors.buttonActiveBackgroundColor,
      color: themeColors.buttonActiveColor,
      border: `1px solid ${themeColors.buttonActiveBorderColor}`,
    },
    '&.Mui-focusVisible': {
      borderColor: 'white',
    },
  })
);

const ObjectiveInfo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gridGap: 4,
  textAlign: 'left',
}));

const ObjectiveWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'applyMargins',
})(({ applyMargins }) => ({
  textAlign: 'left',
  marginTop: applyMargins ? 16 : 0,
  marginBottom: applyMargins ? 16 : 0,
  width: '100%',
}));

export const MultiObjectivesRow = ({
  objectives,
  handleClick,
  selectedObjective,
  applyMargins = true,
}) => (
  <ObjectiveWrapper
    className="intercom-field-objective-scores"
    data-testid="insightsObjectiveScores"
    applyMargins={applyMargins}
  >
    <StyledMultiObjectivesRow objectiveCount={objectives.length}>
      {objectives.map((obj) => (
        <ObjectiveCard
          color="secondary"
          focusRipple={false}
          key={`objective_card_${obj.id}`}
          onClick={() => handleClick(obj)}
          className={selectedObjective.id === obj.id ? 'selectedObjective' : ''}
          objectiveCount={objectives.length}
        >
          {obj.maptualDisplayScore !== -1 && (
            <PowerScore
              score={Math.round(obj.maptualDisplayScore)}
              isSelected={selectedObjective.id === obj.id}
            />
          )}

          <ObjectiveInfo>
            <StyledTitle
              variant="title2"
              isSelected={selectedObjective.id === obj.id}
            >
              {obj.label}
            </StyledTitle>
          </ObjectiveInfo>
        </ObjectiveCard>
      ))}
    </StyledMultiObjectivesRow>
  </ObjectiveWrapper>
);
