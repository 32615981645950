import { useQuery } from 'react-query';
import { useContext, useState } from 'react';
import * as requests from '../request/projectRequests';
import { censorScriptsData } from '../modules/demoCensor';
import { useMetricScriptsMetadata } from './useMetricScriptsMetadata';
import useMaptualContext from './useMaptualContext';
import { AppContext } from '../containers/application/appContext';

const getCacheKey = (object) => Object.values(object);
const validateParams = (params) =>
  Object.values(params).every(
    (value) => value !== undefined && value !== null && value !== ''
  );
export default function useMetricScriptsData(
  activeObjective,
  extraParams = {}
) {
  const { entityId, maptualListId, projectId } = useMaptualContext();
  const { isSuccess: isMetadataLoaded, data: scriptMetadata } =
    useMetricScriptsMetadata();

  const { projectList } = useContext(AppContext);
  const productLineId = projectList.find(
    (proj) => proj.projectId === projectId
  )?.productLineId;

  const [params, setParamState] = useState({
    entityId,
    maptualListId,
    projectId,
    chipMetadata: null,
    productLineId,
  });

  const customHookValues = {
    setParams: (newParams) =>
      setParamState((prevState) => ({ ...prevState, ...newParams })),
  };

  const firstChip = scriptMetadata?.[0]?.chips?.[0];

  if ((!params.chipMetadata || params.entityId !== entityId) && firstChip) {
    setParamState((prevState) => ({
      ...prevState,
      entityId,
      maptualListId,
      projectId,
      chipMetadata: firstChip.chipMetadata,
      productLineId,
    }));
  }

  const queryHookValues = useQuery(
    getCacheKey(params),
    async () => {
      const response = await requests.getScriptsByChip(params);
      const censoredResponse = censorScriptsData(response.data);

      if (!(censoredResponse instanceof Object))
        throw new Error('Unable to parse response from server.');

      return censoredResponse;
    },
    { enabled: isMetadataLoaded && validateParams(params), ...extraParams }
  );

  return { ...customHookValues, ...queryHookValues };
}

export const useLazyMetricScriptsData = () =>
  useMetricScriptsData({
    enabled: false,
  });
