import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import styled from '@emotion/styled';
import EmojiObjects from '@mui/icons-material/EmojiObjects';
import { Typography } from '@mui/material';
import useMaptualContext from '../../../hooks/useMaptualContext';
import { AppContext } from '../../../containers/application/appContext';
import { getEntityMessages } from '../../../request/projectRequests';

const MessagesContainer = styled.div`
  background-color: #16191f;
  border: 1px solid;
  border-color: #27282e;
  border-radius: 7px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  width: 100%;
`;

const MessageText = styled(Typography)`
  text-align: left;
  color: #c2cad6;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-left: 8px;
`;

const MessageIcon = styled(EmojiObjects)`
  color: #5c8cd6;
`;
const fetchEntityMessages = async (params) => {
  try {
    const response = await getEntityMessages(params);
    return response.data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('[ERROR] fetchEntityMessages');
  }
  return {};
};
export const EntityMessages = ({ selectedObjective }) => {
  const { entityId, maptualListId, projectId } = useMaptualContext();
  const { projectList } = useContext(AppContext);

  const productLineId = projectList.find(
    (proj) => proj.projectId === projectId
  )?.productLineId;
  const { objectiveId } = selectedObjective;

  const params = {
    entityId,
    maptualListId,
    projectId,
    productLineId,
    objectiveId,
  };

  const { data, isLoading } = useQuery(
    ['entity-messages', entityId, projectId, maptualListId, objectiveId],
    async () => fetchEntityMessages(params),
    { enabled: !!objectiveId }
  );

  if (isLoading || !data || !data.messages) return null;

  return data.messages.map((message) => (
    <MessagesContainer key={message}>
      <MessageIcon fontSize="small" />
      <MessageText>{message}</MessageText>
    </MessagesContainer>
  ));
};
