import React from 'react';
import PopoverSelectionInput from './popoverSelectionInput';
import {
  Title,
  Description,
  AdditionalDescriptionText,
} from '../styles/styledComponents';

const TerritoriesSelectionInput = ({
  options = {},
  territoryId,
  assignedTerritoryId,
  onChange,
  initialTerritoryIds = [],
  initialAssignedTerritoryIds = [],
  title = '',
  subtitle = '',
}) => {
  const updateTerritoryIds = (val) => {
    onChange(territoryId, val);
  };

  const updateAssignedTerritoryIds = (val) => {
    onChange(assignedTerritoryId, val);
  };

  return (
    <div>
      <Title variant="h4">{title}</Title>
      <Description variant="body1">{subtitle}</Description>
      <AdditionalDescriptionText variant="h6">
        The user's territories
      </AdditionalDescriptionText>
      <PopoverSelectionInput
        buttonText="Add Territories"
        id={territoryId}
        filterOptions={options}
        initialValue={initialTerritoryIds}
        onChange={updateTerritoryIds}
        popoverSearchBarLabel="Find territories"
        popoverButtonText="All Territories"
      />
      <AdditionalDescriptionText variant="h6">
        Other territories the user has access to
      </AdditionalDescriptionText>
      <PopoverSelectionInput
        buttonText="Add Other Territories"
        id={assignedTerritoryId}
        filterOptions={options}
        initialValue={initialAssignedTerritoryIds}
        onChange={updateAssignedTerritoryIds}
        popoverSearchBarLabel="Find territories"
        popoverButtonText="All Territories"
      />
    </div>
  );
};

export default TerritoriesSelectionInput;
