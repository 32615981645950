import { maptualApiInstance } from './config';

export function getDeliveryStatuses() {
  return maptualApiInstance.get(`csv-delivery-dashboard/statuses`, {
    timeout: 500,
  });
}

export const addCsvDeliveryProject = (
  userGroup,
  businessUnit,
  projectId,
  projectName
) =>
  maptualApiInstance.post(
    'csv-delivery-dashboard/add-project',
    {
      user_group: userGroup,
      business_unit: businessUnit,
      project_id: projectId,
      project_name: projectName,
    },
    { timeout: 30000 }
  );

export const updateCsvDeliveryProjectStatus = (projectId, step, status) =>
  maptualApiInstance.put(
    `csv-delivery-dashboard/statuses/${projectId}`,
    {
      step,
      status,
    },
    { timeout: 30000 }
  );

export const resetCsvDeliveryProject = (projectId) =>
  maptualApiInstance.post(
    'csv-delivery-dashboard/reset-project',
    {
      project_id: projectId,
    },
    { timeout: 30000 }
  );

export const resetCsvDeliveryDashboard = () =>
  maptualApiInstance.post(
    'csv-delivery-dashboard/reset-dashboard',
    {},
    { timeout: 60000 }
  );
