/* eslint-disable no-throw-literal */
import * as ingestRequests from '../request/ingestRequests';
import {
  sampleBlob,
  validateBlob,
  ingestNewData,
  validateExistingColumns,
} from '../modules/dataPapaIngestor';
import { ColTagTypeModel } from '../models/ingestDataModel';

export function setActiveStep(index) {
  return {
    type: 'SET_ACTIVE_STEP',
    payload: index,
  };
}

export function completeStep(index) {
  return {
    type: 'COMPLETE_STEP',
    payload: index,
  };
}

export function resetSteps() {
  return { type: 'RESET_STEPS' };
}

export function gotoNextStep() {
  return { type: 'GOTO_NEXT_STEP' };
}

export function newOrAddDataset(actionType) {
  return {
    type: actionType === 'newdataset' ? 'NEW_DATASET' : 'ADD_TO_DATASET',
  };
}

export function onDatasetNameChange(name) {
  return {
    type: 'ON_DATASET_NAME_CHANGE',
    payload: name,
  };
}

export function skipValidation(index) {
  return {
    type: 'SKIP_VALIDATION',
    payload: index,
  };
}

export function clearErrorMsg() {
  return { type: 'CLEAR_ERROR_MSG' };
}

export function validateDatasetName(ingestModel, pageIndex) {
  const name = ingestModel.dataset.datasetName;
  const datasets = Object.keys(ingestModel.customerDatasets);
  if (name === undefined || name === null || name === '') {
    return {
      type: 'VALIDATE_DATASET_NAME_REJECTED',
      payload: { Error: 'Dataset name cannot be empty.' },
    };
  }
  if (datasets.includes(name)) {
    return {
      type: 'VALIDATE_DATASET_NAME_REJECTED',
      payload: { Error: 'Dataset name already exists.' },
    };
  }
  return {
    type: 'VALIDATE_DATASET_NAME_FULFILLED',
    payload: pageIndex,
  };
}

export function getExistingDatasets() {
  return {
    type: 'DATA_INGEST_GET_EXISTING_DATASETS',
    payload: ingestRequests.getExistingDatasets().then((response) => {
      let responseJson = {};
      try {
        responseJson = JSON.parse(response.data);
        responseJson = responseJson.customerDatasets;
      } catch (e) {
        throw { Error: e.message };
      }
      return responseJson;
    }),
  };
}

export function validColumnReview(ingestModel, pageIndex) {
  if (ingestModel.columnMeta.filter((item) => !item.isExcluded).length < 2) {
    return {
      type: 'VALIDATE_COLUMN_REVIEW_REJECTED',
      payload: 'You must select at least 2 columns.',
    };
  }
  if (ingestModel.txtInputErrMsg && ingestModel.txtInputErrMsg.message) {
    return {
      type: 'VALIDATE_COLUMN_REVIEW__REJECTED',
      payload: ingestModel.txtInputErrMsg.message,
    };
  }
  return {
    type: 'VALIDATE_COLUMN_REVIEW_FULFILLED',
    payload: pageIndex,
  };
}

export function validKeyColumns(ingestModel, pageIndex) {
  return {
    type: 'VALIDATE_KEY_COLUMNS',
    payload: validateExistingColumns(ingestModel, pageIndex),
  };
}

export function validJourneyDuration(durationLabel, pageIndex) {
  return {
    type: 'VALIDATE_JOURNEY_DURATION_FULFILLED',
    payload: { durationLabel, pageIndex },
  };
}

export function confirmDataUpload(
  dataset,
  file,
  isNewDataset,
  percentageUpdater
) {
  return {
    type: 'DATA_INGEST_CONFIRM_UPLOAD',
    payload: ingestNewData(dataset, file, isNewDataset, percentageUpdater),
  };
}

export function updateDataUploadProgress(percentage) {
  return {
    type: 'DATA_INGEST_CONFIRM_PROGRESS',
    payload: percentage,
  };
}

export function setDataFile(file, fileName) {
  if (file !== (undefined || null) && fileName !== '')
    return {
      type: 'ON_SELECT_DATAFILE',
      payload: {
        dataFile: file,
        dataFileName: fileName,
      },
    };

  return {};
}

export function sampleDataFile(file) {
  return {
    type: 'SAMPLE_DATAFILE',
    payload: sampleBlob(file),
  };
}

export function validateDataFile(file, dataColumns) {
  return {
    type: 'VALIDATE_DATAFILE',
    payload: validateBlob(file, dataColumns),
  };
}

export function setInclude(index, val) {
  return {
    type: 'DATA_INGEST_INCLUDE_COL',
    payload: {
      index,
      val,
    },
  };
}

export function setColFriendlyName(index, val) {
  return {
    type: 'DATA_INGEST_SET_COL_FRIENDLYNAME',
    payload: {
      index,
      val,
    },
  };
}

export function setColEncryption(index, val) {
  return {
    type: 'DATA_INGEST_SET_COL_ENCRYPTION',
    payload: {
      index,
      val,
    },
  };
}

export function getExistingColumns() {
  return {
    type: 'DATA_INGEST_GET_EXISTING_COLUMNS',
    payload: ingestRequests.getExistingColumns().then((response) => {
      let responseJson = {};
      const keyColumns = [];
      const ctxColumns = [];
      const entryMap = {};
      try {
        responseJson = JSON.parse(response.data);
        responseJson.forEach((item) => {
          if (
            item.colTag.includes(ColTagTypeModel.ACTIVITY_TIMESTAMP) ||
            item.colTag.includes(ColTagTypeModel.ACTIVITY_LABEL_ID) ||
            item.colTag.includes(ColTagTypeModel.USER_ID)
          ) {
            if (!entryMap[item.friendlyName]) {
              entryMap[item.friendlyName] = true;
              keyColumns.push(item);
            }
          } else if (!entryMap[item.friendlyName]) {
            entryMap[item.friendlyName] = true;
            ctxColumns.push(item);
          }
        });
        responseJson = keyColumns.concat(ctxColumns);
      } catch (e) {
        throw { Error: e.message };
      }
      return responseJson;
    }),
  };
}

export function updateColumnMeta(metaData) {
  if (metaData) {
    return {
      type: 'DATA_INGEST_UPDATE_COLUMN_METADATA',
      payload: { columnName: metaData.nameInOriginalSource, data: metaData },
    };
  }
  return {};
}

export function finalizeColumnMeta() {
  return {
    type: 'DATA_INGEST_FINALIZE_COLUMN_META',
    payload: {},
  };
}
