import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getProject as getProjectItemRequest,
  getProjectList as getProjectListRequest,
} from '../request/projectRequests';
import censorResponse from '../modules/demoCensor';
import { sortByNewestCreation } from '../utils/sortProjects';

export const getProjectList = createAsyncThunk(
  'project/getProjectList',
  async (_, thunkApi) => {
    try {
      const response = await getProjectListRequest();
      return censorResponse(response.data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Something went wrong with the request', { err });
      return thunkApi.rejectWithValue(err.message);
    }
  }
);

export const getProjectItem = createAsyncThunk(
  'project/getProjectItem',
  async ({ projectId }, thunkApi) => {
    try {
      const response = await getProjectItemRequest(projectId);
      return censorResponse(response.data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Something went wrong with the request', { err });
      return thunkApi.rejectWithValue(err.message);
    }
  }
);

export const projectInitialState = {
  errorMessage: '',
  isBusy: false,
  isProjectListBusy: false,
  isProjectItemBusy: false,
  projects: [],
  projectItem: null,
};

const projectSlice = createSlice({
  name: 'project',
  initialState: projectInitialState,
  reducers: {
    // load projects saved on current user info
    loadSavedProjects: (state, { payload }) => {
      state.projects = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectList.pending, (state) => {
        state.isBusy = true;
        state.isProjectListBusy = true;
        state.errorMessage = '';
      })
      .addCase(getProjectList.fulfilled, (state, { payload }) => {
        state.isBusy = false;
        state.isProjectListBusy = false;
        state.projects = sortByNewestCreation(payload);
        state.errorMessage = '';
      })
      .addCase(getProjectList.rejected, (state, { payload }) => {
        state.isBusy = false;
        state.isProjectListBusy = false;
        state.errorMessage = payload;
      })
      .addCase(getProjectItem.pending, (state) => {
        state.isBusy = true;
        state.isProjectItemBusy = true;
        state.errorMessage = '';
      })
      .addCase(getProjectItem.fulfilled, (state, { payload }) => {
        state.isBusy = false;
        state.isProjectItemBusy = false;
        state.projectItem = payload;
        state.errorMessage = '';
      })
      .addCase(getProjectItem.rejected, (state, { payload }) => {
        state.isBusy = false;
        state.isProjectItemBusy = false;
        state.errorMessage = payload;
      });
  },
});

export const { loadSavedProjects } = projectSlice.actions;
export default projectSlice.reducer;
