import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import React from 'react';

export const FormTextField = ({ name, control, rules, ...rest }) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field, fieldState }) => (
      <TextField {...field} error={fieldState.invalid} {...rest} />
    )}
  />
);
