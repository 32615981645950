import { styled } from '@mui/material';

export const TwoPaneLayout = styled('div')({
  display: 'grid',
  gridTemplateColumns: '2fr 3fr',
  height: '100%',
  flexGrow: 1,
});

const MARKET_DETAILS_HEIGHT = 86;
export const ProductTreeSectionBody = styled('div')(
  ({ displayTreeDetails }) => ({
    height: `calc(100% - 70px ${
      displayTreeDetails ? `- ${MARKET_DETAILS_HEIGHT}px` : ''
    })`,
  })
);
