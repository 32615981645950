import moment from 'moment';
import { TAB_PATHS } from '../components/headoffice/constants';
import { isCancelled } from '../modules/requestHelpers';
import { getLeafSegments } from '../components/headoffice/helpers';
import { createColumns } from '../components/headoffice/tableview/dynamicColumns';

export const headOfficeInitState = {
  tableColumns: [],
  selectedTab: TAB_PATHS.TABLE,
  isTableRowsBusy: false,
  errorMessage: '',
  projectTableRows: [],
  projectSegments: null,
  selectedSegmentId: '',
  selectedSegment: {},
};

export default function reducer(state = headOfficeInitState, action) {
  switch (action.type) {
    case 'HEAD_OFFICE_VIEW/SET_TAB_VIEW':
      return {
        ...state,
        selectedTab: action.payload,
      };
    case 'PROJECT_GET_TABLE_RESPONSE_PENDING':
      return {
        ...state,
        isTableRowsBusy: true,
        errorMessage: '',
      };
    case 'PROJECT_GET_TABLE_RESPONSE_FULFILLED': {
      return {
        ...state,
        projectTableRows: action.payload.rows.map((row) => {
          const lastContacted = row?.lastContacted
            ? moment(row?.lastContacted).format('YYYY-MM-DD')
            : null;
          return { ...row, lastContacted };
        }),
        tableColumns: createColumns(action.payload.columnConfig),
        errorMessage: '',
        isTableRowsBusy: false,
      };
    }
    case 'PROJECT_GET_TABLE_RESPONSE_REJECTED':
      if (isCancelled(action)) return state;

      return {
        ...state,
        projectTableRows: [],
        tableColumns: [],
        errorMessage: action.payload.Error,
        isTableRowsBusy: false,
      };
    case 'HEAD_OFFICE_VIEW/SET_SEGMENT':
      return {
        ...state,
        selectedSegmentId: action.payload,
        selectedSegment: getLeafSegments(state.projectSegments || []).find(
          (segment) => segment.segmentId === action.payload
        ),
      };
    default:
      return state;
  }
}
