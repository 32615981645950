import { useStore } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { ROUTES } from '../constants';

const useColumnPreferences = () => {
  const store = useStore();
  const reduxState = store.getState();
  const match = useRouteMatch(`${ROUTES.SPHERE}${ROUTES.PROJECTS}/:projectId`);

  const columnPreferences =
    reduxState.app.profile?.preference?.columnPreferences?.[
      match?.params?.projectId
    ];

  return columnPreferences;
};

export default useColumnPreferences;
