export const ColTagTypeModel = Object.freeze({
  ACTIVITY_CONTEXTUAL_DATA: 'A_DATA',
  ACTIVITY_TIMESTAMP: 'TIMESTAMP',
  ACTIVITY_LABEL_ID: 'ACTIVITY',
  CONTEXTUAL_DATA: 'CONTEXTUALDATA',
  CONTEXTUAL_DATA_STATIC: 'CONTEXTUALDATASTATIC',
  USER_ID: 'USERID',
  USER_CONTEXTUAL_DATA: 'U_DATA',
});

export const ColDataTypeModel = Object.freeze({
  BOOLEAN: 'bool',
  DATETIME: 'datetime',
  DATE: 'date',
  FLOAT: 'float',
  INT: 'int',
  STRING: 'string',
});

export const ColModel = Object.freeze({
  associatedColumn: null,
  columnIndexOriginalSource: 0,
  colTag: '',
  datetimeFormat: null,
  description: 'columnDescription',
  hasHeader: false,
  nameInOriginalSource: 'columnName',
  odaiaMetadataId: ColDataTypeModel.STRING,
  friendlyName: 'columnFriendlyName',
  isEncrypted: false,
  isExcluded: false,
});

export const TableModel = Object.freeze({
  columns: [],
  isExcluded: false,
  tableName: '',
});

export const DatasetModel = Object.freeze({
  datasetName: '',
  fileName: '',
  customJourneyDuration: '',
  dataColumns: [],
});
