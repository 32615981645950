/* eslint-disable no-shadow */
import React, { useContext } from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import { useRegionCrmData } from '../../../salesReps/repTable/table/row/hooks/useRegionCrmData';
import { PulseContext } from '../../../../context/PulseContext';
import { BarGraph } from './BarGraph';
import { getObjectiveProducts } from '../../../../shared/utils';

const StyledContainer = styled('div')(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: themeColors.cardBackgroundColor,
  border: `1px solid ${themeColors.buttonBorderColor}`,
  width: '260px',
  borderRadius: '6px',
  padding: '24px',
}));

const StyledHeader = styled(Typography)(() => ({
  fontSize: '15px',
  fontWeight: '400',
  textAlign: 'left',
}));

const StyledLabel = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: '12px',
  margin: 0,
  marginTop: '4px',
  padding: 0,
  color: themeColors.graphAxisColor,
}));

const TitleRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));
const TitleColumn = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const StyledNumber = styled(Typography)(() => ({
  fontSize: '36px',
  lineHeight: 1,
  marginLeft: '5px',
}));

const StyledGraphContainer = styled('div')(() => ({
  marginTop: '14px',
}));

const labels = ['10', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0'];
const scoresColours = [
  '#FF800B',
  '#F0D438',
  '#AC39F4',
  '#AC39F4',
  '#2E6EF9',
  '#2E6EF9',
  '#2E6EF9',
  '#9E9E9E',
  '#9E9E9E',
  '#9E9E9E',
  '#9E9E9E',
];

export const CrmPsCard = ({
  objectiveId,
  cadence,
  timestamp,
  curated = true,
}) => {
  const { projectId, territoryId } = useParams();
  const { productLineId, projectTargetProducts } = useContext(PulseContext);
  const { data } = useRegionCrmData({
    productLineId,
    projectId,
    objectiveId,
    regionId: territoryId.split('?')[0], // todo: fix with routing
    cadence,
    timestamp,
    products: getObjectiveProducts(projectTargetProducts, objectiveId),
  });

  const callCount = ({ curated }) => {
    let value = '--';
    const key = curated ? 'curated' : 'nonCurated';
    if (data?.calls?.[key]) {
      const timestamps = Object.keys(data.calls[key]);
      const lastTimestamp = timestamps[timestamps.length - 1];
      value = data.calls[key][lastTimestamp]?.total || 0;
    }

    return value;
  };

  const distributionData = ({ curated }) => {
    const called = {
      label: 'Called',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: scoresColours,
      borderRadius: 1,
    };
    const remainder = {
      label: 'Not Called',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: ['#313B49'],
      borderRadius: 1,
    };

    const key = curated ? 'curated' : 'nonCurated';
    if (data?.calls?.[key]) {
      const timestamps = Object.keys(data.calls[key]);
      const lastTimestamp = timestamps[timestamps.length - 1];
      // called.label = lastTimestamp;
      called.data = data.calls[key][lastTimestamp].distribution.map(
        (i) => i.count
      );
    }
    if (!curated) return [called]; // turn off distribution for region card

    const remKey = curated ? 'lists' : 'region';
    if (data?.metadata?.[remKey]) {
      const timestamps = Object.keys(data.metadata[remKey]);
      const lastTimestamp = timestamps[timestamps.length - 1];
      // remainder.label = lastTimestamp;
      const distData = [];
      for (let i = 0; i < labels.length; i++) {
        const d1 = data.metadata[remKey][lastTimestamp].distribution[i].count;
        const d2 = data.calls[key][lastTimestamp].distribution[i].count;
        distData.push(d1 - d2);
      }
      remainder.data = distData;
    }

    return [called, remainder];
  };

  return (
    <StyledContainer>
      <TitleRow>
        <TitleColumn>
          <StyledHeader>
            {curated ? '' : 'Non-'}Curated HCPs Called
          </StyledHeader>
          <StyledLabel>Total, By PowerScore</StyledLabel>
        </TitleColumn>
        <StyledNumber>{callCount({ curated })}</StyledNumber>
      </TitleRow>
      <StyledGraphContainer>
        <BarGraph labels={labels} datasets={distributionData({ curated })} />
      </StyledGraphContainer>
    </StyledContainer>
  );
};
