import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import {
  ModalBody,
  ModalDisplay,
  ModalFooter,
  ModalHeader,
} from '../generic/modalDisplay';
import { ModalDisplayCustomStyle } from './styles/styledComponents';
import { NewProjectContext } from './newProjectContext';
import { ObjectiveSummary } from './objectiveSummary';
import { DeployDialogContext } from '../projectviewer/deployCsvDialog/deployDialogContext';
import { PROJECT_CREATION_STEPS } from './common/constants';
import { formatDateToString } from './common/formatters';
import { ProjectModalMode } from './projectModalMode';

const StyledModalBody = styled(ModalBody)({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '36px',
  gridGap: '24px',
});

const ObjectivesWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const SummaryWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});
const ProjectConfigSettingWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});
const SubTitleText = styled(Typography)(({ theme: { themeColors } }) => ({
  fontWeight: 500,
  fontSize: '16px',
  color: themeColors.objectiveTypeEditButtonColor,
}));

const ProjectConfigText = styled(Typography)(({ theme: { themeColors } }) => ({
  fontWeight: 400,
  fontSize: '16px',
  color: themeColors.neutral60,
  whiteSpace: 'pre-line',
}));

const headerMapping = {
  clone: 'Project Clone Summary',
  default: 'New Project Summary',
  [ProjectModalMode.templateCreate]: 'New Template',
};

const actionButtonMapping = {
  clone: 'Clone Project',
  default: 'Complete project creation',
  [ProjectModalMode.templateCreate]: 'Save Template',
};

export default function NewProjectSummaryModal({ isOpen, closeForm }) {
  const { projectModalMode } = useContext(DeployDialogContext);
  const { projectConfig, objectives, setProjectCreationStep, isTemplate } =
    useContext(NewProjectContext);
  const handleClick = () => {
    setProjectCreationStep(PROJECT_CREATION_STEPS.CONFIRMATION);
  };
  const handleClose = () => {
    if (projectModalMode === ProjectModalMode.viewProject) {
      closeForm();
    } else {
      setProjectCreationStep(PROJECT_CREATION_STEPS.CLOSE_PROJECT);
    }
  };

  return (
    <ModalDisplay isOpen={isOpen} style={ModalDisplayCustomStyle}>
      <ModalHeader onClose={handleClose}>
        {headerMapping[projectModalMode] || headerMapping.default}
      </ModalHeader>
      <StyledModalBody>
        <SummaryWrapper>
          <ProjectConfigSettingWrapper>
            <SubTitleText>Project Name</SubTitleText>
            <ProjectConfigText>{projectConfig.projectName}</ProjectConfigText>
          </ProjectConfigSettingWrapper>
          {!isTemplate && (
            <>
              <ProjectConfigSettingWrapper>
                <SubTitleText>Due Date</SubTitleText>
                <ProjectConfigText>
                  {formatDateToString(projectConfig.dueDate)}
                </ProjectConfigText>
              </ProjectConfigSettingWrapper>
              <ProjectConfigSettingWrapper>
                <SubTitleText>Change Log</SubTitleText>
                <ProjectConfigText>{projectConfig.changeLog}</ProjectConfigText>
              </ProjectConfigSettingWrapper>
            </>
          )}
          <ProjectConfigSettingWrapper>
            <SubTitleText>Product Line</SubTitleText>
            <ProjectConfigText>
              {projectConfig.productLineName}
            </ProjectConfigText>
          </ProjectConfigSettingWrapper>
          {projectModalMode === ProjectModalMode.runProjectFromTemplate && (
            <ProjectConfigSettingWrapper>
              <SubTitleText>Migrated Assets</SubTitleText>
              <ProjectConfigText>
                {projectConfig?.migratedProjectName
                  ? `Assets will be migrated from ${projectConfig.migratedProjectName}`
                  : 'Project will run without any existing assets'}
              </ProjectConfigText>
            </ProjectConfigSettingWrapper>
          )}
        </SummaryWrapper>
        <ObjectivesWrapper>
          <ProjectConfigSettingWrapper>
            <SubTitleText>Objectives</SubTitleText>
            {projectModalMode === ProjectModalMode.viewProject ? null : (
              <ProjectConfigText>
                Please review your selected objectives
              </ProjectConfigText>
            )}
          </ProjectConfigSettingWrapper>
          {objectives.map((objective, index) => (
            <ObjectiveSummary
              key={objective.uuid}
              objective={objective}
              number={index + 1}
            />
          ))}
        </ObjectivesWrapper>
      </StyledModalBody>
      <ModalFooter>
        {projectModalMode === ProjectModalMode.viewProject ? (
          <Button onClick={handleClose} variant="outlined">
            Close Configurations
          </Button>
        ) : (
          <>
            <Button
              onClick={() =>
                setProjectCreationStep(PROJECT_CREATION_STEPS.PROJECT)
              }
              variant="outlined"
            >
              Back
            </Button>
            <Button onClick={handleClick} variant="contained">
              {actionButtonMapping[projectModalMode] ||
                actionButtonMapping.default}
            </Button>
          </>
        )}
      </ModalFooter>
    </ModalDisplay>
  );
}
