export const TAB_PATHS = {
  TABLE: 'table',
  DASHBOARD: 'dashboard',
  TERRITORY_TABLE: 'territory-table',
  SEGMENTS_TABLE: 'segments-table',
};

export const CHART_CONTAINER_WIDTH_OPTIONS = {
  Narrow: '550px',
  Wide: '700px',
  'Full Width': '100%',
};
