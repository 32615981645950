import React from 'react';
import { useProductTemplatesQuery } from '../../../request/queries';
import { ProjectTemplatesList } from './projectTemplateList';

export const ProjectTemplateProvider = () => {
  const { data: projectTemplates = [], isLoading } = useProductTemplatesQuery();
  const templatesList = Object.values(projectTemplates);
  return (
    !isLoading &&
    templatesList.length > 0 && (
      <ProjectTemplatesList templates={templatesList} />
    )
  );
};
