import React, { useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import DropdownSelectionInput from './dropdownSelectionInput';
import { userManagementColors } from '../styles/styledComponents';
import { defaultUserRoles } from '../constants';

const groupOptions = ['Field', 'Head Office'];

const departmentOptions = {
  Field: ['Sales Rep', 'Field Medical'],
  'Head Office': ['Marketing', 'Sales Management', 'OpEx', 'Other'],
};

const roleOptions = {
  Field: {
    'Sales Rep': ['Sales Rep'],
    'Field Medical': ['Field Medical'],
  },
  'Head Office': {
    Marketing: ['Marketing / Product Manager', 'Marketing Coordinator'],
    'Sales Management': [
      'National Sales Manager',
      'District / Regional Sales Manager',
    ],
    OpEx: [
      'Omnichannel Manager',
      'Business Insights / Analytics',
      'Sales Operations',
      'CX Manager',
      'CRM Manager',
    ],
    Other: ['Sales Trainer', 'IT', 'Market Access'],
  },
};

const SubtitleText = styled(Typography)(() => ({
  marginTop: 5,
  color: userManagementColors.primaryColor,
}));

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 18,
}));

const UserRoleSelectionInput = ({
  onChange,
  group,
  department,
  formRole,
  error,
}) => {
  const initialDepartmentRender = useRef(false);
  useEffect(() => {
    if (initialDepartmentRender.current) {
      onChange('department', '');
    } else initialDepartmentRender.current = true;
  }, [group]);

  const initialRoleRender = useRef(false);
  useEffect(() => {
    if (initialRoleRender.current) {
      onChange('role', '');
    } else initialRoleRender.current = true;
  }, [department]);

  return (
    <Wrapper>
      <div>
        <Typography variant="h5"> User Role</Typography>
        <SubtitleText variant="body1">
          Set user role based on the user's actual role in their organization
        </SubtitleText>
      </div>

      <DropdownSelectionInput
        id="group"
        label="Group"
        options={groupOptions}
        stateVar={group}
        onChange={onChange}
        placeholderText={defaultUserRoles.group}
        error={error?.group}
        errorMessage="Please select a group"
      />

      <DropdownSelectionInput
        id="department"
        label="Department"
        options={departmentOptions[group]}
        stateVar={department}
        onChange={onChange}
        disabled={group === ''}
        placeholderText={defaultUserRoles.department}
        error={error?.department}
        errorMessage="Please select a department"
      />

      <DropdownSelectionInput
        id="role"
        label="Role"
        options={roleOptions[group]?.[department]}
        stateVar={formRole}
        onChange={onChange}
        disabled={department === ''}
        placeholderText={defaultUserRoles.role}
        error={error?.role}
        errorMessage="Please select a role"
      />
    </Wrapper>
  );
};
export default UserRoleSelectionInput;
