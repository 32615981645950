import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { ModalBody } from '../../generic/modalDisplay';

export const StyledFieldTitle = styled(Typography)(() => ({
  marginBottom: 4,
}));

export const StyledFieldSubtitle = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    marginBottom: 16,
    color: themeColors.neutral60,
  })
);

export const StyledFormControl = styled(FormControl)(() => ({
  width: '100%',
}));

export const SubtitleText = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    marginTop: -25,
    marginBottom: -10,
    color: themeColors.neutral60,
  })
);

export const ModalDisplayCustomStyle = {
  maxHeight: '90%',
  maxWidth: 600,
};

export const StyledModalBody = styled(ModalBody)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.neutral60,
    fontSize: 14,
    padding: '0 20px 45px',
  })
);

export const StyledInputLabel = styled(InputLabel)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.neutral60,
    '&.Mui-focused': {
      color: themeColors.listIconColor,
    },
    '&.Mui-disabled': {
      color: themeColors.listIconColor,
    },
  })
);

export const StyledSelect = styled(Select)(({ theme: { themeColors } }) => ({
  width: '50%',
  '& label.Mui-focused': {
    color: themeColors.neutral60,
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: themeColors.neutral60,
    borderWidth: '1px',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: themeColors.neutral60,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: themeColors.neutral60,
    borderWidth: '1px',
  },
  '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: themeColors.listIconColor,
  },
}));

export const StyledMetricsRow = styled('div')(
  ({ isProjectBuilderDemoMode }) => ({
    maxWidth: isProjectBuilderDemoMode ? 324 : 436,
    display: 'grid',
    gridTemplateColumns: isProjectBuilderDemoMode
      ? '1fr 66px'
      : '1fr 81px 66px',
    gap: 32,
    alignItems: 'center',
  })
);

export const StyledTextField = styled(TextField)(
  ({ isError = false, theme: { themeColors } }) => ({
    width: '100%',
    '& label.Mui-focused': {
      color: isError ? themeColors.danger60 : themeColors.neutral60,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px solid ${
          isError ? themeColors.danger60 : themeColors.neutral60
        }`,
      },
    },
    '& input::placeholder, & textarea::placeholder': {
      opacity: 1,
    },
    '.MuiFormHelperText-root.Mui-error': {
      color: themeColors.danger60,
    },
    '.MuiFormHelperText-root': {
      color: themeColors.neutral60,
    },
  })
);
