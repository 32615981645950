import React, { useState, useContext } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { Typography, Switch, Button } from '@mui/material';
import styled from '@emotion/styled';
import { UserMenuButton } from './userMenuButton';
import {
  ModalDisplay,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '../../generic/modalDisplay';
import { ThemeContext } from '../../../containers/application/themeContext';

const BodyContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 0px',
}));

const TextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Title = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '120%',
  marginBottom: '4px',
}));

const BodyText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tertiaryColor,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '120%',
}));

const CloseButton = styled(Button)(({ theme: { themeColors } }) => ({
  height: '32px',
  backgroundColor: themeColors.primaryColor,
  color: themeColors.maptualListFilteringBadgeColor,
  borderRadius: '4px',
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16px',
}));

export const Preferences = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalClose = () => setIsModalOpen(false);
  const { setActiveThemeByName, ThemeNameOptions, activeThemeName } =
    useContext(ThemeContext);

  return (
    <>
      <UserMenuButton
        onClick={() => setIsModalOpen(true)}
        startIcon={<SettingsIcon />}
        buttonText="Preferences"
      />
      <ModalDisplay isOpen={isModalOpen}>
        <ModalHeader onClose={handleModalClose}>Preferences</ModalHeader>
        <ModalBody>
          <BodyContainer>
            <TextContainer>
              <Title>Dark Mode</Title>
              <BodyText>Enable or disable dark mode.</BodyText>
            </TextContainer>
            <Switch
              checked={activeThemeName === ThemeNameOptions.DARK}
              onChange={() => {
                setActiveThemeByName(
                  activeThemeName === ThemeNameOptions.DARK
                    ? ThemeNameOptions.LIGHT
                    : ThemeNameOptions.DARK
                );
              }}
            />
          </BodyContainer>
        </ModalBody>
        <ModalFooter>
          <CloseButton onClick={handleModalClose} variant="outlined">
            Done
          </CloseButton>
        </ModalFooter>
      </ModalDisplay>
    </>
  );
};
