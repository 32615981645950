import { omit } from 'lodash';
import { ROUTES } from '../../constants';
import { toCapitalize } from '../../utils/toCapitalize';

export const formatMetricName = (name) =>
  name
    .split('_')
    .map((str) => toCapitalize(str))
    .join(' ');

export const getTimescaleTabs = (data) => data?.timescaleKeys;

export const getSelectedTimeframe = (data) => data?.timescaleKeys?.[0]?.id;

export const checkIfTimeframeIDExists = (data, selectedTimeframeID) =>
  data?.timescaleKeys?.some((key) => key.id === selectedTimeframeID);

export const listContainsID = (list, type, id) => {
  if (!list) {
    return false;
  }

  let contains = false;

  switch (type) {
    case 'maptualList':
      contains = Object.keys(list).some((item) => item === id);
      break;

    default:
      break;
  }

  return contains;
};

export const isTerritoryOverviewTimeframeEmpty = (data, selectedTimeframeID) =>
  data instanceof Object &&
  Object.keys(data).length > 0 &&
  Object.keys(data?.timescaleData?.[selectedTimeframeID]).length === 0;

export const isTerritoryOverviewProductEmpty = (data, selectedTimeframeID) =>
  territoryOverviewDataExists(data, selectedTimeframeID) &&
  data?.timescaleData?.[selectedTimeframeID]?.headers?.length < 2;

export const isTerritoryOverviewItemsEmpty = (data, selectedTimeframeID) =>
  territoryOverviewDataExists(data, selectedTimeframeID) &&
  data?.timescaleData?.[selectedTimeframeID]?.items?.length === 0;

export const territoryOverviewDataExists = (data, selectedTimeframeID) => {
  const timeframeIDExists = checkIfTimeframeIDExists(data, selectedTimeframeID);
  const selectedTimeframeHasData = !isTerritoryOverviewTimeframeEmpty(
    data,
    selectedTimeframeID
  );

  return selectedTimeframeID && timeframeIDExists && selectedTimeframeHasData;
};

export const calculateLabelColumnWidth = (timescaleData) => {
  let labelColWidth = 100;

  timescaleData.items.forEach((row) => {
    const rowLabel = row[0].data;
    labelColWidth = Math.max(labelColWidth, rowLabel.length * 9);
  });

  return labelColWidth;
};

const hasValidValue = (value) => {
  if (value && value.length > 0) {
    if (value[0] || (value.length > 1 && value[1])) {
      return true;
    }
  }

  return false;
};

export const isSupplyTableRowEmpty = (row, labelHeaderId) => {
  const timescales = Object.keys(omit(row, ['color', labelHeaderId]));
  const metrics = ['data', 'activity'];

  if (timescales.length < 1) {
    // eslint-disable-next-line no-console
    console.error('isSupplyTableRowEmpty - Row argument is invalid', { row });
    return -1;
  }

  for (let i = 0; i < timescales.length; i++) {
    for (let j = 0; j < metrics.length; j++) {
      const value = row[timescales[i]]?.[metrics[j]];
      if (hasValidValue(value)) {
        return false;
      }
    }
  }

  return true;
};

export const formatSupplyChartXAxis = (timescaleHeaders) => {
  if (
    !timescaleHeaders ||
    !Array.isArray(timescaleHeaders) ||
    timescaleHeaders.length === 0
  ) {
    return [];
  }

  const timescaleHeadersWithoutLabels = timescaleHeaders.filter(
    (header) => header.type !== 'label'
  );

  if (timescaleHeadersWithoutLabels.length < 1) {
    return [];
  }

  let previousSubLabel = timescaleHeadersWithoutLabels[0].subLabel;

  return timescaleHeadersWithoutLabels.map(({ label, subLabel }, index) => {
    const formattedLabel = label.charAt(0) + label.slice(1).toLowerCase();

    if (previousSubLabel !== subLabel) {
      previousSubLabel = subLabel;
      return [formattedLabel, subLabel];
    }

    if (index === timescaleHeadersWithoutLabels.length - 1) {
      return [formattedLabel, 'NOW'];
    }

    return formattedLabel;
  });
};

export const formatSupplyChartSeries = (selectedSupplyRows) =>
  selectedSupplyRows.map((item) => {
    if (item?.[0]?.data) {
      return {
        name: 'volume',
        data: Object.values(omit(item, ['id', 'color', 0])).map(
          ({ data }) => data?.[data.length - 1] ?? 0
        ),
      };
    }

    if (item?.[0]?.activity) {
      return {
        name: 'activity',
        data: Object.values(omit(item, ['id', 'color', 0])).map(
          ({ activity }) => activity?.[0] ?? 0
        ),
      };
    }

    return {};
  });

export const getTerritoryRoute = (row, maptualListId, projectId) => {
  const tableRowData = Object.values(row);
  const territory = tableRowData.find(
    (dataObject) => dataObject?.data && typeof dataObject?.data === 'string'
  )?.data;

  const projectAndRegionUrl = `${ROUTES.PROJECTS}/${projectId}/maptualListId/${maptualListId}`;
  return `${
    ROUTES.FIELD
  }${projectAndRegionUrl}?filterTerritories=${encodeURIComponent(territory)}`;
};

export const isEmptyDemandItem = (item) =>
  Array.isArray(item?.data) &&
  item.data.every((data) => data === null) &&
  item.trend &&
  item.trend.value === null &&
  item.trend.type === 'TREND' &&
  item.trend.status === -1;

export const getTimeframeName = (data, timeframeID) =>
  data?.timescaleKeys?.find((timescale) => timescale.id === timeframeID)?.name;
