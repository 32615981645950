import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Scrollbar from 'react-scrollbars-custom';
import { darkThemeColors } from '../../styles/colorConfig';
import { Content } from './content';
import SectionLabel from '../labels/sectionLabel';
import Section from './section';
import { ProjectContext } from '../../contexts/projectContexts';
import ExpandButton from '../input/expandButton';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    marginBottom: '1.5vh',
    marginTop: '1vh',
  },
  horContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    borderBottomStyle: 'solid',
    borderWidth: 1,
    borderColor: darkThemeColors.tertiaryColor,
  },
  verContainer: {
    width: 'auto',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: '0.5vw',
  },
  scrollAreaStyle: {
    width: '100%',
    height: '100%',
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '3vh',
    paddingRight: 20,
  },
}));

export default function HeaderedSection({
  injectableControl,
  primaryText = null,
  secondaryText = null,
  tertiaryText = null,
  match,
  headerContent,
  sections,
}) {
  const classes = useStyles();

  const { isAnalysisExpanded, setAnalysisExpanded } =
    useContext(ProjectContext);

  return (
    <div className={classes.root}>
      <div className={classes.horContainer}>
        {injectableControl || null}
        <div className={classes.verContainer}>
          {primaryText ? (
            <SectionLabel text={primaryText} type="primaryText" match={match} />
          ) : null}
          {secondaryText ? (
            <SectionLabel
              text={secondaryText}
              type="secondaryText"
              match={match}
            />
          ) : null}
          {tertiaryText ? (
            <SectionLabel
              text={tertiaryText}
              type="tertiaryText"
              match={match}
            />
          ) : null}
        </div>
        {headerContent ? (
          <>
            <Content
              content={headerContent}
              isColumn={false}
              match={match}
              containerStyle={{ justifyContent: 'flex-start' }}
            />
            {isAnalysisExpanded && setAnalysisExpanded ? (
              <ExpandButton
                isExpanded={isAnalysisExpanded}
                handleExpandClick={setAnalysisExpanded}
              />
            ) : null}
          </>
        ) : null}
      </div>
      <Scrollbar
        disableTracksWidthCompensation
        noScrollX
        className={classes.scrollAreaStyle}
      >
        <div className={classes.sectionContainer}>
          {Array.isArray(sections)
            ? sections.map((item, i) => (
                <Section {...item} match={match} key={i} />
              ))
            : null}
        </div>
      </Scrollbar>
    </div>
  );
}

HeaderedSection.propTypes = {
  injectableControl: PropTypes.any,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  tertiaryText: PropTypes.string,
  match: PropTypes.object,
  headerContent: PropTypes.object,
  sections: PropTypes.array,
};
