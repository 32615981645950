import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import * as requests from '../request/projectRequests';
import { censorObjectives } from '../modules/demoCensor';

export const useObjectives = () => {
  const projectId = useSelector(
    (state) => state?.projectViewer?.projectItem?.metadata?.projectId
  );
  const params = {
    projectId,
    cacheString: 'objectives',
  };
  const queryCacheKey = Object.values(params);
  return useQuery(queryCacheKey, async () => {
    try {
      let response = await requests.getObjectives({ projectId });
      response = censorObjectives(response.data);
      return response;
    } catch {
      return [];
    }
  });
};
