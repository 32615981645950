import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { ConfigViewContext } from './configViewContext';
import { TotalMetricsModal } from './totalMetricsModal';
import { getMetricsForMarket } from '../../request/configViewRequests';

export function TotalMetricsModalProvider({ isOpen, onClose, marketName }) {
  const { regionalTeamId } = useContext(ConfigViewContext);
  const { data, isLoading, isSuccess } = useQuery(
    regionalTeamId,
    async () => {
      const response = await getMetricsForMarket(regionalTeamId);
      return response.data;
    },
    { enabled: !!regionalTeamId }
  );
  return (
    <TotalMetricsModal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      marketName={marketName}
      metrics={data?.metrics}
    />
  );
}
