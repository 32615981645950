// targets FIELD object dropdown, inside hamburger sidebar menu on the top left
import { getDemoAccountStatus } from '../../request/config';
import { getProjectItem, getProjectList } from '../../slices/project';

export const anonymizeProjectList = (projects) =>
  projects.map((project, i) => ({
    ...project,
    projectName: `Target Project Name ${(i + 1).toString().padStart(2, '0')}`,
    market: `Market Name ${(i + 1).toString().padStart(2, '0')}`,
  }));

export const anonymizeProjectItem = (project) => ({
  metadata: {
    ...project,
    projectName: 'Target Project Name',
    market: 'Market Name',
  },
});

export const anonymizeGetProjectListResponse = () => (next) => (action) => {
  const modifiedAction = { ...action };
  const isDemo = getDemoAccountStatus();

  if (isDemo && action.type === getProjectList.fulfilled.type) {
    const projects = modifiedAction.payload;
    modifiedAction.payload = anonymizeProjectList(projects);
  }

  return next(modifiedAction);
};

export const anonymizeGetProjectItemResponse = () => (next) => (action) => {
  const modifiedAction = { ...action };
  const isDemo = getDemoAccountStatus();

  if (isDemo && action.type === getProjectItem.fulfilled.type) {
    const { metadata: project } = modifiedAction.payload;
    modifiedAction.payload = {
      ...modifiedAction.payload,
      ...anonymizeProjectItem(project),
    };
  }

  return next(modifiedAction);
};

// targets FIELD top left section for the project name
export const anonymizeDisplayProjectNameEntitySelection =
  () => (next) => (action) => {
    const modifiedAction = action;
    const isDemo = getDemoAccountStatus();

    if (
      isDemo &&
      action.type === 'PROJECT_SET_PROJECT_ITEM' &&
      action.payload
    ) {
      const { metadata } = modifiedAction.payload;
      const newMetadata = { ...metadata, projectName: 'Target project name' };

      modifiedAction.payload = { metadata: newMetadata };
    }

    return next(modifiedAction);
  };
