import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import { createBrowserHistory } from 'history';
import { Provider as ReduxProvider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import store from './store';
import 'rsuite/lib/styles/themes/dark/index.less';
import { currentEnvironment } from './request/config';

const browserHistory = createBrowserHistory();

if (currentEnvironment !== 'localhost') {
  Sentry.init({
    dsn: 'https://51161fab93824bc5a948e13650782d2d@o1300181.ingest.sentry.io/6639145',
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history: browserHistory,
      }),
    ],
    tracesSampleRate: 0.1,
    environment: currentEnvironment,
    release: process?.env?.REACT_APP_SENTRY_RELEASE ?? 'local',
  });
}

const oneHourInMs = 60 * 60 * 1000;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: oneHourInMs,
    },
  },
});

ReactDOM.render(
  <ReduxProvider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </ReduxProvider>,
  document.getElementById('maptualRoot')
);
