import { createLogger } from 'redux-logger';
import promise from 'redux-promise-middleware';
import { configureStore } from '@reduxjs/toolkit';

import { ENVIRONMENTS } from './constants';

import { anonymizeGetEntityResponse } from './middlewares/anonymize/getEntity';
import { anonymizeGetScriptsResponse } from './middlewares/anonymize/getScripts';
import { anonymizeGetShareResponse } from './middlewares/anonymize/getShare';
import {
  anonymizeEntityNameOnGetMaptualListResponse,
  anonymizeSegmentsOnGetMaptualListResponse,
} from './middlewares/anonymize/getMaptualList';
import {
  anonymizeGetProjectListResponse,
  anonymizeDisplayProjectNameEntitySelection,
  anonymizeGetProjectItemResponse,
} from './middlewares/anonymize/getProject';
import { anonymizeSphereTableInfo } from './middlewares/anonymize/getTableResponse';
import singlePermissionAppSelectionMiddleware from './middlewares/appSelection';
import { anonymizeCustomerTerritoriesOnGetMaptualListsResponse } from './middlewares/anonymize/getMaptualLists';
import { anonymizeCustomerTerritoriesOnGetProjectSegmentsResponse } from './middlewares/anonymize/getProjectSegments';

import app from './reducers/appReducer';
import ingest from './reducers/ingestReducer';
import ingestNav from './reducers/ingestNavReducer';
import projectViewer from './reducers/projectViewReducer';
import userManagement from './slices/userManagement';
import projectSearch from './reducers/projectSearchReducer';
import tasksView from './reducers/tasksReducer';
import { updateTableColumnPreferences } from './slices/profile';

const emptyMiddleware = () => (next) => (action) => next(action);
const suppressStateLoggingToConsole = () => ({});
const logger =
  process.env.REACT_APP_NODE_ENV === ENVIRONMENTS.DEVELOPMENT
    ? createLogger({
        diff: false,
        collapsed: true,
        stateTransformer: suppressStateLoggingToConsole,
      })
    : emptyMiddleware;

const anonymizeMiddlewares = [
  anonymizeGetEntityResponse,
  anonymizeGetScriptsResponse,
  anonymizeGetShareResponse,
  anonymizeGetProjectListResponse,
  anonymizeGetProjectItemResponse,
  anonymizeDisplayProjectNameEntitySelection,
  anonymizeEntityNameOnGetMaptualListResponse,
  anonymizeSegmentsOnGetMaptualListResponse,
  anonymizeSphereTableInfo,
  anonymizeCustomerTerritoriesOnGetMaptualListsResponse,
  anonymizeCustomerTerritoriesOnGetProjectSegmentsResponse,
];

const customMiddlewares = [
  singlePermissionAppSelectionMiddleware,
  ...anonymizeMiddlewares,
];

const sphereActionWithNonSerializableValue = [
  updateTableColumnPreferences.type,
];

const sphereActionsWithPromises = [
  'PROJECT_GET_TABLE_RESPONSE',
  'HEAD_OFFICE_VIEW/GET_SEARCH_RESPONSE',
];

const ingestActionsWithPromises = [
  'DATA_INGEST_GET_EXISTING_DATASETS',
  'VALIDATE_KEY_COLUMNS',
  'DATA_INGEST_CONFIRM_UPLOAD',
  'SAMPLE_DATAFILE',
  'VALIDATE_DATAFILE',
  'DATA_INGEST_GET_EXISTING_COLUMNS',
];

const newProjectActionsWithPromises = [
  'NEW_PROJECT_GET_OBJECTIVE_TYPES_WITH_PRODUCT_LINES',
  'NEW_PROJECT_GET_OBJECTIVE_TYPES',
  'NEW_PROJECT_GET_A_PRODUCT_LINE',
  'NEW_PROJECT_GET_MARKETS',
  'NEW_PROJECT_SEND_PROJECT',
];

const projectViewActionsWithPromises = [
  'PROJECT_GET_PROJECT_SEGMENTS',
  'PROJECT_GET_DASHBOARD_STRUCTURE',
  'PROJECT_GET_DASHBOARD_SUB_URL',
  'PROJECT_GET_ANALYSIS_ITEM',
  'PROJECT_GET_ANALYSIS_LIST',
  'PROJECT_GET_MAPTUAL_LIST',
  'PROJECT_GET_MAPTUAL_LISTS',
  'PROJECT_GET_ENTITY_BY_MAPTUALLIST_ID',
  'PROJECT_GET_SCRIPTS_BY_MAPTUALLIST_ID',
  'PROJECT_GET_SHARE_BY_MAPTUALLIST_ID',
  'PROJECT_GET_TABLE_RESPONSE_FULFILLED',
  'PROJECT_UPDATE_METADATA',
  'PROJECT_GET_INSIGHTS',
];

export default configureStore({
  reducer: {
    app,
    ingest,
    ingestNav,
    projectViewer,
    projectSearch,
    tasksView,
    userManagement,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [
          ...sphereActionWithNonSerializableValue,
          ...sphereActionsWithPromises,
          ...ingestActionsWithPromises,
          ...newProjectActionsWithPromises,
          ...projectViewActionsWithPromises,
        ],
        ignoredPaths: [
          'projectViewer.tableColumns',
          'projectViewer.projectTableRows',
          'projectSearch.rows',
        ],
      },
    }).concat([...customMiddlewares, promise, logger]),
});
