import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto';
import 'typeface-roboto';
import { APPVIEWS } from '../../constants';
import {
  getRequiredAction as getRequiredActionFromBackend,
  selectAppThunk,
} from '../../slices/app';
import { initializeAppTracking } from '../../trackers/appEventTracker';
import { refreshAccessToken } from '../../request/requests';
import { getCurrentAppView } from './getCurrentAppView';
import ProfileLoadingView from './appViews/profileLoadingView';
import { AppContext } from './appContext';
import { getCurrentUser as getCurrentUserAsyncThunk } from '../../slices/profile';
import { initializeRudderAnalytics } from '../../rudderstack';

import { AppSelection } from './appViews/AppSelection';
import { Admin } from './appViews/Admin';
import { Sphere } from './appViews/Sphere';
import { Field } from './appViews/field/Field';
import { Pulse } from './appViews/pulse/Pulse';
import { usePreloadFeatureToggles } from '../../hooks/useFeatureToggles';
import { usePreloadAuthorization } from '../../hooks/useIsAuthorized';

const FIFTEEN_MINUTES_INTERVAL = 15 * 60 * 1000;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  content: {
    flexGrow: 1,
  },
}));

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  reloadWindowViaRequiredAction: () => dispatch(getRequiredActionFromBackend()),
  selectApp: (app) => dispatch(selectAppThunk(app)),
});

const Application = function ({
  app,
  reloadWindowViaRequiredAction,
  selectApp,
}) {
  const classes = useStyles();
  const { themeColors } = useTheme();

  const { appView: appViewFromState, requiredAction, profileLoaded } = app;

  const { profile } = useContext(AppContext);
  const [profileLoading, setProfileLoading] = useState(true);
  const [maptualAccess, setMaptualAccess] = useState([]);
  const dispatch = useDispatch();
  const { isLoading: isFeatureTogglesLoading } = usePreloadFeatureToggles();
  const { isLoading: isAuthorizationLoading } = usePreloadAuthorization();
  const isPrerequisiteDataLoaded = [
    profileLoading,
    isFeatureTogglesLoading,
    isAuthorizationLoading,
  ].every((isLoading) => isLoading === false);

  const location = new URLSearchParams(window.location.search);
  if (location.get('redirectUrl')) {
    selectApp(APPVIEWS.FIELD);
  }
  useEffect(() => {
    if (!profileLoaded) {
      /**
       * TODO: Remove getCurrentUserAsyncThunk when we're able to
       *
       * Unfortunately the whole application is too coupled with side effects
       * after the profile loads on Redux Async Thunk
       *
       * Example: maptualLists HCP entity filtering depends on information populated after
       * loading the profile onto our Redux state
       */
      dispatch(getCurrentUserAsyncThunk());
    }
  }, [profileLoaded]);

  useEffect(() => {
    if (!profile.error && profile?.name !== 'Loading') {
      setProfileLoading(false);
    }

    if (!profile.error && profile.role?.maptualAccess) {
      setMaptualAccess(profile.role.maptualAccess);
      initializeRudderAnalytics({ profile });
    }
  }, [profile]);

  const appView = getCurrentAppView({
    maptualAccessList: maptualAccess,
    appViewState: appViewFromState,
  });

  useEffect(() => {
    if (requiredAction) {
      switch (requiredAction) {
        /**
         * The side effect of calling reloadWindowViaRequiredAction()
         *
         * Function getRequiredActionFromBackend fetches from the API whether a user
         * has a config that requires the page to reload every 15 minutes
         */
        //
        case 'REFRESH':
          window.location.reload();
          break;
        default:
          break;
      }
    }
  }, [requiredAction]);

  let backgroundTask = null;
  const backgroundTaskHandler = () => {
    reloadWindowViaRequiredAction();
    refreshAccessToken();
  };

  useEffect(() => {
    if (profile?.userId && profile?.userGroup) {
      if (backgroundTask === null || backgroundTask === undefined) {
        backgroundTask = setInterval(
          backgroundTaskHandler,
          FIFTEEN_MINUTES_INTERVAL
        );
      }

      initializeAppTracking({
        profile,
        maptualAccess,
      });
    } else if (backgroundTask) {
      clearInterval(backgroundTask);
      backgroundTask = null;
    }
  }, [profile?.userId, profile?.userGroup]);

  const renderApp = () => {
    const needsToSelectApp = !appView && Array.isArray(maptualAccess);
    const ViewComponent = {
      [APPVIEWS.FIELD]: Field,
      [APPVIEWS.SPHERE]: Sphere,
      [APPVIEWS.HEADOFFICE_OLDNAME]: Sphere,
      [APPVIEWS.ADMIN]: Admin,
      [APPVIEWS.PULSE]: Pulse,
    }[appView];

    if (needsToSelectApp)
      return (
        <AppSelection maptualAccess={maptualAccess} selectApp={selectApp} />
      );

    return <ViewComponent profile={profile} appView={appView} />;
  };

  return (
    <div
      className={classes.root}
      style={
        appView === APPVIEWS.FIELD
          ? { backgroundColor: themeColors.mainBackground }
          : {}
      }
    >
      <CssBaseline />

      {isPrerequisiteDataLoaded ? (
        renderApp()
      ) : (
        <ProfileLoadingView isBusy hasError={!!profile.error} />
      )}
    </div>
  );
};

Application.propTypes = {
  app: PropTypes.shape({
    appView: PropTypes.string,
    profile: PropTypes.shape({
      email: PropTypes.string,
      fullName: PropTypes.string,
      name: PropTypes.string,
      organization: PropTypes.shape({
        organizationName: PropTypes.string,
      }),
      role: PropTypes.shape({
        specialType: PropTypes.string,
        subType: PropTypes.string,
        type: PropTypes.string,
      }),
      userId: PropTypes.string,
    }),
    profileLoaded: PropTypes.bool,
    requiredAction: PropTypes.string,
  }).isRequired,
  reloadWindowViaRequiredAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Application);
