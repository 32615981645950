import { baseAlbInstance } from './config';

export function getFeatureToggleData() {
  return baseAlbInstance.get(`featureTogglesV2`, {
    timeout: 30000,
  });
}

export function createNewToggle({ name, description, initialState, type }) {
  return baseAlbInstance.post(
    `featureTogglesV2`,
    { name, description, initialState, type },
    {
      timeout: 30000,
    }
  );
}

export function deleteFeatureToggle(name) {
  return baseAlbInstance.delete(`/featureTogglesV2/name/${name}`);
}

export function editExistingToggle({ name, description }) {
  return baseAlbInstance.patch(
    `featureTogglesV2`,
    { name, description, action: 'EDIT_DESCRIPTION' },
    {
      timeout: 30000,
    }
  );
}

export function enableForAllGroups(name) {
  return baseAlbInstance.patch(
    `featureTogglesV2`,
    { name, action: 'ENABLE' },
    {
      timeout: 30000,
    }
  );
}

export function disableForAllGroups(name) {
  return baseAlbInstance.patch(
    `featureTogglesV2`,
    { name, action: 'DISABLE' },
    {
      timeout: 30000,
    }
  );
}

export function toggleMultiple({ names, clientGroup }) {
  return baseAlbInstance.patch(
    `featureTogglesV2`,
    { names, clientGroup, action: 'TOGGLE_MULTIPLE' },
    {
      timeout: 30000,
    }
  );
}

export async function getFeatureTogglesv2() {
  return baseAlbInstance.get(`/featureTogglesV2`, {
    timeout: 90000,
  });
}
