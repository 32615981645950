// targets FIELD Entity List on the left side
import { getDemoAccountStatus } from '../../request/config';

export const anonymizeEntityNameOnGetMaptualList = (maptualList) => {
  if (!maptualList || !maptualList.list || !maptualList.list.items) {
    return maptualList;
  }
  const { items: listItems } = maptualList.list;
  const censoredItems = listItems.map((entity) => ({
    ...entity,
    primaryText: `Entity Name ${entity.entityId}`,
  }));

  return {
    ...maptualList,
    list: {
      ...maptualList.list,
      items: censoredItems,
    },
  };
};

export const anonymizeSegmentsOnGetMaptualList = (maptualList) => {
  const { list, segments, metadata } = maptualList;
  const { items: listItems } = list;

  const anonItems = listItems.map((entity) => {
    const anonSegments = entity.segments.map((entitySegment) => {
      const matchingSegment = segments.find(
        (s) => s.name === entitySegment.segmentName
      );
      const indexOfMatchingSegment = segments.indexOf(matchingSegment);

      return isCustomerSegment(matchingSegment)
        ? {
            ...entitySegment,
            segmentName: `Customer Segment ${indexOfMatchingSegment}`,
          }
        : entitySegment;
    });

    return {
      ...entity,
      segments: anonSegments,
    };
  });

  const anonSegments = segments.map((segment, i) =>
    typeof segment !== 'string' && isCustomerSegment(segment)
      ? { ...segment, name: `Customer Segment ${i}` }
      : segment
  );

  const anonymizedMaptualListMetadata = {
    ...metadata,
    listName: 'Territory Name',
  };

  return {
    ...maptualList,
    list: {
      ...maptualList.list,
      items: anonItems,
    },
    segments: anonSegments,
    metadata: anonymizedMaptualListMetadata,
  };
};

export const anonymizeEntityNameOnGetMaptualListResponse =
  () => (next) => (action) => {
    const modifiedAction = action;
    const isDemo = getDemoAccountStatus();

    if (isDemo && action.type === 'PROJECT_GET_MAPTUAL_LIST_FULFILLED') {
      const { items: listItems } = modifiedAction.payload.list;
      modifiedAction.payload.list.items = listItems.map((entity) => ({
        ...entity,
        primaryText: `Entity Name ${entity.entityId}`,
      }));
    }

    return next(modifiedAction);
  };

const isCustomerSegment = (segmentObject) =>
  segmentObject?.segmentType === 'CUSTOMER';

export const anonymizeSegmentsOnGetMaptualListResponse =
  () => (next) => (action) => {
    const modifiedAction = action;
    const isDemo = getDemoAccountStatus();

    if (isDemo && action.type === 'PROJECT_GET_MAPTUAL_LIST_FULFILLED') {
      const { list, segments, metadata } = modifiedAction.payload;
      const { items: listItems } = list;

      const anonItems = listItems.map((entity) => {
        const anonSegments = entity.segments.map((entitySegment) => {
          const matchingSegment = segments.find(
            (s) => s.name === entitySegment.segmentName
          );
          const indexOfMatchingSegment = segments.indexOf(matchingSegment);

          return isCustomerSegment(matchingSegment)
            ? {
                ...entitySegment,
                segmentName: `Customer Segment ${indexOfMatchingSegment}`,
              }
            : entitySegment;
        });

        return {
          ...entity,
          segments: anonSegments,
        };
      });

      const anonSegments = segments.map((segment, i) =>
        typeof segment !== 'string' && isCustomerSegment(segment)
          ? { ...segment, name: `Customer Segment ${i}` }
          : segment
      );

      const anonymizedMaptualListMetadata = {
        ...metadata,
        listName: 'Territory Name',
      };

      modifiedAction.payload = {
        ...modifiedAction.payload,
        list: {
          ...modifiedAction.payload.list,
          items: anonItems,
        },
        segments: anonSegments,
        metadata: anonymizedMaptualListMetadata,
      };
    }

    return next(modifiedAction);
  };
