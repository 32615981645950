import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import * as layoutStyles from '../../styles/layoutStyles';
import { darkThemeColors } from '../../styles/colorConfig';
import { trackTabNavigation } from '../../trackers/appEventTracker';

const useStyles = makeStyles((theme) => ({
  inlineContainer: {
    ...layoutStyles.inlineContainer,
    width: '20rem',
    minWidth: '15rem',
    maxWidth: '30rem',
    paddingLeft: '3vw',
    paddingTop: '2vh',
    paddingBottom: '1vh',
  },
  scrollAreaStyle: {
    width: '100%',
    height: '100%',
    borderRightStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.background.paper,
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  treeItemStyle: {
    width: '90%',
    height: 'auto',
    display: 'flex',
    padding: '0.5rem',
  },
  treeItemTextStyle: {
    lineHeight: '105%',
    padding: 0,
    margin: 0,
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    align: 'left',
    color: theme.palette.text.primary,
  },
  featureSectionText: {
    fontSize: 14,
    fontWeight: 500,
    color: darkThemeColors.tertiaryColor,
    marginBottom: '0.75rem',
    marginTop: '0.75rem',
  },
  featureSectionDivider: {
    borderTopStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.background.paper,
    marginTop: '2rem',
    paddingTop: '2rem',
  },
  rootTree: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
}));

export default function ProjectFeatureNav({
  viewer: {
    selectedFeature,
    featureIdMap,
    projectItem: { featureSets },
  },
  setFeatureSelection,
  setFeatureIdMapping,
  setAnalysisIdMapping,
  match,
  location,
}) {
  const classes = useStyles();

  const routeHistory = useHistory();

  const featureMap = {};
  const analysisMap = {};

  function mapFeatures(features) {
    return features.map((item) => {
      const id = item.featureSetId;
      if (featureMap[id] === null || featureMap[id] === undefined) {
        featureMap[id] = item;
        if (
          item.analysisItem &&
          item.analysisItem.metadata &&
          item.analysisItem.metadata.analysisId
        )
          analysisMap[item.analysisItem.metadata.analysisId] = id;
      }
      if (item.featureSetType === 'FEATURE_SET_SECTION') {
        return (
          <div className={classes.columnContainer} key={id}>
            <Typography
              variant="subtitle1"
              className={classes.featureSectionText}
              align="left"
            >
              {item.featureSetName.toUpperCase()}
            </Typography>
            {Array.isArray(item.subFeatureSets)
              ? mapFeatures(item.subFeatureSets)
              : null}
          </div>
        );
      }
      if (item.featureSetType === 'FEATURE_SET_SECTION_DIVIDE') {
        return (
          <div className={classes.featureSectionDivider} key={id}>
            {Array.isArray(item.subFeatureSets)
              ? mapFeatures(item.subFeatureSets)
              : null}
          </div>
        );
      }
      return (
        <TreeItem
          style={{ marginBottom: 5 }}
          nodeId={id}
          label={
            <div className={classes.treeItemStyle}>
              <p className={classes.treeItemTextStyle}>{item.featureSetName}</p>
            </div>
          }
          key={id}
        >
          {Array.isArray(item.subFeatureSets)
            ? mapFeatures(item.subFeatureSets)
            : null}
        </TreeItem>
      );
    });
  }

  const features = Array.isArray(featureSets) ? mapFeatures(featureSets) : null;

  useEffect(() => {
    if (Array.isArray(featureSets)) {
      setFeatureIdMapping(featureMap);
      setAnalysisIdMapping(analysisMap);
      if (match?.params?.projectId) {
        routeHistory.push(`${location.pathname}${location.search}`);
      }
      setFeatureSelection(
        featureSets[0]?.subFeatureSets[0] || featureSets[0] || null
      );
    }
  }, [featureSets]);

  return (
    <div className={classes.inlineContainer}>
      <Scrollbar
        disableTracksWidthCompensation
        className={classes.scrollAreaStyle}
      >
        {Array.isArray(featureSets) ? (
          <TreeView
            style={{ marginRight: '1vw' }}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            selected={selectedFeature ? [selectedFeature.featureSetId] : []}
            onNodeSelect={(event, featureIds) => {
              if (match && match.params && match.params.projectId) {
                routeHistory.push(`/projects/${match.params.projectId}`);
              }
              trackTabNavigation(
                featureIdMap[featureIds] &&
                  featureIdMap[featureIds].featureSetName
                  ? featureIdMap[featureIds].featureSetName
                  : 'unknown'
              );
              setFeatureSelection(
                featureIdMap[featureIds] ? featureIdMap[featureIds] : null
              );
            }}
          >
            {features}
          </TreeView>
        ) : null}
      </Scrollbar>
    </div>
  );
}
