import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Fade from '@mui/material/Fade';
import { CardActionArea } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AdminViewIcon } from '../../assets/appIcons/Admin.svg';
import { ReactComponent as FieldViewIcon } from '../../assets/appIcons/Field.svg';
import { ReactComponent as SphereViewIcon } from '../../assets/appIcons/Sphere.svg';
import { ReactComponent as PulseIcon } from '../../assets/appIcons/Pulse.svg';
import { ReactComponent as LabsViewIcon } from '../../assets/appIcons/Labs.svg';
import { APPVIEWS, ROUTES } from '../../constants';
import useFeatureToggles from '../../hooks/useFeatureToggles';
import { AppContext } from '../../containers/application/appContext';

const useStyles = makeStyles(({ insightContentCardStyle, themeColors }) => ({
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: themeColors.mainBackground,
    gap: '30px',
  },
  card: {
    ...insightContentCardStyle('darkTheme'),
    width: '20%',
    height: '30%',
    minWidth: 150,
    minHeight: 150,
    padding: 0,
    marginBottom: 0,
    backgroundColor: themeColors.contentCardBackgroundColor,
    borderColor: themeColors.contentCardBorderColor,
  },
  cardActionArea: {
    padding: 15,
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  iconStyle: {
    height: '80%',
  },
}));

const isAdminUser = (profile) => profile?.role?.type === 'ADMIN';

export default function AppSelectView({
  maptualAccess = [],
  selectApp = () => {},
}) {
  const classes = useStyles();
  const routeHistory = useHistory();
  const { profile } = useContext(AppContext);
  const getFeatureToggle = useFeatureToggles();

  const appIconMap = {
    [APPVIEWS.FIELD]: <FieldViewIcon className={classes.iconStyle} />,
    [APPVIEWS.SPHERE]: <SphereViewIcon className={classes.iconStyle} />,
    [APPVIEWS.ADMIN]: <AdminViewIcon className={classes.iconStyle} />,
    [APPVIEWS.LABS]: <LabsViewIcon className={classes.iconStyle} />,
    [APPVIEWS.PULSE]: <PulseIcon className={classes.iconStyle} />,
    [APPVIEWS.HEADOFFICE_OLDNAME]: (
      <SphereViewIcon className={classes.iconStyle} />
    ),
  };

  const showMaptualLabs =
    getFeatureToggle(profile?.userGroup, 'showMaptualLabs') &&
    isAdminUser(profile);

  return (
    <Fade in timeout={1400}>
      <div className={classes.cardContainer}>
        {showMaptualLabs ? (
          <div className={classes.card} key="LABS">
            <CardActionArea
              data-testid="test-app-select-LABS"
              className={classes.cardActionArea}
              onClick={() => {
                window.location.assign('https://apps.odaia.ai');
              }}
            >
              {appIconMap.LABS || null}
            </CardActionArea>
          </div>
        ) : null}

        {maptualAccess.map((item) => (
          <div className={classes.card} key={item}>
            <CardActionArea
              data-testid={`test-app-select-${item}`}
              className={classes.cardActionArea}
              onClick={() => {
                selectApp(item);
                routeHistory.push(ROUTES.ROOT);
              }}
            >
              {appIconMap[item] || null}
            </CardActionArea>
          </div>
        ))}
      </div>
    </Fade>
  );
}
