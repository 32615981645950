import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import isValidProp from '@emotion/is-prop-valid';

const HeaderBox = styled(Box, {
  shouldForwardProp: isValidProp,
})(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const HeaderText = styled(Typography)(() => ({
  whiteSpace: 'normal',
  textAlign: 'right',
}));

export const SupplyTableHeader = ({ label, subLabel }) => (
  <Box>
    <HeaderBox headerType={label}>
      <HeaderText variant="caption">{label}</HeaderText>
      <HeaderText variant="caption">{subLabel}</HeaderText>
    </HeaderBox>
  </Box>
);
