import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import Warning from '@mui/icons-material/Warning';

const WarningIconView = styled((props) => <Warning {...props} />)`
  height: 28px;
  width: 28px;
  color: #d3a42c;
  margin-top: 2px;
  margin: 6px 6px 0 0;
`;

const InsightsCardWithIconContainer = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  margin-right: 24px;
`;
const InsightsCardContainer = styled.div`
  display: flex;
  margin-top: 18px;
  flex-wrap: wrap;
  margin: 16px auto 20px 16px;
`;
const ActivityInsightsCard = styled.div`
  text-align: left;
  margin-left: 10px;
`;
const InsightsCardTitle = styled(Typography)`
  color: #fcfcfd;
  margin-bottom: 8px;
`;
const InsightsCardContent = styled(Typography)`
  font-size: 14px;
  color: #a3afc2;
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 16px;
  overflow: hidden;
`;

export const InsightsWarningCard = ({ name, userCreatedListType }) => (
  <InsightsCardContainer>
    <InsightsCardWithIconContainer>
      <WarningIconView />
      <ActivityInsightsCard>
        <InsightsCardTitle variant="title3">
          {userCreatedListType} HCP Selected
        </InsightsCardTitle>
        <InsightsCardContent>
          You have previously added {name} to your {userCreatedListType} list.
        </InsightsCardContent>
      </ActivityInsightsCard>
    </InsightsCardWithIconContainer>
  </InsightsCardContainer>
);
