/* eslint-disable no-console */
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { saveHovRoutePreference } from '../request/requests';
import { ROUTES } from '../constants';

const NavigationTracking = ({ children, profile }) => {
  const currentLocation = useLocation();
  const history = useHistory();

  useEffect(() => {
    const pathsToSave = [
      /\/sphere\/projects\/\w{32}/gm,
      /\/sphere\/projects\//gm,
    ];

    const matchPath = (path) => pathsToSave.some((p) => p.exec(path));

    if (matchPath(currentLocation.pathname) && profile?.preference) {
      console.log(
        `SAVE route: ${currentLocation.pathname}?${currentLocation.search}`
      );
      saveHovRoutePreference({
        userProfile: profile,
        route: `${currentLocation.pathname}${currentLocation.search}`,
      });
    }
  }, [profile, currentLocation]);

  useEffect(() => {
    const lastVisitedPage = profile.preference.route || '';

    if (lastVisitedPage.startsWith(ROUTES.SPHERE)) {
      history.push(lastVisitedPage);
    } else {
      history.push(`${ROUTES.SPHERE}${lastVisitedPage}`);
    }
  }, []);

  return children;
};

export default NavigationTracking;
