import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import { ProductTreeCreateForm } from './productTreeCreateForm';
import {
  useMarketsQuery,
  useProductsQuery,
  useProductTreesQuery,
} from '../../../../request/queries';
import { useConfigViewContext } from '../../configViewContext';
import { CenteredContentContainer } from '../../styledComponents';
import { ConfigSectionTitle } from '../../configViewTitle';
import { CONFIG_VIEW_MODE } from '../../userGroupConfigConstants';

const BodyContent = ({
  isLoading,
  isError,
  markets,
  products,
  sourceProductLineForClone,
}) => {
  if (isLoading) {
    return (
      <CenteredContentContainer>
        <Stack gap={2} alignItems="center">
          <CircularProgress />
          <Typography
            variant="body2"
            color={(theme) => theme.themeColors.neutral60}
          >
            Loading New Product Tree
          </Typography>
        </Stack>
      </CenteredContentContainer>
    );
  }
  if (isError) {
    return (
      <CenteredContentContainer>
        <Stack gap={2} alignItems="center">
          <ErrorIcon />
          <Typography
            variant="body2"
            color={(theme) => theme.themeColors.neutral60}
          >
            Failed to fetch data for product tree
          </Typography>
        </Stack>
      </CenteredContentContainer>
    );
  }
  return markets && products ? (
    <ProductTreeCreateForm
      markets={markets}
      products={products}
      sourceProductLineForClone={sourceProductLineForClone}
    />
  ) : null;
};

export const ProcutTreeCreateContainer = () => {
  const { setConfigViewMode, setProductLineId } = useConfigViewContext();
  const { productLineId } = useConfigViewContext();
  const {
    data: productTrees = [],
    isLoading: isLoadingProductTrees,
    isError: productTreesError,
  } = useProductTreesQuery({ enabled: !!productLineId });
  const {
    data: markets,
    isLoading: isLoadingMarkets,
    isError: martketsError,
  } = useMarketsQuery();
  const {
    data: products,
    isLoading: isLoadingProducts,
    isError: productsError,
  } = useProductsQuery();

  const sourceProductLineForClone = productTrees.find(
    (p) => p.productLineId === productLineId
  );

  const title = sourceProductLineForClone
    ? `New Product Tree from ${sourceProductLineForClone.productLine}`
    : 'New Product Tree';

  return (
    <Stack height="100%">
      <ConfigSectionTitle
        currentTabTitle={title}
        onClickBack={() => {
          setProductLineId(null);
          setConfigViewMode(CONFIG_VIEW_MODE.VIEW);
        }}
      />
      <BodyContent
        isLoading={
          isLoadingMarkets || isLoadingProducts || isLoadingProductTrees
        }
        isError={martketsError || productsError || productTreesError}
        markets={markets}
        products={products}
        sourceProductLineForClone={sourceProductLineForClone}
      />
    </Stack>
  );
};
