import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import {
  useCloneProductTreeMutation,
  useCreateProductTreeMutation,
} from '../../../../request/mutations';
import { useConfigViewContext } from '../../configViewContext';
import { CONFIG_VIEW_MODE } from '../../userGroupConfigConstants';
import { CONFIG_ALERT_STATUSES } from '../../configAlertStatuses';
import { queryKeys } from '../../../../request/queries';

export const useCreateProductTree = ({
  markets,
  sourceProductLineForClone,
}) => {
  const marketOptions = useMemo(
    () =>
      markets
        .filter((m) => m.productLines.length === 0)
        .map((m) => ({
          label: m.market,
          value: m.marketId,
        })),
    [markets]
  );

  const defaultValues = sourceProductLineForClone
    ? {
        customerMarketId: null,
        productLine: sourceProductLineForClone.productLine,
        productName: sourceProductLineForClone.productTree.productName,
        friendlyName: sourceProductLineForClone.productTree.friendlyName,
        level: sourceProductLineForClone.productTree.level,
      }
    : {
        customerMarketId: null,
        productLine: '',
        productName: '',
        friendlyName: '',
        level: '',
      };

  const { control, formState, handleSubmit, setValue } = useForm({
    mode: 'all',
    defaultValues,
  });

  const { setConfigViewMode, setProductLineId, showConfigAlert } =
    useConfigViewContext();

  const createProductTreeMutation = useCreateProductTreeMutation();
  const cloneProductTreeMutation = useCloneProductTreeMutation();

  const mutation = sourceProductLineForClone
    ? cloneProductTreeMutation
    : createProductTreeMutation;

  const queryClient = useQueryClient();

  const onSubmit = handleSubmit((formData) => {
    const payload = {
      ...formData,
      customerMarketId: formData.customerMarketId?.value,
      ...(sourceProductLineForClone && {
        sourceProductLineId: sourceProductLineForClone.productLineId,
      }),
    };
    mutation.mutate(payload, {
      onSuccess: (response) => {
        const successMessage = sourceProductLineForClone
          ? `Successfully duplicated ${response.data.productLine} from ${sourceProductLineForClone.productLine}`
          : `Successfully created ${response.data.productLine} tree`;
        queryClient.invalidateQueries(queryKeys.markets);
        queryClient.invalidateQueries(queryKeys.productTrees).then(() => {
          setConfigViewMode(CONFIG_VIEW_MODE.VIEW);
          setProductLineId(response.data.productLineId);
          showConfigAlert(CONFIG_ALERT_STATUSES.SUCCESS, successMessage);
        });
      },
      onError: () => {
        showConfigAlert(CONFIG_ALERT_STATUSES.ERROR);
      },
    });
  });

  const onCancel = () => {
    setConfigViewMode(CONFIG_VIEW_MODE.VIEW);
  };

  return {
    onSubmit,
    marketOptions,
    control,
    formState,
    setValue,
    isLoading: mutation.isLoading,
    onCancel,
  };
};
