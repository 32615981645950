import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getClearCookie,
  getLogin as getLoginRequest,
} from '../request/requests';

export const getLogin = createAsyncThunk('login/getLogin', () =>
  getLoginRequest()
);

export const clearCookie = createAsyncThunk('login/clearCookie', async () => {
  const response = await getClearCookie();
  return { data: response.data };
});

export const loginInitialState = {
  isLoggedIn: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState: loginInitialState,
  extraReducers: (builder) => {
    builder
      .addCase(getLogin.fulfilled, (state) => {
        state.isLoggedIn = true;
      })
      .addCase(getLogin.rejected, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(clearCookie.fulfilled, (state) => {
        state.isLoggedIn = false;
      });
  },
});

export default loginSlice.reducer;
