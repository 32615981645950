import { Distribution } from './constants';

const censorMap = {
  contrave: 'Target Product',
  saxenda: 'Competitor A',
  xenical: 'Competitor B',
  'trelegy 100': 'Target Product',
  'anoro ellipta': 'Competitor A',
  'atrovent tot': 'Competitor B',
  'breztri aerosphere': 'Competitor C',
  'duaklir genuair': 'Competitor D',
  foradil: 'Competitor E',
  'incruse ellipta': 'Competitor F',
  'inspiolto respimat': 'Competitor G',
  'onbrez breezhaler': 'Competitor H',
  oxeze: 'Competitor I',
  'seebri breezhaler': 'Competitor J',
  serevent: 'Competitor K',
  'spiriva respimat': 'Competitor L',
  'tudorza genuair': 'Competitor M',
  'ultibro breezhaler': 'Competitor N',
};

export const anonymizeTerritoryOverviewData = (data, distributionType) => ({
  ...data,
  timescaleData: anonymizeTimescaleData(
    data.timescaleKeys,
    data.timescaleData,
    distributionType
  ),
});

export const anonymizeTerritoryOverviewMetadata = (data) => ({
  ...data,
  objectives: anonymizeProductObjectives(data.objectives),
  products: anonymizeProductObjectives(data.products),
  activityChannels: anonymizeActivityChannels(data.activityChannels),
});

const anonymizeProductObjectives = (productObjectives) =>
  productObjectives.map((productObjective, index) => ({
    label: `Product/Objective ${index + 1}`,
    id: productObjective.id,
    metrics: anonymizeProductObjectiveMetrics(productObjective.metrics),
  }));

const anonymizeProductObjectiveMetrics = (metrics) =>
  metrics.map((metric, index) => ({
    ...metric,
    label: `Metric ${index + 1}`,
  }));

const anonymizeTimescaleData = (
  timescaleKeys,
  timescaleData,
  distributionType
) => {
  const data = timescaleKeys.reduce(
    (tempTimescaleDescription, timescaleKey) => {
      const { id: timescaleId, name: timescale } = timescaleKey;

      // eslint-disable-next-line no-param-reassign
      tempTimescaleDescription[timescaleId] = {
        description: `Compared to last ${timescale.toLowerCase()}`,
        headers: anonymizeTimescaleHeaders(
          timescaleData[timescaleId].headers,
          timescale,
          distributionType
        ),
        items:
          anonymizeTimescaleItems(
            timescaleData[timescaleId],
            distributionType
          ) ?? [],
      };

      return tempTimescaleDescription;
    },
    {}
  );

  const keys = timescaleKeys.map((t) => t.id);
  const timescale = keys.reduce((tempTimescaleData, key) => {
    tempTimescaleData[key] = data[key]; // eslint-disable-line no-param-reassign
    return tempTimescaleData;
  }, {});

  return timescale;
};

const anonymizeTimescaleHeaders = (
  metricHeaders,
  timescale,
  distributionType
) => {
  if (distributionType === Distribution.DEMAND) {
    return anonymizeDemandTimescaleHeaders(metricHeaders, timescale);
  }
  if (distributionType === Distribution.SUPPLY) {
    return anonymizeSupplyTimescaleHeaders(metricHeaders, timescale);
  }
  return null;
};

const anonymizeDemandTimescaleHeaders = (metricHeaders, timescale) =>
  metricHeaders.map(({ label, subLabel, type, graph }) => ({
    label: censorMap[label.toLowerCase()] ?? label,
    subLabel,
    type,
    ...(graph && { graph }),
    ...(type !== 'label' && {
      chartLabels: [...Array(6).keys()].map((i) => `${timescale} ${i + 1}`),
    }),
  }));

const anonymizeSupplyTimescaleHeaders = (metricHeaders, timescale) =>
  metricHeaders.map(({ label, subLabel, type }, index) => {
    if (type === 'label') {
      return {
        label,
        subLabel,
        type,
      };
    }
    return {
      label: `${timescale} ${index}`,
      subLabel: 'Year',
      type,
    };
  });

const anonymizeTimescaleItems = (timescaleData, distributionType) => {
  if (distributionType === Distribution.DEMAND) {
    return anonymizeDemandTimescaleItems(timescaleData);
  }
  if (distributionType === Distribution.SUPPLY) {
    return anonymizeSupplyTimescaleItems(timescaleData);
  }
  return null;
};

const anonymizeDemandTimescaleItems = (timescaleData) =>
  timescaleData?.items.map((item, index) => {
    const minusHeaderColumn = [...item];
    minusHeaderColumn.shift();
    return [{ data: `Region ${index + 1}` }, ...minusHeaderColumn];
  });

const anonymizeSupplyTimescaleItems = (timescaleData) =>
  timescaleData?.items.map((item, index) => {
    const minusHeaderColumn = [...item];
    minusHeaderColumn.shift();
    return [
      { data: index === 0 ? 'Region Avg' : `Region ${index}` },
      ...minusHeaderColumn,
    ];
  });

const anonymizeActivityChannels = (activityChannels) =>
  activityChannels.map((_, index) => `Activity ${index + 1}`);
