export const searchOptions = {
  byPowerScore: 'By PowerScore',
  bySegmentScore: 'By Segment Score',
  byLastContacted: 'By Last Contacted',
};

export const lastContactedIds = {
  OFF: '',
  THIRTY_DAYS: '30days',
  SIXTY_DAYS: '60days',
  NINETY_DAYS: '90days',
};

export const segmentTypes = {
  RECENT: 'recent',
  PREDICTED: 'predicted',
};
