import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grow, TextField, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    'align-items': 'center',
    flex: 1,
    'flex-direction': 'column',
    'justify-content': 'center',
  },
  labelGroup: {
    'justify-content': 'flex-end',
    'flex-direction': 'column',
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    'text-align': 'left',
    padding: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500,
  },
  paddingBox: {},
}));

export default function IngestionNameData({
  ingestNav,
  ingest,
  onDatasetNameChange,
  validateDatasetName,
  clearErrorMsg,
  myIndex,
  onComplete,
}) {
  const classes = useStyles();
  // eslint-disable-next-line no-param-reassign
  onComplete.action = () => {
    validateDatasetName(ingest, myIndex);
  };

  return (
    <div className={classes.container}>
      <Grow in>
        <div className={classes.labelGroup}>
          <div className={classes.paddingBox} />
          <Typography className={classes.title} variant="h2" component="h3">
            Name New Dataset
          </Typography>
          <Typography className={classes.subtitle} variant="h5">
            for historical data
          </Typography>
        </div>
      </Grow>
      <Grow in timeout={1000}>
        <TextField
          required
          id="standard-required"
          label={ingest.errorMessage === '' ? 'Required' : ingest.errorMessage}
          placeholder="A New Dataset Name"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          onChange={(event) => {
            onDatasetNameChange(event.target.value);
          }}
          onFocus={() => {
            clearErrorMsg();
          }}
          disabled={ingestNav.completedSteps[myIndex] || ingest.isBusy}
          value={ingest.dataset.datasetName}
          error={ingest.errorMessage !== ''}
        />
      </Grow>
    </div>
  );
}
