import { filterEntitiesByLastContactedDate } from '../utils/lastContactedDateFiltering';
import {
  filterEntitiesBySearch,
  filterEntitiesBySegmentType,
  filterEntitiesBySpecialties,
  filterForEntitiesBySelectedUserCreatedList,
  filterOutEntitiesInUserCreatedLists,
  includeEntitiesFromSpecificUserCreatedList,
} from '../utils/maptualListEntityFiltering';
import { userCreatedListOptions } from '../components/fieldView/entitySelection/userCreatedListsConfig';

function SearchNFilter(
  list,
  searchTerm,
  segmentSelection,
  maptualListFilters,
  isCurated = false,
  sortBySegment = false,
  selectedUserCreatedList = null
) {
  let ret = { ...list };

  if (
    list &&
    Array.isArray(list.items) &&
    segmentSelection !== '' &&
    segmentSelection
  ) {
    ret = {
      ...ret,
      items: list.items.filter(
        (item) =>
          Array.isArray(item.segments) &&
          item.segments.findIndex(
            (segment) => segment?.segmentName === segmentSelection
          ) > -1
      ),
    };
  }

  if (selectedUserCreatedList && Array.isArray(ret.items)) {
    ret = {
      ...ret,
      items: filterForEntitiesBySelectedUserCreatedList(
        ret.items,
        selectedUserCreatedList
      ),
    };
  }

  if (searchTerm !== '' && searchTerm && ret && Array.isArray(ret.items)) {
    ret = {
      ...ret,
      items: filterEntitiesBySearch(ret.items, searchTerm),
    };
  }
  const includeNoSeeHcps = maptualListFilters?.noSee?.id || isCurated;
  const includeLongTermLeaveHcps =
    maptualListFilters?.longTermLeave?.id || isCurated;

  if (
    !includeNoSeeHcps &&
    !includeLongTermLeaveHcps &&
    (!selectedUserCreatedList ||
      selectedUserCreatedList === userCreatedListOptions.starred.id) &&
    Array.isArray(ret.items)
  ) {
    ret = {
      ...ret,
      items: filterOutEntitiesInUserCreatedLists(ret.items),
    };
  }

  const onlyNoSeeFilterApplied = includeNoSeeHcps && !includeLongTermLeaveHcps;

  if (onlyNoSeeFilterApplied && Array.isArray(ret.items)) {
    ret = {
      ...ret,
      items: includeEntitiesFromSpecificUserCreatedList(
        ret.items,
        userCreatedListOptions.noSee.id
      ),
    };
  }

  const onlyLongTermLeaveFilterApplied =
    includeLongTermLeaveHcps && !includeNoSeeHcps;

  if (onlyLongTermLeaveFilterApplied && Array.isArray(ret.items)) {
    ret = {
      ...ret,
      items: includeEntitiesFromSpecificUserCreatedList(
        ret.items,
        userCreatedListOptions?.longTermLeave?.id
      ),
    };
  }

  if (maptualListFilters?.lastContacted?.id && Array.isArray(ret.items)) {
    ret = {
      ...ret,
      items: filterEntitiesByLastContactedDate(
        ret.items,
        maptualListFilters.lastContacted.id
      ),
    };
  }

  if (maptualListFilters?.segment?.id && Array.isArray(ret.items)) {
    ret = {
      ...ret,
      items: filterEntitiesBySegmentType(ret.items, maptualListFilters.segment),
    };
  }

  if (maptualListFilters?.starred?.id && Array.isArray(ret.items)) {
    ret = {
      ...ret,
      items: filterForEntitiesBySelectedUserCreatedList(
        ret.items,
        userCreatedListOptions.starred.id
      ),
    };
  }

  if (
    maptualListFilters?.specialties?.specialtiesToInclude?.length &&
    Array.isArray(ret.items)
  ) {
    ret = {
      ...ret,
      items: filterEntitiesBySpecialties(
        ret.items,
        maptualListFilters.specialties.specialtiesToInclude,
        maptualListFilters.specialties.specialtiesToExclude
      ),
    };
  }

  if (maptualListFilters?.territories && Array.isArray(ret?.items)) {
    const selectedTerritories = Object.keys(maptualListFilters.territories).map(
      (territory) => territory.toLowerCase()
    );

    ret = {
      ...ret,
      items: ret.items.filter(({ parentEntityId }) =>
        selectedTerritories.includes(parentEntityId?.toLowerCase())
      ),
    };
  }

  const hasSegmentScore =
    segmentSelection && Array.isArray(list?.items)
      ? list.items.some(
          (item) =>
            Array.isArray(item.segments) &&
            item.segments.some((segment) => segment?.segmentFractionalScore > 0)
        )
      : false;

  let sortedList = [];

  if (sortBySegment && segmentSelection && hasSegmentScore) {
    sortedList = Array.isArray(ret.items)
      ? [...ret.items].sort((a, b) => {
          const bSegments = b?.segments;
          const aSegments = a?.segments;
          const bScore = bSegments.find(
            (segment) => segment?.segmentName === segmentSelection
          )?.segmentFractionalScore;
          const aScore = aSegments.find(
            (segment) => segment?.segmentName === segmentSelection
          )?.segmentFractionalScore;

          if (Array.isArray(bSegments) && Array.isArray(aSegments)) {
            return bScore - aScore;
          }
          return 0;
        })
      : ret.items;
  } else {
    sortedList = Array.isArray(ret.items)
      ? [...ret.items].sort(
          (a, b) => b.maptualFractionalScore - a.maptualFractionalScore
        )
      : ret.items;
  }

  ret = {
    ...ret,
    items: sortedList,
  };

  return ret;
}

export { SearchNFilter };
