import React from 'react';
import { ProductTreeHierarchy } from '../userConfigProductTree';

export function NewProductTreePlaceholder() {
  return (
    <div>
      <ProductTreeHierarchy
        productTree={{
          productTree: {
            productId: '',
            productName: 'New Product Tree',
            isPlaceholder: true,
          },
        }}
        selectedTreeNode={{}}
        setSelectedTreeNode={() => {}}
      />
    </div>
  );
}
