import { makeStyles } from '@mui/styles';
import { Typography, Button } from '@mui/material';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.themeColors.borderLowContrast}`,
    paddingBottom: '32px',
  },
  header: {
    height: '22px',
    fontSize: 18,
    fontWeight: 600,
    color: '#E0E4EB',
    marginBottom: '4px',
  },
  subHeader: {
    height: '19px',
    fontSize: 16,
    fontWeight: 400,
    marginBottom: '16px',
    color: theme.themeColors.neutral60,
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '8px',
  },
}));

const validationReportUrl =
  'https://s3.console.aws.amazon.com/s3/buckets/odaia-secure-datapackage-bucket?region=us-east-1&prefix=curation/curation-engine-validation';
const simulationReportUrl =
  'https://s3.console.aws.amazon.com/s3/buckets/odaia-secure-datapackage-bucket?region=us-east-1&prefix=curation/curation-engine-simulation';

function UsefulLinks() {
  const classes = useStyles();
  const link = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>Resources</Typography>
      <Typography className={classes.subHeader}>
        Links to useful resources
      </Typography>
      <div className={classes.linkContainer}>
        <Button variant="text" onClick={() => link(validationReportUrl)}>
          Validation Report
        </Button>
        <Button variant="text" onClick={() => link(simulationReportUrl)}>
          Simulation Report
        </Button>
      </div>
    </div>
  );
}

export default UsefulLinks;
