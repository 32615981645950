import React, { useState } from 'react';
import { FormControl, TextField, Chip, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

const TextFieldWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const StyledFormControl = styled(FormControl)({
  width: 268,
});

const ChipRow = styled(Box)({
  display: 'flex',
  gap: 8,
  flexWrap: 'wrap',
});

const StyledChip = styled(Chip)(({ disabled, theme: { themeColors } }) => ({
  borderColor: themeColors.borderMidContrast,
  fontSize: 14,
  color: disabled ? themeColors.buttonBorderColor : 'initial',
  '& .MuiChip-deleteIcon': {
    color: disabled ? themeColors.buttonBorderColor : themeColors.neutral60,
  },
}));

const StyledTextField = styled(TextField)(({ theme: { themeColors } }) => ({
  '& label.Mui-focused': {
    color: themeColors.neutral60,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      border: `1px solid ${themeColors.neutral60}`,
    },
  },
  '& input::placeholder': {
    opacity: 1,
  },
}));

export function TextFieldWithChipsOutside({
  selectedItems,
  setSelectedItems,
  inputLabel,
}) {
  const [inputValue, setInputValue] = useState('');

  const handleOnChange = (event) => {
    if (event.target.value === ',') return;
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    const { key, target } = event;
    const isEnterOrCommaKey = key === 'Enter' || key === ',';
    const newValue = target.value.trim();

    if (!isEnterOrCommaKey || !newValue) return;

    const newItems = newValue.includes(',')
      ? newValue.split(',').map((element) => element.trim())
      : [newValue];

    setSelectedItems((prevItems) => [...prevItems, ...newItems]);
    setInputValue('');
  };

  const handleDeleteItem = (item) => {
    const newItems = [...selectedItems];
    const index = newItems.indexOf(item);
    if (index > -1) {
      newItems.splice(index, 1);
    }
    setSelectedItems(newItems);
  };
  return (
    <TextFieldWrapper>
      <StyledFormControl>
        <StyledTextField
          placeholder={inputLabel}
          value={inputValue}
          onChange={handleOnChange}
          onKeyDown={handleKeyPress}
          focused
        />
      </StyledFormControl>
      <ChipRow>
        {selectedItems.map((item) => (
          <StyledChip
            key={item}
            value={item}
            variant="outlined"
            label={item.toUpperCase()}
            deleteIcon={<CloseIcon />}
            onMouseDown={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
            onDelete={() => handleDeleteItem(item)}
          />
        ))}
      </ChipRow>
    </TextFieldWrapper>
  );
}
