import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import CheckmarkIcon from '@mui/icons-material/Done';
import { darkThemeColors } from '../../styles/colorConfig';
import SemiOpaqueChip from '../chips/semiOpaqueChip';

const useStyles = makeStyles((theme) => ({
  horContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '0.25rem',
  },
  checkConatiner: {
    minWidth: 15,
    minHeight: 15,
    width: 15,
    height: 15,
    marginRight: 15,
  },
  checkMark: {
    width: '100%',
    height: '100%',
    color: green.A700,
  },
  primaryText: {
    flexGrow: 1,

    align: 'left',
    fontSize: 11,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    padding: 0,
    margin: 0,
    marginRight: '0.25rem',
    lineHeight: 1.1,
  },
  chipContainer: {
    width: 'auto',
  },
}));

export default function CheckListItem({
  primaryText,
  isChecked,
  semiOpaqueChip,
}) {
  const classes = useStyles();

  return (
    <div className={classes.horContainer}>
      <div className={classes.checkConatiner}>
        {isChecked ? (
          <CheckmarkIcon className={classes.checkMark} />
        ) : (
          <div>&nbsp;</div>
        )}
      </div>
      <Typography
        className={classes.primaryText}
        style={isChecked ? {} : { color: darkThemeColors.tertiaryColor }}
      >
        {primaryText}
      </Typography>
      <div className={classes.chipContainer}>
        {semiOpaqueChip ? (
          <SemiOpaqueChip {...semiOpaqueChip} />
        ) : (
          <div>&nbsp;</div>
        )}
      </div>
    </div>
  );
}
