import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import EmojiObjectsOutlined from '@mui/icons-material/EmojiObjectsOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Sell from '@mui/icons-material/Sell';
import Warning from '@mui/icons-material/Warning';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { InsightsCard } from './insightsCard/insightsCard';
import { useEntityActivityInsights } from '../../../hooks/useEntityActivityInsights';

const WarningIconView = styled((props) => <Warning {...props} />)`
  height: 16px;
  width: 16px;
  color: #d3a42c;
  margin-top: 2px;
`;
const ErrorIcon = styled((props) => <Warning {...props} />)`
  height: 16px;
  width: 16px;
  color: #a3afc2;
  margin-top: 2px;
`;
const DefaultIconView = styled((props) => <Sell {...props} />)`
  height: 16px;
  width: 16px;
  color: #8595ad;
  margin-top: 2px;
`;
const LightbulbIconView = styled((props) => <TipsAndUpdatesIcon {...props} />)`
  height: 16px;
  width: 16px;
  color: #336fcc;
  margin-top: 2px;
`;
const CheckmarkIconView = styled((props) => <CheckCircleIcon {...props} />)`
  height: 16px;
  width: 16px;
  color: #399368;
  margin-top: 2px;
`;

const InsightsCardWithIconContainer = styled.div`
  margin: 0;
  width: calc(50% - 24px);
  display: flex;
  flex-direction: row;
  margin-right: 24px;
`;
const InsightsCardContainer = styled.div`
  display: flex;
  margin-top: 18px;
  flex-wrap: wrap;
`;
const ActivityInsightsCard = styled.div`
  text-align: left;
  margin-left: 10px;
`;
const InsightsCardTitle = styled(Typography)`
  color: ${(props) => props.theme.themeColors.insightsCardTitle};
  margin-bottom: 8px;
`;
const InsightsCardContent = styled(Typography)`
  font-size: 14px;
  color: ${(props) => props.theme.themeColors.primaryMaptualListFilteringColor};
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 16px;
  overflow: hidden;
`;
const ErrorInsightsCardWithIconContainer = styled(
  InsightsCardWithIconContainer
)`
  width: calc(100% - 24px);
`;

const typeToIconMap = {
  'CRM OOR': <WarningIconView />,
  'CRM IR': <CheckmarkIconView />,
  SAMPLE: <CheckmarkIconView />,
  WEBSITE: <CheckmarkIconView />,
  PREDICTED_PATIENT: <CheckmarkIconView />,
  BENEFIT_VERIFICATION: <CheckmarkIconView />,
  EVENT: <LightbulbIconView />,
  EMAIL: <LightbulbIconView />,
  DDD_NON_RETAIL: <LightbulbIconView />,
  'DIGITAL DATA': <LightbulbIconView />,
};

const getCardIconFromType = (type) => {
  if (typeToIconMap[type]) {
    return typeToIconMap[type];
  }

  return <DefaultIconView />;
};

export const InsightsActivity = ({ entityId }) => {
  const { data, isLoading } = useEntityActivityInsights(entityId);

  return (
    <InsightsCard
      title="HCP Insights"
      subtitle="Contextual information to better understand behavioral changes in HCPs outside of their Rx data."
      icon={EmojiObjectsOutlined}
      width={750}
      isLoading={isLoading}
    >
      <InsightsCardContainer>
        {data &&
          data.map((insight) => (
            <InsightsCardWithIconContainer key={insight.title}>
              {getCardIconFromType(insight.type)}
              <ActivityInsightsCard>
                <InsightsCardTitle variant="title3">
                  {insight.title}
                </InsightsCardTitle>
                <InsightsCardContent>{insight.description}</InsightsCardContent>
              </ActivityInsightsCard>
            </InsightsCardWithIconContainer>
          ))}
        {(!data || data.length === 0) && (
          <ErrorInsightsCardWithIconContainer>
            <ErrorIcon />
            <ActivityInsightsCard>
              <InsightsCardTitle variant="title3">
                No HCP Insights Available
              </InsightsCardTitle>
              <InsightsCardContent>
                Please refer to Script Volume and Market Share insights for more
                information about this HCP.
              </InsightsCardContent>
            </ActivityInsightsCard>
          </ErrorInsightsCardWithIconContainer>
        )}
      </InsightsCardContainer>
    </InsightsCard>
  );
};
