/* eslint-disable no-restricted-syntax */
import { getDemoAccountStatus } from '../request/config';
import Logger from './logger';
import { toCapitalize } from '../utils/toCapitalize';
import {
  anonymizeEntityNameOnGetMaptualList,
  anonymizeSegmentsOnGetMaptualList,
} from '../middlewares/anonymize/getMaptualList';

const censorMap = {
  rheumatology: 'Specialty 1',
  dermatology: 'Specialty 2',
  rhe: 'Spc',
  myrbetriq: 'Target Product',
  xtandi: 'Target Product',
  'simponi sc': 'Target Product',
  'simponi ra': 'Target Product',
  'simponi spa': 'Target Product',
  simponi: 'Target Product',
  tremfya: 'Target Product',
  'tremfya pso': 'Target Product',
  'tremfya psa': 'Target Product',
  'one-press': 'Injectable',
  'SOMATULINE DEPOT': 'Target Product',
  MYCAPSSA: 'Target Product',
  'IL-17': 'Competitor Class A',
  bioadvance: 'PSP',
  saxenda: 'Target Product',
  contrave: 'Competitor A',
  xenical: 'Competitor B',

  janssen: 'Demo Customer A',
  ipsen: 'Demo Customer B',
  janssendev4: 'Demo Customer A',
  janssendev4_ser: 'Demo Customer A',
  ipsendev: 'Demo Customer B',

  humira: 'Competitor A',
  cosentyx: 'Competitor C',
  brenzys: 'Competitor D',
  orencia: 'Competitor E',
  enbrel: 'Competitor F',
  'xeljanz xr': 'Competitor G',
  xeljanz: 'Competitor G',
  actemra: 'Competitor H',
  remicade: 'Competitor I',
  erelzi: 'Competitor J',
  cimzia: 'Competitor K',
  SOMAVERT: 'Competitor L',
  'SANDOSTATIN LAR': 'Competitor M',
  STELAINDICATION: 'Competitor N',
  SKYRIZI: 'Competitor O',
  RINVOQ: 'Competitor P',
  RUXIENCE: 'Competitor Q',
  STELARA: 'Competitor R',
  TALTZ: 'Competitor S',
  hadlima: 'Competitor T',
  rituxan: 'Competitor U',
  kevzara: 'Competitor V',
  adalimumab: 'Competitor Class C',
  JAK: 'Competitive Class B',
  OAB: '',
  'TTL FESOTERODINE': 'Competitor',
  'REMAINING ARI': 'Competitor',
  ERLEADA: 'Competitor',
  'TOTAL ADT': 'Target',
};

const censorMapCaseSens = {
  Spa: 'Indication 1',
  SpA: 'Indication 1',
  RA: 'Indication 2',
  PsA: 'Indication 3',
  PsO: 'Indication 4',
  ASs: 'Indication 5',
  Der: 'Spc',
  Derm: 'Spc',
  End: 'Spc',
  JAK: 'Competitive Class B',
};

export const censorInsights = (response) => {
  if (!getDemoAccountStatus()) return response;
  const censored = JSON.parse(JSON.stringify(response));
  if (!censored.insights) {
    return censored;
  }
  censored.insights.entityName = `Entity Name ${censored.entityId}`;
  for (const objective of censored.insights.objectives) {
    objective.objectiveDescription = objective.objectiveName;
    objective.objectiveProduct = 'Target Product';
    for (const card of objective.cards) {
      const metric = card.metric
        ? card.metric.toUpperCase().slice(0, -1) +
          card.metric.slice(-1).toLowerCase()
        : '';
      if (card.cardType === 'share') {
        card.title = `VS. Competitor ${metric}`;
      } else {
        card.title = `${toCapitalize(card.productGroup)} Product ${metric}`;
      }
    }
  }
  return censored;
};

export const censorScriptsMetadata = (response) => {
  if (!getDemoAccountStatus()) return response;
  // deep copy to prevent mutation
  const censored = JSON.parse(JSON.stringify(response));

  for (const [i, dropdown] of censored.entries()) {
    // censor dropdown titles
    if (i === 0) dropdown.dropdownTitle = 'Target VS Competitor';
    else if (i === 1) dropdown.dropdownTitle = 'Target VS Top Products';
    else {
      // -2 to take into account the first two loop iterations, and so we start at A
      dropdown.dropdownTitle = `Target VS Competitor ${String.fromCharCode(
        97 + i - 2
      ).toUpperCase()}`;
    }

    // censor chips
    const metrics = ['TRX', 'NRX', 'NBRX', 'DLD'];
    for (const chip of dropdown.chips) {
      const metric = chip.title.slice(chip.title.lastIndexOf(' ') + 1);
      chip.title = `${chip.productCategory === 'B' ? 'Target' : 'Competitor'} ${
        metrics.includes(metric.toUpperCase()) ? metric : ''
      }`;
    }
  }

  return censored;
};

export const censorScriptsData = (response) => {
  if (!getDemoAccountStatus()) return response;
  // deep copy to prevent mutation
  const censored = JSON.parse(JSON.stringify(response));

  const metrics = ['TRX', 'NRX', 'NBRX', 'DLD'];

  const GROUP = censored.complete.productCategory;

  // censore complete cards Monthly
  for (const objective of censored.complete.dataList.graphData) {
    let cards = Object.entries(objective)[0][1];
    if (cards.listItems) {
      cards = cards.listItems;
    }
    for (const card of cards) {
      const cardCopy = { ...card };
      if (card?.series?.length) {
        const metric = cardCopy.series[0].name.slice(
          cardCopy.series[0].name.lastIndexOf(' ') + 1
        );
        card.series[0].name = `${GROUP === 'B' ? 'Target' : 'Competitor'} ${
          metrics.includes(metric.toUpperCase()) ? metric : ''
        }`;

        if (card?.primaryData?.dataSource) {
          const splitName = `${cardCopy.primaryData.dataSource.split(' ')}`;
          const metricSource = splitName[splitName.length - 2];
          const censoredName = `${GROUP === 'B' ? 'Target' : 'Competitor'} ${
            metrics.includes(metricSource.toUpperCase()) ? metricSource : ''
          } MAT`;
          card.primaryData.dataSource = censoredName;

          if (card.series.length) {
            card.series[0].name = censoredName;
          }
        }
      }
    }
  }

  const metric = censored.complete.productName.slice(
    censored.complete.productName.lastIndexOf(' ') + 1
  );
  censored.complete.productName = `${GROUP === 'B' ? 'Target' : 'Competitor'} ${
    metrics.includes(metric.toUpperCase()) ? metric : ''
  }`;

  censored.complete.title = `${GROUP === 'B' ? 'Target' : 'Competitor'} ${
    metrics.includes(metric.toUpperCase()) ? metric : ''
  } Scripts Data`;

  return censored;
};

export const censorScriptsSphereData = (response) => {
  if (!getDemoAccountStatus()) return response;
  // deep copy to prevent mutation
  const censored = JSON.parse(JSON.stringify(response));

  for (const [i, dropdown] of censored.entries()) {
    // censor dropdown titles
    if (i === 0) dropdown.title = 'Target VS Competitor';
    else if (i === 1) dropdown.title = 'Target VS Top Products';
    else {
      // -2 to take into account the first two loop iterations, and so we start at A
      dropdown.title = `Target VS Competitor ${String.fromCharCode(
        97 + i - 2
      ).toUpperCase()}`;
    }

    // censor data
    const metrics = ['TRX', 'NRX', 'NBRX', 'DLD'];

    for (const pmp of dropdown.productMetricPairings) {
      // censor monthly data
      const GROUP = pmp.productCategory;
      for (const objective of pmp.dataList.graphData) {
        let cards = Object.entries(objective)[0][1];
        if (cards.listItems) {
          cards = cards.listItems;
        }
        for (const card of cards) {
          const cardCopy = { ...card };
          if (card?.series?.length) {
            const metric = cardCopy.series[0].name.slice(
              cardCopy.series[0].name.lastIndexOf(' ') + 1
            );
            card.series[0].name = `${GROUP === 'B' ? 'Target' : 'Competitor'} ${
              metrics.includes(metric.toUpperCase()) ? metric : ''
            }`;

            if (card?.primaryData?.dataSource) {
              const splitName = `${cardCopy.primaryData.dataSource.split(' ')}`;
              const metricSource = splitName[splitName.length - 2];
              const censoredName = `${
                GROUP === 'B' ? 'Target' : 'Competitor'
              } ${
                metrics.includes(metricSource.toUpperCase()) ? metricSource : ''
              } MAT`;
              card.primaryData.dataSource = censoredName;

              if (card.series.length) {
                card.series[0].name = censoredName;
              }
            }
          }
        }
      }

      // censor pmp fields
      const metric = pmp.productName.slice(
        pmp.productName.lastIndexOf(' ') + 1
      );
      pmp.productName = `${GROUP === 'B' ? 'Target' : 'Competitor'} ${
        metrics.includes(metric.toUpperCase()) ? metric : ''
      }`;

      pmp.title = `${GROUP === 'B' ? 'Target' : 'Competitor'} ${
        metrics.includes(metric.toUpperCase()) ? metric : ''
      } Scripts Data`;
    }
  }
  return censored;
};

export const censorShareData = (response) => {
  if (!getDemoAccountStatus()) return response;
  // deep copy to prevent mutation
  const censored = JSON.parse(JSON.stringify(response));

  let counter = 0;
  for (const [i, dropdown] of censored.complete.entries()) {
    // censore dropdowns
    if (i === 0) {
      dropdown.title = 'Target VS Competitor';
      counter += 1;
    } else if (dropdown.title.includes('Decreasing')) {
      dropdown.title = 'Target VS Top Decreasing Product';
      counter += 1;
    } else if (dropdown.title.includes('Increasing')) {
      dropdown.title = 'Target VS Top Increasing Product';
      counter += 1;
    } else if (dropdown.title.includes('Product')) {
      dropdown.title = 'Target VS Top Products';
      counter += 1;
    } else {
      dropdown.title = `Target VS Competitor ${String.fromCharCode(
        97 + i - counter
      ).toUpperCase()}`;
    }

    // censor data
    for (const data of dropdown.graphData) {
      const dataObj = Object.values(data)[0];
      let dataSourcecounter = 0;
      let seriesCounter = 0;
      for (const row of dataObj.dataList.listItems) {
        // annual tab
        if (row?.primaryData?.dataSource && dataSourcecounter === 0) {
          row.primaryData.dataSource = 'Target';
          dataSourcecounter += 1;
        } else if (row?.primaryData?.dataSource) {
          row.primaryData.dataSource = `Competitor ${String.fromCharCode(
            97 + dataSourcecounter - 1
          ).toUpperCase()}`;
          dataSourcecounter += 1;
        }
        // other tabs
        if (row?.series) {
          if (seriesCounter === 0) {
            row.series[0].name = 'Target';
          } else {
            row.series[0].name = `Competitor ${String.fromCharCode(
              97 + seriesCounter - 1
            ).toUpperCase()}`;
          }
          seriesCounter += 1;
        }
      }
    }
  }
  return censored;
};

export const censorMaptualList = (maptualList) => {
  if (getDemoAccountStatus()) {
    const maptualListWithoutEntities =
      anonymizeEntityNameOnGetMaptualList(maptualList);
    const maptualListAnon = anonymizeSegmentsOnGetMaptualList(
      maptualListWithoutEntities
    );
    return maptualListAnon;
  }

  return maptualList;
};

export const censorHCPTableData = (tableData) => {
  if (!getDemoAccountStatus()) return tableData;
  // deep copy to prevent mutation
  const censored = JSON.parse(JSON.stringify(tableData));

  // censor columns
  for (const col of censored.columnConfig) {
    for (const [key, value] of Object.entries(censorMap)) {
      const regex = new RegExp(key, 'ig');
      col.headerName = col.headerName.replace(regex, value);
    }
  }

  return censored;
};

export const censorObjectives = (objectivesData) => {
  if (!getDemoAccountStatus()) return objectivesData;
  // deep copy to prevent mutation
  const censored = JSON.parse(JSON.stringify(objectivesData));

  // censor objectives
  for (const obj of censored) {
    for (const [key, value] of Object.entries(censorMap)) {
      const regex = new RegExp(key, 'ig');
      obj.title = obj.title.replace(regex, value);
    }
  }

  return censored;
};

const censorResponse = (response) => {
  let strRes = response;
  let deSerialize = false;

  if (getDemoAccountStatus()) {
    Logger.log('********* CENSORING');
    if (response && typeof response === 'object') {
      deSerialize = true;
      strRes = JSON.stringify(response);
    }

    if (strRes && typeof strRes === 'string') {
      let keys = Object.keys(censorMap);
      if (Array.isArray(keys)) {
        keys.forEach((key) => {
          const regEx = new RegExp(key, 'ig');
          const replaceTerm = censorMap[key];
          strRes = strRes.replace(regEx, replaceTerm);
        });
      }
      keys = Object.keys(censorMapCaseSens);
      if (Array.isArray(keys)) {
        keys.forEach((key) => {
          const filter = `/( ${key}|${key} )/`;
          const regEx = new RegExp(filter, 'g');
          const replaceTerm = censorMapCaseSens[key];
          strRes = strRes.replace(regEx, replaceTerm);
        });
      }
    }

    if (deSerialize) {
      return JSON.parse(strRes);
    }
    return strRes;
  }
  return response;
};

export default censorResponse;
