import { useSelector } from 'react-redux';

const getUserManagementState = () => {
  const userManagement = useSelector((state) => ({
    ...state.userManagement,
    userGroup: state.app.profile.userGroup,
  }));
  return userManagement;
};

export default getUserManagementState;
