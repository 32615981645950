import { red, blue, green, grey } from '@mui/material/colors';

export const darkThemeColors = {
  mainBackground: grey.A400,
  primaryColorLight: blue.A200,
  primaryColor: blue.A400,
  primaryColorDark: blue[900],
  tertiaryColor: grey[700],
};

export const colorToneMapping = {
  POSITIVE: green[600],
  NEGATIVE: red[600],
  NEUTRAL: grey[800],
};
