import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Box, Tab, Tabs } from '@mui/material';
import { EntityOverviewContext } from '../../data/entityOverviewContext';

const StyledSubtypeTabs = styled(Tabs)({
  minHeight: 'initial',
  '.MuiTabs-indicator': {
    display: 'none',
  },
});

const StyledTab = styled(Tab)(({ theme: { themeColors } }) => ({
  minWidth: 'auto',
  minHeight: 'auto',
  padding: '8px 12px',
  backgroundColor: themeColors.buttonBackgroundColor,
  color: themeColors.buttonContentColor,
  border: `1px solid ${themeColors.buttonActiveBorderColor}`,
  marginRight: -1,
  fontSize: 16,
  fontWeight: 400,
  textTransform: 'capitalize',
  '&.Mui-selected': {
    color: themeColors.buttonActiveContentColor,
    backgroundColor: themeColors.buttonSecondaryBackgroundColour,
  },
  '.MuiTab-iconWrapper': {
    margin: '0 6px 0 0',
  },
  '&:first-of-type': {
    borderRadius: '4px 0px 0px 4px',
  },
  '&:last-of-type': {
    borderRadius: '0px 4px 4px 0px',
    marginRight: 0,
  },
}));

export const EntitySubtypeSelectorTabs = () => {
  const { metadata, entitySubtype, setEntitySubtype } = useContext(
    EntityOverviewContext
  );

  const onTabClick = (subtype) => {
    setEntitySubtype(subtype);
  };

  return (
    <Box>
      <StyledSubtypeTabs value={entitySubtype}>
        {metadata.entitySubtypes.map((subtype) => (
          <StyledTab
            key={`${subtype}-key`}
            label={subtype}
            value={subtype}
            onClick={() => {
              onTabClick(subtype);
            }}
          />
        ))}
      </StyledSubtypeTabs>
    </Box>
  );
};
