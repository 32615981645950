import reduceReducers from 'reduce-reducers';
import loginReducer, { loginInitialState } from '../slices/login';
import adminViewReducer, { adminViewInitialState } from '../slices/adminView';
import appReducer, { appInitialState } from '../slices/app';
import profileReducer, { profileInitialState } from '../slices/profile';
import DEFAULT_USER_PROFILE from '../models/defaultUserProfile.json';

const initState = {
  profile: null,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'PROFILE_TABLE_COL_PREF_CHANGE':
      return {
        ...state,
        profile: {
          ...DEFAULT_USER_PROFILE,
          ...state.profile,
          preference: {
            ...DEFAULT_USER_PROFILE.preference,
            ...state.profile.preference,
            columnPreferences: action.payload,
          },
        },
      };
    default:
      return state;
  }
}

export default reduceReducers(
  {
    ...initState,
    ...appInitialState,
    ...loginInitialState,
    ...profileInitialState,
    ...adminViewInitialState,
  },
  reducer,
  appReducer,
  loginReducer,
  profileReducer,
  adminViewReducer
);
