/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Button, Typography, Box } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import { maptualApiInstance } from '../../../request/config';
import { getObjectiveProducts } from '../../application/appViews/pulse/shared/utils';
import { useRegions } from '../../application/appViews/pulse/views/salesReps/regionSelector/useRegions';
import { FetchContainer } from '../../application/appViews/pulse/shared/components';
import { TableHeader } from './tableHeader';

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fullHeight',
})(({ theme: { themeColors }, fullHeight }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflowY: 'auto',
  backgroundColor: themeColors.cardBackgroundColor,
  border: `1px solid ${themeColors.buttonBorderColor}`,
  borderRadius: '6px 6px 0px 0px',
  borderBottom: 'none',
  height: fullHeight ? '100%' : 'auto',
}));

const StyledButton = styled(Button)(({ theme: { themeColors } }) => ({
  width: 'fit-content',
  padding: '8px 12px',
  minHeight: '36px',
  color: themeColors.buttonContentColor,
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '24px',
  backgroundColor: themeColors.buttonBackgroundColor,
  borderRadius: 4,
  border: `1px solid ${themeColors.buttonBorderColor}`,
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: themeColors.buttonBorderColor,
  },
  '& .MuiSvgIcon-root': {
    color: themeColors.buttonContentColor,
  },
}));

const MessageTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.emptyText,
  fontSize: 14,
  fontWeight: 500,
  marginBottom: 8,
}));

const MessageSubtitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.emptyText,
  opacity: 0.8,
  fontSize: 12,
  fontWeight: 400,
  marginBottom: 16,
}));

const MessageContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showBorder',
})(({ theme: { themeColors }, showBorder }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '36px 0px',
  borderRadius: 10,
  border: showBorder
    ? `1px solid ${themeColors.contentCardBorderColor}`
    : 'none',
  background: themeColors.contentCardBackgroundColor,
}));

const StyledProgress = styled(CircularProgress)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.buttonContentColor,
  })
);

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'contentLoaded',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
  maxHeight: '600px',
}));

const _noEmptyParams = (...params) => {
  for (const param of params) {
    if (param === null || param === undefined) return false;
  }
  return true;
};

export const InfiniteEntitiesTable = ({
  objectiveId,
  cadence,
  metric,
  metricType,
  timestamp,
  regionId,
  hcpView = false,
  pageSize = 25,
  curatedType,
  RowContentComponent,
  initialColumns,
  metricColumns,
  activityColumns,
  showAverageRow,
  activitySubtitle,
  activityCadence,
  regionName,
  productLineId,
  projectTargetProducts,
  projectId,
  showProduct = false,
  fullHeight = false,
  onRowClick = () => null,
}) => {
  const tableRef = useRef(null);
  const [maxScrollTop, setMaxScrollTop] = useState(0);
  const [keepData, setKeepData] = useState(true);
  const [allPagesLoaded, setAllPagesLoaded] = useState(false);
  const [rows, setRows] = useState({
    [regionId]: [],
  });
  const [curatedString, setCuratedString] = useState(
    !curatedType || curatedType?.length > 1 ? 'all' : curatedType
  );
  const { data: regions } = useRegions({
    productLineId,
    territories: [],
    isHookEnabled: !!productLineId,
  });

  const prevRegionRef = useRef(regionId);
  const SCROLL_THRESHOLD = 800;

  const isHookEnabled =
    _noEmptyParams(productLineId, regionId, timestamp) &&
    (!rows[regionId] || rows[regionId]?.length === 0) &&
    (!hcpView ||
      (!!projectTargetProducts && projectTargetProducts.length >= 1));

  const fetchEntities = (_productLineId, _regionId, skip = 0) => {
    const productsString = (
      getObjectiveProducts(projectTargetProducts, objectiveId) || []
    )
      .map((i) => `target_products=${i}`)
      .join('&');

    return maptualApiInstance.get(
      `core/product-lines/${_productLineId}/projects/${projectId}/regions/${_regionId}/entities?skip=${skip}&limit=${pageSize}&${productsString}&timestamp=${timestamp}&score=${hcpView}&called=false&curatedType=${curatedString}&cadence=${cadence}&product=${showProduct}`,
      {
        timeout: 40000,
      }
    );
  };
  const { isLoading, isError, data, isFetching, refetch } = useQuery({
    queryKey: [
      'product-line',
      productLineId,
      'region',
      regionId,
      'entities',
      rows[regionId]?.length || 0,
      'timestamp',
      timestamp,
      'curatedType',
      curatedString,
      'cadence',
      cadence,
    ],
    queryFn: () =>
      fetchEntities(productLineId, regionId, rows[regionId]?.length || 0),
    keepPreviousData: keepData,
    enabled: isHookEnabled,
  });

  useEffect(() => {
    if (data) {
      setAllPagesLoaded(false);
      setKeepData(false);
    }
    setCuratedString(
      !curatedType || curatedType?.length > 1 ? 'all' : curatedType
    );
    setRows({
      ...rows,
      [regionId]: [],
    });
  }, [cadence, curatedType?.length, timestamp]);

  useEffect(() => {
    if (!isFetching) {
      prevRegionRef.current = regionId;
    }
  }, [isFetching]);

  useEffect(() => {
    if (!data?.data) return;
    setKeepData(true);
    if (data.data.length < pageSize) setAllPagesLoaded(true);
    const newRows = [];
    for (const row of data.data) {
      newRows.push({
        id: row.entity.id,
        entity: row.entity,
        score: row?.score,
        product: row?.product,
        objectiveId: row?.objective_id,
        rep: row?.rep,
      });
    }
    setRows((_prevRows) => {
      const prev = { ..._prevRows };
      const prevRegionRows = _prevRows[regionId]
        ? [..._prevRows[regionId]]
        : [];
      prev[regionId] = [...prevRegionRows, ...newRows];
      return prev;
    });
  }, [data]);

  useEffect(() => {
    const scrollEl = tableRef?.current;
    if (!scrollEl) return;
    const handleScroll = () => {
      if (scrollEl.scrollTop > maxScrollTop) {
        setMaxScrollTop(scrollEl.scrollTop);
      }
    };
    scrollEl.addEventListener('scroll', handleScroll);

    // unmount
    // eslint-disable-next-line consistent-return
    return () => {
      scrollEl.removeEventListener('scroll', handleScroll);
    };
  }, [tableRef.current]);

  useEffect(() => {
    const scrollEl = tableRef?.current;
    if (isLoading) return;
    if (!scrollEl) return;
    if (allPagesLoaded) return;
    const scrollDistanceFromBottom = Math.abs(
      scrollEl.scrollTop + scrollEl.clientHeight - scrollEl.scrollHeight
    );

    if (scrollDistanceFromBottom < SCROLL_THRESHOLD && rows[regionId]?.length) {
      refetch();
    }
  }, [tableRef?.current, maxScrollTop, allPagesLoaded]);

  const errorContainer = () => (
    <MessageContainer showBorder>
      <MessageTitle>There was an issue loading the content</MessageTitle>
      <MessageSubtitle>
        Try to refresh the content. If the issue still persists contact support.
      </MessageSubtitle>
      <StyledButton
        variant="contained"
        startIcon={<RefreshIcon />}
        onClick={() => refetch()}
      >
        Try again
      </StyledButton>
    </MessageContainer>
  );

  const loadingContainer = () => (
    <MessageContainer showBorder>
      <MessageTitle>Retrieving data...</MessageTitle>
      <StyledProgress />
    </MessageContainer>
  );
  if (isLoading) return loadingContainer();
  if (!isLoading && isHookEnabled && (!data || isError))
    return errorContainer();

  const districtRow = {
    id: regionId,
    score: null,
    rep: {
      id: regionId,
      name: 'DISTRICT AVERAGE',
    },
    entity: {
      id: regionId,
      name: regions?.find((region) => region.id === regionId)?.listName,
    },
  };

  const currentRows = rows[regionId] || [];

  if (
    isFetching &&
    regionId !== prevRegionRef.current &&
    rows[prevRegionRef.current]
  ) {
    if (isError) return errorContainer();
  }

  const AverageRow =
    showAverageRow && currentRows && currentRows.length > 0 ? (
      <RowContentComponent
        objectiveId={objectiveId}
        cadence={cadence}
        metric={metric}
        metricType={metricType}
        row={districtRow}
        isDistrict
        timestamp={timestamp}
        isLoading={isLoading}
      />
    ) : null;
  return (
    <>
      <Wrapper ref={tableRef} fullHeight={fullHeight}>
        {currentRows && currentRows.length > 0 && (
          <TableHeader
            objectiveId={objectiveId}
            initialColumns={initialColumns}
            metricColumns={metricColumns}
            activityColumns={activityColumns}
            metric={metric}
            metricCadence={cadence}
            activitySubtitle={activitySubtitle}
            activityCadence={activityCadence}
            latestCurationTimestamp={timestamp}
            projectTargetProducts={projectTargetProducts}
          />
        )}
        <Container contentLoaded={currentRows && currentRows.length > 0}>
          {AverageRow}
          {currentRows.map((row, index) => (
            <RowContentComponent
              rank={index + 1}
              key={row.id}
              objectiveId={objectiveId}
              cadence={cadence}
              metric={metric}
              metricType={metricType}
              row={row}
              timestamp={timestamp}
              isLoading={isLoading}
              regionName={regionName}
              onRowClick={onRowClick}
            />
          ))}
          {isFetching && <FetchContainer />}
        </Container>
      </Wrapper>

      {isError && errorContainer()}
    </>
  );
};
