/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import { baseAlbInstance, maptualApiInstance } from './config';

export function getProjectList() {
  return maptualApiInstance.get(`core/project-list`, { timeout: 120000 });
}

export function getProject(projectId) {
  if (projectId) {
    return baseAlbInstance.get(`projects/${projectId}`, { timeout: 60000 });
  }
  return getProjectList();
}

export function getMetrics({ project, objective, entityType }) {
  return maptualApiInstance.get(
    `/pulse/projects/${project}/objectives/${objective}/metrics?entity_type=${entityType}`,
    { timeout: 60000 }
  );
}

export function getSegments(projectId) {
  return maptualApiInstance.get(
    `/sphere/product-lines/${null}/projects/${projectId}/segments`,
    { timeout: 120000 }
  );
}

export function clearCache(projectId, clearCacheFlag) {
  if (projectId) {
    return clearCacheWithProjectId(projectId, clearCacheFlag);
  }
  return clearCacheForAllProjects(clearCacheFlag);
}

const clearCacheWithProjectId = (projectId, clearCacheFlag) =>
  baseAlbInstance.get(
    `projects/${projectId}/cacheInvalidator?clearCache=${clearCacheFlag}`,
    {
      timeout: 180000,
    }
  );

const clearCacheForAllProjects = (clearCacheFlag) =>
  baseAlbInstance.get(
    `projects//cacheInvalidator?clearCache=${clearCacheFlag}`,
    {
      timeout: 180000,
    }
  );

export async function patchProjectMetadata(metadata) {
  return baseAlbInstance.patch(`projects`, { metadata });
}

export function getDashboardStructure({ projectId, segmentId, maptualListId }) {
  return maptualApiInstance.get(
    `sphere/product-lines/TODO/projects/${projectId}/regions/${maptualListId}/segments/${segmentId}/dashboard_charts`,
    { timeout: 60000 }
  );
}

export function getDashboardSubUrl({
  subUrl,
  projectId,
  segmentId,
  maptualListId,
  signal,
}) {
  const projectQueryParam = projectId ? `?projectId=${projectId}` : '';
  const segmentQueryParam = segmentId ? `&segmentId=${segmentId}` : '';
  const maptualListQueryParam = maptualListId
    ? `&maptualListId=${maptualListId}`
    : '';

  if (subUrl === '/dashboard/totalScriptsData') {
    return maptualApiInstance.get(
      `sphere/product-lines/TODO/projects/${projectId}/regions/${maptualListId}/segments/${segmentId}/metrics/numerical/rx/scripts`,
      { timeout: 300000 }
    );
  }
  if (subUrl === '/dashboard/shareData') {
    return maptualApiInstance.get(
      `sphere/product-lines/TODO/projects/${projectId}/regions/${maptualListId}/segments/${segmentId}/metrics/numerical/rx/share`,
      { timeout: 300000 }
    );
  }
  if (subUrl.includes('/attributionAssembler/')) {
    const params = { attribution_type: subUrl.split('/')[2] };
    return maptualApiInstance.get(
      `sphere/product-lines/TODO/projects/${projectId}/regions/${maptualListId}/segments/${segmentId}/attribution`,
      { params, timeout: 300000 }
    );
  }

  const url = `${subUrl}${projectQueryParam}${maptualListQueryParam}${segmentQueryParam}`;
  return baseAlbInstance.get(url, { timeout: 300000, signal });
}

export function getTableResponse({
  productLineId,
  projectId,
  segmentId,
  maptualListId,
  objectiveId,
  signal,
}) {
  return maptualApiInstance.get(
    `/sphere/hcp-view/product-lines/${productLineId}/projects/${projectId}/objectives/${objectiveId}/regions/${maptualListId}/segments/${segmentId}`,
    { timeout: 120000, signal }
  );
}

export function deleteProject(projectId) {
  return baseAlbInstance.delete(`projects/${projectId}`);
}

export function getAnalysis(analysisId, userId = null, href = null) {
  const userIdQueryParam = userId ? `?userId=${userId}` : '';
  const analysisUrl = `analysis${
    analysisId ? `/${analysisId}${userIdQueryParam}` : ''
  }`;
  const url = !analysisId && href ? href : analysisUrl;

  return baseAlbInstance.get(url, { timeout: 30000 });
}

export function getCuratedList({ projectId, regionId }) {
  return maptualApiInstance
    .get(
      `field/product-lines/none/projects/${projectId}/maptual-lists/${regionId}/curated`,
      {
        timeout: 600000,
      }
    )
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          if (error.response.data) {
            return axios.get(error.response.data, { timeout: 600000 });
          }
          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    });
}

export function getLastestCurationReport({ projectId }) {
  if (!projectId) {
    return Promise.reject();
  }
  return baseAlbInstance
    .get(
      `curationDashboard/reports/projectId/${projectId}?type=curationReport`,
      {
        timeout: 600000,
      }
    )
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          if (error.response.data) {
            return axios.get(error.response.data, { timeout: 600000 });
          }
          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    });
}

export function getCurationReport({
  projectId,
  regionId,
  report,
  graphType,
  aggregationMethod,
}) {
  if (!projectId || !regionId || !report) {
    return Promise.reject();
  }
  return baseAlbInstance
    .get(
      `curationDashboard/reports/projectId/${projectId}/regionId/${regionId}?type=${report}${
        graphType ? `&graphType=${graphType}` : ''
      }${aggregationMethod ? `&aggregationMethod=${aggregationMethod}` : ''}`,
      {
        timeout: 600000,
      }
    )
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          if (error.response.data) {
            return axios.get(error.response.data, { timeout: 600000 });
          }
          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    });
}

export function getCurationEvents({
  productLineId,
  projectId,
  mode,
  timestamp,
}) {
  if (!productLineId || !projectId || !mode) {
    return Promise.reject();
  }
  return maptualApiInstance.get(
    `admin/product-lines/${productLineId}/projects/${projectId}/curation/events/latest?mode=${mode}${
      timestamp ? `&timestamp=${timestamp}` : ''
    }`,
    {
      timeout: 600000,
    }
  );
}

export function startCurationExecution({ projectId, mode }) {
  return baseAlbInstance.post(
    `curationDashboard`,
    { projectId, mode },
    { timeout: 30000 }
  );
}

export function getMaptualList(projectId, maptualListId, objectiveId) {
  let url = '';

  if (maptualListId && objectiveId) {
    url = `field/product-lines/none/projects/${projectId}/maptual-lists/${maptualListId}/objective/${objectiveId}`;
  } else if (maptualListId) {
    url = `field/product-lines/none/projects/${projectId}/maptual-lists/${maptualListId}`;
  } else {
    url = `field/product-lines/none/projects/${projectId}/maptual-lists/tree`;
  }

  return maptualApiInstance
    .get(url, {
      timeout: 600000,
    })
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          if (error.response.data) {
            return axios.get(error.response.data, { timeout: 600000 });
          }
          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    });
}

export function getMaptualListsMetadata(projectId) {
  return maptualApiInstance
    .get(
      `field/product-lines/none/projects/${projectId}/maptual-lists/metadata`,
      {
        timeout: 600000,
      }
    )
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          if (error.response.data) {
            return axios.get(error.response.data, { timeout: 600000 });
          }
          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    });
}

export function getInsights(entityId, projectId, regionId) {
  return baseAlbInstance
    .get(
      `insights/project/${projectId}/region/${regionId}/entity/${entityId}`,
      {
        timeout: 60000,
      }
    )
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          if (error.response.data) {
            return axios.get(error.response.data, { timeout: 60000 });
          }
          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    });
}

export function getEntityPrecallInsights({
  entityId,
  regionId,
  projectId,
  objectiveId,
}) {
  return maptualApiInstance.get(
    `field/product-lines/none/projects/${projectId}/objectives/${objectiveId}/regions/${regionId}/entities/${entityId}/precall`,
    {
      timeout: 60000,
    }
  );
}

export function getEntity({
  entityType,
  entityId,
  maptualListId,
  projectId,
  signal,
  appType,
}) {
  return maptualApiInstance.get(
    `field/product-lines/none/projects/${projectId}/regions/${maptualListId}/entities/${entityType}/${entityId}/${appType}`,
    {
      timeout: 120000,
      signal,
    }
  );
}

export function getConnections({
  entityId,
  maptualListId,
  projectId,
  signal,
  productLineId,
}) {
  return maptualApiInstance.get(
    `field/product-lines/${productLineId}/projects/${projectId}/regions/${maptualListId}/entities/${entityId}/connections`,
    {
      params: { maxResponseEntityCount: 10 },
      timeout: 90000,
      signal,
    }
  );
}

export function getEntityMessages({
  entityId,
  maptualListId,
  projectId,
  productLineId,
  objectiveId,
}) {
  return maptualApiInstance.get(
    `field/product-lines/${productLineId}/projects/${projectId}/regions/${maptualListId}/entities/${entityId}/messages`,
    {
      params: { objective_id: objectiveId },
      timeout: 90000,
    }
  );
}

export function getEntityActivityInsights({
  projectId,
  maptualListId,
  entityId,
}) {
  return maptualApiInstance.get(
    `core/projects/${projectId}/activity-insights/regions/${maptualListId}/entities/${entityId}`,
    {
      timeout: 90000,
    }
  );
}

export function getScriptsByChip({
  entityId,
  maptualListId,
  projectId,
  chipMetadata,
  productLineId,
}) {
  return maptualApiInstance.get(
    `field/product-lines/${productLineId}/projects/${projectId}/regions/${maptualListId}/entities/${entityId}/metrics/numerical/rx/scripts`,
    {
      timeout: 90000,
      params: { chip_metadata: chipMetadata },
    }
  );
}

export function getScriptsMetadata({
  entityId,
  maptualListId,
  projectId,
  productLineId,
}) {
  return maptualApiInstance.get(
    `field/product-lines/${productLineId}/projects/${projectId}/regions/${maptualListId}/entities/${entityId}/metrics/numerical/rx/scripts/metadata`,
    {
      timeout: 90000,
    }
  );
}

export function getShare({
  // eslint-disable-next-line no-unused-vars
  entityType,
  entityId,
  maptualListId,
  projectId,
  signal,
}) {
  return maptualApiInstance.get(
    `field/product-lines/todo/projects/${projectId}/regions/${maptualListId}/entities/${entityId}/metrics/numerical/rx/share`,
    {
      timeout: 90000,
      signal,
    }
  );
}

export function getMaptualListURL({ projectId, maptualListId }) {
  return maptualApiInstance
    .get(
      `field/product-lines/none/projects/${projectId}/maptual-lists/${maptualListId}/csv-export`,
      {
        timeout: 60000,
      }
    )
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          return error.response.data
            ? Promise.resolve({ data: error.response.data })
            : Promise.reject(error);
        }
        default: {
          return Promise.reject(error);
        }
      }
    });
}

export async function getGskCsv({ projectId }) {
  try {
    const response = await baseAlbInstance.get(`gskCsvDeploy/csv`, {
      params: {
        projectId,
      },
      timeout: 60000,
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function deployGskCsv({ projectId, path, filename, newFilename }) {
  try {
    const response = await baseAlbInstance.post('gskCsvDeploy/csv', {
      projectId,
      path,
      filename,
      newFilename,
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getTerritoryTableData({ projectId }) {
  const url = `table/${projectId}/treeTable`;
  return baseAlbInstance
    .get(url, {
      timeout: 900000,
    })
    .catch(async (error) => {
      switch (error?.response?.status) {
        case 307: {
          const res = await axios.get(error.response.data, { timeout: 900000 });
          return res;
        }
        default: {
          return Promise.reject(error);
        }
      }
    });
}

export async function getSegmentsTableData({ projectId, regionId }) {
  return baseAlbInstance
    .get(`table/${projectId}/segmentsTable/${regionId}`, {
      timeout: 900000,
    })
    .catch(async (error) => {
      switch (error?.response?.status) {
        case 307: {
          const res = await axios.get(error.response.data, { timeout: 900000 });
          // remove all negative values from response
          // TODO: remove this and fix on BE, this is for a demo
          if (res?.data) {
            for (const [objectiveId] of Object.entries(res.data)) {
              for (const row of res.data[objectiveId].rows) {
                for (const [rowName, rowValue] of Object.entries(row)) {
                  if (rowName.toLowerCase().includes('predicted')) {
                    row[rowName] = Math.max(0, rowValue);
                  }
                }
              }
            }
          }
          return res;
        }
        default: {
          return Promise.reject(error);
        }
      }
    });
}

export function getProjectObjectives({ projectId }) {
  return maptualApiInstance.get(`core/projects/${projectId}/objectives`, {
    timeout: 15000,
  });
}

export function getObjectives({ projectId }) {
  return baseAlbInstance.get(`table/${projectId}/objectives`, {
    timeout: 15000,
  });
}

export const getDetailedProjectStatus = (projectId) =>
  baseAlbInstance
    .get(`/projects/${projectId}/detailedProjectStatus`, {
      timeout: 60000,
    })
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          if (error.response.data) {
            return axios.get(error.response.data, { timeout: 60000 });
          }
          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    });

export const getTestSuiteStatus = () =>
  maptualApiInstance.get(
    `admin/product-lines/todo/projects/quality-report-checks`,
    {
      timeout: 60000,
    }
  );

export const getProjectTestSuiteDetailedStatuses = (projectId) =>
  maptualApiInstance.get(
    `admin/product-lines/todo/projects/${projectId}/project-quality-checks`,
    {
      timeout: 60000,
    }
  );

export const getUserGroupTestSuiteDetailedStatuses = () =>
  maptualApiInstance.get(
    `admin/product-lines/todo/projects/user-group-quality-checks`,
    {
      timeout: 60000,
    }
  );

export const getUserGroupDataReport = () =>
  maptualApiInstance.get(
    `admin/product-lines/todo/projects/user-group-report`,
    {
      timeout: 60000,
    }
  );

export const getRecentProjects = (userGroup, businessUnit) =>
  maptualApiInstance.get('csv-delivery-dashboard/recent-projects', {
    params: { user_group: userGroup, business_unit: businessUnit },
    timeout: 60000,
  });
