import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { Container } from './Container';
import { TerritoryOverviewContext } from './territoryOverviewContext';
import { useFieldTerritoryOverviewContext } from './useFieldTerritoryOverviewContext';

const Root = styled(Box)(({ theme: { themeColors } }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: themeColors.mainBackground,
  overflow: 'auto',
}));

export const Wrapper = () => {
  const fieldTerritoryOverviewContext = useFieldTerritoryOverviewContext();

  return (
    <Root>
      <TerritoryOverviewContext.Provider value={fieldTerritoryOverviewContext}>
        <Container />
      </TerritoryOverviewContext.Provider>
    </Root>
  );
};
