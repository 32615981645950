import React, { useContext, useEffect, useState } from 'react';
import SegmentPopover from './segmentPopover';
import { userCreatedListOptions } from './userCreatedListsConfig';
import {
  ListSelectionPopover,
  ListSelectionPopoverButton,
  ListSelectionPopoverContent,
} from '../../../containers/application/appViews/field/list/listSelectionComponents';
import { FieldContext } from '../../../containers/application/appViews/field/fieldContext';

const ALL_HCP_INTERCOM_ID = 6576744;

const defaultSegmentState = {
  descriptionConfig: [],
  metric: '',
  intercomId: ALL_HCP_INTERCOM_ID,
};

const SegmentSelection = ({
  defaultSegment,
  segment,
  setSegment,
  selectedUserCreatedList,
  setSelectedUserCreatedList,
  maptualListSegment,
  selectCustomList,
  selectMaptualListSegment,
  disabled,
}) => {
  const { segmentList: segments } = useContext(FieldContext);
  const segmentNames = segments?.map((s) => s.name);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [segmentState, setSegmentState] = useState(defaultSegmentState);

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  function findDesiredSegment(segmentsList) {
    return segmentsList.find((item) => item.name === maptualListSegment);
  }

  const getPopoverHeight = () =>
    (anchorEl?.offsetTop || 0) + (anchorEl?.clientHeight || 0) || 0;

  const getPopoverWidth = () => {
    if (!anchorEl) return 0;
    const parentOffset = anchorEl.offsetParent?.offsetLeft ?? 0;
    return anchorEl.offsetLeft + parentOffset;
  };

  useEffect(() => {
    const segmentExists = segments?.find((seg) => seg.name === segment);

    if (!segmentExists) {
      setSegment(defaultSegment);
      setSegmentState(defaultSegmentState);
      selectMaptualListSegment(null);
    }
  }, [segments]);

  useEffect(() => {
    if (!maptualListSegment) {
      setSegment(defaultSegment);
      setSegmentState(defaultSegmentState);
    } else if (
      Array.isArray(segmentNames) &&
      maptualListSegment &&
      maptualListSegment !== segment
    ) {
      setSegment(maptualListSegment);

      const desiredSegment = findDesiredSegment(segments);
      setSegmentState(
        desiredSegment
          ? {
              descriptionConfig: desiredSegment.description,
              metric: desiredSegment.metric,
              intercomId: desiredSegment.intercomId,
            }
          : defaultSegmentState
      );
    }
  }, [maptualListSegment, segmentNames]);

  const id = open ? 'transitions-popper' : undefined;

  const createSegmentLabel = (name) =>
    selectedUserCreatedList
      ? userCreatedListOptions?.[selectedUserCreatedList]?.label
      : name;

  return (
    <>
      <ListSelectionPopoverButton
        label={createSegmentLabel(segment === 'All' ? 'All Segments' : segment)}
        onClickAction={handleDropdownClick}
        disabled={disabled}
      />
      <ListSelectionPopover
        anchorTop={getPopoverHeight()}
        data-testid="segment-selection-dropdown"
        id={id}
        open={open}
        onClose={handleDropdownClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: getPopoverHeight(), left: getPopoverWidth() }}
      >
        <ListSelectionPopoverContent>
          <SegmentPopover
            segment={segment}
            defaultSegment={defaultSegment}
            handleDropdownClose={handleDropdownClose}
            selectMaptualListSegment={selectMaptualListSegment}
            selectCustomList={selectCustomList}
            segmentState={segmentState}
            selectedUserCreatedList={selectedUserCreatedList}
            setSelectedUserCreatedList={setSelectedUserCreatedList}
          />
        </ListSelectionPopoverContent>
      </ListSelectionPopover>
    </>
  );
};

export default SegmentSelection;
