import { useQuery } from 'react-query';
import * as requests from '../../../../../../../request/projectRequests';

export const useCurationReports = ({
  projectId,
  regionId,
  report,
  graphType,
  aggregationMethod,
}) => {
  const queryCacheKey = Object.values({
    projectId,
    regionId,
    report,
    graphType,
    admin: 'curation',
    aggregationMethod,
  });
  return useQuery(queryCacheKey, async () => {
    try {
      const response = await requests.getCurationReport({
        projectId,
        regionId,
        report,
        graphType,
        aggregationMethod,
      });
      return response;
    } catch {
      return [];
    }
  });
};
