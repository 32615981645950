import React from 'react';
import HistoricalScriptChart from './historicalScriptChart';
import MATChart from './MATChart';
import { METRIC_CHART_TAB_CADENCES } from './constants';

const ChartController = ({
  currentTab,
  dataList,
  numberFormatter,
  rowSelected,
}) => {
  switch (currentTab.value) {
    case METRIC_CHART_TAB_CADENCES.WEEKLY:
    case METRIC_CHART_TAB_CADENCES.MONTHLY:
    case METRIC_CHART_TAB_CADENCES.R3M:
    case METRIC_CHART_TAB_CADENCES.QUARTERLY:
      return dataList ? (
        <HistoricalScriptChart
          data={dataList}
          numberFormatter={numberFormatter}
          rowSelected={rowSelected}
        />
      ) : null;
    case METRIC_CHART_TAB_CADENCES.ANNUAL:
      return dataList ? (
        <MATChart
          data={dataList}
          numberFormatter={numberFormatter}
          rowSelected={rowSelected}
        />
      ) : null;
    default:
      return <div>could not find a matching chart</div>;
  }
};

export default ChartController;
