import { getDemoAccountStatus } from '../../request/config';

export const anonymizeSphereTableInfo = () => (next) => (action) => {
  const modifiedAction = action;
  const isDemo = getDemoAccountStatus();

  if (isDemo && action.type === 'PROJECT_GET_TABLE_RESPONSE_FULFILLED') {
    const { rows } = modifiedAction.payload;
    const modifiedRows = rows.map((rowItem) => ({
      ...rowItem,
      address: `Address ${rowItem.id}`,
      city: 'City ville',
      cityTown: 'Townsville',
      firstName: 'Entity',
      lastName: `Name ${rowItem.id}`,
      fullName: `Entity Name ${rowItem.id}`,
      postalCode: 'ZIP CODE',
      province: 'AT',
    }));

    modifiedAction.payload = { ...modifiedAction.payload, rows: modifiedRows };
  }

  return next(modifiedAction);
};
