import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import TimelineIcon from '@mui/icons-material/Timeline';
import { InsightsCard } from './insightsCard/insightsCard';
import { getEntityPrecallInsights } from '../../../request/projectRequests';
import { FieldContext } from '../../../containers/application/appViews/field/fieldContext';
import { FeedbackBox } from '../entityMessages/feedbackBox';

export const InsightsPrecall = ({ entityId, objective }) => {
  const fieldContext = useContext(FieldContext);

  if (!fieldContext) {
    return null;
  }

  const { project, maptualListMetadata } = fieldContext;
  const { data } = useQuery({
    queryKey: [
      'entityPrecallInsights',
      entityId,
      objective.objectiveId,
      project.metadata.projectId,
      maptualListMetadata.maptualListId,
    ],
    queryFn: async () => {
      try {
        const response = await getEntityPrecallInsights({
          entityId,
          projectId: project.metadata.projectId,
          objectiveId: objective.objectiveId,
          regionId: maptualListMetadata.maptualListId,
        });
        return response.data;
      } catch {
        return null;
      }
    },
    enabled: !!entityId && !!objective.objectiveId,
  });

  if (!data || !data.title || !data.description) {
    return null;
  }

  return (
    <InsightsCard
      messaging
      title={data.title}
      subtitle={data.description}
      icon={TimelineIcon}
    >
      <FeedbackBox insight={data} objective={objective} entityId={entityId} />
    </InsightsCard>
  );
};
