import React from 'react';
import { Button } from '@mui/material';
import styled from '@emotion/styled';

export const StyledUserMenuButton = styled(Button)(({ theme }) => ({
  height: '100%',
  width: '100%',
  padding: '12px',
  minHeight: '36px',
  justifyContent: 'flex-start',
  color: theme.themeColors.segmentSelectionCaptionColor,
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '24px',
  backgroundColor: theme.themeColors.paperColor,
  border: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
    border: 'none',
    backgroundColor: theme.themeColors.buttonBorderColor,
  },
  '& .MuiSvgIcon-root': {
    color: theme.themeColors.listIconColor,
  },
}));

export const UserMenuButton = ({
  onClick,
  startIcon,
  buttonText,
  disabled,
}) => (
  <StyledUserMenuButton
    onClick={onClick}
    startIcon={startIcon}
    disabled={disabled}
  >
    {buttonText}
  </StyledUserMenuButton>
);
