import React from 'react';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { ReactComponent as ThumbnailIcon } from './thumbnail_bar.svg';

const StyledNavigationIconButton = styled(IconButton)(() => ({
  height: '24px',
  width: '24px',
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  marginRight: '16px',
  position: 'absolute',
  left: 16,
  top: 24,
  zIndex: 999,
}));

export const NavigationButton = ({ handleClick }) => (
  <StyledNavigationIconButton aria-label="navigation" onClick={handleClick}>
    <ThumbnailIcon />
  </StyledNavigationIconButton>
);
