/* eslint-disable no-underscore-dangle */
import { useQuery } from 'react-query';
import { maptualApiInstance } from '../../../../../../../../../../request/config';

export const useRegionMetricData = ({
  productLineId,
  projectId,
  objectiveId,
  regionId,
  metricType,
  cadence,
  timestamp,
  products,
  tableName,
  isDistrict,
}) => {
  const queryCacheKey = Object.values({
    projectId,
    objectiveId,
    regionId,
    metricType,
    cadence,
    timestamp,
    products,
    tableName,
  });
  return useQuery({
    queryKey: queryCacheKey,
    queryFn: async () => {
      try {
        const response = await getRegionMetricData({
          productLineId,
          projectId,
          objectiveId,
          regionId,
          metricType,
          cadence,
          timestamp,
          products,
          tableName,
          isDistrict,
        });
        return response.data;
      } catch {
        return [];
      }
    },
    enabled: _validParams(
      _validParams,
      metricType,
      cadence,
      timestamp,
      products,
      tableName
    ),
  });
};

const _validParams = (
  regionId,
  metricType,
  cadence,
  timestamp,
  products,
  tableName
) => {
  if (
    !regionId ||
    !metricType ||
    !cadence ||
    !timestamp ||
    !products?.length ||
    !tableName
  ) {
    return false;
  }
  return true;
};

export function getRegionMetricData({
  productLineId,
  projectId,
  objectiveId,
  regionId,
  metricType,
  cadence,
  timestamp,
  products,
  tableName,
  isDistrict,
}) {
  if (
    !_validParams(regionId, metricType, cadence, timestamp, products, tableName)
  ) {
    return Promise.reject();
  }
  const entityType = !isDistrict ? 'regions' : 'districts';
  const productsString = products.map((i) => `products=${i}`).join('&');
  return maptualApiInstance.get(
    `pulse/product-lines/${productLineId}/projects/${projectId}/objectives/${objectiveId}/${entityType}/${regionId}/metrics/${metricType}?${productsString}&cadence=${cadence}&timestamp=${timestamp}&table_name=${tableName}`,
    {
      timeout: 600000,
    }
  );
}
