import React, { useContext, useEffect, useState } from 'react';

import { TerritoryOverviewContext } from '../territoryOverviewContext';
import { trackTerritoryOverviewTooltip } from '../../../trackers/mixpanel';
import { getTimeframeName } from '../utils';
import { TooltipPopover } from '../../shared/TooltipPopover';

export const ActivityDescriptionPopover = () => {
  const {
    metadata,
    data,
    selectedTimeframeID,
    distributionType,
    categoryOptions,
    selectedCategory,
    metricOptions,
    selectedMetric,
  } = useContext(TerritoryOverviewContext);

  const onClick = () => {
    trackTerritoryOverviewTooltip('Activity', [
      getTimeframeName(data, selectedTimeframeID),
      distributionType,
      categoryOptions[selectedCategory].label,
      metricOptions[selectedMetric].label,
    ]);
  };

  const [listData, setListData] = useState();

  useEffect(() => {
    if (metadata && metadata.data) {
      setListData(metadata.data.activityChannels);
    }
  }, [metadata]);

  return (
    <TooltipPopover
      title="Activity"
      description="Total count of activities in the region. Activities are one of
            following:"
      listData={listData}
      onClick={onClick}
    />
  );
};
