import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import {
  deleteUser,
  getUsersData,
  clearStatus,
} from '../../slices/userManagement';
import getUserManagementState from '../../hooks/userManagement';
import { userManagementColors } from './styles/styledComponents';
import {
  ModalBody,
  ModalDisplay,
  ModalFooter,
  ModalHeader,
} from '../generic/modalDisplay';

const StyledTypography = styled(Typography)(() => ({
  color: userManagementColors.primaryColor,
}));

const StyledLoadingButton = styled(LoadingButton)(() => ({
  textTransform: 'none',
  marginLeft: 1,
}));

const DeleteUserModal = ({ isDeleteModalOpen, name, userId, onClose }) => {
  const dispatch = useDispatch();
  const state = getUserManagementState();

  useEffect(() => {
    if (state.userRequestStatus === 'SUCCESS' && isDeleteModalOpen) {
      dispatch(getUsersData());
      dispatch(clearStatus('userRequestStatus'));
      onClose();
    }
  }, [state.userRequestStatus]);

  return (
    <ModalDisplay isOpen={isDeleteModalOpen} onClose={onClose}>
      <ModalHeader onClose={onClose}>
        <Typography variant="h3">
          Are you sure you want to delete {name}?
        </Typography>
      </ModalHeader>
      <ModalBody>
        <StyledTypography variant="body2">
          The user will no longer be able to access their account.
        </StyledTypography>
      </ModalBody>
      <ModalFooter>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <StyledLoadingButton
          variant="contained"
          color="error"
          type="submit"
          loading={state.userRequestStatus === 'PENDING'}
          onClick={() => dispatch(deleteUser(userId))}
        >
          Delete User
        </StyledLoadingButton>
      </ModalFooter>
    </ModalDisplay>
  );
};

export default DeleteUserModal;
