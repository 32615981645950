import { styled } from '@mui/material';
import { themeColors as adminColors } from '../../styles/adminStyles';

export const CardBodyText = styled('span')({
  color: adminColors.darkTheme.adminCardBodyText,
  fontWeight: 400,
});

export const ObjectiveTypeText = styled('span')({
  color: adminColors.darkTheme.adminCardSubheader,
  fontWeight: 600,
});
