import React from 'react';
import styled from '@emotion/styled';
import { Skeleton, Box } from '@mui/material';

const SkeletonWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}));

const SkeletonHeader = styled(Skeleton)(() => ({
  transform: 'scale(1)',
  height: 64,
}));

const SkeletonBody = styled(Skeleton)(() => ({
  transform: 'scale(1)',
  height: 160,
}));

export const TableSkeleton = () => (
  <SkeletonWrapper>
    <SkeletonHeader animation="wave" />
    <SkeletonBody animation="wave" />
  </SkeletonWrapper>
);
