import React, { useContext, useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { SentryRoute } from '../../sentryRoute';
import * as ViewerAction from '../../actions/projectViewActions';
import EmptyView from '../../components/emptyview/emptyView';
import EntitySelection from '../../components/fieldView/entitySelection/entitySelection';
import HCPEntityPage from '../../components/fieldView/hcpEntityPage';
import { createFieldUserPreference } from '../../request/requests';
import {
  trackObjectiveSelection,
  trackMaptualListSelection,
} from '../../trackers/appEventTracker';
import { useMetricScriptsMetadata } from '../../hooks/useMetricScriptsMetadata';
import useMetricScriptsData from '../../hooks/useMetricScriptsData';
import useFeatureToggles from '../../hooks/useFeatureToggles';
import { FieldContext } from '../application/appViews/field/fieldContext';
import { useFieldViewDeepLinkPreferences } from '../../hooks/useFieldViewDeepLinkPreferences';
import { AppContext } from '../application/appContext';
import { setCurrentUserProfile } from '../../slices/profile';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    'min-height': '100%',
    display: 'flex',
    backgroundColor: theme.themeColors.mainBackground,
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  progressContainer: {
    height: '100%',
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'flex-end',
    'align-items': 'center',
  },
  progressBar: {
    'margin-top': '50px',
    width: 'auto',
    'margin-bottom': '70px',
  },
  projectName: {
    flexGrow: 1,
  },
  widthSpacer: {
    width: 25,
  },
}));
const eagerLoadMetricScriptsMetadata = () => useMetricScriptsMetadata();
const eagerLoadMetricScriptsData = () => useMetricScriptsData();
const eagerLoadFeatureToggles = () => useFeatureToggles();

const mapStateToProps = (state) => ({
  viewer: state.projectViewer,
});

const mapDispatchToProps = (dispatch) => ({
  selectMaptualListSegment: (segmentSelection) =>
    dispatch(ViewerAction.selectMaptualListSegment(segmentSelection)),
  selectCustomList: (customerListSelection) =>
    dispatch(ViewerAction.selectCustomList(customerListSelection)),
  selectSortingOption: (option) =>
    dispatch(ViewerAction.selectSortingOption(option)),
  getEntity: (
    entityType,
    entityId,
    maptualListId,
    projectId,
    excludeScore = false
  ) =>
    dispatch(
      ViewerAction.getEntity({
        entityType,
        entityId,
        maptualListId,
        projectId,
        appType: excludeScore ? 'field' : 'sphere',
      })
    ),
  getShare: (entityType, entityId, maptualListId, projectId) =>
    dispatch(
      ViewerAction.getShare({
        entityType,
        entityId,
        maptualListId,
        projectId,
      })
    ),
  getInsights: (entityId, projectId, maptualListId) =>
    dispatch(ViewerAction.getInsights({ entityId, projectId, maptualListId })),
  selectEntity: (entityMetadata) =>
    dispatch(ViewerAction.selectEntity(entityMetadata)),
  setUserPreferences: (preferences) =>
    dispatch(setCurrentUserProfile(preferences)),
});

const shouldUpdatePreference = (oldPreference, newPreference, idKey) => {
  if (!oldPreference && !newPreference) return false;
  if (!oldPreference && newPreference) return true;
  return newPreference[idKey] !== oldPreference[idKey];
};

const ENTITY_HCP = 'HCP';

function FieldViewer({
  match,
  profile,
  viewer,
  getEntity,
  getShare,
  getInsights,
  selectMaptualListSegment,
  selectEntity,
  selectCustomList,
  selectSortingOption,
  setUserPreferences,
  redirectUrl,
}) {
  const classes = useStyles();
  const fieldContext = useContext(FieldContext);
  const { projectList, isProjectListLoading, updateUserPreferences } =
    useContext(AppContext);

  const {
    project,
    maptualListMetadata,
    isMaptualListsLoading,
    isMaptualListLoading,
    refetchMaptualList,
  } = fieldContext;

  const setUserPreferenceAll = (newPreferences) => {
    updateUserPreferences(newPreferences);
    setUserPreferences(newPreferences);
  };

  const [fieldPreferences] = useState(
    useFieldViewDeepLinkPreferences({
      project,
      preference: profile?.preference || {},
      projects: projectList,
      redirectUrl,
    })
  );

  const { maptualListSegment, entityMetadata, entity, isEntityBusy } = viewer;

  useEffect(() => {
    const { entityMetadataPref } = fieldPreferences;

    if (entityMetadataPref) {
      selectEntity({ ...entityMetadataPref });
    }
  }, [fieldPreferences, profile, project]);

  useEffect(() => {
    if (maptualListMetadata && project && project.metadata) {
      const { maptualListMetadataPref, entityMetadataPref } = fieldPreferences;
      if (entityMetadataPref) {
        selectEntity({ ...entityMetadataPref });
      }

      const shouldUpdateMaptualList = shouldUpdatePreference(
        maptualListMetadataPref,
        maptualListMetadata,
        'maptualListId'
      );

      if (shouldUpdateMaptualList) {
        setUserPreferenceAll(
          createFieldUserPreference({
            userProfile: profile,
            projectMetadata: project.metadata,
            maptualListMetadata,
            segmentSelection: maptualListSegment || null,
          })
        );
      }
      trackMaptualListSelection(maptualListMetadata.listName);
    }
  }, [maptualListMetadata]);

  useEffect(() => {
    const projectAndMaptualListExists =
      maptualListMetadata && project && project.metadata;
    const shouldUpdateSegment =
      maptualListSegment !== profile?.preference?.lastSegmentSelection;

    if (projectAndMaptualListExists && shouldUpdateSegment) {
      setUserPreferenceAll(
        createFieldUserPreference({
          userProfile: profile,
          projectMetadata: project.metadata,
          maptualListMetadata,
          segmentSelection: maptualListSegment,
        })
      );
    }
  }, [maptualListSegment, project, profile]);

  useEffect(() => {
    if (project?.metadata?.projectId) {
      trackObjectiveSelection(project.metadata.projectName);
    }
  }, [project]);

  eagerLoadMetricScriptsMetadata();
  eagerLoadMetricScriptsData();
  eagerLoadFeatureToggles();

  useEffect(() => {
    if (
      entityMetadata?.entityId &&
      maptualListMetadata?.maptualListId &&
      project?.metadata?.projectId
    ) {
      getEntity(
        ENTITY_HCP,
        entityMetadata.entityId,
        maptualListMetadata.maptualListId,
        project.metadata.projectId,
        true
      );
    }
  }, [
    entityMetadata?.entityId,
    maptualListMetadata?.maptualListId,
    project?.metadata?.projectId,
  ]);

  useEffect(() => {
    if (
      entityMetadata?.entityId &&
      maptualListMetadata?.maptualListId &&
      project?.metadata?.projectId
    ) {
      getShare(
        ENTITY_HCP,
        entityMetadata.entityId,
        maptualListMetadata.maptualListId,
        project.metadata.projectId
      );
    }
  }, [
    entityMetadata?.entityId,
    maptualListMetadata?.maptualListId,
    project?.metadata?.projectId,
  ]);

  useEffect(() => {
    if (
      entityMetadata?.entityId &&
      maptualListMetadata?.maptualListId &&
      project?.metadata?.projectId
    ) {
      getInsights(
        entityMetadata.entityId,
        project.metadata.projectId,
        maptualListMetadata.maptualListId
      );
    }
  }, [
    entityMetadata?.entityId,
    maptualListMetadata?.maptualListId,
    project?.metadata?.projectId,
  ]);

  useEffect(() => {
    if (
      maptualListMetadata?.maptualListId &&
      entityMetadata?.entityId &&
      entityMetadata?.entityType &&
      project?.metadata?.projectId
    ) {
      const {
        maptualListMetadataPref,
        projectMetadataPref,
        entityMetadataPref,
      } = fieldPreferences;

      const shouldUpdateMaptualList = shouldUpdatePreference(
        maptualListMetadataPref,
        maptualListMetadata,
        'maptualListId'
      );
      const shouldUpdateProject = shouldUpdatePreference(
        projectMetadataPref,
        project?.metadata,
        'projectId'
      );
      const shouldUpdateEntity = shouldUpdatePreference(
        entityMetadataPref,
        entityMetadata,
        'entityId'
      );

      if (
        shouldUpdateMaptualList ||
        shouldUpdateProject ||
        shouldUpdateEntity
      ) {
        setUserPreferenceAll(
          createFieldUserPreference({
            userProfile: profile,
            projectMetadata: project.metadata,
            maptualListMetadata,
            entityMetadata,
            segmentSelection: maptualListSegment || null,
          })
        );
      }
    }
  }, [entityMetadata?.entityId]);

  return (
    <div className={classes.root}>
      <Switch>
        <SentryRoute
          exact
          path={match.path}
          render={() =>
            !isMaptualListsLoading && !isProjectListLoading ? (
              <>
                <EntitySelection
                  profile={profile}
                  selectEntity={selectEntity}
                  selectMaptualListSegment={selectMaptualListSegment}
                  selectCustomList={selectCustomList}
                  selectSortingOption={selectSortingOption}
                  viewer={viewer}
                />
                {maptualListMetadata ? (
                  <HCPEntityPage
                    entity={entity}
                    entityMetadata={entityMetadata}
                    isEntityBusy={isEntityBusy}
                    getEntity={getEntity}
                    getInsights={getInsights}
                    getShare={getShare}
                    entityType="HCP" // TO-DO move entityType into metadata on the backend
                    maptualListId={
                      maptualListMetadata
                        ? maptualListMetadata.maptualListId
                        : ''
                    }
                    projectId={project?.metadata?.projectId}
                    displayUserCreatedListsButton
                    isMaptualListBusy={isMaptualListLoading}
                  />
                ) : null}
              </>
            ) : (
              <EmptyView
                isLoading={isMaptualListsLoading}
                loadingTitle={
                  (maptualListMetadata?.listName &&
                    `Loading ${maptualListMetadata.listName} List ...`) ||
                  (isMaptualListsLoading &&
                    project?.metadata?.projectName &&
                    `Loading ${project.metadata.projectName} Objective ...`) ||
                  'Loading Content ...'
                }
                refreshAction={() => {
                  try {
                    refetchMaptualList();
                  } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log('[ERROR] Reload Error ', error);
                  }
                }}
              />
            )
          }
        />
        <SentryRoute
          path={match.path}
          render={() => (
            <EmptyView
              isLoading
              loadingTitle="Loading Content ..."
              refreshAction={() => {
                try {
                  refetchMaptualList();
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.log('[ERROR] Reload Error ', error);
                }
              }}
            />
          )}
        />
      </Switch>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldViewer);
