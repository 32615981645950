import React from 'react';
import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';

const StyledSkeletonBar = styled(Skeleton)(() => ({
  transform: 'scale(1)',
  height: 100,
}));

export const DrawerProfileSkeleton = () => (
  <StyledSkeletonBar animation="wave" />
);
