import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useQuery } from 'react-query';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { getMetrics } from '../../../../../../../../../request/projectRequests';

const StyledMetricSelector = styled.div(() => ({}));

export const MetricSelector = ({
  project,
  objective,
  updateFilterMetric,
  entityType,
}) => {
  const [selectedMetric, setSelectedMetric] = useState('');

  const { data, isSuccess } = useQuery({
    queryKey: ['metrics', project, objective, entityType],
    queryFn: async () => {
      const response = await getMetrics({ project, objective, entityType });
      return response.data;
    },
  });

  useEffect(() => {
    if (data && data.length >= 0) {
      setSelectedMetric(data[0]);
      updateFilterMetric(data[0]);
    }
  }, [data]);

  const handleMetricChange = (e, newMetric) => {
    if (newMetric) {
      setSelectedMetric(newMetric);
      updateFilterMetric(newMetric);
    }
  };

  return (
    <StyledMetricSelector>
      {data && data.length > 0 && isSuccess && (
        <ToggleButtonGroup
          value={selectedMetric}
          exclusive
          onChange={handleMetricChange}
          aria-label="Metric"
        >
          {data.map((metric) => (
            <ToggleButton key={metric.type} value={metric} variant="contained">
              {metric.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </StyledMetricSelector>
  );
};
