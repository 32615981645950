import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Autocomplete } from '@mui/lab';
import { StyledTextField, SubtitleText } from '../styles/styledComponents';

const StyledTitleText = styled(Typography)({
  paddingBottom: '4px',
});

const StyledSubtitleText = styled(SubtitleText)({
  marginBottom: '0px',
});

const StyledAutocomplete = styled(Autocomplete)(
  ({ theme: { themeColors } }) => ({
    width: '60%',
    '& label.Mui-focused': {
      color: themeColors.neutral60,
    },
    '&.Mui-focused .MuiAutocomplete': {
      borderColor: themeColors.neutral60,
      borderWidth: '1px',
    },
  })
);

const DropdownWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

const FooterText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
  fontSize: '12px',
  textIndent: '14px',
}));

export function ShareMetricsDropdown({
  metrics,
  disabled,
  setShareMetric,
  editedObjectiveShareMetricId,
}) {
  const [selectedMetric, setSelectedMetric] = useState(null);
  useEffect(() => {
    const metricToPrefill = metrics.find(
      (metric) => metric.metricId === editedObjectiveShareMetricId
    );
    setSelectedMetric(metricToPrefill);
  }, [editedObjectiveShareMetricId, metrics]);
  return (
    <>
      <StyledTitleText variant="h5">Market Share Metric</StyledTitleText>
      <StyledSubtitleText>
        Select a metric if you wish to calculate market share between target
        products and competitive opportunities.
      </StyledSubtitleText>
      <DropdownWrapper>
        <StyledAutocomplete
          disabled={disabled}
          options={metrics}
          value={selectedMetric}
          getOptionLabel={(option) => option.metricFriendlyName.toUpperCase()}
          onChange={(event, value) => {
            setShareMetric({
              uuid: value?.metricId,
              rxType: value?.metricRxType,
            });
            setSelectedMetric(value);
          }}
          renderInput={(params) => (
            <StyledTextField
              notched
              focused
              {...params}
              disabled={disabled}
              placeholder={metrics.length ? 'Select Market Share Metric' : ''}
            />
          )}
        />
        {disabled && (
          <FooterText>Create target basket to enable this selection</FooterText>
        )}
      </DropdownWrapper>
    </>
  );
}
